import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useCategory from '../../../hooks/useCategories';
import { Category } from '../../../models/GQL_API';
import MenuSide from './MenuSide';
import OrderSide from './OrderSide';
import { useDispatch, useSelector } from 'react-redux';
import { setModalOpen } from '../../../store/ducks/server';

interface Props {
  slug: string;
}
const POS: React.FC<Props> = ({ slug }) => {
  const [itemsToCheckout, setItemsToCheckout] = useState<any[]>([]);
  const [isBuying, setIsBuying] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [fetchingCategories, setFetchingCategories] = useState(false);
  const { categoriesFetch } = useCategory('categories', 'category');
  const dispatch = useDispatch();
  const serverAddress = useSelector((state: any) => state.server.serverAddress);
  // const fetchCategories = async () => {
  //   try {
  //     setFetchingCategories(true);
  //     const fetchedCategories = await categoriesFetch();
  //     setCategories(fetchedCategories);
  //     setFetchingCategories(false);
  //   } catch (err) {
  //     setFetchingCategories(false);
  //     console.log({ err });
  //   }
  // };

  const fetchCategories = async () => {
    try {
      setFetchingCategories(true);
      const response = await fetch(serverAddress + '/category');
      if (response.ok) {
        const fetchedCategories = await response.json();
        setCategories(fetchedCategories.data.data);
        setFetchingCategories(false);
      } else {
        // Handle non-successful response
        setFetchingCategories(false);
        console.error('Failed to fetch categories:', response.statusText);
      }
    } catch (err) {
      // Handle error
      setFetchingCategories(false);
      console.error('Error fetching categories:', err);
      dispatch(setModalOpen(true));
    }
  };

  // const fetchCategories = async () => {
  //   try {
  //     setFetchingCategories(true);
  //     const response = await fetch('http://localhost:8000/api/v1/category');
  //     if (response.ok) {
  //       const fetchedCategories = await response.json();
  //       setCategories(fetchedCategories.data.data);
  //       setFetchingCategories(false);
  //     } else {
  //       // Handle non-successful response
  //       setFetchingCategories(false);
  //       console.error('Failed to fetch categories:', response.statusText);
  //     }
  //   } catch (err) {
  //     // Handle error
  //     setFetchingCategories(false);
  //     console.error('Error fetching categories:', err);
  //   }
  // };

  useEffect(() => {
    fetchCategories();
  }, [serverAddress]);

  return (
    <Box>
      <Grid container>
        <MenuSide
          setItemsToCheckout={setItemsToCheckout}
          itemsToCheckout={itemsToCheckout}
          isBuying={isBuying}
          setIsBuying={setIsBuying}
        />
        {serverAddress ? (
          <OrderSide
            setItemsToCheckout={setItemsToCheckout}
            itemsToCheckout={itemsToCheckout}
            isBuying={isBuying}
            categories={categories}
            fetchingCategories={fetchingCategories}
          />
        ) : (
          <Grid item xs={12} sm={7} md={8} lg={9}>
            <Box
              sx={{
                backgroundColor: 'info.700',
                minHeight: '100vh',
                padding: 2,
              }}
            >
              {categories?.length === 0 && (
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  height={'100%'}
                >
                  <Typography
                    sx={{
                      fontSize: 25,
                      fontWeight: '600',
                    }}
                  >
                    Connect To Server
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default POS;
