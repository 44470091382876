import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

const PrivateRoute: FC<Props> = ({ children }) => {
  const session = useSelector((state: any) => state.app.session);

  if (!session) return <Navigate to="/" />;

  return <>{children}</>;
};

export default PrivateRoute;
