import {
  BookingStatus,
  Genders,
  LOCAL_STORAGE,
  Orders,
  Pages,
} from '../constants/enums';
import { Account, Booking } from '../models/GQL_API';
import { ExportStatistics, HeadCell } from '../models/app';
import { Order } from '../models/order';
import * as XLSX from 'xlsx';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { ChipProps } from '@mui/material';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import awsmobile from '../aws-exports';
import AWS from 'aws-sdk';
import { fetchAuthSession } from 'aws-amplify/auth';

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === 'kioskdev' ||
    // [::1] is the IPv6 kioskdev address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ) ||
    window.location.hostname.match(
      /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

export function convertToCamelCase(text: string) {
  return text.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeSentence(string: string): string {
  let result: string = '';

  const words: string[] = string.split(' ');

  for (let word of words) {
    result +=
      result.length === 0
        ? capitalizeFirstLetter(word)
        : ' ' + capitalizeFirstLetter(word);
  }

  return result;
}

export function persistBookingDate(date: Date): Date {
  const bookingsDate: string | null = localStorage.getItem(
    LOCAL_STORAGE.BOOKING_DATE,
  );

  if (bookingsDate) return new Date(bookingsDate);

  return date;
}

export function extractSelectedCheckboxes(keyName: string, data: any) {
  const selected: string[] = [];

  for (let key of Object.getOwnPropertyNames(data)) {
    if (key.includes(keyName)) {
      if (data[key] === true) {
        selected.push(key.replace(keyName, ''));
      }
    }
  }
  return selected;
}

export function formatDate(date: any, withTime?: boolean) {
  const now = date ? new Date(date) : new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hour = String(now.getHours()).padStart(2, '0');
  const minute = String(now.getMinutes()).padStart(2, '0');
  const second = String(now.getSeconds()).padStart(2, '0');

  let formattedDate = `${year}-${month}-${day}`;
  if (withTime) formattedDate += ` ${hour}:${minute}:${second}`;
  return formattedDate;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function descendingComparatorLowerCase<
  T extends Record<keyof T, string | number>,
>(a: T, b: T, orderBy: keyof T) {
  const aVal = String(a[orderBy]).toLowerCase();
  const bVal = String(b[orderBy]).toLowerCase();
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === Orders.DSC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function getComparatorLowerCase<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === Orders.DSC
    ? (a, b) => descendingComparatorLowerCase(a, b, orderBy)
    : (a, b) => -descendingComparatorLowerCase(a, b, orderBy);
}

export function stableSort(
  array: any[],
  comparator: any,
  isSortByNameAsNumber: boolean = false,
) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (isSortByNameAsNumber) {
      return parseInt(a[0].name) < parseInt(b[0].name) ? -1 : 1;
    } else {
      if (order !== 0) return order;
      return a[1] - b[1];
    }
  });
  return stabilizedThis.map((el) => el[0]);
}

export const getDomainName = (account: Account) => {
  if (window.location.hostname === 'kioskdev') return 'kioskdev';
  // if (
  //   // window.location.hostname === "main.d1uxgdbmrbcym6.amplifyapp.com" ||
  //   // window.location.hostname === "main.drwxy8igs4w9j.amplifyapp.com" ||
  //   // window.location.hostname === "www.baky.anyware.software" ||
  //   // window.location.hostname === "baky.anyware.software" ||
  //   // window.location.hostname === "www.bakynew.anyware.software" ||
  //   // window.location.hostname === "bakynew.anyware.software" ||
  //   // window.location.hostname === "staging.drwxy8igs4w9j.amplifyapp.com" ||
  //   window.location.hostname ===
  //     "performance-fix.drwxy8igs4w9j.amplifyapp.com" ||
  //   // Production URLs
  //   window.location.hostname === "new-stag.drwxy8igs4w9j.amplifyapp.com" ||
  //   window.location.hostname ===
  //     "production-fix.drwxy8igs4w9j.amplifyapp.com" ||
  //   // Development URLs
  //   window.location.hostname === "development.drwxy8igs4w9j.amplifyapp.com" ||
  //   window.location.hostname === "developpayfix.drwxy8igs4w9j.amplifyapp.com"
  //   // window.location.hostname === "callcenter.drwxy8igs4w9j.amplifyapp.com" ||
  //   // window.location.hostname === "etihad.drwxy8igs4w9j.amplifyapp.com" ||
  //   // window.location.hostname === "dashboard-enhancements.drwxy8igs4w9j.amplifyapp.com" ||
  //   // window.location.hostname ===
  //     // "onlineorderingcreateorder.drwxy8igs4w9j.amplifyapp.com"
  // )
  //   return "anyware";

  // if (
  //   // window.location.hostname === "192.168.42.194:3000" ||
  //   window.location.hostname === "prod.d1uxgdbmrbcym6.amplifyapp.com"
  // )
  //   return "anyware";
  return account.domain;
};
export function persistSelectedConcept(conceptID: string) {
  const selected: string | null = localStorage.getItem(
    LOCAL_STORAGE.SELECTED_CONCEPT,
  );

  return selected ? selected : conceptID;
}

export async function clearBrowser(reload = true) {
  // remove indexedDB
  const indexedDBs = await window.indexedDB.databases();
  const amplifyDB = indexedDBs.find(
    (db: any) => db.name === 'amplify-datastore',
  )?.name;
  if (amplifyDB) window.indexedDB.deleteDatabase(amplifyDB);

  // clear localStorage
  window.localStorage.clear();

  // clear sessionStorage
  window.sessionStorage.clear();

  // reload
  if (reload) window.location.reload();
}

export function currentDate(date: Date | string): Date {
  const newDate: Date = new Date();

  if (newDate.getHours() < 4) {
    let yesterday: Date = new Date(date);
    yesterday.setDate(yesterday.getDate() - 1);

    return yesterday;
  }

  return new Date(date);
}

export function renderPickedDates(
  startDate: Date | undefined,
  endDate: Date | undefined,
  BookingDateStatus: boolean = false,
): string {
  if (!startDate || !endDate) return 'Select Date Range';
  const startWeekday = startDate.toLocaleString('default', {
    weekday: 'short',
  });
  const endWeekday = endDate.toLocaleString('default', {
    weekday: 'short',
  });

  if (BookingDateStatus) {
    if (formatDate(startDate) === formatDate(endDate))
      return `${startWeekday} ${formatDate(startDate)}`;

    return `${startWeekday} ${formatDate(
      startDate,
    )} to ${endWeekday} ${formatDate(endDate)}`;
  } else {
    if (formatDate(startDate) === formatDate(endDate))
      return `${startWeekday} ${formatDate(persistBookingDate(startDate))}`;

    return `${startWeekday} ${formatDate(
      persistBookingDate(startDate),
    )} to ${endWeekday} ${formatDate(persistBookingDate(endDate))}`;
  }
}

export const mergeListOfStringsByDash = (list: string[] | null) => {
  if (list !== null && list.length)
    return list.reduce((prev, current) => `${prev} - ${current}`);

  return '';
};
interface ExportProps {
  slug: string;
  headCells: readonly HeadCell[];
  data: any[];
  rowKeys: readonly string[];
  selected?: Set<string>;
  filename?: string;
  reportName?: string;
  businessDate?: string;
  concepts?: string;
  shifts?: string;
  fromDate?: string;
  toDate?: string;
  statistics?: ExportStatistics;
}

export function exportXLSX(params: ExportProps) {
  const {
    slug,
    headCells,
    data,
    rowKeys,
    selected,
    filename,
    reportName,
    businessDate,
    concepts,
    shifts,
    fromDate,
    toDate,
    statistics,
  } = params;
  const heads: any = [];
  const rows: any = [];

  // console.log({ slug, headCells, data });
  // Report Header
  if (reportName) {
    rows.push(['Report:', reportName]);
  }

  // rows.push(['Business Date:', businessDate ? businessDate : 'All']);
  // rows.push(['Concept:', concepts ? concepts : 'All']);
  // rows.push(['Shifts:', shifts ? shifts : 'All']);
  rows.push(['Start Date:', fromDate ? fromDate : new Date()]);
  rows.push(['End Date:', toDate ? toDate : new Date()]);

  rows.push([]); // Add an empty row for spacing
  // rows.push(['Total Revenue:', '12820 EGP']);
  // rows.push([]);

  if (statistics) {
    if (
      statistics.totalRevenue !== null &&
      statistics.totalRevenue !== undefined
    ) {
      rows.push(['Total Revenue', statistics.totalRevenue ?? 0]);
    }
    if (statistics.cash !== null && statistics.cash !== undefined) {
      rows.push(['Cash', statistics.cash ?? 0]);
    }
    if (statistics.visa !== null && statistics.visa !== undefined) {
      rows.push(['Visa', statistics.visa ?? 0]);
    }
    if (
      statistics.complementary !== null &&
      statistics.complementary !== undefined
    ) {
      rows.push(['Complementary', statistics.complementary ?? 0]);
    }
    rows.push([]);
  }

  let exportData = data;

  if (selected) {
    exportData = data.filter((row: any) => selected.has(row.id));
  }

  let headLen =
    headCells[headCells.length - 1].label === 'actions'
      ? headCells.length - 1
      : headCells.length;
  for (let i = 0; i < headLen; i++) {
    // csv format doesn't accept empty symbols
    const label =
      headCells[i].label === '# of Guests'
        ? 'Number of Guests'
        : headCells[i].label;
    if (label === 'Connections') continue;
    if (label === '') continue;
    if (
      label === 'Date' &&
      (slug === Pages.CASHLESS_ALL_TOP_UPS ||
        slug === Pages.CASHLESS_ALL_Transactions)
    )
      continue;
    if (label === 'Item Image' && slug === Pages.ITEM_CONSUMPTION_REPORT)
      continue;

    heads.push(label);
  }
  if (
    !heads.includes(
      'createdByName' &&
        slug !== Pages.CASHLESS_ALL_Transactions &&
        slug !== Pages.ITEM_CONSUMPTION_REPORT,
    ) &&
    slug !== Pages.INVITATIONS_HISTORY
  ) {
    heads.push('createdByName');
  }
  if (
    !heads.includes(
      'createdAt' &&
        slug !== Pages.CASHLESS_ALL_Transactions &&
        slug !== Pages.ITEM_CONSUMPTION_REPORT,
    ) &&
    slug !== Pages.INVITATIONS_HISTORY
  ) {
    heads.push('createdAt');
  }
  rows.push(heads);
  if (slug === Pages.BOOKINGS) {
    for (let row of exportData) {
      const rowItems: any = [];

      if (row.conceptName) {
        rowItems.push(row.conceptName ? row.conceptName : 'N/A');
      }

      // rowItems.push(row.timeSlotName ? row.timeSlotName : "");
      rowItems.push(
        row.slotNames ? mergeListOfStringsByDash(row.slotNames) : 'N/A',
      );
      rowItems.push(
        row.customerName ? capitalizeFirstLetter(row.customerName) : 'N/A',
      );
      rowItems.push(row.customerGroup ? row.customerGroup : 'N/A');
      rowItems.push(row.customerPhone ? row.customerPhone : 'N/A');
      rowItems.push(row.accompaniedCount ? row.accompaniedCount : 'N/A');
      rowItems.push(row.tableName ? row.tableName : 'N/A');
      rowItems.push(row.statusName ? row.statusName : 'N/A');
      rowItems.push(
        isNaN(row.customerPhone)
          ? 'N/A'
          : row.paymentStatus
            ? 'Paid'
            : 'Pending',
      );
      // rowItems.push(row.lastComment ? stripHtml(row.lastComment) : "");
      rowItems.push(
        row.commentsTexts ? mergeListOfStringsByDash(row.commentsTexts) : 'N/A',
      );
      rowItems.push(row.date ? row.date : 'N/A');
      rowItems.push(row.createdBy ? row.createdBy.name : 'Admin');
      rowItems.push(row.createdAt ? row.createdAt.split(',')[0] : 'N/A');

      rows.push(rowItems);
    }
  } else if (slug === Pages.GUESTS) {
    for (let row of exportData) {
      const rowItems: any = [];
      rowItems.push(row?.name ?? 'N/A');
      rowItems.push(row?.phone_number ?? 'N/A');
      rowItems.push(row?.avg_spend ?? 0);
      rowItems.push(row?.avg_ticket_type ?? 'N/A');
      rowItems.push(row?.last_attended_event ?? 'N/A');
      rowItems.push(row?.createdByName ?? 'N/A');
      rowItems.push(formatDate(row?.createdAt ?? 'N/A', true));
      rows.push(rowItems);
    }
  } else if (slug === Pages.CASHLESS_ALL_TOP_UPS) {
    for (let row of exportData) {
      const rowItems: any = [];
      rowItems.push(((row.guest && row?.guest.name) || row.guestName) ?? 'N/A');
      rowItems.push(
        ((row.guest && row?.guest?.phone_number) || row.guestPhoneNumber) ??
          'N/A',
      );
      rowItems.push(
        ((row.guest && row?.guest?.email) || row.guestEmail) ?? 'N/A',
      );
      rowItems.push(((row.event && row?.event?.name) || row.eventName) ?? 'N/A');
      rowItems.push(
        ((row.booking && row?.booking?.eventTicket?.type) || row.ticketType) ??
          'N/A',
      );
      rowItems.push(row?.type ?? 'N/A');
      rowItems.push(row?.operation ?? 'N/A');
      rowItems.push(row?.amount ?? 'N/A');
      rowItems.push(row?.availableBalance ?? 'N/A');
      rowItems.push(row?.paymentChannel ?? 'N/A');
      // rowItems.push(row?.createdAt ?? 'N/A');
      rowItems.push(row?.status ?? 'N/A');
      rowItems.push(row?.paymentMethod ?? 'N/A');
      rowItems.push(row?.createdByName ?? 'N/A');
      rowItems.push(formatDate(row?.createdAt ?? 'N/A', true));
      rows.push(rowItems);
    }
  } else if (slug === Pages.CASHLESS_ALL_Transactions) {
    for (let row of exportData) {
      const rowItems: any = [];
      rowItems.push(row?.guestName ?? 'N/A');
      rowItems.push(row?.guestPhoneNumber ?? 'N/A');
      rowItems.push(row?.guestEmail ?? 'N/A');
      // rowItems.push(row?.pos ?? 'N/A');
      // rowItems.push(row?.checkNumber ?? 'N/A');
      rowItems.push(row?.type ?? 'N/A');
      rowItems.push(row?.event.name ?? 'N/A');
      rowItems.push(row?.orderTotal ?? 'N/A');
      rowItems.push(row?.paidAmount ?? 'N/A');
      // rowItems.push(row?.paymentStatus ?? 'N/A');
      // rowItems.push(row?.createdAt ?? 'N/A');
      rowItems.push(row?.createdByName ?? 'N/A');
      rowItems.push(formatDate(row?.createdAt ?? 'N/A', true));
      rows.push(rowItems);
    }
  } else if (slug === Pages.ITEM_CONSUMPTION_REPORT) {
    for (let row of exportData) {
      const rowItems: any = [];
      rowItems.push(row?.itemName ?? 'N/A');
      rowItems.push(row?.itemQuantity ?? 0);
      rowItems.push(row?.itemPrice.toFixed(2) ?? 0.0);
      rowItems.push((row.itemPrice * row.itemQuantity).toFixed(2) ?? 0.0);
      rowItems.push(row?.event?.name);
      rowItems.push(row?.createdByName);
      rowItems.push(formatDate(row?.createdAt ?? 'N/A', true));
      rows.push(rowItems);
    }
  } else {
    try {
      for (let row of exportData) {
        const rowItems: any = [];
        for (let key of rowKeys) {
          if (key === 'event') {
            rowItems.push(row[key].name);
          } else if (key === 'createdAt') {
            rowItems.push(formatDate(row[key], true));
          } else if (key === 'used') {
            rowItems.push(row[key] === true ? 'Used' : 'Not Used');
          } else {
            rowItems.push(row[key]);
          }
        }
        rows.push(rowItems);
      }
    } catch (e) {
      console.log(e);
    }

  }

  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.aoa_to_sheet(rows);
  // // Example: Set width of column A to 30
  // newWs['!cols'] = [{ wpx: 80 }];
  // // Example: Set height of row 1 to 50
  // newWs['!rows'] = [{ hpx: 100 }];
  XLSX.utils.book_append_sheet(wb, newWs);
  const rawExcel = XLSX.write(wb, { type: 'base64' });

  const encodedUri = encodeURI(rawExcel);
  const link = document.createElement('a');
  link.setAttribute(
    'href',
    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
      encodedUri,
  );
  link.setAttribute('download', `${filename ? filename : slug}.xlsx`);
  document.body.appendChild(link); // Required for FF

  link.click();
}

export function isEquivalentArrays(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    let prob = arr1[i];

    if (arr2.indexOf(prob) === -1) return false;
  }

  return true;
}

export function validatePhone(phone: string) {
  const localAndInternational =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
  return localAndInternational.test(phone);
}

export function calculateAge(birthDate: string) {
  const ageInMilliseconds = Date.now() - new Date(birthDate).getTime();
  const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
  return Math.floor(ageInYears);
}

export function formatTimeAgo(createdAt) {
  const now: any = new Date();
  const createdDate: any = new Date(createdAt);

  const timeDifference = now - createdDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 1) {
    return `${days} days ago`;
  } else if (days === 1) {
    return '1 day ago';
  } else if (hours > 1) {
    return `${hours} hours ago`;
  } else if (hours === 1) {
    return '1 hour ago';
  } else if (minutes > 1) {
    return `${minutes} minutes ago`;
  } else if (minutes === 1) {
    return '1 minute ago';
  } else {
    return 'just now';
  }
}

export function getStatusColorAndIcon(status: any) {
  let icon: any, color: string, presetColor: ChipProps['color'];
  switch (status) {
    case BookingStatus.PENDING:
      icon = AccessTimeIcon;
      color = '#ffa726';
      presetColor = 'warning';
      break;
    case BookingStatus.REJECTED:
      icon = CancelOutlinedIcon;
      color = '#f44336';
      presetColor = 'error';
      break;
    case BookingStatus.PARTIALLY_APPROVED:
      icon = TrackChangesOutlinedIcon;
      color = '#0288d1';
      presetColor = 'info';
      break;
    case BookingStatus.APPROVED:
      icon = CheckCircleOutlineIcon;
      color = '#66bb6a';
      presetColor = 'success';
      break;
    case BookingStatus.NOT_REGISTERED:
      icon = DoNotDisturbIcon;
      color = '#555555';
      presetColor = 'default';
      break;

    default:
      icon = AccessTimeIcon;
      color = '#ffff00';
      presetColor = 'default';
      break;
  }
  return { statusColor: color, StatusIcon: icon, presetColor: presetColor };
}

export function getBookingDescription(booking: Booking, currentId?: string) {
  let {
    status,
    statusUpdatedAt,
    statusUpdatedByName,
    createdAt,
    createdByName,
    statusUpdatedByID,
  } = booking;
  if (status === 'cancelled') {
    console.log({ statusUpdatedAt, statusUpdatedByID, statusUpdatedByName });
  }
  if (!statusUpdatedAt)
    return `created by ${capitalizeSentence(
      createdByName ?? '',
    )} ${formatTimeAgo(createdAt)}`;
  return `${status} by ${
    currentId && currentId === statusUpdatedByID
      ? 'YOU'
      : capitalizeSentence(statusUpdatedByName ?? '')
  } ${formatTimeAgo(statusUpdatedAt)}`;
}

export function getGenderColorAndIcon(gender: any) {
  let icon: any, color: string, fadedColor: string;
  switch (gender) {
    case Genders.MALE:
      icon = ManIcon;
      color = '#3D227E';
      fadedColor = '#A690DA';
      break;
    case Genders.FEMALE:
      icon = WomanIcon;
      color = '#7D164E';
      fadedColor = '#ECACAD';
      break;

    default:
      icon = QuestionMarkIcon;
      color = '#4e4e4e';
      fadedColor = '#CCCCCC';
      break;
  }
  return { color, GenderIcon: icon, fadedColor };
}

export function getAmplifyEnv() {
  // if we don't have aws_user_files_s3_bucket in awsmobile we can use something else that includes the env name
  let envStartIndex = awsmobile.aws_user_files_s3_bucket.lastIndexOf('-');
  let env = awsmobile.aws_user_files_s3_bucket.slice(envStartIndex + 1);
  return env;
}

export async function getToken() {
  try {
    const userSession = await fetchAuthSession();
    // const accessTokenObj = userSession?.getAccessToken();
    // const accessToken = accessTokenObj.getJwtToken();
    console.log({ userSession });
    return userSession?.tokens?.accessToken;
  } catch (err: any) {
    console.log({ err });
  }
}

// the following funtion may need to be changed in the future,
// may be we will find that we should change how lambda return it's response or how we sent it a request
// or may we find better way to get amplify env
export async function invokeLambda(functionName: string, requestBody: any) {
  try {
    AWS.config.region = awsmobile.aws_cognito_region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsmobile.aws_cognito_identity_pool_id,
    });
    let lambda = new AWS.Lambda();

    const token = await getToken();
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    };

    let response = await lambda
      .invoke({
        FunctionName: `${functionName}-${getAmplifyEnv()}`,
        Payload: JSON.stringify(options),
      })
      .promise();

    let payload: any;
    let body: any;
    if (response.Payload) {
      if (typeof response.Payload === 'string') {
        payload = JSON.parse(response.Payload);
      } else {
        // console.log(response);
        throw new Error('the payload is not of type string');
      }
    }

    console.log({ payload });
    if (payload.body) {
      if (typeof payload.body === 'string') {
        body = JSON.parse(payload.body);
        if (body?.errorMessage?.length) throw new Error(body.errorMessage);
        return body;
      } else {
        throw new Error('the body is not of type string');
      }
    } else {
      return payload;
    }
  } catch (error) {
    console.error('Error invoking lambda function:', error);
    throw error;
  }
}

export const formatDateToAmPmFormat = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based, so we add 1
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

  const formattedDate = `${day}-${month}-${year} -@ ${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')} ${period}`;

  return formattedDate;
};

export function sessionTimeout() {
  const currentTimeStamp = localStorage.getItem('lastLogin');
  if (!currentTimeStamp) return;
  const timeStamp = JSON.parse(currentTimeStamp);
  const currentTime = Date.now();
  const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
  if (currentTime - timeStamp > twentyFourHoursInMilliseconds) {
    return true;
  } else {
    return false;
  }
}

export function clearLocalStorageData() {
  // localStorage.removeItem('sessionStored');
  // localStorage.removeItem('lastLogin');
  // localStorage.removeItem('offlineAccount');
  // localStorage.removeItem('offlineFeatures');
  // localStorage.removeItem('offlineUserConcepts');
  // localStorage.removeItem('offlineAdminGroups');
  // localStorage.removeItem('offlineAdminRoles');
  // localStorage.removeItem('roles');
}
export async function clearBrowserDatabase() {
  // Change local storage flag to fire clean cache checking
  localStorage.setItem(LOCAL_STORAGE.VERSION_CHECKING, 'true');
}
export const getCurrentTime = () => new Date().getTime();
export const is24HoursPassed = (storedTime: number) => {
  const currentTime = getCurrentTime();
  const hoursInMilliseconds = 48 * 60 * 60 * 1000; // 24 hours in milliseconds
  return currentTime - storedTime >= hoursInMilliseconds;
};

// Event Helpers
export const generateTicketNumber = () => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const idLength = 7;
  let randomId = '';
  const uniqueCharacters = new Set();
  while (randomId.length < idLength) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    const char = characters.charAt(randomIndex);
    if (!uniqueCharacters.has(char)) {
      randomId += char;
      uniqueCharacters.add(char);
    }
  }
  return randomId;
};
