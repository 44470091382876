import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DateRange from '../DateRange/DateRange';
import { DateRangeProps } from '../../../models/app';
import ActionLoader from '../ActionLoader/ActionLoader';

interface Props {
  title: string;
  selection: string;
  shouldSync: boolean;
  dateRange: DateRangeProps[];
  handleClose: () => void;
  handleChange: (item: any) => void;
}

const DateRangePicker: React.FC<Props> = ({
  title,
  selection,
  shouldSync,
  dateRange,
  handleClose,
  handleChange,
}) => {
  const tablet = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        width: '90%',
        maxWidth: 892,
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        borderRadius: '15px',
        border: '1px solid rebeccapurple',
        overflow: 'hidden',
        '& .rdrDefinedRangesWrapper': {
          display: { xs: 'none', md: 'block' },
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: 'info.300',
          padding: '8px 16px',
          color: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={handleClose}
          sx={{ cursor: 'pointer' }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{
          position: 'relative',
          color: 'black',
        }}
      >
        <Box sx={{ filter: 'invert(1)' }}>
          <DateRange
            setLimit={false}
            state={dateRange}
            handleChange={(item: any) => handleChange(item)}
            months={tablet ? 1 : 2}
          />
        </Box>
        {shouldSync && <ActionLoader position="absolute" />}
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            disableElevation
            variant="text"
            size="small"
            onClick={handleClose}
            sx={{ borderRadius: '5pxf' }}
          >
            Cancel
          </Button>
          {(selection === 'Multiple' || selection === 'range') && (
            <Button
              disableElevation
              variant="text"
              size="small"
              onClick={handleClose}
              sx={{ ml: 2, borderRadius: '5px' }}
            >
              OK
            </Button>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default DateRangePicker;
