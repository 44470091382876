import {
  CreateVariables,
  FlagBulkTrashVariables,
  FlagUpdateVariables,
  Option,
  FlagGetVariables,
  FlagListingVariables,
} from './../models/app';
import { GraphQLQuery, generateClient } from 'aws-amplify/api';
import { useDispatch, useSelector } from 'react-redux';
import { setListing, setNextToken, setSelected } from '../store/ducks/flag';
import { HeadCell } from '../models/dataTable';
import useApp from './useApp';
import { Flag, ModelFlagFilterInput, UpdateFlagInput } from '../models/GQL_API';
import { CreateFlagInput } from '../models/GQL_API';
import { onCreateFlag } from '../graphql/subscriptions';
import { getFlag, listFlags } from '../graphql/queries';
import { createFlag, deleteFlag, updateFlag } from '../graphql/mutations';

let client = generateClient();
const useFlag = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]['nextToken'],
  );

  const flagsListing = useSelector(
    (state: any) => state[`${listingName}`]['listing'],
  );
  async function fetch(params: FlagListingVariables) {
    const { accountID, searchText, startIndex, limit, flagsIDs } = params;

    try {
      // const listing = await DataStore.query(
      //   Flag as any,
      //   (model: any) => {
      //     model.accountID('eq', accountID).deleted('eq', '0');

      //     if (searchText.length > 0)
      //       model.message('contains', searchText.toLowerCase());

      //     return model;
      //   },
      //   {
      //     page: startIndex / limit,
      //     limit: limit,
      //     sort: (s) => s.createdAt(SortDirection.DESCENDING),
      //   },
      // );
      const filter: ModelFlagFilterInput = {
        deleted: { eq: '0' },
        accountID: { eq: accountID },
      };
      filter.or = [];
      if (searchText) filter.name = { contains: searchText.toLowerCase() };
      if (flagsIDs && flagsIDs.length > 0) {
        for (const flagsID of flagsIDs) {
          filter.or.push({ id: { eq: flagsID } });
        }
      }

      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }
      const groupsList: any = await client.graphql<Flag>({
        query: listFlags,
        variables: {
          filter,
          limit: 100000,
          nextToken: nextToken,
          deleted: '0',
        },
        authMode: 'userPool',
      });
      const currentNextToken = groupsList.data.listFlags.nextToken;
      const responseListing = groupsList.data.listFlags.items;

      let listing = [...flagsListing, ...responseListing];
      // if (responseListing?.length > 0) {
      dispatch(setListing(listing));
      // }
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function fetchAll(params: FlagListingVariables) {
    const { accountID, searchText, startIndex, limit, flagsIDs } = params;

    try {
      const filter: ModelFlagFilterInput = {
        deleted: { eq: '0' },
        accountID: { eq: accountID },
      };
      filter.or = [];
      if (searchText) filter.name = { contains: searchText.toLowerCase() };
      if (flagsIDs && flagsIDs.length > 0) {
        for (const flagsID of flagsIDs) {
          filter.or.push({ id: { eq: flagsID } });
        }
      }

      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }
      const groupsList: any = await client.graphql<Flag>({
        query: listFlags,
        variables: { filter, limit: 100000, deleted: '0' },
        authMode: 'userPool',
      });
      const responseListing = groupsList.data.listFlags.items;
      // console.log({ responseListing });

      dispatch(setListing(responseListing));
      return responseListing;
    } catch (err: Error | any) {
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: FlagGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Flag) => model.id === id);

      return model ? model.name : '';
    }

    return '';
  };

  async function get(params: FlagGetVariables) {
    const { id, listing } = params;

    // const single: Flag | undefined =
    //   listing.length === 0
    //     ? await DataStore.query(Flag as any, id)
    //     : listing.find((model: Flag) => model.id === id);

    let single: Flag | undefined;
    if (listing.length !== 0) {
      single = listing.find((resource: any) => resource.id === id);
    }

    if (single === undefined) {
      const listing = await client.graphql({
        query: getFlag,
        variables: { id },
        authMode: 'userPool',
      });
      single = listing.data.getFlag!;
    }
    return single;
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const createInput: CreateFlagInput = {
        accountID: data.accountID,
        name: data.name.toLowerCase(),
        icon: data.icon ? data.icon : '',
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.color) createInput.color = data.color;

      // await DataStore.save(new Flag(createInput as any));

      await client.graphql({
        query: createFlag,
        variables: { input: createInput },
        authMode: 'userPool',
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: FlagUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      // await DataStore.save(
      //   Flag.copyOf(original!, (updated) => {
      //     updated.name = data.name ? data.name.toLowerCase() : original!.name;
      //     updated.icon = data.icon ? data.icon : original!.icon;
      //     updated.color = data.color ? data.color : original!.color;
      //   }),
      // );

      let updateInput: UpdateFlagInput = {
        id: original.id,
        accountID: data.accountID ? data.accountID : original.accountID,
        name: data.name ? data.name : original.name,
        icon: data.icon ? data.icon : original.icon,
        color: data.color ? data.color : original.color,
        customers: data.customers ? data.accountID : original.customers,
        _version: original._version,
      };

      await client.graphql({
        query: updateFlag,
        variables: { input: updateInput },
        authMode: 'userPool',
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: FlagGetVariables) {
    try {
      const original = await get(params);
      const { id, listing } = params;

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      // await DataStore.save(
      //   Flag.copyOf(original!, (updated) => {
      //     updated.deleted = '1';
      //   }),
      // );

      await client.graphql({
        query: updateFlag,
        variables: {
          input: { id: original.id, deleted: '1', _version: original._version },
        },
        authMode: 'userPool',
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: FlagBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: FlagGetVariables) {
    const { id, listing } = params;

    try {
      // await DataStore.delete(id as any);

      await client.graphql({
        query: deleteFlag,
        variables: { input: { id } },
        authMode: 'userPool',
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: Flag[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'color',
      numeric: false,
      disablePadding: false,
      label: 'Color',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  const dataCells: readonly string[] = ['name', 'color'];

  const api: any = {};

  api[`${listingName}CreateSubscription`] = onCreateFlag;

  api[`${listingName}Options`] = options;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: Flag[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = flagsListing;
  return api;
};

export default useFlag;
