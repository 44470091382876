import { generateClient } from 'aws-amplify/api';
import { useDispatch } from 'react-redux';
import {
  CreateVariables,
  GateBulkTrashVariables,
  GateGetVariables,
  GateListingVariables,
  GateUpdateVariables,
  Option,
} from './../models/app';
import { createGate, updateGate } from '../graphql/mutations';
import { GateByEventID, getGate } from '../graphql/queries';
import { onCreateGate } from '../graphql/subscriptions';
import {
  CreateGateInput,
  Gate,
  ModelGateFilterInput,
  ModelSortDirection,
  UpdateGateInput,
} from '../models/GQL_API';
import { HeadCell } from '../models/dataTable';
import { setListing } from '../store/ducks/gate';
import useApp from './useApp';

let client = generateClient();

const useGate = (listingName: string, singleName: string) => {
  const { showConfirm, showError } = useApp();
  const dispatch = useDispatch();

  async function fetch(params: GateListingVariables) {
    const { eventID } = params;

    try {
      const filter: ModelGateFilterInput = {
        deleted: { eq: '0' },
      };

      const listing = await client.graphql({
        query: GateByEventID,
        variables: {
          filter,
          limit: 100000,
          eventId: eventID,
          sortDirection: ModelSortDirection.ASC,
        },
        authMode: 'userPool',
      });
      const gates = listing.data.GateByEventID.items;
      dispatch(setListing(gates));
      return gates;
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function get(params: GateGetVariables) {
    const { id, listing } = params;

    try {
      const single = await client.graphql({
        query: getGate,
        variables: { id },
        authMode: 'userPool',
      });

      return single.data.getGate;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.eventID) {
      const error = new Error(`Cannot create ${singleName} without eventID`);
      return showError(error);
    }

    try {
      const createInput: CreateGateInput = {
        name: data.name,
        checkpoints: data.checkpoints ?? [],
        tickets: data.tickets ?? [],
        eventId: data.eventID,
        admins: data.admins ?? [],
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      const createdGate = await client.graphql({
        query: createGate,
        variables: { input: createInput },
        authMode: 'userPool',
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
      throw err;
    }
  }

  async function update(params: GateUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });
      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      if (data.checkpoints) {
        data.checkpoints.forEach(
          (checkpoint: any) => delete checkpoint.__typename,
        );
      } else if (original.checkpoints) {
        original?.checkpoints.forEach(
          (checkpoint: any) => delete checkpoint.__typename,
        );
      }

      let updateInput: UpdateGateInput = {
        id: original.id,
        name: data.name ? data.name : original.name,
        checkpoints: data.checkpoints ? data.checkpoints : original.checkpoints,
        tickets: data.tickets ? data.tickets : original.tickets,
        admins: data.admins ? data.admins : original.admins,
        deleted: data.deleted ? data.deleted : original.deleted,
        _version: original._version,
      };

      await client.graphql({
        query: updateGate,
        variables: { input: updateInput },
        authMode: 'userPool',
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
      throw err;
    }
  }

  async function trash(params: GateGetVariables) {
    try {
      const original = await get(params);
      const { id, listing } = params;

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      await client.graphql({
        query: updateGate,
        variables: {
          input: { id: original.id, deleted: '1', _version: original._version },
        },
        authMode: 'userPool',
      });
      //   dispatch(setListing(listing.filter((model: any) => model.id !== id)));
    } catch (err) {
      showError(err);
      console.log('Error while moving Ticket to trash');
    }
  }

  async function bulkTrash(params: GateBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  function options(listing: Gate[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  const dataCells: readonly string[] = ['name'];

  const api: any = {};

  api[`${listingName}CreateSubscription`] = onCreateGate;

  api[`${listingName}Options`] = options;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;

  return api;
};

export default useGate;
