import useAccount from './useAccount';
import useComment from './useComment';
import useConcept from './useConcept';
import useFeature from './useFeature';
import useFlag from './useFlag';
import useLanguage from './useLanguage';
import useTimeline from './useTimeline';
import useAttachment from './useAttachment';
import useBooking from './useBooking';
import useAdminRole from './useAdminRole';
import useAdminGroup from './useAdminGroup';
import useUserConcepts from './useUserConcepts';
import useTransaction from './useTransaction';
// import usePaymentStat from "./usePaymentStat";
import useGuest from './useGuest';
import useGuestGroup from './useGuestGroup';
import useEvent from './useEvent';
import useInvitation from './useInvitaion';
import useRejectionReasons from './useRejectionReasons';
import useCashlessTransaction from './useCashlessTransaction';
import useCashlessTopups from './useCashlessTopups';
import useItemConsumption from './useItemConsumption';
import useGate from './useGate';
import useInvitationLimit from './useInvitationLimit';
import useInvitationHistory from './useInvitationHistory';
import useHostScans from './useHostScans';

const useResource = () => {
  const accountsApi = useAccount('accounts', 'account');
  const conceptsApi = useConcept('concepts', 'concept');
  const featuresApi = useFeature('features', 'feature');
  const flagsApi = useFlag('flags', 'flag');
  const languagesApi = useLanguage('languages', 'language');
  // const usersApi = useUser("guests", "guest");
  const commentsApi = useComment('comments', 'comment');
  const timeLinesApi = useTimeline('timelines', 'timeline');
  const attachmentsApi = useAttachment('attachments', 'attachment');
  const bookingsApi = useBooking('bookings', 'booking');
  const adminRolesApi = useAdminRole('adminRoles', 'adminRole');
  const adminGroupsApi = useAdminGroup('adminGroups', 'adminGroup');
  const userConceptsApi = useUserConcepts('userConcepts', 'userConcept');
  const guestsApi = useGuest('guests', 'guest');
  const guestGroupsApi = useGuestGroup('guestGroups', 'guestGroup');
  const eventApi = useEvent('events', 'event');
  const invitationApi = useInvitation('invitations', 'invitation');
  const cashlessTransactionApi = useCashlessTransaction(
    'cashlessTransactions',
    'cashlessTransaction',
  );
  const cashlessTopupsApi = useCashlessTopups(
    'cashlessTopups',
    'cashlessTopup',
  );
  const rejectionReasonsApi = useRejectionReasons(
    'rejectionReasons',
    'rejectionReason',
  );
  const itemConsumptionApi = useItemConsumption(
    'itemConsumptions',
    'itemConsumption',
  );
  const invitationsLimitApi = useInvitationLimit('invitations', 'invitation');
  const invitationsHistoryApi = useInvitationHistory(
    'invitationsHistory',
    'invitationHistory',
  );
  const ticketsScanApi = useHostScans('hostScans', 'hostScan');

  const gateAPI = useGate('gates', 'gate');
  const transactionsApi = useTransaction('transactions', 'transaction');
  // const paymentStatsApi = usePaymentStat("paymentStats", "paymentStat");

  return {
    ...adminGroupsApi,
    ...adminRolesApi,
    ...accountsApi,
    ...conceptsApi,
    ...featuresApi,
    ...flagsApi,
    ...languagesApi,
    // ...usersApi,
    ...commentsApi,
    ...timeLinesApi,
    ...attachmentsApi,
    ...bookingsApi,
    ...userConceptsApi,
    ...transactionsApi,
    // ...paymentStatsApi,
    ...guestsApi,
    ...guestGroupsApi,
    ...eventApi,
    ...invitationApi,
    ...rejectionReasonsApi,
    ...cashlessTransactionApi,
    ...cashlessTopupsApi,
    ...itemConsumptionApi,
    ...gateAPI,
    ...invitationsLimitApi,
    ...invitationsHistoryApi,
    ...ticketsScanApi,
  };
};

export default useResource;
