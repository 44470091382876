/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAccountInput = {
  id?: string | null,
  domain: string,
  siteTitle: string,
  guestsCount?: number | null,
  tagline: string,
  description: string,
  siteAddress: string,
  defaultLanguage: string,
  languages: Array< string | null >,
  features: Array< string | null >,
  status: string,
  socialLinks?: Array< string | null > | null,
  currency?: string | null,
  merchantVersion?: string | null,
  merchantID?: string | null,
  merchantIframe?: string | null,
  merchantIntegrationNumber?: string | null,
  merchantAPIKey?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
  accountLogoId?: string | null,
};

export type ModelAccountConditionInput = {
  domain?: ModelStringInput | null,
  siteTitle?: ModelStringInput | null,
  guestsCount?: ModelIntInput | null,
  tagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  siteAddress?: ModelStringInput | null,
  defaultLanguage?: ModelStringInput | null,
  languages?: ModelStringInput | null,
  features?: ModelStringInput | null,
  status?: ModelStringInput | null,
  socialLinks?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  merchantVersion?: ModelStringInput | null,
  merchantID?: ModelStringInput | null,
  merchantIframe?: ModelStringInput | null,
  merchantIntegrationNumber?: ModelStringInput | null,
  merchantAPIKey?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  accountLogoId?: ModelIDInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  logo?: Attachment | null,
  domain: string,
  siteTitle: string,
  guestsCount?: number | null,
  tagline: string,
  description: string,
  siteAddress: string,
  defaultLanguage: string,
  languages: Array< string | null >,
  features: Array< string | null >,
  status: string,
  socialLinks?: Array< string | null > | null,
  currency?: string | null,
  merchantVersion?: string | null,
  merchantID?: string | null,
  merchantIframe?: string | null,
  merchantIntegrationNumber?: string | null,
  merchantAPIKey?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  accountLogoId?: string | null,
};

export type Attachment = {
  __typename: "Attachment",
  id: string,
  mediaID: string,
  fileUrl: string,
  filename: string,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAccountInput = {
  id: string,
  domain?: string | null,
  siteTitle?: string | null,
  guestsCount?: number | null,
  tagline?: string | null,
  description?: string | null,
  siteAddress?: string | null,
  defaultLanguage?: string | null,
  languages?: Array< string | null > | null,
  features?: Array< string | null > | null,
  status?: string | null,
  socialLinks?: Array< string | null > | null,
  currency?: string | null,
  merchantVersion?: string | null,
  merchantID?: string | null,
  merchantIframe?: string | null,
  merchantIntegrationNumber?: string | null,
  merchantAPIKey?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
  accountLogoId?: string | null,
};

export type DeleteAccountInput = {
  id: string,
  _version?: number | null,
};

export type CreateConceptInput = {
  id?: string | null,
  accountID: string,
  name: string,
  description?: string | null,
  logo?: string | null,
  type?: string | null,
  location?: string | null,
  precedence?: number | null,
  longitude?: string | null,
  latitude?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelConceptConditionInput = {
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  type?: ModelStringInput | null,
  location?: ModelStringInput | null,
  precedence?: ModelIntInput | null,
  longitude?: ModelStringInput | null,
  latitude?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelConceptConditionInput | null > | null,
  or?: Array< ModelConceptConditionInput | null > | null,
  not?: ModelConceptConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Concept = {
  __typename: "Concept",
  id: string,
  accountID: string,
  name: string,
  description?: string | null,
  logo?: string | null,
  type?: string | null,
  location?: string | null,
  precedence?: number | null,
  longitude?: string | null,
  latitude?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateConceptInput = {
  id: string,
  accountID?: string | null,
  name?: string | null,
  description?: string | null,
  logo?: string | null,
  type?: string | null,
  location?: string | null,
  precedence?: number | null,
  longitude?: string | null,
  latitude?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteConceptInput = {
  id: string,
  _version?: number | null,
};

export type CreateLanguageInput = {
  id?: string | null,
  name: string,
  code: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelLanguageConditionInput = {
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelLanguageConditionInput | null > | null,
  or?: Array< ModelLanguageConditionInput | null > | null,
  not?: ModelLanguageConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Language = {
  __typename: "Language",
  id: string,
  name: string,
  code: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateLanguageInput = {
  id: string,
  name?: string | null,
  code?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteLanguageInput = {
  id: string,
  _version?: number | null,
};

export type CreateFeatureInput = {
  id?: string | null,
  name: string,
  icon?: string | null,
  slug: string,
  precedence: string,
  parent?: string | null,
  private?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelFeatureConditionInput = {
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  parent?: ModelStringInput | null,
  private?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelFeatureConditionInput | null > | null,
  or?: Array< ModelFeatureConditionInput | null > | null,
  not?: ModelFeatureConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Feature = {
  __typename: "Feature",
  id: string,
  name: string,
  icon?: string | null,
  slug: string,
  precedence: string,
  parent?: string | null,
  private?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateFeatureInput = {
  id: string,
  name?: string | null,
  icon?: string | null,
  slug?: string | null,
  precedence?: string | null,
  parent?: string | null,
  private?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteFeatureInput = {
  id: string,
  _version?: number | null,
};

export type CreateAdminRoleInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelAdminRoleConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAdminRoleConditionInput | null > | null,
  or?: Array< ModelAdminRoleConditionInput | null > | null,
  not?: ModelAdminRoleConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type AdminRole = {
  __typename: "AdminRole",
  id: string,
  name?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAdminRoleInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteAdminRoleInput = {
  id: string,
  _version?: number | null,
};

export type CreateAdminGroupInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  roles?: Array< string | null > | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelAdminGroupConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  roles?: ModelIDInput | null,
  users?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAdminGroupConditionInput | null > | null,
  or?: Array< ModelAdminGroupConditionInput | null > | null,
  not?: ModelAdminGroupConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type AdminGroup = {
  __typename: "AdminGroup",
  id: string,
  name?: string | null,
  description?: string | null,
  roles?: Array< string | null > | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAdminGroupInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  roles?: Array< string | null > | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteAdminGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateAttachmentInput = {
  id?: string | null,
  mediaID: string,
  fileUrl: string,
  filename: string,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelAttachmentConditionInput = {
  mediaID?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAttachmentConditionInput | null > | null,
  or?: Array< ModelAttachmentConditionInput | null > | null,
  not?: ModelAttachmentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateAttachmentInput = {
  id: string,
  mediaID?: string | null,
  fileUrl?: string | null,
  filename?: string | null,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteAttachmentInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserConceptsInput = {
  id?: string | null,
  defaultConcept?: string | null,
  concepts?: Array< string | null > | null,
  conceptsRoles?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelUserConceptsConditionInput = {
  defaultConcept?: ModelIDInput | null,
  concepts?: ModelIDInput | null,
  conceptsRoles?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelUserConceptsConditionInput | null > | null,
  or?: Array< ModelUserConceptsConditionInput | null > | null,
  not?: ModelUserConceptsConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UserConcepts = {
  __typename: "UserConcepts",
  id: string,
  defaultConcept?: string | null,
  concepts?: Array< string | null > | null,
  conceptsRoles?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserConceptsInput = {
  id: string,
  defaultConcept?: string | null,
  concepts?: Array< string | null > | null,
  conceptsRoles?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteUserConceptsInput = {
  id: string,
  _version?: number | null,
};

export type CreateGuestInput = {
  id?: string | null,
  name?: string | null,
  isNameEdited?: boolean | null,
  username?: string | null,
  email?: string | null,
  phone_number?: string | null,
  guestGroupID?: string | null,
  guestGroupName?: string | null,
  sub?: string | null,
  facebookSub?: string | null,
  guest_avatar?: string | null,
  avg_spend?: number | null,
  avg_ticket_type?: string | null,
  numberOfTickets?: number | null,
  connections?: string | null,
  last_attended_event?: string | null,
  last_attended_event_Name?: string | null,
  gender?: string | null,
  isGenderEdited?: boolean | null,
  group?: string | null,
  faceBookID?: string | null,
  appPassword?: string | null,
  birthdate?: string | null,
  isBirthdateEdited?: boolean | null,
  isVerified?: boolean | null,
  images?: Array< string | null > | null,
  address?: string | null,
  totalEvents?: number | null,
  flags?: Array< string | null > | null,
  frontPersonalID?: string | null,
  isFrontPersonalIDEdited?: boolean | null,
  passportImage?: string | null,
  isPassportImageEdited?: boolean | null,
  backPersonalID?: string | null,
  isBackPersonalIDEdited?: boolean | null,
  faceBookProfileLink?: string | null,
  instagramProfileLink?: string | null,
  nationality?: string | null,
  balance?: number | null,
  orderedItems?: Array< OrderedItemInput | null > | null,
  isBlocked?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type OrderedItemInput = {
  id?: string | null,
  name?: string | null,
  quantity: number,
  price: number,
};

export type ModelGuestConditionInput = {
  name?: ModelStringInput | null,
  isNameEdited?: ModelBooleanInput | null,
  username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  guestGroupID?: ModelIDInput | null,
  guestGroupName?: ModelStringInput | null,
  sub?: ModelStringInput | null,
  facebookSub?: ModelStringInput | null,
  guest_avatar?: ModelStringInput | null,
  avg_spend?: ModelFloatInput | null,
  avg_ticket_type?: ModelStringInput | null,
  numberOfTickets?: ModelIntInput | null,
  connections?: ModelStringInput | null,
  last_attended_event?: ModelStringInput | null,
  last_attended_event_Name?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  isGenderEdited?: ModelBooleanInput | null,
  group?: ModelStringInput | null,
  faceBookID?: ModelIDInput | null,
  appPassword?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  isBirthdateEdited?: ModelBooleanInput | null,
  isVerified?: ModelBooleanInput | null,
  images?: ModelStringInput | null,
  address?: ModelStringInput | null,
  totalEvents?: ModelIntInput | null,
  flags?: ModelIDInput | null,
  frontPersonalID?: ModelStringInput | null,
  isFrontPersonalIDEdited?: ModelBooleanInput | null,
  passportImage?: ModelStringInput | null,
  isPassportImageEdited?: ModelBooleanInput | null,
  backPersonalID?: ModelStringInput | null,
  isBackPersonalIDEdited?: ModelBooleanInput | null,
  faceBookProfileLink?: ModelStringInput | null,
  instagramProfileLink?: ModelStringInput | null,
  nationality?: ModelStringInput | null,
  balance?: ModelFloatInput | null,
  isBlocked?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelGuestConditionInput | null > | null,
  or?: Array< ModelGuestConditionInput | null > | null,
  not?: ModelGuestConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Guest = {
  __typename: "Guest",
  id: string,
  name?: string | null,
  isNameEdited?: boolean | null,
  username?: string | null,
  email?: string | null,
  phone_number?: string | null,
  guestGroupID?: string | null,
  guestGroupName?: string | null,
  sub?: string | null,
  facebookSub?: string | null,
  guest_avatar?: string | null,
  avg_spend?: number | null,
  avg_ticket_type?: string | null,
  numberOfTickets?: number | null,
  connections?: string | null,
  last_attended_event?: string | null,
  last_attended_event_Name?: string | null,
  gender?: string | null,
  isGenderEdited?: boolean | null,
  group?: string | null,
  faceBookID?: string | null,
  appPassword?: string | null,
  birthdate?: string | null,
  isBirthdateEdited?: boolean | null,
  isVerified?: boolean | null,
  images?: Array< string | null > | null,
  address?: string | null,
  totalEvents?: number | null,
  flags?: Array< string | null > | null,
  frontPersonalID?: string | null,
  isFrontPersonalIDEdited?: boolean | null,
  passportImage?: string | null,
  isPassportImageEdited?: boolean | null,
  backPersonalID?: string | null,
  isBackPersonalIDEdited?: boolean | null,
  faceBookProfileLink?: string | null,
  instagramProfileLink?: string | null,
  nationality?: string | null,
  balance?: number | null,
  orderedItems?:  Array<OrderedItem | null > | null,
  isBlocked?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type OrderedItem = {
  __typename: "OrderedItem",
  id?: string | null,
  name?: string | null,
  quantity: number,
  price: number,
};

export type UpdateGuestInput = {
  id: string,
  name?: string | null,
  isNameEdited?: boolean | null,
  username?: string | null,
  email?: string | null,
  phone_number?: string | null,
  guestGroupID?: string | null,
  guestGroupName?: string | null,
  sub?: string | null,
  facebookSub?: string | null,
  guest_avatar?: string | null,
  avg_spend?: number | null,
  avg_ticket_type?: string | null,
  numberOfTickets?: number | null,
  connections?: string | null,
  last_attended_event?: string | null,
  last_attended_event_Name?: string | null,
  gender?: string | null,
  isGenderEdited?: boolean | null,
  group?: string | null,
  faceBookID?: string | null,
  appPassword?: string | null,
  birthdate?: string | null,
  isBirthdateEdited?: boolean | null,
  isVerified?: boolean | null,
  images?: Array< string | null > | null,
  address?: string | null,
  totalEvents?: number | null,
  flags?: Array< string | null > | null,
  frontPersonalID?: string | null,
  isFrontPersonalIDEdited?: boolean | null,
  passportImage?: string | null,
  isPassportImageEdited?: boolean | null,
  backPersonalID?: string | null,
  isBackPersonalIDEdited?: boolean | null,
  faceBookProfileLink?: string | null,
  instagramProfileLink?: string | null,
  nationality?: string | null,
  balance?: number | null,
  orderedItems?: Array< OrderedItemInput | null > | null,
  isBlocked?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteGuestInput = {
  id: string,
  _version?: number | null,
};

export type CreateGuestGroupInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  color?: string | null,
  guests?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelGuestGroupConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  color?: ModelStringInput | null,
  guests?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelGuestGroupConditionInput | null > | null,
  or?: Array< ModelGuestGroupConditionInput | null > | null,
  not?: ModelGuestGroupConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type GuestGroup = {
  __typename: "GuestGroup",
  id: string,
  name?: string | null,
  description?: string | null,
  color?: string | null,
  guests?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateGuestGroupInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  color?: string | null,
  guests?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteGuestGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateTimelineInput = {
  id?: string | null,
  actionName: string,
  oldStatus: string,
  newStatus: string,
  bookingId?: string | null,
  customerId?: string | null,
  isScanned?: string | null,
  type?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelTimelineConditionInput = {
  actionName?: ModelStringInput | null,
  oldStatus?: ModelStringInput | null,
  newStatus?: ModelStringInput | null,
  bookingId?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  isScanned?: ModelStringInput | null,
  type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTimelineConditionInput | null > | null,
  or?: Array< ModelTimelineConditionInput | null > | null,
  not?: ModelTimelineConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Timeline = {
  __typename: "Timeline",
  id: string,
  actionName: string,
  oldStatus: string,
  newStatus: string,
  bookingId?: string | null,
  customerId?: string | null,
  isScanned?: string | null,
  type?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTimelineInput = {
  id: string,
  actionName?: string | null,
  oldStatus?: string | null,
  newStatus?: string | null,
  bookingId?: string | null,
  customerId?: string | null,
  isScanned?: string | null,
  type?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteTimelineInput = {
  id: string,
  _version?: number | null,
};

export type CreateFlagInput = {
  id?: string | null,
  accountID: string,
  name: string,
  icon: string,
  color?: string | null,
  customers?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelFlagConditionInput = {
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  color?: ModelStringInput | null,
  customers?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelFlagConditionInput | null > | null,
  or?: Array< ModelFlagConditionInput | null > | null,
  not?: ModelFlagConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Flag = {
  __typename: "Flag",
  id: string,
  accountID: string,
  name: string,
  icon: string,
  color?: string | null,
  customers?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateFlagInput = {
  id: string,
  accountID?: string | null,
  name?: string | null,
  icon?: string | null,
  color?: string | null,
  customers?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteFlagInput = {
  id: string,
  _version?: number | null,
};

export type CreateCommentInput = {
  id?: string | null,
  message: string,
  customerId?: string | null,
  bookingId?: string | null,
  replyTo?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByImg?: string | null,
  createdByName: string,
  _version?: number | null,
};

export type ModelCommentConditionInput = {
  message?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  bookingId?: ModelStringInput | null,
  replyTo?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByImg?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  message: string,
  customerId?: string | null,
  bookingId?: string | null,
  replyTo?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByImg?: string | null,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCommentInput = {
  id: string,
  message?: string | null,
  customerId?: string | null,
  bookingId?: string | null,
  replyTo?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByImg?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteCommentInput = {
  id: string,
  _version?: number | null,
};

export type CreateEventInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  disclaimer?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  location?: LocationInput | null,
  todoList?: Array< string | null > | null,
  eventComments?: Array< EventCommentsInput | null > | null,
  map?: string | null,
  image?: string | null,
  largeScreensImage?: string | null,
  mediumScreensImage?: string | null,
  smallScreensImage?: string | null,
  sendEmailToValidGuest?: boolean | null,
  sendSmsToValidGuest?: boolean | null,
  gallery?: Array< string | null > | null,
  visibleTo?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  published: boolean,
  applyGenderRatio?: boolean | null,
  _version?: number | null,
};

export type LocationInput = {
  address?: string | null,
  coordinates?: CoordinatesInput | null,
};

export type CoordinatesInput = {
  lat?: number | null,
  lng?: number | null,
};

export type EventCommentsInput = {
  image?: string | null,
  name?: string | null,
  message?: string | null,
};

export type ModelEventConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  disclaimer?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  todoList?: ModelStringInput | null,
  map?: ModelStringInput | null,
  image?: ModelStringInput | null,
  largeScreensImage?: ModelStringInput | null,
  mediumScreensImage?: ModelStringInput | null,
  smallScreensImage?: ModelStringInput | null,
  sendEmailToValidGuest?: ModelBooleanInput | null,
  sendSmsToValidGuest?: ModelBooleanInput | null,
  gallery?: ModelStringInput | null,
  visibleTo?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  applyGenderRatio?: ModelBooleanInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Event = {
  __typename: "Event",
  id: string,
  name?: string | null,
  description?: string | null,
  disclaimer?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  location?: Location | null,
  todoList?: Array< string | null > | null,
  eventComments?:  Array<EventComments | null > | null,
  map?: string | null,
  image?: string | null,
  largeScreensImage?: string | null,
  mediumScreensImage?: string | null,
  smallScreensImage?: string | null,
  sendEmailToValidGuest?: boolean | null,
  sendSmsToValidGuest?: boolean | null,
  gallery?: Array< string | null > | null,
  visibleTo?: string | null,
  tickets?: ModelEventTicketConnection | null,
  gates?: ModelGateConnection | null,
  invitationLimit?: ModelInvitationLimitConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  published: boolean,
  applyGenderRatio?: boolean | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Location = {
  __typename: "Location",
  address?: string | null,
  coordinates?: Coordinates | null,
};

export type Coordinates = {
  __typename: "Coordinates",
  lat?: number | null,
  lng?: number | null,
};

export type EventComments = {
  __typename: "EventComments",
  image?: string | null,
  name?: string | null,
  message?: string | null,
};

export type ModelEventTicketConnection = {
  __typename: "ModelEventTicketConnection",
  items:  Array<EventTicket | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type EventTicket = {
  __typename: "EventTicket",
  id: string,
  type: string,
  cashlessCredit?: number | null,
  description?: string | null,
  waves?:  Array<Wave | null > | null,
  color?: string | null,
  paymentRules?: string | null,
  approvalRule?: string | null,
  showAll?: boolean | null,
  showOnHold?: boolean | null,
  onHoldDisplayText?: string | null,
  showSoldOut?: boolean | null,
  soldOutDisplayText?: string | null,
  setAvailable?: boolean | null,
  availableAtDate?: string | null,
  allowTransferred?: boolean | null,
  transferredAprroval?: boolean | null,
  transferredSameGender?: boolean | null,
  showUpcomingWave?: boolean | null,
  showUpcomingWaveDisplayText?: string | null,
  eventID: string,
  gateID?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Wave = {
  __typename: "Wave",
  id: string,
  name: string,
  price: number,
  startDate?: string | null,
  endDate?: string | null,
  active?: boolean | null,
  quota?: number | null,
  AutomaticShift?: string | null,
};

export type ModelGateConnection = {
  __typename: "ModelGateConnection",
  items:  Array<Gate | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Gate = {
  __typename: "Gate",
  id: string,
  name: string,
  eventId: string,
  tickets?: Array< string | null > | null,
  admins?: Array< string | null > | null,
  checkpoints?:  Array<Checkpoint | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Checkpoint = {
  __typename: "Checkpoint",
  id: string,
  name: string,
  precedence: number,
  isSkippable?: boolean | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
};

export type ModelInvitationLimitConnection = {
  __typename: "ModelInvitationLimitConnection",
  items:  Array<InvitationLimit | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type InvitationLimit = {
  __typename: "InvitationLimit",
  id: string,
  adminID?: string | null,
  quota?: number | null,
  eventID?: string | null,
  totalQuota?: number | null,
  sentInvitations?:  Array<sentInvitation | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  eventInvitationLimitId?: string | null,
};

export type sentInvitation = {
  __typename: "sentInvitation",
  invitationNumber?: number | null,
  reciver?: string | null,
};

export type UpdateEventInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  disclaimer?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  location?: LocationInput | null,
  todoList?: Array< string | null > | null,
  eventComments?: Array< EventCommentsInput | null > | null,
  map?: string | null,
  image?: string | null,
  largeScreensImage?: string | null,
  mediumScreensImage?: string | null,
  smallScreensImage?: string | null,
  sendEmailToValidGuest?: boolean | null,
  sendSmsToValidGuest?: boolean | null,
  gallery?: Array< string | null > | null,
  visibleTo?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  published?: boolean | null,
  applyGenderRatio?: boolean | null,
  _version?: number | null,
};

export type DeleteEventInput = {
  id: string,
  _version?: number | null,
};

export type CreateEventTicketInput = {
  id?: string | null,
  type: string,
  cashlessCredit?: number | null,
  description?: string | null,
  waves?: Array< WaveInput | null > | null,
  color?: string | null,
  paymentRules?: string | null,
  approvalRule?: string | null,
  showAll?: boolean | null,
  showOnHold?: boolean | null,
  onHoldDisplayText?: string | null,
  showSoldOut?: boolean | null,
  soldOutDisplayText?: string | null,
  setAvailable?: boolean | null,
  availableAtDate?: string | null,
  allowTransferred?: boolean | null,
  transferredAprroval?: boolean | null,
  transferredSameGender?: boolean | null,
  showUpcomingWave?: boolean | null,
  showUpcomingWaveDisplayText?: string | null,
  eventID: string,
  gateID?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type WaveInput = {
  id: string,
  name: string,
  price: number,
  startDate?: string | null,
  endDate?: string | null,
  active?: boolean | null,
  quota?: number | null,
  AutomaticShift?: string | null,
};

export type ModelEventTicketConditionInput = {
  type?: ModelStringInput | null,
  cashlessCredit?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  color?: ModelStringInput | null,
  paymentRules?: ModelStringInput | null,
  approvalRule?: ModelStringInput | null,
  showAll?: ModelBooleanInput | null,
  showOnHold?: ModelBooleanInput | null,
  onHoldDisplayText?: ModelStringInput | null,
  showSoldOut?: ModelBooleanInput | null,
  soldOutDisplayText?: ModelStringInput | null,
  setAvailable?: ModelBooleanInput | null,
  availableAtDate?: ModelStringInput | null,
  allowTransferred?: ModelBooleanInput | null,
  transferredAprroval?: ModelBooleanInput | null,
  transferredSameGender?: ModelBooleanInput | null,
  showUpcomingWave?: ModelBooleanInput | null,
  showUpcomingWaveDisplayText?: ModelStringInput | null,
  eventID?: ModelIDInput | null,
  gateID?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelEventTicketConditionInput | null > | null,
  or?: Array< ModelEventTicketConditionInput | null > | null,
  not?: ModelEventTicketConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateEventTicketInput = {
  id: string,
  type?: string | null,
  cashlessCredit?: number | null,
  description?: string | null,
  waves?: Array< WaveInput | null > | null,
  color?: string | null,
  paymentRules?: string | null,
  approvalRule?: string | null,
  showAll?: boolean | null,
  showOnHold?: boolean | null,
  onHoldDisplayText?: string | null,
  showSoldOut?: boolean | null,
  soldOutDisplayText?: string | null,
  setAvailable?: boolean | null,
  availableAtDate?: string | null,
  allowTransferred?: boolean | null,
  transferredAprroval?: boolean | null,
  transferredSameGender?: boolean | null,
  showUpcomingWave?: boolean | null,
  showUpcomingWaveDisplayText?: string | null,
  eventID?: string | null,
  gateID?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteEventTicketInput = {
  id: string,
  _version?: number | null,
};

export type CreateInvitationLimitInput = {
  id?: string | null,
  adminID?: string | null,
  quota?: number | null,
  eventID?: string | null,
  totalQuota?: number | null,
  sentInvitations?: Array< SentInvitationInput | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
  eventInvitationLimitId?: string | null,
};

export type SentInvitationInput = {
  invitationNumber?: number | null,
  reciver?: string | null,
};

export type ModelInvitationLimitConditionInput = {
  adminID?: ModelStringInput | null,
  quota?: ModelIntInput | null,
  eventID?: ModelIDInput | null,
  totalQuota?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelInvitationLimitConditionInput | null > | null,
  or?: Array< ModelInvitationLimitConditionInput | null > | null,
  not?: ModelInvitationLimitConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  eventInvitationLimitId?: ModelIDInput | null,
};

export type UpdateInvitationLimitInput = {
  id: string,
  adminID?: string | null,
  quota?: number | null,
  eventID?: string | null,
  totalQuota?: number | null,
  sentInvitations?: Array< SentInvitationInput | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
  eventInvitationLimitId?: string | null,
};

export type DeleteInvitationLimitInput = {
  id: string,
  _version?: number | null,
};

export type CreateBookingInput = {
  id?: string | null,
  status?: string | null,
  overallStatus?: string | null,
  bookingGuestId?: string | null,
  bookingMainGuestId?: string | null,
  mainBookingId?: string | null,
  bookingEventId?: string | null,
  bookingEventTicketId?: string | null,
  cashlessCredit?: number | null,
  wave?: string | null,
  waveId: string,
  isMainGuest?: boolean | null,
  isInvited?: boolean | null,
  orderId?: string | null,
  ticketNumber?: string | null,
  redeemed?: boolean | null,
  passedCheckpoints?: Array< CheckpointInput | null > | null,
  statusUpdatedByID?: string | null,
  statusUpdatedByName?: string | null,
  statusUpdatedAt?: string | null,
  specialNeed?: boolean | null,
  phone_number?: string | null,
  guestTicket?: GuestTicketInput | null,
  guestName?: string | null,
  notRegisteredGuests?: Array< string | null > | null,
  hasNotRegisteredGuest?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  rejectionComment?: string | null,
  isPaid?: boolean | null,
  isTransfered?: boolean | null,
  isPendingTransfer?: boolean | null,
  lastGuestId?: string | null,
  lastMainGuestId?: string | null,
  ownerHistory?: Array< string | null > | null,
  paidAmount?: number | null,
  balance?: number | null,
  scannedAt?: string | null,
  scannedByName?: string | null,
  refunded?: boolean | null,
  paymentTransactionId?: string | null,
  paymentParentTransactionId?: string | null,
  origin?: string | null,
  paymentMethod?: string | null,
  testField?: string | null,
  _version?: number | null,
  bookingRejectionReasonId?: string | null,
};

export type CheckpointInput = {
  id: string,
  name: string,
  precedence: number,
  isSkippable?: boolean | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
};

export type GuestTicketInput = {
  number?: string | null,
  redeemed?: boolean | null,
};

export type ModelBookingConditionInput = {
  status?: ModelStringInput | null,
  overallStatus?: ModelStringInput | null,
  bookingGuestId?: ModelIDInput | null,
  bookingMainGuestId?: ModelIDInput | null,
  mainBookingId?: ModelIDInput | null,
  bookingEventId?: ModelIDInput | null,
  bookingEventTicketId?: ModelIDInput | null,
  cashlessCredit?: ModelFloatInput | null,
  wave?: ModelStringInput | null,
  waveId?: ModelIDInput | null,
  isMainGuest?: ModelBooleanInput | null,
  isInvited?: ModelBooleanInput | null,
  orderId?: ModelStringInput | null,
  ticketNumber?: ModelStringInput | null,
  redeemed?: ModelBooleanInput | null,
  statusUpdatedByID?: ModelStringInput | null,
  statusUpdatedByName?: ModelStringInput | null,
  statusUpdatedAt?: ModelStringInput | null,
  specialNeed?: ModelBooleanInput | null,
  phone_number?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  notRegisteredGuests?: ModelStringInput | null,
  hasNotRegisteredGuest?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  rejectionComment?: ModelStringInput | null,
  isPaid?: ModelBooleanInput | null,
  isTransfered?: ModelBooleanInput | null,
  isPendingTransfer?: ModelBooleanInput | null,
  lastGuestId?: ModelIDInput | null,
  lastMainGuestId?: ModelIDInput | null,
  ownerHistory?: ModelIDInput | null,
  paidAmount?: ModelFloatInput | null,
  balance?: ModelFloatInput | null,
  scannedAt?: ModelStringInput | null,
  scannedByName?: ModelStringInput | null,
  refunded?: ModelBooleanInput | null,
  paymentTransactionId?: ModelStringInput | null,
  paymentParentTransactionId?: ModelStringInput | null,
  origin?: ModelStringInput | null,
  paymentMethod?: ModelStringInput | null,
  testField?: ModelStringInput | null,
  and?: Array< ModelBookingConditionInput | null > | null,
  or?: Array< ModelBookingConditionInput | null > | null,
  not?: ModelBookingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  bookingRejectionReasonId?: ModelIDInput | null,
};

export type Booking = {
  __typename: "Booking",
  id: string,
  status?: string | null,
  overallStatus?: string | null,
  bookingGuestId?: string | null,
  bookingMainGuestId?: string | null,
  mainBookingId?: string | null,
  bookingEventId?: string | null,
  bookingEventTicketId?: string | null,
  guest?: Guest | null,
  mainGuest: Guest,
  event: Event,
  eventTicket: EventTicket,
  cashlessCredit?: number | null,
  wave?: string | null,
  waveId: string,
  isMainGuest?: boolean | null,
  isInvited?: boolean | null,
  orderId?: string | null,
  ticketNumber?: string | null,
  redeemed?: boolean | null,
  passedCheckpoints?:  Array<Checkpoint | null > | null,
  statusUpdatedByID?: string | null,
  statusUpdatedByName?: string | null,
  statusUpdatedAt?: string | null,
  specialNeed?: boolean | null,
  phone_number?: string | null,
  guestTicket?: GuestTicket | null,
  guestName?: string | null,
  notRegisteredGuests?: Array< string | null > | null,
  hasNotRegisteredGuest?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  rejectionReason?: RejectReason | null,
  rejectionComment?: string | null,
  isPaid?: boolean | null,
  isTransfered?: boolean | null,
  isPendingTransfer?: boolean | null,
  lastGuestId?: string | null,
  lastGuest?: Guest | null,
  lastMainGuestId?: string | null,
  ownerHistory?: Array< string | null > | null,
  paidAmount?: number | null,
  balance?: number | null,
  scannedAt?: string | null,
  scannedByName?: string | null,
  refunded?: boolean | null,
  paymentTransactionId?: string | null,
  paymentParentTransactionId?: string | null,
  origin?: string | null,
  paymentMethod?: string | null,
  testField?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  bookingRejectionReasonId?: string | null,
};

export type GuestTicket = {
  __typename: "GuestTicket",
  number?: string | null,
  redeemed?: boolean | null,
};

export type RejectReason = {
  __typename: "RejectReason",
  id: string,
  content: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt?: string | null,
  updatedByID?: string | null,
  updatedByName?: string | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateBookingInput = {
  id: string,
  status?: string | null,
  overallStatus?: string | null,
  bookingGuestId?: string | null,
  bookingMainGuestId?: string | null,
  mainBookingId?: string | null,
  bookingEventId?: string | null,
  bookingEventTicketId?: string | null,
  cashlessCredit?: number | null,
  wave?: string | null,
  waveId?: string | null,
  isMainGuest?: boolean | null,
  isInvited?: boolean | null,
  orderId?: string | null,
  ticketNumber?: string | null,
  redeemed?: boolean | null,
  passedCheckpoints?: Array< CheckpointInput | null > | null,
  statusUpdatedByID?: string | null,
  statusUpdatedByName?: string | null,
  statusUpdatedAt?: string | null,
  specialNeed?: boolean | null,
  phone_number?: string | null,
  guestTicket?: GuestTicketInput | null,
  guestName?: string | null,
  notRegisteredGuests?: Array< string | null > | null,
  hasNotRegisteredGuest?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  rejectionComment?: string | null,
  isPaid?: boolean | null,
  isTransfered?: boolean | null,
  isPendingTransfer?: boolean | null,
  lastGuestId?: string | null,
  lastMainGuestId?: string | null,
  ownerHistory?: Array< string | null > | null,
  paidAmount?: number | null,
  balance?: number | null,
  scannedAt?: string | null,
  scannedByName?: string | null,
  refunded?: boolean | null,
  paymentTransactionId?: string | null,
  paymentParentTransactionId?: string | null,
  origin?: string | null,
  paymentMethod?: string | null,
  testField?: string | null,
  _version?: number | null,
  bookingRejectionReasonId?: string | null,
};

export type DeleteBookingInput = {
  id: string,
  _version?: number | null,
};

export type CreateRejectReasonInput = {
  id?: string | null,
  content: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  updatedAt?: string | null,
  updatedByID?: string | null,
  updatedByName?: string | null,
  _version?: number | null,
};

export type ModelRejectReasonConditionInput = {
  content?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedByID?: ModelStringInput | null,
  updatedByName?: ModelStringInput | null,
  and?: Array< ModelRejectReasonConditionInput | null > | null,
  or?: Array< ModelRejectReasonConditionInput | null > | null,
  not?: ModelRejectReasonConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateRejectReasonInput = {
  id: string,
  content?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  updatedAt?: string | null,
  updatedByID?: string | null,
  updatedByName?: string | null,
  _version?: number | null,
};

export type DeleteRejectReasonInput = {
  id: string,
  _version?: number | null,
};

export type CreateInvitationInput = {
  id?: string | null,
  invitationEventId?: string | null,
  cashlessCredit?: number | null,
  balance?: number | null,
  wave?: string | null,
  phone_number?: string | null,
  name?: string | null,
  email?: string | null,
  reason?: string | null,
  secret?: string | null,
  used?: boolean | null,
  scannedAt?: string | null,
  scannedByName?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
  invitationEventTicketId: string,
};

export type ModelInvitationConditionInput = {
  invitationEventId?: ModelIDInput | null,
  cashlessCredit?: ModelFloatInput | null,
  balance?: ModelFloatInput | null,
  wave?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  reason?: ModelStringInput | null,
  secret?: ModelStringInput | null,
  used?: ModelBooleanInput | null,
  scannedAt?: ModelStringInput | null,
  scannedByName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelInvitationConditionInput | null > | null,
  or?: Array< ModelInvitationConditionInput | null > | null,
  not?: ModelInvitationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  invitationEventTicketId?: ModelIDInput | null,
};

export type Invitation = {
  __typename: "Invitation",
  id: string,
  invitationEventId?: string | null,
  event: Event,
  eventTicket: EventTicket,
  cashlessCredit?: number | null,
  balance?: number | null,
  wave?: string | null,
  phone_number?: string | null,
  name?: string | null,
  email?: string | null,
  reason?: string | null,
  secret?: string | null,
  used?: boolean | null,
  scannedAt?: string | null,
  scannedByName?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  invitationEventTicketId: string,
};

export type UpdateInvitationInput = {
  id: string,
  invitationEventId?: string | null,
  cashlessCredit?: number | null,
  balance?: number | null,
  wave?: string | null,
  phone_number?: string | null,
  name?: string | null,
  email?: string | null,
  reason?: string | null,
  secret?: string | null,
  used?: boolean | null,
  scannedAt?: string | null,
  scannedByName?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
  invitationEventTicketId?: string | null,
};

export type DeleteInvitationInput = {
  id: string,
  _version?: number | null,
};

export type CreateTransactionInput = {
  id?: string | null,
  type?: string | null,
  guestId?: string | null,
  guestName?: string | null,
  guestsNames?: Array< string | null > | null,
  guestPhone_number?: string | null,
  eventId: string,
  ticketId?: string | null,
  success?: boolean | null,
  failureReason?: string | null,
  currency?: string | null,
  amount_cents?: string | null,
  refund?: boolean | null,
  refunded_amount_cents?: string | null,
  orderID: string,
  transactionID: string,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
  transactionBookingId?: string | null,
};

export type ModelTransactionConditionInput = {
  type?: ModelStringInput | null,
  guestId?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  guestsNames?: ModelStringInput | null,
  guestPhone_number?: ModelStringInput | null,
  eventId?: ModelStringInput | null,
  ticketId?: ModelStringInput | null,
  success?: ModelBooleanInput | null,
  failureReason?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  amount_cents?: ModelStringInput | null,
  refund?: ModelBooleanInput | null,
  refunded_amount_cents?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  transactionID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTransactionConditionInput | null > | null,
  or?: Array< ModelTransactionConditionInput | null > | null,
  not?: ModelTransactionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  transactionBookingId?: ModelIDInput | null,
};

export type Transaction = {
  __typename: "Transaction",
  id: string,
  type?: string | null,
  guestId?: string | null,
  guestName?: string | null,
  guestsNames?: Array< string | null > | null,
  guestPhone_number?: string | null,
  eventId: string,
  ticketId?: string | null,
  booking?: Booking | null,
  success?: boolean | null,
  failureReason?: string | null,
  currency?: string | null,
  amount_cents?: string | null,
  refund?: boolean | null,
  refunded_amount_cents?: string | null,
  orderID: string,
  transactionID: string,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  transactionBookingId?: string | null,
};

export type UpdateTransactionInput = {
  id: string,
  type?: string | null,
  guestId?: string | null,
  guestName?: string | null,
  guestsNames?: Array< string | null > | null,
  guestPhone_number?: string | null,
  eventId?: string | null,
  ticketId?: string | null,
  success?: boolean | null,
  failureReason?: string | null,
  currency?: string | null,
  amount_cents?: string | null,
  refund?: boolean | null,
  refunded_amount_cents?: string | null,
  orderID?: string | null,
  transactionID?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
  transactionBookingId?: string | null,
};

export type DeleteTransactionInput = {
  id: string,
  _version?: number | null,
};

export type CreateWavesConsumptionInput = {
  waveId: string,
  consumedTickets?: number | null,
  reservedTickets?: number | null,
  totalTickets: number,
  consumed?: string | null,
  eventId: string,
  eventTicketId?: string | null,
  id?: string | null,
  _version?: number | null,
};

export type ModelWavesConsumptionConditionInput = {
  waveId?: ModelStringInput | null,
  consumedTickets?: ModelIntInput | null,
  reservedTickets?: ModelIntInput | null,
  totalTickets?: ModelIntInput | null,
  consumed?: ModelStringInput | null,
  eventId?: ModelStringInput | null,
  eventTicketId?: ModelStringInput | null,
  and?: Array< ModelWavesConsumptionConditionInput | null > | null,
  or?: Array< ModelWavesConsumptionConditionInput | null > | null,
  not?: ModelWavesConsumptionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type WavesConsumption = {
  __typename: "WavesConsumption",
  waveId: string,
  consumedTickets?: number | null,
  reservedTickets?: number | null,
  totalTickets: number,
  consumed?: string | null,
  eventId: string,
  eventTicketId?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateWavesConsumptionInput = {
  waveId?: string | null,
  consumedTickets?: number | null,
  reservedTickets?: number | null,
  totalTickets?: number | null,
  consumed?: string | null,
  eventId?: string | null,
  eventTicketId?: string | null,
  id: string,
  _version?: number | null,
};

export type DeleteWavesConsumptionInput = {
  id: string,
  _version?: number | null,
};

export type CreateCashlessTransactionInput = {
  id?: string | null,
  pos: string,
  checkNumber: string,
  orderTotal: number,
  paidAmount: number,
  paymentStatus: string,
  guestId: string,
  guestPhoneNumber?: string | null,
  guestEmail?: string | null,
  guestName: string,
  type?: string | null,
  bookingId?: string | null,
  invitationId?: string | null,
  eventId: string,
  orderedItems?: Array< OrderedItemInput | null > | null,
  syncOperationId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type ModelCashlessTransactionConditionInput = {
  pos?: ModelStringInput | null,
  checkNumber?: ModelStringInput | null,
  orderTotal?: ModelFloatInput | null,
  paidAmount?: ModelFloatInput | null,
  paymentStatus?: ModelStringInput | null,
  guestId?: ModelIDInput | null,
  guestPhoneNumber?: ModelStringInput | null,
  guestEmail?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  type?: ModelStringInput | null,
  bookingId?: ModelIDInput | null,
  invitationId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  syncOperationId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCashlessTransactionConditionInput | null > | null,
  or?: Array< ModelCashlessTransactionConditionInput | null > | null,
  not?: ModelCashlessTransactionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type CashlessTransaction = {
  __typename: "CashlessTransaction",
  id: string,
  pos: string,
  checkNumber: string,
  orderTotal: number,
  paidAmount: number,
  paymentStatus: string,
  guestId: string,
  guestPhoneNumber?: string | null,
  guestEmail?: string | null,
  guest?: Guest | null,
  guestName: string,
  type?: string | null,
  bookingId?: string | null,
  invitationId?: string | null,
  eventId: string,
  event: Event,
  orderedItems?:  Array<OrderedItem | null > | null,
  syncOperationId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCashlessTransactionInput = {
  id: string,
  pos?: string | null,
  checkNumber?: string | null,
  orderTotal?: number | null,
  paidAmount?: number | null,
  paymentStatus?: string | null,
  guestId?: string | null,
  guestPhoneNumber?: string | null,
  guestEmail?: string | null,
  guestName?: string | null,
  type?: string | null,
  bookingId?: string | null,
  invitationId?: string | null,
  eventId?: string | null,
  orderedItems?: Array< OrderedItemInput | null > | null,
  syncOperationId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteCashlessTransactionInput = {
  id: string,
  _version?: number | null,
};

export type CreateCashlessTopUpInput = {
  id?: string | null,
  operation: string,
  amount: number,
  availableBalance?: number | null,
  paymentChannel: string,
  paymentMethod: string,
  isVoided?: string | null,
  guestId: string,
  guestPhoneNumber?: string | null,
  guestEmail?: string | null,
  bookingId?: string | null,
  invitationId?: string | null,
  ticketType?: string | null,
  eventId: string,
  eventName?: string | null,
  status: string,
  type?: string | null,
  syncOperationId?: string | null,
  deleted?: string | null,
  guestName: string,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type ModelCashlessTopUpConditionInput = {
  operation?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  availableBalance?: ModelFloatInput | null,
  paymentChannel?: ModelStringInput | null,
  paymentMethod?: ModelStringInput | null,
  isVoided?: ModelStringInput | null,
  guestId?: ModelIDInput | null,
  guestPhoneNumber?: ModelStringInput | null,
  guestEmail?: ModelStringInput | null,
  bookingId?: ModelIDInput | null,
  invitationId?: ModelIDInput | null,
  ticketType?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  eventName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  type?: ModelStringInput | null,
  syncOperationId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCashlessTopUpConditionInput | null > | null,
  or?: Array< ModelCashlessTopUpConditionInput | null > | null,
  not?: ModelCashlessTopUpConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type CashlessTopUp = {
  __typename: "CashlessTopUp",
  id: string,
  operation: string,
  amount: number,
  availableBalance?: number | null,
  paymentChannel: string,
  paymentMethod: string,
  isVoided?: string | null,
  guestId: string,
  guestPhoneNumber?: string | null,
  guestEmail?: string | null,
  guest?: Guest | null,
  bookingId?: string | null,
  invitationId?: string | null,
  ticketType?: string | null,
  booking?: Booking | null,
  invitation?: Invitation | null,
  eventId: string,
  eventName?: string | null,
  event: Event,
  status: string,
  type?: string | null,
  syncOperationId?: string | null,
  deleted?: string | null,
  guestName: string,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCashlessTopUpInput = {
  id: string,
  operation?: string | null,
  amount?: number | null,
  availableBalance?: number | null,
  paymentChannel?: string | null,
  paymentMethod?: string | null,
  isVoided?: string | null,
  guestId?: string | null,
  guestPhoneNumber?: string | null,
  guestEmail?: string | null,
  bookingId?: string | null,
  invitationId?: string | null,
  ticketType?: string | null,
  eventId?: string | null,
  eventName?: string | null,
  status?: string | null,
  type?: string | null,
  syncOperationId?: string | null,
  deleted?: string | null,
  guestName?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteCashlessTopUpInput = {
  id: string,
  _version?: number | null,
};

export type CreateCategoryInput = {
  id?: string | null,
  name: string,
  precedence: number,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type ModelCategoryConditionInput = {
  name?: ModelStringInput | null,
  precedence?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  name: string,
  precedence: number,
  items?: ModelItemConnection | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelItemConnection = {
  __typename: "ModelItemConnection",
  items:  Array<Item | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Item = {
  __typename: "Item",
  id: string,
  name: string,
  image?: string | null,
  price: number,
  precedence: number,
  categoryID: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCategoryInput = {
  id: string,
  name?: string | null,
  precedence?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateItemInput = {
  id?: string | null,
  name: string,
  image?: string | null,
  price: number,
  precedence: number,
  categoryID: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type ModelItemConditionInput = {
  name?: ModelStringInput | null,
  image?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  precedence?: ModelIntInput | null,
  categoryID?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelItemConditionInput | null > | null,
  or?: Array< ModelItemConditionInput | null > | null,
  not?: ModelItemConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateItemInput = {
  id: string,
  name?: string | null,
  image?: string | null,
  price?: number | null,
  precedence?: number | null,
  categoryID?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateItemConsumptionInput = {
  id?: string | null,
  eventId: string,
  itemId: string,
  itemName: string,
  itemQuantity: number,
  itemPrice: number,
  syncOperationId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelItemConsumptionConditionInput = {
  eventId?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  itemName?: ModelStringInput | null,
  itemQuantity?: ModelIntInput | null,
  itemPrice?: ModelFloatInput | null,
  syncOperationId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelItemConsumptionConditionInput | null > | null,
  or?: Array< ModelItemConsumptionConditionInput | null > | null,
  not?: ModelItemConsumptionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ItemConsumption = {
  __typename: "ItemConsumption",
  id: string,
  eventId: string,
  event: Event,
  itemId: string,
  item?: Item | null,
  itemName: string,
  itemQuantity: number,
  itemPrice: number,
  syncOperationId?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateItemConsumptionInput = {
  id: string,
  eventId?: string | null,
  itemId?: string | null,
  itemName?: string | null,
  itemQuantity?: number | null,
  itemPrice?: number | null,
  syncOperationId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteItemConsumptionInput = {
  id: string,
  _version?: number | null,
};

export type CreateTicketsScanInput = {
  id?: string | null,
  scannedById: string,
  scannedAt?: string | null,
  status?: string | null,
  eventId?: string | null,
  bookingId?: string | null,
  invitationId?: string | null,
  guestName?: string | null,
  ticketType?: string | null,
  guestAvatar?: string | null,
  deleted?: string | null,
  gateName?: string | null,
  checkpointName?: string | null,
  scannedByName?: string | null,
  _version?: number | null,
};

export type ModelTicketsScanConditionInput = {
  scannedById?: ModelIDInput | null,
  scannedAt?: ModelStringInput | null,
  status?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  bookingId?: ModelIDInput | null,
  invitationId?: ModelIDInput | null,
  guestName?: ModelStringInput | null,
  ticketType?: ModelStringInput | null,
  guestAvatar?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  gateName?: ModelStringInput | null,
  checkpointName?: ModelStringInput | null,
  scannedByName?: ModelStringInput | null,
  and?: Array< ModelTicketsScanConditionInput | null > | null,
  or?: Array< ModelTicketsScanConditionInput | null > | null,
  not?: ModelTicketsScanConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type TicketsScan = {
  __typename: "TicketsScan",
  id: string,
  scannedById: string,
  scannedAt?: string | null,
  status?: string | null,
  eventId?: string | null,
  bookingId?: string | null,
  invitationId?: string | null,
  guestName?: string | null,
  ticketType?: string | null,
  guestAvatar?: string | null,
  deleted?: string | null,
  gateName?: string | null,
  checkpointName?: string | null,
  scannedByName?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTicketsScanInput = {
  id: string,
  scannedById?: string | null,
  scannedAt?: string | null,
  status?: string | null,
  eventId?: string | null,
  bookingId?: string | null,
  invitationId?: string | null,
  guestName?: string | null,
  ticketType?: string | null,
  guestAvatar?: string | null,
  deleted?: string | null,
  gateName?: string | null,
  checkpointName?: string | null,
  scannedByName?: string | null,
  _version?: number | null,
};

export type DeleteTicketsScanInput = {
  id: string,
  _version?: number | null,
};

export type CreateGateInput = {
  id?: string | null,
  name: string,
  eventId: string,
  tickets?: Array< string | null > | null,
  admins?: Array< string | null > | null,
  checkpoints?: Array< CheckpointInput | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelGateConditionInput = {
  name?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  tickets?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelGateConditionInput | null > | null,
  or?: Array< ModelGateConditionInput | null > | null,
  not?: ModelGateConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateGateInput = {
  id: string,
  name?: string | null,
  eventId?: string | null,
  tickets?: Array< string | null > | null,
  admins?: Array< string | null > | null,
  checkpoints?: Array< CheckpointInput | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteGateInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrderInput = {
  id?: string | null,
  bookingId?: string | null,
  eventId?: string | null,
  userId?: string | null,
  orderedItems?: Array< OrderedItemInput | null > | null,
  status?: string | null,
  dispatcherId?: string | null,
  totalAmount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type ModelOrderConditionInput = {
  bookingId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  dispatcherId?: ModelIDInput | null,
  totalAmount?: ModelFloatInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Order = {
  __typename: "Order",
  id: string,
  bookingId?: string | null,
  eventId?: string | null,
  userId?: string | null,
  orderedItems?:  Array<OrderedItem | null > | null,
  status?: string | null,
  dispatcherId?: string | null,
  totalAmount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateOrderInput = {
  id: string,
  bookingId?: string | null,
  eventId?: string | null,
  userId?: string | null,
  orderedItems?: Array< OrderedItemInput | null > | null,
  status?: string | null,
  dispatcherId?: string | null,
  totalAmount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteOrderInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserIPInput = {
  id?: string | null,
  ip?: string | null,
  date?: string | null,
  times?: number | null,
  _version?: number | null,
};

export type ModelUserIPConditionInput = {
  ip?: ModelStringInput | null,
  date?: ModelStringInput | null,
  times?: ModelIntInput | null,
  and?: Array< ModelUserIPConditionInput | null > | null,
  or?: Array< ModelUserIPConditionInput | null > | null,
  not?: ModelUserIPConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UserIP = {
  __typename: "UserIP",
  id: string,
  ip?: string | null,
  date?: string | null,
  times?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserIPInput = {
  id: string,
  ip?: string | null,
  date?: string | null,
  times?: number | null,
  _version?: number | null,
};

export type DeleteUserIPInput = {
  id: string,
  _version?: number | null,
};

export type CreateScanInput = {
  id?: string | null,
  link: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelScanConditionInput = {
  link?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelScanConditionInput | null > | null,
  or?: Array< ModelScanConditionInput | null > | null,
  not?: ModelScanConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Scan = {
  __typename: "Scan",
  id: string,
  link: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateScanInput = {
  id: string,
  link?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteScanInput = {
  id: string,
  _version?: number | null,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  domain?: ModelStringInput | null,
  siteTitle?: ModelStringInput | null,
  guestsCount?: ModelIntInput | null,
  tagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  siteAddress?: ModelStringInput | null,
  defaultLanguage?: ModelStringInput | null,
  languages?: ModelStringInput | null,
  features?: ModelStringInput | null,
  status?: ModelStringInput | null,
  socialLinks?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  merchantVersion?: ModelStringInput | null,
  merchantID?: ModelStringInput | null,
  merchantIframe?: ModelStringInput | null,
  merchantIntegrationNumber?: ModelStringInput | null,
  merchantAPIKey?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  accountLogoId?: ModelIDInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelConceptFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  type?: ModelStringInput | null,
  location?: ModelStringInput | null,
  precedence?: ModelIntInput | null,
  longitude?: ModelStringInput | null,
  latitude?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelConceptFilterInput | null > | null,
  or?: Array< ModelConceptFilterInput | null > | null,
  not?: ModelConceptFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelConceptConnection = {
  __typename: "ModelConceptConnection",
  items:  Array<Concept | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLanguageFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelLanguageFilterInput | null > | null,
  or?: Array< ModelLanguageFilterInput | null > | null,
  not?: ModelLanguageFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLanguageConnection = {
  __typename: "ModelLanguageConnection",
  items:  Array<Language | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFeatureFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  parent?: ModelStringInput | null,
  private?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelFeatureFilterInput | null > | null,
  or?: Array< ModelFeatureFilterInput | null > | null,
  not?: ModelFeatureFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFeatureConnection = {
  __typename: "ModelFeatureConnection",
  items:  Array<Feature | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAdminRoleFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAdminRoleFilterInput | null > | null,
  or?: Array< ModelAdminRoleFilterInput | null > | null,
  not?: ModelAdminRoleFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAdminRoleConnection = {
  __typename: "ModelAdminRoleConnection",
  items:  Array<AdminRole | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAdminGroupFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  roles?: ModelIDInput | null,
  users?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAdminGroupFilterInput | null > | null,
  or?: Array< ModelAdminGroupFilterInput | null > | null,
  not?: ModelAdminGroupFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAdminGroupConnection = {
  __typename: "ModelAdminGroupConnection",
  items:  Array<AdminGroup | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAttachmentFilterInput = {
  id?: ModelIDInput | null,
  mediaID?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAttachmentFilterInput | null > | null,
  or?: Array< ModelAttachmentFilterInput | null > | null,
  not?: ModelAttachmentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAttachmentConnection = {
  __typename: "ModelAttachmentConnection",
  items:  Array<Attachment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserConceptsFilterInput = {
  id?: ModelIDInput | null,
  defaultConcept?: ModelIDInput | null,
  concepts?: ModelIDInput | null,
  conceptsRoles?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelUserConceptsFilterInput | null > | null,
  or?: Array< ModelUserConceptsFilterInput | null > | null,
  not?: ModelUserConceptsFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserConceptsConnection = {
  __typename: "ModelUserConceptsConnection",
  items:  Array<UserConcepts | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelGuestFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  isNameEdited?: ModelBooleanInput | null,
  username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  guestGroupID?: ModelIDInput | null,
  guestGroupName?: ModelStringInput | null,
  sub?: ModelStringInput | null,
  facebookSub?: ModelStringInput | null,
  guest_avatar?: ModelStringInput | null,
  avg_spend?: ModelFloatInput | null,
  avg_ticket_type?: ModelStringInput | null,
  numberOfTickets?: ModelIntInput | null,
  connections?: ModelStringInput | null,
  last_attended_event?: ModelStringInput | null,
  last_attended_event_Name?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  isGenderEdited?: ModelBooleanInput | null,
  group?: ModelStringInput | null,
  faceBookID?: ModelIDInput | null,
  appPassword?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  isBirthdateEdited?: ModelBooleanInput | null,
  isVerified?: ModelBooleanInput | null,
  images?: ModelStringInput | null,
  address?: ModelStringInput | null,
  totalEvents?: ModelIntInput | null,
  flags?: ModelIDInput | null,
  frontPersonalID?: ModelStringInput | null,
  isFrontPersonalIDEdited?: ModelBooleanInput | null,
  passportImage?: ModelStringInput | null,
  isPassportImageEdited?: ModelBooleanInput | null,
  backPersonalID?: ModelStringInput | null,
  isBackPersonalIDEdited?: ModelBooleanInput | null,
  faceBookProfileLink?: ModelStringInput | null,
  instagramProfileLink?: ModelStringInput | null,
  nationality?: ModelStringInput | null,
  balance?: ModelFloatInput | null,
  isBlocked?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelGuestFilterInput | null > | null,
  or?: Array< ModelGuestFilterInput | null > | null,
  not?: ModelGuestFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelGuestConnection = {
  __typename: "ModelGuestConnection",
  items:  Array<Guest | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelGuestGroupFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  color?: ModelStringInput | null,
  guests?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelGuestGroupFilterInput | null > | null,
  or?: Array< ModelGuestGroupFilterInput | null > | null,
  not?: ModelGuestGroupFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelGuestGroupConnection = {
  __typename: "ModelGuestGroupConnection",
  items:  Array<GuestGroup | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTimelineFilterInput = {
  id?: ModelIDInput | null,
  actionName?: ModelStringInput | null,
  oldStatus?: ModelStringInput | null,
  newStatus?: ModelStringInput | null,
  bookingId?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  isScanned?: ModelStringInput | null,
  type?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTimelineFilterInput | null > | null,
  or?: Array< ModelTimelineFilterInput | null > | null,
  not?: ModelTimelineFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTimelineConnection = {
  __typename: "ModelTimelineConnection",
  items:  Array<Timeline | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFlagFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  color?: ModelStringInput | null,
  customers?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelFlagFilterInput | null > | null,
  or?: Array< ModelFlagFilterInput | null > | null,
  not?: ModelFlagFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFlagConnection = {
  __typename: "ModelFlagConnection",
  items:  Array<Flag | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  message?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  bookingId?: ModelStringInput | null,
  replyTo?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByImg?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  disclaimer?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  todoList?: ModelStringInput | null,
  map?: ModelStringInput | null,
  image?: ModelStringInput | null,
  largeScreensImage?: ModelStringInput | null,
  mediumScreensImage?: ModelStringInput | null,
  smallScreensImage?: ModelStringInput | null,
  sendEmailToValidGuest?: ModelBooleanInput | null,
  sendSmsToValidGuest?: ModelBooleanInput | null,
  gallery?: ModelStringInput | null,
  visibleTo?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  published?: ModelBooleanInput | null,
  applyGenderRatio?: ModelBooleanInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelEventTicketFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  cashlessCredit?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  color?: ModelStringInput | null,
  paymentRules?: ModelStringInput | null,
  approvalRule?: ModelStringInput | null,
  showAll?: ModelBooleanInput | null,
  showOnHold?: ModelBooleanInput | null,
  onHoldDisplayText?: ModelStringInput | null,
  showSoldOut?: ModelBooleanInput | null,
  soldOutDisplayText?: ModelStringInput | null,
  setAvailable?: ModelBooleanInput | null,
  availableAtDate?: ModelStringInput | null,
  allowTransferred?: ModelBooleanInput | null,
  transferredAprroval?: ModelBooleanInput | null,
  transferredSameGender?: ModelBooleanInput | null,
  showUpcomingWave?: ModelBooleanInput | null,
  showUpcomingWaveDisplayText?: ModelStringInput | null,
  eventID?: ModelIDInput | null,
  gateID?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelEventTicketFilterInput | null > | null,
  or?: Array< ModelEventTicketFilterInput | null > | null,
  not?: ModelEventTicketFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInvitationLimitFilterInput = {
  id?: ModelIDInput | null,
  adminID?: ModelStringInput | null,
  quota?: ModelIntInput | null,
  eventID?: ModelIDInput | null,
  totalQuota?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelInvitationLimitFilterInput | null > | null,
  or?: Array< ModelInvitationLimitFilterInput | null > | null,
  not?: ModelInvitationLimitFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  eventInvitationLimitId?: ModelIDInput | null,
};

export type ModelBookingFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  overallStatus?: ModelStringInput | null,
  bookingGuestId?: ModelIDInput | null,
  bookingMainGuestId?: ModelIDInput | null,
  mainBookingId?: ModelIDInput | null,
  bookingEventId?: ModelIDInput | null,
  bookingEventTicketId?: ModelIDInput | null,
  cashlessCredit?: ModelFloatInput | null,
  wave?: ModelStringInput | null,
  waveId?: ModelIDInput | null,
  isMainGuest?: ModelBooleanInput | null,
  isInvited?: ModelBooleanInput | null,
  orderId?: ModelStringInput | null,
  ticketNumber?: ModelStringInput | null,
  redeemed?: ModelBooleanInput | null,
  statusUpdatedByID?: ModelStringInput | null,
  statusUpdatedByName?: ModelStringInput | null,
  statusUpdatedAt?: ModelStringInput | null,
  specialNeed?: ModelBooleanInput | null,
  phone_number?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  notRegisteredGuests?: ModelStringInput | null,
  hasNotRegisteredGuest?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  rejectionComment?: ModelStringInput | null,
  isPaid?: ModelBooleanInput | null,
  isTransfered?: ModelBooleanInput | null,
  isPendingTransfer?: ModelBooleanInput | null,
  lastGuestId?: ModelIDInput | null,
  lastMainGuestId?: ModelIDInput | null,
  ownerHistory?: ModelIDInput | null,
  paidAmount?: ModelFloatInput | null,
  balance?: ModelFloatInput | null,
  scannedAt?: ModelStringInput | null,
  scannedByName?: ModelStringInput | null,
  refunded?: ModelBooleanInput | null,
  paymentTransactionId?: ModelStringInput | null,
  paymentParentTransactionId?: ModelStringInput | null,
  origin?: ModelStringInput | null,
  paymentMethod?: ModelStringInput | null,
  testField?: ModelStringInput | null,
  and?: Array< ModelBookingFilterInput | null > | null,
  or?: Array< ModelBookingFilterInput | null > | null,
  not?: ModelBookingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  bookingRejectionReasonId?: ModelIDInput | null,
};

export type ModelBookingConnection = {
  __typename: "ModelBookingConnection",
  items:  Array<Booking | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelRejectReasonFilterInput = {
  id?: ModelIDInput | null,
  content?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  updatedByID?: ModelStringInput | null,
  updatedByName?: ModelStringInput | null,
  and?: Array< ModelRejectReasonFilterInput | null > | null,
  or?: Array< ModelRejectReasonFilterInput | null > | null,
  not?: ModelRejectReasonFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRejectReasonConnection = {
  __typename: "ModelRejectReasonConnection",
  items:  Array<RejectReason | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInvitationFilterInput = {
  id?: ModelIDInput | null,
  invitationEventId?: ModelIDInput | null,
  cashlessCredit?: ModelFloatInput | null,
  balance?: ModelFloatInput | null,
  wave?: ModelStringInput | null,
  phone_number?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  reason?: ModelStringInput | null,
  secret?: ModelStringInput | null,
  used?: ModelBooleanInput | null,
  scannedAt?: ModelStringInput | null,
  scannedByName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelInvitationFilterInput | null > | null,
  or?: Array< ModelInvitationFilterInput | null > | null,
  not?: ModelInvitationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  invitationEventTicketId?: ModelIDInput | null,
};

export type ModelInvitationConnection = {
  __typename: "ModelInvitationConnection",
  items:  Array<Invitation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTransactionFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  guestId?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  guestsNames?: ModelStringInput | null,
  guestPhone_number?: ModelStringInput | null,
  eventId?: ModelStringInput | null,
  ticketId?: ModelStringInput | null,
  success?: ModelBooleanInput | null,
  failureReason?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  amount_cents?: ModelStringInput | null,
  refund?: ModelBooleanInput | null,
  refunded_amount_cents?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  transactionID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTransactionFilterInput | null > | null,
  or?: Array< ModelTransactionFilterInput | null > | null,
  not?: ModelTransactionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  transactionBookingId?: ModelIDInput | null,
};

export type ModelTransactionConnection = {
  __typename: "ModelTransactionConnection",
  items:  Array<Transaction | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelWavesConsumptionFilterInput = {
  waveId?: ModelStringInput | null,
  consumedTickets?: ModelIntInput | null,
  reservedTickets?: ModelIntInput | null,
  totalTickets?: ModelIntInput | null,
  consumed?: ModelStringInput | null,
  eventId?: ModelStringInput | null,
  eventTicketId?: ModelStringInput | null,
  and?: Array< ModelWavesConsumptionFilterInput | null > | null,
  or?: Array< ModelWavesConsumptionFilterInput | null > | null,
  not?: ModelWavesConsumptionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelWavesConsumptionConnection = {
  __typename: "ModelWavesConsumptionConnection",
  items:  Array<WavesConsumption | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelCashlessTransactionFilterInput = {
  id?: ModelIDInput | null,
  pos?: ModelStringInput | null,
  checkNumber?: ModelStringInput | null,
  orderTotal?: ModelFloatInput | null,
  paidAmount?: ModelFloatInput | null,
  paymentStatus?: ModelStringInput | null,
  guestId?: ModelIDInput | null,
  guestPhoneNumber?: ModelStringInput | null,
  guestEmail?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  type?: ModelStringInput | null,
  bookingId?: ModelIDInput | null,
  invitationId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  syncOperationId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCashlessTransactionFilterInput | null > | null,
  or?: Array< ModelCashlessTransactionFilterInput | null > | null,
  not?: ModelCashlessTransactionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCashlessTransactionConnection = {
  __typename: "ModelCashlessTransactionConnection",
  items:  Array<CashlessTransaction | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCashlessTopUpFilterInput = {
  id?: ModelIDInput | null,
  operation?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  availableBalance?: ModelFloatInput | null,
  paymentChannel?: ModelStringInput | null,
  paymentMethod?: ModelStringInput | null,
  isVoided?: ModelStringInput | null,
  guestId?: ModelIDInput | null,
  guestPhoneNumber?: ModelStringInput | null,
  guestEmail?: ModelStringInput | null,
  bookingId?: ModelIDInput | null,
  invitationId?: ModelIDInput | null,
  ticketType?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  eventName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  type?: ModelStringInput | null,
  syncOperationId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCashlessTopUpFilterInput | null > | null,
  or?: Array< ModelCashlessTopUpFilterInput | null > | null,
  not?: ModelCashlessTopUpFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCashlessTopUpConnection = {
  __typename: "ModelCashlessTopUpConnection",
  items:  Array<CashlessTopUp | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelItemFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  image?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  precedence?: ModelIntInput | null,
  categoryID?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelItemFilterInput | null > | null,
  or?: Array< ModelItemFilterInput | null > | null,
  not?: ModelItemFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelItemConsumptionFilterInput = {
  id?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  itemId?: ModelIDInput | null,
  itemName?: ModelStringInput | null,
  itemQuantity?: ModelIntInput | null,
  itemPrice?: ModelFloatInput | null,
  syncOperationId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelItemConsumptionFilterInput | null > | null,
  or?: Array< ModelItemConsumptionFilterInput | null > | null,
  not?: ModelItemConsumptionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelItemConsumptionConnection = {
  __typename: "ModelItemConsumptionConnection",
  items:  Array<ItemConsumption | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTicketsScanFilterInput = {
  id?: ModelIDInput | null,
  scannedById?: ModelIDInput | null,
  scannedAt?: ModelStringInput | null,
  status?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  bookingId?: ModelIDInput | null,
  invitationId?: ModelIDInput | null,
  guestName?: ModelStringInput | null,
  ticketType?: ModelStringInput | null,
  guestAvatar?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  gateName?: ModelStringInput | null,
  checkpointName?: ModelStringInput | null,
  scannedByName?: ModelStringInput | null,
  and?: Array< ModelTicketsScanFilterInput | null > | null,
  or?: Array< ModelTicketsScanFilterInput | null > | null,
  not?: ModelTicketsScanFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTicketsScanConnection = {
  __typename: "ModelTicketsScanConnection",
  items:  Array<TicketsScan | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelGateFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  eventId?: ModelIDInput | null,
  tickets?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelGateFilterInput | null > | null,
  or?: Array< ModelGateFilterInput | null > | null,
  not?: ModelGateFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  bookingId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  dispatcherId?: ModelIDInput | null,
  totalAmount?: ModelFloatInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserIPFilterInput = {
  id?: ModelIDInput | null,
  ip?: ModelStringInput | null,
  date?: ModelStringInput | null,
  times?: ModelIntInput | null,
  and?: Array< ModelUserIPFilterInput | null > | null,
  or?: Array< ModelUserIPFilterInput | null > | null,
  not?: ModelUserIPFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserIPConnection = {
  __typename: "ModelUserIPConnection",
  items:  Array<UserIP | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelScanFilterInput = {
  id?: ModelIDInput | null,
  link?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelScanFilterInput | null > | null,
  or?: Array< ModelScanFilterInput | null > | null,
  not?: ModelScanFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelScanConnection = {
  __typename: "ModelScanConnection",
  items:  Array<Scan | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubscriptionAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  domain?: ModelSubscriptionStringInput | null,
  siteTitle?: ModelSubscriptionStringInput | null,
  guestsCount?: ModelSubscriptionIntInput | null,
  tagline?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  siteAddress?: ModelSubscriptionStringInput | null,
  defaultLanguage?: ModelSubscriptionStringInput | null,
  languages?: ModelSubscriptionStringInput | null,
  features?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  socialLinks?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  merchantVersion?: ModelSubscriptionStringInput | null,
  merchantID?: ModelSubscriptionStringInput | null,
  merchantIframe?: ModelSubscriptionStringInput | null,
  merchantIntegrationNumber?: ModelSubscriptionStringInput | null,
  merchantAPIKey?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionConceptFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  logo?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  location?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionIntInput | null,
  longitude?: ModelSubscriptionStringInput | null,
  latitude?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConceptFilterInput | null > | null,
  or?: Array< ModelSubscriptionConceptFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLanguageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLanguageFilterInput | null > | null,
  or?: Array< ModelSubscriptionLanguageFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFeatureFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  parent?: ModelSubscriptionStringInput | null,
  private?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFeatureFilterInput | null > | null,
  or?: Array< ModelSubscriptionFeatureFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionAdminRoleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminRoleFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminRoleFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAdminGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  roles?: ModelSubscriptionIDInput | null,
  users?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminGroupFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAttachmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  mediaID?: ModelSubscriptionIDInput | null,
  fileUrl?: ModelSubscriptionStringInput | null,
  filename?: ModelSubscriptionStringInput | null,
  filetype?: ModelSubscriptionStringInput | null,
  fileSize?: ModelSubscriptionIntInput | null,
  alternativeText?: ModelSubscriptionStringInput | null,
  caption?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionAttachmentFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserConceptsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  defaultConcept?: ModelSubscriptionIDInput | null,
  concepts?: ModelSubscriptionIDInput | null,
  conceptsRoles?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserConceptsFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserConceptsFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionGuestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  isNameEdited?: ModelSubscriptionBooleanInput | null,
  username?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  guestGroupID?: ModelSubscriptionIDInput | null,
  guestGroupName?: ModelSubscriptionStringInput | null,
  sub?: ModelSubscriptionStringInput | null,
  facebookSub?: ModelSubscriptionStringInput | null,
  guest_avatar?: ModelSubscriptionStringInput | null,
  avg_spend?: ModelSubscriptionFloatInput | null,
  avg_ticket_type?: ModelSubscriptionStringInput | null,
  numberOfTickets?: ModelSubscriptionIntInput | null,
  connections?: ModelSubscriptionStringInput | null,
  last_attended_event?: ModelSubscriptionStringInput | null,
  last_attended_event_Name?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  isGenderEdited?: ModelSubscriptionBooleanInput | null,
  group?: ModelSubscriptionStringInput | null,
  faceBookID?: ModelSubscriptionIDInput | null,
  appPassword?: ModelSubscriptionStringInput | null,
  birthdate?: ModelSubscriptionStringInput | null,
  isBirthdateEdited?: ModelSubscriptionBooleanInput | null,
  isVerified?: ModelSubscriptionBooleanInput | null,
  images?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  totalEvents?: ModelSubscriptionIntInput | null,
  flags?: ModelSubscriptionIDInput | null,
  frontPersonalID?: ModelSubscriptionStringInput | null,
  isFrontPersonalIDEdited?: ModelSubscriptionBooleanInput | null,
  passportImage?: ModelSubscriptionStringInput | null,
  isPassportImageEdited?: ModelSubscriptionBooleanInput | null,
  backPersonalID?: ModelSubscriptionStringInput | null,
  isBackPersonalIDEdited?: ModelSubscriptionBooleanInput | null,
  faceBookProfileLink?: ModelSubscriptionStringInput | null,
  instagramProfileLink?: ModelSubscriptionStringInput | null,
  nationality?: ModelSubscriptionStringInput | null,
  balance?: ModelSubscriptionFloatInput | null,
  isBlocked?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGuestFilterInput | null > | null,
  or?: Array< ModelSubscriptionGuestFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionGuestGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  guests?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGuestGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionGuestGroupFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTimelineFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  actionName?: ModelSubscriptionStringInput | null,
  oldStatus?: ModelSubscriptionStringInput | null,
  newStatus?: ModelSubscriptionStringInput | null,
  bookingId?: ModelSubscriptionStringInput | null,
  customerId?: ModelSubscriptionStringInput | null,
  isScanned?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTimelineFilterInput | null > | null,
  or?: Array< ModelSubscriptionTimelineFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFlagFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  customers?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFlagFilterInput | null > | null,
  or?: Array< ModelSubscriptionFlagFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  message?: ModelSubscriptionStringInput | null,
  customerId?: ModelSubscriptionStringInput | null,
  bookingId?: ModelSubscriptionStringInput | null,
  replyTo?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByImg?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionEventFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  disclaimer?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  todoList?: ModelSubscriptionStringInput | null,
  map?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  largeScreensImage?: ModelSubscriptionStringInput | null,
  mediumScreensImage?: ModelSubscriptionStringInput | null,
  smallScreensImage?: ModelSubscriptionStringInput | null,
  sendEmailToValidGuest?: ModelSubscriptionBooleanInput | null,
  sendSmsToValidGuest?: ModelSubscriptionBooleanInput | null,
  gallery?: ModelSubscriptionStringInput | null,
  visibleTo?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  published?: ModelSubscriptionBooleanInput | null,
  applyGenderRatio?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionEventFilterInput | null > | null,
  or?: Array< ModelSubscriptionEventFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionEventTicketFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  cashlessCredit?: ModelSubscriptionFloatInput | null,
  description?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  paymentRules?: ModelSubscriptionStringInput | null,
  approvalRule?: ModelSubscriptionStringInput | null,
  showAll?: ModelSubscriptionBooleanInput | null,
  showOnHold?: ModelSubscriptionBooleanInput | null,
  onHoldDisplayText?: ModelSubscriptionStringInput | null,
  showSoldOut?: ModelSubscriptionBooleanInput | null,
  soldOutDisplayText?: ModelSubscriptionStringInput | null,
  setAvailable?: ModelSubscriptionBooleanInput | null,
  availableAtDate?: ModelSubscriptionStringInput | null,
  allowTransferred?: ModelSubscriptionBooleanInput | null,
  transferredAprroval?: ModelSubscriptionBooleanInput | null,
  transferredSameGender?: ModelSubscriptionBooleanInput | null,
  showUpcomingWave?: ModelSubscriptionBooleanInput | null,
  showUpcomingWaveDisplayText?: ModelSubscriptionStringInput | null,
  eventID?: ModelSubscriptionIDInput | null,
  gateID?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEventTicketFilterInput | null > | null,
  or?: Array< ModelSubscriptionEventTicketFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionInvitationLimitFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  adminID?: ModelSubscriptionStringInput | null,
  quota?: ModelSubscriptionIntInput | null,
  eventID?: ModelSubscriptionIDInput | null,
  totalQuota?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInvitationLimitFilterInput | null > | null,
  or?: Array< ModelSubscriptionInvitationLimitFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionBookingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  overallStatus?: ModelSubscriptionStringInput | null,
  bookingGuestId?: ModelSubscriptionIDInput | null,
  bookingMainGuestId?: ModelSubscriptionIDInput | null,
  mainBookingId?: ModelSubscriptionIDInput | null,
  bookingEventId?: ModelSubscriptionIDInput | null,
  bookingEventTicketId?: ModelSubscriptionIDInput | null,
  cashlessCredit?: ModelSubscriptionFloatInput | null,
  wave?: ModelSubscriptionStringInput | null,
  waveId?: ModelSubscriptionIDInput | null,
  isMainGuest?: ModelSubscriptionBooleanInput | null,
  isInvited?: ModelSubscriptionBooleanInput | null,
  orderId?: ModelSubscriptionStringInput | null,
  ticketNumber?: ModelSubscriptionStringInput | null,
  redeemed?: ModelSubscriptionBooleanInput | null,
  statusUpdatedByID?: ModelSubscriptionStringInput | null,
  statusUpdatedByName?: ModelSubscriptionStringInput | null,
  statusUpdatedAt?: ModelSubscriptionStringInput | null,
  specialNeed?: ModelSubscriptionBooleanInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  guestName?: ModelSubscriptionStringInput | null,
  notRegisteredGuests?: ModelSubscriptionStringInput | null,
  hasNotRegisteredGuest?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  rejectionComment?: ModelSubscriptionStringInput | null,
  isPaid?: ModelSubscriptionBooleanInput | null,
  isTransfered?: ModelSubscriptionBooleanInput | null,
  isPendingTransfer?: ModelSubscriptionBooleanInput | null,
  lastGuestId?: ModelSubscriptionIDInput | null,
  lastMainGuestId?: ModelSubscriptionIDInput | null,
  ownerHistory?: ModelSubscriptionIDInput | null,
  paidAmount?: ModelSubscriptionFloatInput | null,
  balance?: ModelSubscriptionFloatInput | null,
  scannedAt?: ModelSubscriptionStringInput | null,
  scannedByName?: ModelSubscriptionStringInput | null,
  refunded?: ModelSubscriptionBooleanInput | null,
  paymentTransactionId?: ModelSubscriptionStringInput | null,
  paymentParentTransactionId?: ModelSubscriptionStringInput | null,
  origin?: ModelSubscriptionStringInput | null,
  paymentMethod?: ModelSubscriptionStringInput | null,
  testField?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBookingFilterInput | null > | null,
  or?: Array< ModelSubscriptionBookingFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionRejectReasonFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  content?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  updatedByID?: ModelSubscriptionStringInput | null,
  updatedByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRejectReasonFilterInput | null > | null,
  or?: Array< ModelSubscriptionRejectReasonFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionInvitationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  invitationEventId?: ModelSubscriptionIDInput | null,
  cashlessCredit?: ModelSubscriptionFloatInput | null,
  balance?: ModelSubscriptionFloatInput | null,
  wave?: ModelSubscriptionStringInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  reason?: ModelSubscriptionStringInput | null,
  secret?: ModelSubscriptionStringInput | null,
  used?: ModelSubscriptionBooleanInput | null,
  scannedAt?: ModelSubscriptionStringInput | null,
  scannedByName?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInvitationFilterInput | null > | null,
  or?: Array< ModelSubscriptionInvitationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTransactionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  guestId?: ModelSubscriptionStringInput | null,
  guestName?: ModelSubscriptionStringInput | null,
  guestsNames?: ModelSubscriptionStringInput | null,
  guestPhone_number?: ModelSubscriptionStringInput | null,
  eventId?: ModelSubscriptionStringInput | null,
  ticketId?: ModelSubscriptionStringInput | null,
  success?: ModelSubscriptionBooleanInput | null,
  failureReason?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  amount_cents?: ModelSubscriptionStringInput | null,
  refund?: ModelSubscriptionBooleanInput | null,
  refunded_amount_cents?: ModelSubscriptionStringInput | null,
  orderID?: ModelSubscriptionStringInput | null,
  transactionID?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTransactionFilterInput | null > | null,
  or?: Array< ModelSubscriptionTransactionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionWavesConsumptionFilterInput = {
  waveId?: ModelSubscriptionStringInput | null,
  consumedTickets?: ModelSubscriptionIntInput | null,
  reservedTickets?: ModelSubscriptionIntInput | null,
  totalTickets?: ModelSubscriptionIntInput | null,
  consumed?: ModelSubscriptionStringInput | null,
  eventId?: ModelSubscriptionStringInput | null,
  eventTicketId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWavesConsumptionFilterInput | null > | null,
  or?: Array< ModelSubscriptionWavesConsumptionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCashlessTransactionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  pos?: ModelSubscriptionStringInput | null,
  checkNumber?: ModelSubscriptionStringInput | null,
  orderTotal?: ModelSubscriptionFloatInput | null,
  paidAmount?: ModelSubscriptionFloatInput | null,
  paymentStatus?: ModelSubscriptionStringInput | null,
  guestId?: ModelSubscriptionIDInput | null,
  guestPhoneNumber?: ModelSubscriptionStringInput | null,
  guestEmail?: ModelSubscriptionStringInput | null,
  guestName?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  bookingId?: ModelSubscriptionIDInput | null,
  invitationId?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  syncOperationId?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCashlessTransactionFilterInput | null > | null,
  or?: Array< ModelSubscriptionCashlessTransactionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCashlessTopUpFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  operation?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  availableBalance?: ModelSubscriptionFloatInput | null,
  paymentChannel?: ModelSubscriptionStringInput | null,
  paymentMethod?: ModelSubscriptionStringInput | null,
  isVoided?: ModelSubscriptionStringInput | null,
  guestId?: ModelSubscriptionIDInput | null,
  guestPhoneNumber?: ModelSubscriptionStringInput | null,
  guestEmail?: ModelSubscriptionStringInput | null,
  bookingId?: ModelSubscriptionIDInput | null,
  invitationId?: ModelSubscriptionIDInput | null,
  ticketType?: ModelSubscriptionStringInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  eventName?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  syncOperationId?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  guestName?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCashlessTopUpFilterInput | null > | null,
  or?: Array< ModelSubscriptionCashlessTopUpFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  precedence?: ModelSubscriptionIntInput | null,
  categoryID?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionItemFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionItemConsumptionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  itemId?: ModelSubscriptionIDInput | null,
  itemName?: ModelSubscriptionStringInput | null,
  itemQuantity?: ModelSubscriptionIntInput | null,
  itemPrice?: ModelSubscriptionFloatInput | null,
  syncOperationId?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionItemConsumptionFilterInput | null > | null,
  or?: Array< ModelSubscriptionItemConsumptionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTicketsScanFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  scannedById?: ModelSubscriptionIDInput | null,
  scannedAt?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  bookingId?: ModelSubscriptionIDInput | null,
  invitationId?: ModelSubscriptionIDInput | null,
  guestName?: ModelSubscriptionStringInput | null,
  ticketType?: ModelSubscriptionStringInput | null,
  guestAvatar?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  gateName?: ModelSubscriptionStringInput | null,
  checkpointName?: ModelSubscriptionStringInput | null,
  scannedByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTicketsScanFilterInput | null > | null,
  or?: Array< ModelSubscriptionTicketsScanFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionGateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  tickets?: ModelSubscriptionStringInput | null,
  admins?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGateFilterInput | null > | null,
  or?: Array< ModelSubscriptionGateFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOrderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  bookingId?: ModelSubscriptionIDInput | null,
  eventId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  dispatcherId?: ModelSubscriptionIDInput | null,
  totalAmount?: ModelSubscriptionFloatInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserIPFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ip?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  times?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionUserIPFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserIPFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionScanFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  link?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionScanFilterInput | null > | null,
  or?: Array< ModelSubscriptionScanFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    currency?: string | null,
    merchantVersion?: string | null,
    merchantID?: string | null,
    merchantIframe?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    accountLogoId?: string | null,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    currency?: string | null,
    merchantVersion?: string | null,
    merchantID?: string | null,
    merchantIframe?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    accountLogoId?: string | null,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    currency?: string | null,
    merchantVersion?: string | null,
    merchantID?: string | null,
    merchantIframe?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    accountLogoId?: string | null,
  } | null,
};

export type CreateConceptMutationVariables = {
  input: CreateConceptInput,
  condition?: ModelConceptConditionInput | null,
};

export type CreateConceptMutation = {
  createConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateConceptMutationVariables = {
  input: UpdateConceptInput,
  condition?: ModelConceptConditionInput | null,
};

export type UpdateConceptMutation = {
  updateConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteConceptMutationVariables = {
  input: DeleteConceptInput,
  condition?: ModelConceptConditionInput | null,
};

export type DeleteConceptMutation = {
  deleteConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLanguageMutationVariables = {
  input: CreateLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type CreateLanguageMutation = {
  createLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLanguageMutationVariables = {
  input: UpdateLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type UpdateLanguageMutation = {
  updateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLanguageMutationVariables = {
  input: DeleteLanguageInput,
  condition?: ModelLanguageConditionInput | null,
};

export type DeleteLanguageMutation = {
  deleteLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFeatureMutationVariables = {
  input: CreateFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type CreateFeatureMutation = {
  createFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFeatureMutationVariables = {
  input: UpdateFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type UpdateFeatureMutation = {
  updateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFeatureMutationVariables = {
  input: DeleteFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type DeleteFeatureMutation = {
  deleteFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAdminRoleMutationVariables = {
  input: CreateAdminRoleInput,
  condition?: ModelAdminRoleConditionInput | null,
};

export type CreateAdminRoleMutation = {
  createAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAdminRoleMutationVariables = {
  input: UpdateAdminRoleInput,
  condition?: ModelAdminRoleConditionInput | null,
};

export type UpdateAdminRoleMutation = {
  updateAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAdminRoleMutationVariables = {
  input: DeleteAdminRoleInput,
  condition?: ModelAdminRoleConditionInput | null,
};

export type DeleteAdminRoleMutation = {
  deleteAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAdminGroupMutationVariables = {
  input: CreateAdminGroupInput,
  condition?: ModelAdminGroupConditionInput | null,
};

export type CreateAdminGroupMutation = {
  createAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAdminGroupMutationVariables = {
  input: UpdateAdminGroupInput,
  condition?: ModelAdminGroupConditionInput | null,
};

export type UpdateAdminGroupMutation = {
  updateAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAdminGroupMutationVariables = {
  input: DeleteAdminGroupInput,
  condition?: ModelAdminGroupConditionInput | null,
};

export type DeleteAdminGroupMutation = {
  deleteAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAttachmentMutationVariables = {
  input: CreateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type CreateAttachmentMutation = {
  createAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAttachmentMutationVariables = {
  input: UpdateAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type UpdateAttachmentMutation = {
  updateAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAttachmentMutationVariables = {
  input: DeleteAttachmentInput,
  condition?: ModelAttachmentConditionInput | null,
};

export type DeleteAttachmentMutation = {
  deleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserConceptsMutationVariables = {
  input: CreateUserConceptsInput,
  condition?: ModelUserConceptsConditionInput | null,
};

export type CreateUserConceptsMutation = {
  createUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserConceptsMutationVariables = {
  input: UpdateUserConceptsInput,
  condition?: ModelUserConceptsConditionInput | null,
};

export type UpdateUserConceptsMutation = {
  updateUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserConceptsMutationVariables = {
  input: DeleteUserConceptsInput,
  condition?: ModelUserConceptsConditionInput | null,
};

export type DeleteUserConceptsMutation = {
  deleteUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateGuestMutationVariables = {
  input: CreateGuestInput,
  condition?: ModelGuestConditionInput | null,
};

export type CreateGuestMutation = {
  createGuest?:  {
    __typename: "Guest",
    id: string,
    name?: string | null,
    isNameEdited?: boolean | null,
    username?: string | null,
    email?: string | null,
    phone_number?: string | null,
    guestGroupID?: string | null,
    guestGroupName?: string | null,
    sub?: string | null,
    facebookSub?: string | null,
    guest_avatar?: string | null,
    avg_spend?: number | null,
    avg_ticket_type?: string | null,
    numberOfTickets?: number | null,
    connections?: string | null,
    last_attended_event?: string | null,
    last_attended_event_Name?: string | null,
    gender?: string | null,
    isGenderEdited?: boolean | null,
    group?: string | null,
    faceBookID?: string | null,
    appPassword?: string | null,
    birthdate?: string | null,
    isBirthdateEdited?: boolean | null,
    isVerified?: boolean | null,
    images?: Array< string | null > | null,
    address?: string | null,
    totalEvents?: number | null,
    flags?: Array< string | null > | null,
    frontPersonalID?: string | null,
    isFrontPersonalIDEdited?: boolean | null,
    passportImage?: string | null,
    isPassportImageEdited?: boolean | null,
    backPersonalID?: string | null,
    isBackPersonalIDEdited?: boolean | null,
    faceBookProfileLink?: string | null,
    instagramProfileLink?: string | null,
    nationality?: string | null,
    balance?: number | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    isBlocked?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateGuestMutationVariables = {
  input: UpdateGuestInput,
  condition?: ModelGuestConditionInput | null,
};

export type UpdateGuestMutation = {
  updateGuest?:  {
    __typename: "Guest",
    id: string,
    name?: string | null,
    isNameEdited?: boolean | null,
    username?: string | null,
    email?: string | null,
    phone_number?: string | null,
    guestGroupID?: string | null,
    guestGroupName?: string | null,
    sub?: string | null,
    facebookSub?: string | null,
    guest_avatar?: string | null,
    avg_spend?: number | null,
    avg_ticket_type?: string | null,
    numberOfTickets?: number | null,
    connections?: string | null,
    last_attended_event?: string | null,
    last_attended_event_Name?: string | null,
    gender?: string | null,
    isGenderEdited?: boolean | null,
    group?: string | null,
    faceBookID?: string | null,
    appPassword?: string | null,
    birthdate?: string | null,
    isBirthdateEdited?: boolean | null,
    isVerified?: boolean | null,
    images?: Array< string | null > | null,
    address?: string | null,
    totalEvents?: number | null,
    flags?: Array< string | null > | null,
    frontPersonalID?: string | null,
    isFrontPersonalIDEdited?: boolean | null,
    passportImage?: string | null,
    isPassportImageEdited?: boolean | null,
    backPersonalID?: string | null,
    isBackPersonalIDEdited?: boolean | null,
    faceBookProfileLink?: string | null,
    instagramProfileLink?: string | null,
    nationality?: string | null,
    balance?: number | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    isBlocked?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteGuestMutationVariables = {
  input: DeleteGuestInput,
  condition?: ModelGuestConditionInput | null,
};

export type DeleteGuestMutation = {
  deleteGuest?:  {
    __typename: "Guest",
    id: string,
    name?: string | null,
    isNameEdited?: boolean | null,
    username?: string | null,
    email?: string | null,
    phone_number?: string | null,
    guestGroupID?: string | null,
    guestGroupName?: string | null,
    sub?: string | null,
    facebookSub?: string | null,
    guest_avatar?: string | null,
    avg_spend?: number | null,
    avg_ticket_type?: string | null,
    numberOfTickets?: number | null,
    connections?: string | null,
    last_attended_event?: string | null,
    last_attended_event_Name?: string | null,
    gender?: string | null,
    isGenderEdited?: boolean | null,
    group?: string | null,
    faceBookID?: string | null,
    appPassword?: string | null,
    birthdate?: string | null,
    isBirthdateEdited?: boolean | null,
    isVerified?: boolean | null,
    images?: Array< string | null > | null,
    address?: string | null,
    totalEvents?: number | null,
    flags?: Array< string | null > | null,
    frontPersonalID?: string | null,
    isFrontPersonalIDEdited?: boolean | null,
    passportImage?: string | null,
    isPassportImageEdited?: boolean | null,
    backPersonalID?: string | null,
    isBackPersonalIDEdited?: boolean | null,
    faceBookProfileLink?: string | null,
    instagramProfileLink?: string | null,
    nationality?: string | null,
    balance?: number | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    isBlocked?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateGuestGroupMutationVariables = {
  input: CreateGuestGroupInput,
  condition?: ModelGuestGroupConditionInput | null,
};

export type CreateGuestGroupMutation = {
  createGuestGroup?:  {
    __typename: "GuestGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    color?: string | null,
    guests?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateGuestGroupMutationVariables = {
  input: UpdateGuestGroupInput,
  condition?: ModelGuestGroupConditionInput | null,
};

export type UpdateGuestGroupMutation = {
  updateGuestGroup?:  {
    __typename: "GuestGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    color?: string | null,
    guests?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteGuestGroupMutationVariables = {
  input: DeleteGuestGroupInput,
  condition?: ModelGuestGroupConditionInput | null,
};

export type DeleteGuestGroupMutation = {
  deleteGuestGroup?:  {
    __typename: "GuestGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    color?: string | null,
    guests?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTimelineMutationVariables = {
  input: CreateTimelineInput,
  condition?: ModelTimelineConditionInput | null,
};

export type CreateTimelineMutation = {
  createTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    customerId?: string | null,
    isScanned?: string | null,
    type?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTimelineMutationVariables = {
  input: UpdateTimelineInput,
  condition?: ModelTimelineConditionInput | null,
};

export type UpdateTimelineMutation = {
  updateTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    customerId?: string | null,
    isScanned?: string | null,
    type?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTimelineMutationVariables = {
  input: DeleteTimelineInput,
  condition?: ModelTimelineConditionInput | null,
};

export type DeleteTimelineMutation = {
  deleteTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    customerId?: string | null,
    isScanned?: string | null,
    type?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFlagMutationVariables = {
  input: CreateFlagInput,
  condition?: ModelFlagConditionInput | null,
};

export type CreateFlagMutation = {
  createFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFlagMutationVariables = {
  input: UpdateFlagInput,
  condition?: ModelFlagConditionInput | null,
};

export type UpdateFlagMutation = {
  updateFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFlagMutationVariables = {
  input: DeleteFlagInput,
  condition?: ModelFlagConditionInput | null,
};

export type DeleteFlagMutation = {
  deleteFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    replyTo?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByImg?: string | null,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    replyTo?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByImg?: string | null,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    replyTo?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByImg?: string | null,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    name?: string | null,
    description?: string | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    location?:  {
      __typename: "Location",
      address?: string | null,
      coordinates?:  {
        __typename: "Coordinates",
        lat?: number | null,
        lng?: number | null,
      } | null,
    } | null,
    todoList?: Array< string | null > | null,
    eventComments?:  Array< {
      __typename: "EventComments",
      image?: string | null,
      name?: string | null,
      message?: string | null,
    } | null > | null,
    map?: string | null,
    image?: string | null,
    largeScreensImage?: string | null,
    mediumScreensImage?: string | null,
    smallScreensImage?: string | null,
    sendEmailToValidGuest?: boolean | null,
    sendSmsToValidGuest?: boolean | null,
    gallery?: Array< string | null > | null,
    visibleTo?: string | null,
    tickets?:  {
      __typename: "ModelEventTicketConnection",
      items:  Array< {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gates?:  {
      __typename: "ModelGateConnection",
      items:  Array< {
        __typename: "Gate",
        id: string,
        name: string,
        eventId: string,
        tickets?: Array< string | null > | null,
        admins?: Array< string | null > | null,
        checkpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invitationLimit?:  {
      __typename: "ModelInvitationLimitConnection",
      items:  Array< {
        __typename: "InvitationLimit",
        id: string,
        adminID?: string | null,
        quota?: number | null,
        eventID?: string | null,
        totalQuota?: number | null,
        sentInvitations?:  Array< {
          __typename: "sentInvitation",
          invitationNumber?: number | null,
          reciver?: string | null,
        } | null > | null,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventInvitationLimitId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    published: boolean,
    applyGenderRatio?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    name?: string | null,
    description?: string | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    location?:  {
      __typename: "Location",
      address?: string | null,
      coordinates?:  {
        __typename: "Coordinates",
        lat?: number | null,
        lng?: number | null,
      } | null,
    } | null,
    todoList?: Array< string | null > | null,
    eventComments?:  Array< {
      __typename: "EventComments",
      image?: string | null,
      name?: string | null,
      message?: string | null,
    } | null > | null,
    map?: string | null,
    image?: string | null,
    largeScreensImage?: string | null,
    mediumScreensImage?: string | null,
    smallScreensImage?: string | null,
    sendEmailToValidGuest?: boolean | null,
    sendSmsToValidGuest?: boolean | null,
    gallery?: Array< string | null > | null,
    visibleTo?: string | null,
    tickets?:  {
      __typename: "ModelEventTicketConnection",
      items:  Array< {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gates?:  {
      __typename: "ModelGateConnection",
      items:  Array< {
        __typename: "Gate",
        id: string,
        name: string,
        eventId: string,
        tickets?: Array< string | null > | null,
        admins?: Array< string | null > | null,
        checkpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invitationLimit?:  {
      __typename: "ModelInvitationLimitConnection",
      items:  Array< {
        __typename: "InvitationLimit",
        id: string,
        adminID?: string | null,
        quota?: number | null,
        eventID?: string | null,
        totalQuota?: number | null,
        sentInvitations?:  Array< {
          __typename: "sentInvitation",
          invitationNumber?: number | null,
          reciver?: string | null,
        } | null > | null,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventInvitationLimitId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    published: boolean,
    applyGenderRatio?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    name?: string | null,
    description?: string | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    location?:  {
      __typename: "Location",
      address?: string | null,
      coordinates?:  {
        __typename: "Coordinates",
        lat?: number | null,
        lng?: number | null,
      } | null,
    } | null,
    todoList?: Array< string | null > | null,
    eventComments?:  Array< {
      __typename: "EventComments",
      image?: string | null,
      name?: string | null,
      message?: string | null,
    } | null > | null,
    map?: string | null,
    image?: string | null,
    largeScreensImage?: string | null,
    mediumScreensImage?: string | null,
    smallScreensImage?: string | null,
    sendEmailToValidGuest?: boolean | null,
    sendSmsToValidGuest?: boolean | null,
    gallery?: Array< string | null > | null,
    visibleTo?: string | null,
    tickets?:  {
      __typename: "ModelEventTicketConnection",
      items:  Array< {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gates?:  {
      __typename: "ModelGateConnection",
      items:  Array< {
        __typename: "Gate",
        id: string,
        name: string,
        eventId: string,
        tickets?: Array< string | null > | null,
        admins?: Array< string | null > | null,
        checkpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invitationLimit?:  {
      __typename: "ModelInvitationLimitConnection",
      items:  Array< {
        __typename: "InvitationLimit",
        id: string,
        adminID?: string | null,
        quota?: number | null,
        eventID?: string | null,
        totalQuota?: number | null,
        sentInvitations?:  Array< {
          __typename: "sentInvitation",
          invitationNumber?: number | null,
          reciver?: string | null,
        } | null > | null,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventInvitationLimitId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    published: boolean,
    applyGenderRatio?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateEventTicketMutationVariables = {
  input: CreateEventTicketInput,
  condition?: ModelEventTicketConditionInput | null,
};

export type CreateEventTicketMutation = {
  createEventTicket?:  {
    __typename: "EventTicket",
    id: string,
    type: string,
    cashlessCredit?: number | null,
    description?: string | null,
    waves?:  Array< {
      __typename: "Wave",
      id: string,
      name: string,
      price: number,
      startDate?: string | null,
      endDate?: string | null,
      active?: boolean | null,
      quota?: number | null,
      AutomaticShift?: string | null,
    } | null > | null,
    color?: string | null,
    paymentRules?: string | null,
    approvalRule?: string | null,
    showAll?: boolean | null,
    showOnHold?: boolean | null,
    onHoldDisplayText?: string | null,
    showSoldOut?: boolean | null,
    soldOutDisplayText?: string | null,
    setAvailable?: boolean | null,
    availableAtDate?: string | null,
    allowTransferred?: boolean | null,
    transferredAprroval?: boolean | null,
    transferredSameGender?: boolean | null,
    showUpcomingWave?: boolean | null,
    showUpcomingWaveDisplayText?: string | null,
    eventID: string,
    gateID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateEventTicketMutationVariables = {
  input: UpdateEventTicketInput,
  condition?: ModelEventTicketConditionInput | null,
};

export type UpdateEventTicketMutation = {
  updateEventTicket?:  {
    __typename: "EventTicket",
    id: string,
    type: string,
    cashlessCredit?: number | null,
    description?: string | null,
    waves?:  Array< {
      __typename: "Wave",
      id: string,
      name: string,
      price: number,
      startDate?: string | null,
      endDate?: string | null,
      active?: boolean | null,
      quota?: number | null,
      AutomaticShift?: string | null,
    } | null > | null,
    color?: string | null,
    paymentRules?: string | null,
    approvalRule?: string | null,
    showAll?: boolean | null,
    showOnHold?: boolean | null,
    onHoldDisplayText?: string | null,
    showSoldOut?: boolean | null,
    soldOutDisplayText?: string | null,
    setAvailable?: boolean | null,
    availableAtDate?: string | null,
    allowTransferred?: boolean | null,
    transferredAprroval?: boolean | null,
    transferredSameGender?: boolean | null,
    showUpcomingWave?: boolean | null,
    showUpcomingWaveDisplayText?: string | null,
    eventID: string,
    gateID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteEventTicketMutationVariables = {
  input: DeleteEventTicketInput,
  condition?: ModelEventTicketConditionInput | null,
};

export type DeleteEventTicketMutation = {
  deleteEventTicket?:  {
    __typename: "EventTicket",
    id: string,
    type: string,
    cashlessCredit?: number | null,
    description?: string | null,
    waves?:  Array< {
      __typename: "Wave",
      id: string,
      name: string,
      price: number,
      startDate?: string | null,
      endDate?: string | null,
      active?: boolean | null,
      quota?: number | null,
      AutomaticShift?: string | null,
    } | null > | null,
    color?: string | null,
    paymentRules?: string | null,
    approvalRule?: string | null,
    showAll?: boolean | null,
    showOnHold?: boolean | null,
    onHoldDisplayText?: string | null,
    showSoldOut?: boolean | null,
    soldOutDisplayText?: string | null,
    setAvailable?: boolean | null,
    availableAtDate?: string | null,
    allowTransferred?: boolean | null,
    transferredAprroval?: boolean | null,
    transferredSameGender?: boolean | null,
    showUpcomingWave?: boolean | null,
    showUpcomingWaveDisplayText?: string | null,
    eventID: string,
    gateID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInvitationLimitMutationVariables = {
  input: CreateInvitationLimitInput,
  condition?: ModelInvitationLimitConditionInput | null,
};

export type CreateInvitationLimitMutation = {
  createInvitationLimit?:  {
    __typename: "InvitationLimit",
    id: string,
    adminID?: string | null,
    quota?: number | null,
    eventID?: string | null,
    totalQuota?: number | null,
    sentInvitations?:  Array< {
      __typename: "sentInvitation",
      invitationNumber?: number | null,
      reciver?: string | null,
    } | null > | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventInvitationLimitId?: string | null,
  } | null,
};

export type UpdateInvitationLimitMutationVariables = {
  input: UpdateInvitationLimitInput,
  condition?: ModelInvitationLimitConditionInput | null,
};

export type UpdateInvitationLimitMutation = {
  updateInvitationLimit?:  {
    __typename: "InvitationLimit",
    id: string,
    adminID?: string | null,
    quota?: number | null,
    eventID?: string | null,
    totalQuota?: number | null,
    sentInvitations?:  Array< {
      __typename: "sentInvitation",
      invitationNumber?: number | null,
      reciver?: string | null,
    } | null > | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventInvitationLimitId?: string | null,
  } | null,
};

export type DeleteInvitationLimitMutationVariables = {
  input: DeleteInvitationLimitInput,
  condition?: ModelInvitationLimitConditionInput | null,
};

export type DeleteInvitationLimitMutation = {
  deleteInvitationLimit?:  {
    __typename: "InvitationLimit",
    id: string,
    adminID?: string | null,
    quota?: number | null,
    eventID?: string | null,
    totalQuota?: number | null,
    sentInvitations?:  Array< {
      __typename: "sentInvitation",
      invitationNumber?: number | null,
      reciver?: string | null,
    } | null > | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventInvitationLimitId?: string | null,
  } | null,
};

export type CreateBookingMutationVariables = {
  input: CreateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type CreateBookingMutation = {
  createBooking?:  {
    __typename: "Booking",
    id: string,
    status?: string | null,
    overallStatus?: string | null,
    bookingGuestId?: string | null,
    bookingMainGuestId?: string | null,
    mainBookingId?: string | null,
    bookingEventId?: string | null,
    bookingEventTicketId?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mainGuest:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    wave?: string | null,
    waveId: string,
    isMainGuest?: boolean | null,
    isInvited?: boolean | null,
    orderId?: string | null,
    ticketNumber?: string | null,
    redeemed?: boolean | null,
    passedCheckpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    statusUpdatedByID?: string | null,
    statusUpdatedByName?: string | null,
    statusUpdatedAt?: string | null,
    specialNeed?: boolean | null,
    phone_number?: string | null,
    guestTicket?:  {
      __typename: "GuestTicket",
      number?: string | null,
      redeemed?: boolean | null,
    } | null,
    guestName?: string | null,
    notRegisteredGuests?: Array< string | null > | null,
    hasNotRegisteredGuest?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    rejectionReason?:  {
      __typename: "RejectReason",
      id: string,
      content: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    rejectionComment?: string | null,
    isPaid?: boolean | null,
    isTransfered?: boolean | null,
    isPendingTransfer?: boolean | null,
    lastGuestId?: string | null,
    lastGuest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lastMainGuestId?: string | null,
    ownerHistory?: Array< string | null > | null,
    paidAmount?: number | null,
    balance?: number | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    refunded?: boolean | null,
    paymentTransactionId?: string | null,
    paymentParentTransactionId?: string | null,
    origin?: string | null,
    paymentMethod?: string | null,
    testField?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    bookingRejectionReasonId?: string | null,
  } | null,
};

export type UpdateBookingMutationVariables = {
  input: UpdateBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type UpdateBookingMutation = {
  updateBooking?:  {
    __typename: "Booking",
    id: string,
    status?: string | null,
    overallStatus?: string | null,
    bookingGuestId?: string | null,
    bookingMainGuestId?: string | null,
    mainBookingId?: string | null,
    bookingEventId?: string | null,
    bookingEventTicketId?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mainGuest:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    wave?: string | null,
    waveId: string,
    isMainGuest?: boolean | null,
    isInvited?: boolean | null,
    orderId?: string | null,
    ticketNumber?: string | null,
    redeemed?: boolean | null,
    passedCheckpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    statusUpdatedByID?: string | null,
    statusUpdatedByName?: string | null,
    statusUpdatedAt?: string | null,
    specialNeed?: boolean | null,
    phone_number?: string | null,
    guestTicket?:  {
      __typename: "GuestTicket",
      number?: string | null,
      redeemed?: boolean | null,
    } | null,
    guestName?: string | null,
    notRegisteredGuests?: Array< string | null > | null,
    hasNotRegisteredGuest?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    rejectionReason?:  {
      __typename: "RejectReason",
      id: string,
      content: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    rejectionComment?: string | null,
    isPaid?: boolean | null,
    isTransfered?: boolean | null,
    isPendingTransfer?: boolean | null,
    lastGuestId?: string | null,
    lastGuest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lastMainGuestId?: string | null,
    ownerHistory?: Array< string | null > | null,
    paidAmount?: number | null,
    balance?: number | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    refunded?: boolean | null,
    paymentTransactionId?: string | null,
    paymentParentTransactionId?: string | null,
    origin?: string | null,
    paymentMethod?: string | null,
    testField?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    bookingRejectionReasonId?: string | null,
  } | null,
};

export type DeleteBookingMutationVariables = {
  input: DeleteBookingInput,
  condition?: ModelBookingConditionInput | null,
};

export type DeleteBookingMutation = {
  deleteBooking?:  {
    __typename: "Booking",
    id: string,
    status?: string | null,
    overallStatus?: string | null,
    bookingGuestId?: string | null,
    bookingMainGuestId?: string | null,
    mainBookingId?: string | null,
    bookingEventId?: string | null,
    bookingEventTicketId?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mainGuest:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    wave?: string | null,
    waveId: string,
    isMainGuest?: boolean | null,
    isInvited?: boolean | null,
    orderId?: string | null,
    ticketNumber?: string | null,
    redeemed?: boolean | null,
    passedCheckpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    statusUpdatedByID?: string | null,
    statusUpdatedByName?: string | null,
    statusUpdatedAt?: string | null,
    specialNeed?: boolean | null,
    phone_number?: string | null,
    guestTicket?:  {
      __typename: "GuestTicket",
      number?: string | null,
      redeemed?: boolean | null,
    } | null,
    guestName?: string | null,
    notRegisteredGuests?: Array< string | null > | null,
    hasNotRegisteredGuest?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    rejectionReason?:  {
      __typename: "RejectReason",
      id: string,
      content: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    rejectionComment?: string | null,
    isPaid?: boolean | null,
    isTransfered?: boolean | null,
    isPendingTransfer?: boolean | null,
    lastGuestId?: string | null,
    lastGuest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lastMainGuestId?: string | null,
    ownerHistory?: Array< string | null > | null,
    paidAmount?: number | null,
    balance?: number | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    refunded?: boolean | null,
    paymentTransactionId?: string | null,
    paymentParentTransactionId?: string | null,
    origin?: string | null,
    paymentMethod?: string | null,
    testField?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    bookingRejectionReasonId?: string | null,
  } | null,
};

export type CreateRejectReasonMutationVariables = {
  input: CreateRejectReasonInput,
  condition?: ModelRejectReasonConditionInput | null,
};

export type CreateRejectReasonMutation = {
  createRejectReason?:  {
    __typename: "RejectReason",
    id: string,
    content: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRejectReasonMutationVariables = {
  input: UpdateRejectReasonInput,
  condition?: ModelRejectReasonConditionInput | null,
};

export type UpdateRejectReasonMutation = {
  updateRejectReason?:  {
    __typename: "RejectReason",
    id: string,
    content: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRejectReasonMutationVariables = {
  input: DeleteRejectReasonInput,
  condition?: ModelRejectReasonConditionInput | null,
};

export type DeleteRejectReasonMutation = {
  deleteRejectReason?:  {
    __typename: "RejectReason",
    id: string,
    content: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInvitationMutationVariables = {
  input: CreateInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type CreateInvitationMutation = {
  createInvitation?:  {
    __typename: "Invitation",
    id: string,
    invitationEventId?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    balance?: number | null,
    wave?: string | null,
    phone_number?: string | null,
    name?: string | null,
    email?: string | null,
    reason?: string | null,
    secret?: string | null,
    used?: boolean | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    invitationEventTicketId: string,
  } | null,
};

export type UpdateInvitationMutationVariables = {
  input: UpdateInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type UpdateInvitationMutation = {
  updateInvitation?:  {
    __typename: "Invitation",
    id: string,
    invitationEventId?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    balance?: number | null,
    wave?: string | null,
    phone_number?: string | null,
    name?: string | null,
    email?: string | null,
    reason?: string | null,
    secret?: string | null,
    used?: boolean | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    invitationEventTicketId: string,
  } | null,
};

export type DeleteInvitationMutationVariables = {
  input: DeleteInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type DeleteInvitationMutation = {
  deleteInvitation?:  {
    __typename: "Invitation",
    id: string,
    invitationEventId?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    balance?: number | null,
    wave?: string | null,
    phone_number?: string | null,
    name?: string | null,
    email?: string | null,
    reason?: string | null,
    secret?: string | null,
    used?: boolean | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    invitationEventTicketId: string,
  } | null,
};

export type CreateTransactionMutationVariables = {
  input: CreateTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type CreateTransactionMutation = {
  createTransaction?:  {
    __typename: "Transaction",
    id: string,
    type?: string | null,
    guestId?: string | null,
    guestName?: string | null,
    guestsNames?: Array< string | null > | null,
    guestPhone_number?: string | null,
    eventId: string,
    ticketId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    success?: boolean | null,
    failureReason?: string | null,
    currency?: string | null,
    amount_cents?: string | null,
    refund?: boolean | null,
    refunded_amount_cents?: string | null,
    orderID: string,
    transactionID: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transactionBookingId?: string | null,
  } | null,
};

export type UpdateTransactionMutationVariables = {
  input: UpdateTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type UpdateTransactionMutation = {
  updateTransaction?:  {
    __typename: "Transaction",
    id: string,
    type?: string | null,
    guestId?: string | null,
    guestName?: string | null,
    guestsNames?: Array< string | null > | null,
    guestPhone_number?: string | null,
    eventId: string,
    ticketId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    success?: boolean | null,
    failureReason?: string | null,
    currency?: string | null,
    amount_cents?: string | null,
    refund?: boolean | null,
    refunded_amount_cents?: string | null,
    orderID: string,
    transactionID: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transactionBookingId?: string | null,
  } | null,
};

export type DeleteTransactionMutationVariables = {
  input: DeleteTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type DeleteTransactionMutation = {
  deleteTransaction?:  {
    __typename: "Transaction",
    id: string,
    type?: string | null,
    guestId?: string | null,
    guestName?: string | null,
    guestsNames?: Array< string | null > | null,
    guestPhone_number?: string | null,
    eventId: string,
    ticketId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    success?: boolean | null,
    failureReason?: string | null,
    currency?: string | null,
    amount_cents?: string | null,
    refund?: boolean | null,
    refunded_amount_cents?: string | null,
    orderID: string,
    transactionID: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transactionBookingId?: string | null,
  } | null,
};

export type CreateWavesConsumptionMutationVariables = {
  input: CreateWavesConsumptionInput,
  condition?: ModelWavesConsumptionConditionInput | null,
};

export type CreateWavesConsumptionMutation = {
  createWavesConsumption?:  {
    __typename: "WavesConsumption",
    waveId: string,
    consumedTickets?: number | null,
    reservedTickets?: number | null,
    totalTickets: number,
    consumed?: string | null,
    eventId: string,
    eventTicketId?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWavesConsumptionMutationVariables = {
  input: UpdateWavesConsumptionInput,
  condition?: ModelWavesConsumptionConditionInput | null,
};

export type UpdateWavesConsumptionMutation = {
  updateWavesConsumption?:  {
    __typename: "WavesConsumption",
    waveId: string,
    consumedTickets?: number | null,
    reservedTickets?: number | null,
    totalTickets: number,
    consumed?: string | null,
    eventId: string,
    eventTicketId?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWavesConsumptionMutationVariables = {
  input: DeleteWavesConsumptionInput,
  condition?: ModelWavesConsumptionConditionInput | null,
};

export type DeleteWavesConsumptionMutation = {
  deleteWavesConsumption?:  {
    __typename: "WavesConsumption",
    waveId: string,
    consumedTickets?: number | null,
    reservedTickets?: number | null,
    totalTickets: number,
    consumed?: string | null,
    eventId: string,
    eventTicketId?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCashlessTransactionMutationVariables = {
  input: CreateCashlessTransactionInput,
  condition?: ModelCashlessTransactionConditionInput | null,
};

export type CreateCashlessTransactionMutation = {
  createCashlessTransaction?:  {
    __typename: "CashlessTransaction",
    id: string,
    pos: string,
    checkNumber: string,
    orderTotal: number,
    paidAmount: number,
    paymentStatus: string,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    guestName: string,
    type?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCashlessTransactionMutationVariables = {
  input: UpdateCashlessTransactionInput,
  condition?: ModelCashlessTransactionConditionInput | null,
};

export type UpdateCashlessTransactionMutation = {
  updateCashlessTransaction?:  {
    __typename: "CashlessTransaction",
    id: string,
    pos: string,
    checkNumber: string,
    orderTotal: number,
    paidAmount: number,
    paymentStatus: string,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    guestName: string,
    type?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCashlessTransactionMutationVariables = {
  input: DeleteCashlessTransactionInput,
  condition?: ModelCashlessTransactionConditionInput | null,
};

export type DeleteCashlessTransactionMutation = {
  deleteCashlessTransaction?:  {
    __typename: "CashlessTransaction",
    id: string,
    pos: string,
    checkNumber: string,
    orderTotal: number,
    paidAmount: number,
    paymentStatus: string,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    guestName: string,
    type?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCashlessTopUpMutationVariables = {
  input: CreateCashlessTopUpInput,
  condition?: ModelCashlessTopUpConditionInput | null,
};

export type CreateCashlessTopUpMutation = {
  createCashlessTopUp?:  {
    __typename: "CashlessTopUp",
    id: string,
    operation: string,
    amount: number,
    availableBalance?: number | null,
    paymentChannel: string,
    paymentMethod: string,
    isVoided?: string | null,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    bookingId?: string | null,
    invitationId?: string | null,
    ticketType?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    invitation?:  {
      __typename: "Invitation",
      id: string,
      invitationEventId?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      balance?: number | null,
      wave?: string | null,
      phone_number?: string | null,
      name?: string | null,
      email?: string | null,
      reason?: string | null,
      secret?: string | null,
      used?: boolean | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      invitationEventTicketId: string,
    } | null,
    eventId: string,
    eventName?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    status: string,
    type?: string | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    guestName: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCashlessTopUpMutationVariables = {
  input: UpdateCashlessTopUpInput,
  condition?: ModelCashlessTopUpConditionInput | null,
};

export type UpdateCashlessTopUpMutation = {
  updateCashlessTopUp?:  {
    __typename: "CashlessTopUp",
    id: string,
    operation: string,
    amount: number,
    availableBalance?: number | null,
    paymentChannel: string,
    paymentMethod: string,
    isVoided?: string | null,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    bookingId?: string | null,
    invitationId?: string | null,
    ticketType?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    invitation?:  {
      __typename: "Invitation",
      id: string,
      invitationEventId?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      balance?: number | null,
      wave?: string | null,
      phone_number?: string | null,
      name?: string | null,
      email?: string | null,
      reason?: string | null,
      secret?: string | null,
      used?: boolean | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      invitationEventTicketId: string,
    } | null,
    eventId: string,
    eventName?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    status: string,
    type?: string | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    guestName: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCashlessTopUpMutationVariables = {
  input: DeleteCashlessTopUpInput,
  condition?: ModelCashlessTopUpConditionInput | null,
};

export type DeleteCashlessTopUpMutation = {
  deleteCashlessTopUp?:  {
    __typename: "CashlessTopUp",
    id: string,
    operation: string,
    amount: number,
    availableBalance?: number | null,
    paymentChannel: string,
    paymentMethod: string,
    isVoided?: string | null,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    bookingId?: string | null,
    invitationId?: string | null,
    ticketType?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    invitation?:  {
      __typename: "Invitation",
      id: string,
      invitationEventId?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      balance?: number | null,
      wave?: string | null,
      phone_number?: string | null,
      name?: string | null,
      email?: string | null,
      reason?: string | null,
      secret?: string | null,
      used?: boolean | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      invitationEventTicketId: string,
    } | null,
    eventId: string,
    eventName?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    status: string,
    type?: string | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    guestName: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    precedence: number,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        image?: string | null,
        price: number,
        precedence: number,
        categoryID: string,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    precedence: number,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        image?: string | null,
        price: number,
        precedence: number,
        categoryID: string,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    precedence: number,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        image?: string | null,
        price: number,
        precedence: number,
        categoryID: string,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateItemMutationVariables = {
  input: CreateItemInput,
  condition?: ModelItemConditionInput | null,
};

export type CreateItemMutation = {
  createItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    image?: string | null,
    price: number,
    precedence: number,
    categoryID: string,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateItemMutationVariables = {
  input: UpdateItemInput,
  condition?: ModelItemConditionInput | null,
};

export type UpdateItemMutation = {
  updateItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    image?: string | null,
    price: number,
    precedence: number,
    categoryID: string,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteItemMutationVariables = {
  input: DeleteItemInput,
  condition?: ModelItemConditionInput | null,
};

export type DeleteItemMutation = {
  deleteItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    image?: string | null,
    price: number,
    precedence: number,
    categoryID: string,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateItemConsumptionMutationVariables = {
  input: CreateItemConsumptionInput,
  condition?: ModelItemConsumptionConditionInput | null,
};

export type CreateItemConsumptionMutation = {
  createItemConsumption?:  {
    __typename: "ItemConsumption",
    id: string,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      image?: string | null,
      price: number,
      precedence: number,
      categoryID: string,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    itemName: string,
    itemQuantity: number,
    itemPrice: number,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateItemConsumptionMutationVariables = {
  input: UpdateItemConsumptionInput,
  condition?: ModelItemConsumptionConditionInput | null,
};

export type UpdateItemConsumptionMutation = {
  updateItemConsumption?:  {
    __typename: "ItemConsumption",
    id: string,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      image?: string | null,
      price: number,
      precedence: number,
      categoryID: string,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    itemName: string,
    itemQuantity: number,
    itemPrice: number,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteItemConsumptionMutationVariables = {
  input: DeleteItemConsumptionInput,
  condition?: ModelItemConsumptionConditionInput | null,
};

export type DeleteItemConsumptionMutation = {
  deleteItemConsumption?:  {
    __typename: "ItemConsumption",
    id: string,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      image?: string | null,
      price: number,
      precedence: number,
      categoryID: string,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    itemName: string,
    itemQuantity: number,
    itemPrice: number,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTicketsScanMutationVariables = {
  input: CreateTicketsScanInput,
  condition?: ModelTicketsScanConditionInput | null,
};

export type CreateTicketsScanMutation = {
  createTicketsScan?:  {
    __typename: "TicketsScan",
    id: string,
    scannedById: string,
    scannedAt?: string | null,
    status?: string | null,
    eventId?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    guestName?: string | null,
    ticketType?: string | null,
    guestAvatar?: string | null,
    deleted?: string | null,
    gateName?: string | null,
    checkpointName?: string | null,
    scannedByName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTicketsScanMutationVariables = {
  input: UpdateTicketsScanInput,
  condition?: ModelTicketsScanConditionInput | null,
};

export type UpdateTicketsScanMutation = {
  updateTicketsScan?:  {
    __typename: "TicketsScan",
    id: string,
    scannedById: string,
    scannedAt?: string | null,
    status?: string | null,
    eventId?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    guestName?: string | null,
    ticketType?: string | null,
    guestAvatar?: string | null,
    deleted?: string | null,
    gateName?: string | null,
    checkpointName?: string | null,
    scannedByName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTicketsScanMutationVariables = {
  input: DeleteTicketsScanInput,
  condition?: ModelTicketsScanConditionInput | null,
};

export type DeleteTicketsScanMutation = {
  deleteTicketsScan?:  {
    __typename: "TicketsScan",
    id: string,
    scannedById: string,
    scannedAt?: string | null,
    status?: string | null,
    eventId?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    guestName?: string | null,
    ticketType?: string | null,
    guestAvatar?: string | null,
    deleted?: string | null,
    gateName?: string | null,
    checkpointName?: string | null,
    scannedByName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateGateMutationVariables = {
  input: CreateGateInput,
  condition?: ModelGateConditionInput | null,
};

export type CreateGateMutation = {
  createGate?:  {
    __typename: "Gate",
    id: string,
    name: string,
    eventId: string,
    tickets?: Array< string | null > | null,
    admins?: Array< string | null > | null,
    checkpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateGateMutationVariables = {
  input: UpdateGateInput,
  condition?: ModelGateConditionInput | null,
};

export type UpdateGateMutation = {
  updateGate?:  {
    __typename: "Gate",
    id: string,
    name: string,
    eventId: string,
    tickets?: Array< string | null > | null,
    admins?: Array< string | null > | null,
    checkpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteGateMutationVariables = {
  input: DeleteGateInput,
  condition?: ModelGateConditionInput | null,
};

export type DeleteGateMutation = {
  deleteGate?:  {
    __typename: "Gate",
    id: string,
    name: string,
    eventId: string,
    tickets?: Array< string | null > | null,
    admins?: Array< string | null > | null,
    checkpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    id: string,
    bookingId?: string | null,
    eventId?: string | null,
    userId?: string | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    status?: string | null,
    dispatcherId?: string | null,
    totalAmount?: number | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    id: string,
    bookingId?: string | null,
    eventId?: string | null,
    userId?: string | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    status?: string | null,
    dispatcherId?: string | null,
    totalAmount?: number | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    id: string,
    bookingId?: string | null,
    eventId?: string | null,
    userId?: string | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    status?: string | null,
    dispatcherId?: string | null,
    totalAmount?: number | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserIPMutationVariables = {
  input: CreateUserIPInput,
  condition?: ModelUserIPConditionInput | null,
};

export type CreateUserIPMutation = {
  createUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserIPMutationVariables = {
  input: UpdateUserIPInput,
  condition?: ModelUserIPConditionInput | null,
};

export type UpdateUserIPMutation = {
  updateUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserIPMutationVariables = {
  input: DeleteUserIPInput,
  condition?: ModelUserIPConditionInput | null,
};

export type DeleteUserIPMutation = {
  deleteUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateScanMutationVariables = {
  input: CreateScanInput,
  condition?: ModelScanConditionInput | null,
};

export type CreateScanMutation = {
  createScan?:  {
    __typename: "Scan",
    id: string,
    link: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateScanMutationVariables = {
  input: UpdateScanInput,
  condition?: ModelScanConditionInput | null,
};

export type UpdateScanMutation = {
  updateScan?:  {
    __typename: "Scan",
    id: string,
    link: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteScanMutationVariables = {
  input: DeleteScanInput,
  condition?: ModelScanConditionInput | null,
};

export type DeleteScanMutation = {
  deleteScan?:  {
    __typename: "Scan",
    id: string,
    link: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    currency?: string | null,
    merchantVersion?: string | null,
    merchantID?: string | null,
    merchantIframe?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    accountLogoId?: string | null,
  } | null,
};

export type ListAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        mediaID: string,
        fileUrl: string,
        filename: string,
        filetype?: string | null,
        fileSize?: number | null,
        alternativeText?: string | null,
        caption?: string | null,
        description?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      domain: string,
      siteTitle: string,
      guestsCount?: number | null,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages: Array< string | null >,
      features: Array< string | null >,
      status: string,
      socialLinks?: Array< string | null > | null,
      currency?: string | null,
      merchantVersion?: string | null,
      merchantID?: string | null,
      merchantIframe?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      accountLogoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAccountsQuery = {
  syncAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      logo?:  {
        __typename: "Attachment",
        id: string,
        mediaID: string,
        fileUrl: string,
        filename: string,
        filetype?: string | null,
        fileSize?: number | null,
        alternativeText?: string | null,
        caption?: string | null,
        description?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      domain: string,
      siteTitle: string,
      guestsCount?: number | null,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages: Array< string | null >,
      features: Array< string | null >,
      status: string,
      socialLinks?: Array< string | null > | null,
      currency?: string | null,
      merchantVersion?: string | null,
      merchantID?: string | null,
      merchantIframe?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      accountLogoId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetConceptQueryVariables = {
  id: string,
};

export type GetConceptQuery = {
  getConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListConceptsQueryVariables = {
  filter?: ModelConceptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConceptsQuery = {
  listConcepts?:  {
    __typename: "ModelConceptConnection",
    items:  Array< {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      description?: string | null,
      logo?: string | null,
      type?: string | null,
      location?: string | null,
      precedence?: number | null,
      longitude?: string | null,
      latitude?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncConceptsQueryVariables = {
  filter?: ModelConceptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncConceptsQuery = {
  syncConcepts?:  {
    __typename: "ModelConceptConnection",
    items:  Array< {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      description?: string | null,
      logo?: string | null,
      type?: string | null,
      location?: string | null,
      precedence?: number | null,
      longitude?: string | null,
      latitude?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLanguageQueryVariables = {
  id: string,
};

export type GetLanguageQuery = {
  getLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLanguagesQueryVariables = {
  filter?: ModelLanguageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLanguagesQuery = {
  listLanguages?:  {
    __typename: "ModelLanguageConnection",
    items:  Array< {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLanguagesQueryVariables = {
  filter?: ModelLanguageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLanguagesQuery = {
  syncLanguages?:  {
    __typename: "ModelLanguageConnection",
    items:  Array< {
      __typename: "Language",
      id: string,
      name: string,
      code: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFeatureQueryVariables = {
  id: string,
};

export type GetFeatureQuery = {
  getFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFeaturesQueryVariables = {
  filter?: ModelFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeaturesQuery = {
  listFeatures?:  {
    __typename: "ModelFeatureConnection",
    items:  Array< {
      __typename: "Feature",
      id: string,
      name: string,
      icon?: string | null,
      slug: string,
      precedence: string,
      parent?: string | null,
      private?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFeaturesQueryVariables = {
  filter?: ModelFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFeaturesQuery = {
  syncFeatures?:  {
    __typename: "ModelFeatureConnection",
    items:  Array< {
      __typename: "Feature",
      id: string,
      name: string,
      icon?: string | null,
      slug: string,
      precedence: string,
      parent?: string | null,
      private?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAdminRoleQueryVariables = {
  id: string,
};

export type GetAdminRoleQuery = {
  getAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAdminRolesQueryVariables = {
  filter?: ModelAdminRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminRolesQuery = {
  listAdminRoles?:  {
    __typename: "ModelAdminRoleConnection",
    items:  Array< {
      __typename: "AdminRole",
      id: string,
      name?: string | null,
      description?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAdminRolesQueryVariables = {
  filter?: ModelAdminRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAdminRolesQuery = {
  syncAdminRoles?:  {
    __typename: "ModelAdminRoleConnection",
    items:  Array< {
      __typename: "AdminRole",
      id: string,
      name?: string | null,
      description?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAdminGroupQueryVariables = {
  id: string,
};

export type GetAdminGroupQuery = {
  getAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAdminGroupsQueryVariables = {
  filter?: ModelAdminGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminGroupsQuery = {
  listAdminGroups?:  {
    __typename: "ModelAdminGroupConnection",
    items:  Array< {
      __typename: "AdminGroup",
      id: string,
      name?: string | null,
      description?: string | null,
      roles?: Array< string | null > | null,
      users?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAdminGroupsQueryVariables = {
  filter?: ModelAdminGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAdminGroupsQuery = {
  syncAdminGroups?:  {
    __typename: "ModelAdminGroupConnection",
    items:  Array< {
      __typename: "AdminGroup",
      id: string,
      name?: string | null,
      description?: string | null,
      roles?: Array< string | null > | null,
      users?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AdminGroupsByDeletedQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAdminGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AdminGroupsByDeletedQuery = {
  adminGroupsByDeleted?:  {
    __typename: "ModelAdminGroupConnection",
    items:  Array< {
      __typename: "AdminGroup",
      id: string,
      name?: string | null,
      description?: string | null,
      roles?: Array< string | null > | null,
      users?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAttachmentQueryVariables = {
  id: string,
};

export type GetAttachmentQuery = {
  getAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAttachmentsQueryVariables = {
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttachmentsQuery = {
  listAttachments?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAttachmentsQueryVariables = {
  filter?: ModelAttachmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAttachmentsQuery = {
  syncAttachments?:  {
    __typename: "ModelAttachmentConnection",
    items:  Array< {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserConceptsQueryVariables = {
  id: string,
};

export type GetUserConceptsQuery = {
  getUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserConceptsQueryVariables = {
  filter?: ModelUserConceptsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserConceptsQuery = {
  listUserConcepts?:  {
    __typename: "ModelUserConceptsConnection",
    items:  Array< {
      __typename: "UserConcepts",
      id: string,
      defaultConcept?: string | null,
      concepts?: Array< string | null > | null,
      conceptsRoles?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserConceptsQueryVariables = {
  filter?: ModelUserConceptsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserConceptsQuery = {
  syncUserConcepts?:  {
    __typename: "ModelUserConceptsConnection",
    items:  Array< {
      __typename: "UserConcepts",
      id: string,
      defaultConcept?: string | null,
      concepts?: Array< string | null > | null,
      conceptsRoles?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGuestQueryVariables = {
  id: string,
};

export type GetGuestQuery = {
  getGuest?:  {
    __typename: "Guest",
    id: string,
    name?: string | null,
    isNameEdited?: boolean | null,
    username?: string | null,
    email?: string | null,
    phone_number?: string | null,
    guestGroupID?: string | null,
    guestGroupName?: string | null,
    sub?: string | null,
    facebookSub?: string | null,
    guest_avatar?: string | null,
    avg_spend?: number | null,
    avg_ticket_type?: string | null,
    numberOfTickets?: number | null,
    connections?: string | null,
    last_attended_event?: string | null,
    last_attended_event_Name?: string | null,
    gender?: string | null,
    isGenderEdited?: boolean | null,
    group?: string | null,
    faceBookID?: string | null,
    appPassword?: string | null,
    birthdate?: string | null,
    isBirthdateEdited?: boolean | null,
    isVerified?: boolean | null,
    images?: Array< string | null > | null,
    address?: string | null,
    totalEvents?: number | null,
    flags?: Array< string | null > | null,
    frontPersonalID?: string | null,
    isFrontPersonalIDEdited?: boolean | null,
    passportImage?: string | null,
    isPassportImageEdited?: boolean | null,
    backPersonalID?: string | null,
    isBackPersonalIDEdited?: boolean | null,
    faceBookProfileLink?: string | null,
    instagramProfileLink?: string | null,
    nationality?: string | null,
    balance?: number | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    isBlocked?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListGuestsQueryVariables = {
  filter?: ModelGuestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGuestsQuery = {
  listGuests?:  {
    __typename: "ModelGuestConnection",
    items:  Array< {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGuestsQueryVariables = {
  filter?: ModelGuestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGuestsQuery = {
  syncGuests?:  {
    __typename: "ModelGuestConnection",
    items:  Array< {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ByEmailQueryVariables = {
  email: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGuestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByEmailQuery = {
  ByEmail?:  {
    __typename: "ModelGuestConnection",
    items:  Array< {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ByPhoneNumberQueryVariables = {
  phone_number: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGuestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByPhoneNumberQuery = {
  ByPhoneNumber?:  {
    __typename: "ModelGuestConnection",
    items:  Array< {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UsersByFacebookSubQueryVariables = {
  facebookSub: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGuestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByFacebookSubQuery = {
  usersByFacebookSub?:  {
    __typename: "ModelGuestConnection",
    items:  Array< {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UserByDeletedQueryVariables = {
  deleted: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGuestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByDeletedQuery = {
  userByDeleted?:  {
    __typename: "ModelGuestConnection",
    items:  Array< {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGuestGroupQueryVariables = {
  id: string,
};

export type GetGuestGroupQuery = {
  getGuestGroup?:  {
    __typename: "GuestGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    color?: string | null,
    guests?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListGuestGroupsQueryVariables = {
  filter?: ModelGuestGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGuestGroupsQuery = {
  listGuestGroups?:  {
    __typename: "ModelGuestGroupConnection",
    items:  Array< {
      __typename: "GuestGroup",
      id: string,
      name?: string | null,
      description?: string | null,
      color?: string | null,
      guests?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGuestGroupsQueryVariables = {
  filter?: ModelGuestGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGuestGroupsQuery = {
  syncGuestGroups?:  {
    __typename: "ModelGuestGroupConnection",
    items:  Array< {
      __typename: "GuestGroup",
      id: string,
      name?: string | null,
      description?: string | null,
      color?: string | null,
      guests?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTimelineQueryVariables = {
  id: string,
};

export type GetTimelineQuery = {
  getTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    customerId?: string | null,
    isScanned?: string | null,
    type?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTimelinesQueryVariables = {
  filter?: ModelTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTimelinesQuery = {
  listTimelines?:  {
    __typename: "ModelTimelineConnection",
    items:  Array< {
      __typename: "Timeline",
      id: string,
      actionName: string,
      oldStatus: string,
      newStatus: string,
      bookingId?: string | null,
      customerId?: string | null,
      isScanned?: string | null,
      type?: string | null,
      description?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTimelinesQueryVariables = {
  filter?: ModelTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTimelinesQuery = {
  syncTimelines?:  {
    __typename: "ModelTimelineConnection",
    items:  Array< {
      __typename: "Timeline",
      id: string,
      actionName: string,
      oldStatus: string,
      newStatus: string,
      bookingId?: string | null,
      customerId?: string | null,
      isScanned?: string | null,
      type?: string | null,
      description?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TimelineByBookingIDQueryVariables = {
  bookingId: string,
  isScanned?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimelineByBookingIDQuery = {
  timelineByBookingID?:  {
    __typename: "ModelTimelineConnection",
    items:  Array< {
      __typename: "Timeline",
      id: string,
      actionName: string,
      oldStatus: string,
      newStatus: string,
      bookingId?: string | null,
      customerId?: string | null,
      isScanned?: string | null,
      type?: string | null,
      description?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TimelineByCustomerIDQueryVariables = {
  customerId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimelineByCustomerIDQuery = {
  timelineByCustomerID?:  {
    __typename: "ModelTimelineConnection",
    items:  Array< {
      __typename: "Timeline",
      id: string,
      actionName: string,
      oldStatus: string,
      newStatus: string,
      bookingId?: string | null,
      customerId?: string | null,
      isScanned?: string | null,
      type?: string | null,
      description?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFlagQueryVariables = {
  id: string,
};

export type GetFlagQuery = {
  getFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFlagsQueryVariables = {
  filter?: ModelFlagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFlagsQuery = {
  listFlags?:  {
    __typename: "ModelFlagConnection",
    items:  Array< {
      __typename: "Flag",
      id: string,
      accountID: string,
      name: string,
      icon: string,
      color?: string | null,
      customers?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFlagsQueryVariables = {
  filter?: ModelFlagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFlagsQuery = {
  syncFlags?:  {
    __typename: "ModelFlagConnection",
    items:  Array< {
      __typename: "Flag",
      id: string,
      accountID: string,
      name: string,
      icon: string,
      color?: string | null,
      customers?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    replyTo?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByImg?: string | null,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      message: string,
      customerId?: string | null,
      bookingId?: string | null,
      replyTo?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByImg?: string | null,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCommentsQuery = {
  syncComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      message: string,
      customerId?: string | null,
      bookingId?: string | null,
      replyTo?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByImg?: string | null,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    name?: string | null,
    description?: string | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    location?:  {
      __typename: "Location",
      address?: string | null,
      coordinates?:  {
        __typename: "Coordinates",
        lat?: number | null,
        lng?: number | null,
      } | null,
    } | null,
    todoList?: Array< string | null > | null,
    eventComments?:  Array< {
      __typename: "EventComments",
      image?: string | null,
      name?: string | null,
      message?: string | null,
    } | null > | null,
    map?: string | null,
    image?: string | null,
    largeScreensImage?: string | null,
    mediumScreensImage?: string | null,
    smallScreensImage?: string | null,
    sendEmailToValidGuest?: boolean | null,
    sendSmsToValidGuest?: boolean | null,
    gallery?: Array< string | null > | null,
    visibleTo?: string | null,
    tickets?:  {
      __typename: "ModelEventTicketConnection",
      items:  Array< {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gates?:  {
      __typename: "ModelGateConnection",
      items:  Array< {
        __typename: "Gate",
        id: string,
        name: string,
        eventId: string,
        tickets?: Array< string | null > | null,
        admins?: Array< string | null > | null,
        checkpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invitationLimit?:  {
      __typename: "ModelInvitationLimitConnection",
      items:  Array< {
        __typename: "InvitationLimit",
        id: string,
        adminID?: string | null,
        quota?: number | null,
        eventID?: string | null,
        totalQuota?: number | null,
        sentInvitations?:  Array< {
          __typename: "sentInvitation",
          invitationNumber?: number | null,
          reciver?: string | null,
        } | null > | null,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventInvitationLimitId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    published: boolean,
    applyGenderRatio?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEventsQuery = {
  syncEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEventTicketQueryVariables = {
  id: string,
};

export type GetEventTicketQuery = {
  getEventTicket?:  {
    __typename: "EventTicket",
    id: string,
    type: string,
    cashlessCredit?: number | null,
    description?: string | null,
    waves?:  Array< {
      __typename: "Wave",
      id: string,
      name: string,
      price: number,
      startDate?: string | null,
      endDate?: string | null,
      active?: boolean | null,
      quota?: number | null,
      AutomaticShift?: string | null,
    } | null > | null,
    color?: string | null,
    paymentRules?: string | null,
    approvalRule?: string | null,
    showAll?: boolean | null,
    showOnHold?: boolean | null,
    onHoldDisplayText?: string | null,
    showSoldOut?: boolean | null,
    soldOutDisplayText?: string | null,
    setAvailable?: boolean | null,
    availableAtDate?: string | null,
    allowTransferred?: boolean | null,
    transferredAprroval?: boolean | null,
    transferredSameGender?: boolean | null,
    showUpcomingWave?: boolean | null,
    showUpcomingWaveDisplayText?: string | null,
    eventID: string,
    gateID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListEventTicketsQueryVariables = {
  filter?: ModelEventTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventTicketsQuery = {
  listEventTickets?:  {
    __typename: "ModelEventTicketConnection",
    items:  Array< {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEventTicketsQueryVariables = {
  filter?: ModelEventTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEventTicketsQuery = {
  syncEventTickets?:  {
    __typename: "ModelEventTicketConnection",
    items:  Array< {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ByEventIDQueryVariables = {
  eventID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByEventIDQuery = {
  byEventID?:  {
    __typename: "ModelEventTicketConnection",
    items:  Array< {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ByGateIDQueryVariables = {
  gateID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByGateIDQuery = {
  byGateID?:  {
    __typename: "ModelEventTicketConnection",
    items:  Array< {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInvitationLimitQueryVariables = {
  id: string,
};

export type GetInvitationLimitQuery = {
  getInvitationLimit?:  {
    __typename: "InvitationLimit",
    id: string,
    adminID?: string | null,
    quota?: number | null,
    eventID?: string | null,
    totalQuota?: number | null,
    sentInvitations?:  Array< {
      __typename: "sentInvitation",
      invitationNumber?: number | null,
      reciver?: string | null,
    } | null > | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventInvitationLimitId?: string | null,
  } | null,
};

export type ListInvitationLimitsQueryVariables = {
  filter?: ModelInvitationLimitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvitationLimitsQuery = {
  listInvitationLimits?:  {
    __typename: "ModelInvitationLimitConnection",
    items:  Array< {
      __typename: "InvitationLimit",
      id: string,
      adminID?: string | null,
      quota?: number | null,
      eventID?: string | null,
      totalQuota?: number | null,
      sentInvitations?:  Array< {
        __typename: "sentInvitation",
        invitationNumber?: number | null,
        reciver?: string | null,
      } | null > | null,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventInvitationLimitId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInvitationLimitsQueryVariables = {
  filter?: ModelInvitationLimitFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInvitationLimitsQuery = {
  syncInvitationLimits?:  {
    __typename: "ModelInvitationLimitConnection",
    items:  Array< {
      __typename: "InvitationLimit",
      id: string,
      adminID?: string | null,
      quota?: number | null,
      eventID?: string | null,
      totalQuota?: number | null,
      sentInvitations?:  Array< {
        __typename: "sentInvitation",
        invitationNumber?: number | null,
        reciver?: string | null,
      } | null > | null,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      eventInvitationLimitId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBookingQueryVariables = {
  id: string,
};

export type GetBookingQuery = {
  getBooking?:  {
    __typename: "Booking",
    id: string,
    status?: string | null,
    overallStatus?: string | null,
    bookingGuestId?: string | null,
    bookingMainGuestId?: string | null,
    mainBookingId?: string | null,
    bookingEventId?: string | null,
    bookingEventTicketId?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mainGuest:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    wave?: string | null,
    waveId: string,
    isMainGuest?: boolean | null,
    isInvited?: boolean | null,
    orderId?: string | null,
    ticketNumber?: string | null,
    redeemed?: boolean | null,
    passedCheckpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    statusUpdatedByID?: string | null,
    statusUpdatedByName?: string | null,
    statusUpdatedAt?: string | null,
    specialNeed?: boolean | null,
    phone_number?: string | null,
    guestTicket?:  {
      __typename: "GuestTicket",
      number?: string | null,
      redeemed?: boolean | null,
    } | null,
    guestName?: string | null,
    notRegisteredGuests?: Array< string | null > | null,
    hasNotRegisteredGuest?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    rejectionReason?:  {
      __typename: "RejectReason",
      id: string,
      content: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    rejectionComment?: string | null,
    isPaid?: boolean | null,
    isTransfered?: boolean | null,
    isPendingTransfer?: boolean | null,
    lastGuestId?: string | null,
    lastGuest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lastMainGuestId?: string | null,
    ownerHistory?: Array< string | null > | null,
    paidAmount?: number | null,
    balance?: number | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    refunded?: boolean | null,
    paymentTransactionId?: string | null,
    paymentParentTransactionId?: string | null,
    origin?: string | null,
    paymentMethod?: string | null,
    testField?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    bookingRejectionReasonId?: string | null,
  } | null,
};

export type ListBookingsQueryVariables = {
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBookingsQuery = {
  listBookings?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBookingsQueryVariables = {
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBookingsQuery = {
  syncBookings?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByGuestIdQueryVariables = {
  bookingGuestId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByGuestIdQuery = {
  bookingByGuestId?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByMainGuestIdQueryVariables = {
  bookingMainGuestId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByMainGuestIdQuery = {
  bookingByMainGuestId?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByMainBookingIdQueryVariables = {
  mainBookingId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByMainBookingIdQuery = {
  bookingByMainBookingId?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByEventIdQueryVariables = {
  bookingEventId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByEventIdQuery = {
  bookingByEventId?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type BookingByWaveIdQueryVariables = {
  waveId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBookingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BookingByWaveIdQuery = {
  bookingByWaveId?:  {
    __typename: "ModelBookingConnection",
    items:  Array< {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRejectReasonQueryVariables = {
  id: string,
};

export type GetRejectReasonQuery = {
  getRejectReason?:  {
    __typename: "RejectReason",
    id: string,
    content: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRejectReasonsQueryVariables = {
  filter?: ModelRejectReasonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRejectReasonsQuery = {
  listRejectReasons?:  {
    __typename: "ModelRejectReasonConnection",
    items:  Array< {
      __typename: "RejectReason",
      id: string,
      content: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRejectReasonsQueryVariables = {
  filter?: ModelRejectReasonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRejectReasonsQuery = {
  syncRejectReasons?:  {
    __typename: "ModelRejectReasonConnection",
    items:  Array< {
      __typename: "RejectReason",
      id: string,
      content: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInvitationQueryVariables = {
  id: string,
};

export type GetInvitationQuery = {
  getInvitation?:  {
    __typename: "Invitation",
    id: string,
    invitationEventId?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    balance?: number | null,
    wave?: string | null,
    phone_number?: string | null,
    name?: string | null,
    email?: string | null,
    reason?: string | null,
    secret?: string | null,
    used?: boolean | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    invitationEventTicketId: string,
  } | null,
};

export type ListInvitationsQueryVariables = {
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvitationsQuery = {
  listInvitations?:  {
    __typename: "ModelInvitationConnection",
    items:  Array< {
      __typename: "Invitation",
      id: string,
      invitationEventId?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      balance?: number | null,
      wave?: string | null,
      phone_number?: string | null,
      name?: string | null,
      email?: string | null,
      reason?: string | null,
      secret?: string | null,
      used?: boolean | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      invitationEventTicketId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInvitationsQueryVariables = {
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInvitationsQuery = {
  syncInvitations?:  {
    __typename: "ModelInvitationConnection",
    items:  Array< {
      __typename: "Invitation",
      id: string,
      invitationEventId?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      balance?: number | null,
      wave?: string | null,
      phone_number?: string | null,
      name?: string | null,
      email?: string | null,
      reason?: string | null,
      secret?: string | null,
      used?: boolean | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      invitationEventTicketId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvitationByEventIdQueryVariables = {
  invitationEventId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvitationByEventIdQuery = {
  invitationByEventId?:  {
    __typename: "ModelInvitationConnection",
    items:  Array< {
      __typename: "Invitation",
      id: string,
      invitationEventId?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      balance?: number | null,
      wave?: string | null,
      phone_number?: string | null,
      name?: string | null,
      email?: string | null,
      reason?: string | null,
      secret?: string | null,
      used?: boolean | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      invitationEventTicketId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InvitationBySecretQueryVariables = {
  secret: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvitationBySecretQuery = {
  invitationBySecret?:  {
    __typename: "ModelInvitationConnection",
    items:  Array< {
      __typename: "Invitation",
      id: string,
      invitationEventId?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      balance?: number | null,
      wave?: string | null,
      phone_number?: string | null,
      name?: string | null,
      email?: string | null,
      reason?: string | null,
      secret?: string | null,
      used?: boolean | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      invitationEventTicketId: string,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTransactionQueryVariables = {
  id: string,
};

export type GetTransactionQuery = {
  getTransaction?:  {
    __typename: "Transaction",
    id: string,
    type?: string | null,
    guestId?: string | null,
    guestName?: string | null,
    guestsNames?: Array< string | null > | null,
    guestPhone_number?: string | null,
    eventId: string,
    ticketId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    success?: boolean | null,
    failureReason?: string | null,
    currency?: string | null,
    amount_cents?: string | null,
    refund?: boolean | null,
    refunded_amount_cents?: string | null,
    orderID: string,
    transactionID: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transactionBookingId?: string | null,
  } | null,
};

export type ListTransactionsQueryVariables = {
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionsQuery = {
  listTransactions?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      type?: string | null,
      guestId?: string | null,
      guestName?: string | null,
      guestsNames?: Array< string | null > | null,
      guestPhone_number?: string | null,
      eventId: string,
      ticketId?: string | null,
      booking?:  {
        __typename: "Booking",
        id: string,
        status?: string | null,
        overallStatus?: string | null,
        bookingGuestId?: string | null,
        bookingMainGuestId?: string | null,
        mainBookingId?: string | null,
        bookingEventId?: string | null,
        bookingEventTicketId?: string | null,
        guest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        mainGuest:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        wave?: string | null,
        waveId: string,
        isMainGuest?: boolean | null,
        isInvited?: boolean | null,
        orderId?: string | null,
        ticketNumber?: string | null,
        redeemed?: boolean | null,
        passedCheckpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        statusUpdatedByID?: string | null,
        statusUpdatedByName?: string | null,
        statusUpdatedAt?: string | null,
        specialNeed?: boolean | null,
        phone_number?: string | null,
        guestTicket?:  {
          __typename: "GuestTicket",
          number?: string | null,
          redeemed?: boolean | null,
        } | null,
        guestName?: string | null,
        notRegisteredGuests?: Array< string | null > | null,
        hasNotRegisteredGuest?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        rejectionReason?:  {
          __typename: "RejectReason",
          id: string,
          content: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt?: string | null,
          updatedByID?: string | null,
          updatedByName?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        rejectionComment?: string | null,
        isPaid?: boolean | null,
        isTransfered?: boolean | null,
        isPendingTransfer?: boolean | null,
        lastGuestId?: string | null,
        lastGuest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        lastMainGuestId?: string | null,
        ownerHistory?: Array< string | null > | null,
        paidAmount?: number | null,
        balance?: number | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        refunded?: boolean | null,
        paymentTransactionId?: string | null,
        paymentParentTransactionId?: string | null,
        origin?: string | null,
        paymentMethod?: string | null,
        testField?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        bookingRejectionReasonId?: string | null,
      } | null,
      success?: boolean | null,
      failureReason?: string | null,
      currency?: string | null,
      amount_cents?: string | null,
      refund?: boolean | null,
      refunded_amount_cents?: string | null,
      orderID: string,
      transactionID: string,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      transactionBookingId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTransactionsQueryVariables = {
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTransactionsQuery = {
  syncTransactions?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      type?: string | null,
      guestId?: string | null,
      guestName?: string | null,
      guestsNames?: Array< string | null > | null,
      guestPhone_number?: string | null,
      eventId: string,
      ticketId?: string | null,
      booking?:  {
        __typename: "Booking",
        id: string,
        status?: string | null,
        overallStatus?: string | null,
        bookingGuestId?: string | null,
        bookingMainGuestId?: string | null,
        mainBookingId?: string | null,
        bookingEventId?: string | null,
        bookingEventTicketId?: string | null,
        guest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        mainGuest:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        wave?: string | null,
        waveId: string,
        isMainGuest?: boolean | null,
        isInvited?: boolean | null,
        orderId?: string | null,
        ticketNumber?: string | null,
        redeemed?: boolean | null,
        passedCheckpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        statusUpdatedByID?: string | null,
        statusUpdatedByName?: string | null,
        statusUpdatedAt?: string | null,
        specialNeed?: boolean | null,
        phone_number?: string | null,
        guestTicket?:  {
          __typename: "GuestTicket",
          number?: string | null,
          redeemed?: boolean | null,
        } | null,
        guestName?: string | null,
        notRegisteredGuests?: Array< string | null > | null,
        hasNotRegisteredGuest?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        rejectionReason?:  {
          __typename: "RejectReason",
          id: string,
          content: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt?: string | null,
          updatedByID?: string | null,
          updatedByName?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        rejectionComment?: string | null,
        isPaid?: boolean | null,
        isTransfered?: boolean | null,
        isPendingTransfer?: boolean | null,
        lastGuestId?: string | null,
        lastGuest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        lastMainGuestId?: string | null,
        ownerHistory?: Array< string | null > | null,
        paidAmount?: number | null,
        balance?: number | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        refunded?: boolean | null,
        paymentTransactionId?: string | null,
        paymentParentTransactionId?: string | null,
        origin?: string | null,
        paymentMethod?: string | null,
        testField?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        bookingRejectionReasonId?: string | null,
      } | null,
      success?: boolean | null,
      failureReason?: string | null,
      currency?: string | null,
      amount_cents?: string | null,
      refund?: boolean | null,
      refunded_amount_cents?: string | null,
      orderID: string,
      transactionID: string,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      transactionBookingId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TransactionsByEventQueryVariables = {
  eventId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByEventQuery = {
  transactionsByEvent?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      type?: string | null,
      guestId?: string | null,
      guestName?: string | null,
      guestsNames?: Array< string | null > | null,
      guestPhone_number?: string | null,
      eventId: string,
      ticketId?: string | null,
      booking?:  {
        __typename: "Booking",
        id: string,
        status?: string | null,
        overallStatus?: string | null,
        bookingGuestId?: string | null,
        bookingMainGuestId?: string | null,
        mainBookingId?: string | null,
        bookingEventId?: string | null,
        bookingEventTicketId?: string | null,
        guest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        mainGuest:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        wave?: string | null,
        waveId: string,
        isMainGuest?: boolean | null,
        isInvited?: boolean | null,
        orderId?: string | null,
        ticketNumber?: string | null,
        redeemed?: boolean | null,
        passedCheckpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        statusUpdatedByID?: string | null,
        statusUpdatedByName?: string | null,
        statusUpdatedAt?: string | null,
        specialNeed?: boolean | null,
        phone_number?: string | null,
        guestTicket?:  {
          __typename: "GuestTicket",
          number?: string | null,
          redeemed?: boolean | null,
        } | null,
        guestName?: string | null,
        notRegisteredGuests?: Array< string | null > | null,
        hasNotRegisteredGuest?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        rejectionReason?:  {
          __typename: "RejectReason",
          id: string,
          content: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt?: string | null,
          updatedByID?: string | null,
          updatedByName?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        rejectionComment?: string | null,
        isPaid?: boolean | null,
        isTransfered?: boolean | null,
        isPendingTransfer?: boolean | null,
        lastGuestId?: string | null,
        lastGuest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        lastMainGuestId?: string | null,
        ownerHistory?: Array< string | null > | null,
        paidAmount?: number | null,
        balance?: number | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        refunded?: boolean | null,
        paymentTransactionId?: string | null,
        paymentParentTransactionId?: string | null,
        origin?: string | null,
        paymentMethod?: string | null,
        testField?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        bookingRejectionReasonId?: string | null,
      } | null,
      success?: boolean | null,
      failureReason?: string | null,
      currency?: string | null,
      amount_cents?: string | null,
      refund?: boolean | null,
      refunded_amount_cents?: string | null,
      orderID: string,
      transactionID: string,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      transactionBookingId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWavesConsumptionQueryVariables = {
  id: string,
};

export type GetWavesConsumptionQuery = {
  getWavesConsumption?:  {
    __typename: "WavesConsumption",
    waveId: string,
    consumedTickets?: number | null,
    reservedTickets?: number | null,
    totalTickets: number,
    consumed?: string | null,
    eventId: string,
    eventTicketId?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWavesConsumptionsQueryVariables = {
  filter?: ModelWavesConsumptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWavesConsumptionsQuery = {
  listWavesConsumptions?:  {
    __typename: "ModelWavesConsumptionConnection",
    items:  Array< {
      __typename: "WavesConsumption",
      waveId: string,
      consumedTickets?: number | null,
      reservedTickets?: number | null,
      totalTickets: number,
      consumed?: string | null,
      eventId: string,
      eventTicketId?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncWavesConsumptionsQueryVariables = {
  filter?: ModelWavesConsumptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncWavesConsumptionsQuery = {
  syncWavesConsumptions?:  {
    __typename: "ModelWavesConsumptionConnection",
    items:  Array< {
      __typename: "WavesConsumption",
      waveId: string,
      consumedTickets?: number | null,
      reservedTickets?: number | null,
      totalTickets: number,
      consumed?: string | null,
      eventId: string,
      eventTicketId?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type WaveConsumptionByWaveIdQueryVariables = {
  waveId: string,
  consumedTickets?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWavesConsumptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WaveConsumptionByWaveIdQuery = {
  waveConsumptionByWaveId?:  {
    __typename: "ModelWavesConsumptionConnection",
    items:  Array< {
      __typename: "WavesConsumption",
      waveId: string,
      consumedTickets?: number | null,
      reservedTickets?: number | null,
      totalTickets: number,
      consumed?: string | null,
      eventId: string,
      eventTicketId?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCashlessTransactionQueryVariables = {
  id: string,
};

export type GetCashlessTransactionQuery = {
  getCashlessTransaction?:  {
    __typename: "CashlessTransaction",
    id: string,
    pos: string,
    checkNumber: string,
    orderTotal: number,
    paidAmount: number,
    paymentStatus: string,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    guestName: string,
    type?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCashlessTransactionsQueryVariables = {
  filter?: ModelCashlessTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCashlessTransactionsQuery = {
  listCashlessTransactions?:  {
    __typename: "ModelCashlessTransactionConnection",
    items:  Array< {
      __typename: "CashlessTransaction",
      id: string,
      pos: string,
      checkNumber: string,
      orderTotal: number,
      paidAmount: number,
      paymentStatus: string,
      guestId: string,
      guestPhoneNumber?: string | null,
      guestEmail?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      guestName: string,
      type?: string | null,
      bookingId?: string | null,
      invitationId?: string | null,
      eventId: string,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      syncOperationId?: string | null,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCashlessTransactionsQueryVariables = {
  filter?: ModelCashlessTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCashlessTransactionsQuery = {
  syncCashlessTransactions?:  {
    __typename: "ModelCashlessTransactionConnection",
    items:  Array< {
      __typename: "CashlessTransaction",
      id: string,
      pos: string,
      checkNumber: string,
      orderTotal: number,
      paidAmount: number,
      paymentStatus: string,
      guestId: string,
      guestPhoneNumber?: string | null,
      guestEmail?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      guestName: string,
      type?: string | null,
      bookingId?: string | null,
      invitationId?: string | null,
      eventId: string,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      syncOperationId?: string | null,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CashlessTransactionByGuestIDQueryVariables = {
  guestId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashlessTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CashlessTransactionByGuestIDQuery = {
  cashlessTransactionByGuestID?:  {
    __typename: "ModelCashlessTransactionConnection",
    items:  Array< {
      __typename: "CashlessTransaction",
      id: string,
      pos: string,
      checkNumber: string,
      orderTotal: number,
      paidAmount: number,
      paymentStatus: string,
      guestId: string,
      guestPhoneNumber?: string | null,
      guestEmail?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      guestName: string,
      type?: string | null,
      bookingId?: string | null,
      invitationId?: string | null,
      eventId: string,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      syncOperationId?: string | null,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CashlessTransactionByEventIDQueryVariables = {
  eventId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashlessTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CashlessTransactionByEventIDQuery = {
  cashlessTransactionByEventID?:  {
    __typename: "ModelCashlessTransactionConnection",
    items:  Array< {
      __typename: "CashlessTransaction",
      id: string,
      pos: string,
      checkNumber: string,
      orderTotal: number,
      paidAmount: number,
      paymentStatus: string,
      guestId: string,
      guestPhoneNumber?: string | null,
      guestEmail?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      guestName: string,
      type?: string | null,
      bookingId?: string | null,
      invitationId?: string | null,
      eventId: string,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      syncOperationId?: string | null,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CashlessTransactionsByDeletedQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashlessTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CashlessTransactionsByDeletedQuery = {
  cashlessTransactionsByDeleted?:  {
    __typename: "ModelCashlessTransactionConnection",
    items:  Array< {
      __typename: "CashlessTransaction",
      id: string,
      pos: string,
      checkNumber: string,
      orderTotal: number,
      paidAmount: number,
      paymentStatus: string,
      guestId: string,
      guestPhoneNumber?: string | null,
      guestEmail?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      guestName: string,
      type?: string | null,
      bookingId?: string | null,
      invitationId?: string | null,
      eventId: string,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      syncOperationId?: string | null,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCashlessTopUpQueryVariables = {
  id: string,
};

export type GetCashlessTopUpQuery = {
  getCashlessTopUp?:  {
    __typename: "CashlessTopUp",
    id: string,
    operation: string,
    amount: number,
    availableBalance?: number | null,
    paymentChannel: string,
    paymentMethod: string,
    isVoided?: string | null,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    bookingId?: string | null,
    invitationId?: string | null,
    ticketType?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    invitation?:  {
      __typename: "Invitation",
      id: string,
      invitationEventId?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      balance?: number | null,
      wave?: string | null,
      phone_number?: string | null,
      name?: string | null,
      email?: string | null,
      reason?: string | null,
      secret?: string | null,
      used?: boolean | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      invitationEventTicketId: string,
    } | null,
    eventId: string,
    eventName?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    status: string,
    type?: string | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    guestName: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCashlessTopUpsQueryVariables = {
  filter?: ModelCashlessTopUpFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCashlessTopUpsQuery = {
  listCashlessTopUps?:  {
    __typename: "ModelCashlessTopUpConnection",
    items:  Array< {
      __typename: "CashlessTopUp",
      id: string,
      operation: string,
      amount: number,
      availableBalance?: number | null,
      paymentChannel: string,
      paymentMethod: string,
      isVoided?: string | null,
      guestId: string,
      guestPhoneNumber?: string | null,
      guestEmail?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      bookingId?: string | null,
      invitationId?: string | null,
      ticketType?: string | null,
      booking?:  {
        __typename: "Booking",
        id: string,
        status?: string | null,
        overallStatus?: string | null,
        bookingGuestId?: string | null,
        bookingMainGuestId?: string | null,
        mainBookingId?: string | null,
        bookingEventId?: string | null,
        bookingEventTicketId?: string | null,
        guest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        mainGuest:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        wave?: string | null,
        waveId: string,
        isMainGuest?: boolean | null,
        isInvited?: boolean | null,
        orderId?: string | null,
        ticketNumber?: string | null,
        redeemed?: boolean | null,
        passedCheckpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        statusUpdatedByID?: string | null,
        statusUpdatedByName?: string | null,
        statusUpdatedAt?: string | null,
        specialNeed?: boolean | null,
        phone_number?: string | null,
        guestTicket?:  {
          __typename: "GuestTicket",
          number?: string | null,
          redeemed?: boolean | null,
        } | null,
        guestName?: string | null,
        notRegisteredGuests?: Array< string | null > | null,
        hasNotRegisteredGuest?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        rejectionReason?:  {
          __typename: "RejectReason",
          id: string,
          content: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt?: string | null,
          updatedByID?: string | null,
          updatedByName?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        rejectionComment?: string | null,
        isPaid?: boolean | null,
        isTransfered?: boolean | null,
        isPendingTransfer?: boolean | null,
        lastGuestId?: string | null,
        lastGuest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        lastMainGuestId?: string | null,
        ownerHistory?: Array< string | null > | null,
        paidAmount?: number | null,
        balance?: number | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        refunded?: boolean | null,
        paymentTransactionId?: string | null,
        paymentParentTransactionId?: string | null,
        origin?: string | null,
        paymentMethod?: string | null,
        testField?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        bookingRejectionReasonId?: string | null,
      } | null,
      invitation?:  {
        __typename: "Invitation",
        id: string,
        invitationEventId?: string | null,
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        balance?: number | null,
        wave?: string | null,
        phone_number?: string | null,
        name?: string | null,
        email?: string | null,
        reason?: string | null,
        secret?: string | null,
        used?: boolean | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        invitationEventTicketId: string,
      } | null,
      eventId: string,
      eventName?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      status: string,
      type?: string | null,
      syncOperationId?: string | null,
      deleted?: string | null,
      guestName: string,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCashlessTopUpsQueryVariables = {
  filter?: ModelCashlessTopUpFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCashlessTopUpsQuery = {
  syncCashlessTopUps?:  {
    __typename: "ModelCashlessTopUpConnection",
    items:  Array< {
      __typename: "CashlessTopUp",
      id: string,
      operation: string,
      amount: number,
      availableBalance?: number | null,
      paymentChannel: string,
      paymentMethod: string,
      isVoided?: string | null,
      guestId: string,
      guestPhoneNumber?: string | null,
      guestEmail?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      bookingId?: string | null,
      invitationId?: string | null,
      ticketType?: string | null,
      booking?:  {
        __typename: "Booking",
        id: string,
        status?: string | null,
        overallStatus?: string | null,
        bookingGuestId?: string | null,
        bookingMainGuestId?: string | null,
        mainBookingId?: string | null,
        bookingEventId?: string | null,
        bookingEventTicketId?: string | null,
        guest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        mainGuest:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        wave?: string | null,
        waveId: string,
        isMainGuest?: boolean | null,
        isInvited?: boolean | null,
        orderId?: string | null,
        ticketNumber?: string | null,
        redeemed?: boolean | null,
        passedCheckpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        statusUpdatedByID?: string | null,
        statusUpdatedByName?: string | null,
        statusUpdatedAt?: string | null,
        specialNeed?: boolean | null,
        phone_number?: string | null,
        guestTicket?:  {
          __typename: "GuestTicket",
          number?: string | null,
          redeemed?: boolean | null,
        } | null,
        guestName?: string | null,
        notRegisteredGuests?: Array< string | null > | null,
        hasNotRegisteredGuest?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        rejectionReason?:  {
          __typename: "RejectReason",
          id: string,
          content: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt?: string | null,
          updatedByID?: string | null,
          updatedByName?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        rejectionComment?: string | null,
        isPaid?: boolean | null,
        isTransfered?: boolean | null,
        isPendingTransfer?: boolean | null,
        lastGuestId?: string | null,
        lastGuest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        lastMainGuestId?: string | null,
        ownerHistory?: Array< string | null > | null,
        paidAmount?: number | null,
        balance?: number | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        refunded?: boolean | null,
        paymentTransactionId?: string | null,
        paymentParentTransactionId?: string | null,
        origin?: string | null,
        paymentMethod?: string | null,
        testField?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        bookingRejectionReasonId?: string | null,
      } | null,
      invitation?:  {
        __typename: "Invitation",
        id: string,
        invitationEventId?: string | null,
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        balance?: number | null,
        wave?: string | null,
        phone_number?: string | null,
        name?: string | null,
        email?: string | null,
        reason?: string | null,
        secret?: string | null,
        used?: boolean | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        invitationEventTicketId: string,
      } | null,
      eventId: string,
      eventName?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      status: string,
      type?: string | null,
      syncOperationId?: string | null,
      deleted?: string | null,
      guestName: string,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CashlessTopUpByGuestIDQueryVariables = {
  guestId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashlessTopUpFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CashlessTopUpByGuestIDQuery = {
  cashlessTopUpByGuestID?:  {
    __typename: "ModelCashlessTopUpConnection",
    items:  Array< {
      __typename: "CashlessTopUp",
      id: string,
      operation: string,
      amount: number,
      availableBalance?: number | null,
      paymentChannel: string,
      paymentMethod: string,
      isVoided?: string | null,
      guestId: string,
      guestPhoneNumber?: string | null,
      guestEmail?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      bookingId?: string | null,
      invitationId?: string | null,
      ticketType?: string | null,
      booking?:  {
        __typename: "Booking",
        id: string,
        status?: string | null,
        overallStatus?: string | null,
        bookingGuestId?: string | null,
        bookingMainGuestId?: string | null,
        mainBookingId?: string | null,
        bookingEventId?: string | null,
        bookingEventTicketId?: string | null,
        guest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        mainGuest:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        wave?: string | null,
        waveId: string,
        isMainGuest?: boolean | null,
        isInvited?: boolean | null,
        orderId?: string | null,
        ticketNumber?: string | null,
        redeemed?: boolean | null,
        passedCheckpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        statusUpdatedByID?: string | null,
        statusUpdatedByName?: string | null,
        statusUpdatedAt?: string | null,
        specialNeed?: boolean | null,
        phone_number?: string | null,
        guestTicket?:  {
          __typename: "GuestTicket",
          number?: string | null,
          redeemed?: boolean | null,
        } | null,
        guestName?: string | null,
        notRegisteredGuests?: Array< string | null > | null,
        hasNotRegisteredGuest?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        rejectionReason?:  {
          __typename: "RejectReason",
          id: string,
          content: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt?: string | null,
          updatedByID?: string | null,
          updatedByName?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        rejectionComment?: string | null,
        isPaid?: boolean | null,
        isTransfered?: boolean | null,
        isPendingTransfer?: boolean | null,
        lastGuestId?: string | null,
        lastGuest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        lastMainGuestId?: string | null,
        ownerHistory?: Array< string | null > | null,
        paidAmount?: number | null,
        balance?: number | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        refunded?: boolean | null,
        paymentTransactionId?: string | null,
        paymentParentTransactionId?: string | null,
        origin?: string | null,
        paymentMethod?: string | null,
        testField?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        bookingRejectionReasonId?: string | null,
      } | null,
      invitation?:  {
        __typename: "Invitation",
        id: string,
        invitationEventId?: string | null,
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        balance?: number | null,
        wave?: string | null,
        phone_number?: string | null,
        name?: string | null,
        email?: string | null,
        reason?: string | null,
        secret?: string | null,
        used?: boolean | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        invitationEventTicketId: string,
      } | null,
      eventId: string,
      eventName?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      status: string,
      type?: string | null,
      syncOperationId?: string | null,
      deleted?: string | null,
      guestName: string,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CashlessTopUpByEventIDQueryVariables = {
  eventId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashlessTopUpFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CashlessTopUpByEventIDQuery = {
  cashlessTopUpByEventID?:  {
    __typename: "ModelCashlessTopUpConnection",
    items:  Array< {
      __typename: "CashlessTopUp",
      id: string,
      operation: string,
      amount: number,
      availableBalance?: number | null,
      paymentChannel: string,
      paymentMethod: string,
      isVoided?: string | null,
      guestId: string,
      guestPhoneNumber?: string | null,
      guestEmail?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      bookingId?: string | null,
      invitationId?: string | null,
      ticketType?: string | null,
      booking?:  {
        __typename: "Booking",
        id: string,
        status?: string | null,
        overallStatus?: string | null,
        bookingGuestId?: string | null,
        bookingMainGuestId?: string | null,
        mainBookingId?: string | null,
        bookingEventId?: string | null,
        bookingEventTicketId?: string | null,
        guest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        mainGuest:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        wave?: string | null,
        waveId: string,
        isMainGuest?: boolean | null,
        isInvited?: boolean | null,
        orderId?: string | null,
        ticketNumber?: string | null,
        redeemed?: boolean | null,
        passedCheckpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        statusUpdatedByID?: string | null,
        statusUpdatedByName?: string | null,
        statusUpdatedAt?: string | null,
        specialNeed?: boolean | null,
        phone_number?: string | null,
        guestTicket?:  {
          __typename: "GuestTicket",
          number?: string | null,
          redeemed?: boolean | null,
        } | null,
        guestName?: string | null,
        notRegisteredGuests?: Array< string | null > | null,
        hasNotRegisteredGuest?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        rejectionReason?:  {
          __typename: "RejectReason",
          id: string,
          content: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt?: string | null,
          updatedByID?: string | null,
          updatedByName?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        rejectionComment?: string | null,
        isPaid?: boolean | null,
        isTransfered?: boolean | null,
        isPendingTransfer?: boolean | null,
        lastGuestId?: string | null,
        lastGuest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        lastMainGuestId?: string | null,
        ownerHistory?: Array< string | null > | null,
        paidAmount?: number | null,
        balance?: number | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        refunded?: boolean | null,
        paymentTransactionId?: string | null,
        paymentParentTransactionId?: string | null,
        origin?: string | null,
        paymentMethod?: string | null,
        testField?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        bookingRejectionReasonId?: string | null,
      } | null,
      invitation?:  {
        __typename: "Invitation",
        id: string,
        invitationEventId?: string | null,
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        balance?: number | null,
        wave?: string | null,
        phone_number?: string | null,
        name?: string | null,
        email?: string | null,
        reason?: string | null,
        secret?: string | null,
        used?: boolean | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        invitationEventTicketId: string,
      } | null,
      eventId: string,
      eventName?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      status: string,
      type?: string | null,
      syncOperationId?: string | null,
      deleted?: string | null,
      guestName: string,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CashlessTopUpByDeletedQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCashlessTopUpFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CashlessTopUpByDeletedQuery = {
  cashlessTopUpByDeleted?:  {
    __typename: "ModelCashlessTopUpConnection",
    items:  Array< {
      __typename: "CashlessTopUp",
      id: string,
      operation: string,
      amount: number,
      availableBalance?: number | null,
      paymentChannel: string,
      paymentMethod: string,
      isVoided?: string | null,
      guestId: string,
      guestPhoneNumber?: string | null,
      guestEmail?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      bookingId?: string | null,
      invitationId?: string | null,
      ticketType?: string | null,
      booking?:  {
        __typename: "Booking",
        id: string,
        status?: string | null,
        overallStatus?: string | null,
        bookingGuestId?: string | null,
        bookingMainGuestId?: string | null,
        mainBookingId?: string | null,
        bookingEventId?: string | null,
        bookingEventTicketId?: string | null,
        guest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        mainGuest:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        wave?: string | null,
        waveId: string,
        isMainGuest?: boolean | null,
        isInvited?: boolean | null,
        orderId?: string | null,
        ticketNumber?: string | null,
        redeemed?: boolean | null,
        passedCheckpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        statusUpdatedByID?: string | null,
        statusUpdatedByName?: string | null,
        statusUpdatedAt?: string | null,
        specialNeed?: boolean | null,
        phone_number?: string | null,
        guestTicket?:  {
          __typename: "GuestTicket",
          number?: string | null,
          redeemed?: boolean | null,
        } | null,
        guestName?: string | null,
        notRegisteredGuests?: Array< string | null > | null,
        hasNotRegisteredGuest?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        rejectionReason?:  {
          __typename: "RejectReason",
          id: string,
          content: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt?: string | null,
          updatedByID?: string | null,
          updatedByName?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        rejectionComment?: string | null,
        isPaid?: boolean | null,
        isTransfered?: boolean | null,
        isPendingTransfer?: boolean | null,
        lastGuestId?: string | null,
        lastGuest?:  {
          __typename: "Guest",
          id: string,
          name?: string | null,
          isNameEdited?: boolean | null,
          username?: string | null,
          email?: string | null,
          phone_number?: string | null,
          guestGroupID?: string | null,
          guestGroupName?: string | null,
          sub?: string | null,
          facebookSub?: string | null,
          guest_avatar?: string | null,
          avg_spend?: number | null,
          avg_ticket_type?: string | null,
          numberOfTickets?: number | null,
          connections?: string | null,
          last_attended_event?: string | null,
          last_attended_event_Name?: string | null,
          gender?: string | null,
          isGenderEdited?: boolean | null,
          group?: string | null,
          faceBookID?: string | null,
          appPassword?: string | null,
          birthdate?: string | null,
          isBirthdateEdited?: boolean | null,
          isVerified?: boolean | null,
          images?: Array< string | null > | null,
          address?: string | null,
          totalEvents?: number | null,
          flags?: Array< string | null > | null,
          frontPersonalID?: string | null,
          isFrontPersonalIDEdited?: boolean | null,
          passportImage?: string | null,
          isPassportImageEdited?: boolean | null,
          backPersonalID?: string | null,
          isBackPersonalIDEdited?: boolean | null,
          faceBookProfileLink?: string | null,
          instagramProfileLink?: string | null,
          nationality?: string | null,
          balance?: number | null,
          isBlocked?: boolean | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        lastMainGuestId?: string | null,
        ownerHistory?: Array< string | null > | null,
        paidAmount?: number | null,
        balance?: number | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        refunded?: boolean | null,
        paymentTransactionId?: string | null,
        paymentParentTransactionId?: string | null,
        origin?: string | null,
        paymentMethod?: string | null,
        testField?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        bookingRejectionReasonId?: string | null,
      } | null,
      invitation?:  {
        __typename: "Invitation",
        id: string,
        invitationEventId?: string | null,
        event:  {
          __typename: "Event",
          id: string,
          name?: string | null,
          description?: string | null,
          disclaimer?: string | null,
          startDate?: string | null,
          endDate?: string | null,
          todoList?: Array< string | null > | null,
          map?: string | null,
          image?: string | null,
          largeScreensImage?: string | null,
          mediumScreensImage?: string | null,
          smallScreensImage?: string | null,
          sendEmailToValidGuest?: boolean | null,
          sendSmsToValidGuest?: boolean | null,
          gallery?: Array< string | null > | null,
          visibleTo?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          published: boolean,
          applyGenderRatio?: boolean | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        eventTicket:  {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        cashlessCredit?: number | null,
        balance?: number | null,
        wave?: string | null,
        phone_number?: string | null,
        name?: string | null,
        email?: string | null,
        reason?: string | null,
        secret?: string | null,
        used?: boolean | null,
        scannedAt?: string | null,
        scannedByName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        invitationEventTicketId: string,
      } | null,
      eventId: string,
      eventName?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      status: string,
      type?: string | null,
      syncOperationId?: string | null,
      deleted?: string | null,
      guestName: string,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    precedence: number,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        image?: string | null,
        price: number,
        precedence: number,
        categoryID: string,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      precedence: number,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          image?: string | null,
          price: number,
          precedence: number,
          categoryID: string,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCategoriesQuery = {
  syncCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      precedence: number,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          image?: string | null,
          price: number,
          precedence: number,
          categoryID: string,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CategoryByDeletedQueryVariables = {
  deleted: string,
  precedence?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryByDeletedQuery = {
  categoryByDeleted?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      precedence: number,
      items?:  {
        __typename: "ModelItemConnection",
        items:  Array< {
          __typename: "Item",
          id: string,
          name: string,
          image?: string | null,
          price: number,
          precedence: number,
          categoryID: string,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetItemQueryVariables = {
  id: string,
};

export type GetItemQuery = {
  getItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    image?: string | null,
    price: number,
    precedence: number,
    categoryID: string,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListItemsQueryVariables = {
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemsQuery = {
  listItems?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      image?: string | null,
      price: number,
      precedence: number,
      categoryID: string,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncItemsQueryVariables = {
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncItemsQuery = {
  syncItems?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      image?: string | null,
      price: number,
      precedence: number,
      categoryID: string,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ByCategoryIDQueryVariables = {
  categoryID: string,
  precedence?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByCategoryIDQuery = {
  byCategoryID?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      name: string,
      image?: string | null,
      price: number,
      precedence: number,
      categoryID: string,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetItemConsumptionQueryVariables = {
  id: string,
};

export type GetItemConsumptionQuery = {
  getItemConsumption?:  {
    __typename: "ItemConsumption",
    id: string,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      image?: string | null,
      price: number,
      precedence: number,
      categoryID: string,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    itemName: string,
    itemQuantity: number,
    itemPrice: number,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListItemConsumptionsQueryVariables = {
  filter?: ModelItemConsumptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemConsumptionsQuery = {
  listItemConsumptions?:  {
    __typename: "ModelItemConsumptionConnection",
    items:  Array< {
      __typename: "ItemConsumption",
      id: string,
      eventId: string,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        image?: string | null,
        price: number,
        precedence: number,
        categoryID: string,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      itemName: string,
      itemQuantity: number,
      itemPrice: number,
      syncOperationId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncItemConsumptionsQueryVariables = {
  filter?: ModelItemConsumptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncItemConsumptionsQuery = {
  syncItemConsumptions?:  {
    __typename: "ModelItemConsumptionConnection",
    items:  Array< {
      __typename: "ItemConsumption",
      id: string,
      eventId: string,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        image?: string | null,
        price: number,
        precedence: number,
        categoryID: string,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      itemName: string,
      itemQuantity: number,
      itemPrice: number,
      syncOperationId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ItemConsumptionByEventIdQueryVariables = {
  eventId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemConsumptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ItemConsumptionByEventIdQuery = {
  ItemConsumptionByEventId?:  {
    __typename: "ModelItemConsumptionConnection",
    items:  Array< {
      __typename: "ItemConsumption",
      id: string,
      eventId: string,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      itemId: string,
      item?:  {
        __typename: "Item",
        id: string,
        name: string,
        image?: string | null,
        price: number,
        precedence: number,
        categoryID: string,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      itemName: string,
      itemQuantity: number,
      itemPrice: number,
      syncOperationId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTicketsScanQueryVariables = {
  id: string,
};

export type GetTicketsScanQuery = {
  getTicketsScan?:  {
    __typename: "TicketsScan",
    id: string,
    scannedById: string,
    scannedAt?: string | null,
    status?: string | null,
    eventId?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    guestName?: string | null,
    ticketType?: string | null,
    guestAvatar?: string | null,
    deleted?: string | null,
    gateName?: string | null,
    checkpointName?: string | null,
    scannedByName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTicketsScansQueryVariables = {
  filter?: ModelTicketsScanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTicketsScansQuery = {
  listTicketsScans?:  {
    __typename: "ModelTicketsScanConnection",
    items:  Array< {
      __typename: "TicketsScan",
      id: string,
      scannedById: string,
      scannedAt?: string | null,
      status?: string | null,
      eventId?: string | null,
      bookingId?: string | null,
      invitationId?: string | null,
      guestName?: string | null,
      ticketType?: string | null,
      guestAvatar?: string | null,
      deleted?: string | null,
      gateName?: string | null,
      checkpointName?: string | null,
      scannedByName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTicketsScansQueryVariables = {
  filter?: ModelTicketsScanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTicketsScansQuery = {
  syncTicketsScans?:  {
    __typename: "ModelTicketsScanConnection",
    items:  Array< {
      __typename: "TicketsScan",
      id: string,
      scannedById: string,
      scannedAt?: string | null,
      status?: string | null,
      eventId?: string | null,
      bookingId?: string | null,
      invitationId?: string | null,
      guestName?: string | null,
      ticketType?: string | null,
      guestAvatar?: string | null,
      deleted?: string | null,
      gateName?: string | null,
      checkpointName?: string | null,
      scannedByName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TicketsScanByScannedByIdQueryVariables = {
  scannedById: string,
  scannedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketsScanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TicketsScanByScannedByIdQuery = {
  TicketsScanByScannedById?:  {
    __typename: "ModelTicketsScanConnection",
    items:  Array< {
      __typename: "TicketsScan",
      id: string,
      scannedById: string,
      scannedAt?: string | null,
      status?: string | null,
      eventId?: string | null,
      bookingId?: string | null,
      invitationId?: string | null,
      guestName?: string | null,
      ticketType?: string | null,
      guestAvatar?: string | null,
      deleted?: string | null,
      gateName?: string | null,
      checkpointName?: string | null,
      scannedByName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TicketScansByEventIdQueryVariables = {
  eventId: string,
  scannedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketsScanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TicketScansByEventIdQuery = {
  TicketScansByEventId?:  {
    __typename: "ModelTicketsScanConnection",
    items:  Array< {
      __typename: "TicketsScan",
      id: string,
      scannedById: string,
      scannedAt?: string | null,
      status?: string | null,
      eventId?: string | null,
      bookingId?: string | null,
      invitationId?: string | null,
      guestName?: string | null,
      ticketType?: string | null,
      guestAvatar?: string | null,
      deleted?: string | null,
      gateName?: string | null,
      checkpointName?: string | null,
      scannedByName?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGateQueryVariables = {
  id: string,
};

export type GetGateQuery = {
  getGate?:  {
    __typename: "Gate",
    id: string,
    name: string,
    eventId: string,
    tickets?: Array< string | null > | null,
    admins?: Array< string | null > | null,
    checkpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListGatesQueryVariables = {
  filter?: ModelGateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGatesQuery = {
  listGates?:  {
    __typename: "ModelGateConnection",
    items:  Array< {
      __typename: "Gate",
      id: string,
      name: string,
      eventId: string,
      tickets?: Array< string | null > | null,
      admins?: Array< string | null > | null,
      checkpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGatesQueryVariables = {
  filter?: ModelGateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGatesQuery = {
  syncGates?:  {
    __typename: "ModelGateConnection",
    items:  Array< {
      __typename: "Gate",
      id: string,
      name: string,
      eventId: string,
      tickets?: Array< string | null > | null,
      admins?: Array< string | null > | null,
      checkpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GateByEventIDQueryVariables = {
  eventId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GateByEventIDQuery = {
  GateByEventID?:  {
    __typename: "ModelGateConnection",
    items:  Array< {
      __typename: "Gate",
      id: string,
      name: string,
      eventId: string,
      tickets?: Array< string | null > | null,
      admins?: Array< string | null > | null,
      checkpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    bookingId?: string | null,
    eventId?: string | null,
    userId?: string | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    status?: string | null,
    dispatcherId?: string | null,
    totalAmount?: number | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      bookingId?: string | null,
      eventId?: string | null,
      userId?: string | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      status?: string | null,
      dispatcherId?: string | null,
      totalAmount?: number | null,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrdersQuery = {
  syncOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      bookingId?: string | null,
      eventId?: string | null,
      userId?: string | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      status?: string | null,
      dispatcherId?: string | null,
      totalAmount?: number | null,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserIPQueryVariables = {
  id: string,
};

export type GetUserIPQuery = {
  getUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserIPSQueryVariables = {
  filter?: ModelUserIPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserIPSQuery = {
  listUserIPS?:  {
    __typename: "ModelUserIPConnection",
    items:  Array< {
      __typename: "UserIP",
      id: string,
      ip?: string | null,
      date?: string | null,
      times?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserIPSQueryVariables = {
  filter?: ModelUserIPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserIPSQuery = {
  syncUserIPS?:  {
    __typename: "ModelUserIPConnection",
    items:  Array< {
      __typename: "UserIP",
      id: string,
      ip?: string | null,
      date?: string | null,
      times?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetScanQueryVariables = {
  id: string,
};

export type GetScanQuery = {
  getScan?:  {
    __typename: "Scan",
    id: string,
    link: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListScansQueryVariables = {
  filter?: ModelScanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListScansQuery = {
  listScans?:  {
    __typename: "ModelScanConnection",
    items:  Array< {
      __typename: "Scan",
      id: string,
      link: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncScansQueryVariables = {
  filter?: ModelScanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncScansQuery = {
  syncScans?:  {
    __typename: "ModelScanConnection",
    items:  Array< {
      __typename: "Scan",
      id: string,
      link: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    currency?: string | null,
    merchantVersion?: string | null,
    merchantID?: string | null,
    merchantIframe?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    accountLogoId?: string | null,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    currency?: string | null,
    merchantVersion?: string | null,
    merchantID?: string | null,
    merchantIframe?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    accountLogoId?: string | null,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    logo?:  {
      __typename: "Attachment",
      id: string,
      mediaID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    currency?: string | null,
    merchantVersion?: string | null,
    merchantID?: string | null,
    merchantIframe?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    accountLogoId?: string | null,
  } | null,
};

export type OnCreateConceptSubscriptionVariables = {
  filter?: ModelSubscriptionConceptFilterInput | null,
};

export type OnCreateConceptSubscription = {
  onCreateConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateConceptSubscriptionVariables = {
  filter?: ModelSubscriptionConceptFilterInput | null,
};

export type OnUpdateConceptSubscription = {
  onUpdateConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteConceptSubscriptionVariables = {
  filter?: ModelSubscriptionConceptFilterInput | null,
};

export type OnDeleteConceptSubscription = {
  onDeleteConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    description?: string | null,
    logo?: string | null,
    type?: string | null,
    location?: string | null,
    precedence?: number | null,
    longitude?: string | null,
    latitude?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLanguageSubscriptionVariables = {
  filter?: ModelSubscriptionLanguageFilterInput | null,
};

export type OnCreateLanguageSubscription = {
  onCreateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLanguageSubscriptionVariables = {
  filter?: ModelSubscriptionLanguageFilterInput | null,
};

export type OnUpdateLanguageSubscription = {
  onUpdateLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLanguageSubscriptionVariables = {
  filter?: ModelSubscriptionLanguageFilterInput | null,
};

export type OnDeleteLanguageSubscription = {
  onDeleteLanguage?:  {
    __typename: "Language",
    id: string,
    name: string,
    code: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnCreateFeatureSubscription = {
  onCreateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnUpdateFeatureSubscription = {
  onUpdateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnDeleteFeatureSubscription = {
  onDeleteFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAdminRoleSubscriptionVariables = {
  filter?: ModelSubscriptionAdminRoleFilterInput | null,
};

export type OnCreateAdminRoleSubscription = {
  onCreateAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAdminRoleSubscriptionVariables = {
  filter?: ModelSubscriptionAdminRoleFilterInput | null,
};

export type OnUpdateAdminRoleSubscription = {
  onUpdateAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAdminRoleSubscriptionVariables = {
  filter?: ModelSubscriptionAdminRoleFilterInput | null,
};

export type OnDeleteAdminRoleSubscription = {
  onDeleteAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAdminGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAdminGroupFilterInput | null,
};

export type OnCreateAdminGroupSubscription = {
  onCreateAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAdminGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAdminGroupFilterInput | null,
};

export type OnUpdateAdminGroupSubscription = {
  onUpdateAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAdminGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAdminGroupFilterInput | null,
};

export type OnDeleteAdminGroupSubscription = {
  onDeleteAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnCreateAttachmentSubscription = {
  onCreateAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnUpdateAttachmentSubscription = {
  onUpdateAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAttachmentSubscriptionVariables = {
  filter?: ModelSubscriptionAttachmentFilterInput | null,
};

export type OnDeleteAttachmentSubscription = {
  onDeleteAttachment?:  {
    __typename: "Attachment",
    id: string,
    mediaID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserConceptsSubscriptionVariables = {
  filter?: ModelSubscriptionUserConceptsFilterInput | null,
};

export type OnCreateUserConceptsSubscription = {
  onCreateUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserConceptsSubscriptionVariables = {
  filter?: ModelSubscriptionUserConceptsFilterInput | null,
};

export type OnUpdateUserConceptsSubscription = {
  onUpdateUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserConceptsSubscriptionVariables = {
  filter?: ModelSubscriptionUserConceptsFilterInput | null,
};

export type OnDeleteUserConceptsSubscription = {
  onDeleteUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateGuestSubscriptionVariables = {
  filter?: ModelSubscriptionGuestFilterInput | null,
};

export type OnCreateGuestSubscription = {
  onCreateGuest?:  {
    __typename: "Guest",
    id: string,
    name?: string | null,
    isNameEdited?: boolean | null,
    username?: string | null,
    email?: string | null,
    phone_number?: string | null,
    guestGroupID?: string | null,
    guestGroupName?: string | null,
    sub?: string | null,
    facebookSub?: string | null,
    guest_avatar?: string | null,
    avg_spend?: number | null,
    avg_ticket_type?: string | null,
    numberOfTickets?: number | null,
    connections?: string | null,
    last_attended_event?: string | null,
    last_attended_event_Name?: string | null,
    gender?: string | null,
    isGenderEdited?: boolean | null,
    group?: string | null,
    faceBookID?: string | null,
    appPassword?: string | null,
    birthdate?: string | null,
    isBirthdateEdited?: boolean | null,
    isVerified?: boolean | null,
    images?: Array< string | null > | null,
    address?: string | null,
    totalEvents?: number | null,
    flags?: Array< string | null > | null,
    frontPersonalID?: string | null,
    isFrontPersonalIDEdited?: boolean | null,
    passportImage?: string | null,
    isPassportImageEdited?: boolean | null,
    backPersonalID?: string | null,
    isBackPersonalIDEdited?: boolean | null,
    faceBookProfileLink?: string | null,
    instagramProfileLink?: string | null,
    nationality?: string | null,
    balance?: number | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    isBlocked?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateGuestSubscriptionVariables = {
  filter?: ModelSubscriptionGuestFilterInput | null,
};

export type OnUpdateGuestSubscription = {
  onUpdateGuest?:  {
    __typename: "Guest",
    id: string,
    name?: string | null,
    isNameEdited?: boolean | null,
    username?: string | null,
    email?: string | null,
    phone_number?: string | null,
    guestGroupID?: string | null,
    guestGroupName?: string | null,
    sub?: string | null,
    facebookSub?: string | null,
    guest_avatar?: string | null,
    avg_spend?: number | null,
    avg_ticket_type?: string | null,
    numberOfTickets?: number | null,
    connections?: string | null,
    last_attended_event?: string | null,
    last_attended_event_Name?: string | null,
    gender?: string | null,
    isGenderEdited?: boolean | null,
    group?: string | null,
    faceBookID?: string | null,
    appPassword?: string | null,
    birthdate?: string | null,
    isBirthdateEdited?: boolean | null,
    isVerified?: boolean | null,
    images?: Array< string | null > | null,
    address?: string | null,
    totalEvents?: number | null,
    flags?: Array< string | null > | null,
    frontPersonalID?: string | null,
    isFrontPersonalIDEdited?: boolean | null,
    passportImage?: string | null,
    isPassportImageEdited?: boolean | null,
    backPersonalID?: string | null,
    isBackPersonalIDEdited?: boolean | null,
    faceBookProfileLink?: string | null,
    instagramProfileLink?: string | null,
    nationality?: string | null,
    balance?: number | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    isBlocked?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteGuestSubscriptionVariables = {
  filter?: ModelSubscriptionGuestFilterInput | null,
};

export type OnDeleteGuestSubscription = {
  onDeleteGuest?:  {
    __typename: "Guest",
    id: string,
    name?: string | null,
    isNameEdited?: boolean | null,
    username?: string | null,
    email?: string | null,
    phone_number?: string | null,
    guestGroupID?: string | null,
    guestGroupName?: string | null,
    sub?: string | null,
    facebookSub?: string | null,
    guest_avatar?: string | null,
    avg_spend?: number | null,
    avg_ticket_type?: string | null,
    numberOfTickets?: number | null,
    connections?: string | null,
    last_attended_event?: string | null,
    last_attended_event_Name?: string | null,
    gender?: string | null,
    isGenderEdited?: boolean | null,
    group?: string | null,
    faceBookID?: string | null,
    appPassword?: string | null,
    birthdate?: string | null,
    isBirthdateEdited?: boolean | null,
    isVerified?: boolean | null,
    images?: Array< string | null > | null,
    address?: string | null,
    totalEvents?: number | null,
    flags?: Array< string | null > | null,
    frontPersonalID?: string | null,
    isFrontPersonalIDEdited?: boolean | null,
    passportImage?: string | null,
    isPassportImageEdited?: boolean | null,
    backPersonalID?: string | null,
    isBackPersonalIDEdited?: boolean | null,
    faceBookProfileLink?: string | null,
    instagramProfileLink?: string | null,
    nationality?: string | null,
    balance?: number | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    isBlocked?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateGuestGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGuestGroupFilterInput | null,
};

export type OnCreateGuestGroupSubscription = {
  onCreateGuestGroup?:  {
    __typename: "GuestGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    color?: string | null,
    guests?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateGuestGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGuestGroupFilterInput | null,
};

export type OnUpdateGuestGroupSubscription = {
  onUpdateGuestGroup?:  {
    __typename: "GuestGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    color?: string | null,
    guests?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteGuestGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGuestGroupFilterInput | null,
};

export type OnDeleteGuestGroupSubscription = {
  onDeleteGuestGroup?:  {
    __typename: "GuestGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    color?: string | null,
    guests?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTimelineSubscriptionVariables = {
  filter?: ModelSubscriptionTimelineFilterInput | null,
};

export type OnCreateTimelineSubscription = {
  onCreateTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    customerId?: string | null,
    isScanned?: string | null,
    type?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTimelineSubscriptionVariables = {
  filter?: ModelSubscriptionTimelineFilterInput | null,
};

export type OnUpdateTimelineSubscription = {
  onUpdateTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    customerId?: string | null,
    isScanned?: string | null,
    type?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTimelineSubscriptionVariables = {
  filter?: ModelSubscriptionTimelineFilterInput | null,
};

export type OnDeleteTimelineSubscription = {
  onDeleteTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    customerId?: string | null,
    isScanned?: string | null,
    type?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFlagSubscriptionVariables = {
  filter?: ModelSubscriptionFlagFilterInput | null,
};

export type OnCreateFlagSubscription = {
  onCreateFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFlagSubscriptionVariables = {
  filter?: ModelSubscriptionFlagFilterInput | null,
};

export type OnUpdateFlagSubscription = {
  onUpdateFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFlagSubscriptionVariables = {
  filter?: ModelSubscriptionFlagFilterInput | null,
};

export type OnDeleteFlagSubscription = {
  onDeleteFlag?:  {
    __typename: "Flag",
    id: string,
    accountID: string,
    name: string,
    icon: string,
    color?: string | null,
    customers?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    replyTo?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByImg?: string | null,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    replyTo?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByImg?: string | null,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    message: string,
    customerId?: string | null,
    bookingId?: string | null,
    replyTo?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByImg?: string | null,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    name?: string | null,
    description?: string | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    location?:  {
      __typename: "Location",
      address?: string | null,
      coordinates?:  {
        __typename: "Coordinates",
        lat?: number | null,
        lng?: number | null,
      } | null,
    } | null,
    todoList?: Array< string | null > | null,
    eventComments?:  Array< {
      __typename: "EventComments",
      image?: string | null,
      name?: string | null,
      message?: string | null,
    } | null > | null,
    map?: string | null,
    image?: string | null,
    largeScreensImage?: string | null,
    mediumScreensImage?: string | null,
    smallScreensImage?: string | null,
    sendEmailToValidGuest?: boolean | null,
    sendSmsToValidGuest?: boolean | null,
    gallery?: Array< string | null > | null,
    visibleTo?: string | null,
    tickets?:  {
      __typename: "ModelEventTicketConnection",
      items:  Array< {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gates?:  {
      __typename: "ModelGateConnection",
      items:  Array< {
        __typename: "Gate",
        id: string,
        name: string,
        eventId: string,
        tickets?: Array< string | null > | null,
        admins?: Array< string | null > | null,
        checkpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invitationLimit?:  {
      __typename: "ModelInvitationLimitConnection",
      items:  Array< {
        __typename: "InvitationLimit",
        id: string,
        adminID?: string | null,
        quota?: number | null,
        eventID?: string | null,
        totalQuota?: number | null,
        sentInvitations?:  Array< {
          __typename: "sentInvitation",
          invitationNumber?: number | null,
          reciver?: string | null,
        } | null > | null,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventInvitationLimitId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    published: boolean,
    applyGenderRatio?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    name?: string | null,
    description?: string | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    location?:  {
      __typename: "Location",
      address?: string | null,
      coordinates?:  {
        __typename: "Coordinates",
        lat?: number | null,
        lng?: number | null,
      } | null,
    } | null,
    todoList?: Array< string | null > | null,
    eventComments?:  Array< {
      __typename: "EventComments",
      image?: string | null,
      name?: string | null,
      message?: string | null,
    } | null > | null,
    map?: string | null,
    image?: string | null,
    largeScreensImage?: string | null,
    mediumScreensImage?: string | null,
    smallScreensImage?: string | null,
    sendEmailToValidGuest?: boolean | null,
    sendSmsToValidGuest?: boolean | null,
    gallery?: Array< string | null > | null,
    visibleTo?: string | null,
    tickets?:  {
      __typename: "ModelEventTicketConnection",
      items:  Array< {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gates?:  {
      __typename: "ModelGateConnection",
      items:  Array< {
        __typename: "Gate",
        id: string,
        name: string,
        eventId: string,
        tickets?: Array< string | null > | null,
        admins?: Array< string | null > | null,
        checkpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invitationLimit?:  {
      __typename: "ModelInvitationLimitConnection",
      items:  Array< {
        __typename: "InvitationLimit",
        id: string,
        adminID?: string | null,
        quota?: number | null,
        eventID?: string | null,
        totalQuota?: number | null,
        sentInvitations?:  Array< {
          __typename: "sentInvitation",
          invitationNumber?: number | null,
          reciver?: string | null,
        } | null > | null,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventInvitationLimitId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    published: boolean,
    applyGenderRatio?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteEventSubscriptionVariables = {
  filter?: ModelSubscriptionEventFilterInput | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    name?: string | null,
    description?: string | null,
    disclaimer?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    location?:  {
      __typename: "Location",
      address?: string | null,
      coordinates?:  {
        __typename: "Coordinates",
        lat?: number | null,
        lng?: number | null,
      } | null,
    } | null,
    todoList?: Array< string | null > | null,
    eventComments?:  Array< {
      __typename: "EventComments",
      image?: string | null,
      name?: string | null,
      message?: string | null,
    } | null > | null,
    map?: string | null,
    image?: string | null,
    largeScreensImage?: string | null,
    mediumScreensImage?: string | null,
    smallScreensImage?: string | null,
    sendEmailToValidGuest?: boolean | null,
    sendSmsToValidGuest?: boolean | null,
    gallery?: Array< string | null > | null,
    visibleTo?: string | null,
    tickets?:  {
      __typename: "ModelEventTicketConnection",
      items:  Array< {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    gates?:  {
      __typename: "ModelGateConnection",
      items:  Array< {
        __typename: "Gate",
        id: string,
        name: string,
        eventId: string,
        tickets?: Array< string | null > | null,
        admins?: Array< string | null > | null,
        checkpoints?:  Array< {
          __typename: "Checkpoint",
          id: string,
          name: string,
          precedence: number,
          isSkippable?: boolean | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
        } | null > | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    invitationLimit?:  {
      __typename: "ModelInvitationLimitConnection",
      items:  Array< {
        __typename: "InvitationLimit",
        id: string,
        adminID?: string | null,
        quota?: number | null,
        eventID?: string | null,
        totalQuota?: number | null,
        sentInvitations?:  Array< {
          __typename: "sentInvitation",
          invitationNumber?: number | null,
          reciver?: string | null,
        } | null > | null,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        eventInvitationLimitId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    published: boolean,
    applyGenderRatio?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateEventTicketSubscriptionVariables = {
  filter?: ModelSubscriptionEventTicketFilterInput | null,
};

export type OnCreateEventTicketSubscription = {
  onCreateEventTicket?:  {
    __typename: "EventTicket",
    id: string,
    type: string,
    cashlessCredit?: number | null,
    description?: string | null,
    waves?:  Array< {
      __typename: "Wave",
      id: string,
      name: string,
      price: number,
      startDate?: string | null,
      endDate?: string | null,
      active?: boolean | null,
      quota?: number | null,
      AutomaticShift?: string | null,
    } | null > | null,
    color?: string | null,
    paymentRules?: string | null,
    approvalRule?: string | null,
    showAll?: boolean | null,
    showOnHold?: boolean | null,
    onHoldDisplayText?: string | null,
    showSoldOut?: boolean | null,
    soldOutDisplayText?: string | null,
    setAvailable?: boolean | null,
    availableAtDate?: string | null,
    allowTransferred?: boolean | null,
    transferredAprroval?: boolean | null,
    transferredSameGender?: boolean | null,
    showUpcomingWave?: boolean | null,
    showUpcomingWaveDisplayText?: string | null,
    eventID: string,
    gateID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateEventTicketSubscriptionVariables = {
  filter?: ModelSubscriptionEventTicketFilterInput | null,
};

export type OnUpdateEventTicketSubscription = {
  onUpdateEventTicket?:  {
    __typename: "EventTicket",
    id: string,
    type: string,
    cashlessCredit?: number | null,
    description?: string | null,
    waves?:  Array< {
      __typename: "Wave",
      id: string,
      name: string,
      price: number,
      startDate?: string | null,
      endDate?: string | null,
      active?: boolean | null,
      quota?: number | null,
      AutomaticShift?: string | null,
    } | null > | null,
    color?: string | null,
    paymentRules?: string | null,
    approvalRule?: string | null,
    showAll?: boolean | null,
    showOnHold?: boolean | null,
    onHoldDisplayText?: string | null,
    showSoldOut?: boolean | null,
    soldOutDisplayText?: string | null,
    setAvailable?: boolean | null,
    availableAtDate?: string | null,
    allowTransferred?: boolean | null,
    transferredAprroval?: boolean | null,
    transferredSameGender?: boolean | null,
    showUpcomingWave?: boolean | null,
    showUpcomingWaveDisplayText?: string | null,
    eventID: string,
    gateID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteEventTicketSubscriptionVariables = {
  filter?: ModelSubscriptionEventTicketFilterInput | null,
};

export type OnDeleteEventTicketSubscription = {
  onDeleteEventTicket?:  {
    __typename: "EventTicket",
    id: string,
    type: string,
    cashlessCredit?: number | null,
    description?: string | null,
    waves?:  Array< {
      __typename: "Wave",
      id: string,
      name: string,
      price: number,
      startDate?: string | null,
      endDate?: string | null,
      active?: boolean | null,
      quota?: number | null,
      AutomaticShift?: string | null,
    } | null > | null,
    color?: string | null,
    paymentRules?: string | null,
    approvalRule?: string | null,
    showAll?: boolean | null,
    showOnHold?: boolean | null,
    onHoldDisplayText?: string | null,
    showSoldOut?: boolean | null,
    soldOutDisplayText?: string | null,
    setAvailable?: boolean | null,
    availableAtDate?: string | null,
    allowTransferred?: boolean | null,
    transferredAprroval?: boolean | null,
    transferredSameGender?: boolean | null,
    showUpcomingWave?: boolean | null,
    showUpcomingWaveDisplayText?: string | null,
    eventID: string,
    gateID?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInvitationLimitSubscriptionVariables = {
  filter?: ModelSubscriptionInvitationLimitFilterInput | null,
};

export type OnCreateInvitationLimitSubscription = {
  onCreateInvitationLimit?:  {
    __typename: "InvitationLimit",
    id: string,
    adminID?: string | null,
    quota?: number | null,
    eventID?: string | null,
    totalQuota?: number | null,
    sentInvitations?:  Array< {
      __typename: "sentInvitation",
      invitationNumber?: number | null,
      reciver?: string | null,
    } | null > | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventInvitationLimitId?: string | null,
  } | null,
};

export type OnUpdateInvitationLimitSubscriptionVariables = {
  filter?: ModelSubscriptionInvitationLimitFilterInput | null,
};

export type OnUpdateInvitationLimitSubscription = {
  onUpdateInvitationLimit?:  {
    __typename: "InvitationLimit",
    id: string,
    adminID?: string | null,
    quota?: number | null,
    eventID?: string | null,
    totalQuota?: number | null,
    sentInvitations?:  Array< {
      __typename: "sentInvitation",
      invitationNumber?: number | null,
      reciver?: string | null,
    } | null > | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventInvitationLimitId?: string | null,
  } | null,
};

export type OnDeleteInvitationLimitSubscriptionVariables = {
  filter?: ModelSubscriptionInvitationLimitFilterInput | null,
};

export type OnDeleteInvitationLimitSubscription = {
  onDeleteInvitationLimit?:  {
    __typename: "InvitationLimit",
    id: string,
    adminID?: string | null,
    quota?: number | null,
    eventID?: string | null,
    totalQuota?: number | null,
    sentInvitations?:  Array< {
      __typename: "sentInvitation",
      invitationNumber?: number | null,
      reciver?: string | null,
    } | null > | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    eventInvitationLimitId?: string | null,
  } | null,
};

export type OnCreateBookingSubscriptionVariables = {
  filter?: ModelSubscriptionBookingFilterInput | null,
};

export type OnCreateBookingSubscription = {
  onCreateBooking?:  {
    __typename: "Booking",
    id: string,
    status?: string | null,
    overallStatus?: string | null,
    bookingGuestId?: string | null,
    bookingMainGuestId?: string | null,
    mainBookingId?: string | null,
    bookingEventId?: string | null,
    bookingEventTicketId?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mainGuest:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    wave?: string | null,
    waveId: string,
    isMainGuest?: boolean | null,
    isInvited?: boolean | null,
    orderId?: string | null,
    ticketNumber?: string | null,
    redeemed?: boolean | null,
    passedCheckpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    statusUpdatedByID?: string | null,
    statusUpdatedByName?: string | null,
    statusUpdatedAt?: string | null,
    specialNeed?: boolean | null,
    phone_number?: string | null,
    guestTicket?:  {
      __typename: "GuestTicket",
      number?: string | null,
      redeemed?: boolean | null,
    } | null,
    guestName?: string | null,
    notRegisteredGuests?: Array< string | null > | null,
    hasNotRegisteredGuest?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    rejectionReason?:  {
      __typename: "RejectReason",
      id: string,
      content: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    rejectionComment?: string | null,
    isPaid?: boolean | null,
    isTransfered?: boolean | null,
    isPendingTransfer?: boolean | null,
    lastGuestId?: string | null,
    lastGuest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lastMainGuestId?: string | null,
    ownerHistory?: Array< string | null > | null,
    paidAmount?: number | null,
    balance?: number | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    refunded?: boolean | null,
    paymentTransactionId?: string | null,
    paymentParentTransactionId?: string | null,
    origin?: string | null,
    paymentMethod?: string | null,
    testField?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    bookingRejectionReasonId?: string | null,
  } | null,
};

export type OnUpdateBookingSubscriptionVariables = {
  filter?: ModelSubscriptionBookingFilterInput | null,
};

export type OnUpdateBookingSubscription = {
  onUpdateBooking?:  {
    __typename: "Booking",
    id: string,
    status?: string | null,
    overallStatus?: string | null,
    bookingGuestId?: string | null,
    bookingMainGuestId?: string | null,
    mainBookingId?: string | null,
    bookingEventId?: string | null,
    bookingEventTicketId?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mainGuest:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    wave?: string | null,
    waveId: string,
    isMainGuest?: boolean | null,
    isInvited?: boolean | null,
    orderId?: string | null,
    ticketNumber?: string | null,
    redeemed?: boolean | null,
    passedCheckpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    statusUpdatedByID?: string | null,
    statusUpdatedByName?: string | null,
    statusUpdatedAt?: string | null,
    specialNeed?: boolean | null,
    phone_number?: string | null,
    guestTicket?:  {
      __typename: "GuestTicket",
      number?: string | null,
      redeemed?: boolean | null,
    } | null,
    guestName?: string | null,
    notRegisteredGuests?: Array< string | null > | null,
    hasNotRegisteredGuest?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    rejectionReason?:  {
      __typename: "RejectReason",
      id: string,
      content: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    rejectionComment?: string | null,
    isPaid?: boolean | null,
    isTransfered?: boolean | null,
    isPendingTransfer?: boolean | null,
    lastGuestId?: string | null,
    lastGuest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lastMainGuestId?: string | null,
    ownerHistory?: Array< string | null > | null,
    paidAmount?: number | null,
    balance?: number | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    refunded?: boolean | null,
    paymentTransactionId?: string | null,
    paymentParentTransactionId?: string | null,
    origin?: string | null,
    paymentMethod?: string | null,
    testField?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    bookingRejectionReasonId?: string | null,
  } | null,
};

export type OnDeleteBookingSubscriptionVariables = {
  filter?: ModelSubscriptionBookingFilterInput | null,
};

export type OnDeleteBookingSubscription = {
  onDeleteBooking?:  {
    __typename: "Booking",
    id: string,
    status?: string | null,
    overallStatus?: string | null,
    bookingGuestId?: string | null,
    bookingMainGuestId?: string | null,
    mainBookingId?: string | null,
    bookingEventId?: string | null,
    bookingEventTicketId?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    mainGuest:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    wave?: string | null,
    waveId: string,
    isMainGuest?: boolean | null,
    isInvited?: boolean | null,
    orderId?: string | null,
    ticketNumber?: string | null,
    redeemed?: boolean | null,
    passedCheckpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    statusUpdatedByID?: string | null,
    statusUpdatedByName?: string | null,
    statusUpdatedAt?: string | null,
    specialNeed?: boolean | null,
    phone_number?: string | null,
    guestTicket?:  {
      __typename: "GuestTicket",
      number?: string | null,
      redeemed?: boolean | null,
    } | null,
    guestName?: string | null,
    notRegisteredGuests?: Array< string | null > | null,
    hasNotRegisteredGuest?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    rejectionReason?:  {
      __typename: "RejectReason",
      id: string,
      content: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt?: string | null,
      updatedByID?: string | null,
      updatedByName?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    rejectionComment?: string | null,
    isPaid?: boolean | null,
    isTransfered?: boolean | null,
    isPendingTransfer?: boolean | null,
    lastGuestId?: string | null,
    lastGuest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    lastMainGuestId?: string | null,
    ownerHistory?: Array< string | null > | null,
    paidAmount?: number | null,
    balance?: number | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    refunded?: boolean | null,
    paymentTransactionId?: string | null,
    paymentParentTransactionId?: string | null,
    origin?: string | null,
    paymentMethod?: string | null,
    testField?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    bookingRejectionReasonId?: string | null,
  } | null,
};

export type OnCreateRejectReasonSubscriptionVariables = {
  filter?: ModelSubscriptionRejectReasonFilterInput | null,
};

export type OnCreateRejectReasonSubscription = {
  onCreateRejectReason?:  {
    __typename: "RejectReason",
    id: string,
    content: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateRejectReasonSubscriptionVariables = {
  filter?: ModelSubscriptionRejectReasonFilterInput | null,
};

export type OnUpdateRejectReasonSubscription = {
  onUpdateRejectReason?:  {
    __typename: "RejectReason",
    id: string,
    content: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteRejectReasonSubscriptionVariables = {
  filter?: ModelSubscriptionRejectReasonFilterInput | null,
};

export type OnDeleteRejectReasonSubscription = {
  onDeleteRejectReason?:  {
    __typename: "RejectReason",
    id: string,
    content: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt?: string | null,
    updatedByID?: string | null,
    updatedByName?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionInvitationFilterInput | null,
};

export type OnCreateInvitationSubscription = {
  onCreateInvitation?:  {
    __typename: "Invitation",
    id: string,
    invitationEventId?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    balance?: number | null,
    wave?: string | null,
    phone_number?: string | null,
    name?: string | null,
    email?: string | null,
    reason?: string | null,
    secret?: string | null,
    used?: boolean | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    invitationEventTicketId: string,
  } | null,
};

export type OnUpdateInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionInvitationFilterInput | null,
};

export type OnUpdateInvitationSubscription = {
  onUpdateInvitation?:  {
    __typename: "Invitation",
    id: string,
    invitationEventId?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    balance?: number | null,
    wave?: string | null,
    phone_number?: string | null,
    name?: string | null,
    email?: string | null,
    reason?: string | null,
    secret?: string | null,
    used?: boolean | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    invitationEventTicketId: string,
  } | null,
};

export type OnDeleteInvitationSubscriptionVariables = {
  filter?: ModelSubscriptionInvitationFilterInput | null,
};

export type OnDeleteInvitationSubscription = {
  onDeleteInvitation?:  {
    __typename: "Invitation",
    id: string,
    invitationEventId?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    eventTicket:  {
      __typename: "EventTicket",
      id: string,
      type: string,
      cashlessCredit?: number | null,
      description?: string | null,
      waves?:  Array< {
        __typename: "Wave",
        id: string,
        name: string,
        price: number,
        startDate?: string | null,
        endDate?: string | null,
        active?: boolean | null,
        quota?: number | null,
        AutomaticShift?: string | null,
      } | null > | null,
      color?: string | null,
      paymentRules?: string | null,
      approvalRule?: string | null,
      showAll?: boolean | null,
      showOnHold?: boolean | null,
      onHoldDisplayText?: string | null,
      showSoldOut?: boolean | null,
      soldOutDisplayText?: string | null,
      setAvailable?: boolean | null,
      availableAtDate?: string | null,
      allowTransferred?: boolean | null,
      transferredAprroval?: boolean | null,
      transferredSameGender?: boolean | null,
      showUpcomingWave?: boolean | null,
      showUpcomingWaveDisplayText?: string | null,
      eventID: string,
      gateID?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    cashlessCredit?: number | null,
    balance?: number | null,
    wave?: string | null,
    phone_number?: string | null,
    name?: string | null,
    email?: string | null,
    reason?: string | null,
    secret?: string | null,
    used?: boolean | null,
    scannedAt?: string | null,
    scannedByName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    invitationEventTicketId: string,
  } | null,
};

export type OnCreateTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionFilterInput | null,
};

export type OnCreateTransactionSubscription = {
  onCreateTransaction?:  {
    __typename: "Transaction",
    id: string,
    type?: string | null,
    guestId?: string | null,
    guestName?: string | null,
    guestsNames?: Array< string | null > | null,
    guestPhone_number?: string | null,
    eventId: string,
    ticketId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    success?: boolean | null,
    failureReason?: string | null,
    currency?: string | null,
    amount_cents?: string | null,
    refund?: boolean | null,
    refunded_amount_cents?: string | null,
    orderID: string,
    transactionID: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transactionBookingId?: string | null,
  } | null,
};

export type OnUpdateTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionFilterInput | null,
};

export type OnUpdateTransactionSubscription = {
  onUpdateTransaction?:  {
    __typename: "Transaction",
    id: string,
    type?: string | null,
    guestId?: string | null,
    guestName?: string | null,
    guestsNames?: Array< string | null > | null,
    guestPhone_number?: string | null,
    eventId: string,
    ticketId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    success?: boolean | null,
    failureReason?: string | null,
    currency?: string | null,
    amount_cents?: string | null,
    refund?: boolean | null,
    refunded_amount_cents?: string | null,
    orderID: string,
    transactionID: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transactionBookingId?: string | null,
  } | null,
};

export type OnDeleteTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionFilterInput | null,
};

export type OnDeleteTransactionSubscription = {
  onDeleteTransaction?:  {
    __typename: "Transaction",
    id: string,
    type?: string | null,
    guestId?: string | null,
    guestName?: string | null,
    guestsNames?: Array< string | null > | null,
    guestPhone_number?: string | null,
    eventId: string,
    ticketId?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    success?: boolean | null,
    failureReason?: string | null,
    currency?: string | null,
    amount_cents?: string | null,
    refund?: boolean | null,
    refunded_amount_cents?: string | null,
    orderID: string,
    transactionID: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transactionBookingId?: string | null,
  } | null,
};

export type OnCreateWavesConsumptionSubscriptionVariables = {
  filter?: ModelSubscriptionWavesConsumptionFilterInput | null,
};

export type OnCreateWavesConsumptionSubscription = {
  onCreateWavesConsumption?:  {
    __typename: "WavesConsumption",
    waveId: string,
    consumedTickets?: number | null,
    reservedTickets?: number | null,
    totalTickets: number,
    consumed?: string | null,
    eventId: string,
    eventTicketId?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateWavesConsumptionSubscriptionVariables = {
  filter?: ModelSubscriptionWavesConsumptionFilterInput | null,
};

export type OnUpdateWavesConsumptionSubscription = {
  onUpdateWavesConsumption?:  {
    __typename: "WavesConsumption",
    waveId: string,
    consumedTickets?: number | null,
    reservedTickets?: number | null,
    totalTickets: number,
    consumed?: string | null,
    eventId: string,
    eventTicketId?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteWavesConsumptionSubscriptionVariables = {
  filter?: ModelSubscriptionWavesConsumptionFilterInput | null,
};

export type OnDeleteWavesConsumptionSubscription = {
  onDeleteWavesConsumption?:  {
    __typename: "WavesConsumption",
    waveId: string,
    consumedTickets?: number | null,
    reservedTickets?: number | null,
    totalTickets: number,
    consumed?: string | null,
    eventId: string,
    eventTicketId?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCashlessTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionCashlessTransactionFilterInput | null,
};

export type OnCreateCashlessTransactionSubscription = {
  onCreateCashlessTransaction?:  {
    __typename: "CashlessTransaction",
    id: string,
    pos: string,
    checkNumber: string,
    orderTotal: number,
    paidAmount: number,
    paymentStatus: string,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    guestName: string,
    type?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCashlessTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionCashlessTransactionFilterInput | null,
};

export type OnUpdateCashlessTransactionSubscription = {
  onUpdateCashlessTransaction?:  {
    __typename: "CashlessTransaction",
    id: string,
    pos: string,
    checkNumber: string,
    orderTotal: number,
    paidAmount: number,
    paymentStatus: string,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    guestName: string,
    type?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCashlessTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionCashlessTransactionFilterInput | null,
};

export type OnDeleteCashlessTransactionSubscription = {
  onDeleteCashlessTransaction?:  {
    __typename: "CashlessTransaction",
    id: string,
    pos: string,
    checkNumber: string,
    orderTotal: number,
    paidAmount: number,
    paymentStatus: string,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    guestName: string,
    type?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCashlessTopUpSubscriptionVariables = {
  filter?: ModelSubscriptionCashlessTopUpFilterInput | null,
};

export type OnCreateCashlessTopUpSubscription = {
  onCreateCashlessTopUp?:  {
    __typename: "CashlessTopUp",
    id: string,
    operation: string,
    amount: number,
    availableBalance?: number | null,
    paymentChannel: string,
    paymentMethod: string,
    isVoided?: string | null,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    bookingId?: string | null,
    invitationId?: string | null,
    ticketType?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    invitation?:  {
      __typename: "Invitation",
      id: string,
      invitationEventId?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      balance?: number | null,
      wave?: string | null,
      phone_number?: string | null,
      name?: string | null,
      email?: string | null,
      reason?: string | null,
      secret?: string | null,
      used?: boolean | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      invitationEventTicketId: string,
    } | null,
    eventId: string,
    eventName?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    status: string,
    type?: string | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    guestName: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCashlessTopUpSubscriptionVariables = {
  filter?: ModelSubscriptionCashlessTopUpFilterInput | null,
};

export type OnUpdateCashlessTopUpSubscription = {
  onUpdateCashlessTopUp?:  {
    __typename: "CashlessTopUp",
    id: string,
    operation: string,
    amount: number,
    availableBalance?: number | null,
    paymentChannel: string,
    paymentMethod: string,
    isVoided?: string | null,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    bookingId?: string | null,
    invitationId?: string | null,
    ticketType?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    invitation?:  {
      __typename: "Invitation",
      id: string,
      invitationEventId?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      balance?: number | null,
      wave?: string | null,
      phone_number?: string | null,
      name?: string | null,
      email?: string | null,
      reason?: string | null,
      secret?: string | null,
      used?: boolean | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      invitationEventTicketId: string,
    } | null,
    eventId: string,
    eventName?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    status: string,
    type?: string | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    guestName: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCashlessTopUpSubscriptionVariables = {
  filter?: ModelSubscriptionCashlessTopUpFilterInput | null,
};

export type OnDeleteCashlessTopUpSubscription = {
  onDeleteCashlessTopUp?:  {
    __typename: "CashlessTopUp",
    id: string,
    operation: string,
    amount: number,
    availableBalance?: number | null,
    paymentChannel: string,
    paymentMethod: string,
    isVoided?: string | null,
    guestId: string,
    guestPhoneNumber?: string | null,
    guestEmail?: string | null,
    guest?:  {
      __typename: "Guest",
      id: string,
      name?: string | null,
      isNameEdited?: boolean | null,
      username?: string | null,
      email?: string | null,
      phone_number?: string | null,
      guestGroupID?: string | null,
      guestGroupName?: string | null,
      sub?: string | null,
      facebookSub?: string | null,
      guest_avatar?: string | null,
      avg_spend?: number | null,
      avg_ticket_type?: string | null,
      numberOfTickets?: number | null,
      connections?: string | null,
      last_attended_event?: string | null,
      last_attended_event_Name?: string | null,
      gender?: string | null,
      isGenderEdited?: boolean | null,
      group?: string | null,
      faceBookID?: string | null,
      appPassword?: string | null,
      birthdate?: string | null,
      isBirthdateEdited?: boolean | null,
      isVerified?: boolean | null,
      images?: Array< string | null > | null,
      address?: string | null,
      totalEvents?: number | null,
      flags?: Array< string | null > | null,
      frontPersonalID?: string | null,
      isFrontPersonalIDEdited?: boolean | null,
      passportImage?: string | null,
      isPassportImageEdited?: boolean | null,
      backPersonalID?: string | null,
      isBackPersonalIDEdited?: boolean | null,
      faceBookProfileLink?: string | null,
      instagramProfileLink?: string | null,
      nationality?: string | null,
      balance?: number | null,
      orderedItems?:  Array< {
        __typename: "OrderedItem",
        id?: string | null,
        name?: string | null,
        quantity: number,
        price: number,
      } | null > | null,
      isBlocked?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    bookingId?: string | null,
    invitationId?: string | null,
    ticketType?: string | null,
    booking?:  {
      __typename: "Booking",
      id: string,
      status?: string | null,
      overallStatus?: string | null,
      bookingGuestId?: string | null,
      bookingMainGuestId?: string | null,
      mainBookingId?: string | null,
      bookingEventId?: string | null,
      bookingEventTicketId?: string | null,
      guest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      mainGuest:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      wave?: string | null,
      waveId: string,
      isMainGuest?: boolean | null,
      isInvited?: boolean | null,
      orderId?: string | null,
      ticketNumber?: string | null,
      redeemed?: boolean | null,
      passedCheckpoints?:  Array< {
        __typename: "Checkpoint",
        id: string,
        name: string,
        precedence: number,
        isSkippable?: boolean | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
      } | null > | null,
      statusUpdatedByID?: string | null,
      statusUpdatedByName?: string | null,
      statusUpdatedAt?: string | null,
      specialNeed?: boolean | null,
      phone_number?: string | null,
      guestTicket?:  {
        __typename: "GuestTicket",
        number?: string | null,
        redeemed?: boolean | null,
      } | null,
      guestName?: string | null,
      notRegisteredGuests?: Array< string | null > | null,
      hasNotRegisteredGuest?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      rejectionReason?:  {
        __typename: "RejectReason",
        id: string,
        content: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt?: string | null,
        updatedByID?: string | null,
        updatedByName?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      rejectionComment?: string | null,
      isPaid?: boolean | null,
      isTransfered?: boolean | null,
      isPendingTransfer?: boolean | null,
      lastGuestId?: string | null,
      lastGuest?:  {
        __typename: "Guest",
        id: string,
        name?: string | null,
        isNameEdited?: boolean | null,
        username?: string | null,
        email?: string | null,
        phone_number?: string | null,
        guestGroupID?: string | null,
        guestGroupName?: string | null,
        sub?: string | null,
        facebookSub?: string | null,
        guest_avatar?: string | null,
        avg_spend?: number | null,
        avg_ticket_type?: string | null,
        numberOfTickets?: number | null,
        connections?: string | null,
        last_attended_event?: string | null,
        last_attended_event_Name?: string | null,
        gender?: string | null,
        isGenderEdited?: boolean | null,
        group?: string | null,
        faceBookID?: string | null,
        appPassword?: string | null,
        birthdate?: string | null,
        isBirthdateEdited?: boolean | null,
        isVerified?: boolean | null,
        images?: Array< string | null > | null,
        address?: string | null,
        totalEvents?: number | null,
        flags?: Array< string | null > | null,
        frontPersonalID?: string | null,
        isFrontPersonalIDEdited?: boolean | null,
        passportImage?: string | null,
        isPassportImageEdited?: boolean | null,
        backPersonalID?: string | null,
        isBackPersonalIDEdited?: boolean | null,
        faceBookProfileLink?: string | null,
        instagramProfileLink?: string | null,
        nationality?: string | null,
        balance?: number | null,
        orderedItems?:  Array< {
          __typename: "OrderedItem",
          id?: string | null,
          name?: string | null,
          quantity: number,
          price: number,
        } | null > | null,
        isBlocked?: boolean | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      lastMainGuestId?: string | null,
      ownerHistory?: Array< string | null > | null,
      paidAmount?: number | null,
      balance?: number | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      refunded?: boolean | null,
      paymentTransactionId?: string | null,
      paymentParentTransactionId?: string | null,
      origin?: string | null,
      paymentMethod?: string | null,
      testField?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      bookingRejectionReasonId?: string | null,
    } | null,
    invitation?:  {
      __typename: "Invitation",
      id: string,
      invitationEventId?: string | null,
      event:  {
        __typename: "Event",
        id: string,
        name?: string | null,
        description?: string | null,
        disclaimer?: string | null,
        startDate?: string | null,
        endDate?: string | null,
        location?:  {
          __typename: "Location",
          address?: string | null,
        } | null,
        todoList?: Array< string | null > | null,
        eventComments?:  Array< {
          __typename: "EventComments",
          image?: string | null,
          name?: string | null,
          message?: string | null,
        } | null > | null,
        map?: string | null,
        image?: string | null,
        largeScreensImage?: string | null,
        mediumScreensImage?: string | null,
        smallScreensImage?: string | null,
        sendEmailToValidGuest?: boolean | null,
        sendSmsToValidGuest?: boolean | null,
        gallery?: Array< string | null > | null,
        visibleTo?: string | null,
        tickets?:  {
          __typename: "ModelEventTicketConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        gates?:  {
          __typename: "ModelGateConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        invitationLimit?:  {
          __typename: "ModelInvitationLimitConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        published: boolean,
        applyGenderRatio?: boolean | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      eventTicket:  {
        __typename: "EventTicket",
        id: string,
        type: string,
        cashlessCredit?: number | null,
        description?: string | null,
        waves?:  Array< {
          __typename: "Wave",
          id: string,
          name: string,
          price: number,
          startDate?: string | null,
          endDate?: string | null,
          active?: boolean | null,
          quota?: number | null,
          AutomaticShift?: string | null,
        } | null > | null,
        color?: string | null,
        paymentRules?: string | null,
        approvalRule?: string | null,
        showAll?: boolean | null,
        showOnHold?: boolean | null,
        onHoldDisplayText?: string | null,
        showSoldOut?: boolean | null,
        soldOutDisplayText?: string | null,
        setAvailable?: boolean | null,
        availableAtDate?: string | null,
        allowTransferred?: boolean | null,
        transferredAprroval?: boolean | null,
        transferredSameGender?: boolean | null,
        showUpcomingWave?: boolean | null,
        showUpcomingWaveDisplayText?: string | null,
        eventID: string,
        gateID?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      cashlessCredit?: number | null,
      balance?: number | null,
      wave?: string | null,
      phone_number?: string | null,
      name?: string | null,
      email?: string | null,
      reason?: string | null,
      secret?: string | null,
      used?: boolean | null,
      scannedAt?: string | null,
      scannedByName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      invitationEventTicketId: string,
    } | null,
    eventId: string,
    eventName?: string | null,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    status: string,
    type?: string | null,
    syncOperationId?: string | null,
    deleted?: string | null,
    guestName: string,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    precedence: number,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        image?: string | null,
        price: number,
        precedence: number,
        categoryID: string,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    precedence: number,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        image?: string | null,
        price: number,
        precedence: number,
        categoryID: string,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    precedence: number,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        name: string,
        image?: string | null,
        price: number,
        precedence: number,
        categoryID: string,
        deleted?: string | null,
        createdAt?: string | null,
        createdByID?: string | null,
        createdByName?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateItemSubscriptionVariables = {
  filter?: ModelSubscriptionItemFilterInput | null,
};

export type OnCreateItemSubscription = {
  onCreateItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    image?: string | null,
    price: number,
    precedence: number,
    categoryID: string,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateItemSubscriptionVariables = {
  filter?: ModelSubscriptionItemFilterInput | null,
};

export type OnUpdateItemSubscription = {
  onUpdateItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    image?: string | null,
    price: number,
    precedence: number,
    categoryID: string,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteItemSubscriptionVariables = {
  filter?: ModelSubscriptionItemFilterInput | null,
};

export type OnDeleteItemSubscription = {
  onDeleteItem?:  {
    __typename: "Item",
    id: string,
    name: string,
    image?: string | null,
    price: number,
    precedence: number,
    categoryID: string,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateItemConsumptionSubscriptionVariables = {
  filter?: ModelSubscriptionItemConsumptionFilterInput | null,
};

export type OnCreateItemConsumptionSubscription = {
  onCreateItemConsumption?:  {
    __typename: "ItemConsumption",
    id: string,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      image?: string | null,
      price: number,
      precedence: number,
      categoryID: string,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    itemName: string,
    itemQuantity: number,
    itemPrice: number,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateItemConsumptionSubscriptionVariables = {
  filter?: ModelSubscriptionItemConsumptionFilterInput | null,
};

export type OnUpdateItemConsumptionSubscription = {
  onUpdateItemConsumption?:  {
    __typename: "ItemConsumption",
    id: string,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      image?: string | null,
      price: number,
      precedence: number,
      categoryID: string,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    itemName: string,
    itemQuantity: number,
    itemPrice: number,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteItemConsumptionSubscriptionVariables = {
  filter?: ModelSubscriptionItemConsumptionFilterInput | null,
};

export type OnDeleteItemConsumptionSubscription = {
  onDeleteItemConsumption?:  {
    __typename: "ItemConsumption",
    id: string,
    eventId: string,
    event:  {
      __typename: "Event",
      id: string,
      name?: string | null,
      description?: string | null,
      disclaimer?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      location?:  {
        __typename: "Location",
        address?: string | null,
        coordinates?:  {
          __typename: "Coordinates",
          lat?: number | null,
          lng?: number | null,
        } | null,
      } | null,
      todoList?: Array< string | null > | null,
      eventComments?:  Array< {
        __typename: "EventComments",
        image?: string | null,
        name?: string | null,
        message?: string | null,
      } | null > | null,
      map?: string | null,
      image?: string | null,
      largeScreensImage?: string | null,
      mediumScreensImage?: string | null,
      smallScreensImage?: string | null,
      sendEmailToValidGuest?: boolean | null,
      sendSmsToValidGuest?: boolean | null,
      gallery?: Array< string | null > | null,
      visibleTo?: string | null,
      tickets?:  {
        __typename: "ModelEventTicketConnection",
        items:  Array< {
          __typename: "EventTicket",
          id: string,
          type: string,
          cashlessCredit?: number | null,
          description?: string | null,
          color?: string | null,
          paymentRules?: string | null,
          approvalRule?: string | null,
          showAll?: boolean | null,
          showOnHold?: boolean | null,
          onHoldDisplayText?: string | null,
          showSoldOut?: boolean | null,
          soldOutDisplayText?: string | null,
          setAvailable?: boolean | null,
          availableAtDate?: string | null,
          allowTransferred?: boolean | null,
          transferredAprroval?: boolean | null,
          transferredSameGender?: boolean | null,
          showUpcomingWave?: boolean | null,
          showUpcomingWaveDisplayText?: string | null,
          eventID: string,
          gateID?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      gates?:  {
        __typename: "ModelGateConnection",
        items:  Array< {
          __typename: "Gate",
          id: string,
          name: string,
          eventId: string,
          tickets?: Array< string | null > | null,
          admins?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      invitationLimit?:  {
        __typename: "ModelInvitationLimitConnection",
        items:  Array< {
          __typename: "InvitationLimit",
          id: string,
          adminID?: string | null,
          quota?: number | null,
          eventID?: string | null,
          totalQuota?: number | null,
          deleted?: string | null,
          createdAt?: string | null,
          createdByID?: string | null,
          createdByName?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          eventInvitationLimitId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      published: boolean,
      applyGenderRatio?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    itemId: string,
    item?:  {
      __typename: "Item",
      id: string,
      name: string,
      image?: string | null,
      price: number,
      precedence: number,
      categoryID: string,
      deleted?: string | null,
      createdAt?: string | null,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    itemName: string,
    itemQuantity: number,
    itemPrice: number,
    syncOperationId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTicketsScanSubscriptionVariables = {
  filter?: ModelSubscriptionTicketsScanFilterInput | null,
};

export type OnCreateTicketsScanSubscription = {
  onCreateTicketsScan?:  {
    __typename: "TicketsScan",
    id: string,
    scannedById: string,
    scannedAt?: string | null,
    status?: string | null,
    eventId?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    guestName?: string | null,
    ticketType?: string | null,
    guestAvatar?: string | null,
    deleted?: string | null,
    gateName?: string | null,
    checkpointName?: string | null,
    scannedByName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTicketsScanSubscriptionVariables = {
  filter?: ModelSubscriptionTicketsScanFilterInput | null,
};

export type OnUpdateTicketsScanSubscription = {
  onUpdateTicketsScan?:  {
    __typename: "TicketsScan",
    id: string,
    scannedById: string,
    scannedAt?: string | null,
    status?: string | null,
    eventId?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    guestName?: string | null,
    ticketType?: string | null,
    guestAvatar?: string | null,
    deleted?: string | null,
    gateName?: string | null,
    checkpointName?: string | null,
    scannedByName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTicketsScanSubscriptionVariables = {
  filter?: ModelSubscriptionTicketsScanFilterInput | null,
};

export type OnDeleteTicketsScanSubscription = {
  onDeleteTicketsScan?:  {
    __typename: "TicketsScan",
    id: string,
    scannedById: string,
    scannedAt?: string | null,
    status?: string | null,
    eventId?: string | null,
    bookingId?: string | null,
    invitationId?: string | null,
    guestName?: string | null,
    ticketType?: string | null,
    guestAvatar?: string | null,
    deleted?: string | null,
    gateName?: string | null,
    checkpointName?: string | null,
    scannedByName?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateGateSubscriptionVariables = {
  filter?: ModelSubscriptionGateFilterInput | null,
};

export type OnCreateGateSubscription = {
  onCreateGate?:  {
    __typename: "Gate",
    id: string,
    name: string,
    eventId: string,
    tickets?: Array< string | null > | null,
    admins?: Array< string | null > | null,
    checkpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateGateSubscriptionVariables = {
  filter?: ModelSubscriptionGateFilterInput | null,
};

export type OnUpdateGateSubscription = {
  onUpdateGate?:  {
    __typename: "Gate",
    id: string,
    name: string,
    eventId: string,
    tickets?: Array< string | null > | null,
    admins?: Array< string | null > | null,
    checkpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteGateSubscriptionVariables = {
  filter?: ModelSubscriptionGateFilterInput | null,
};

export type OnDeleteGateSubscription = {
  onDeleteGate?:  {
    __typename: "Gate",
    id: string,
    name: string,
    eventId: string,
    tickets?: Array< string | null > | null,
    admins?: Array< string | null > | null,
    checkpoints?:  Array< {
      __typename: "Checkpoint",
      id: string,
      name: string,
      precedence: number,
      isSkippable?: boolean | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
    } | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnCreateOrderSubscription = {
  onCreateOrder?:  {
    __typename: "Order",
    id: string,
    bookingId?: string | null,
    eventId?: string | null,
    userId?: string | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    status?: string | null,
    dispatcherId?: string | null,
    totalAmount?: number | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    id: string,
    bookingId?: string | null,
    eventId?: string | null,
    userId?: string | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    status?: string | null,
    dispatcherId?: string | null,
    totalAmount?: number | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOrderFilterInput | null,
};

export type OnDeleteOrderSubscription = {
  onDeleteOrder?:  {
    __typename: "Order",
    id: string,
    bookingId?: string | null,
    eventId?: string | null,
    userId?: string | null,
    orderedItems?:  Array< {
      __typename: "OrderedItem",
      id?: string | null,
      name?: string | null,
      quantity: number,
      price: number,
    } | null > | null,
    status?: string | null,
    dispatcherId?: string | null,
    totalAmount?: number | null,
    deleted?: string | null,
    createdAt?: string | null,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserIPSubscriptionVariables = {
  filter?: ModelSubscriptionUserIPFilterInput | null,
};

export type OnCreateUserIPSubscription = {
  onCreateUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserIPSubscriptionVariables = {
  filter?: ModelSubscriptionUserIPFilterInput | null,
};

export type OnUpdateUserIPSubscription = {
  onUpdateUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserIPSubscriptionVariables = {
  filter?: ModelSubscriptionUserIPFilterInput | null,
};

export type OnDeleteUserIPSubscription = {
  onDeleteUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    times?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateScanSubscriptionVariables = {
  filter?: ModelSubscriptionScanFilterInput | null,
};

export type OnCreateScanSubscription = {
  onCreateScan?:  {
    __typename: "Scan",
    id: string,
    link: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateScanSubscriptionVariables = {
  filter?: ModelSubscriptionScanFilterInput | null,
};

export type OnUpdateScanSubscription = {
  onUpdateScan?:  {
    __typename: "Scan",
    id: string,
    link: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteScanSubscriptionVariables = {
  filter?: ModelSubscriptionScanFilterInput | null,
};

export type OnDeleteScanSubscription = {
  onDeleteScan?:  {
    __typename: "Scan",
    id: string,
    link: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
