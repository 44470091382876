import { FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Link, Typography, Paper, Container } from '@mui/material';
import FormField from '../UI/FormFields/FormFields';
import ButtonLoader from '../UI/ButtonLoader/ButtonLoader';
import useLocalization from '../../hooks/useLocalization';
import useFormFields from '../../hooks/useFormFields';
import useFormValidations from '../../hooks/useFormValidations';
import useListings from '../../hooks/useListings';
import { isLocalhost } from '../../helpers/utils';
import { Pages } from '../../constants/enums';
import { FormFieldsVariables } from '../../models/app';
import Logo from '../Logo/Logo';
// import ImageContainer from "../UI/ImageContainer/ImageContainer";

// those are temporary types, if it exists some where esle import it and delete the following types

const Auth: FC = () => {
  let { slug } = useParams();
  if (slug === undefined || slug === 'login') slug = Pages.LOGIN;

  const { strings } = useLocalization();
  const formParams: FormFieldsVariables = { slug };
  const { getFormFields } = useFormFields(formParams);
  const { getValidationSchema } = useFormValidations(slug!, '..');
  const { createResource } = useListings(slug!);
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<any>({ resolver: yupResolver(getValidationSchema()) });

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    const stateData: any = {};

    await createResource(data, stateData);
  };

  if (slug === Pages.REGISTER) {
    if (!isLocalhost) return <>404 not found!</>;
  }

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-55%)',
        }}
      >
        <Paper sx={{ p: 3, mb: 4 }}>
          <Box
            sx={{
              width: '150px',
              height: 'auto',
              mb: '20px',
              ml: 'auto',
              mr: 'auto',
              img: { maxWidth: '100%' },
              mixBlendMode: 'lighten',
            }}
          >
            <Logo />
            {/* <ImageContainer
              src="1657037835676-logo.png"
              thumb="1657037835676-logo.png"
              alt="logo"
              width={80}
              height={80}
            /> */}
          </Box>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            {getFormFields(null, null).map((field: any) => (
              <FormField
                key={field.name}
                control={control}
                name={field.name}
                label={field.label}
                type={field.type}
                autoFocus={field.autoFocus}
                defaultValue={field.defaultValue}
                options={field.options}
                register={register}
                setValue={setValue}
                errors={errors}
              />
            ))}
            {slug === Pages.LOGIN && (
              <Typography align="right" color="text.secondary" sx={{ mt: 1 }}>
                <Link
                  underline="none"
                  color="inherit"
                  {...({
                    component: RouterLink,
                    to: `/${Pages.FORGOT_PASSWORD}`,
                  } as any)}
                >
                  {strings.forgetpassword}
                </Link>
              </Typography>
            )}
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{ mt: 1, p: 1 }}
              endIcon={isSubmitting && <ButtonLoader />}
              disabled={isSubmitting}
            >
              {slug === Pages.LOGIN && strings.login}
              {slug === Pages.REGISTER && strings.register}
              {slug === Pages.FORGOT_PASSWORD && strings.sendResetMail}
              {slug === Pages.RESET_PASSWORD && strings.resetpassword}
            </Button>
          </Box>
        </Paper>
        {slug === Pages.LOGIN && isLocalhost && (
          <Typography align="center" color="text.secondary" sx={{ mt: 1 }}>
            <Link
              underline="none"
              color="inherit"
              {...({
                component: RouterLink,
                to: `/${Pages.REGISTER}`,
              } as any)}
            >
              {strings.register}
            </Link>
          </Typography>
        )}
      </Container>
    </>
  );
};

export default Auth;
