import {
  signIn,
  signOut,
  signUp,
  resetPassword,
  type ResetPasswordOutput,
} from 'aws-amplify/auth';
import { DEPLOYMENT_VERSION, LOCAL_STORAGE, Pages } from '../constants/enums';
import {
  getBookingDateRange,
  setBookingDateRange,
} from '../helpers/localStorage';
import {
  clearBrowser,
  formatDate,
  getCurrentTime,
  getDomainName,
  is24HoursPassed,
} from '../helpers/utils';
import { Account, Concept, Feature, UserConcepts } from '../models/GQL_API';
import {
  AdminPermissions,
  ConceptListingVariables,
  IBookingsSyncDuration,
  ListingVariables,
  UserConceptsListingVariables,
} from '../models/app';
import useAccount from './useAccount';
import useAdminGroup from './useAdminGroup';
import useApp from './useApp';
import useConcept from './useConcept';
import useFeature from './useFeature';
import usePermissions from './usePermissions';
import useUserConcepts from './useUserConcepts';

const useAuth = () => {
  const {
    showError,
    showConfirm,
    setSession,
    fetchSession,
    clearSession,
    navigateTo,
    setPermissions,
    setConcepts,
  } = useApp();
  const { getAdminPermissions } = usePermissions();
  const { userConceptsFetch } = useUserConcepts('userConcepts', 'userConcept');

  const { accountsFetchByDomainOnline } = useAccount('accounts', 'account');
  const { adminGroupsFetchCurrentUser } = useAdminGroup(
    'adminGroups',
    'adminGroup',
  );
  const { featuresFetchAll, featuresChangeListingAll } = useFeature(
    'features',
    'feature',
  );
  const { conceptsChangeSelected, conceptsChangeListing, conceptsFetch } =
    useConcept('concepts', 'concepts');

  async function register(data: any) {
    try {
      const accountsSelected: Account =
        await accountsFetchByDomainOnline(false);
      await signUp({
        username: `${getDomainName(accountsSelected)}_${data.email}`,
        password: data.password,
        options: {
          userAttributes: {
            name: data.name,
            email: `${getDomainName(accountsSelected)}_${data.email}`,
          },
        },
      });

      navigateTo('/');
    } catch (err: Error | any) {
      showError(
        typeof err.message === 'string' ? err.message : 'Error Occurred',
      );
      // console.log("error signing up:", err);
    }
  }

  async function login(data: any) {
    const filteredConcepts: Concept[] = [];

    const params: ListingVariables = {
      searchText: '',
      startIndex: 0,
      limit: 1000,
    };

    try {
      const accountsSelected: Account =
        await accountsFetchByDomainOnline(false);
      await signIn({
        username: `${getDomainName(accountsSelected)}_${data.email}`,
        password: data.password,
      });

      let idToken = await fetchSession();
      // console.log({idToken})
      const lastLoginTimestamp = Date.now();
      localStorage.setItem('lastLogin', JSON.stringify(lastLoginTimestamp));
      localStorage.setItem('sessionStored', JSON.stringify(idToken));
      setSession(idToken);

      const userConceptParams: UserConceptsListingVariables = {
        ...params,
        userID: idToken?.sub,
      };

      const conceptsParams: ConceptListingVariables = {
        ...params,
        accountID: accountsSelected.id,
      };

      const resultData: any[] = await Promise.all([
        userConceptsFetch(userConceptParams),
        conceptsFetch(conceptsParams),
        adminGroupsFetchCurrentUser(idToken?.sub),
        featuresFetchAll(params),
      ]);

      const userConcepts: UserConcepts = resultData[0][0];
      const allConcepts: Concept[] = resultData[1];
      const userGroup = resultData[2];
      const featuresListingAll: Feature[] = resultData[3];

      featuresChangeListingAll(featuresListingAll);

      const permissions: AdminPermissions = await getAdminPermissions({
        userGroup,
      });

      setPermissions(permissions);

      if (userConcepts) {
        for (let concept of allConcepts) {
          if (userConcepts.concepts!.includes(concept.id)) {
            filteredConcepts.push(concept);
          }
        }

        setConcepts(userConcepts);
      }

      conceptsChangeListing(allConcepts);

      const storageConcept: string | null = localStorage.getItem(
        LOCAL_STORAGE.SELECTED_CONCEPT,
      );

      if (storageConcept) {
        conceptsChangeSelected(storageConcept);
      } else {
        if (filteredConcepts.length > 0) {
          for (var j = 0; j < filteredConcepts.length; j++) {
            if (
              userConcepts &&
              userConcepts.concepts &&
              userConcepts.concepts.includes(filteredConcepts[j].id)
            ) {
              conceptsChangeSelected(filteredConcepts[j].id);
              break;
            }
          }
        }
      }

      const bookingsSyncDuration: IBookingsSyncDuration =
        getBookingDateRange(userConcepts);
      const { fromDate, toDate } = bookingsSyncDuration;
      setBookingDateRange(fromDate, toDate);

      localStorage.setItem(LOCAL_STORAGE.BOOKING_REFRESH, 'false');
      localStorage.setItem(LOCAL_STORAGE.BOOKING_GUEST, 'false');
      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE_RANGE, 'false');
      localStorage.setItem(LOCAL_STORAGE.GUESTS_SEARCH, 'false');
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUEST_STATS, 'false');
      localStorage.setItem(LOCAL_STORAGE.SYNC_BOOKINGS, 'true');
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUESTS, 'true');
      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE, formatDate(new Date()));

      setPermissions(permissions);
    } catch (err: Error | any) {
      console.log(err);
      showError(
        typeof err.message === 'string' ? err.message : 'Error Occurred',
      );
    }
  }

  async function loginSync() {
    const filteredConcepts: Concept[] = [];

    const params: ListingVariables = {
      searchText: '',
      startIndex: 0,
      limit: 1000,
    };

    try {
      // console.log('use auth sync');

      const result = await Promise.all([
        accountsFetchByDomainOnline(true),
        featuresFetchAll(params),
      ]);

      const accountsSelected: Account = result[0];
      const featuresListingAll: Feature[] = result[1];

      const user: any = await fetchSession();
      // console.log({user: user})
      featuresChangeListingAll(featuresListingAll);

      const userConceptParams: UserConceptsListingVariables = {
        ...params,
        userID: user.sub,
      };

      const conceptsParams: ConceptListingVariables = {
        ...params,
        accountID: accountsSelected.id,
      };

      const resultData: any[] = await Promise.all([
        userConceptsFetch(userConceptParams),
        conceptsFetch(conceptsParams),
        adminGroupsFetchCurrentUser(user.sub),
      ]);

      const userConcepts: UserConcepts = resultData[0][0];
      const allConcepts: Concept[] = resultData[1];
      const userGroup = resultData[2];

      // console.log({ allConcepts });

      const permissions: AdminPermissions = await getAdminPermissions({
        userGroup,
      });

      setPermissions(permissions);

      if (userConcepts) {
        for (let concept of allConcepts) {
          if (userConcepts.concepts!.includes(concept.id)) {
            filteredConcepts.push(concept);
          }
        }

        setConcepts(userConcepts);
      }

      conceptsChangeListing(allConcepts);

      const storageConcept: string | null = localStorage.getItem(
        LOCAL_STORAGE.SELECTED_CONCEPT,
      );

      if (storageConcept) {
        conceptsChangeSelected(storageConcept);
      } else {
        if (filteredConcepts.length > 0) {
          for (var j = 0; j < filteredConcepts.length; j++) {
            if (
              userConcepts &&
              userConcepts.concepts &&
              userConcepts.concepts.includes(filteredConcepts[j].id)
            ) {
              conceptsChangeSelected(filteredConcepts[j].id);
              break;
            }
          }
        }
      }

      const bookingsSyncDuration: IBookingsSyncDuration =
        getBookingDateRange(userConcepts);
      const { fromDate, toDate } = bookingsSyncDuration;
      setBookingDateRange(fromDate, toDate);

      localStorage.setItem(LOCAL_STORAGE.BOOKING_REFRESH, 'false');
      localStorage.setItem(LOCAL_STORAGE.BOOKING_GUEST, 'false');
      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE_RANGE, 'false');
      localStorage.setItem(LOCAL_STORAGE.GUESTS_SEARCH, 'false');
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUEST_STATS, 'false');
      localStorage.setItem(LOCAL_STORAGE.SYNC_BOOKINGS, 'true');
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUESTS, 'true');
      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE, formatDate(new Date()));

      setPermissions(permissions);
      setSession(user);
    } catch (err: Error | any) {
      showError(
        typeof err.message === 'string' ? err.message : 'Error Occurred',
      );
    }
  }
  async function logout() {
    try {
      localStorage.clear();

      await signOut();

      clearSession();
    } catch (err: Error | any) {
      showError(
        typeof err.message === 'string' ? err.message : 'Error Occurred',
      );
      // console.log("error signing out: ", err);
    }
  }

  // async function forgetPassword(data: any) {
  //   try {
  //     const accountsSelected: Account = await accountsFetchByDomainOnline(
  //       false
  //     );
  //     // Send confirmation code to user's email
  //     await Auth.forgotPassword(
  //       `${getDomainName(accountsSelected)}_${data.email}`
  //     );
  //     showConfirm("Verification code has been sent to your email.");
  //     navigateTo(`/${Pages.RESET_PASSWORD}`);
  //   } catch (err: Error | any) {
  //     showError(
  //       typeof err.message === "string" ? err.message : "Error Occurred"
  //     );
  //     // console.log("error sending code to email: ", err);
  //   }
  // }

  async function forgetPassword(data: any) {
    try {
      const accountsSelected: Account =
        await accountsFetchByDomainOnline(false);
      // Send confirmation code to user's email
      const output = await resetPassword({
        username: `${getDomainName(accountsSelected)}_${data.email}`,
      });
      forgetPasswordSubmit(output);
    } catch (error) {
      console.log(error);
    }
  }

  function forgetPasswordSubmit(output: ResetPasswordOutput) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        // console.log(
        //   `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
        // );
        // Collect the confirmation code from the user and pass to confirmResetPassword.
        break;
      case 'DONE':
        // console.log("Successfully reset password.");
        break;
    }
  }

  // async function forgetPasswordSubmit(data: any) {
  //   try {
  //     const accountsSelected: Account = await accountsFetchByDomainOnline(
  //       false
  //     );
  //     await Auth.forgotPasswordSubmit(
  //       `${getDomainName(accountsSelected)}_${data.email}`,
  //       data.code,
  //       data.newPassword
  //     );
  //   } catch (err: Error | any) {
  //     showError(
  //       typeof err.message === "string" ? err.message : "Error Occurred"
  //     );
  //     // console.log(err);
  //   }
  // }
  async function checkDeploymentVersion() {
    try {
      // Get current version from local storage
      const currentVersion: string | null = localStorage.getItem(
        LOCAL_STORAGE.VERSION,
      );

      // Compare it with constant version
      console.log({ currentVersion, DEPLOYMENT_VERSION });
      const currentTime = getCurrentTime();
      if (currentVersion === null || currentVersion < DEPLOYMENT_VERSION) {
        // Get value stored in session
        await logout();
        await refreshCacheAndReload();
        await clearBrowser(true);

        localStorage.setItem(LOCAL_STORAGE.VERSION, DEPLOYMENT_VERSION);
        localStorage.setItem(LOCAL_STORAGE.LAST_VISIT, String(currentTime));

        return true;
      }

      return false;
    } catch (err: Error | any) {
      showError(
        typeof err.message === 'string' ? err.message : 'Error Occurred',
      );

      return false;
    }
  }
  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
  };

  async function checkLastLoginTime() {
    try {
      const storedTime: string | null = localStorage.getItem(
        LOCAL_STORAGE.LAST_VISIT,
      );

      // console.log({ storedTime });

      // Check if 24 hours have passed since the last visit
      const currentTime = getCurrentTime();
      if (storedTime === null || is24HoursPassed(Number(storedTime))) {
        // Do something if 24 hours have passed
        console.log('24 hours have passed since the last visit.');

        await logout();
        await refreshCacheAndReload();
        await clearBrowser(true);

        // Store the current time in localStorage
        localStorage.setItem(LOCAL_STORAGE.VERSION, DEPLOYMENT_VERSION);
        localStorage.setItem(LOCAL_STORAGE.LAST_VISIT, String(currentTime));

        console.log('Update last visit');
      }
    } catch (err: Error | any) {
      showError(
        typeof err.message === 'string' ? err.message : 'Error Occurred',
      );
    }
  }
  return {
    loginSync,
    login,
    register,
    logout,
    forgetPassword,
    forgetPasswordSubmit,
    checkLastLoginTime,
    checkDeploymentVersion,
  };
};

export default useAuth;
