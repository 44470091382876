import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';

import ButtonLoader from '../ButtonLoader/ButtonLoader';
import useApp from '../../../hooks/useApp';
import { Pages } from '../../../constants/enums';
import useListings from '../../../hooks/useListings';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface Props {
  resourceId: string;
  slug: string;
}

const initialState: { toDelete: string; isDeleting: boolean } = {
  toDelete: '',
  isDeleting: false,
};

const DeleteResource: React.FC<Props> = ({ resourceId, slug }) => {
  const [state, setState] = useState(initialState);
  // const statusesListing = useSelector((state: any) => state.statuses.listing);
  // const groupsListing = useSelector((state: any) => state.groups.listing);
  // const guestsListing = useSelector((state: any) => state.guests.listing);
  const accountsListing = useSelector((state: any) => state.accounts.listing);
  // const areasListing = useSelector((state: any) => state.areas.listing);
  const conceptsListing = useSelector((state: any) => state.concepts.listing);
  const featuresListing = useSelector((state: any) => state.features.listing);
  const flagsListing = useSelector((state: any) => state.flags.listing);
  const eventsListing = useSelector((state: any) => state.events.listing);
  // const groupSettingsListing = useSelector(
  //   (state: any) => state.groupSettings.listing
  // );
  const languagesListing = useSelector((state: any) => state.languages.listing);
  // const interestsListing = useSelector((state: any) => state.interests.listing);
  // const planItemsListing = useSelector((state: any) => state.planItems.listing);
  // const socialLinksListing = useSelector(
  //   (state: any) => state.socialLinks.listing
  // );
  // const tablesListing = useSelector((state: any) => state.tables.listing);
  // const timeSlotsListing = useSelector((state: any) => state.timeSlots.listing);
  // const commentsListing = useSelector((state: any) => state.comments.listing);
  // const customerStatsListing = useSelector(
  //   (state: any) => state.customerStats.listing
  // );
  // const objectsListing = useSelector((state: any) => state.objects.listing);
  // const servingAreasListing = useSelector(
  //   (state: any) => state.servingAreas.listing
  // );
  // const timelinesListing = useSelector((state: any) => state.timelines.listing);
  const bookingsListing = useSelector((state: any) => state.bookings.listing);
  // const notificationsListing = useSelector(
  //   (state: any) => state.notifications.listing
  // );
  const adminRolesListing = useSelector(
    (state: any) => state.adminRoles.listing,
  );
  const adminGroupsListing = useSelector(
    (state: any) => state.adminGroups.listing,
  );
  const guestsListing = useSelector((state: any) => state.guests.listing);
  const guestGroupsListing = useSelector(
    (state: any) => state.guestGroups.listing,
  );
  const rejectionReasonsListing = useSelector(
    (state: any) => state.rejectionReasons.listing,
  );
  const invitationHistoryListing = useSelector(
    (state: any) => state.invitationHistory.listing,
  );
  const { hideDeletePopup } = useApp();
  const { trashResource } = useListings(slug);

  const renderTitle = () => {
    switch (slug) {
      case Pages.MEDIA_LIBRARY:
        return 'permanently media file';
      default:
        return slug.slice(0, -1);
    }
  };

  const renderListing = () => {
    switch (slug) {
      case Pages.ACCOUNTS:
        return accountsListing;
      case Pages.CONCEPTS:
        return conceptsListing;
      case Pages.FEATURES:
        return featuresListing;
      case Pages.FLAGS:
        return flagsListing;
      case Pages.Event:
        return eventsListing;
      case Pages.LANGUAGES:
        return languagesListing;
      case Pages.BOOKINGS:
        return bookingsListing;
      case Pages.ADMIN_ROLES:
        return adminRolesListing;
      case Pages.ADMIN_GROUPS:
        return adminGroupsListing;
      case Pages.GUEST:
        return guestsListing;
      case Pages.GUEST_GROUP:
        return guestGroupsListing;
      case Pages.REJECTION_REASONS:
        return rejectionReasonsListing;
      case Pages.INVITATIONS_HISTORY:
        return invitationHistoryListing;
      default:
        return [];
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, toDelete: event.target.value });
  };
  // const navigate = useNavigate();
  const handleDelete = async () => {
    setState({ ...state, isDeleting: true });

    await trashResource({ id: resourceId, listing: renderListing() });

    setState({ ...state, isDeleting: false });
    hideDeletePopup();

    // navigate(`/dashboard/${slug}`);
    // window.location.reload();
  };

  return (
    <Box
      sx={{
        width: '90%',
        maxWidth: 600,
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <Box
        sx={{
          background: (theme) => theme.palette.grey[300],
          padding: '8px 16px',
          color: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">{`Your about to delete ${renderTitle()}`}</Typography>
        <CloseIcon
          aria-label="close modal"
          onClick={hideDeletePopup}
          sx={{ cursor: 'pointer' }}
        />
      </Box>
      <Paper
        square
        elevation={0}
        sx={{
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Typography component={'span'}>
              In order to proceed with deletion type
            </Typography>
            <Typography component={'span'}>
              {' '}
              <strong>delete</strong>{' '}
            </Typography>
            <Typography component={'span'}>in field below</Typography>
          </Box>
          <TextField
            placeholder={'delete'}
            fullWidth
            size="small"
            value={state.toDelete}
            onChange={handleChange}
          />
        </Box>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Button
            disableElevation
            sx={{ mr: 2 }}
            variant="contained"
            size="small"
            onClick={hideDeletePopup}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="error"
            size="small"
            onClick={handleDelete}
            disabled={state.toDelete !== 'delete' || state.isDeleting}
            endIcon={state.isDeleting && <ButtonLoader size={20} />}
          >
            Continue
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default DeleteResource;
