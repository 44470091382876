import { getEvent, listEvents } from '../graphql/queries';
import { onCreateEvent } from '../graphql/subscriptions';
import { updateEvent, createEvent, deleteEvent } from '../graphql/mutations';
import { generateClient } from '@aws-amplify/api';
import {
  EventBulkTrashVariables,
  EventGetVariables,
  EventListingVariables,
  EventUpdateVariables,
  CreateVariables,
} from './../models/app';
import { useDispatch, useSelector } from 'react-redux';
import { setListing, setNextToken, setSelected } from '../store/ducks/event';
import { HeadCell } from '../models/dataTable';
import useApp from './useApp';
import {
  CreateEventInput,
  UpdateEventInput,
  Event,
  ModelEventFilterInput,
} from '../models/GQL_API';

const client = generateClient();

const useEvent = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]['nextToken'],
  );
  const eventListing = useSelector(
    (state: any) => state[`${listingName}`]['listing'],
  );

  async function fetch(params: EventListingVariables) {
    const { searchText, limit, generalFilters } = params;
    try {
      const filter: ModelEventFilterInput = {
        deleted: { eq: '0' },
      };
      filter.and = [];

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      if (generalFilters?.fromDate && generalFilters?.toDate) {
        const fromDate = new Date(generalFilters.fromDate);
        fromDate.setHours(0, 0, 0, 500);
        const toDate = new Date(generalFilters.toDate);
        toDate.setHours(23, 59, 59, 999);
        filter.and.push({
          startDate: {
            ge: fromDate.toISOString(),
          },
        });
        filter.and.push({
          startDate: {
            lt: toDate.toISOString(),
          },
        });
      }

      if (filter.and && filter.and.length === 0) delete filter.and;

      const eventList = await client.graphql({
        query: listEvents,
        variables: { filter, nextToken, limit: 100000 },
        authMode: 'userPool',
      });

      const currentNextToken = eventList.data.listEvents.nextToken;
      const responseListing = eventList.data.listEvents.items;

      let listing = [...eventListing, ...responseListing];
      console.log({
        currentNextToken,
        responseListing,
      });
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function fetchAll(params: EventListingVariables) {
    const { searchText, limit, generalFilters } = params;
    let nextToken: string | null = null;
    let listing: Event[] = [];

    try {
      const filter: ModelEventFilterInput = {
        deleted: { eq: '0' },
      };
      filter.and = [];

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }

      if (generalFilters?.fromDate && generalFilters?.toDate) {
        const fromDate = new Date(generalFilters.fromDate);
        fromDate.setHours(0, 0, 0, 500);
        const toDate = new Date(generalFilters.toDate);
        toDate.setHours(23, 59, 59, 999);
        filter.and.push({
          startDate: {
            ge: fromDate.toISOString(),
          },
        });
        filter.and.push({
          startDate: {
            lt: toDate.toISOString(),
          },
        });
      }

      if (filter.and && filter.and.length === 0) delete filter.and;

      do {
        const eventList = await client.graphql({
          query: listEvents,
          variables: { filter, nextToken, limit: 100000 },
          authMode: 'userPool',
        });

        const currentNextToken = eventList.data.listEvents.nextToken;
        const responseListing = eventList.data.listEvents.items;

        listing = [...listing, ...responseListing];

        nextToken = currentNextToken;
      } while (nextToken);

      // dispatch(setListing(listing));
      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function fetchUpComingEvent() {
    try {
      const limit = 10000;
      const filter: ModelEventFilterInput = {
        deleted: { eq: '0' },
        published: {
          eq: true,
        },
        endDate: {
          gt: new Date().toISOString(),
        },
      };

      const eventList = await client.graphql({
        query: listEvents,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });
      const listing = eventList.data.listEvents.items;
      return listing[0];
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function get(params: EventGetVariables) {
    const { id, listing } = params;
    try {
      let single: Event | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await client.graphql<Event>({
          query: getEvent,
          variables: { id },
          authMode: 'userPool',
        });
        single = listing.data.getEvent;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateEventInput = {
        name: data.name ? data.name.toLowerCase() : '',
        description: data.description ? data.description : '',
        disclaimer: data.disclaimer ? data.disclaimer : '',
        startDate: data.startDate ? new Date(data.startDate).toISOString() : '',
        endDate: data.endDate ? new Date(data.endDate).toISOString() : '',
        location: data.location ? data.location : {},
        eventComments: data.eventComments ? data.eventComments : [],
        todoList: data.todoList ? data.todoList : [],
        map: data.map ? data.map : '',
        image: data.image ? data.image : '',
        largeScreensImage: data.largeScreensImage ? data.largeScreensImage : '',
        mediumScreensImage: data.mediumScreensImage
          ? data.mediumScreensImage
          : '',
        smallScreensImage: data.smallScreensImage ? data.smallScreensImage : '',
        sendEmailToValidGuest: data.sendEmailToValidGuest ?? true,
        sendSmsToValidGuest: data.sendSmsToValidGuest ?? false,
        applyGenderRatio: data.applyGenderRatio ?? false,
        visibleTo: data.visibleTo ? data.visibleTo : '',
        gallery: data.gallery ? data.gallery : [],
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
        published: data.published ?? false,
      };

      const res = await client.graphql({
        query: createEvent,
        variables: { input: createInput },
        authMode: 'userPool',
      });
      // showConfirm(`New ${singleName} has been created successfully`);
      return res.data.createEvent;
    } catch (err) {
      // this is special case for events and event tickets only
      // showError(err);
      throw err;
    }
  }

  async function update(params: EventUpdateVariables) {
    const { id, listing, data } = params;
    try {
      const original: any = await get({ id, listing });
      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }
      // delete __typename field
      if (original?.location) delete original.location.__typename;
      if (original?.location?.coordinates)
        delete original.location.coordinates.__typename;
      original?.todoList?.forEach((todo: any) => delete todo.__typename);
      original?.eventComments?.forEach(
        (comment: any) => delete comment.__typename,
      );
      // delete __typename field
      if (data?.location) delete data.location.__typename;
      if (data?.location?.coordinates)
        delete data.location.coordinates.__typename;
      data?.todoList?.forEach((todo: any) => delete todo.__typename);
      data?.eventComments?.forEach((comment: any) => delete comment.__typename);

      const updateInput: UpdateEventInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase() : original!.name,
        description: data.description
          ? data.description
          : original!.description,
        disclaimer: data.disclaimer ? data.disclaimer : original!.disclaimer,
        startDate: data.startDate
          ? new Date(data.startDate).toISOString()
          : original!.startDate,
        endDate: data.endDate
          ? new Date(data.endDate).toISOString()
          : original!.endDate,
        location: data.location ? data.location : original!.location,
        eventComments: data.eventComments
          ? data.eventComments
          : original!.eventComments,
        todoList: data.todoList ? data.todoList : original!.todoList,
        map: data.map ? data.map : original!.map,
        image: data.image ? data.image : original!.image,
        largeScreensImage: data.largeScreensImage
          ? data.largeScreensImage
          : original!.largeScreensImage,
        mediumScreensImage: data.mediumScreensImage
          ? data.mediumScreensImage
          : original!.mediumScreensImage,
        smallScreensImage: data.smallScreensImage
          ? data.smallScreensImage
          : original!.smallScreensImage,
        sendEmailToValidGuest:
          data.sendEmailToValidGuest !== null &&
          data.sendEmailToValidGuest !== undefined
            ? data.sendEmailToValidGuest
            : original.sendEmailToValidGuest,
        sendSmsToValidGuest:
          data.sendSmsToValidGuest !== null &&
          data.sendSmsToValidGuest !== undefined
            ? data.sendSmsToValidGuest
            : original.sendSmsToValidGuest,

            applyGenderRatio:
            data.applyGenderRatio !== null &&
            data.applyGenderRatio !== undefined
              ? data.applyGenderRatio
              : original.applyGenderRatio,

            
        visibleTo: data.visibleTo ? data.visibleTo : original!.visibleTo,
        gallery: data.gallery ? data.gallery : original!.gallery,
        published: data.published ?? original!.published,
        _version: original._version,
      };
      const updatedEvent: any = await client.graphql<Event>({
        query: updateEvent,
        variables: { input: updateInput },
        authMode: 'userPool',
      });
      return updatedEvent.data.updateEvent;
      // showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      // this is special case for events and event tickets only
      // showError(err);
      throw err;
    }
  }

  async function trash(params: EventGetVariables) {
    try {
      const { id, listing } = params;

      const original = await get(params);

      // if (original?.published) {
      //   throw new Error("Event can't be deleted as it was already published");
      // }

      if (original) {
        const updateInput: UpdateEventInput = {
          id: original.id,
          deleted: '1',
          _version: original._version,
        };

        await client.graphql<Event>({
          query: updateEvent,
          variables: { input: updateInput },
          authMode: 'userPool',
        });
      }
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: EventBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: EventGetVariables) {
    const { id, listing } = params;
    try {
      await client.graphql<Event>({
        query: deleteEvent,
        variables: { id: id },
        authMode: 'userPool',
      });
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'startDate',
      numeric: false,
      disablePadding: false,
      label: 'Start Date',
    },
    {
      id: 'endDate',
      numeric: false,
      disablePadding: false,
      label: 'End Date',
    },
    {
      id: 'published',
      numeric: false,
      disablePadding: false,
      label: 'Published',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  const dataCells: readonly string[] = [
    'name',
    'startDate',
    'endDate',
    'published',
  ];

  const api: any = {};
  api[`${listingName}CreateSubscription`] = onCreateEvent;
  api[`${listingName}Options`] = [];
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchUpComingEvent`] = fetchUpComingEvent;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Event[]) => {
    console.log({ eventsListing: listing });
    dispatch(setListing(listing));
  };
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = eventListing;
  return api;
};
export default useEvent;
