import { fetchAuthSession } from 'aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserConcepts } from '../models/GQL_API';
import { AdminPermissions, GeneralFilters, ModalProps } from '../models/app';
import {
  clearSession,
  openSnackBar,
  setSession,
  openModal,
  closeModal,
  openDialog,
  closeDialog,
  setSelectedUser,
  setDateRange,
  setPaginateState,
  setUserPermissions,
  setUserConcepts,
  setDatePickerModal,
  setBookingModal,
  setGuestStatsModal,
  setEditBookingModal,
  openSecondaryModal,
  closeSecondaryModal,
} from '../store/ducks/app';
import {
  clearGeneralFilters,
  setGeneralFilters,
} from '../store/ducks/generalFilters';
import { AppTypes, appType } from '../constants/enums';
import { sessionTimeout } from '../helpers/utils';

const useApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchSession = async () => {
    const storedSession = localStorage.getItem('session');
    // @ts-ignore
    if (storedSession && !sessionTimeout() && appType === AppTypes.CASHLESS) {
      return JSON.parse(storedSession);
    } else {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      return idToken?.payload;
    }
  };

  function hasPermission(
    permissions: AdminPermissions,
    permissionName: string,
  ): boolean {
    if (permissionName === 'callCenter' && permissions.callCenter) return true;
    else if (permissionName === 'settings' && permissions.settings) return true;

    return false;
  }

  function showError(error: any): string {
    console.log(error);
    if (error?.message) {
      return error.message;
    } else if (error.errors && error.errors.length > 0) {
      return error.errors[0].message;
    } else {
      return error;
    }
  }

  return {
    hasPermission,
    setPaginateState: (paginateState: any) =>
      dispatch(setPaginateState(paginateState)),
    fetchSession,
    setPermissions: (permissions: AdminPermissions) =>
      dispatch(setUserPermissions(permissions)),
    setConcepts: (payload: UserConcepts | null) =>
      dispatch(setUserConcepts(payload)),
    setSession: (session: any) => dispatch(setSession(session)),
    clearSession: () => {
      dispatch(clearSession());
    },
    showConfirm: (message: string) =>
      dispatch(openSnackBar(message, 'success')),
    showError: (error: any) => {
      dispatch(openSnackBar(showError(error), 'error'));
    },
    showWarning: (message: any) => {
      dispatch(openSnackBar(message, 'warning'));
    },
    showInfo: (message: any) => {
      dispatch(openSnackBar(message, 'info'));
    },
    openModal: (modalContent: any, modalContentId?: any) =>
      dispatch(openModal(modalContent, modalContentId)),
    showDeletePopup: (id: string, slug: string, resourceId: string) =>
      dispatch(openModal({ slug, resourceId }, id)),
    hideDeletePopup: () => dispatch(closeModal()),
    modalClose: () => dispatch(closeModal()),
    navigateTo: (url: string) => navigate(url),
    navigateBack: () => navigate(-1),
    openDialog: () => dispatch(openDialog()),
    closeDialog: () => dispatch(closeDialog()),
    setSelectedUser: (user: any) => dispatch(setSelectedUser(user)),
    changeDateRange: (dateRange: any) => dispatch(setDateRange(dateRange)),
    changeDatePickerModal: (payload: ModalProps) =>
      dispatch(setDatePickerModal(payload)),
    changeBookingModal: (payload: ModalProps) =>
      dispatch(setBookingModal(payload)),
    changeEditBookingModal: (payload: ModalProps) =>
      dispatch(setEditBookingModal(payload)),
    changeGuestStatsModal: (payload: ModalProps) =>
      dispatch(setGuestStatsModal(payload)),
    showSecondaryPopup: (id: string, slug: string, resourceId: string) =>
      dispatch(openSecondaryModal({ slug, resourceId }, id)),
    hideSecondaryPopup: () => dispatch(closeSecondaryModal()),
    setGeneralFilters: (name: keyof GeneralFilters, value: any) =>
      dispatch(setGeneralFilters(name, value)),
    clearGeneralFilters: () => dispatch(clearGeneralFilters()),
  };
};

export default useApp;
