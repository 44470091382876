/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/GQL_API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getAccount = /* GraphQL */ `query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    currency
    merchantVersion
    merchantID
    merchantIframe
    merchantIntegrationNumber
    merchantAPIKey
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    accountLogoId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      logo {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      currency
      merchantVersion
      merchantID
      merchantIframe
      merchantIntegrationNumber
      merchantAPIKey
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      accountLogoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const syncAccounts = /* GraphQL */ `query SyncAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAccounts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      logo {
        id
        mediaID
        fileUrl
        filename
        filetype
        fileSize
        alternativeText
        caption
        description
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      currency
      merchantVersion
      merchantID
      merchantIframe
      merchantIntegrationNumber
      merchantAPIKey
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      accountLogoId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAccountsQueryVariables,
  APITypes.SyncAccountsQuery
>;
export const getConcept = /* GraphQL */ `query GetConcept($id: ID!) {
  getConcept(id: $id) {
    id
    accountID
    name
    description
    logo
    type
    location
    precedence
    longitude
    latitude
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConceptQueryVariables,
  APITypes.GetConceptQuery
>;
export const listConcepts = /* GraphQL */ `query ListConcepts(
  $filter: ModelConceptFilterInput
  $limit: Int
  $nextToken: String
) {
  listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      name
      description
      logo
      type
      location
      precedence
      longitude
      latitude
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConceptsQueryVariables,
  APITypes.ListConceptsQuery
>;
export const syncConcepts = /* GraphQL */ `query SyncConcepts(
  $filter: ModelConceptFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncConcepts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      name
      description
      logo
      type
      location
      precedence
      longitude
      latitude
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncConceptsQueryVariables,
  APITypes.SyncConceptsQuery
>;
export const getLanguage = /* GraphQL */ `query GetLanguage($id: ID!) {
  getLanguage(id: $id) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLanguageQueryVariables,
  APITypes.GetLanguageQuery
>;
export const listLanguages = /* GraphQL */ `query ListLanguages(
  $filter: ModelLanguageFilterInput
  $limit: Int
  $nextToken: String
) {
  listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLanguagesQueryVariables,
  APITypes.ListLanguagesQuery
>;
export const syncLanguages = /* GraphQL */ `query SyncLanguages(
  $filter: ModelLanguageFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLanguages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      code
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLanguagesQueryVariables,
  APITypes.SyncLanguagesQuery
>;
export const getFeature = /* GraphQL */ `query GetFeature($id: ID!) {
  getFeature(id: $id) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeatureQueryVariables,
  APITypes.GetFeatureQuery
>;
export const listFeatures = /* GraphQL */ `query ListFeatures(
  $filter: ModelFeatureFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeaturesQueryVariables,
  APITypes.ListFeaturesQuery
>;
export const syncFeatures = /* GraphQL */ `query SyncFeatures(
  $filter: ModelFeatureFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFeatures(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFeaturesQueryVariables,
  APITypes.SyncFeaturesQuery
>;
export const getAdminRole = /* GraphQL */ `query GetAdminRole($id: ID!) {
  getAdminRole(id: $id) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminRoleQueryVariables,
  APITypes.GetAdminRoleQuery
>;
export const listAdminRoles = /* GraphQL */ `query ListAdminRoles(
  $filter: ModelAdminRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminRolesQueryVariables,
  APITypes.ListAdminRolesQuery
>;
export const syncAdminRoles = /* GraphQL */ `query SyncAdminRoles(
  $filter: ModelAdminRoleFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdminRoles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminRolesQueryVariables,
  APITypes.SyncAdminRolesQuery
>;
export const getAdminGroup = /* GraphQL */ `query GetAdminGroup($id: ID!) {
  getAdminGroup(id: $id) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminGroupQueryVariables,
  APITypes.GetAdminGroupQuery
>;
export const listAdminGroups = /* GraphQL */ `query ListAdminGroups(
  $filter: ModelAdminGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminGroupsQueryVariables,
  APITypes.ListAdminGroupsQuery
>;
export const syncAdminGroups = /* GraphQL */ `query SyncAdminGroups(
  $filter: ModelAdminGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdminGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminGroupsQueryVariables,
  APITypes.SyncAdminGroupsQuery
>;
export const adminGroupsByDeleted = /* GraphQL */ `query AdminGroupsByDeleted(
  $deleted: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAdminGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  adminGroupsByDeleted(
    deleted: $deleted
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGroupsByDeletedQueryVariables,
  APITypes.AdminGroupsByDeletedQuery
>;
export const getAttachment = /* GraphQL */ `query GetAttachment($id: ID!) {
  getAttachment(id: $id) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAttachmentQueryVariables,
  APITypes.GetAttachmentQuery
>;
export const listAttachments = /* GraphQL */ `query ListAttachments(
  $filter: ModelAttachmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAttachmentsQueryVariables,
  APITypes.ListAttachmentsQuery
>;
export const syncAttachments = /* GraphQL */ `query SyncAttachments(
  $filter: ModelAttachmentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAttachments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAttachmentsQueryVariables,
  APITypes.SyncAttachmentsQuery
>;
export const getUserConcepts = /* GraphQL */ `query GetUserConcepts($id: ID!) {
  getUserConcepts(id: $id) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserConceptsQueryVariables,
  APITypes.GetUserConceptsQuery
>;
export const listUserConcepts = /* GraphQL */ `query ListUserConcepts(
  $filter: ModelUserConceptsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      defaultConcept
      concepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserConceptsQueryVariables,
  APITypes.ListUserConceptsQuery
>;
export const syncUserConcepts = /* GraphQL */ `query SyncUserConcepts(
  $filter: ModelUserConceptsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserConcepts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      defaultConcept
      concepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserConceptsQueryVariables,
  APITypes.SyncUserConceptsQuery
>;
export const getGuest = /* GraphQL */ `query GetGuest($id: ID!) {
  getGuest(id: $id) {
    id
    name
    isNameEdited
    username
    email
    phone_number
    guestGroupID
    guestGroupName
    sub
    facebookSub
    guest_avatar
    avg_spend
    avg_ticket_type
    numberOfTickets
    connections
    last_attended_event
    last_attended_event_Name
    gender
    isGenderEdited
    group
    faceBookID
    appPassword
    birthdate
    isBirthdateEdited
    isVerified
    images
    address
    totalEvents
    flags
    frontPersonalID
    isFrontPersonalIDEdited
    passportImage
    isPassportImageEdited
    backPersonalID
    isBackPersonalIDEdited
    faceBookProfileLink
    instagramProfileLink
    nationality
    balance
    orderedItems {
      id
      name
      quantity
      price
      __typename
    }
    isBlocked
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGuestQueryVariables, APITypes.GetGuestQuery>;
export const listGuests = /* GraphQL */ `query ListGuests(
  $filter: ModelGuestFilterInput
  $limit: Int
  $nextToken: String
) {
  listGuests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGuestsQueryVariables,
  APITypes.ListGuestsQuery
>;
export const syncGuests = /* GraphQL */ `query SyncGuests(
  $filter: ModelGuestFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncGuests(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGuestsQueryVariables,
  APITypes.SyncGuestsQuery
>;
export const ByEmail = /* GraphQL */ `query ByEmail(
  $email: String!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGuestFilterInput
  $limit: Int
  $nextToken: String
) {
  ByEmail(
    email: $email
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ByEmailQueryVariables, APITypes.ByEmailQuery>;
export const ByPhoneNumber = /* GraphQL */ `query ByPhoneNumber(
  $phone_number: String!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGuestFilterInput
  $limit: Int
  $nextToken: String
) {
  ByPhoneNumber(
    phone_number: $phone_number
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ByPhoneNumberQueryVariables,
  APITypes.ByPhoneNumberQuery
>;
export const usersByFacebookSub = /* GraphQL */ `query UsersByFacebookSub(
  $facebookSub: String!
  $sortDirection: ModelSortDirection
  $filter: ModelGuestFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByFacebookSub(
    facebookSub: $facebookSub
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByFacebookSubQueryVariables,
  APITypes.UsersByFacebookSubQuery
>;
export const userByDeleted = /* GraphQL */ `query UserByDeleted(
  $deleted: String!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGuestFilterInput
  $limit: Int
  $nextToken: String
) {
  userByDeleted(
    deleted: $deleted
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByDeletedQueryVariables,
  APITypes.UserByDeletedQuery
>;
export const getGuestGroup = /* GraphQL */ `query GetGuestGroup($id: ID!) {
  getGuestGroup(id: $id) {
    id
    name
    description
    color
    guests
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGuestGroupQueryVariables,
  APITypes.GetGuestGroupQuery
>;
export const listGuestGroups = /* GraphQL */ `query ListGuestGroups(
  $filter: ModelGuestGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listGuestGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      color
      guests
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGuestGroupsQueryVariables,
  APITypes.ListGuestGroupsQuery
>;
export const syncGuestGroups = /* GraphQL */ `query SyncGuestGroups(
  $filter: ModelGuestGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncGuestGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      color
      guests
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGuestGroupsQueryVariables,
  APITypes.SyncGuestGroupsQuery
>;
export const getTimeline = /* GraphQL */ `query GetTimeline($id: ID!) {
  getTimeline(id: $id) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    customerId
    isScanned
    type
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTimelineQueryVariables,
  APITypes.GetTimelineQuery
>;
export const listTimelines = /* GraphQL */ `query ListTimelines(
  $filter: ModelTimelineFilterInput
  $limit: Int
  $nextToken: String
) {
  listTimelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      customerId
      isScanned
      type
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTimelinesQueryVariables,
  APITypes.ListTimelinesQuery
>;
export const syncTimelines = /* GraphQL */ `query SyncTimelines(
  $filter: ModelTimelineFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTimelines(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      customerId
      isScanned
      type
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTimelinesQueryVariables,
  APITypes.SyncTimelinesQuery
>;
export const timelineByBookingID = /* GraphQL */ `query TimelineByBookingID(
  $bookingId: String!
  $isScanned: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTimelineFilterInput
  $limit: Int
  $nextToken: String
) {
  timelineByBookingID(
    bookingId: $bookingId
    isScanned: $isScanned
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      customerId
      isScanned
      type
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TimelineByBookingIDQueryVariables,
  APITypes.TimelineByBookingIDQuery
>;
export const timelineByCustomerID = /* GraphQL */ `query TimelineByCustomerID(
  $customerId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTimelineFilterInput
  $limit: Int
  $nextToken: String
) {
  timelineByCustomerID(
    customerId: $customerId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      customerId
      isScanned
      type
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TimelineByCustomerIDQueryVariables,
  APITypes.TimelineByCustomerIDQuery
>;
export const getFlag = /* GraphQL */ `query GetFlag($id: ID!) {
  getFlag(id: $id) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFlagQueryVariables, APITypes.GetFlagQuery>;
export const listFlags = /* GraphQL */ `query ListFlags(
  $filter: ModelFlagFilterInput
  $limit: Int
  $nextToken: String
) {
  listFlags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFlagsQueryVariables, APITypes.ListFlagsQuery>;
export const syncFlags = /* GraphQL */ `query SyncFlags(
  $filter: ModelFlagFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFlags(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      name
      icon
      color
      customers
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncFlagsQueryVariables, APITypes.SyncFlagsQuery>;
export const getComment = /* GraphQL */ `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    message
    customerId
    bookingId
    replyTo
    deleted
    createdAt
    createdByID
    createdByImg
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentQueryVariables,
  APITypes.GetCommentQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      message
      customerId
      bookingId
      replyTo
      deleted
      createdAt
      createdByID
      createdByImg
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const syncComments = /* GraphQL */ `query SyncComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncComments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      message
      customerId
      bookingId
      replyTo
      deleted
      createdAt
      createdByID
      createdByImg
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCommentsQueryVariables,
  APITypes.SyncCommentsQuery
>;
export const getEvent = /* GraphQL */ `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    name
    description
    disclaimer
    startDate
    endDate
    location {
      address
      coordinates {
        lat
        lng
        __typename
      }
      __typename
    }
    todoList
    eventComments {
      image
      name
      message
      __typename
    }
    map
    image
    largeScreensImage
    mediumScreensImage
    smallScreensImage
    sendEmailToValidGuest
    sendSmsToValidGuest
    gallery
    visibleTo
    tickets {
      items {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    gates {
      items {
        id
        name
        eventId
        tickets
        admins
        checkpoints {
          id
          name
          precedence
          isSkippable
          createdAt
          createdByID
          createdByName
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invitationLimit {
      items {
        id
        adminID
        quota
        eventID
        totalQuota
        sentInvitations {
          invitationNumber
          reciver
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventInvitationLimitId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    published
    applyGenderRatio
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const listEvents = /* GraphQL */ `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const syncEvents = /* GraphQL */ `query SyncEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEvents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEventsQueryVariables,
  APITypes.SyncEventsQuery
>;
export const getEventTicket = /* GraphQL */ `query GetEventTicket($id: ID!) {
  getEventTicket(id: $id) {
    id
    type
    cashlessCredit
    description
    waves {
      id
      name
      price
      startDate
      endDate
      active
      quota
      AutomaticShift
      __typename
    }
    color
    paymentRules
    approvalRule
    showAll
    showOnHold
    onHoldDisplayText
    showSoldOut
    soldOutDisplayText
    setAvailable
    availableAtDate
    allowTransferred
    transferredAprroval
    transferredSameGender
    showUpcomingWave
    showUpcomingWaveDisplayText
    eventID
    gateID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventTicketQueryVariables,
  APITypes.GetEventTicketQuery
>;
export const listEventTickets = /* GraphQL */ `query ListEventTickets(
  $filter: ModelEventTicketFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      cashlessCredit
      description
      waves {
        id
        name
        price
        startDate
        endDate
        active
        quota
        AutomaticShift
        __typename
      }
      color
      paymentRules
      approvalRule
      showAll
      showOnHold
      onHoldDisplayText
      showSoldOut
      soldOutDisplayText
      setAvailable
      availableAtDate
      allowTransferred
      transferredAprroval
      transferredSameGender
      showUpcomingWave
      showUpcomingWaveDisplayText
      eventID
      gateID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventTicketsQueryVariables,
  APITypes.ListEventTicketsQuery
>;
export const syncEventTickets = /* GraphQL */ `query SyncEventTickets(
  $filter: ModelEventTicketFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEventTickets(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      type
      cashlessCredit
      description
      waves {
        id
        name
        price
        startDate
        endDate
        active
        quota
        AutomaticShift
        __typename
      }
      color
      paymentRules
      approvalRule
      showAll
      showOnHold
      onHoldDisplayText
      showSoldOut
      soldOutDisplayText
      setAvailable
      availableAtDate
      allowTransferred
      transferredAprroval
      transferredSameGender
      showUpcomingWave
      showUpcomingWaveDisplayText
      eventID
      gateID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEventTicketsQueryVariables,
  APITypes.SyncEventTicketsQuery
>;
export const byEventID = /* GraphQL */ `query ByEventID(
  $eventID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEventTicketFilterInput
  $limit: Int
  $nextToken: String
) {
  byEventID(
    eventID: $eventID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      cashlessCredit
      description
      waves {
        id
        name
        price
        startDate
        endDate
        active
        quota
        AutomaticShift
        __typename
      }
      color
      paymentRules
      approvalRule
      showAll
      showOnHold
      onHoldDisplayText
      showSoldOut
      soldOutDisplayText
      setAvailable
      availableAtDate
      allowTransferred
      transferredAprroval
      transferredSameGender
      showUpcomingWave
      showUpcomingWaveDisplayText
      eventID
      gateID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ByEventIDQueryVariables, APITypes.ByEventIDQuery>;
export const byGateID = /* GraphQL */ `query ByGateID(
  $gateID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEventTicketFilterInput
  $limit: Int
  $nextToken: String
) {
  byGateID(
    gateID: $gateID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      cashlessCredit
      description
      waves {
        id
        name
        price
        startDate
        endDate
        active
        quota
        AutomaticShift
        __typename
      }
      color
      paymentRules
      approvalRule
      showAll
      showOnHold
      onHoldDisplayText
      showSoldOut
      soldOutDisplayText
      setAvailable
      availableAtDate
      allowTransferred
      transferredAprroval
      transferredSameGender
      showUpcomingWave
      showUpcomingWaveDisplayText
      eventID
      gateID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ByGateIDQueryVariables, APITypes.ByGateIDQuery>;
export const getInvitationLimit = /* GraphQL */ `query GetInvitationLimit($id: ID!) {
  getInvitationLimit(id: $id) {
    id
    adminID
    quota
    eventID
    totalQuota
    sentInvitations {
      invitationNumber
      reciver
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventInvitationLimitId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvitationLimitQueryVariables,
  APITypes.GetInvitationLimitQuery
>;
export const listInvitationLimits = /* GraphQL */ `query ListInvitationLimits(
  $filter: ModelInvitationLimitFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvitationLimits(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      adminID
      quota
      eventID
      totalQuota
      sentInvitations {
        invitationNumber
        reciver
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventInvitationLimitId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvitationLimitsQueryVariables,
  APITypes.ListInvitationLimitsQuery
>;
export const syncInvitationLimits = /* GraphQL */ `query SyncInvitationLimits(
  $filter: ModelInvitationLimitFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInvitationLimits(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      adminID
      quota
      eventID
      totalQuota
      sentInvitations {
        invitationNumber
        reciver
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventInvitationLimitId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInvitationLimitsQueryVariables,
  APITypes.SyncInvitationLimitsQuery
>;
export const getBooking = /* GraphQL */ `query GetBooking($id: ID!) {
  getBooking(id: $id) {
    id
    status
    overallStatus
    bookingGuestId
    bookingMainGuestId
    mainBookingId
    bookingEventId
    bookingEventTicketId
    guest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mainGuest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventTicket {
      id
      type
      cashlessCredit
      description
      waves {
        id
        name
        price
        startDate
        endDate
        active
        quota
        AutomaticShift
        __typename
      }
      color
      paymentRules
      approvalRule
      showAll
      showOnHold
      onHoldDisplayText
      showSoldOut
      soldOutDisplayText
      setAvailable
      availableAtDate
      allowTransferred
      transferredAprroval
      transferredSameGender
      showUpcomingWave
      showUpcomingWaveDisplayText
      eventID
      gateID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cashlessCredit
    wave
    waveId
    isMainGuest
    isInvited
    orderId
    ticketNumber
    redeemed
    passedCheckpoints {
      id
      name
      precedence
      isSkippable
      createdAt
      createdByID
      createdByName
      __typename
    }
    statusUpdatedByID
    statusUpdatedByName
    statusUpdatedAt
    specialNeed
    phone_number
    guestTicket {
      number
      redeemed
      __typename
    }
    guestName
    notRegisteredGuests
    hasNotRegisteredGuest
    deleted
    createdAt
    createdByID
    createdByName
    rejectionReason {
      id
      content
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      updatedByID
      updatedByName
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    rejectionComment
    isPaid
    isTransfered
    isPendingTransfer
    lastGuestId
    lastGuest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    lastMainGuestId
    ownerHistory
    paidAmount
    balance
    scannedAt
    scannedByName
    refunded
    paymentTransactionId
    paymentParentTransactionId
    origin
    paymentMethod
    testField
    updatedAt
    _version
    _deleted
    _lastChangedAt
    bookingRejectionReasonId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookingQueryVariables,
  APITypes.GetBookingQuery
>;
export const listBookings = /* GraphQL */ `query ListBookings(
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookingsQueryVariables,
  APITypes.ListBookingsQuery
>;
export const syncBookings = /* GraphQL */ `query SyncBookings(
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBookings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBookingsQueryVariables,
  APITypes.SyncBookingsQuery
>;
export const bookingByGuestId = /* GraphQL */ `query BookingByGuestId(
  $bookingGuestId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingByGuestId(
    bookingGuestId: $bookingGuestId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingByGuestIdQueryVariables,
  APITypes.BookingByGuestIdQuery
>;
export const bookingByMainGuestId = /* GraphQL */ `query BookingByMainGuestId(
  $bookingMainGuestId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingByMainGuestId(
    bookingMainGuestId: $bookingMainGuestId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingByMainGuestIdQueryVariables,
  APITypes.BookingByMainGuestIdQuery
>;
export const bookingByMainBookingId = /* GraphQL */ `query BookingByMainBookingId(
  $mainBookingId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingByMainBookingId(
    mainBookingId: $mainBookingId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingByMainBookingIdQueryVariables,
  APITypes.BookingByMainBookingIdQuery
>;
export const bookingByEventId = /* GraphQL */ `query BookingByEventId(
  $bookingEventId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingByEventId(
    bookingEventId: $bookingEventId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingByEventIdQueryVariables,
  APITypes.BookingByEventIdQuery
>;
export const bookingByWaveId = /* GraphQL */ `query BookingByWaveId(
  $waveId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBookingFilterInput
  $limit: Int
  $nextToken: String
) {
  bookingByWaveId(
    waveId: $waveId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BookingByWaveIdQueryVariables,
  APITypes.BookingByWaveIdQuery
>;
export const getRejectReason = /* GraphQL */ `query GetRejectReason($id: ID!) {
  getRejectReason(id: $id) {
    id
    content
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    updatedByID
    updatedByName
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRejectReasonQueryVariables,
  APITypes.GetRejectReasonQuery
>;
export const listRejectReasons = /* GraphQL */ `query ListRejectReasons(
  $filter: ModelRejectReasonFilterInput
  $limit: Int
  $nextToken: String
) {
  listRejectReasons(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      content
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      updatedByID
      updatedByName
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRejectReasonsQueryVariables,
  APITypes.ListRejectReasonsQuery
>;
export const syncRejectReasons = /* GraphQL */ `query SyncRejectReasons(
  $filter: ModelRejectReasonFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRejectReasons(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      content
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      updatedByID
      updatedByName
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRejectReasonsQueryVariables,
  APITypes.SyncRejectReasonsQuery
>;
export const getInvitation = /* GraphQL */ `query GetInvitation($id: ID!) {
  getInvitation(id: $id) {
    id
    invitationEventId
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventTicket {
      id
      type
      cashlessCredit
      description
      waves {
        id
        name
        price
        startDate
        endDate
        active
        quota
        AutomaticShift
        __typename
      }
      color
      paymentRules
      approvalRule
      showAll
      showOnHold
      onHoldDisplayText
      showSoldOut
      soldOutDisplayText
      setAvailable
      availableAtDate
      allowTransferred
      transferredAprroval
      transferredSameGender
      showUpcomingWave
      showUpcomingWaveDisplayText
      eventID
      gateID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cashlessCredit
    balance
    wave
    phone_number
    name
    email
    reason
    secret
    used
    scannedAt
    scannedByName
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    invitationEventTicketId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvitationQueryVariables,
  APITypes.GetInvitationQuery
>;
export const listInvitations = /* GraphQL */ `query ListInvitations(
  $filter: ModelInvitationFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      invitationEventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      balance
      wave
      phone_number
      name
      email
      reason
      secret
      used
      scannedAt
      scannedByName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invitationEventTicketId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvitationsQueryVariables,
  APITypes.ListInvitationsQuery
>;
export const syncInvitations = /* GraphQL */ `query SyncInvitations(
  $filter: ModelInvitationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInvitations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      invitationEventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      balance
      wave
      phone_number
      name
      email
      reason
      secret
      used
      scannedAt
      scannedByName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invitationEventTicketId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInvitationsQueryVariables,
  APITypes.SyncInvitationsQuery
>;
export const invitationByEventId = /* GraphQL */ `query InvitationByEventId(
  $invitationEventId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvitationFilterInput
  $limit: Int
  $nextToken: String
) {
  invitationByEventId(
    invitationEventId: $invitationEventId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      invitationEventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      balance
      wave
      phone_number
      name
      email
      reason
      secret
      used
      scannedAt
      scannedByName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invitationEventTicketId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvitationByEventIdQueryVariables,
  APITypes.InvitationByEventIdQuery
>;
export const invitationBySecret = /* GraphQL */ `query InvitationBySecret(
  $secret: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvitationFilterInput
  $limit: Int
  $nextToken: String
) {
  invitationBySecret(
    secret: $secret
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      invitationEventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      balance
      wave
      phone_number
      name
      email
      reason
      secret
      used
      scannedAt
      scannedByName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invitationEventTicketId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvitationBySecretQueryVariables,
  APITypes.InvitationBySecretQuery
>;
export const getTransaction = /* GraphQL */ `query GetTransaction($id: ID!) {
  getTransaction(id: $id) {
    id
    type
    guestId
    guestName
    guestsNames
    guestPhone_number
    eventId
    ticketId
    booking {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    success
    failureReason
    currency
    amount_cents
    refund
    refunded_amount_cents
    orderID
    transactionID
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    transactionBookingId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTransactionQueryVariables,
  APITypes.GetTransactionQuery
>;
export const listTransactions = /* GraphQL */ `query ListTransactions(
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      guestId
      guestName
      guestsNames
      guestPhone_number
      eventId
      ticketId
      booking {
        id
        status
        overallStatus
        bookingGuestId
        bookingMainGuestId
        mainBookingId
        bookingEventId
        bookingEventTicketId
        guest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        mainGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        wave
        waveId
        isMainGuest
        isInvited
        orderId
        ticketNumber
        redeemed
        passedCheckpoints {
          id
          name
          precedence
          isSkippable
          createdAt
          createdByID
          createdByName
          __typename
        }
        statusUpdatedByID
        statusUpdatedByName
        statusUpdatedAt
        specialNeed
        phone_number
        guestTicket {
          number
          redeemed
          __typename
        }
        guestName
        notRegisteredGuests
        hasNotRegisteredGuest
        deleted
        createdAt
        createdByID
        createdByName
        rejectionReason {
          id
          content
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          updatedByID
          updatedByName
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        rejectionComment
        isPaid
        isTransfered
        isPendingTransfer
        lastGuestId
        lastGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lastMainGuestId
        ownerHistory
        paidAmount
        balance
        scannedAt
        scannedByName
        refunded
        paymentTransactionId
        paymentParentTransactionId
        origin
        paymentMethod
        testField
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookingRejectionReasonId
        __typename
      }
      success
      failureReason
      currency
      amount_cents
      refund
      refunded_amount_cents
      orderID
      transactionID
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transactionBookingId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTransactionsQueryVariables,
  APITypes.ListTransactionsQuery
>;
export const syncTransactions = /* GraphQL */ `query SyncTransactions(
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTransactions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      type
      guestId
      guestName
      guestsNames
      guestPhone_number
      eventId
      ticketId
      booking {
        id
        status
        overallStatus
        bookingGuestId
        bookingMainGuestId
        mainBookingId
        bookingEventId
        bookingEventTicketId
        guest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        mainGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        wave
        waveId
        isMainGuest
        isInvited
        orderId
        ticketNumber
        redeemed
        passedCheckpoints {
          id
          name
          precedence
          isSkippable
          createdAt
          createdByID
          createdByName
          __typename
        }
        statusUpdatedByID
        statusUpdatedByName
        statusUpdatedAt
        specialNeed
        phone_number
        guestTicket {
          number
          redeemed
          __typename
        }
        guestName
        notRegisteredGuests
        hasNotRegisteredGuest
        deleted
        createdAt
        createdByID
        createdByName
        rejectionReason {
          id
          content
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          updatedByID
          updatedByName
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        rejectionComment
        isPaid
        isTransfered
        isPendingTransfer
        lastGuestId
        lastGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lastMainGuestId
        ownerHistory
        paidAmount
        balance
        scannedAt
        scannedByName
        refunded
        paymentTransactionId
        paymentParentTransactionId
        origin
        paymentMethod
        testField
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookingRejectionReasonId
        __typename
      }
      success
      failureReason
      currency
      amount_cents
      refund
      refunded_amount_cents
      orderID
      transactionID
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transactionBookingId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTransactionsQueryVariables,
  APITypes.SyncTransactionsQuery
>;
export const transactionsByEvent = /* GraphQL */ `query TransactionsByEvent(
  $eventId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByEvent(
    eventId: $eventId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      guestId
      guestName
      guestsNames
      guestPhone_number
      eventId
      ticketId
      booking {
        id
        status
        overallStatus
        bookingGuestId
        bookingMainGuestId
        mainBookingId
        bookingEventId
        bookingEventTicketId
        guest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        mainGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        wave
        waveId
        isMainGuest
        isInvited
        orderId
        ticketNumber
        redeemed
        passedCheckpoints {
          id
          name
          precedence
          isSkippable
          createdAt
          createdByID
          createdByName
          __typename
        }
        statusUpdatedByID
        statusUpdatedByName
        statusUpdatedAt
        specialNeed
        phone_number
        guestTicket {
          number
          redeemed
          __typename
        }
        guestName
        notRegisteredGuests
        hasNotRegisteredGuest
        deleted
        createdAt
        createdByID
        createdByName
        rejectionReason {
          id
          content
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          updatedByID
          updatedByName
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        rejectionComment
        isPaid
        isTransfered
        isPendingTransfer
        lastGuestId
        lastGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lastMainGuestId
        ownerHistory
        paidAmount
        balance
        scannedAt
        scannedByName
        refunded
        paymentTransactionId
        paymentParentTransactionId
        origin
        paymentMethod
        testField
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookingRejectionReasonId
        __typename
      }
      success
      failureReason
      currency
      amount_cents
      refund
      refunded_amount_cents
      orderID
      transactionID
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transactionBookingId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByEventQueryVariables,
  APITypes.TransactionsByEventQuery
>;
export const getWavesConsumption = /* GraphQL */ `query GetWavesConsumption($id: ID!) {
  getWavesConsumption(id: $id) {
    waveId
    consumedTickets
    reservedTickets
    totalTickets
    consumed
    eventId
    eventTicketId
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWavesConsumptionQueryVariables,
  APITypes.GetWavesConsumptionQuery
>;
export const listWavesConsumptions = /* GraphQL */ `query ListWavesConsumptions(
  $filter: ModelWavesConsumptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listWavesConsumptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      waveId
      consumedTickets
      reservedTickets
      totalTickets
      consumed
      eventId
      eventTicketId
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWavesConsumptionsQueryVariables,
  APITypes.ListWavesConsumptionsQuery
>;
export const syncWavesConsumptions = /* GraphQL */ `query SyncWavesConsumptions(
  $filter: ModelWavesConsumptionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncWavesConsumptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      waveId
      consumedTickets
      reservedTickets
      totalTickets
      consumed
      eventId
      eventTicketId
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncWavesConsumptionsQueryVariables,
  APITypes.SyncWavesConsumptionsQuery
>;
export const waveConsumptionByWaveId = /* GraphQL */ `query WaveConsumptionByWaveId(
  $waveId: String!
  $consumedTickets: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWavesConsumptionFilterInput
  $limit: Int
  $nextToken: String
) {
  waveConsumptionByWaveId(
    waveId: $waveId
    consumedTickets: $consumedTickets
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      waveId
      consumedTickets
      reservedTickets
      totalTickets
      consumed
      eventId
      eventTicketId
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.WaveConsumptionByWaveIdQueryVariables,
  APITypes.WaveConsumptionByWaveIdQuery
>;
export const getCashlessTransaction = /* GraphQL */ `query GetCashlessTransaction($id: ID!) {
  getCashlessTransaction(id: $id) {
    id
    pos
    checkNumber
    orderTotal
    paidAmount
    paymentStatus
    guestId
    guestPhoneNumber
    guestEmail
    guest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    guestName
    type
    bookingId
    invitationId
    eventId
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    orderedItems {
      id
      name
      quantity
      price
      __typename
    }
    syncOperationId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCashlessTransactionQueryVariables,
  APITypes.GetCashlessTransactionQuery
>;
export const listCashlessTransactions = /* GraphQL */ `query ListCashlessTransactions(
  $filter: ModelCashlessTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listCashlessTransactions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pos
      checkNumber
      orderTotal
      paidAmount
      paymentStatus
      guestId
      guestPhoneNumber
      guestEmail
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      guestName
      type
      bookingId
      invitationId
      eventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      syncOperationId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCashlessTransactionsQueryVariables,
  APITypes.ListCashlessTransactionsQuery
>;
export const syncCashlessTransactions = /* GraphQL */ `query SyncCashlessTransactions(
  $filter: ModelCashlessTransactionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCashlessTransactions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      pos
      checkNumber
      orderTotal
      paidAmount
      paymentStatus
      guestId
      guestPhoneNumber
      guestEmail
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      guestName
      type
      bookingId
      invitationId
      eventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      syncOperationId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCashlessTransactionsQueryVariables,
  APITypes.SyncCashlessTransactionsQuery
>;
export const cashlessTransactionByGuestID = /* GraphQL */ `query CashlessTransactionByGuestID(
  $guestId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashlessTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  cashlessTransactionByGuestID(
    guestId: $guestId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pos
      checkNumber
      orderTotal
      paidAmount
      paymentStatus
      guestId
      guestPhoneNumber
      guestEmail
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      guestName
      type
      bookingId
      invitationId
      eventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      syncOperationId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CashlessTransactionByGuestIDQueryVariables,
  APITypes.CashlessTransactionByGuestIDQuery
>;
export const cashlessTransactionByEventID = /* GraphQL */ `query CashlessTransactionByEventID(
  $eventId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashlessTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  cashlessTransactionByEventID(
    eventId: $eventId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pos
      checkNumber
      orderTotal
      paidAmount
      paymentStatus
      guestId
      guestPhoneNumber
      guestEmail
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      guestName
      type
      bookingId
      invitationId
      eventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      syncOperationId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CashlessTransactionByEventIDQueryVariables,
  APITypes.CashlessTransactionByEventIDQuery
>;
export const cashlessTransactionsByDeleted = /* GraphQL */ `query CashlessTransactionsByDeleted(
  $deleted: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashlessTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  cashlessTransactionsByDeleted(
    deleted: $deleted
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pos
      checkNumber
      orderTotal
      paidAmount
      paymentStatus
      guestId
      guestPhoneNumber
      guestEmail
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      guestName
      type
      bookingId
      invitationId
      eventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      syncOperationId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CashlessTransactionsByDeletedQueryVariables,
  APITypes.CashlessTransactionsByDeletedQuery
>;
export const getCashlessTopUp = /* GraphQL */ `query GetCashlessTopUp($id: ID!) {
  getCashlessTopUp(id: $id) {
    id
    operation
    amount
    availableBalance
    paymentChannel
    paymentMethod
    isVoided
    guestId
    guestPhoneNumber
    guestEmail
    guest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bookingId
    invitationId
    ticketType
    booking {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    invitation {
      id
      invitationEventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      balance
      wave
      phone_number
      name
      email
      reason
      secret
      used
      scannedAt
      scannedByName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invitationEventTicketId
      __typename
    }
    eventId
    eventName
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    type
    syncOperationId
    deleted
    guestName
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCashlessTopUpQueryVariables,
  APITypes.GetCashlessTopUpQuery
>;
export const listCashlessTopUps = /* GraphQL */ `query ListCashlessTopUps(
  $filter: ModelCashlessTopUpFilterInput
  $limit: Int
  $nextToken: String
) {
  listCashlessTopUps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      operation
      amount
      availableBalance
      paymentChannel
      paymentMethod
      isVoided
      guestId
      guestPhoneNumber
      guestEmail
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bookingId
      invitationId
      ticketType
      booking {
        id
        status
        overallStatus
        bookingGuestId
        bookingMainGuestId
        mainBookingId
        bookingEventId
        bookingEventTicketId
        guest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        mainGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        wave
        waveId
        isMainGuest
        isInvited
        orderId
        ticketNumber
        redeemed
        passedCheckpoints {
          id
          name
          precedence
          isSkippable
          createdAt
          createdByID
          createdByName
          __typename
        }
        statusUpdatedByID
        statusUpdatedByName
        statusUpdatedAt
        specialNeed
        phone_number
        guestTicket {
          number
          redeemed
          __typename
        }
        guestName
        notRegisteredGuests
        hasNotRegisteredGuest
        deleted
        createdAt
        createdByID
        createdByName
        rejectionReason {
          id
          content
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          updatedByID
          updatedByName
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        rejectionComment
        isPaid
        isTransfered
        isPendingTransfer
        lastGuestId
        lastGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lastMainGuestId
        ownerHistory
        paidAmount
        balance
        scannedAt
        scannedByName
        refunded
        paymentTransactionId
        paymentParentTransactionId
        origin
        paymentMethod
        testField
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookingRejectionReasonId
        __typename
      }
      invitation {
        id
        invitationEventId
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        balance
        wave
        phone_number
        name
        email
        reason
        secret
        used
        scannedAt
        scannedByName
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        invitationEventTicketId
        __typename
      }
      eventId
      eventName
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      type
      syncOperationId
      deleted
      guestName
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCashlessTopUpsQueryVariables,
  APITypes.ListCashlessTopUpsQuery
>;
export const syncCashlessTopUps = /* GraphQL */ `query SyncCashlessTopUps(
  $filter: ModelCashlessTopUpFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCashlessTopUps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      operation
      amount
      availableBalance
      paymentChannel
      paymentMethod
      isVoided
      guestId
      guestPhoneNumber
      guestEmail
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bookingId
      invitationId
      ticketType
      booking {
        id
        status
        overallStatus
        bookingGuestId
        bookingMainGuestId
        mainBookingId
        bookingEventId
        bookingEventTicketId
        guest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        mainGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        wave
        waveId
        isMainGuest
        isInvited
        orderId
        ticketNumber
        redeemed
        passedCheckpoints {
          id
          name
          precedence
          isSkippable
          createdAt
          createdByID
          createdByName
          __typename
        }
        statusUpdatedByID
        statusUpdatedByName
        statusUpdatedAt
        specialNeed
        phone_number
        guestTicket {
          number
          redeemed
          __typename
        }
        guestName
        notRegisteredGuests
        hasNotRegisteredGuest
        deleted
        createdAt
        createdByID
        createdByName
        rejectionReason {
          id
          content
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          updatedByID
          updatedByName
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        rejectionComment
        isPaid
        isTransfered
        isPendingTransfer
        lastGuestId
        lastGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lastMainGuestId
        ownerHistory
        paidAmount
        balance
        scannedAt
        scannedByName
        refunded
        paymentTransactionId
        paymentParentTransactionId
        origin
        paymentMethod
        testField
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookingRejectionReasonId
        __typename
      }
      invitation {
        id
        invitationEventId
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        balance
        wave
        phone_number
        name
        email
        reason
        secret
        used
        scannedAt
        scannedByName
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        invitationEventTicketId
        __typename
      }
      eventId
      eventName
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      type
      syncOperationId
      deleted
      guestName
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCashlessTopUpsQueryVariables,
  APITypes.SyncCashlessTopUpsQuery
>;
export const cashlessTopUpByGuestID = /* GraphQL */ `query CashlessTopUpByGuestID(
  $guestId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashlessTopUpFilterInput
  $limit: Int
  $nextToken: String
) {
  cashlessTopUpByGuestID(
    guestId: $guestId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      operation
      amount
      availableBalance
      paymentChannel
      paymentMethod
      isVoided
      guestId
      guestPhoneNumber
      guestEmail
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bookingId
      invitationId
      ticketType
      booking {
        id
        status
        overallStatus
        bookingGuestId
        bookingMainGuestId
        mainBookingId
        bookingEventId
        bookingEventTicketId
        guest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        mainGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        wave
        waveId
        isMainGuest
        isInvited
        orderId
        ticketNumber
        redeemed
        passedCheckpoints {
          id
          name
          precedence
          isSkippable
          createdAt
          createdByID
          createdByName
          __typename
        }
        statusUpdatedByID
        statusUpdatedByName
        statusUpdatedAt
        specialNeed
        phone_number
        guestTicket {
          number
          redeemed
          __typename
        }
        guestName
        notRegisteredGuests
        hasNotRegisteredGuest
        deleted
        createdAt
        createdByID
        createdByName
        rejectionReason {
          id
          content
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          updatedByID
          updatedByName
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        rejectionComment
        isPaid
        isTransfered
        isPendingTransfer
        lastGuestId
        lastGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lastMainGuestId
        ownerHistory
        paidAmount
        balance
        scannedAt
        scannedByName
        refunded
        paymentTransactionId
        paymentParentTransactionId
        origin
        paymentMethod
        testField
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookingRejectionReasonId
        __typename
      }
      invitation {
        id
        invitationEventId
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        balance
        wave
        phone_number
        name
        email
        reason
        secret
        used
        scannedAt
        scannedByName
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        invitationEventTicketId
        __typename
      }
      eventId
      eventName
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      type
      syncOperationId
      deleted
      guestName
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CashlessTopUpByGuestIDQueryVariables,
  APITypes.CashlessTopUpByGuestIDQuery
>;
export const cashlessTopUpByEventID = /* GraphQL */ `query CashlessTopUpByEventID(
  $eventId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashlessTopUpFilterInput
  $limit: Int
  $nextToken: String
) {
  cashlessTopUpByEventID(
    eventId: $eventId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      operation
      amount
      availableBalance
      paymentChannel
      paymentMethod
      isVoided
      guestId
      guestPhoneNumber
      guestEmail
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bookingId
      invitationId
      ticketType
      booking {
        id
        status
        overallStatus
        bookingGuestId
        bookingMainGuestId
        mainBookingId
        bookingEventId
        bookingEventTicketId
        guest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        mainGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        wave
        waveId
        isMainGuest
        isInvited
        orderId
        ticketNumber
        redeemed
        passedCheckpoints {
          id
          name
          precedence
          isSkippable
          createdAt
          createdByID
          createdByName
          __typename
        }
        statusUpdatedByID
        statusUpdatedByName
        statusUpdatedAt
        specialNeed
        phone_number
        guestTicket {
          number
          redeemed
          __typename
        }
        guestName
        notRegisteredGuests
        hasNotRegisteredGuest
        deleted
        createdAt
        createdByID
        createdByName
        rejectionReason {
          id
          content
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          updatedByID
          updatedByName
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        rejectionComment
        isPaid
        isTransfered
        isPendingTransfer
        lastGuestId
        lastGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lastMainGuestId
        ownerHistory
        paidAmount
        balance
        scannedAt
        scannedByName
        refunded
        paymentTransactionId
        paymentParentTransactionId
        origin
        paymentMethod
        testField
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookingRejectionReasonId
        __typename
      }
      invitation {
        id
        invitationEventId
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        balance
        wave
        phone_number
        name
        email
        reason
        secret
        used
        scannedAt
        scannedByName
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        invitationEventTicketId
        __typename
      }
      eventId
      eventName
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      type
      syncOperationId
      deleted
      guestName
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CashlessTopUpByEventIDQueryVariables,
  APITypes.CashlessTopUpByEventIDQuery
>;
export const cashlessTopUpByDeleted = /* GraphQL */ `query CashlessTopUpByDeleted(
  $deleted: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCashlessTopUpFilterInput
  $limit: Int
  $nextToken: String
) {
  cashlessTopUpByDeleted(
    deleted: $deleted
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      operation
      amount
      availableBalance
      paymentChannel
      paymentMethod
      isVoided
      guestId
      guestPhoneNumber
      guestEmail
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      bookingId
      invitationId
      ticketType
      booking {
        id
        status
        overallStatus
        bookingGuestId
        bookingMainGuestId
        mainBookingId
        bookingEventId
        bookingEventTicketId
        guest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        mainGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        wave
        waveId
        isMainGuest
        isInvited
        orderId
        ticketNumber
        redeemed
        passedCheckpoints {
          id
          name
          precedence
          isSkippable
          createdAt
          createdByID
          createdByName
          __typename
        }
        statusUpdatedByID
        statusUpdatedByName
        statusUpdatedAt
        specialNeed
        phone_number
        guestTicket {
          number
          redeemed
          __typename
        }
        guestName
        notRegisteredGuests
        hasNotRegisteredGuest
        deleted
        createdAt
        createdByID
        createdByName
        rejectionReason {
          id
          content
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          updatedByID
          updatedByName
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        rejectionComment
        isPaid
        isTransfered
        isPendingTransfer
        lastGuestId
        lastGuest {
          id
          name
          isNameEdited
          username
          email
          phone_number
          guestGroupID
          guestGroupName
          sub
          facebookSub
          guest_avatar
          avg_spend
          avg_ticket_type
          numberOfTickets
          connections
          last_attended_event
          last_attended_event_Name
          gender
          isGenderEdited
          group
          faceBookID
          appPassword
          birthdate
          isBirthdateEdited
          isVerified
          images
          address
          totalEvents
          flags
          frontPersonalID
          isFrontPersonalIDEdited
          passportImage
          isPassportImageEdited
          backPersonalID
          isBackPersonalIDEdited
          faceBookProfileLink
          instagramProfileLink
          nationality
          balance
          isBlocked
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lastMainGuestId
        ownerHistory
        paidAmount
        balance
        scannedAt
        scannedByName
        refunded
        paymentTransactionId
        paymentParentTransactionId
        origin
        paymentMethod
        testField
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookingRejectionReasonId
        __typename
      }
      invitation {
        id
        invitationEventId
        event {
          id
          name
          description
          disclaimer
          startDate
          endDate
          todoList
          map
          image
          largeScreensImage
          mediumScreensImage
          smallScreensImage
          sendEmailToValidGuest
          sendSmsToValidGuest
          gallery
          visibleTo
          deleted
          createdAt
          createdByID
          createdByName
          published
          applyGenderRatio
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        eventTicket {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        cashlessCredit
        balance
        wave
        phone_number
        name
        email
        reason
        secret
        used
        scannedAt
        scannedByName
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        invitationEventTicketId
        __typename
      }
      eventId
      eventName
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      type
      syncOperationId
      deleted
      guestName
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CashlessTopUpByDeletedQueryVariables,
  APITypes.CashlessTopUpByDeletedQuery
>;
export const getCategory = /* GraphQL */ `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    name
    precedence
    items {
      items {
        id
        name
        image
        price
        precedence
        categoryID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryQueryVariables,
  APITypes.GetCategoryQuery
>;
export const listCategories = /* GraphQL */ `query ListCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      precedence
      items {
        items {
          id
          name
          image
          price
          precedence
          categoryID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategoriesQueryVariables,
  APITypes.ListCategoriesQuery
>;
export const syncCategories = /* GraphQL */ `query SyncCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      precedence
      items {
        items {
          id
          name
          image
          price
          precedence
          categoryID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCategoriesQueryVariables,
  APITypes.SyncCategoriesQuery
>;
export const categoryByDeleted = /* GraphQL */ `query CategoryByDeleted(
  $deleted: String!
  $precedence: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  categoryByDeleted(
    deleted: $deleted
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      precedence
      items {
        items {
          id
          name
          image
          price
          precedence
          categoryID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CategoryByDeletedQueryVariables,
  APITypes.CategoryByDeletedQuery
>;
export const getItem = /* GraphQL */ `query GetItem($id: ID!) {
  getItem(id: $id) {
    id
    name
    image
    price
    precedence
    categoryID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetItemQueryVariables, APITypes.GetItemQuery>;
export const listItems = /* GraphQL */ `query ListItems(
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      image
      price
      precedence
      categoryID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListItemsQueryVariables, APITypes.ListItemsQuery>;
export const syncItems = /* GraphQL */ `query SyncItems(
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      image
      price
      precedence
      categoryID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncItemsQueryVariables, APITypes.SyncItemsQuery>;
export const byCategoryID = /* GraphQL */ `query ByCategoryID(
  $categoryID: ID!
  $precedence: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelItemFilterInput
  $limit: Int
  $nextToken: String
) {
  byCategoryID(
    categoryID: $categoryID
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      image
      price
      precedence
      categoryID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ByCategoryIDQueryVariables,
  APITypes.ByCategoryIDQuery
>;
export const getItemConsumption = /* GraphQL */ `query GetItemConsumption($id: ID!) {
  getItemConsumption(id: $id) {
    id
    eventId
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    itemId
    item {
      id
      name
      image
      price
      precedence
      categoryID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    itemName
    itemQuantity
    itemPrice
    syncOperationId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetItemConsumptionQueryVariables,
  APITypes.GetItemConsumptionQuery
>;
export const listItemConsumptions = /* GraphQL */ `query ListItemConsumptions(
  $filter: ModelItemConsumptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listItemConsumptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      eventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      itemId
      item {
        id
        name
        image
        price
        precedence
        categoryID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      itemName
      itemQuantity
      itemPrice
      syncOperationId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListItemConsumptionsQueryVariables,
  APITypes.ListItemConsumptionsQuery
>;
export const syncItemConsumptions = /* GraphQL */ `query SyncItemConsumptions(
  $filter: ModelItemConsumptionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncItemConsumptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      eventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      itemId
      item {
        id
        name
        image
        price
        precedence
        categoryID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      itemName
      itemQuantity
      itemPrice
      syncOperationId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncItemConsumptionsQueryVariables,
  APITypes.SyncItemConsumptionsQuery
>;
export const ItemConsumptionByEventId = /* GraphQL */ `query ItemConsumptionByEventId(
  $eventId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelItemConsumptionFilterInput
  $limit: Int
  $nextToken: String
) {
  ItemConsumptionByEventId(
    eventId: $eventId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      itemId
      item {
        id
        name
        image
        price
        precedence
        categoryID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      itemName
      itemQuantity
      itemPrice
      syncOperationId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ItemConsumptionByEventIdQueryVariables,
  APITypes.ItemConsumptionByEventIdQuery
>;
export const getTicketsScan = /* GraphQL */ `query GetTicketsScan($id: ID!) {
  getTicketsScan(id: $id) {
    id
    scannedById
    scannedAt
    status
    eventId
    bookingId
    invitationId
    guestName
    ticketType
    guestAvatar
    deleted
    gateName
    checkpointName
    scannedByName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTicketsScanQueryVariables,
  APITypes.GetTicketsScanQuery
>;
export const listTicketsScans = /* GraphQL */ `query ListTicketsScans(
  $filter: ModelTicketsScanFilterInput
  $limit: Int
  $nextToken: String
) {
  listTicketsScans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      scannedById
      scannedAt
      status
      eventId
      bookingId
      invitationId
      guestName
      ticketType
      guestAvatar
      deleted
      gateName
      checkpointName
      scannedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTicketsScansQueryVariables,
  APITypes.ListTicketsScansQuery
>;
export const syncTicketsScans = /* GraphQL */ `query SyncTicketsScans(
  $filter: ModelTicketsScanFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTicketsScans(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      scannedById
      scannedAt
      status
      eventId
      bookingId
      invitationId
      guestName
      ticketType
      guestAvatar
      deleted
      gateName
      checkpointName
      scannedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTicketsScansQueryVariables,
  APITypes.SyncTicketsScansQuery
>;
export const TicketsScanByScannedById = /* GraphQL */ `query TicketsScanByScannedById(
  $scannedById: ID!
  $scannedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTicketsScanFilterInput
  $limit: Int
  $nextToken: String
) {
  TicketsScanByScannedById(
    scannedById: $scannedById
    scannedAt: $scannedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      scannedById
      scannedAt
      status
      eventId
      bookingId
      invitationId
      guestName
      ticketType
      guestAvatar
      deleted
      gateName
      checkpointName
      scannedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TicketsScanByScannedByIdQueryVariables,
  APITypes.TicketsScanByScannedByIdQuery
>;
export const TicketScansByEventId = /* GraphQL */ `query TicketScansByEventId(
  $eventId: ID!
  $scannedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTicketsScanFilterInput
  $limit: Int
  $nextToken: String
) {
  TicketScansByEventId(
    eventId: $eventId
    scannedAt: $scannedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      scannedById
      scannedAt
      status
      eventId
      bookingId
      invitationId
      guestName
      ticketType
      guestAvatar
      deleted
      gateName
      checkpointName
      scannedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TicketScansByEventIdQueryVariables,
  APITypes.TicketScansByEventIdQuery
>;
export const getGate = /* GraphQL */ `query GetGate($id: ID!) {
  getGate(id: $id) {
    id
    name
    eventId
    tickets
    admins
    checkpoints {
      id
      name
      precedence
      isSkippable
      createdAt
      createdByID
      createdByName
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGateQueryVariables, APITypes.GetGateQuery>;
export const listGates = /* GraphQL */ `query ListGates(
  $filter: ModelGateFilterInput
  $limit: Int
  $nextToken: String
) {
  listGates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      eventId
      tickets
      admins
      checkpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListGatesQueryVariables, APITypes.ListGatesQuery>;
export const syncGates = /* GraphQL */ `query SyncGates(
  $filter: ModelGateFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncGates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      eventId
      tickets
      admins
      checkpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncGatesQueryVariables, APITypes.SyncGatesQuery>;
export const GateByEventID = /* GraphQL */ `query GateByEventID(
  $eventId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelGateFilterInput
  $limit: Int
  $nextToken: String
) {
  GateByEventID(
    eventId: $eventId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      eventId
      tickets
      admins
      checkpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GateByEventIDQueryVariables,
  APITypes.GateByEventIDQuery
>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    bookingId
    eventId
    userId
    orderedItems {
      id
      name
      quantity
      price
      __typename
    }
    status
    dispatcherId
    totalAmount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      bookingId
      eventId
      userId
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      status
      dispatcherId
      totalAmount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const syncOrders = /* GraphQL */ `query SyncOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      bookingId
      eventId
      userId
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      status
      dispatcherId
      totalAmount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrdersQueryVariables,
  APITypes.SyncOrdersQuery
>;
export const getUserIP = /* GraphQL */ `query GetUserIP($id: ID!) {
  getUserIP(id: $id) {
    id
    ip
    date
    times
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserIPQueryVariables, APITypes.GetUserIPQuery>;
export const listUserIPS = /* GraphQL */ `query ListUserIPS(
  $filter: ModelUserIPFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserIPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      ip
      date
      times
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserIPSQueryVariables,
  APITypes.ListUserIPSQuery
>;
export const syncUserIPS = /* GraphQL */ `query SyncUserIPS(
  $filter: ModelUserIPFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserIPS(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      ip
      date
      times
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserIPSQueryVariables,
  APITypes.SyncUserIPSQuery
>;
export const getScan = /* GraphQL */ `query GetScan($id: ID!) {
  getScan(id: $id) {
    id
    link
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetScanQueryVariables, APITypes.GetScanQuery>;
export const listScans = /* GraphQL */ `query ListScans(
  $filter: ModelScanFilterInput
  $limit: Int
  $nextToken: String
) {
  listScans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      link
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListScansQueryVariables, APITypes.ListScansQuery>;
export const syncScans = /* GraphQL */ `query SyncScans(
  $filter: ModelScanFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncScans(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      link
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncScansQueryVariables, APITypes.SyncScansQuery>;
