import { Link, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { cloudinaryURL } from '../../constants/enums';
import LogoURL from './ulter-logo-white.webp';
const Logo: React.FC = () => {
  const account = useSelector((state: any) => state.accounts.selected);

  let logoURL;
  if (account && account.logo) {
    logoURL = account.logo;
    // console.log(logoURL);
  } else {
    // logoURL = "https://etihadairwaysassets143308-dev.s3.us-east-2.amazonaws.com/public/1696506804232-etihad.png";
    // logoURL = 'https://ulter.events/assets/images/ulter-logo-white.png';
    // logoURL = `${cloudinaryURL}1707217547501-ulter.png`;
    logoURL = `https://ticketingsystemadmina29b1f146cd8476b9f9aefcf325132756-dev.s3.us-east-2.amazonaws.com/public/ulter-logo-white.webp`;
  }

  // let logoURL = `https://wanijoseph.mo.cloudinary.net/hospitalityoffline-staging/${account.domain}.png`;
  return (
    <Link color="inherit" underline="none">
      <img
        // src={`https://bakyassets122329-staging.s3.us-east-2.amazonaws.com/public/${account.domain}.png`}
        src={logoURL}
        alt=""
        style={{
          width: 200,
          height: 30,
          objectFit: 'contain',
        }}
      />

      {/* <Typography
        component={"span"}
        align="center"
        sx={{
          display: "block",
          lineHeight: 1.3,
          fontWeight: 300,
          fontSize: ".9rem",
          letterSpacing: 4,
          color: (theme) => commonBlack(theme),
        }}
      >
        BAKY HOSPITALITY
      </Typography>
      <Typography
        component={"span"}
        align="center"
        sx={{
          display: "block",
          lineHeight: 1.3,
          fontWeight: 300,
          fontSize: ".7rem",
          letterSpacing: 5,
          color: (theme) => commonBlack(theme),
        }}
      >
        GROUP LLC
      </Typography> */}
    </Link>
  );
};

export default Logo;
