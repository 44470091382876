import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import useApp from '../../hooks/useApp';
import { ping } from '../../Services/testServer';
import { useDispatch } from 'react-redux';
import {
  setModalOpen,
  setServerAddress,
  setServerStatus,
} from '../../store/ducks/server';

type Props = {
  open: boolean;
  onClose: () => void;
};
function OfflineIpModal({ open, onClose }: Props) {
  const { showError } = useApp();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setModalOpen(false));
    onClose();
  };

  const backGround = (theme) => theme.palette.grey[500];
  const [ip, setIp] = useState('');
  const [port, setPort] = useState('');
  const [loading, setLoading] = useState(false);
  const [tested, setTested] = useState(false);
  const test = async () => {
    setLoading(true);
    try {
      const response = await ping(ip, port);
      if (response.status === 'success') {
        setTested(true);
      }
    } catch (error) {
      console.log(error);
      showError('failed to connect to server, verify the IP and try again');
    } finally {
      setLoading(false);
    }
  };
  const handleSubmitIp = () => {
    dispatch(setServerAddress('http://' + ip + ':' + port + '/api/v1'));
    dispatch(setServerStatus(true));
    setTested(false);
    handleClose();
  };

  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          alignItems: 'center',
        }}
        open={open}
        onClick={(e) => handleClose()}
      >
        <Box sx={classes.containerBox} onClick={(e) => e.stopPropagation()}>
          <Box
            sx={{
              background: (theme) => theme.palette.grey[300],
              padding: '8px 16px',
              color: 'black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Submit Server IP</Typography>
            <CloseIcon
              aria-label="close modal"
              onClick={handleClose}
              sx={{ cursor: 'pointer' }}
            />
          </Box>
          <Paper
            square
            elevation={0}
            sx={{
              border: (theme) => `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Box
              sx={{
                backgroundColor: backGround,
                height: '1.5vh',
              }}
            ></Box>
            <Box
              sx={{
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 25,
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 3,
                  }}
                >
                  <Typography
                    sx={{
                      color: 'primary.main',
                    }}
                  >
                    Enter Server IP:
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                    gap: { xs: 3, sm: 3, md: 3 },
                    justifyContent: 'space-between',
                    mt: 2,
                  }}
                >
                  <TextField
                    id="ip"
                    label="IP"
                    variant="standard"
                    value={ip}
                    fullWidth
                    onChange={(e) => setIp(e.target.value)}
                    disabled={tested}
                  />
                  <TextField
                    id="port"
                    label="Port"
                    variant="standard"
                    value={port}
                    fullWidth
                    onChange={(e) => setPort(e.target.value)}
                    disabled={tested}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    my: 3,
                    gap: '15px',
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    disabled={loading || tested || !ip || !port}
                    sx={{
                      backgroundColor: 'primary.contrastText',
                      color: 'secondary.main',
                      borderRadius: '5px',
                      fontSize: 16,
                      fontWeight: 700,
                      px: 3,
                      py: 0.25,
                    }}
                    onClick={() => {
                      if (!ip) {
                        showError('Please Enter a valid IP');
                      } else {
                        test();
                      }
                    }}
                  >
                    Test
                  </LoadingButton>
                  <Button
                    variant="contained"
                    disabled={!tested}
                    sx={{
                      backgroundColor: 'primary.contrastText',
                      color: 'secondary.main',
                      borderRadius: '5px',
                      fontSize: 16,
                      fontWeight: 700,
                      px: 3,
                      py: 0.25,
                    }}
                    onClick={() => {
                      if (!ip) {
                        showError('Please Enter a valid IP');
                      } else {
                        handleSubmitIp();
                      }
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Backdrop>
    </>
  );
}
const classes = {
  containerBox: {
    boxSizing: 'border-box',
    position: 'relative',
    padding: {
      xs: '35px',
      sm: '1rem',
      md: '1.5rem',
    },
    borderRadius: '10px',
    width: '100%',
    maxWidth: 800,
  },
};
export default OfflineIpModal;
