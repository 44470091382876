import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { capitalizeSentence } from '../../../helpers/utils';
import { Category, Item } from '../../../models/GQL_API';
import ContentLoader from '../../UI/ContentLoader/ContentLoader';

export default function OrderSide({
  setItemsToCheckout,
  itemsToCheckout,
  categories,
  fetchingCategories,
  isBuying,
}) {
  const [selectedCategory, setSelectedCategory] = useState<Category>(
    categories[0],
  );
  // const [itemsToCheckout, setItemsToCheckout] = useState<any[]>([]);

  const handleSelectCategory = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const target = e.target as HTMLButtonElement;
    const category: Category = categories.find(
      (category: Category) => category.id === target.id,
    );
    setSelectedCategory(category);
  };

  useEffect(() => {
    if (!categories || !categories.length) return;
    if (selectedCategory) return;
    setSelectedCategory(categories[0]);
  }, [categories]);

  const handleAddToCheckout = (item: any) => {
    const index = itemsToCheckout.findIndex((i: Item) => i.id === item.id);
    if (index !== -1) {
      const updatedItems = [...itemsToCheckout];
      updatedItems[index].quantity += 1;
      setItemsToCheckout(updatedItems);
    } else {
      setItemsToCheckout([...itemsToCheckout, { ...item, quantity: 1 }]);
    }
  };

  return (
    <Grid item xs={12} sm={7} md={8} lg={9}>
      <Box
        sx={{
          backgroundColor: 'info.700',
          minHeight: '100vh',
          padding: 2,
        }}
      >
        {fetchingCategories && (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
          >
            <ContentLoader />
          </Box>
        )}

        {categories?.length === 0 && (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'100%'}
          >
            <Typography
              sx={{
                fontSize: 25,
                fontWeight: '600',
              }}
            >
              No Data
            </Typography>
          </Box>
        )}

        {!fetchingCategories && (
          <>
            <Box display={'flex'} flexDirection={'row'} width={'100%'}>
              {categories.map((category: Category) => {
                return (
                  <Button
                    key={category.id}
                    id={category.id}
                    variant="outlined"
                    onClick={handleSelectCategory}
                    size="large"
                    sx={{
                      flex: 1,
                      textTransform: 'none',
                      borderRadius: '5px',
                      fontWeight: '600',
                      ...(selectedCategory?.id === category.id && {
                        backgroundColor: '#ffffff',
                        color: '#000000',
                      }),
                    }}
                  >
                    {capitalizeSentence(category.name)}
                  </Button>
                );
              })}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 3,
                mt: 5,
                justifyContent: { xs: 'center', sm: 'start' },
                px: 1,
              }}
            >
              {selectedCategory?.items?.items.map((item: Item | null) => {
                if (!item) return;
                return (
                  <Box
                    sx={{
                      cursor: isBuying ? 'default' : 'pointer',
                    }}
                    onClick={() => {
                      if (!isBuying) {
                        handleAddToCheckout(item);
                      }
                    }}
                  >
                    {/* <Box
                      sx={{
                        border: '1px solid white',
                        borderRadius: '10px',
                        px: { xs: 8, sm: 5 },
                        py: 2,
                      }}
                    >
                      <Box
                        component={'img'}
                        src={`${item.image}`}
                        alt={item.name}
                        sx={{ width: '5rem', height: '8rem' }}
                      />
                    </Box> */}
                    <Box
                      sx={{
                        borderRadius: '15px',
                        backgroundColor: '#222222',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 1,
                      }}
                    >
                      <Typography>{capitalizeSentence(item.name)}</Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Box>
    </Grid>
  );
}
