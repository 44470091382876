import { LOCAL_STORAGE } from '../constants/enums';
import { UserConcepts } from '../models/GQL_API';
import { IBookingsSyncDuration } from '../models/app';
import { formatDate } from './utils';

export const getBookingDateRange = (
  userConcepts: UserConcepts,
): IBookingsSyncDuration => {
  const bookingSyncDuration: string | null = localStorage.getItem(
    LOCAL_STORAGE.BOOKING_SYNC_DURATION,
  );

  if (bookingSyncDuration) {
    const syncDuration: string[] = JSON.parse(bookingSyncDuration);

    const fromDate = new Date(syncDuration[0]);
    const toDate = new Date(syncDuration[1]);

    return { fromDate, toDate };
  } else {
    let fromDate = new Date();
    let toDate = new Date();

    if (
      (userConcepts &&
        userConcepts.concepts &&
        userConcepts.concepts!.length === 0) ||
      (userConcepts &&
        userConcepts.concepts &&
        userConcepts.concepts!.length === 1)
    ) {
      fromDate.setDate(fromDate.getDate() - 13);
      toDate.setDate(toDate.getDate() + 17);
    } else {
      fromDate.setDate(fromDate.getDate() - 3);
      toDate.setDate(toDate.getDate() + 7);
    }

    return { fromDate, toDate };
  }
};

export const setBookingDateRange = (fromDate: Date, toDate: Date): void => {
  localStorage.setItem(
    LOCAL_STORAGE.BOOKING_SYNC_DURATION,
    JSON.stringify([formatDate(fromDate), formatDate(toDate)]),
  );
};
