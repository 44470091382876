import { generateClient } from '@aws-amplify/api';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  cashlessTopUpByDeletedCustom,
  cashlessTopUpByEventIdCustom,
} from '../constants/customQueries';
import {
  CashlessType,
  PaymentMethod,
  PaymentOperation,
  cashlessTopUpStatus,
  paymentChannel,
} from '../constants/enums';
import { createCashlessTopUp, updateCashlessTopUp } from '../graphql/mutations';
import { cashlessTopUpByGuestID, getCashlessTopUp } from '../graphql/queries';
import { checkInternet } from '../helpers/checkInternet';
import {
  CashlessTopUpGetVariables,
  CashlessTopUpListingVariables,
  CashlessTopUpStatisticsByEventParams,
  CashlessTopUpStatisticsByEventResult,
  CashlessTopUpUpdateVariables,
  CreateCashlessTopUpVariables,
  FetchDashboardStatisticsResult,
  HeadCell,
  RecentGuest,
} from '../models/app';
import {
  setFilter,
  setListing,
  setNextToken,
} from '../store/ducks/cashlessTopups';
import { setModalOpen } from '../store/ducks/server';
import {
  Booking,
  CashlessTopUp,
  CreateCashlessTopUpInput,
  Guest,
  ModelSortDirection,
  UpdateCashlessTopUpInput,
} from './../models/GQL_API';
import useApp from './useApp';
import useBooking from './useBooking';
import useInvitation from './useInvitaion';

const client = generateClient();
const useCashlessTopups = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const cashlessTopupsListing = useSelector(
    (state: any) => state.cashlessTopups.listing,
  );
  const cashlessTopupFilter = useSelector(
    (state: any) => state.cashlessTopups.filter,
  );
  const serverAddress = useSelector((state: any) => state.server.serverAddress);
  const serverStatus = useSelector((state: any) => state.server.serverStatus);
  const {
    bookingsFetchBookingsByGuestId,
    bookingsSortUserUpcomingEventsBookings,
    bookingsUpdate,
  } = useBooking('bookings', 'booking');

  const { invitationsUpdate } = useInvitation('invitations', 'invitation');

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]['nextToken'],
  );

  async function fetch(params: CashlessTopUpListingVariables) {
    try {
      const {
        searchText,
        limit,
        generalFilters,
        guestId,
        eventFilter,
        operationTypeFilter,
        paymentChannelFilter,
        adminIdFilter,
        bookedTicketTypeFilter,
      } = params;
      const filter: any = {
        // deleted: { eq: '0' },
      };
      const createdAtFilter: any = {};
      filter.or = [];
      filter.and = [];
      if (searchText.length > 0) {
        if (/[a-zA-Z]/.test(searchText)) {
          filter.or.push({
            paymentChannel: { contains: searchText.toLowerCase() },
          });
          filter.or.push({
            operation: { contains: searchText.toLowerCase() },
          });
          filter.or.push({ eventName: { contains: searchText.toLowerCase() } });
          filter.or.push({
            ticketType: { contains: searchText.toLowerCase() },
          });
          filter.or.push({ guestName: { contains: searchText.toLowerCase() } });
          filter.or.push({
            guestEmail: { contains: searchText.toLowerCase() },
          });
          filter.or.push({
            type: { contains: searchText.toLowerCase() },
          });
        } else if (/[0-9]/.test(searchText)) {
          filter.or.push({ amount: { eq: searchText } });
          filter.or.push({ availableBalance: { eq: searchText } });
          filter.or.push({ guestPhoneNumber: { contains: searchText } });
        }
      }

      if (eventFilter) {
        filter.or.push({ eventId: { contains: eventFilter } });
      }

      if (operationTypeFilter) {
        filter.or.push({ operation: { contains: operationTypeFilter } });
      }

      if (paymentChannelFilter) {
        filter.or.push({ paymentChannel: { contains: paymentChannelFilter } });
      }

      if (adminIdFilter) {
        filter.and.push({ createdByID: { eq: adminIdFilter } });
      }

      if (bookedTicketTypeFilter) {
        filter.and.push({ type: { eq: bookedTicketTypeFilter } });
      }

      if (generalFilters?.fromDate && generalFilters.toDate) {
        const toDatePlusOneDay = new Date(generalFilters.toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        createdAtFilter.between = [
          new Date(generalFilters.fromDate).toISOString(),
          new Date(toDatePlusOneDay).toISOString(),
        ];
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const variables: any = {
        filter,
        limit: 100000,
        nextToken: nextToken,
        sortDirection: 'DESC',
        guestId,
      };

      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }

      const groupsList: any = await client.graphql<CashlessTopUp>({
        query: cashlessTopUpByGuestID,
        variables: variables,
        authMode: 'userPool',
      });
      const currentNextToken = groupsList.data.cashlessTopUpByGuestID.nextToken;
      const listing = groupsList.data.cashlessTopUpByGuestID.items;
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAllSortedByDate(params: CashlessTopUpListingVariables) {
    try {
      const {
        searchText,
        limit,
        generalFilters,
        guestId,
        eventFilter,
        operationTypeFilter,
        paymentChannelFilter,
        adminIdFilter,
        bookedTicketTypeFilter,
      } = params;
      const filter: any = {
        // deleted: { eq: '0' },
      };
      const createdAtFilter: any = {};
      filter.or = [];
      filter.and = [];

      if (searchText.length > 0) {
        if (/[a-zA-Z]/.test(searchText)) {
          filter.or.push({
            paymentChannel: { contains: searchText.toLowerCase() },
          });
          filter.or.push({
            operation: { contains: searchText.toLowerCase() },
          });
          filter.or.push({ eventName: { contains: searchText.toLowerCase() } });
          filter.or.push({
            ticketType: { contains: searchText.toLowerCase() },
          });
          filter.or.push({ guestName: { contains: searchText.toLowerCase() } });
          filter.or.push({
            guestEmail: { contains: searchText.toLowerCase() },
          });
          filter.or.push({
            type: { contains: searchText.toLowerCase() },
          });
        } else if (/[0-9]/.test(searchText)) {
          filter.or.push({ amount: { eq: searchText } });
          filter.or.push({ availableBalance: { eq: searchText } });
          filter.or.push({ guestPhoneNumber: { contains: searchText } });
        }
      }

      if (eventFilter) {
        filter.and.push({ eventId: { contains: eventFilter } });
      }

      if (paymentChannelFilter) {
        filter.and.push({ paymentChannel: { eq: paymentChannelFilter } });
      }

      if (adminIdFilter) {
        filter.and.push({ createdByID: { eq: adminIdFilter } });
      }

      if (bookedTicketTypeFilter) {
        filter.and.push({ type: { eq: bookedTicketTypeFilter } });
      }

      if (generalFilters?.fromDate && generalFilters.toDate) {
        const fromDate = new Date(generalFilters.fromDate);
        const toDate = new Date(generalFilters.toDate);

        // Increment `toDate` by one day
        toDate.setDate(toDate.getDate() + 2);

        // Set hours, minutes, seconds, and milliseconds to zero for precise filtering
        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(0, 0, 0, 0);

        // Adjust `createdAtFilter.between` to use ISO strings of fromDate and toDate
        createdAtFilter.between = [
          fromDate.toISOString(),
          toDate.toISOString(),
        ];
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const variables: any = {
        filter,
        limit: 100000,
        // nextToken: nextToken,
        sortDirection: 'DESC',
        deleted: '0',
      };

      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }
      let internet = false;
      if (!serverStatus) {
        internet = await checkInternet();
        if (!internet) {
          dispatch(setModalOpen(true));
          return [];
        }
      } else {
        // console.log({ searchText });
        const storedSession = localStorage.getItem('sessionStored');
        let parsedStoredSession: any = '';
        if (storedSession) {
          parsedStoredSession = JSON.parse(storedSession);
        }
        const res = await axios.post(
          serverAddress + '/topup/list?s=' + searchText,
          {
            createdByID: parsedStoredSession.sub,
          },
        );
        const data = res.data.data.data;
        return data;
      }
      const groupsList: any = await client.graphql<CashlessTopUp>({
        query: cashlessTopUpByDeletedCustom,
        variables: variables,
        authMode: 'userPool',
      });
      let currentNextToken = groupsList.data.cashlessTopUpByDeleted.nextToken;
      let listing = groupsList.data.cashlessTopUpByDeleted.items;
      while (currentNextToken) {
        variables.nextToken = currentNextToken;
        const response: any = await client.graphql<CashlessTopUp>({
          query: cashlessTopUpByDeletedCustom,
          variables: variables,
          authMode: 'userPool',
        });
        currentNextToken = response.data.cashlessTopUpByDeleted.nextToken;
        listing = listing.concat(response.data.cashlessTopUpByDeleted.items);
      }
      // dispatch(setListing(listing));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchSortedByDate(params: CashlessTopUpListingVariables) {
    try {
      const {
        searchText,
        limit,
        nextToken,
        generalFilters,
        guestId,
        eventFilter,
        operationTypeFilter,
        paymentChannelFilter,
        adminIdFilter,
        bookedTicketTypeFilter,
      } = params;

      const filter: any = {};
      const createdAtFilter: any = {};
      filter.or = [];
      filter.and = [];
      let requestNextToken = nextToken;

      if (searchText.length > 0) {
        if (/[a-zA-Z]/.test(searchText)) {
          filter.or.push({
            paymentChannel: { contains: searchText.toLowerCase() },
          });
          filter.or.push({
            operation: { contains: searchText.toLowerCase() },
          });
          filter.or.push({ eventName: { contains: searchText.toLowerCase() } });
          filter.or.push({
            ticketType: { contains: searchText.toLowerCase() },
          });
          filter.or.push({ guestName: { contains: searchText.toLowerCase() } });
          filter.or.push({
            guestEmail: { contains: searchText.toLowerCase() },
          });
          filter.or.push({
            type: { contains: searchText.toLowerCase() },
          });
        } else if (/[0-9]/.test(searchText)) {
          filter.or.push({ amount: { eq: searchText } });
          filter.or.push({ availableBalance: { eq: searchText } });
          filter.or.push({ guestPhoneNumber: { contains: searchText } });
        }
      }

      if (eventFilter) {
        filter.and.push({ eventId: { eq: eventFilter } });
      }

      if (paymentChannelFilter) {
        filter.and.push({ paymentChannel: { eq: paymentChannelFilter } });
      }

      if (adminIdFilter) {
        filter.and.push({ createdByID: { eq: adminIdFilter } });
      }

      if (bookedTicketTypeFilter) {
        filter.and.push({ type: { eq: bookedTicketTypeFilter } });
      }

      if (generalFilters?.fromDate && generalFilters.toDate) {
        const fromDate = new Date(generalFilters.fromDate);
        const toDate = new Date(generalFilters.toDate);

        // Increment `toDate` by one day
        toDate.setDate(toDate.getDate() + 2);

        // Set hours, minutes, seconds, and milliseconds to zero for precise filtering
        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(0, 0, 0, 0);

        // Adjust `createdAtFilter.between` to use ISO strings of fromDate and toDate
        createdAtFilter.between = [
          fromDate.toISOString(),
          toDate.toISOString(),
        ];
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      if (
        cashlessTopupFilter &&
        cashlessTopupFilter.toString() !==
          [
            JSON.stringify(generalFilters),
            eventFilter,
            paymentChannelFilter,
            adminIdFilter,
            bookedTicketTypeFilter,
            searchText,
            limit,
            searchText,
          ].toString()
      ) {
        requestNextToken = null;
        // console.log('changed filters');
      }

      const variables: any = {
        filter,
        limit: 100000,
        sortDirection: 'DESC',
        deleted: '0',
      };

      if (requestNextToken) {
        variables.nextToken = requestNextToken;
      }

      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }

      const response: any = await client.graphql<CashlessTopUp>({
        query: cashlessTopUpByDeletedCustom,
        variables: variables,
        authMode: 'userPool',
      });
      let currentNextToken = response.data.cashlessTopUpByDeleted.nextToken;
      let listing = response.data.cashlessTopUpByDeleted.items;

      dispatch(
        setFilter([
          JSON.stringify(generalFilters),
          eventFilter,
          paymentChannelFilter,
          adminIdFilter,
          bookedTicketTypeFilter,
          searchText,
          limit,
          searchText,
        ]),
      );

      return { listing, nextToken: currentNextToken };
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchTopupsStatisticsByEvent(
    params: CashlessTopUpStatisticsByEventParams,
  ) {
    const statisticsResult: CashlessTopUpStatisticsByEventResult = {
      totalRevenue: 0,
      cash: 0,
      visa: 0,
      complementary: 0,
    };
    try {
      // fetch all topups by event id
      const allEventTopups: CashlessTopUp[] =
        await fetchAllTopUpsByEventId(params);

      for (const topUp of allEventTopups) {
        statisticsResult.totalRevenue += topUp.amount;

        if (topUp.paymentMethod === PaymentMethod.CASH) {
          statisticsResult.cash += topUp.amount;
        } else if (topUp.paymentMethod === PaymentMethod.VISA) {
          statisticsResult.visa += topUp.amount;
        } else {
          statisticsResult.complementary += topUp.amount;
        }
      }

      return statisticsResult;
    } catch (err) {
      console.log({ err });
      return statisticsResult;
    }
  }

  async function fetchAllTopUpsByEventId(
    params: CashlessTopUpStatisticsByEventParams,
  ) {
    try {
      const { eventId, adminId } = params;
      if (!eventId) return [];
      const filter: any = { deleted: { eq: '0' }, isVoided: { ne: '1' } };
      if (adminId) {
        filter.createdByID = { eq: adminId };
      }
      let allListing: CashlessTopUp[] = [];
      let nextToken = undefined;

      do {
        const variables = {
          filter,
          sortDirection: ModelSortDirection.DESC,
          limit: 100000,
          nextToken,
          eventId,
        };
        const response: any = await client.graphql<CashlessTopUp>({
          query: cashlessTopUpByEventIdCustom,
          variables: variables,
          authMode: 'userPool',
        });
        allListing.push(...response.data.cashlessTopUpByEventID.items);
        nextToken = response.data.cashlessTopUpByEventID.nextToken;
      } while (nextToken);

      return allListing;
    } catch (err) {
      console.log({ err });
      return [];
    }
  }

  const getUserOrdersCount = async (
    guestPhoneNumber: string,
    cashlessTopupsList: any,
  ) => {
    let operationsCount = 0;
    let internet = false;
    if (!serverStatus) {
      internet = await checkInternet();
    }
    if (!internet) {
      if (!serverAddress) {
        dispatch(setModalOpen(true));
      }
      for (const cashlessTopup of cashlessTopupsList) {
        if (cashlessTopup.guestPhoneNumber === guestPhoneNumber) {
          operationsCount++;
        }
      }
      return operationsCount;
    }
    for (const cashlessTopup of cashlessTopupsList) {
      if (cashlessTopup.guest.phone_number === guestPhoneNumber) {
        operationsCount++;
      }
    }
    return operationsCount;
  };

  const calculatePercentageChange = (
    todayPerformanceTotalAmount: number,
    yesterdayPerformanceTotalAmount: number,
  ): number => {
    if (yesterdayPerformanceTotalAmount === 0) {
      // Handle division by zero scenario (avoiding NaN or infinite result)
      if (todayPerformanceTotalAmount === 0) {
        return 0; // If both values are zero, consider it no change
      } else {
        return 100; // Consider infinite percentage change if yesterday's value is zero
      }
    }
    // console.log(todayPerformanceTotalAmount);
    // console.log(yesterdayPerformanceTotalAmount);
    // console.log('calculatePercentageChange');

    // Calculate percentage change
    const percentageChange =
      ((todayPerformanceTotalAmount - yesterdayPerformanceTotalAmount) /
        yesterdayPerformanceTotalAmount) *
      100;

    return percentageChange;
  };

  const fetchDashboardStatistics = async (
    cashlessTopupsList: any[],
    yesterdayPerformance: number,
  ) => {
    const statistics: FetchDashboardStatisticsResult = {
      recentGuests: [],
      todayPerformance: 0,
      todayPerformancePercentageWithYesterday: 0,
      totalTransactions: 0,
      successfullTransactions: 0,
      successfullCash: 0,
      successfullVisa: 0,
      successfullComplementary: 0,
      voidedTransactions: 0,
    };

    if (!cashlessTopupsList?.length) return statistics;
    let internet = false;
    if (!serverStatus) {
      internet = await checkInternet();
    }
    if (!internet) {
      if (!serverAddress) {
        dispatch(setModalOpen(true));
      }
      const last4Users: any[] = [];
      // loop over the cashless list untill we get the last 4 users
      for (
        let i = 0;
        i < cashlessTopupsList.length && last4Users.length < 4;
        i++
      ) {
        const user = cashlessTopupsList[i].guestId!;
        const userExists = last4Users.find(
          (existingUser: any) => user === existingUser.id,
        );
        console.log({ userNoInternedStatistics: user });
        if (!userExists && user && user !== '0') {
          last4Users.push({
            id: cashlessTopupsList[i].guestId,
            name: cashlessTopupsList[i].guestName,
            guest_avatar: '',
          });
        }
      }

      // loop over the cashless list again but all the list
      for (const cashlessTopup of cashlessTopupsList) {
        // if the guest is in the last 4 guests increment his total
        const user: any = cashlessTopup.guestId;
        const userExists = last4Users.find(
          (existingUser: any) => user === existingUser.id,
        );
        if (userExists && user && user !== '0') {
          const recentGuestIdx = statistics.recentGuests.findIndex(
            (recentGuest: RecentGuest) => recentGuest.id === user,
          );
          if ((!recentGuestIdx && recentGuestIdx !== 0) || recentGuestIdx < 0) {
            statistics.recentGuests.push({
              id: cashlessTopup.guestId,
              image: cashlessTopup.guestImage ?? '',
              name: cashlessTopup.guestName ?? '',
              totalChargedToday: cashlessTopup.amount,
            });
          } else if (user && user !== '0') {
            const recentGuest = statistics.recentGuests[recentGuestIdx];
            recentGuest.totalChargedToday += cashlessTopup.amount;
            statistics.recentGuests[recentGuestIdx] = recentGuest;
          }
        }

        // increment todays performance
        statistics.todayPerformance += cashlessTopup.amount;
        // increment total transactions
        statistics.totalTransactions += 1;
        // increment success or fail based on status
        if (cashlessTopup.status === cashlessTopUpStatus.SUCCESS) {
          statistics.successfullTransactions += 1;
          if (cashlessTopup.paymentMethod === PaymentMethod.CASH) {
            statistics.successfullCash += 1;
          } else if (cashlessTopup.paymentMethod === PaymentMethod.VISA) {
            statistics.successfullVisa += 1;
          } else if (
            cashlessTopup.paymentMethod === PaymentMethod.COMPLEMENTARY
          ) {
            statistics.successfullComplementary += 1;
          }
        } else if (cashlessTopup.status === cashlessTopUpStatus.VOID) {
          statistics.voidedTransactions += 1;
          statistics.todayPerformance -= cashlessTopup.amount;
        }
        // loop ends
      }
      // console.log({ statistics });
    } else {
      const last4Users: Guest[] = [];
      // loop over the cashless list untill we get the last 4 users
      for (
        let i = 0;
        i < cashlessTopupsList.length && last4Users.length < 4;
        i++
      ) {
        const user = cashlessTopupsList[i].guest!;
        const userExists = last4Users.find(
          (existingUser: Guest) => user.id === existingUser.id,
        );
        if (!userExists && user) {
          last4Users.push(user);
        }
      }

      // loop over the cashless list again but all the list
      for (const cashlessTopup of cashlessTopupsList) {
        // if the guest is in the last 4 guests increment his total
        const user: Guest = cashlessTopup.guest;
        console.log({ user });
        const userExists = !user
          ? undefined
          : last4Users.find(
              (existingUser: Guest) => user.id === existingUser.id,
            );
        console.log({ userExists });
        if (userExists) {
          const recentGuestIdx = statistics.recentGuests.findIndex(
            (recentGuest: RecentGuest) => recentGuest.id === user.id,
          );
          if ((!recentGuestIdx && recentGuestIdx !== 0) || recentGuestIdx < 0) {
            statistics.recentGuests.push({
              id: user.id,
              image: user.guest_avatar ?? '',
              name: user.name ?? '',
              totalChargedToday: cashlessTopup.amount,
            });
          } else {
            const recentGuest = statistics.recentGuests[recentGuestIdx];
            recentGuest.totalChargedToday += cashlessTopup.amount;
            statistics.recentGuests[recentGuestIdx] = recentGuest;
          }
        }

        // increment todays performance
        statistics.todayPerformance += cashlessTopup.amount;
        // increment total transactions
        statistics.totalTransactions += 1;
        // increment success or fail based on status
        if (cashlessTopup.status === cashlessTopUpStatus.SUCCESS) {
          statistics.successfullTransactions += 1;
          if (cashlessTopup.paymentMethod === PaymentMethod.CASH) {
            statistics.successfullCash += 1;
          } else if (cashlessTopup.paymentMethod === PaymentMethod.VISA) {
            statistics.successfullVisa += 1;
          } else if (
            cashlessTopup.paymentMethod === PaymentMethod.COMPLEMENTARY
          ) {
            statistics.successfullComplementary += 1;
          }
        } else if (cashlessTopup.status === cashlessTopUpStatus.VOID) {
          statistics.voidedTransactions += 1;
        }
        // loop ends
      }

      // calc the percentage of performance based on yesterdayPerformance and today performance
      statistics.todayPerformancePercentageWithYesterday =
        calculatePercentageChange(
          statistics.todayPerformance,
          yesterdayPerformance,
        );
    }
    return statistics;
  };

  const fetchUserPaidBookings = async (userId: string) => {
    try {
      const filter = {
        deleted: { eq: '0' },
        isPaid: { eq: true },
      };

      return await bookingsFetchBookingsByGuestId(userId, filter);
    } catch (err) {
      throw err;
    }
  };

  const userHasUpcomingEventPaidBooking = (userPaidBookings: Booking[]) => {
    if (!userPaidBookings.length) return false;
    const userUpcomingEventsBookings = userPaidBookings.filter((booking) => {
      const currentDate = new Date().toISOString().split('T')[0];
      const event = booking.event!;
      const eventStartDate = new Date(event.startDate!)
        .toISOString()
        .split('T')[0];

      return currentDate <= eventStartDate;
    });
    return bookingsSortUserUpcomingEventsBookings(
      userUpcomingEventsBookings,
    )[0];
  };

  const userCanChargeBalance = async (userId: string) => {
    try {
      // fetch user bookings that are in the future
      const userPaidBookings = await fetchUserPaidBookings(userId);

      // check that the user has at least one booking that is approved and is in the future
      const userUpcomingEventBooking =
        userHasUpcomingEventPaidBooking(userPaidBookings);
      if (!userUpcomingEventBooking)
        throw new Error(
          'The Guest must have at least one upcoming event paid ticket in order to be able to charge his/her balance',
        );

      return userUpcomingEventBooking;
    } catch (err) {
      console.log({ err });
      throw err;
    }
  };

  const getUserUpcomingBookingBalance = async (userId: string) => {
    try {
      let internet = false;
      if (!serverStatus) {
        internet = await checkInternet();
      }
      if (!internet) {
        if (!serverAddress) {
          dispatch(setModalOpen(true));
        }
        const booking = await axios.get(
          serverAddress + '/booking/user?id=' + userId,
        );
        const data = booking.data.data.data;
        return data.balance;
      }
      const userUpcomingEventBooking = await userCanChargeBalance(userId);
      return userUpcomingEventBooking.balance || 0;
    } catch (err) {
      console.log({ err });
      return 0;
    }
  };

  async function create(params: CreateCashlessTopUpVariables) {
    const { userID, userName, data } = params;
    const bookedTicketType = data.type;
    if (!data.amount || data.amount < 0)
      throw new Error('the amount must be greater than 0');

    const guestId = data.guestId;
    let internet = false;
    if (!serverStatus) {
      internet = await checkInternet();
      if (!internet) {
        dispatch(setModalOpen(true));
        return {};
      }
    } else {
      const topup = await axios.post(serverAddress + '/topup', {
        amount: data.amount,
        paymentMethod: data.paymentMethod,
        guestId: guestId,
        createdByID: userID,
        createdByName: userName,
        invitationId:
          bookedTicketType === CashlessType.INVITATION ? data.booking.id : '',
      });
      const res = topup.data.data.data;
      const createdCashlessTopUp = res;
      dispatch(setListing([createdCashlessTopUp, ...cashlessTopupsListing]));
      showConfirm(`Balance topped up successfully.`);
      return res;
    }

    // check if user can add charge to his balance
    let userUpcomingEventBooking: any;
    let bookingId: any;
    if (!data.booking) {
      userUpcomingEventBooking = await userCanChargeBalance(guestId);
      bookingId = userUpcomingEventBooking.id;
    } else {
      bookingId = data.booking.id;
      userUpcomingEventBooking = data.booking;
    }
    const now = new Date().getTime();
    const eventEndDate = new Date(
      userUpcomingEventBooking.event.endDate,
    ).getTime();
    const eventStartDate = new Date(
      userUpcomingEventBooking.event.startDate,
    ).getTime();
    // console.log(
    //   userUpcomingEventBooking.event.startDate,
    //   new Date().toISOString(),
    // );

    // console.log({
    //   before: now <= eventEndDate,
    //   after: now >= eventStartDate,
    //   eventStartDate,
    //   eventEndDate,
    // });

    if (now <= eventEndDate && now >= eventStartDate) {
      dispatch(setModalOpen(true));
      showError(
        'You have to setup the local server to be able to add this topup',
      );
      return;
    }
    if (now >= eventStartDate) {
      showError("You can't charge this ticket.");
      return;
    }
    const eventId =
      userUpcomingEventBooking.bookingEventId ??
      userUpcomingEventBooking.event.id;
    const bookingBalance = userUpcomingEventBooking.balance ?? 0;

    // update booking balance
    if (bookedTicketType === CashlessType.BOOKING) {
      await bookingsUpdate({
        id: bookingId,
        data: { balance: bookingBalance + data.amount },
      });
    } else if (bookedTicketType === CashlessType.INVITATION) {
      await invitationsUpdate({
        id: bookingId,
        data: { balance: bookingBalance + data.amount },
      });
    }

    try {
      const createInput: CreateCashlessTopUpInput = {
        operation: PaymentOperation.CHARGE,
        amount: data.amount,
        availableBalance: bookingBalance + data.amount,
        paymentChannel: paymentChannel.ONSITE,
        paymentMethod: data.paymentMethod,
        guestId: guestId ?? '0',
        bookingId:
          bookedTicketType === CashlessType.BOOKING ? bookingId : undefined,
        invitationId:
          bookedTicketType === CashlessType.INVITATION ? bookingId : undefined,
        eventId: eventId,
        status: cashlessTopUpStatus.SUCCESS,
        eventName: userUpcomingEventBooking.event.name,
        ticketType: userUpcomingEventBooking.eventTicket.type,
        guestName:
          bookedTicketType === CashlessType.BOOKING
            ? userUpcomingEventBooking.guest!.name
            : 'invitation guest',
        guestPhoneNumber:
          bookedTicketType === CashlessType.BOOKING
            ? userUpcomingEventBooking.guest.phone_number
            : userUpcomingEventBooking.phone_number,
        guestEmail:
          bookedTicketType === CashlessType.BOOKING
            ? userUpcomingEventBooking.guest.email
            : userUpcomingEventBooking.email,
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
        type: bookedTicketType,
      };

      const res = await client.graphql({
        query: createCashlessTopUp,
        variables: { input: createInput },
        authMode: 'userPool',
      });
      const createdCashlessTopUp = res.data.createCashlessTopUp;
      dispatch(setListing([createdCashlessTopUp, ...cashlessTopupsListing]));
      showConfirm(`Balance topped up successfully.`);
      return createdCashlessTopUp;
    } catch (err) {
      // showError(err);
      throw err;
    }
  }
  async function get(params: CashlessTopUpGetVariables) {
    try {
      const { id, listing } = params;
      let single: CashlessTopUp | undefined;
      if (listing && listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }
      if (single === undefined) {
        const listing: any = await client.graphql<Guest>({
          query: getCashlessTopUp,
          variables: { id },
          authMode: 'userPool',
        });
        single = listing.data.getCashlessTopUp;
      }
      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function voidCashlessTopUp(params: CashlessTopUpUpdateVariables) {
    try {
      let internet = false;
      if (!serverStatus) {
        internet = await checkInternet();
        if (!internet) {
          if (!serverAddress) {
            dispatch(setModalOpen(true));
          }
        }
      } else {
        const res = await axios.post(
          serverAddress + '/topup/void/' + params.id,
        );
        const data = res.data.data.data;
        return data;
      }
      params.listing = [];
      const original: any = await get(params);
      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }
      if (original.status === cashlessTopUpStatus.VOID) {
        showError('This cashless top up was already voided');
        return;
      }
      const createdAtTime = new Date(original.createdAt);
      const currentTime = new Date();

      // Calculate time difference in milliseconds
      const timeDifference = currentTime.getTime() - createdAtTime.getTime();

      // Check if more than 30 minute (1800000 milliseconds) has passed
      if (timeDifference > 1800000) {
        showError("You can't void this cashless top up as a minute has passed");
        return;
      }

      if (original.type === CashlessType.BOOKING) {
        if (original.booking.balance - original.amount < 0) {
          throw new Error(
            "You can't void this cashless top up as the user doesn't have enough balance",
          );
        }
      } else if (original.type === CashlessType.INVITATION) {
        if (original.invitation.balance - original.amount < 0) {
          throw new Error(
            "You can't void this cashless top up as the user doesn't have enough balance",
          );
        }
      }

      const updateInput: UpdateCashlessTopUpInput = {
        id: original.id,
        status: cashlessTopUpStatus.VOID,
        isVoided: '1',
        _version: original._version,
      };

      if (original.type === CashlessType.BOOKING) {
        await bookingsUpdate({
          id: original.booking.id,
          data: { balance: original.booking.balance - original.amount },
        });
      } else if (original.type === CashlessType.INVITATION) {
        await invitationsUpdate({
          id: original.invitationId,
          data: { balance: original.invitation.balance - original.amount },
        });
      }

      const res = await client.graphql({
        query: updateCashlessTopUp,
        variables: { input: updateInput },
        authMode: 'userPool',
      });

      const updatedCashlessTopUp = res.data.updateCashlessTopUp;

      const updatedCashlessTopUpListing = [...cashlessTopupsListing];
      const updatedCashlessTopUpIdx = updatedCashlessTopUpListing.find(
        (cashlessTopup: CashlessTopUp) =>
          cashlessTopup.id === updatedCashlessTopUp.id,
      );
      if (updatedCashlessTopUpIdx >= 0) {
        updatedCashlessTopUpListing[updatedCashlessTopUpIdx] =
          updatedCashlessTopUp;
        dispatch(setListing(updatedCashlessTopUpListing));
      }

      showConfirm(`${singleName} has been voided successfully`);
      return updatedCashlessTopUp;
    } catch (err: any) {
      console.log({ err });
      showError(err?.response?.data?.message ? err.response.data.message : err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'guest',
      numeric: false,
      disablePadding: false,
      label: 'Guest Name',
    },
    {
      id: 'guest',
      numeric: false,
      disablePadding: false,
      label: 'Guest Phone',
    },
    {
      id: 'guest',
      numeric: false,
      disablePadding: false,
      label: 'Guest Email',
    },
    {
      id: 'event',
      numeric: false,
      disablePadding: false,
      label: 'Event Name',
    },
    {
      id: 'booking',
      numeric: false,
      disablePadding: false,
      label: 'Ticket Type',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
    },
    {
      id: 'operation',
      numeric: false,
      disablePadding: false,
      label: 'Operation',
    },
    {
      id: 'amount',
      numeric: false,
      disablePadding: false,
      label: 'Amount',
    },
    {
      id: 'availableBalance',
      numeric: false,
      disablePadding: false,
      label: 'Available Balance',
    },
    {
      id: 'paymentChannel',
      numeric: false,
      disablePadding: false,
      label: 'Payment Channel',
    },
    // {
    //   id: 'createdAt',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Date',
    // },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'paymentMethod',
      numeric: true,
      disablePadding: false,
      label: 'Payment Method',
    },
  ];
  const dataCells: readonly string[] = [
    'event',
    'booking',
    'operation',
    'amount',
    'availableBalance',
    'paymentChannel',
    'createdAt',
  ];
  const api: any = {};

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Create`] = create;
  api[`${listingName}Void`] = voidCashlessTopUp;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAllSortedByDate`] = fetchAllSortedByDate;
  api[`${listingName}FetchSortedByDate`] = fetchSortedByDate;
  api[`${listingName}FetchStatistics`] = fetchDashboardStatistics;
  api[`${listingName}FetchTopupsStatisticsByEvent`] =
    fetchTopupsStatisticsByEvent;
  api[`${listingName}GetUserOrdersCount`] = getUserOrdersCount;
  api[`${listingName}GetUserUpcomingBookingBalance`] =
    getUserUpcomingBookingBalance;
  api[`${listingName}ChangeListing`] = (listing: CashlessTopUp[]) =>
    dispatch(setListing(listing));
  return api;
};
export default useCashlessTopups;
