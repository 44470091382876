import { FC, ReactElement, useEffect } from 'react';

import { Pages } from '../../constants/enums';
import useApp from '../../hooks/useApp';
import useAdmin from '../../hooks/useAdmin';
import { useSelector } from 'react-redux';

interface Props {
  children: ReactElement;
}

const PublicRoute: FC<Props> = ({ children }) => {
  const session = useSelector((state: any) => state.app.session);
  const { navigateTo } = useApp();
  const { isAdmin } = useAdmin('admins', 'admin');

  useEffect(() => {
    if (session) {
      if (isAdmin(session)) {
        navigateTo(`/dashboard/${Pages.DASHBOARD}`);
      } else if (
        session['cognito:groups'] &&
        session['cognito:groups'][0] === 'staff'
      ) {
        navigateTo(`/dashboard/${Pages.WAITER_ORDER}`);
      } else {
        navigateTo(`/dashboard/${Pages.ADMINS}/${session['cognito:username']}`);
      }
    }

    // eslint-disable-next-line
  }, [session]);

  return <>{children}</>;
};

export default PublicRoute;
