import { createGuest, updateGuest } from './../graphql/mutations';
import {
  UpdateGuestInput,
  Guest,
  CreateGuestInput,
  CreateTimelineInput,
} from './../models/GQL_API';
import { useDispatch, useSelector } from 'react-redux';
import {
  setListing,
  setSelected,
  setNextToken,
  setPrevNextToken,
} from '../store/ducks/guest';
import {
  guestBulkTrashVariables,
  guestGetVariables,
  guestUpdateVariables,
  CreateVariables,
  ListingVariables,
  Option,
  HeadCell,
  guestListingVariables,
} from '../models/app';
import { deleteGuest } from '../graphql/mutations';
import useApp from './useApp';
import useTimeline from './useTimeline';
import {
  ByPhoneNumber,
  getGuest,
  listGuests,
  userByDeleted,
} from '../graphql/queries';
import { onCreateGuest } from '../graphql/subscriptions';
import { generateClient } from '@aws-amplify/api';
import { Pages, TimeLineForGuest, TimelineTypes } from '../constants/enums';
import { validatePhone } from '../helpers/utils';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';

const client = generateClient();
const useGuest = (listingName: string, singleName: string) => {
  let { slug } = useParams();
  const dispatch = useDispatch();
  const { showConfirm, showError, setSelectedUser } = useApp();
  const serverAddress = useSelector((state: any) => state.server.serverAddress);
  const serverStatus = useSelector((state: any) => state.server.serverStatus);
  const { timelinesCreate } = useTimeline('timelines', 'timelines');

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]['nextToken'],
  );
  const prevNextToken = useSelector(
    (state: any) => state[`${listingName}`]['prevNextToken'],
  );
  const guestListing = useSelector(
    (state: any) => state[`${listingName}`]['listing'],
  );
  const session = useSelector((state: any) => state.app.session);
  const newFirstFetch = useSelector((state: any) => state.app.firstFetch);
  // useEffect(() => {
  //   if (newFirstFetch) {
  //     dispatch(setListing([]));
  //     dispatch(setNextToken(null));
  //     console.log({ newFirstFetch });
  //   }
  // }, [newFirstFetch]);
  async function fetch(params: guestListingVariables) {
    try {
      const { searchText, limit, generalFilters } = params;
      const filter: any = {};
      filter.or = [];
      filter.and = [];
      if (searchText.length > 0) {
        filter.or.push({
          phone_number: { contains: searchText.toLowerCase() },
        });
      }
      if (generalFilters?.fromDate && generalFilters.toDate) {
        const fromDate = new Date(generalFilters.fromDate);
        fromDate.setHours(0, 0, 0, 500);
        const toDate = new Date(generalFilters.toDate);
        toDate.setHours(23, 59, 59, 999);
        generalFilters.fromDate = fromDate.toISOString();
        generalFilters.toDate = toDate.toISOString();
        filter.and.push({
          createdAt: {
            ge: new Date(generalFilters?.fromDate).toISOString(),
          },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(generalFilters.toDate).toISOString(),
          },
        });
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const groupsList: any = await client.graphql<Guest>({
        query: userByDeleted,
        // variables: { filter, limit, nextToken: nextToken, deleted: '0' },
        variables: {
          filter,
          limit: 100000,
          nextToken: nextToken,
          deleted: '0',
        },
        authMode: 'userPool',
      });
      const currentNextToken = groupsList.data.userByDeleted.nextToken;
      const responseListing = groupsList.data.userByDeleted.items;

      let listing = [...guestListing, ...responseListing];
      console.log({ newFirstFetch, listing });
      // if (responseListing?.length > 0) {
      dispatch(setListing(listing));
      // }
      dispatch(setPrevNextToken(nextToken));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAll(params: guestListingVariables) {
    try {
      const { searchText, limit, generalFilters } = params;
      let allListing: any[] = [];
      const filter: any = {
        deleted: { eq: '0' },
      };
      filter.or = [];
      filter.and = [];
      if (searchText.length > 0) {
        filter.or.push({ name: { contains: searchText.toLowerCase() } });
        filter.or.push({
          phone_number: { contains: searchText.toLowerCase() },
        });
      }
      if (generalFilters?.fromDate && generalFilters.toDate) {
        const fromDate = new Date(generalFilters.fromDate);
        fromDate.setHours(0, 0, 0, 500);
        const toDate = new Date(generalFilters.toDate);
        toDate.setHours(23, 59, 59, 999);
        generalFilters.fromDate = fromDate.toISOString();
        generalFilters.toDate = toDate.toISOString();
        filter.and.push({
          createdAt: {
            ge: new Date(generalFilters?.fromDate).toISOString(),
          },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(generalFilters.toDate).toISOString(),
          },
        });
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const groupsList: any = await client.graphql<Guest>({
        query: listGuests,
        variables: { filter, limit: 10000 },
        authMode: 'userPool',
      });
      let currentNextToken = groupsList.data.listGuests.nextToken;
      allListing = groupsList.data.listGuests.items;
      while (currentNextToken) {
        const groupsListNew: any = await client.graphql<Guest>({
          query: listGuests,
          variables: { filter, limit: 10000, nextToken: currentNextToken },
          authMode: 'userPool',
        });
        allListing = [...allListing, groupsListNew.data.listGuests.items];
        currentNextToken = groupsListNew.data.listGuests.nextToken;
      }
      return allListing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchByPhoneNumber(params: any) {
    const { phone_number } = params;
    const filter: any = {
      deleted: { eq: '0' },
    };
    try {
      const listing: any = await client.graphql({
        query: ByPhoneNumber,
        variables: { filter, phone_number, limit: 100000 },
        authMode: 'userPool',
      });
      return listing.data.ByPhoneNumber.items[0];
    } catch (err) {
      throw err;
    }
  }

  async function fetchGuestOffline(phoneNumber: string) {
    try {
      const res = await axios.get(
        serverAddress + '/booking/user?phone=' + phoneNumber,
      );
      const data = res.data.data.data;
      return data;
    } catch (err: any) {
      console.log({ err });
      if (err?.response?.status === 404) {
        throw new Error("This Guest Doesn't exist");
      } else {
        throw new Error(err?.response?.data?.message) ?? err;
      }
    }
  }

  async function fetchConnections(params: guestListingVariables) {
    const { searchText, startIndex, limit, connectionsIDs } = params;

    try {
      const filter: any = {
        deleted: { eq: '0' },
      };
      filter.or = [];
      if (searchText) filter.name = { contains: searchText.toLowerCase() };
      if (connectionsIDs && connectionsIDs.length > 0) {
        for (const connectionsID of connectionsIDs) {
          filter.or.push({ id: { eq: connectionsID } });
        }
      } else {
        return [];
      }

      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const listing = await client.graphql({
        query: listGuests,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });

      // dispatch(setListing(listing));

      return listing.data.listGuests.items;
    } catch (err: Error | any) {
      showError(err);
    }
  }

  const getName = (params: guestGetVariables) => {
    const { id, listing } = params;
    if (listing.length > 0) {
      const model = listing.find((model: Guest) => model.id === id);
      return model ? model.name : '';
    }
    return '';
  };
  async function get(params: guestGetVariables) {
    try {
      const { id, listing, getNew } = params;
      let single: Guest | undefined;
      if (listing?.length && !getNew) {
        single = listing.find((resource: any) => resource.id === id);
      }
      if (single === undefined) {
        const listing: any = await client.graphql<Guest>({
          query: getGuest,
          variables: { id },
          authMode: 'userPool',
        });
        single = listing.data.getGuest;
      }
      return single;
    } catch (err) {
      showError(err);
    }
  }
  async function create(params: CreateVariables) {
    try {
      const { userID, userName, data } = params;
      const createInput: CreateGuestInput = {
        // name: data.name.toLowerCase(),
        // name: data.name,
        guestGroupID: data.guestGroupID,
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
        last_attended_event: new Date().toISOString(),
      };
      if (data.name) createInput.name = data.name.toLowerCase();
      if (data.username) createInput.username = data.username;
      if (data.email) createInput.email = data.email;
      if (data.phone_number) createInput.phone_number = data.phone_number;
      if (data.guest_avatar) createInput.guest_avatar = data.guest_avatar;
      if (data.avg_spend) createInput.avg_spend = data.avg_spend;
      if (data.address) createInput.address = data.address;
      if (data.gender) createInput.gender = data.gender;
      if (data.birthdate) createInput.birthdate = data.birthdate;
      if (data.avg_ticket_type)
        createInput.avg_ticket_type = data.avg_ticket_type;
      if (data.isVerified) createInput.isVerified = data.isVerified;
      if (data.numberOfTickets)
        createInput.numberOfTickets = data.numberOfTickets;
      if (data.nationality) createInput.nationality = data.nationality;
      // if (data.connections) createInput.connections = data.connections;
      // if (data.last_attended_event) createInput.last_attended_event = data.last_attended_event;

      if (data.phone_number) {
        if (data.phone_number && !validatePhone(data.phone_number)) {
          throw new Error('Phone number is not valid');
        }
        const params = {
          phone_number: data.phone_number,
        };
        let userExists = await fetchByPhoneNumber(params);
        if (userExists) {
          throw new Error('Phone number already exists');
        }
      }

      const res = await client.graphql({
        query: createGuest,
        variables: { input: createInput },
        authMode: 'userPool',
      });
      showConfirm(`New ${singleName} has been created successfully`);
      return res.data.createGuest;
    } catch (err) {
      showError(err);
    }
  }
  async function update(params: guestUpdateVariables) {
    try {
      const { data, getNew } = params;
      const original: any = await get({ ...params, getNew: true });
      console.log({ original });
      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }
      // console.log();

      const updateInput: UpdateGuestInput = {
        id: original.id,
        name: data.name
          ? data.name.toLowerCase()
          : original!.name.toLowerCase(),
        username: data.username ? data.username : original!.username,
        email: data.email ? data.email : original!.email,
        phone_number: data.phone_number
          ? data.phone_number
          : original!.phone_number,
        guestGroupID: data.guestGroupID
          ? data.guestGroupID
          : original!.guestGroupID,
        guestGroupName: data.guestGroupName
          ? data.guestGroupName
          : original!.guestGroupName,
        guest_avatar: data.guest_avatar
          ? data.guest_avatar
          : original!.guest_avatar,
        avg_spend: data.avg_spend ? data.avg_spend : original!.avg_spend,
        avg_ticket_type: data.avg_ticket_type
          ? data.avg_ticket_type
          : original!.avg_ticket_type,
        numberOfTickets: data.numberOfTickets
          ? data.numberOfTickets
          : original!.numberOfTickets,
        connections: data.connections
          ? data.connections
          : original!.connections,
        // if (data.isVerified) createInput.isVerified = data.isVerified;
        isVerified:
          data.isVerified !== null && data.isVerified !== undefined
            ? data.isVerified
            : original!.isVerified,
        isBlocked:
          data.isBlocked !== null && data.isBlocked !== undefined
            ? data.isBlocked
            : original!.isBlocked,
        // isVerified: data.isVerified ? data.isVerified : original!.isVerified,
        // last_attended_event: data.last_attended_event ? data.last_attended_event : original!.last_attended_event,
        last_attended_event: new Date().toISOString(),
        gender: data.gender ? data.gender : original!.gender,
        deleted: original!.deleted,
        address: data.address ? data.address : original!.address,
        birthdate: data.birthdate ? data.birthdate : original!.birthdate,
        nationality: data.nationality
          ? data.nationality
          : original!.nationality,
        flags: data.flags ? data.flags : original!.flags,
        createdAt: original!.createdAt,
        _version: original._version,
      };

      if (data.phone_number) {
        if (data.phone_number && !validatePhone(data.phone_number)) {
          throw new Error('Phone number is not valid');
        }
        const params = {
          phone_number: data.phone_number,
        };
        let userExists = await fetchByPhoneNumber(params);
        if (userExists && userExists.id !== original.id) {
          throw new Error('Phone number already exists');
        }
      }

      let updatedGuest = await client.graphql({
        query: updateGuest,
        variables: { input: updateInput },
        authMode: 'userPool',
      });

      if (slug === Pages.GUESTS) {
        setSelectedUser(updatedGuest.data.updateGuest);
      }

      const createInput: CreateTimelineInput = {
        ...data,
        customerId: original.id,
        address: data.address,
        resource: original!,
        type: TimelineTypes.INFO,
        timelinesListing: data.timelinesListing,
        // statusesListing: data.statusesListing,
        // timeSlotsListing: data.timeSlotsListing,
      };

      const timelineParams: CreateVariables = {
        userID: session.sub,
        userName: session.name,
        data: createInput,
      };

      await timelinesCreate(timelineParams);

      showConfirm(`${singleName} has been updated successfully`);
      return updatedGuest.data.updateGuest;
    } catch (err) {
      showError(err);
    }
  }
  async function trash(params: guestGetVariables) {
    try {
      const original: any = await get(params);
      const { id, listing } = params;
      if (original) {
        const updateInput: UpdateGuestInput = {
          id: original.id,
          deleted: '1',
          name: original.name,
          createdAt: original!.createdAt,
          _version: original._version,
        };
        await client.graphql<Guest>({
          query: updateGuest,
          variables: { input: updateInput },
          authMode: 'userPool',
        });
      }
      // console.log(params);
      // console.log(listing);
      // console.log(listing.filter((model: any) => model.id !== id));
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }
  async function bulkTrash(params: guestBulkTrashVariables) {
    const { ids, listing } = params;
    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });
    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));
    return `${ids.size} ${listingName} items has been moved to trash`;
  }
  async function remove(params: guestGetVariables) {
    try {
      const { id, listing } = params;
      await client.graphql<Guest>({
        query: deleteGuest,
        variables: { id: id },
        authMode: 'userPool',
      });
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }
  async function exportAll(params: any) {
    try {
      let exportedData: Guest[] = [];
      console.log({ params });
      const data: Guest[] = await fetchAll(params);
      for (let user of data!) {
        let row: Guest = { ...user };
        exportedData.push(row);
      }
      console.log({ exportedData });
      return exportedData;
    } catch (err: any) {
      showError(err);
    }
  }
  function options(listing: Guest[]) {
    const options: Option[] = [];
    for (let option of listing) {
      options.push({ label: option.name!, value: option.id });
    }
    return options;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Guest',
    },
    {
      id: 'phone_number',
      numeric: false,
      disablePadding: false,
      label: 'Mobile No.',
    },
    {
      id: 'avg_spend',
      numeric: false,
      disablePadding: false,
      label: 'Avg. Spend',
    },
    {
      id: 'avg_ticket_type',
      numeric: false,
      disablePadding: false,
      label: 'Avg. Ticket Type',
    },
    {
      id: 'connections',
      numeric: false,
      disablePadding: false,
      label: 'Connections',
    },
    {
      id: 'last_attended_event',
      numeric: false,
      disablePadding: false,
      label: 'Last attended event',
    },
    // {
    //     id: 'createdBy',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Created By',
    // },
    // {
    //     id: 'createdAt',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Date',
    // },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];
  const dataCells: readonly string[] = [
    'name',
    'phone_number',
    'avg_spend',
    'avg_ticket_type',
    'connections',
    'last_attended_event',
  ];
  const api: any = {};

  api[`${listingName}CreateSubscription`] = onCreateGuest;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchByPhoneNumber`] = fetchByPhoneNumber;
  api[`${listingName}FetchGuestOfflineByPhone`] = fetchGuestOffline;
  api[`${listingName}FetchConnections`] = fetchConnections;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: Guest[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}PrevNextToken`] = prevNextToken;
  api[`${listingName}Listing`] = guestListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  // api[`${listingName}ClearNextToken`] = () => {console.log("aaaaaaaaaaaaaaaaaaaaaaa")};
  return api;
};
export default useGuest;
