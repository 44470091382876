import { useDispatch, useSelector } from 'react-redux';
import {
  setListing,
  setSelected,
  setNextToken,
} from '../store/ducks/ticketsScan';
import { HeadCell, ListingVariables } from '../models/app';
import useApp from './useApp';
import { generateClient } from '@aws-amplify/api';
import { ModelEventFilterInput } from '../models/GQL_API';
import { TicketsScan } from '../models';
import { listEvents, listTicketsScans } from '../graphql/queries';

const client = generateClient();
const useHostScans = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  const nextToken = useSelector((state: any) => state.ticketsScan['nextToken']);
  const ticketsScanListing = useSelector(
    (state: any) => state.ticketsScan['listing'],
  );
  const generalFilters = useSelector((state: any) => state.generalFilters);
  const selectedAdmin = useSelector((state: any) => state.admins.selected);

  async function fetch(params: ListingVariables) {
    try {
      if (!selectedAdmin) {
        return ticketsScanListing;
      }
      const { searchText, generalFilters } = params;
      const upcomingEvent = await fetchUpComingEvent();
      // console.log({ upcomingEvent: upcomingEvent });

      const filter: any = {};
      filter.or = [];
      filter.and = [];
      if (searchText.length > 0) {
        filter.or.push({ guestName: { contains: searchText.toLowerCase() } });
      }
      if (generalFilters?.fromDate && generalFilters.toDate) {
        const fromDate = new Date(generalFilters.fromDate);
        fromDate.setHours(0, 0, 0, 500);
        const toDate = new Date(generalFilters.toDate);
        toDate.setHours(23, 59, 59, 999);
        generalFilters.fromDate = fromDate.toISOString();
        generalFilters.toDate = toDate.toISOString();
        filter.and.push({
          scannedAt: {
            ge: new Date(generalFilters?.fromDate).toISOString(),
          },
        });
        filter.and.push({
          scannedAt: {
            lt: new Date(generalFilters.toDate).toISOString(),
          },
        });
      }
      if (generalFilters?.event) {
        filter.eventId = { eq: generalFilters?.event };
      }
      if (selectedAdmin.sub) {
        filter.scannedById = { eq: selectedAdmin?.sub };
      }
      if (
        (!generalFilters?.event || generalFilters?.event === '') &&
        upcomingEvent?.id
      ) {
        filter.eventId = { eq: upcomingEvent?.id };
      }
      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }
      filter.deleted = { eq: 0 };
      const groupsList: any = await client.graphql<TicketsScan>({
        query: listTicketsScans,
        variables: { filter, limit: 1000, nextToken: nextToken },
        authMode: 'userPool',
      });
      const currentNextToken = groupsList.data.listTicketsScans.nextToken;
      const responseListing = groupsList.data.listTicketsScans.items;

      let listing = [...ticketsScanListing, ...responseListing];
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchUpComingEvent() {
    try {
      const limit = 10000;
      const filter: ModelEventFilterInput = {
        deleted: { eq: '0' },
        published: {
          eq: true,
        },
        endDate: {
          gt: new Date().toISOString(),
        },
      };

      const eventList = await client.graphql({
        query: listEvents,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });
      const listing = eventList.data.listEvents.items;
      return listing[0];
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'guestName',
      numeric: false,
      disablePadding: false,
      label: 'Name / Email / Phone',
    },
    {
      id: 'scannedByName',
      numeric: false,
      disablePadding: false,
      label: 'Scanned By',
    },
    {
      id: 'ticketType',
      numeric: false,
      disablePadding: false,
      label: 'Ticket Type',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'gateName',
      numeric: false,
      disablePadding: false,
      label: 'Gate',
    },
    {
      id: 'scannedAt',
      numeric: false,
      disablePadding: false,
      label: 'Scanned At',
    },

    // {
    //   id: 'actions',
    //   numeric: true,
    //   disablePadding: false,
    //   label: '',
    // },
  ];
  const dataCells: readonly string[] = [
    'guestName',
    'scannedByName',
    'ticketType',
    'status',
    'gateName',
    'scannedAt',
  ];

  const api: any = {};
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;

  api[`${listingName}ChangeListing`] = (listing: TicketsScan[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = ticketsScanListing;
  return api;
};
export default useHostScans;
