import { CountryType } from '../models/app';

export const DEPLOYMENT_VERSION = '6';

export enum Directions {
  RTL = 'rtl',
  LTR = 'ltr',
}

export enum Languages {
  ENGLISH = 'en',
  ARABIC = 'ar',
}
export const TimeLineForGuest = 'forGuest';
export enum Pages {
  LOGIN = '',
  REGISTER = 'register',
  VERIFY = 'verify',
  FORGOT_PASSWORD = 'forget-password',
  DASHBOARD = 'overview',
  ACCOUNTS = 'accounts',
  ADMINS = 'admins',
  LANGUAGES = 'languages',
  FEATURES = 'features',
  PAGES = 'pages',
  MEDIA_LIBRARY = 'media-library',
  MESSAGES = 'messages',
  MENUS = 'menus',
  POSTS = 'posts',
  SETTINGS = 'settings',
  SLIDES = 'slides',
  COURSES = 'courses',
  MODULES = 'modules',
  QUIZZES = 'quizzes',
  QUIZ_QUESTIONS = 'quiz-questions',
  QUESTIONS = 'questions',
  QUESTIONS_TYPES = 'questions-types',
  GROUPS = 'groups',
  GROUP_SETTINGS = 'group-settings',
  INTERESTS = 'interests',
  BOOKINGS = 'bookings',
  CASHLESS = 'cashless',
  CASHLESS_ALL_TOP_UPS = 'allTopUps',
  CASHLESS_ALL_Transactions = 'allTransactions',
  BOOKINGS_LIST = 'bookings-history',
  FLAGS = 'flags',
  GUESTS = 'guests',
  GUESTS_PROFILE = 'guests-profile',
  CONCEPTS = 'concepts',
  PAYMOB = 'paymob',
  BRANCHES = 'branches',
  RESERVATION_STATUS = 'statuses',
  TIME_SLOTS = 'time-slots',
  AREAS = 'areas',
  COMMENTS = 'comments',
  PLAN_ITEMS = 'plan-items',
  TABLES = 'tables',
  TIMELINES = 'timelines',
  SERVING_AREAS = 'serving-areas',
  OBJECTS = 'objects',
  CUSTOMER_STATS = 'customerStats',
  FLOOR_EDITOR = 'floor-editor',
  SOCIAL_LINKS = 'social-links',
  CALL_CENTER = 'call-center',
  CALL_CENTER_SETTINGS = 'call-center-settings',
  BOOKINGS_TABLES = 'bookings-tables',
  RESET_PASSWORD = 'reset-password',
  NOTIFICATION_SETTINGS = 'notification-settings',
  NOTIFICATIONS = 'notifications',
  Mobile_NOTIFICATIONS = 'mobileNotifications',
  BOOKING_CONFIRMATION = 'reservations',
  ADMIN_ROLES = 'admin-roles',
  ADMIN_GROUPS = 'admin-groups',
  PAYMENT = 'Payment',
  PAYMENT_DASHBOARD = 'transactionsDashboard',
  TRANSACTIONS_REPORT = 'transactions',
  PAYMENT_STATS = 'paymentStats',
  BOOKING_GUEST = 'bookingGuests',
  Parent_Concepts = 'parentconcepts',
  // Online Ordering
  CATEGORIES = 'categories',
  MENU_ITEMS = 'menuItems',
  PRICES = 'prices',
  CHOICES = 'choices',
  CHOICE_GROUPS = 'choiceGroups',
  ORDER_STATUSES = 'orderStatuses',
  ONLINE_ORDER = 'onlineOrders',
  KPI_REPORT = 'KPIReport',
  DISPATCHERS = 'dispatchers',
  STAFF_MEMBERS = 'staffMembers',
  WAITER_ORDER = 'waiterOrder',
  MAIN_CATEGORIES = 'mainCategories',
  SUB_CATEGORIES = 'subCategories',
  SHIFTS = 'shifts',
  PREPARATION_AREA = 'preparationAreas',
  Analysis = 'analysis',
  WAITERS_REPORT = 'waitersReport',
  MENU_ITEMS_REPORT = 'menuItemsReport',
  SERVING_AREA_REPORT = 'servingAreaReport',
  WAREHOUSE = 'warehouse',
  GUEST = 'guests',
  GUEST_GROUP = 'guest-groups',
  Event = 'events',
  REJECTION_REASONS = 'rejection-reasons',
  Wallet_Managements = 'walletManagements',
  POS = 'pos',
  ITEM_CONSUMPTION_REPORT = 'itemConsumptions',
  INVITATIONS = 'invitations',
  DISPATCHER = 'dispatcher',
  INVITATIONS_HISTORY = 'invitations-history',
  hostScans = 'hostScans',
  EVENT_DASHBOARD = 'event-dashboard',
  FIELD_DASHBOARD = 'field-dashboard',
}

export enum FeaturesNames {
  Guests = `guests`,
  Events = `events`,
  EVENT_DASHBOARD = `event dashboard`,
  FIELD_DASHBOARD = `field dashboard`,
  Booking = `booking`,
  Dashboard = `dashboard`,
  SystemUsers = `system users`,
  Settings = `settings`,
  Cashless = `cashless`,
  AdminGroups = `admin groups`,
  Reports = `reports`,
  Invitations = `invitations`,
  HostScans = `hostScans`,
}

export const NOTIFICATION_CHANNELS = [
  { label: 'email', value: 'email' },
  { label: 'sms', value: 'sms' },
];

export enum ADMIN_ROLES {
  SETTINGS = 'settings_access',
  DASHBOARD = 'dashboard_access',
  ADMINS = 'admins_access',
  VIEW_RESERVATIONS = 'view_reservation_access',
  CREATE_RESERVATION = 'create_reservation_access',
  CANCEL_RESERVATION = 'cancel_reservation_access',
  CREATE_GUEST = 'create_guest_access',
  EDIT_GUEST = 'edit_guest_access',
  EDIT_VIP = 'edit_vip_access',
  LOCK_TABLE = 'lock_table_access',
  EDIT_LAYOUT = 'edit_layout_access',
  TRANSACTION_REPORT = 'transaction_report',
  PAYMENT_DASHBOARD = 'payment_dashboard',
  PAYMOB_SETTINGS = 'paymob_settings',
  CALL_CENTER = 'call_center',
  WAITER = 'waiter',
  KITCHEN = 'kitchen',
  view_payment_analysis_report = 'view_payment_analysis_report',
  view_gender_analysis_report = 'view_gender_analysis_report',
  view_tickets_statistics_report = 'view_tickets_statistics_report',
  view_ticket_types_overview_report = 'view_ticket_types_overview_report',
  Booking_Access = 'booking_access',
  guests_Access = 'guests_access',
  events_Access = 'events_access',
  // cashless_Access = 'cashless_access',
  POS_Access = 'pos_access',
  Wallet_Management_Access = 'wallet_management',
  All_Top_Ups_Access = 'all_topups',
  All_Transactions_Access = 'all_transactions',
  Item_Consumption_Access = 'item_consumption',
  Can_View_Dispatcher = 'can_view_dispatcher',
  Can_View_Complementary = 'can_view_complementary',
  can_view_invitations_history = 'can_view_invitations_history',
  can_view_hostscans = 'can_view_hostscans',
  can_cancel_invitations = 'can_cancel_invitations',
  can_view_event_dashboard = 'can_view_event_dashboard',
  can_view_field_dashboard = 'can_view_field_dashboard',
}

export enum LOCAL_STORAGE {
  SYNC_BOOKINGS = 'sync-bookings',
  SYNC_GUESTS = 'sync-guests',
  SYNC_GUEST_STATS = 'sync-guest-stats',
  SELECTED_CONCEPT = 'selected-concept',
  BOOKING_DATE_RANGE = 'booking-date-range',
  BOOKING_GUEST = 'booking-guest',
  BOOKING_REFRESH = 'booking-refresh',
  BOOKING_SYNC_DURATION = 'booking-sync-duration',
  BOOKING_DATE = 'booking-date',
  GUESTS_SEARCH = 'guests-search',
  VERSION_CHECKING = 'version_checking',
  VERSION = 'version',
  LAST_VISIT = 'lastVisit',
}

export enum DATE_PICKER_TYPES {
  SINGLE = 'single',
  RANGE = 'range',
}

export enum MODAL_TYPES {
  DATE_PICKER = 'date-picker',
  BOOKING = 'booking',
  EDIT_BOOKING = 'edit-booking',
  VIEW_OLD_BOOKING = 'view-old-booking',
  GUEST_STATS = 'guest-stats',
}

export enum Orders {
  ASC = 'asc',
  DSC = 'desc',
}

export enum AdminsApiPaths {
  LIST_USERS = '/listUsers',
  LIST_USERS_IN_GROUP = '/listUsersInGroup',
  LIST_GROUPS_FOR_USER = '/listGroupsForUser',
  GET_USER = '/getUser',
  LIST_GROUPS = '/listGroups',
  ADD_USER_TO_GROUP = '/addUserToGroup',
  REMOVE_USER_FROM_GROUP = '/removeUserFromGroup',
  DISABLE_USER = '/disableUser',
  ENABLE_USER = '/enableUser',
  ADMIN_DELETE_USER = '/adminDeleteUser',
}

export enum AdminsConstants {
  ADMINS_API = 'AdminQueries',
}

export enum FormActions {
  ADD = 'Add',
  EDIT = 'Edit',
}
// Dev Env
// export const cloudinaryURL =
//   'https://ticketingsystemadmina29b1f146cd8476b9f9aefcf325132756-dev.s3.us-east-2.amazonaws.com/public/';
// export const CashlessTransactionsFunction =
//   'https://fxvmm3tufovcikezg4zvplal4e0glpex.lambda-url.us-east-2.on.aws/';

// Prod Env
export const cloudinaryURL =
  'https://ticketingsystemadmina29b1f146cd8476b9f9aefcf325165037-prod.s3.us-east-2.amazonaws.com/public/';
export const CashlessTransactionsFunction =
  'https://uxq33hj4wpzagznbgycxisw4r40hambg.lambda-url.us-east-2.on.aws/';

export const unknownUserImg =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/925px-Unknown_person.jpg';

export const EventEndPoints =
  'https://l77jmcjaqqwf7gzd44mjquxpoy0josbx.lambda-url.us-east-2.on.aws/';

export const smsEndpoint = 'https://sms.anyware.software/cequens-sms/sendSMS';

export enum AppTypes {
  CASHLESS = 'cashless',
  ONLINE = 'online',
}

// export const appType = 'cashless';
export const appType = 'online';

export enum ResourceTypes {
  CUSTOMER = 'customer',
  BOOKING = 'booking',
}

export enum AdminType {
  WAITER = 'waiter',
  CALLCENTER = 'callCenter',
}

export enum PaymentLinkOrigin {
  EMAIL = 'email',
  SMS = 'sms',
}

export enum TimelineActions {
  CHANGE_FACEBOOK = 'Facebook link',
  CHANGE_INSTAGRAM = 'Instagram link',
  CHANGE_NAME = 'name',
  CHANGE_ADDRESS = 'address',
  CHANGE_GROUP = 'Group',
  CHANGE_EMAIL = 'Email',
  CHANGE_GENDER = 'Gender',
  CHANGE_VERIFICATION_STATUS = 'Verify Guest',
  ADD_INTEREST = 'Add Customer Interests',
  REMOVE_INTEREST = 'Remove Customer Interests',
  ADD_FLAG = 'Add Customer Flags',
  REMOVE_FLAG = 'Remove Customer Flags',
  CHANGE_PHONE_NUMBER = 'Phone Number',
  DEPOSIT_PAYMENT_SUCCESS = 'Deposit Payment Success',
  DEPOSIT_PAYMENT_FAILED = 'Deposit Payment Failed',
  DEPOSIT_PAYMENT_REFUND = 'Deposit Refund Success',
  SHIFT_CHANGE = 'Shift Change',
  SHIFT_ASSIGNED = 'Shift Assigned',
  ADMIN_GROUP_ADDED = 'Admin Group Added',
  ADMIN_GROUP_REMOVED = 'Admin Group Removed',
  PREPARATION_AREA_ADDED = 'Service Area Added',
  PREPARATION_AREA_REMOVED = 'Service Area Removed',
  CONCEPT_ADDED = 'Concept Added',
  CONCEPT_REMOVED = 'Concept Removed',
  USER_ATTRIBUTES_CHANGED = 'User Attributes',
  CHANGE_PASSWORD = 'Password',
  USER_ENABELED = 'User Enabled',
  USER_DISABLED = 'User Disabled',
  TRANSFERED = 'Transfered',
}

export enum TimelineTypes {
  ALL = '',
  SUCCESS = 'success',
  approvedBooking = 'Approved Booking',
  rejectedBooking = 'Rejected Booking',
  paidTicket = 'Paid Ticket',
  createdBooking = 'Created Booking',
  ENTRY = 'entry',
  ALERT = 'alert',
  INFO = 'info',
}

export enum BookingTimelineActions {
  TIME_SLOT = 'Update time slot',
  STATUS = 'Update Booking Status',
  TABLE = 'Update Booking Table',
  DATE = 'Update Booking Date',
  GUEST_COUNT = 'Update Guest Count',
  RESERVATION_EMAIL = 'Send Reservation Email',
}

export enum MapSetting {
  Organizers = 'organizers',
  Guests = 'guests',
  Owners = 'owners',
}
export enum TicketsPaymentRules {
  LADIES_FIRST = 'Ladies pay first',
  ALL_AT_SAME_TIME = 'Guests pay at the same time',
}

export enum TicketsApprovalRules {
  REQUIRES_APPROVAL = 'Requires Approval',
}

export enum TicketsDisplayRules {
  SHOW_ALL = 'Show all available tickets',
  SHOW_ONHOLD = 'Show onhold tickets',
  SHOW_SOLD_OUT = 'Show sold out tickets',
  SHOW_UPCOMING_WAVE = 'Show upcoming waves',
  SET_AVAILABLE_AT = 'Set available at',
  ALLOW_TRANSFERRED = 'Allow transferred tickets',
}

export enum BookingStatus {
  TEST = 'test',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  TRANSFERED = 'transfered',
  PARTIALLY_APPROVED = 'partially approved',
  NOT_REGISTERED = 'not registered',
  BANNED = 'banned',
  ALL = '',
}

export enum OrderStatus {
  PENDING = 'pending',
  DELEVERED = 'delevered',
  VOIDED = 'voided',
}

export enum BookingPendingFilter {
  REGISTERED = 'Registered Bookings',
  NON_REGISTERED = 'Non Registered Bookings',
}

export enum BookingSpecialNeedsFilter {
  Yes = 'yes',
  No = 'no',
}

export enum Genders {
  MALE = 'male',
  FEMALE = 'female',
  UNKNOWN = 'unkown',
}

export enum LambdaFunctions {
  ticketingEventsOperations = 'ticketingEventsOperations',
  ticketingGuestsData = 'ticketingGuestsData',
  ticketingPaymentOperations = 'ticketingPaymentOperations',
  ticketingPaymentWebhook = 'ticketingPaymentWebhook',
}

export enum AutomaticShiftTypes {
  OFF = 'off',
  ON_TIME = 'onTime',
  ON_TICKETS = 'onTickets',
}

export enum PaymentStatus {
  FULL = 'full',
  PARTIAL = 'partial',
}

export enum PaymentOperation {
  DEDUCT = 'deduct',
  CHARGE = 'charge',
}

export enum paymentChannel {
  ONLINE = 'online',
  ONSITE = 'on-site',
  TicketBenefit = 'ticket-benefit',
}

export enum PaymentMethod {
  CASH = 'cash',
  VISA = 'visa',
  COMPLEMENTARY = 'complementary',
}

export enum cashlessTopUpStatus {
  SUCCESS = 'success',
  VOID = 'void',
  FAIL = 'fail',
}

export enum CashlessCreditUpdateRule {
  APPLY_FOR_PAST_BOOKINGS = 'all',
  APPLY_FOR_UPCOMING_BOOKINGS_ONLY = 'upcoming',
}

export enum Transaction_TYPES {
  PAY_TICKET = 'deposit',
  CHARGE_WALLET = 'chargeWallet',
  REFUND = 'refund',
}

export enum CashlessType {
  BOOKING = 'booking',
  INVITATION = 'invitation',
}

export const CashlessTypeList = ['booking', 'invitation'];

export enum BookingOrigin {
  WEBSITE = 'website',
  HOST_APP = 'host app',
}
export const BookingOriginList = ['all', 'website', 'host app'];

export enum BookingsPaymentStatus {
  PAID = 1,
  NOT_PAID = 2,
}

export const countries: readonly CountryType[] = [
  { label: 'Andorra', value: 'Andorra' },
  { label: 'United Arab Emirates', value: 'United Arab Emirates' },
  { label: 'Afghanistan', value: 'Afghanistan' },
  { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
  { label: 'Anguilla', value: 'Anguilla' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Antarctica', value: 'Antarctica' },
  { label: 'Argentina', value: 'Argentina' },
  { label: 'American Samoa', value: 'American Samoa' },
  { label: 'Austria', value: 'Austria' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Aruba', value: 'Aruba' },
  { label: 'Alland Islands', value: 'Alland Islands' },
  { label: 'Azerbaijan', value: 'Azerbaijan' },
  { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { label: 'Barbados', value: 'Barbados' },
  { label: 'Bangladesh', value: 'Bangladesh' },
  { label: 'Belgium', value: 'Belgium' },
  { label: 'Burkina Faso', value: 'Burkina Faso' },
  { label: 'Bulgaria', value: 'Bulgaria' },
  { label: 'Bahrain', value: 'Bahrain' },
  { label: 'Burundi', value: 'Burundi' },
  { label: 'Benin', value: 'Benin' },
  { label: 'Saint Barthelemy', value: 'Saint Barthelemy' },
  { label: 'Bermuda', value: 'Bermuda' },
  { label: 'Brunei Darussalam', value: 'Brunei Darussalam' },
  { label: 'Bolivia', value: 'Bolivia' },
  { label: 'Brazil', value: 'Brazil' },
  { label: 'Bahamas', value: 'Bahamas' },
  { label: 'Bhutan', value: 'Bhutan' },
  { label: 'Bouvet Island', value: 'Bouvet Island' },
  { label: 'Botswana', value: 'Botswana' },
  { label: 'Belarus', value: 'Belarus' },
  { label: 'Belize', value: 'Belize' },
  { label: 'Canada', value: 'Canada' },
  { label: 'Cocos (Keeling) Islands', value: 'Cocos (Keeling) Islands' },
  {
    label: 'Congo, Democratic Republic of the',
    value: 'Congo, Democratic Republic of the',
  },
  { label: 'Central African Republic', value: 'Central African Republic' },
  { label: 'Congo, Republic of the', value: 'Congo, Republic of the' },
  { label: 'Switzerland', value: 'Switzerland' },
  { label: "Cote d'Ivoire", value: "Cote d'Ivoire" },
  { label: 'Cook Islands', value: 'Cook Islands' },
  { label: 'Chile', value: 'Chile' },
  { label: 'Cameroon', value: 'Cameroon' },
  { label: 'China', value: 'China' },
  { label: 'Colombia', value: 'Colombia' },
  { label: 'Costa Rica', value: 'Costa Rica' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Cape Verde', value: 'Cape Verde' },
  { label: 'Curacao', value: 'Curacao' },
  { label: 'Christmas Island', value: 'Christmas Island' },
  { label: 'Cyprus', value: 'Cyprus' },
  { label: 'Czech Republic', value: 'Czech Republic' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Djibouti', value: 'Djibouti' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Dominica', value: 'Dominica' },
  { label: 'Dominican Republic', value: 'Dominican Republic' },
  { label: 'Algeria', value: 'Algeria' },
  { label: 'Ecuador', value: 'Ecuador' },
  { label: 'Estonia', value: 'Estonia' },
  { label: 'Egypt', value: 'Egypt' },
  { label: 'Western Sahara', value: 'Western Sahara' },
  { label: 'Eritrea', value: 'Eritrea' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Ethiopia', value: 'Ethiopia' },
  { label: 'Finland', value: 'Finland' },
  { label: 'Fiji', value: 'Fiji' },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'Falkland Islands (Malvinas)',
  },
  {
    label: 'Micronesia, Federated States of',
    value: 'Micronesia, Federated States of',
  },
  { label: 'Faroe Islands', value: 'Faroe Islands' },
  { label: 'France', value: 'France' },
  { label: 'Gabon', value: 'Gabon' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'Grenada', value: 'Grenada' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'French Guiana', value: 'French Guiana' },
  { label: 'Guernsey', value: 'Guernsey' },
  { label: 'Ghana', value: 'Ghana' },
  { label: 'Gibraltar', value: 'Gibraltar' },
  { label: 'Greenland', value: 'Greenland' },
  { label: 'Gambia', value: 'Gambia' },
  { label: 'Guinea', value: 'Guinea' },
  { label: 'Guadeloupe', value: 'Guadeloupe' },
  { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { label: 'Greece', value: 'Greece' },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
  },
  { label: 'Guatemala', value: 'Guatemala' },
  { label: 'Guam', value: 'Guam' },
  { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  { label: 'Guyana', value: 'Guyana' },
  { label: 'Hong Kong', value: 'Hong Kong' },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'Heard Island and McDonald Islands',
  },
  { label: 'Honduras', value: 'Honduras' },
  { label: 'Croatia', value: 'Croatia' },
  { label: 'Haiti', value: 'Haiti' },
  { label: 'Hungary', value: 'Hungary' },
  { label: 'Indonesia', value: 'Indonesia' },
  { label: 'Ireland', value: 'Ireland' },
  { label: 'Israel', value: 'Israel' },
  { label: 'Isle of Man', value: 'Isle of Man' },
  { label: 'India', value: 'India' },
  {
    label: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
  },
  { label: 'Iraq', value: 'Iraq' },
  { label: 'Iran, Islamic Republic of', value: 'Iran, Islamic Republic of' },
  { label: 'Iceland', value: 'Iceland' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Jersey', value: 'Jersey' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Japan', value: 'Japan' },
  { label: 'Kenya', value: 'Kenya' },
  { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { label: 'Cambodia', value: 'Cambodia' },
  { label: 'Kiribati', value: 'Kiribati' },
  { label: 'Comoros', value: 'Comoros' },
  { label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
  {
    label: "Korea, Democratic People's Republic of",
    value: "Korea, Democratic People's Republic of",
  },
  { label: 'Korea, Republic of', value: 'Korea, Republic of' },
  { label: 'Kuwait', value: 'Kuwait' },
  { label: 'Cayman Islands', value: 'Cayman Islands' },
  { label: 'Kazakhstan', value: 'Kazakhstan' },
  {
    label: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic",
  },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Saint Lucia', value: 'Saint Lucia' },
  { label: 'Liechtenstein', value: 'Liechtenstein' },
  { label: 'Sri Lanka', value: 'Sri Lanka' },
  { label: 'Liberia', value: 'Liberia' },
  { label: 'Lesotho', value: 'Lesotho' },
  { label: 'Lithuania', value: 'Lithuania' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Latvia', value: 'Latvia' },
  { label: 'Libya', value: 'Libya' },
  { label: 'Morocco', value: 'Morocco' },
  { label: 'Monaco', value: 'Monaco' },
  { label: 'Moldova, Republic of', value: 'Moldova, Republic of' },
  { label: 'Montenegro', value: 'Montenegro' },
  { label: 'Saint Martin (French part)', value: 'Saint Martin (French part)' },
  { label: 'Madagascar', value: 'Madagascar' },
  { label: 'Marshall Islands', value: 'Marshall Islands' },
  {
    label: 'Macedonia, the Former Yugoslav Republic of',
    value: 'Macedonia, the Former Yugoslav Republic of',
  },
  { label: 'Mali', value: 'Mali' },
  { label: 'Myanmar', value: 'Myanmar' },
  { label: 'Mongolia', value: 'Mongolia' },
  { label: 'Macao', value: 'Macao' },
  { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  { label: 'Martinique', value: 'Martinique' },
  { label: 'Mauritania', value: 'Mauritania' },
  { label: 'Montserrat', value: 'Montserrat' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Mauritius', value: 'Mauritius' },
  { label: 'Maldives', value: 'Maldives' },
  { label: 'Malawi', value: 'Malawi' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Malaysia', value: 'Malaysia' },
  { label: 'Mozambique', value: 'Mozambique' },
  { label: 'Namibia', value: 'Namibia' },
  { label: 'New Caledonia', value: 'New Caledonia' },
  { label: 'Niger', value: 'Niger' },
  { label: 'Norfolk Island', value: 'Norfolk Island' },
  { label: 'Nigeria', value: 'Nigeria' },
  { label: 'Nicaragua', value: 'Nicaragua' },
  { label: 'Netherlands', value: 'Netherlands' },
  { label: 'Norway', value: 'Norway' },
  { label: 'Nepal', value: 'Nepal' },
  { label: 'Nauru', value: 'Nauru' },
  { label: 'Niue', value: 'Niue' },
  { label: 'New Zealand', value: 'New Zealand' },
  { label: 'Oman', value: 'Oman' },
  { label: 'Panama', value: 'Panama' },
  { label: 'Peru', value: 'Peru' },
  { label: 'French Polynesia', value: 'French Polynesia' },
  { label: 'Papua New Guinea', value: 'Papua New Guinea' },
  { label: 'Philippines', value: 'Philippines' },
  { label: 'Pakistan', value: 'Pakistan' },
  { label: 'Poland', value: 'Poland' },
  { label: 'Saint Pierre and Miquelon', value: 'Saint Pierre and Miquelon' },
  { label: 'Pitcairn', value: 'Pitcairn' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'Palestine, State of', value: 'Palestine, State of' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'Palau', value: 'Palau' },
  { label: 'Paraguay', value: 'Paraguay' },
  { label: 'Qatar', value: 'Qatar' },
  { label: 'Reunion', value: 'Reunion' },
  { label: 'Romania', value: 'Romania' },
  { label: 'Serbia', value: 'Serbia' },
  { label: 'Russian Federation', value: 'Russian Federation' },
  { label: 'Rwanda', value: 'Rwanda' },
  { label: 'Saudi Arabia', value: 'Saudi Arabia' },
  { label: 'Solomon Islands', value: 'Solomon Islands' },
  { label: 'Seychelles', value: 'Seychelles' },
  { label: 'Sudan', value: 'Sudan' },
  { label: 'Sweden', value: 'Sweden' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Saint Helena', value: 'Saint Helena' },
  { label: 'Slovenia', value: 'Slovenia' },
  { label: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen' },
  { label: 'Slovakia', value: 'Slovakia' },
  { label: 'Sierra Leone', value: 'Sierra Leone' },
  { label: 'San Marino', value: 'San Marino' },
  { label: 'Senegal', value: 'Senegal' },
  { label: 'Somalia', value: 'Somalia' },
  { label: 'Suriname', value: 'Suriname' },
  { label: 'South Sudan', value: 'South Sudan' },
  { label: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
  { label: 'El Salvador', value: 'El Salvador' },
  { label: 'Sint Maarten (Dutch part)', value: 'Sint Maarten (Dutch part)' },
  { label: 'Syrian Arab Republic', value: 'Syrian Arab Republic' },
  { label: 'Swaziland', value: 'Swaziland' },
  { label: 'Turks and Caicos Islands', value: 'Turks and Caicos Islands' },
  { label: 'Chad', value: 'Chad' },
  {
    label: 'French Southern Territories',
    value: 'French Southern Territories',
  },
  { label: 'Togo', value: 'Togo' },
  { label: 'Thailand', value: 'Thailand' },
  { label: 'Tajikistan', value: 'Tajikistan' },
  { label: 'Tokelau', value: 'Tokelau' },
  { label: 'Timor-Leste', value: 'Timor-Leste' },
  { label: 'Turkmenistan', value: 'Turkmenistan' },
  { label: 'Tunisia', value: 'Tunisia' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Turkey', value: 'Turkey' },
  { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  { label: 'Tuvalu', value: 'Tuvalu' },
  { label: 'Taiwan, Province of China', value: 'Taiwan, Province of China' },
  {
    label: 'Tanzania, United Republic of',
    value: 'Tanzania, United Republic of',
  },
  { label: 'Ukraine', value: 'Ukraine' },
  { label: 'Uganda', value: 'Uganda' },
  {
    label: 'United States Minor Outlying Islands',
    value: 'United States Minor Outlying Islands',
  },
  { label: 'United States', value: 'United States' },
  { label: 'Uruguay', value: 'Uruguay' },
  { label: 'Uzbekistan', value: 'Uzbekistan' },
  {
    label: 'Holy See (Vatican City State)',
    value: 'Holy See (Vatican City State)',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
  },
  { label: 'Venezuela', value: 'Venezuela' },
  { label: 'Virgin Islands, British', value: 'Virgin Islands, British' },
  { label: 'Virgin Islands, U.S.', value: 'Virgin Islands, U.S.' },
  { label: 'Viet Nam', value: 'Viet Nam' },
  { label: 'Vanuatu', value: 'Vanuatu' },
  { label: 'Wallis and Futuna', value: 'Wallis and Futuna' },
  { label: 'Samoa', value: 'Samoa' },
  { label: 'Kosovo', value: 'Kosovo' },
  { label: 'Yemen', value: 'Yemen' },
  { label: 'Mayotte', value: 'Mayotte' },
  { label: 'South Africa', value: 'South Africa' },
  { label: 'Zambia', value: 'Zambia' },
  { label: 'Zimbabwe', value: 'Zimbabwe' },
];
