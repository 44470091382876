import React from 'react';
import MainModal from '../MainModal';
import MainSnackbar from '../MainSnackbar';
import { useSelector } from 'react-redux';

const AlertsWrapper = () => {
  const modalOpen = useSelector((state: any) => state.app.modalOpen);
  const snackBarOpen = useSelector((state: any) => state.app.snackBarOpen);
  return (
    <>
      {modalOpen && <MainModal />}
      {snackBarOpen && <MainSnackbar />}
    </>
  );
};

export default AlertsWrapper;
