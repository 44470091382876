import { generateClient } from '@aws-amplify/api';
import { Category } from './../models/GQL_API';
import useApp from './useApp';
import { categoryByDeleted } from '../graphql/queries';

const client = generateClient();
const useCategory = (listingName: string, singleName: string) => {
  const { showError } = useApp();
  //
  async function fetch() {
    try {
      const variables: any = {
        limit: 100000,
        sortDirection: 'ASC',
        deleted: '0',
      };

      const categories: any = await client.graphql<Category>({
        query: categoryByDeleted,
        variables: variables,
        authMode: 'userPool',
      });
      const listing = categories.data.categoryByDeleted.items;
      const filteredCategories = listing.filter((category: Category) => {
        return (category.items?.items?.length ?? 0) > 0;
      });
      return filteredCategories;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  const api: any = {};

  api[`${listingName}Fetch`] = fetch;

  return api;
};

export default useCategory;
