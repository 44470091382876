import { Box, Button, TableCell, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  CashlessType,
  Pages,
  PaymentOperation,
  PaymentStatus,
  cashlessTopUpStatus,
  paymentChannel,
} from '../../constants/enums';
import useCashlessTopups from '../../hooks/useCashlessTopups';
import { CashlessTopUp } from '../../models/GQL_API';
import { CashlessTopUpListingVariables } from '../../models/app';
import useBooking from '../../hooks/useBooking';
import useGuest from '../../hooks/useGuest';

interface CashlessTopupsRowsProps {
  row: any;
  setUserBalance?: (newBalance: number) => void;
  slug?: string;
}
const TopUpRows: React.FC<CashlessTopupsRowsProps> = ({
  row,
  setUserBalance,
  slug,
}) => {
  const accountsSelected = useSelector((state: any) => state.accounts.selected);
  const [voidButtonDisabled, setVoidButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const serverStatus = useSelector((state: any) => state.server.serverStatus);
  const { bookingsGet } = useBooking('bookings', 'booking');
  const {
    cashlessTopupsVoid,
    cashlessTopupsFetchAllSortedByDate,
    cashlessTopupsChangeListing,
  } = useCashlessTopups('cashlessTopups', 'cashlessTopup');
  const { guestsFetchGuestOfflineByPhone } = useGuest('guests', 'guest');

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    return date.toLocaleString('en-US', options);
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      const currentTime = new Date().getTime();
      const createdAtTime = new Date(row.createdAt).getTime();
      const timeDifference = currentTime - createdAtTime;

      if (
        timeDifference >= 1800000 ||
        row.status === cashlessTopUpStatus.VOID
      ) {
        // 1800000 milliseconds = 30 minutes
        setVoidButtonDisabled(true);
        clearInterval(timerId); // Stop the timer when button is disabled
      } else {
        setVoidButtonDisabled(false);
      }
    }, 1000); // Check every second

    return () => clearInterval(timerId); // Cleanup timer on component unmount
  }, [row.status, voidButtonDisabled]);

  const fetchCashlessTopups = async () => {
    const Params: CashlessTopUpListingVariables = {
      searchText: '',
      limit: 0,
      startIndex: 0,
      generalFilters: {
        fromDate: new Date().toISOString(),
        toDate: new Date().toISOString(),
        searchText: '',
        event: '',
      },
      guestId: '',
      eventFilter: '',
      operationTypeFilter: '',
      paymentChannelFilter: paymentChannel.ONSITE,
    };
    const listing: CashlessTopUp[] =
      await cashlessTopupsFetchAllSortedByDate(Params);
    cashlessTopupsChangeListing(listing);
  };

  const handleVoidClick = async () => {
    try {
      if (slug !== Pages.Wallet_Managements) return;
      setLoading(true);
      await cashlessTopupsVoid({ id: row.id });
      setVoidButtonDisabled(true);
      await fetchCashlessTopups();
      let booking;
      if (serverStatus) {
        booking = await guestsFetchGuestOfflineByPhone(row.guestPhoneNumber);
      } else {
        booking = await bookingsGet({ listing: [], id: row.bookingId });
      }
      setUserBalance!(booking.balance);
      setLoading(false);
    } catch (err) {
      console.log({ err });
      setLoading(false);
    }
  };
  // console.log(row.availableBalance);
  // console.log(row.amount);

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      style={{ cursor: 'pointer' }}
    >
      <TableCell
        sx={{ p: '8px 16px', minWidth: 80, textTransform: 'capitalize' }}
        align="left"
      >
        {row.guestName}
      </TableCell>
      <TableCell
        sx={{ p: '8px 16px', minWidth: 80, textTransform: 'capitalize' }}
        align="left"
      >
        {row.guestPhoneNumber || (row.guest?.phone_number ?? 'N/A')}
      </TableCell>
      <TableCell sx={{ p: '8px 16px', minWidth: 80 }} align="left">
        {row.guestEmail || (row.guest?.email ?? 'N/A')}
      </TableCell>
      <TableCell
        sx={{ p: '8px 16px', minWidth: 80, textTransform: 'capitalize' }}
        align="left"
      >
        {row.eventName || row.event.name}
      </TableCell>
      <TableCell
        sx={{ p: '8px 16px', minWidth: 80, textTransform: 'capitalize' }}
        align="left"
      >
        {row.ticketType || row.booking.eventTicket.type}
      </TableCell>
      <TableCell
        sx={{ p: '8px 16px', minWidth: 80, textTransform: 'capitalize' }}
        align="left"
      >
        {row.type ?? CashlessType.BOOKING}
      </TableCell>
      <TableCell
        sx={{ p: '8px 16px', minWidth: 160, textTransform: 'capitalize' }}
        align="left"
      >
        {row.operation}
      </TableCell>
      <TableCell
        sx={{
          p: '8px 16px',
          minWidth: 80,
          color: row.operation === PaymentOperation.CHARGE ? 'green' : 'red',
        }}
        align="left"
      >
        {row.operation === PaymentOperation.CHARGE ? '+' : '-'} {row.amount}{' '}
        {accountsSelected.currency}
      </TableCell>
      <TableCell sx={{ p: '8px 16px', minWidth: 80 }} align="left">
        {row.availableBalance}
      </TableCell>
      <TableCell
        sx={{ p: '8px 16px', minWidth: 160, textTransform: 'capitalize' }}
        align="left"
      >
        {row.paymentChannel}
      </TableCell>
      <TableCell sx={{ p: '8px 16px', minWidth: 160 }} align="left">
        {formatDate(row.createdAt)}
      </TableCell>
      <TableCell sx={{ p: '8px 16px', minWidth: 160 }} align="left">
        <Box
          sx={{
            backgroundColor:
              row.status === cashlessTopUpStatus.SUCCESS ? '#1dd75b' : 'red',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textTransform: 'capitalize',
            width: '5rem',
          }}
        >
          {row.status}
        </Box>
      </TableCell>
      <TableCell sx={{ p: '8px 16px', minWidth: 160 }} align="left">
        <Box
          sx={{
            backgroundColor: 'primary.main',
            border: '1px solid #2DC8DD',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#2DC8DD',
            textTransform: 'capitalize',
            maxWidth: '8rem',
          }}
        >
          {row.paymentMethod}
        </Box>
      </TableCell>
      <TableCell sx={{ p: '8px 16px', minWidth: 160 }} align="left">
        <Button
          variant="contained"
          onClick={handleVoidClick}
          sx={{
            backgroundColor: 'secondary.dark',
            border: '1px solid red',
            color: 'primary.main',
            borderRadius: '10px',
            width: { xs: 'auto', sm: '5rem' },
            textTransform: 'capitalize',
            ':hover': {
              border: '1px solid red',
              backgroundColor: 'red',
            },
          }}
          disabled={
            voidButtonDisabled ||
            loading ||
            (row.status && row.status === 'void') ||
            slug !== Pages.Wallet_Managements
          }
        >
          Void
        </Button>
      </TableCell>
    </TableRow>
  );
};
export default TopUpRows;
