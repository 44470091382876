import { lazy, Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LOCAL_STORAGE, Pages } from '../../constants/enums';
// import CallCenterSettings from "./CallCenterSettings";
import useNetwork from '../../hooks/useNetwork';
import { Box, Button, Grid, Typography } from '@mui/material';
// import networkErrorImage from "../../assets/images/no-wifi.png";
import { useSelector } from 'react-redux';
// import useLoading from "../../hooks/useLoading";
import { UserConcepts } from '../../models/GQL_API';
// import {
//   syncBookings,
//   syncBookingsConfig,
//   syncBookingsListener,
// } from "../../helpers/dbSync";
import { getBookingDateRange } from '../../helpers/localStorage';
import { AdminPermissions, IBookingsSyncDuration } from '../../models/app';
// import { DataStore } from "aws-amplify";
// import KPIReport from "./CallCenter/KPIReport/KPIReport";
// import WaiterOrder from "./WaiterOrder/WaiterOrder";
import useApp from '../../hooks/useApp';
// import Analysis from "./WaiterOrder/Analysis";
import ErrorIcon from '@mui/icons-material/Error';
import MainLoader from '../UI/MainLoader/MainLoader';
import CashlessPage from '../CashlessPage/CashlessPage';
import TopUpsAllListing from '../CashlessPage/TopUpsAllListing';
import TransactionsAllListing from '../CashlessPage/TransactionsAllListing';
import POS from '../CashlessPage/POS/POS';
import Dispatcher from '../CashlessPage/Dispatcher/Dispatcher';
// import Listing from './Listing/Listing';
// import Overview from './Overview/Overview';
const Overview = lazy(() => import('./Overview/Overview'));
const Listing = lazy(() => import('./Listing/Listing'));
const EventDashboard = lazy(() => import('./EventDashboard'));
const FieldDashboard = lazy(() => import('./FieldDashboard'));
// import EventContainer from '../EventContainer/EventContainer';
// import WaiterPerformanceReport from "../Reports/WaiterPerformanceReport";
// import MenuItemConsumption from "../Reports/MenuItemConsumption";
// import ServingAreaReport from "../Reports/ServingAreaReport";
// import Warehouse from "../Warehouse/Warehouse";

// const Bookings = lazy(() => import("./Bookings"));
// const FloorEditor = lazy(() => import("./FloorEditor"));
// const Overview = lazy(() => import("./Overview"));
// const Settings = lazy(() => import("./Settings"));
// const CallCenter = lazy(() => import("./CallCenter"));
// const Guests = lazy(() => import("./Guests"));
// const TransactionsDashboard = lazy(() => import("../TransactionsDashboard"));

const Dashboard: React.FC = () => {
  let { slug } = useParams();
  const { navigateTo } = useApp();
  const session = useSelector((state: any) => state.app.session);

  const accountsSelected = useSelector((state: any) => state.accounts.selected);
  const userPermissions: AdminPermissions = useSelector(
    (state: any) => state.app.userPermissions,
  );

  const featuresAllListing = useSelector(
    (state: any) => state.features.listingAll,
  );

  function checkFeature(slug: any): Boolean {
    let feature: any[] = [];
    if (accountsSelected && accountsSelected.features) {
      feature = featuresAllListing.filter(function (feature: any) {
        return (
          slug.toLowerCase() === feature.slug.toLowerCase() &&
          accountsSelected.features.indexOf(feature.id) !== -1
        );
      });
    }
    if (feature.length > 0) {
      return true;
    }

    return false;
  }

  const notAuthorized = () => {
    if (!session) {
      navigateTo(`/`);
    }
    return (
      <Grid
        item
        container
        alignContent={'center'}
        alignItems={'center'}
        justifyContent={'center'}
        justifyItems={'center'}
      >
        <Grid
          item
          xs={4}
          container
          alignContent={'center'}
          alignItems={'center'}
          justifyContent={'center'}
          justifyItems={'center'}
          marginTop={3}
        >
          <ErrorIcon sx={{ fontSize: 50 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography
            align={'center'}
            marginTop={3}
            fontSize={25}
            fontFamily={'Raleway'}
          >
            Unauthorized Action
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const userConcepts: UserConcepts = useSelector(
    (state: any) => state.app.concepts,
  );

  const conceptsSelected: string = useSelector(
    (state: any) => state.concepts.selected,
  );
  const { online } = useNetwork();
  // const { loading, changeLoading } = useLoading();

  const bookingsSyncDuration: IBookingsSyncDuration =
    getBookingDateRange(userConcepts);
  const { fromDate, toDate } = bookingsSyncDuration;

  // DataStore.configure(syncBookingsConfig(userConcepts, fromDate, toDate));

  // useEffect(() => {
  //   syncBookings();

  //   const listener = syncBookingsListener(syncBookingsCb);

  //   if (listener) {
  //     return () => listener();
  //   } else {
  //     changeLoading(false);
  //   }

  //   // eslint-disable-next-line
  // }, []);
  // const syncBookingsCb = async () => {
  //   changeLoading(false);
  // };

  const renderOffline = () => {
    if (!online) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '80vh',
          }}
        >
          <Box
            sx={{
              display: 'block',
              maxWidth: 200,
              height: 'auto',
              marginBottom: '40px',
            }}
            component="img"
            // src={networkErrorImage}
            alt="network-error"
          />
          <Typography variant="h4" component="h4" paragraph>
            You cant view admins when you're offline.
          </Typography>
          <Typography variant="h3" component="h1" paragraph>
            Connect to the internet
          </Typography>
          <Typography variant="h4" component="h3" paragraph>
            Check your connection. And Try again.
          </Typography>
          <Button
            variant="outlined"
            sx={{ mt: 6 }}
            size="large"
            onClick={() => window.location.reload()}
          >
            Try Again
          </Button>
        </Box>
      );
    } else {
      return <Listing key={`${slug!}-${`${conceptsSelected}`}`} slug={slug!} />;
    }
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userPermissions.admins === false) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 10000);
    } else {
      setLoading(false);
    }
  }, [userPermissions]);

  if (loading) return <MainLoader />;

  const renderContent = () => {
    if (slug === Pages.GUESTS && !online)
      localStorage.setItem(LOCAL_STORAGE.GUESTS_SEARCH, 'true');
    if (
      slug !== Pages.Wallet_Managements &&
      slug !== Pages.POS &&
      slug !== Pages.DISPATCHER &&
      !online &&
      slug
    ) {
      if (slug === Pages.CASHLESS) {
        return checkFeature(Pages.CASHLESS) &&
          userPermissions.Wallet_Management_Access === true ? (
          <CashlessPage key={`${conceptsSelected}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      } else if (slug === Pages.POS) {
        return checkFeature(Pages.POS) &&
          userPermissions.POS_Access === true ? (
          <POS key={`${conceptsSelected}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      } else if (slug === Pages.DISPATCHER) {
        return checkFeature(Pages.DISPATCHER) &&
          userPermissions.can_view_dispatcher === true ? (
          <Dispatcher key={`${conceptsSelected}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      } else if (slug === Pages.EVENT_DASHBOARD) {
        return checkFeature(Pages.EVENT_DASHBOARD) &&
          userPermissions.can_view_event_dashboard === true ? (
          <EventDashboard key={`${conceptsSelected}`} />
        ) : (
          notAuthorized()
        );
      } else if (slug === Pages.FIELD_DASHBOARD) {
        return checkFeature(Pages.FIELD_DASHBOARD) &&
          userPermissions.can_view_field_dashboard === true ? (
          <FieldDashboard key={`${conceptsSelected}`} />
        ) : (
          notAuthorized()
        );
      } else {
        return checkFeature(Pages.POS) &&
          userPermissions.POS_Access === true ? (
          <POS key={`${conceptsSelected}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      }
    }
    switch (slug) {
      case Pages.ADMINS:
        return checkFeature(Pages.ADMINS) &&
          userPermissions.admins &&
          userPermissions.settings
          ? renderOffline()
          : notAuthorized();
      case Pages.DASHBOARD:
        return checkFeature(Pages.DASHBOARD) &&
          (userPermissions.view_gender_analysis_report ||
            userPermissions.view_payment_analysis_report ||
            userPermissions.view_ticket_types_overview_report ||
            userPermissions.view_tickets_statistics_report) ? (
          <Overview key={`${conceptsSelected}`} slug={slug} />
        ) : userPermissions.Wallet_Management_Access === true ? (
          <CashlessPage slug={slug} />
        ) : userPermissions.can_view_event_dashboard === true ? (
          <EventDashboard key={`${conceptsSelected}`} />
        ) : userPermissions.can_view_field_dashboard === true ? (
          <FieldDashboard key={`${conceptsSelected}`} />
        ) : userPermissions.POS_Access === true ? (
          <POS key={`${conceptsSelected}`} slug={slug} />
        ) : userPermissions.can_view_dispatcher === true ? (
          <Dispatcher key={`${conceptsSelected}`} slug={slug} />
        ) : userPermissions.All_Top_Ups_Access === true ? (
          <TopUpsAllListing />
        ) : userPermissions.All_Transactions_Access === true ? (
          <TransactionsAllListing />
        ) : userPermissions.Item_Consumption_Access === true ? (
          <Listing
            key={`${Pages.ITEM_CONSUMPTION_REPORT}-${`${conceptsSelected}`}`}
            slug={Pages.ITEM_CONSUMPTION_REPORT}
          />
        ) : userPermissions.Events_Access === true ? (
          <Listing key={`events-${`${conceptsSelected}`}`} slug={`events`} />
        ) : userPermissions.Guests_Access === true ? (
          <Listing key={`guests-${`${conceptsSelected}`}`} slug={'guests'} />
        ) : userPermissions.Booking_Access === true ? (
          <Listing
            key={`bookings-${`${conceptsSelected}`}`}
            slug={'bookings'}
          />
        ) : (
          notAuthorized()
        );

      case Pages.Wallet_Managements:
        return checkFeature(Pages.Wallet_Managements) &&
          userPermissions.Wallet_Management_Access === true ? (
          <CashlessPage key={`${conceptsSelected}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      case Pages.POS:
        return checkFeature(Pages.POS) &&
          userPermissions.POS_Access === true ? (
          <POS key={`${conceptsSelected}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      case Pages.DISPATCHER:
        return checkFeature(Pages.DISPATCHER) &&
          userPermissions.can_view_dispatcher === true ? (
          <Dispatcher key={`${conceptsSelected}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      case Pages.GUESTS:
        return checkFeature(Pages.GUESTS) &&
          userPermissions.Guests_Access === true ? (
          <Listing key={`${slug}-${`${conceptsSelected}`}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      case Pages.Event:
        return checkFeature(Pages.Event) &&
          userPermissions.Events_Access === true ? (
          <Listing key={`${slug}-${`${conceptsSelected}`}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      case Pages.EVENT_DASHBOARD:
        return checkFeature(Pages.EVENT_DASHBOARD) &&
          userPermissions.can_view_event_dashboard === true ? (
          <EventDashboard key={`${slug}-${`${conceptsSelected}`}`} />
        ) : (
          notAuthorized()
        );
        case Pages.FIELD_DASHBOARD:
        return checkFeature(Pages.FIELD_DASHBOARD) &&
          userPermissions.can_view_field_dashboard === true ? (
          <FieldDashboard key={`${slug}-${`${conceptsSelected}`}`} />
        ) : (
          notAuthorized()
        );
      case Pages.ADMIN_ROLES:
        return checkFeature(Pages.ADMIN_ROLES) &&
          userPermissions.settings === true ? (
          <Listing key={`${slug}-${`${conceptsSelected}`}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      case Pages.ADMIN_GROUPS:
        return checkFeature(Pages.ADMIN_GROUPS) &&
          userPermissions.settings === true ? (
          <Listing key={`${slug}-${`${conceptsSelected}`}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      case Pages.CASHLESS:
        return checkFeature(Pages.CASHLESS) &&
          userPermissions.Wallet_Management_Access === true ? (
          <CashlessPage key={`${slug}-${`${conceptsSelected}`}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      case Pages.CASHLESS_ALL_TOP_UPS:
        return checkFeature(Pages.CASHLESS_ALL_TOP_UPS) &&
          userPermissions.All_Top_Ups_Access === true ? (
          <TopUpsAllListing />
        ) : (
          notAuthorized()
        );
      case Pages.CASHLESS_ALL_Transactions:
        return checkFeature(Pages.CASHLESS_ALL_Transactions) &&
          userPermissions.All_Transactions_Access === true ? (
          <TransactionsAllListing />
        ) : (
          notAuthorized()
        );
      case Pages.ITEM_CONSUMPTION_REPORT:
        return checkFeature(Pages.ITEM_CONSUMPTION_REPORT) &&
          userPermissions.Item_Consumption_Access === true ? (
          <Listing key={`${slug}-${`${conceptsSelected}`}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      case Pages.BOOKINGS:
        return checkFeature(Pages.BOOKINGS) &&
          userPermissions.Booking_Access === true ? (
          <Listing key={`${slug}-${`${conceptsSelected}`}`} slug={slug} />
        ) : (
          notAuthorized()
        );
      default:
        return (checkFeature(slug) && userPermissions.admins) || true ? (
          <Listing key={`${slug!}-${`${conceptsSelected}`}`} slug={slug!} />
        ) : (
          notAuthorized()
        );
    }
  };

  return <Suspense fallback={<MainLoader />}>{renderContent()}</Suspense>;
};

export default Dashboard;
