import {
  BookingPendingFilter,
  BookingSpecialNeedsFilter,
} from '../../constants/enums';
import { GeneralFilters } from '../../models/app';

// actions
const SET = 'generalFilters/SET';
const CLEAR = 'generalFilters/CLEAR';

const DEFAULT_STATE: GeneralFilters = {
  searchText: '',
  fromDate: '',
  toDate: '',
  event: '',
  ticket: '',
  wave: '',
  bookingsRegisteredFilter: BookingPendingFilter.NON_REGISTERED,
  bookingSpecialNeedsFilter: BookingSpecialNeedsFilter.No,
  bookingsOrigin: '',
  bookingsPaymentStatus: 0,
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { [action.name]: action.value });
    case CLEAR:
      return Object.assign({}, state, {
        searchText: '',
        fromDate: '',
        toDate: '',
        event: '',
        ticket: '',
        wave: '',
        bookingsRegisteredFilter: BookingPendingFilter.NON_REGISTERED,
        bookingSpecialNeedsFilter: BookingSpecialNeedsFilter.No,
        bookingsOrigin: '',
        bookingsPaymentStatus: 0,
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setGeneralFilters = (name: keyof GeneralFilters, value: any) => ({
  name,
  value,
  type: SET,
});

export const clearGeneralFilters = () => ({
  type: CLEAR,
});
