import { updateTransaction } from '../graphql/mutations';
import { UpdateTransactionInput, Transaction } from '../models/GQL_API';
import { useDispatch, useSelector } from 'react-redux';
import {
  setListing,
  setSelected,
  setNextToken,
} from '../store/ducks/transaction';
import {
  HeadCell,
  ListingVariables,
  transactionGetVariables,
  transactionUpdateVariables,
} from '../models/app';
import useApp from './useApp';
import { getTransaction, listTransactions } from '../graphql/queries';
import { onCreateTransaction } from '../graphql/subscriptions';
import { generateClient } from '@aws-amplify/api';

const client = generateClient();
const useTransaction = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]['nextToken'],
  );

  const transactionsListing = useSelector(
    (state: any) => state[`${listingName}`]['listing'],
  );
  async function fetch(params: ListingVariables) {
    try {
      const { searchText, limit, generalFilters } = params;
      const filter: any = {};
      filter.or = [];
      filter.and = [];
      if (searchText.length > 0) {
        filter.or.push({ guestName: { contains: searchText.toLowerCase() } });
        filter.or.push({
          guestPhone_number: { contains: searchText.toLowerCase() },
        });
      }
      if (generalFilters?.fromDate && generalFilters.toDate) {
        const fromDate = new Date(generalFilters.fromDate);
        fromDate.setHours(0, 0, 0, 500);
        const toDate = new Date(generalFilters.toDate);
        toDate.setHours(23, 59, 59, 999);
        generalFilters.fromDate = fromDate.toISOString();
        generalFilters.toDate = toDate.toISOString();
        filter.and.push({
          createdAt: {
            ge: new Date(generalFilters?.fromDate).toISOString(),
          },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(generalFilters.toDate).toISOString(),
          },
        });
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const groupsList: any = await client.graphql<Transaction>({
        query: listTransactions,
        variables: { filter, limit: 100000, nextToken: nextToken },
        authMode: 'userPool',
      });
      const currentNextToken = groupsList.data.listTransactions.nextToken;
      const responseListing = groupsList.data.listTransactions.items;

      let listing = [...transactionsListing, ...responseListing];
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAll(params: ListingVariables) {
    try {
      const { searchText, generalFilters } = params;
      let allListing: any[] = [];
      const filter: any = {};
      filter.or = [];
      filter.and = [];
      if (searchText.length > 0) {
        filter.or.push({ name: { contains: searchText.toLowerCase() } });
        filter.or.push({
          phone_number: { contains: searchText.toLowerCase() },
        });
      }
      if (generalFilters?.fromDate && generalFilters.toDate) {
        filter.and.push({
          createdAt: {
            ge: new Date(generalFilters?.fromDate).toISOString(),
          },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(generalFilters.toDate).toISOString(),
          },
        });
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const groupsList: any = await client.graphql<Transaction>({
        query: listTransactions,
        variables: { filter, limit: 10000 },
        authMode: 'userPool',
      });
      let currentNextToken = groupsList.data.listTransactions.nextToken;
      allListing = groupsList.data.listTransactions.items;
      while (currentNextToken) {
        const groupsListNew: any = await client.graphql<Transaction>({
          query: listTransactions,
          variables: { filter, limit: 10000, nextToken: currentNextToken },
          authMode: 'userPool',
        });
        allListing = [...allListing, groupsListNew.data.listTransactions.items];
        currentNextToken = groupsListNew.data.listTransactions.nextToken;
      }
      return allListing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: transactionGetVariables) {
    try {
      const { id, listing } = params;
      let single: Transaction | undefined;
      if (listing?.length) {
        single = listing.find((resource: any) => resource.id === id);
      }
      if (single === undefined) {
        const listing: any = await client.graphql<Transaction>({
          query: getTransaction,
          variables: { id },
          authMode: 'userPool',
        });
        single = listing.data.getTransaction;
      }
      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: transactionUpdateVariables) {
    try {
      const { data } = params;
      const original: any = await get(params);
      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateTransactionInput = {
        id: original.id,
        refunded_amount_cents: data.refunded_amount_cents
          ? data.refunded_amount_cents
          : original!.refunded_amount_cents,
        _version: original._version,
      };

      let updatedTransaction = await client.graphql({
        query: updateTransaction,
        variables: { input: updateInput },
        authMode: 'userPool',
      });

      showConfirm(`${singleName} has been updated successfully`);
      return updatedTransaction.data.updateTransaction;
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: any) {
    try {
      let exportedData: Transaction[] = [];
      const data: Transaction[] = await fetchAll(params);
      for (let user of data!) {
        let row: Transaction = { ...user };
        exportedData.push(row);
      }
      return exportedData;
    } catch (err: any) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'transactionID',
      numeric: false,
      disablePadding: false,
      label: 'Trx ID',
    },

    {
      id: 'guestName',
      numeric: false,
      disablePadding: false,
      label: 'Guest Name',
    },
    {
      id: 'guestPhone_number',
      numeric: false,
      disablePadding: false,
      label: 'Guest Phone Number',
    },
    {
      id: 'amount_cents',
      numeric: false,
      disablePadding: false,
      label: 'Amount',
    },
    {
      id: 'currency',
      numeric: false,
      disablePadding: false,
      label: 'Currency',
    },
    {
      id: 'success',
      numeric: false,
      disablePadding: false,
      label: 'Payment Status',
    },
    {
      id: 'failureReason',
      numeric: false,
      disablePadding: false,
      label: 'Details',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'type',
    },

    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Creation Date',
    },

    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];
  const dataCells: readonly string[] = [
    'transactionID',
    'guestName',
    'guestPhone_number',
    'amount_cents',
    'currency',
    'success',
    'failureReason',
    'type',
    'createdAt',
  ];

  const api: any = {};
  api[`${listingName}CreateSubscription`] = onCreateTransaction;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Update`] = update;
  api[`${listingName}Export`] = exportAll;

  api[`${listingName}ChangeListing`] = (listing: Transaction[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = transactionsListing;
  return api;
};
export default useTransaction;
