export const cashlessTopUpByEventIdCustom = /* GraphQL */ `
  query CashlessTopUpByEventID(
    $eventId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashlessTopUpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashlessTopUpByEventID(
      eventId: $eventId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        paymentMethod
        isVoided
        status
        type
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const cashlessTransactionByEventIdCustom = /* GraphQL */ `
  query CashlessTransactionByEventID(
    $eventId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashlessTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashlessTransactionByEventID(
      eventId: $eventId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderTotal
        paidAmount
        paymentStatus
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const cashlessTopUpByDeletedCustom = /* GraphQL */ `
  query CashlessTopUpByDeleted(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashlessTopUpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashlessTopUpByDeleted(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        operation
        amount
        availableBalance
        paymentChannel
        paymentMethod
        isVoided
        guestId
        guestPhoneNumber
        guestEmail
        bookingId
        invitationId
        ticketType
        booking {
          id
          balance
        }
        invitation {
          id
          balance
        }
        guest {
          email
        }
        eventId
        eventName
        status
        type
        syncOperationId
        deleted
        guestName
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const cashlessTransactionsByDeletedCustom = /* GraphQL */ `
  query CashlessTransactionsByDeleted(
    $deleted: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCashlessTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cashlessTransactionsByDeleted(
      deleted: $deleted
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pos
        checkNumber
        orderTotal
        paidAmount
        paymentStatus
        guestId
        guestPhoneNumber
        guestEmail
        guest {
          id
          name
          email
          phone_number
        }
        guestName
        type
        bookingId
        invitationId
        eventId
        event {
          id
          name
        }
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        syncOperationId
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
