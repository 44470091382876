import { useSelector } from 'react-redux';
import { OrderListingVariables, orderUpdateVariables } from '../models/app';
import useApp from './useApp';

const useOrder = (listingName: string, singleName: string) => {
  const { showConfirm, showError } = useApp();
  const serverAddress = useSelector((state: any) => state.server.serverAddress);

  async function fetchByBookingId(params: OrderListingVariables) {
    try {
      const { bookingId } = params;
      const response = await fetch(
        serverAddress + `/order?bookingId=${bookingId}`,
      );
      const responseJson = await response.json();
      if (response.ok) {
        const orders = responseJson.data.data;
        return orders;
      } else {
        throw new Error(responseJson?.message);
      }
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function deliver(params: orderUpdateVariables) {
    try {
      const { data } = params;

      const response = await fetch(serverAddress + `/order/${data.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const responseJson = await response.json();
      if (response.ok) {
        const updatedOrder = responseJson.data.data;
        showConfirm(`${singleName} has been delivered successfully`);
        return updatedOrder;
      } else {
        throw new Error(responseJson?.message);
      }
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: orderUpdateVariables) {
    try {
      const { data } = params;

      const response = await fetch(serverAddress + `/order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const responseJson = await response.json();
      if (response.ok) {
        const createdOrder = responseJson.data.data;
        return createdOrder;
      } else {
        throw new Error(responseJson?.message);
      }
    } catch (err) {
      showError(err);
      console.log({ err });
    }
  }

  const api: any = {};
  api[`${listingName}FetchByBookingId`] = fetchByBookingId;
  api[`${listingName}Deliver`] = deliver;
  api[`${listingName}Create`] = create;

  return api;
};
export default useOrder;
