import { generateClient } from '@aws-amplify/api';
import {
  CreateVariables,
  LanguageBulkTrashVariables,
  LanguageUpdateVariables,
  Option,
} from './../models/app';
import { useDispatch } from 'react-redux';
import { setListing, setSelected } from '../store/ducks/language';
import { Language, UpdateLanguageInput } from '../models/GQL_API';
import { HeadCell } from '../models/dataTable';
import useApp from './useApp';
import { LanguageGetVariables, ListingVariables } from '../models/app';
import { CreateLanguageInput } from '../models/GQL_API';
import { getLanguage, listLanguages } from '../graphql/queries';
import {
  createLanguage,
  deleteLanguage,
  updateLanguage,
} from '../graphql/mutations';

const client = generateClient();

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: ListingVariables) {
    // const { searchText, startIndex, limit } = params;

    try {
      // const listing = await DataStore.query(
      //   Language as any,
      //   (model: any) => {
      //     model.deleted("eq", "0");
      //     if (searchText.length > 0)
      //       model.name("contains", searchText.toLowerCase());
      //     return model;
      //   },
      //   {
      //     page: startIndex / limit,
      //     limit: limit,
      //     sort: (s) => s.createdAt(SortDirection.DESCENDING),
      //   }
      // );

      // dispatch(setListing(listing));

      const { searchText, limit } = params;
      const filter: any = {
        deleted: { eq: '0' },
      };
      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      const listing: any = await client.graphql<Language>({
        query: listLanguages,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });
      dispatch(setListing(listing.data.listLanguages.items));
      return listing.data.listLanguages.items;
    } catch (err) {
      showError(err);
    }
  }

  async function get(params: LanguageGetVariables) {
    const { id, listing } = params;

    try {
      // const single: Language | undefined =
      //   listing.length === 0
      //     ? await DataStore.query(Language as any, id)
      //     : listing.find((model: Language) => model.id === id);
      let single: Language | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await client.graphql<Language>({
          query: getLanguage,
          variables: { id },
          authMode: 'userPool',
        });
        single = listing.data.getLanguage;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateLanguageInput = {
        name: data.name,
        code: data.code,
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      // await DataStore.save(new Language(createInput as any));

      // showConfirm(`New ${singleName} has been created successfully`);

      await client.graphql<Language>({
        query: createLanguage,
        variables: { input: createInput },
        authMode: 'userPool',
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: LanguageUpdateVariables) {
    // const { id, listing, data } = params;
    const { data } = params;

    try {
      // const original = await get({ id, listing });

      // await DataStore.save(
      //   Language.copyOf(original!, (updated) => {
      //     updated.name = data.name ? data.name : original!.name;
      //     updated.code = data.code ? data.code : original!.code;
      //   })
      // );

      // showConfirm(`${singleName} has been updated successfully`);
      const original: any = await get(params);

      const updateInput: UpdateLanguageInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase() : original!.name,
        code: data.code ? data.code.toLowerCase() : original!.code,
        _version: original._version,
      };

      await client.graphql<Language>({
        query: updateLanguage,
        variables: { input: updateInput },
        authMode: 'userPool',
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: LanguageGetVariables) {
    try {
      // const original = await get(params);

      // await DataStore.save(
      //   Language.copyOf(original!, (updated) => {
      //     updated.deleted = "1";
      //   })
      // );

      // showConfirm(`${singleName} has been moved to trash successfully`);
      const original: any = await get(params);

      if (original) {
        const updateInput: UpdateLanguageInput = {
          id: original.id,
          deleted: '1',
          _version: original._version,
        };

        await client.graphql<Language>({
          query: updateLanguage,
          variables: { input: updateInput },
          authMode: 'userPool',
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: LanguageBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: LanguageGetVariables) {
    const { id, listing } = params;

    try {
      // await DataStore.delete(id as any);

      // dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      // showConfirm(`${singleName} has been deleted successfully`);
      await client.graphql<Language>({
        query: deleteLanguage,
        variables: { id: id },
        authMode: 'userPool',
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: Language[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  const dataCells: readonly string[] = ['name'];

  const api: any = {};

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Language[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));

  return api;
};

export default useResource;
