import { generateClient } from '@aws-amplify/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  cashlessTransactionByEventIdCustom,
  cashlessTransactionsByDeletedCustom,
} from '../constants/customQueries';
import {
  cashlessTransactionByGuestID,
  cashlessTransactionsByDeleted,
} from '../graphql/queries';
import {
  CashlessTransactionListingVariables,
  CashlessTransactionStatisticsByEventParams,
  CashlessTransactionStatisticsByEventResult,
  HeadCell,
} from '../models/app';
import {
  setFilter,
  setListing,
  setNextToken,
} from '../store/ducks/cashlessTransactions';
import { CashlessTransaction, ModelSortDirection } from './../models/GQL_API';
import useApp from './useApp';

const client = generateClient();
const useCashlessTransaction = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError } = useApp();
  const cashlessTransactionsFilter = useSelector(
    (state: any) => state.cashlessTransactions.filter,
  );

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]['nextToken'],
  );

  async function fetch(params: CashlessTransactionListingVariables) {
    try {
      const {
        searchText,
        limit,
        generalFilters,
        guestId,
        eventFilter,
        adminIdFilter,
        bookedTicketTypeFilter,
      } = params;

      const filter: any = {
        // deleted: { eq: '0' },
      };
      const createdAtFilter: any = {};
      filter.or = [];
      filter.and = [];
      if (searchText.length > 0) {
        filter.or.push({ pos: { contains: searchText } });
        filter.or.push({
          checkNumber: { contains: searchText },
        });
        filter.or.push({ guestPhoneNumber: { contains: searchText } });
        filter.or.push({ guestName: { contains: searchText } });
        filter.or.push({ guestEmail: { contains: searchText } });
        filter.or.push({ type: { contains: searchText } });
      }

      if (eventFilter) {
        filter.and.push({ eventId: { contains: eventFilter } });
      }

      if (adminIdFilter) {
        filter.and.push({ createdByID: { eq: adminIdFilter } });
      }

      if (bookedTicketTypeFilter) {
        filter.and.push({ type: { eq: bookedTicketTypeFilter } });
      }

      if (generalFilters?.fromDate && generalFilters.toDate) {
        const toDatePlusOneDay = new Date(generalFilters.toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        createdAtFilter.between = [
          new Date(generalFilters.fromDate).toISOString(),
          new Date(toDatePlusOneDay).toISOString(),
        ];
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const variables: any = {
        filter,
        limit: 100000,
        nextToken: nextToken,
        sortDirection: 'DESC',
        guestId,
      };

      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }

      const groupsList: any = await client.graphql<CashlessTransaction>({
        query: cashlessTransactionByGuestID,
        variables: variables,
        authMode: 'userPool',
      });
      const currentNextToken =
        groupsList.data.cashlessTransactionByGuestID.nextToken;
      const listing = groupsList.data.cashlessTransactionByGuestID.items;
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchSortedByDate(
    params: CashlessTransactionListingVariables,
  ) {
    try {
      const {
        searchText,
        limit,
        nextToken,
        generalFilters,
        eventFilter,
        adminIdFilter,
        bookedTicketTypeFilter,
      } = params;

      const filter: any = {};
      const createdAtFilter: any = {};
      filter.or = [];
      filter.and = [];
      let requestNextToken = nextToken;

      if (searchText.length > 0) {
        filter.or.push({ pos: { contains: searchText } });
        filter.or.push({
          checkNumber: { contains: searchText },
        });
        filter.or.push({ guestPhoneNumber: { contains: searchText } });
        filter.or.push({ guestName: { contains: searchText } });
        filter.or.push({ guestEmail: { contains: searchText } });
        filter.or.push({ type: { contains: searchText } });
      }

      if (eventFilter) {
        filter.and.push({ eventId: { contains: eventFilter } });
      }

      if (adminIdFilter) {
        filter.and.push({ createdByID: { eq: adminIdFilter } });
      }

      if (bookedTicketTypeFilter) {
        filter.and.push({ type: { eq: bookedTicketTypeFilter } });
      }

      if (generalFilters?.fromDate && generalFilters.toDate) {
        const fromDate = new Date(generalFilters.fromDate);
        const toDate = new Date(generalFilters.toDate);

        // Increment `toDate` by one day
        toDate.setDate(toDate.getDate() + 2);

        // Set hours, minutes, seconds, and milliseconds to zero for precise filtering
        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(0, 0, 0, 0);

        // Adjust `createdAtFilter.between` to use ISO strings of fromDate and toDate
        createdAtFilter.between = [
          fromDate.toISOString(),
          toDate.toISOString(),
        ];
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      if (
        cashlessTransactionsFilter &&
        cashlessTransactionsFilter.toString() !==
          [
            JSON.stringify(generalFilters),
            eventFilter,
            adminIdFilter,
            bookedTicketTypeFilter,
            searchText,
            limit,
            searchText,
          ].toString()
      ) {
        requestNextToken = null;
      }

      const variables: any = {
        filter,
        limit: 100000,
        sortDirection: 'DESC',
        deleted: '0',
      };

      if (requestNextToken) {
        variables.nextToken = requestNextToken;
      }

      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }

      const response: any = await client.graphql<CashlessTransaction>({
        query: cashlessTransactionsByDeletedCustom,
        variables: variables,
        authMode: 'userPool',
      });
      let currentNextToken =
        response.data.cashlessTransactionsByDeleted.nextToken;
      let listing = response.data.cashlessTransactionsByDeleted.items;

      dispatch(
        setFilter([
          JSON.stringify(generalFilters),
          eventFilter,
          adminIdFilter,
          bookedTicketTypeFilter,
          searchText,
          limit,
          searchText,
        ]),
      );

      return { listing, nextToken: currentNextToken };
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAllSortedByDate(
    params: CashlessTransactionListingVariables,
  ) {
    try {
      const {
        searchText,
        generalFilters,
        eventFilter,
        adminIdFilter,
        bookedTicketTypeFilter,
      } = params;

      const filter: any = {};
      const createdAtFilter: any = {};
      filter.or = [];
      filter.and = [];

      if (searchText.length > 0) {
        filter.or.push({ pos: { contains: searchText } });
        filter.or.push({
          checkNumber: { contains: searchText },
        });
        filter.or.push({ guestPhoneNumber: { contains: searchText } });
        filter.or.push({ guestName: { contains: searchText } });
        filter.or.push({
          guestEmail: { contains: searchText.toLowerCase() },
        });
        filter.or.push({
          type: { contains: searchText.toLowerCase() },
        });
      }

      if (eventFilter) {
        filter.and.push({ eventId: { contains: eventFilter } });
      }

      if (adminIdFilter) {
        filter.and.push({ createdByID: { eq: adminIdFilter } });
      }

      if (bookedTicketTypeFilter) {
        filter.and.push({ type: { eq: bookedTicketTypeFilter } });
      }

      if (generalFilters?.fromDate && generalFilters.toDate) {
        const fromDate = new Date(generalFilters.fromDate);
        const toDate = new Date(generalFilters.toDate);

        // Increment `toDate` by one day
        toDate.setDate(toDate.getDate() + 2);

        // Set hours, minutes, seconds, and milliseconds to zero for precise filtering
        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(0, 0, 0, 0);

        // Adjust `createdAtFilter.between` to use ISO strings of fromDate and toDate
        createdAtFilter.between = [
          fromDate.toISOString(),
          toDate.toISOString(),
        ];
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const variables: any = {
        filter,
        limit: 100000,
        sortDirection: 'DESC',
        deleted: '0',
      };

      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }

      const groupsList: any = await client.graphql<CashlessTransaction>({
        query: cashlessTransactionsByDeletedCustom,
        variables: variables,
        authMode: 'userPool',
      });
      let currentNextToken =
        groupsList.data.cashlessTransactionsByDeleted.nextToken;
      let listing = groupsList.data.cashlessTransactionsByDeleted.items;
      while (currentNextToken) {
        const response: any = await client.graphql<CashlessTransaction>({
          query: cashlessTransactionsByDeleted,
          variables: variables,
          authMode: 'userPool',
        });
        currentNextToken =
          response.data.cashlessTransactionsByDeleted.nextToken;
        listing = listing.concat(
          response.data.cashlessTransactionsByDeleted.items,
        );
      }
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchTransactionStatisticsByEvent(
    params: CashlessTransactionStatisticsByEventParams,
  ) {
    const statisticsResult: CashlessTransactionStatisticsByEventResult = {
      totalRevenue: 0,
    };
    try {
      // fetch all topups by event id
      const allEventTransactions: CashlessTransaction[] =
        await fetchAllTransactionByEventId(params);

      for (const transaction of allEventTransactions) {
        console.log({ transaction });
        statisticsResult.totalRevenue += transaction.paidAmount;
      }

      return statisticsResult;
    } catch (err) {
      console.log({ err });
      return statisticsResult;
    }
  }

  async function fetchAllTransactionByEventId(
    params: CashlessTransactionStatisticsByEventParams,
  ) {
    try {
      const { eventId, adminId } = params;
      if (!eventId) return [];
      const filter: any = { deleted: { eq: '0' } };
      if (adminId) {
        filter.createdByID = { eq: adminId };
      }
      let allListing: CashlessTransaction[] = [];
      let nextToken = undefined;

      do {
        const variables = {
          filter,
          sortDirection: ModelSortDirection.DESC,
          limit: 100000,
          nextToken,
          eventId,
        };
        const response: any = await client.graphql<CashlessTransaction>({
          query: cashlessTransactionByEventIdCustom,
          variables: variables,
          authMode: 'userPool',
        });
        allListing.push(...response.data.cashlessTransactionByEventID.items);
        nextToken = response.data.cashlessTransactionByEventID.nextToken;
      } while (nextToken);

      return allListing;
    } catch (err) {
      console.log({ err });
      return [];
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'guest',
      numeric: false,
      disablePadding: false,
      label: 'Guest Name',
    },
    {
      id: 'guest',
      numeric: false,
      disablePadding: false,
      label: 'Guest Phone',
    },
    {
      id: 'guest',
      numeric: false,
      disablePadding: false,
      label: 'Guest Email',
    },
    // {
    //   id: 'pos',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'POS',
    // },
    // {
    //   id: 'checkNumber',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Check #',
    // },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
    },
    {
      id: 'event',
      numeric: false,
      disablePadding: false,
      label: 'Event Name',
    },
    {
      id: 'orderTotal',
      numeric: false,
      disablePadding: false,
      label: 'Order Total',
    },
    {
      id: 'paidAmount',
      numeric: false,
      disablePadding: false,
      label: 'Paid Amount',
    },
    // {
    //   id: 'paymentStatus',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Payment Status',
    // },
    {
      id: 'createdAt',
      numeric: true,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'createdByName',
      numeric: true,
      disablePadding: false,
      label: 'Created By',
    },
  ];
  const dataCells: readonly string[] = [
    'POS',
    'checkNumber',
    'event',
    'orderTotal',
    'paidAmount',
    // 'paymentStatus',
    'createdAt',
    'createdByName',
  ];
  const api: any = {};

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchSortedByDate`] = fetchSortedByDate;
  api[`${listingName}FetchAllSortedByDate`] = fetchAllSortedByDate;
  api[`${listingName}FetchStatisticsByEvent`] =
    fetchTransactionStatisticsByEvent;
  api[`${listingName}ChangeListing`] = (listing: CashlessTransaction[]) =>
    dispatch(setListing(listing));
  return api;
};
export default useCashlessTransaction;
