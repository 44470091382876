// actions
const SET = 'rejectionReasons/SET';
const SET_ALL = 'rejectionReasons/SET_ALL';
const SET_SELECTED = 'rejectionReasons/SET_SELECTED';

const CHANGE_LIMIT = 'rejectionReasons/CHANGE_LIMIT';
const NEXT_ACTION = 'rejectionReasons/NEXT_ACTION';
const SET_NEXT_TOKEN = 'rejectionReasons/SET_NEXT_TOKEN';
const SET_PREVIOUS_TOKENS = 'rejectionReasons/SET_PREVIOUS_TOKENS';

const DEFAULT_STATE = {
  listing: [],
  listingAll: [],
  selected: '',

  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_ALL:
      return Object.assign({}, state, { listingAll: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, {
        selected: action.concept,
      });

    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.nextToken });
    case SET_PREVIOUS_TOKENS:
      return Object.assign({}, state, {
        previousTokens: action.previousTokens,
      });
    case CHANGE_LIMIT:
      return Object.assign({}, state, {
        limit: action.limit,
        nextToken: action.nextToken,
        nextNextToken: action.nextNextToken,
        previousTokens: action.previousTokens,
      });

    case NEXT_ACTION:
      return Object.assign({}, state, {
        nextToken: action.nextToken,
        previousTokens: action.previousTokens,
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setListingAll = (listing: any[]) => ({ listing, type: SET_ALL });
export const setSelected = (single: any) => ({ single, type: SET_SELECTED });

export const changeLimit = (limit: any) => ({
  limit: limit,
  nextToken: null,
  nextNextToken: null,
  previousTokens: [],
  type: CHANGE_LIMIT,
});
export const nextAction = (nextToken: any, previousTokens: any[]) => ({
  nextToken: nextToken,
  previousTokens: previousTokens ? [...previousTokens, nextToken] : [nextToken],
  type: NEXT_ACTION,
});
export const setNextToken = (nextToken: any) => ({
  nextToken,
  type: SET_NEXT_TOKEN,
});
export const setPreviousToken = (previousTokens: any) => ({
  previousTokens,
  type: SET_PREVIOUS_TOKENS,
});
