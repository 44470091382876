import React, { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import RedeemIcon from '@mui/icons-material/Redeem';
import { Booking, Guest, GuestGroup } from '../../models/GQL_API';
import { capitalizeSentence } from '../../helpers/utils';
import useGuest from '../../hooks/useGuest';
import useApp from '../../hooks/useApp';
import { useDispatch, useSelector } from 'react-redux';
import useCashlessTopups from '../../hooks/useCashlessTopups';
import {
  CashlessType,
  PaymentMethod,
  cloudinaryURL,
} from '../../constants/enums';
import { AdminPermissions, GuestGroupListingVariables } from '../../models/app';
import useGuestGroup from '../../hooks/useGuestGroup';
import { checkInternet } from '../../helpers/checkInternet';
import { setModalOpen } from '../../store/ducks/server';
import useBooking from '../../hooks/useBooking';
import useInvitation from '../../hooks/useInvitaion';
import { Invitation } from '../../models';

interface Props {
  setUserBalance: (newBalance: number) => void;
  userBalance: number;
}

const UserCharge: React.FC<Props> = ({ setUserBalance, userBalance }) => {
  const dispatch = useDispatch();
  const [fetchedGuest, setFetchedGuest] = useState<any | null>();
  const [fetchedGuestTotalOrders, setFetchedGuestTotalOrders] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedBooking, setSelectedBooking] = useState<
    Booking | Invitation | null
  >();
  const [initialAmount, setInitialAmount] = useState(true);
  const [bookedTicketType, setBookedTicketType] = useState<string>(
    CashlessType.BOOKING,
  );
  const [amount, setAmount] = useState<string | number>('');
  const [loading, setLoading] = useState(false);
  const [userGroup, setUserGroup] = useState<GuestGroup>();
  const SearchForUserField = useRef<any>();
  // const [userBalance, setUserBalance] = useState(0);
  const cashlessTopupsListing = useSelector(
    (state: any) => state.cashlessTopups.listing,
  );
  const session = useSelector((state: any) => state.app.session);
  const serverAddress = useSelector((state: any) => state.server.serverAddress);
  const serverStatus = useSelector((state: any) => state.server.serverStatus);
  const userPermissions: AdminPermissions = useSelector(
    (state: any) => state.app.userPermissions,
  );
  const { guestsFetchByPhoneNumber, guestsFetchGuestOfflineByPhone } = useGuest(
    'guests',
    'guest',
  );
  const { bookingsGet, bookingsFetchBookingByIdOffline } = useBooking(
    'bookings',
    'booking',
  );
  const { invitationsGet, invitationsFetchInvitationByIdOffline } =
    useInvitation('invitations', 'invitation');
  const { guestGroupsFetch } = useGuestGroup('guestGroups', 'guestGroup');
  const {
    cashlessTopupsGetUserOrdersCount,
    cashlessTopupsCreate,
    cashlessTopupsGetUserUpcomingBookingBalance,
  } = useCashlessTopups('cashlessTopups', 'cashlessTopup');
  const { showWarning, showError } = useApp();

  // const handlePhoneNumberChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  // ) => {
  //   const inputValue = e.target.value;
  //   // const numericValue = inputValue.replace(/\D/g, '');
  //   setPhoneNumber(inputValue);
  // };

  const handlePhoneNumberChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const inputValue = e.target.value.toLowerCase();
    // const urlPattern = /https:\/\/ulter\.anyware\.software\/\?id=([a-f0-9-]+)/;

    // Dev
    // const urlPattern =
    //   /https:\/\/ticketing-system-app-eight\.vercel\.app\/\?id=([a-f0-9-]+)/;

    // Prod
    const urlPattern = /https:\/\/www\.ascai\.tickets\/\?id=([a-f0-9-]+)/;

    let newValue = inputValue;
    // Check if the input matches the URL pattern
    const match = inputValue.match(urlPattern);
    if (match) {
      newValue = match[1];
    }
    setPhoneNumber(newValue);
  };

  const handleSearch = () => {
    if (!serverStatus) {
      dispatch(setModalOpen(true));
      return;
    }
    let validPhoneNumber = '';
    let ticketId = '';
    if (!phoneNumber?.length) {
      showError('Please insert a phone number or a Ticket Id');
      return;
    }
    const validPrefixes = ['010', '011', '012', '015'];
    const startsWithValidPrefix = validPrefixes.some((prefix) =>
      phoneNumber.startsWith(prefix),
    );
    const isValidLength = phoneNumber.length === 11;
    if (startsWithValidPrefix && !isValidLength) {
      showError('Please insert a valid phone number');
      return;
    }
    if (startsWithValidPrefix && isValidLength) {
      validPhoneNumber = phoneNumber;
    } else {
      ticketId = phoneNumber;
    }
    fetchGuest({ validPhoneNumber, ticketId });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const fetchGuest = async ({ validPhoneNumber, ticketId }: any) => {
    try {
      setLoading(true);
      let internet = false;
      let type = CashlessType.BOOKING;

      // must ping the server to check if it is still connected if not open the modal
      if (!serverStatus) {
        internet = await checkInternet();
      }
      if (internet) {
        let guest: any;
        let userGroups: any;
        let balance = 0;
        if (validPhoneNumber) {
          const [guestFetched, guestOrdersCount, userGroupsFetched] =
            await Promise.all([
              guestsFetchByPhoneNumber({ phone_number: validPhoneNumber }),
              cashlessTopupsGetUserOrdersCount(
                validPhoneNumber,
                cashlessTopupsListing,
              ),
              fetchGuestGroup(),
            ]);
          guest = guestFetched;
          userGroups = userGroupsFetched;
          balance = await cashlessTopupsGetUserUpcomingBookingBalance(guest.id);
          setSelectedBooking(null);
          setFetchedGuest(guest);
          setFetchedGuestTotalOrders(guestOrdersCount);
        } else if (ticketId) {
          let [bookingFetched, userGroupsFetched] = await Promise.all([
            bookingsGet({ id: ticketId, listing: [] }),
            fetchGuestGroup(),
          ]);
          if (!bookingFetched) {
            type = CashlessType.INVITATION;
            bookingFetched = await invitationsGet({
              id: ticketId,
              listing: [],
            });
          }
          const invitationGuest = {
            name: 'Invitation Guest',
            contactInfo: bookingFetched.phone_number
              ? bookingFetched.phone_number
              : bookingFetched.email,
            guest_avatar: '',
          };
          guest =
            type === CashlessType.BOOKING
              ? bookingFetched.guest
              : invitationGuest;
          userGroups = userGroupsFetched;
          balance = bookingFetched.balance ?? 0;
          setSelectedBooking(bookingFetched);
          let guestOrdersCount = 0;
          if (type === CashlessType.BOOKING) {
            guestOrdersCount = await cashlessTopupsGetUserOrdersCount(
              guest.phone_number,
              cashlessTopupsListing,
            );
          }
          setFetchedGuest(guest);
          setFetchedGuestTotalOrders(guestOrdersCount);
        }
        if (!guest && type !== CashlessType.INVITATION) {
          showWarning(`There is no user with this phone number`);
          setAmount('');
          setInitialAmount(true);
          setLoading(false);
          return;
        }
        setUserBalance(balance);
        let userGroupItem = userGroups.find(
          (group: GuestGroup) => guest?.guestGroupID === group.id,
        );
        setUserGroup(userGroupItem);
        setInitialAmount(true);
        setAmount('');
      } else {
        let user: any;
        let balance: any;
        if (validPhoneNumber) {
          const [guestOrdersCount] = await Promise.all([
            cashlessTopupsGetUserOrdersCount(
              validPhoneNumber,
              cashlessTopupsListing,
            ),
          ]);
          user = await guestsFetchGuestOfflineByPhone(validPhoneNumber);
          setFetchedGuest({
            id: user.userId,
            guest_avatar: user.userImage,
            email: user.userEmail,
            name: user.userName,
          });
          setFetchedGuestTotalOrders(guestOrdersCount);
          balance = await cashlessTopupsGetUserUpcomingBookingBalance(
            user.userId,
          );
        } else if (ticketId) {
          try {
            user = await bookingsFetchBookingByIdOffline(ticketId);
          } catch (err) {
            type = CashlessType.INVITATION;
            user = await invitationsFetchInvitationByIdOffline(ticketId);
          }
          setSelectedBooking(user);
          let guestOrdersCount = 0;
          if (type === CashlessType.BOOKING) {
            guestOrdersCount = await cashlessTopupsGetUserOrdersCount(
              user.userPhone,
              cashlessTopupsListing,
            );
          }
          setFetchedGuest({
            id: user.userId,
            guest_avatar: user.userImage,
            email: user.userEmail,
            name: user.userName,
          });
          setFetchedGuestTotalOrders(guestOrdersCount);
          if (type === CashlessType.BOOKING) {
            balance = await cashlessTopupsGetUserUpcomingBookingBalance(
              user.userId,
            );
          } else if (type === CashlessType.INVITATION) {
            balance = user.balance;
          }
        }
        if (!user) {
          showWarning(`There is no user with this phone number`);
          setAmount('');
          setInitialAmount(true);
          setLoading(false);
          return;
        }
        setUserBalance(balance);
        setUserGroup(undefined);
        setInitialAmount(true);
        setAmount('');
      }
      setBookedTicketType(type);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log({ err });
      setFetchedGuest(null);
      setSelectedBooking(null);
      showWarning(err.message);
    }
  };
  console.log({ fetchedGuest, selectedBooking });
  const fetchGuestGroup = async () => {
    try {
      const guestGroupsParams: GuestGroupListingVariables = {
        searchText: '',
        startIndex: 0,
        limit: 1000,
      };
      const userGroups = await guestGroupsFetch(guestGroupsParams);
      return userGroups;
    } catch (err: any) {
      setLoading(false);
      console.log({ err });
      showError(err.message);
    }
  };

  // const handleAmountChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  // ) => {
  //   const newAmountValue = Number(e.target.value);
  //   if (initialAmount) {
  //     if (e.target.value === '2') {
  //       setAmount(2);
  //     } else {
  //       setAmount(Number(e.target.value.slice(1, 2)));
  //     }
  //     setInitialAmount(false);
  //   } else {
  //     setAmount(newAmountValue);
  //   }
  //   if (e.target.value === '' || e.target.value === '0') {
  //     setInitialAmount(true);
  //     setAmount(0);
  //   }
  // };
  const handleAmountChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newAmountValue = Number(e.target.value);
    setAmount(newAmountValue);
  };
  // const handleAmountChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  // ) => {
  //   const newAmountValue = Number(e.target.value);
  //   if (newAmountValue <= 0) return;
  //   setAmount(newAmountValue);
  // };

  const handleClearData = () => {
    setUserBalance(0);
    setFetchedGuest(null);
    setFetchedGuestTotalOrders(0);
    setPhoneNumber('');
    SearchForUserField?.current?.focus();
  };

  const createCashlessTopUp = async (
    amount: number | string,
    paymentMethod: string,
  ) => {
    try {
      setLoading(true);
      let numericAmount = Number(amount);
      if (!numericAmount || numericAmount < 1)
        throw new Error('You must enter at least 1 EGP.');
      console.log({
        fetchedGuestID: fetchedGuest?.id !== '' ? fetchedGuest.id : '',
      });
      const data = {
        amount,
        paymentMethod,
        guestId: fetchedGuest?.id !== '' ? fetchedGuest.id : '',
        booking: selectedBooking,
        type: bookedTicketType,
      };
      const params = { userID: session.sub, userName: session.name, data };
      const createdCashlessTopUp = await cashlessTopupsCreate(params);
      // setUserBalance(
      //   createdCashlessTopUp ? createdCashlessTopUp.availableBalance : 0,
      // );
      // setFetchedGuestTotalOrders((prev) => prev + 1);

      // clear data after charge
      handleClearData();

      setAmount('');
      setInitialAmount(true);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log({ err });
      showError(err.message);
    }
  };

  const handleCreateCashlessTopUp = (paymentMethod: string) => {
    let numericAmount = Number(amount);
    if (!numericAmount || numericAmount <= 0) {
      showError('Amount must be greater than 0');
      return;
    }
    if (!fetchedGuest) {
      showError('You must search for a guest first');
      return;
    }
    // const target = e.target as HTMLButtonElement;
    // const paymentMethod = target.id;
    // console.log({ paymentMethod, target });
    createCashlessTopUp(amount, paymentMethod);
  };
  return (
    <Grid
      item
      xs={12}
      sm={12}
      lg={12}
      sx={{ my: { xs: 0, sm: 3 }, mx: { xs: 0, sm: 3 } }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '20px',
              ml: 0.5,
            }}
          >
            Search Guest
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <TextField
              name="phone"
              autoComplete="false"
              onChange={handlePhoneNumberChange}
              onKeyDown={handleKeyPress}
              value={phoneNumber}
              focused={false}
              inputRef={SearchForUserField}
              autoFocus
              sx={{
                backgroundColor: 'secondary.main',
                'input::placeholder': {
                  color: 'white',
                },
                input: {
                  color: 'white',
                },
                border: '1px solid',
                borderColor: 'secondary.main',
                width: { xs: 'auto', sm: '15rem' },
                borderRadius: '10px',
                '.MuiInputBase-root': {
                  borderRadius: '10px',
                },
                mt: 2,
              }}
              placeholder="Guest Phone / Ticket Id"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearData} edge="end">
                      <CloseIcon style={{ color: 'grey' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              onClick={handleSearch}
              disabled={loading}
              sx={{
                backgroundColor: 'secondary.contrastText',
                color: 'primary.main',
                borderRadius: '10px',
                py: 1.5,
                mt: 2,
                textTransform: 'capitalize',
              }}
            >
              Search
            </Button>

            <Button
              variant="contained"
              color="warning"
              onClick={() => dispatch(setModalOpen(true))}
              sx={{
                backgroundColor: 'warning.main',
                textTransform: 'capitalize',
                borderRadius: '10px',
                py: 1.5,
                mt: 2,
              }}
            >
              Configure Server
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 3,
          }}
        >
          {fetchedGuest && (
            <Box>
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '20px',
                  ml: 0.5,
                  my: { xs: 2, sm: 0 },
                }}
              >
                Guest Info
              </Typography>
              <Box
                sx={{
                  backgroundColor: 'secondary.dark',
                  border: '1px solid',
                  borderColor: 'secondary.contrastText',
                  borderRadius: '5px',
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  sx={{ width: 80, height: 80, mb: 3 }}
                  alt={fetchedGuest?.name?.toUpperCase()}
                  // src={
                  //   fetchedGuest.images?.[0]
                  //     ? cloudinaryURL + fetchedGuest.images[0]
                  //     : `/static/images/avatar/1.jpg`
                  // }
                  src={
                    fetchedGuest.guest_avatar?.includes('platform')
                      ? `${fetchedGuest.guest_avatar}`
                      : `${cloudinaryURL}${fetchedGuest.guest_avatar}`
                  }
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                    justifyContent: 'start',
                    width: '100%',
                  }}
                >
                  <Typography>Name</Typography>
                  <Typography>
                    {capitalizeSentence(fetchedGuest.name ?? '')}
                  </Typography>
                  {fetchedGuest.guestGroupName && (
                    <Box
                      sx={{
                        backgroundColor: userGroup?.color,
                        borderRadius: '15px',
                        px: 3,
                        py: 0.5,
                        color: 'secondary.dark',
                      }}
                    >
                      {capitalizeSentence(
                        fetchedGuest.guestGroupName.toUpperCase(),
                      )}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    width: '100%',
                    gap: 3,
                    mt: 1,
                  }}
                >
                  <Typography>
                    {fetchedGuest?.contactInfo ? 'Contact' : 'Email'}
                  </Typography>
                  <Typography>
                    {fetchedGuest?.contactInfo
                      ? fetchedGuest?.contactInfo
                      : fetchedGuest?.email}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 3,
                    mt: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>Total Orders</Typography>
                    <Typography>{fetchedGuestTotalOrders}</Typography>
                  </Box>

                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      backgroundColor: 'primary.main',
                    }}
                  />

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography>Available Balance</Typography>
                    <Typography>{userBalance} EGP</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {fetchedGuest && (
            <Box>
              <Box
                sx={{
                  backgroundColor: 'secondary.dark',
                  border: '1px solid',
                  borderColor: 'secondary.contrastText',
                  borderRadius: '5px',
                  p: { xs: 1.75, sm: 3 },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  py: 6,
                  mt: 3.5,
                  height: '42vh',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '25px',
                  }}
                >
                  Enter Amount
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <TextField
                    name="amount"
                    autoComplete="false"
                    type="number"
                    focused={false}
                    sx={{
                      backgroundColor: 'secondary.main',
                      'input::placeholder': {
                        color: 'white',
                      },
                      input: {
                        color: 'white',
                      },
                      border: '1px solid',
                      borderColor: 'secondary.main',
                      width: { xs: 'auto', sm: '20.5rem' },
                      borderRadius: '10px',
                      '.MuiInputBase-root': {
                        borderRadius: '10px',
                      },
                      mt: 2,
                    }}
                    value={amount ? amount : ''}
                    onChange={handleAmountChange}
                    placeholder="Enter amount here"
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleCreateCashlessTopUp(PaymentMethod.CASH)
                      }
                      disabled={loading}
                      id={PaymentMethod.CASH}
                      sx={{
                        backgroundColor: 'secondary.dark',
                        border: '1px solid #0F910C',
                        color: 'primary.main',
                        borderRadius: '10px',
                        py: 1.5,
                        width: { xs: 'auto', sm: '10rem' },
                        textTransform: 'capitalize',
                      }}
                      startIcon={<LocalAtmIcon />}
                    >
                      Charge Cash
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleCreateCashlessTopUp(PaymentMethod.VISA)
                      }
                      disabled={loading}
                      id={PaymentMethod.VISA}
                      sx={{
                        backgroundColor: 'secondary.dark',
                        border: '1px solid #0F910C',
                        color: 'primary.main',
                        borderRadius: '10px',
                        py: 1.5,
                        width: { xs: 'auto', sm: '10rem' },
                        textTransform: 'capitalize',
                      }}
                      startIcon={<CreditCardIcon />}
                    >
                      Charge Visa
                    </Button>
                    {userPermissions.can_view_dispatcher === true && (
                      <Button
                        variant="contained"
                        onClick={() =>
                          handleCreateCashlessTopUp(PaymentMethod.COMPLEMENTARY)
                        }
                        disabled={loading}
                        id={PaymentMethod.COMPLEMENTARY}
                        sx={{
                          backgroundColor: 'secondary.dark',
                          border: '1px solid #0F910C',
                          color: 'primary.main',
                          borderRadius: '10px',
                          py: 1.5,
                          width: { xs: 'auto', sm: '10rem' },
                          textTransform: 'capitalize',
                        }}
                        startIcon={<RedeemIcon />}
                      >
                        Complementary
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
};
export default UserCharge;
