import { useEffect, useState } from 'react';
import { Token } from '../models/app';
import { useParams } from 'react-router-dom';
import { Pages } from '../constants/enums';
import { useSelector } from 'react-redux';

interface StateProps {
  startIndex: number;
  limit: number;
  nextToken: Token;
  nextNextToken: Token;
  previousTokens: Token[];
  moveForward: boolean;
}

const INITIAL_STATE: StateProps = {
  startIndex: 0,
  limit: 10,
  nextToken: undefined,
  nextNextToken: undefined,
  previousTokens: [],
  moveForward: true,
};

const INITIAL_STATE_Concepts: StateProps = {
  startIndex: 0,
  limit: 20,
  nextToken: undefined,
  nextNextToken: undefined,
  previousTokens: [],
  moveForward: true,
};

const usePaginate = () => {
  let { slug } = useParams();

  const [state, setState] = useState(
    slug !== Pages.CONCEPTS ? INITIAL_STATE : INITIAL_STATE_Concepts,
  );
  const { nextToken, nextNextToken, previousTokens, limit } = state;
  const generalFilters = useSelector((state: any) => state.generalFilters);

  useEffect(() => {
    const resetPagination = () => {
      setState(
        slug === Pages.CONCEPTS ? INITIAL_STATE_Concepts : INITIAL_STATE,
      );
    };

    resetPagination();
  }, [generalFilters, slug]); // Run effect when generalFilters or slug changes
  return {
    ...state,
    hasNext: !!state.nextNextToken,
    hasPrev: state.previousTokens.length > 0,
    changeLimit: (limit: number) =>
      setState(
        slug !== Pages.CONCEPTS
          ? { ...INITIAL_STATE, limit }
          : { ...INITIAL_STATE_Concepts, limit },
      ),
    changeNextNextToken: (nextNextToken: Token) =>
      setState((prevState) => ({ ...prevState, nextNextToken })),
    next: () =>
      setState((state) => ({
        ...state,
        moveForward: true,
        startIndex: state.startIndex + state.limit,
      })),
    tokenNext: () =>
      setState({
        ...state,
        previousTokens: [...previousTokens, nextToken],
        nextToken: nextNextToken,
        nextNextToken: undefined,
      }),
    prev: () =>
      setState((state) => ({
        ...state,
        moveForward: false,
        startIndex: state.startIndex - state.limit,
      })),
    tokenPrev: () =>
      setState({
        ...state,
        nextToken: previousTokens.pop(),
        previousTokens: [...previousTokens],
        nextNextToken: undefined,
      }),
    reset: () =>
      setState(
        slug === Pages.CONCEPTS ? INITIAL_STATE_Concepts : INITIAL_STATE,
      ),
  };
};

export default usePaginate;
