import { Pages } from '../../constants/enums';
import { Concept } from '../../models/GQL_API';
import useApp from '../../hooks/useApp';
import useConcept from '../../hooks/useConcept';

import Location from './Location';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
// import useTimeSlot from "../../hooks/useTimeSlot";
import { persistSelectedConcept } from '../../helpers/utils';

interface Props {
  handleClose?: () => void;
}

const MultiConcepts: React.FC<Props> = ({ handleClose }) => {
  const session = useSelector((state: any) => state.app.session);
  const userConcepts = useSelector((state: any) => state.app.concepts);
  const conceptsListing = useSelector((state: any) => state.concepts.listing);
  const conceptsSelected = useSelector((state: any) => state.concepts.selected);
  const { navigateTo } = useApp();
  const { conceptsChangeSelected } = useConcept('concepts', 'concept');
  // const { timeSlotsChangeSelected } = useTimeSlot("timeSlots", "timeSlot");

  const accountsSelected = useSelector((state: any) => state.accounts.selected);
  const featuresAllListing = useSelector(
    (state: any) => state.features.listingAll,
  );

  function checkFeature(slug: any): Boolean {
    let feature: any[] = [];
    if (accountsSelected && accountsSelected.features) {
      feature = featuresAllListing.filter(function (feature: any) {
        return (
          slug.toLowerCase() === feature.slug.toLowerCase() &&
          accountsSelected.features.indexOf(feature.id) !== -1
        );
      });
    }
    if (feature.length > 0) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    for (var j = 0; j < conceptsListing.length; j++) {
      if (
        userConcepts &&
        userConcepts.concepts &&
        userConcepts.concepts.includes(conceptsListing[j].id)
      ) {
        conceptsChangeSelected(persistSelectedConcept(conceptsListing[j].id));
        break;
      }
    }

    // eslint-disable-next-line
  }, [conceptsListing]);
  const handleClick = (event: any, id: string) => {
    // timeSlotsChangeSelected("");
    conceptsChangeSelected(id);

    if (
      session['cognito:groups'].includes('admin') &&
      checkFeature(Pages.BOOKINGS)
    ) {
      navigateTo(`/dashboard/${Pages.BOOKINGS}`);
    } else if (
      session['cognito:groups'].includes('admin') &&
      checkFeature(Pages.WAITER_ORDER)
    ) {
      navigateTo(`/dashboard/${Pages.Analysis}`);
    }
  };

  if (
    session &&
    session['cognito:groups'] &&
    session['cognito:groups'].length > 0 &&
    (session['cognito:groups'].includes('admin') ||
      session['cognito:groups'].includes('staff'))
  )
    return (
      <>
        {conceptsListing
          .sort((a: any, b: any) => a.precedence - b.precedence)
          .map((concept: Concept) => {
            if (
              userConcepts &&
              userConcepts.concepts &&
              userConcepts.concepts.includes(concept.id)
            ) {
              return (
                <Location
                  key={concept.id}
                  concept={concept}
                  isSelected={conceptsSelected === concept.id}
                  onClick={(event: React.MouseEvent<unknown>) => {
                    handleClick(event, concept.id);
                    if (handleClose) handleClose();
                  }}
                />
              );
            } else {
              return null;
            }
          })}
      </>
    );

  return null;
};

export default MultiConcepts;
