import { useDispatch, useSelector } from 'react-redux';
import { generateClient } from '@aws-amplify/api';
import {
  setListing,
  setSelected,
  setNextToken,
} from '../store/ducks/guestGroup';
import {
  GuestGroup,
  CreateGuestGroupInput,
  UpdateGuestGroupInput,
  Guest,
} from '../models/GQL_API';
import { extractSelectedCheckboxes } from '../helpers/utils';
import {
  GuestGroupBulkTrashVariables,
  GuestGroupGetVariables,
  GuestGroupUpdateVariables,
  GuestGroupListingVariables,
  CreateVariables,
  Option,
  HeadCell,
} from '../models/app';
import useApp from './useApp';
import {
  createGuestGroup,
  deleteGuestGroup,
  updateGuestGroup,
} from '../graphql/mutations';
import { getGuestGroup, listGuestGroups } from '../graphql/queries';
import { onCreateGuestGroup } from '../graphql/subscriptions';

const client = generateClient();

const useGuestGroup = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]['nextToken'],
  );
  const guestGroupsListing = useSelector(
    (state: any) => state.guestGroups.listing,
  );

  async function fetch(params: GuestGroupListingVariables) {
    const { searchText, limit, guestID } = params;
    try {
      const filter: any = {
        deleted: { eq: '0' },
        name: { contains: searchText.toLowerCase() },
      };
      if (guestID) {
        filter.guests = { contains: guestID };
      }
      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      const groupsList: any = await client.graphql<GuestGroup>({
        query: listGuestGroups,
        variables: { filter, limit: 100000, nextToken: nextToken },
        authMode: 'userPool',
      });
      const currentNextToken = groupsList.data.listGuestGroups.nextToken;
      const responseListing = groupsList.data.listGuestGroups.items;

      let listing = [...guestGroupsListing, ...responseListing];

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }
  async function fetchAll(params: GuestGroupListingVariables) {
    try {
      const { searchText } = params;
      const filter: any = {
        deleted: { eq: '0' },
        name: { contains: searchText.toLowerCase() },
      };
      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      // if (guestGroupsListing.length === 0 || guestID !== guestGroupsListing[0].guestID) {
      const listing: any = await client.graphql<GuestGroup>({
        query: listGuestGroups,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });

      const listSorted = listing.data.listGuestGroups.items.sort(
        (a: any, b: any) => {
          return a.precedence - b.precedence;
        },
      );
      return listSorted;
    } catch (err) {
      showError(err);
    }
  }
  async function fetchCurrentUser(session: string) {
    try {
      const filter: any = {
        deleted: { eq: '0' },
        guests: { contains: session },
      };
      const groupsList: any = await client.graphql<GuestGroup>({
        query: listGuestGroups,
        variables: { filter, nextToken: nextToken, limit: 100000 },
        authMode: session ? 'userPool' : 'iam',
      });
      const currentNextToken = groupsList.data.listGuestGroups.nextToken;
      const listing = groupsList.data.listGuestGroups.items;
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }
  async function fetchUsersInGroup(params: any) {
    try {
      const { groupName } = params;
      const filter: any = {
        deleted: { eq: '0' },
      };
      if (groupName) {
        filter.name = { contains: groupName };
      }
      const groupsList: any = await client.graphql<GuestGroup>({
        query: listGuestGroups,
        variables: { filter, nextToken: nextToken, limit: 100000 },
        authMode: 'userPool',
      });
      const currentNextToken = groupsList.data.listGuestGroups.nextToken;
      const listing = groupsList.data.listGuestGroups.items;
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }
  const getName = (params: GuestGroupGetVariables) => {
    const { id, listing } = params;
    if (listing.length > 0) {
      const model = listing.find((model: GuestGroup) => model.id === id);
      return model ? model.name : '';
    }
    return '';
  };
  async function get(params: GuestGroupGetVariables) {
    try {
      const { id, listing } = params;
      let single: GuestGroup | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }
      if (single === undefined) {
        const listing = await client.graphql({
          query: getGuestGroup,
          variables: { id },
          authMode: 'userPool',
        });
        single = listing.data.getGuestGroup!;
      }
      return single;
    } catch (err) {
      showError(err);
    }
  }
  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    const users: string[] = extractSelectedCheckboxes('guests_', data);
    try {
      const createInput: CreateGuestGroupInput = {
        name: data.name.toLowerCase(),
        description: data.description,
        color: data.color,
        guests: users,
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      await client.graphql<GuestGroup>({
        query: createGuestGroup,
        variables: { input: createInput },
        authMode: 'userPool',
      });
      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      throw err;
    }
  }
  async function update(params: GuestGroupUpdateVariables) {
    const { id, listing, data } = params;
    const users: string[] = extractSelectedCheckboxes('guests_', data);
    try {
      const original: any = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }
      const updateInput: UpdateGuestGroupInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase() : original!.name,
        color: data.color ? data.color : original!.color,
        description: data.description
          ? data.description
          : original!.description,
        guests: users,
        _version: original._version,
      };
      await client.graphql<GuestGroup>({
        query: updateGuestGroup,
        variables: { input: updateInput },
        authMode: 'userPool',
      });
      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }
  async function trash(params: GuestGroupGetVariables) {
    try {
      const original: any = await get(params);
      const { id, listing } = params;
      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }
      const updateInput: UpdateGuestGroupInput = {
        id: original.id,
        deleted: '1',
        _version: original._version,
      };
      await client.graphql<GuestGroup>({
        query: updateGuestGroup,
        variables: { input: updateInput },
        authMode: 'userPool',
      });
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }
  async function bulkTrash(params: GuestGroupBulkTrashVariables) {
    const { ids, listing } = params;
    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });
    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));
    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }
  async function remove(params: GuestGroupGetVariables) {
    try {
      const { id, listing } = params;
      await client.graphql<GuestGroup>({
        query: deleteGuestGroup,
        variables: { id: id },
        authMode: 'userPool',
      });
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }
  async function exportAll(params: GuestGroupListingVariables) {
    try {
      const data: any[] = await fetch(params);
      let exportedData: any[] = [];
      for (let group of data) {
        let row: any = { ...group };
        exportedData.push(row);
      }
      return exportedData;
    } catch (err) {
      showError(err);
    }
  }
  function options(listing: Guest[]) {
    const options: Option[] = [];
    for (let option of listing) {
      options.push({ label: option.name!, value: option.id });
    }
    return options;
  }
  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'color',
      numeric: false,
      disablePadding: false,
      label: 'Color',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];
  const dataCells: readonly string[] = ['name', 'color', 'description'];

  const api: any = {};
  api[`${listingName}CreateSubscription`] = onCreateGuestGroup;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchCurrentUser`] = fetchCurrentUser;
  api[`${listingName}FetchUsersInGroup`] = fetchUsersInGroup;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: GuestGroup[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = guestGroupsListing;
  return api;
};
export default useGuestGroup;
