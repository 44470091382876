// actions
const SET = 'gates/SET';
const SET_FORM_DATA = 'gates/SET_FORM_DATA';
const CLEAR_FORM_DATA = 'gates/CLEAR_FORM_DATA';

const DEFAULT_STATE = {
  listing: [],
  formData: {
    gate: {
      name: '',
      tickets: [],
      checkpoints: [],
    },
    tickets: [],
    checkpoints: [],
  },
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_FORM_DATA:
      return Object.assign({}, state, {
        formData: action.formData,
      });
    case CLEAR_FORM_DATA:
      return Object.assign({}, state, {
        formData: {
          gate: {
            name: '',
            tickets: [],
            checkpoints: [],
          },
          tickets: [],
          checkpoints: [],
        },
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setFormData = (formData: any) => ({
  formData,
  type: SET_FORM_DATA,
});
export const clearFormData = () => ({
  type: CLEAR_FORM_DATA,
});
