import { BookingPendingFilter, BookingStatus } from '../../constants/enums';

// actions
const SET = 'booking/SET';
const SET_ALL = 'booking/SET_ALL';
const SET_SELECTED = 'booking/SET_SELECTED';
const SET_STATUS_FILTER = 'booking/SET_STATUS_FILTER';
const SET_BOOKING_REGISTERED_FILTER = 'booking/SET_BOOKING_REGISTERED_FILTER';

const CHANGE_LIMIT = 'booking/CHANGE_LIMIT';
const NEXT_ACTION = 'booking/NEXT_ACTION';
const SET_NEXT_TOKEN = 'booking/SET_NEXT_TOKEN';
const SET_PREVIOUS_TOKENS = 'booking/SET_PREVIOUS_TOKENS';

const DEFAULT_STATE = {
  listing: [],
  listingAll: [],
  selected: '',
  statusFilter: BookingStatus.ALL,
  registeredFilter: BookingPendingFilter.REGISTERED,

  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_ALL:
      return Object.assign({}, state, { listingAll: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, {
        selected: action.concept,
      });
    case SET_STATUS_FILTER:
      return Object.assign({}, state, {
        statusFilter: action.status,
      });
    case SET_BOOKING_REGISTERED_FILTER:
      return Object.assign({}, state, {
        registeredFilter: action.status,
      });

    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.nextToken });
    case SET_PREVIOUS_TOKENS:
      return Object.assign({}, state, {
        previousTokens: action.previousTokens,
      });
    case CHANGE_LIMIT:
      return Object.assign({}, state, {
        limit: action.limit,
        nextToken: action.nextToken,
        nextNextToken: action.nextNextToken,
        previousTokens: action.previousTokens,
      });

    case NEXT_ACTION:
      return Object.assign({}, state, {
        nextToken: action.nextToken,
        previousTokens: action.previousTokens,
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setListingAll = (listing: any[]) => ({ listing, type: SET_ALL });
export const setSelected = (single: any) => ({ single, type: SET_SELECTED });
export const setStatusFilter = (status: any) => ({
  status,
  type: SET_STATUS_FILTER,
});
export const setRegisteredFilter = (status: any) => ({
  status,
  type: SET_BOOKING_REGISTERED_FILTER,
});

export const changeLimit = (limit: any) => ({
  limit: limit,
  nextToken: null,
  nextNextToken: null,
  previousTokens: [],
  type: CHANGE_LIMIT,
});
export const nextAction = (nextToken: any, previousTokens: any[]) => ({
  nextToken: nextToken,
  previousTokens: previousTokens ? [...previousTokens, nextToken] : [nextToken],
  type: NEXT_ACTION,
});
export const setNextToken = (nextToken: any) => ({
  nextToken,
  type: SET_NEXT_TOKEN,
});
export const setPreviousToken = (previousTokens: any) => ({
  previousTokens,
  type: SET_PREVIOUS_TOKENS,
});
