import InputIcon from '@mui/icons-material/Input';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppTypes,
  OrderStatus,
  appType,
  cloudinaryURL,
} from '../../../constants/enums';
import useApp from '../../../hooks/useApp';
import { OrderedItem } from '../../../models';
import { setModalOpen } from '../../../store/ducks/server';
import useOrder from '../../../hooks/useOrder';
import { sessionTimeout } from '../../../helpers/utils';

interface OfflineBooking {
  id: string;
  userName: string;
  userImage: string;
  userId: string;
  userPhone: string;
  eventId: string;
  eventName: string;
  eventStartDate: string;
  eventEndDate: string;
  ticketType: string;
  balance: number;
  version: number;
}

const testOrders = [
  {
    id: '637567',
    userName: 'John Doe',
    userImage: 'user1.jpg',
    userId: 'user1',
    eventId: 'event1',
    items: [
      { id: 'item1', name: 'Burger', quantity: 2, price: 50 },
      { id: 'item2', name: 'Fries', quantity: 1, price: 30 },
    ],
  },
  {
    id: '25335',
    userName: 'John Doe',
    userImage: 'user1.jpg',
    userId: 'user1',
    eventId: 'event1',
    items: [
      { id: 'item3', name: 'Soda', quantity: 3, price: 20 },
      { id: 'item4', name: 'Sandwich', quantity: 2, price: 40 },
    ],
  },
  {
    id: '8865',
    userName: 'John Doe',
    userImage: 'user1.jpg',
    userId: 'user1',
    eventId: 'event1',
    items: [
      { id: 'item5', name: 'Popcorn', quantity: 1, price: 30 },
      { id: 'item6', name: 'Juice', quantity: 2, price: 25 },
    ],
  },
];

export default function Order() {
  const { showWarning } = useApp();
  const [bookingInputValue, setBookingInputValue] = useState('');
  const [currentBooking, setCurrentBooking] = useState<OfflineBooking>();
  const [bookingLoading, setBookingLoading] = useState(false);
  const [deliveryLoading, setDeliveryLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [offlineSession, setOfflineSession] = useState<string | null>(null);
  const dispatch = useDispatch();
  const serverAddress = useSelector((state: any) => state.server.serverAddress);
  const { ordersFetchByBookingId, ordersDeliver } = useOrder('orders', 'order');

  useEffect(() => {
    const storedSession = localStorage.getItem('sessionStored');
    if (storedSession && 
      !sessionTimeout() &&
      // @ts-ignore
     appType === AppTypes.CASHLESS) {
      setOfflineSession(JSON.parse(storedSession));
    }
  }, []);

  useEffect(() => {
    if (!currentBooking) return;
    getOrders();
  }, [currentBooking]);

  const getOrders = async () => {
    try {
      const ordersFetched = await ordersFetchByBookingId({
        bookingId: currentBooking?.id,
      });
      setOrders(ordersFetched ?? []);
    } catch (err) {
      console.log({ err });
    }
  };

  // const handleInputChange = (event) => {
  //   setBookingInputValue(event.target.value);
  // };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const inputValue = e.target.value;
    const urlPattern = /https:\/\/ulter\.anyware\.software\/\?id=([a-f0-9-]+)/;
    let newValue = inputValue;
    // Check if the input matches the URL pattern
    const match = inputValue.match(urlPattern);
    if (match) {
      newValue = match[1];
    }
    setBookingInputValue(newValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleGetBooking();
    }
  };

  const handleGetBooking = async () => {
    if (bookingInputValue && bookingInputValue !== '') {
      setBookingLoading(true);
      try {
        const response = await fetch(
          serverAddress + `/booking/${bookingInputValue}`,
        );
        if (response.ok) {
          const booking = await response.json();
          setCurrentBooking(booking.data.data);
          setBookingLoading(false);
        } else {
          showWarning('Please enter a valid booking');
          setBookingLoading(false);
          setBookingInputValue('');
          setCurrentBooking(undefined);
        }
      } catch (error) {
        console.error('Error fetching booking:', error);
        showWarning('Please connect to the server and try again');
        setBookingLoading(false);
        setBookingInputValue('');
        setCurrentBooking(undefined);
        dispatch(setModalOpen(true));
      }
    } else {
      showWarning('Please enter or scan booking');
      setBookingLoading(false);
    }
  };

  const handleDelivered = async (orderId: string) => {
    try {
      setDeliveryLoading(true);
      const deliverParams = {
        status: OrderStatus.DELEVERED,
        dispatcherId: offlineSession?.sub,
        id: orderId,
      };
      await ordersDeliver({ data: deliverParams });
      if (orders?.length <= 1) {
        setCurrentBooking(undefined);
        setBookingInputValue('');
      }
      await getOrders();
    } catch (err) {
      console.log({ err });
    } finally {
      setDeliveryLoading(false);
    }
  };

  const handleCancel = () => {
    setOrders([]);
    setBookingInputValue('');
    setCurrentBooking(undefined);
  };

  return (
    <Grid item xs={12} sm={8} md={8} lg={8}>
      <Box
        sx={{
          backgroundColor: 'secondary.light',
          minHeight: '100vh',
          p: 0.1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 3,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <Typography
            sx={{
              color: 'primary.main',
              fontWeight: 520,
              fontSize: '20px',
            }}
          >
            Dispatcher
          </Typography>

          {bookingLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (
            <Avatar
              alt=""
              src={
                currentBooking?.userImage?.includes('platform')
                  ? `${currentBooking?.userImage}`
                  : `${cloudinaryURL}${currentBooking?.userImage}`
              }
              sx={{ width: 120, height: 120 }}
            />
          )}

          {!bookingLoading && currentBooking && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <PriceChangeIcon sx={{ fontSize: '15px' }} />
              <Typography>Balance: {currentBooking?.balance} EGP</Typography>
              <Button
                variant="contained"
                onClick={handleCancel}
                sx={{
                  backgroundColor: '#6e3a37',
                  textTransform: 'capitalize',
                  borderRadius: '5px',
                  color: 'primary.main',
                }}
                disabled={deliveryLoading}
              >
                Cancel
              </Button>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <TextField
              name="booking"
              autoComplete="false"
              focused={false}
              sx={{
                backgroundColor: 'secondary.main',
                'input::placeholder': {
                  color: 'white',
                },
                input: {
                  color: 'white',
                  height: '10px',
                  textAlign: 'center',
                },
                border: '1px solid',
                borderColor: 'secondary.main',
                borderRadius: '10px',
                '.MuiInputBase-root': {
                  borderRadius: '10px',
                },
              }}
              placeholder="Booking"
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              value={bookingInputValue}
            />

            <IconButton
              sx={{
                backgroundColor: '#6e3a37',
                p: 1,
                px: 1.25,
                borderRadius: '5px',
              }}
              onClick={handleGetBooking}
            >
              <InputIcon />
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
          }}
        >
          {orders.map((order: any) => (
            <Box
              key={order.id}
              sx={{
                p: 2,
                border: '1px solid #ccc',
                borderRadius: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6">Order #{order.id}</Typography>
              </Box>
              {order.orderedItems.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 1,
                    ml: 2,
                    mb: 2,
                  }}
                >
                  <Typography>{item.name}</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography>Quantity: {item.quantity}</Typography>
                    <Typography sx={{ mr: 2 }}>
                      {item.price * item.quantity} EGP
                    </Typography>
                  </Box>
                </Box>
              ))}

              <Box
                sx={{
                  backgroundColor: '#6b6b6b',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 2,
                  width: '100%',
                  p: 1,
                  borderRadius: '5px',
                  mt: 2,
                }}
              >
                <Typography sx={{ fontSize: '18px' }}>Total</Typography>
                <Typography sx={{ fontSize: '18px' }}>
                  {order.totalAmount} EGP
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                  my: 3,
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleDelivered(order.id)}
                  sx={{
                    backgroundColor: '#175f3d',
                    textTransform: 'capitalize',
                    borderRadius: '5px',
                    color: 'primary.main',
                  }}
                  disabled={deliveryLoading}
                >
                  Delivered
                </Button>
              </Box>
            </Box>
          ))}
          {!orders?.length ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography>No Orders Found</Typography>
            </Box>
          ) : (
            ''
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            my: 4,
          }}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => dispatch(setModalOpen(true))}
            sx={{
              backgroundColor: 'warning.main',
              textTransform: 'capitalize',
              borderRadius: '5px',
            }}
          >
            Configure Server
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}
