import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputIcon from '@mui/icons-material/Input';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderStatus, cloudinaryURL } from '../../../constants/enums';
import useApp from '../../../hooks/useApp';
import useBooking from '../../../hooks/useBooking';
import useOrder from '../../../hooks/useOrder';
import { setModalOpen } from '../../../store/ducks/server';
import useInvitation from '../../../hooks/useInvitaion';

interface OfflineBooking {
  id: string;
  userName: string;
  userImage: string;
  userId: string;
  userPhone: string;
  eventId: string;
  eventName: string;
  eventStartDate: string;
  eventEndDate: string;
  ticketType: string;
  balance: number;
  version: number;
}

export default function MenuSide({
  setItemsToCheckout,
  itemsToCheckout,
  isBuying,
  setIsBuying,
}) {
  let { bookingsGet } = useBooking('bookings', 'booking');
  let { invitationsFetchInvitationByIdOffline } = useInvitation(
    'invitations',
    'invitation',
  );
  const { ordersCreate } = useOrder('orders', 'order');
  const { showConfirm, showError, showWarning } = useApp();
  const [bookingInputValue, setBookingInputValue] = useState('');
  const [currentBooking, setCurrentBooking] = useState<OfflineBooking>();
  const [bookingLoading, setBookingLoading] = useState(false);
  const session = useSelector((state: any) => state.app.session);
  const [offlineSession, setOfflineSession] = useState<string | null>(null);
  const dispatch = useDispatch();
  const serverAddress = useSelector((state: any) => state.server.serverAddress);
  const SearchForUserField = useRef<any>();
  useEffect(() => {
    localStorage.setItem('offlineSession', JSON.stringify(session));
    const storedSession = localStorage.getItem('offlineSession');
    if (storedSession) {
      setOfflineSession(JSON.parse(storedSession));
    }
  }, []);

  // const handleInputChange = (event) => {
  //   setBookingInputValue(event.target.value);
  // };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const inputValue = e.target.value.toLowerCase();
    // const urlPattern = /https:\/\/ulter\.anyware\.software\/\?id=([a-f0-9-]+)/;
    // Dev
    // const urlPattern =
    //   /https:\/\/ticketing-system-app-eight\.vercel\.app\/\?id=([a-f0-9-]+)/;

    // Prod
    const urlPattern = /https:\/\/www\.ascai\.tickets\/\?id=([a-f0-9-]+)/;
    let newValue = inputValue;
    // Check if the input matches the URL pattern
    const match = inputValue.match(urlPattern);
    if (match) {
      newValue = match[1];
    }
    setBookingInputValue(newValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleGetBooking();
    }
  };

  // const handleGetBooking = async () => {
  //   if (bookingInputValue && bookingInputValue !== '') {
  //     setBookingLoading(true);
  //     const booking = await bookingsGet({
  //       listing: [],
  //       id: bookingInputValue,
  //     });
  //     if (booking) {
  //       setCurrentBooking(booking);
  //       setBookingLoading(false);
  //     } else {
  //       showWarning('Please enter a valid booking');
  //       setBookingLoading(false);
  //     }
  //   } else {
  //     showWarning('Please enter or scan booking');
  //     setBookingLoading(false);
  //   }
  // };

  const handleGetBooking = async () => {
    if (bookingInputValue && bookingInputValue !== '') {
      setBookingLoading(true);
      try {
        try {
          const response = await fetch(
            serverAddress + `/booking/${bookingInputValue}`,
          );
          if (response.ok) {
            const booking = await response.json();
            setCurrentBooking(booking.data.data);
            setBookingLoading(false);
          } else {
            // showWarning('Please enter a valid booking');
            // setBookingLoading(false);
            // setBookingInputValue('');
            // setCurrentBooking(undefined);
            try {
              const invitation =
                await invitationsFetchInvitationByIdOffline(bookingInputValue);
              setCurrentBooking(invitation);
              setBookingLoading(false);
            } catch (err) {
              showWarning('Please enter a valid booking');
              SearchForUserField?.current?.focus();
              setBookingLoading(false);
              setBookingInputValue('');
              setCurrentBooking(undefined);
            }
          }
        } catch (error) {
          try {
            const invitation =
              await invitationsFetchInvitationByIdOffline(bookingInputValue);
            setCurrentBooking(invitation);
            setBookingLoading(false);
          } catch (err) {
            showWarning('Please enter a valid booking');
            SearchForUserField?.current?.focus();
            setBookingLoading(false);
            setBookingInputValue('');
            setCurrentBooking(undefined);
          }
        }
      } catch (error) {
        console.error('Error fetching booking:', error);
        showWarning('Please connect to the server and try again');
        setBookingLoading(false);
        setBookingInputValue('');
        setCurrentBooking(undefined);
        dispatch(setModalOpen(true));
      }
    } else {
      showWarning('Please enter or scan booking');
      setBookingLoading(false);
    }
  };

  const handleItemNumberIncrement = (itemId) => {
    setItemsToCheckout((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, quantity: item.quantity + 1 } : item,
      ),
    );
  };

  const handleItemNumberDecrement = (itemId: string) => {
    setItemsToCheckout((prevItems) =>
      prevItems
        .map((item) =>
          item.id === itemId && item.quantity > 0
            ? { ...item, quantity: item.quantity - 1 }
            : item,
        )
        .filter((item) => item.quantity > 0),
    );
  };

  const getSubTotalPrice = () => {
    return itemsToCheckout.reduce(
      (total, item) => total + item.price * item.quantity,
      0,
    );
  };

  const getTotalPrice = () => {
    const subtotal = itemsToCheckout.reduce(
      (total, item) => total + item.price * item.quantity,
      0,
    );
    // return Math.round(subtotal * 1.14);
    return Math.round(subtotal);
  };

  // const handleConfirm = async () => {
  //   const apiUrl =
  //     'https://fxvmm3tufovcikezg4zvplal4e0glpex.lambda-url.us-east-2.on.aws/';

  //   const requestBody = {
  //     pos: '1',
  //     checkNumber: '1',
  //     createdAt: new Date().toISOString(),
  //     bookingId: currentBooking?.id,
  //     orderTotal: getTotalPrice(),
  //     createdByName: session.name,
  //     createdById: session.sub,
  //     orderedItems: itemsToCheckout.map((item, index) => ({
  //       id: item.id,
  //       name: item.name,
  //       quantity: item.quantity,
  //       price: item.price,
  //     })),
  //   };

  //   try {
  //     if (currentBooking) {
  //       setIsBuying(true);
  //       const response = await axios.post(apiUrl, requestBody);

  //       console.log('Order confirmed successfully:', response.data);
  //       setItemsToCheckout([]);
  //       showConfirm('Order confirmed successfully');
  //       setIsBuying(false);
  //     }
  //   } catch (error: any) {
  //     console.error('Failed to confirm order:', error);
  //     setIsBuying(false);
  //     showError(error.response.data.errorMessage);
  //   }
  // };

  const handleConfirm = async () => {
    const apiUrl = serverAddress + '/transactions';

    const sessionToUse = session ? session : offlineSession;

    const requestBody = {
      pos: '1',
      checkNumber: '1',
      createdAt: new Date().toISOString(),
      bookingId: currentBooking?.id,
      orderTotal: getTotalPrice(),
      createdByName: sessionToUse.name,
      createdById: sessionToUse.sub,
      orderedItems: itemsToCheckout.map((item, index) => ({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        price: item.price,
      })),
    };

    try {
      if (currentBooking) {
        setIsBuying(true);
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (response.ok) {
          const responseData = await response.json();
          const createOrderReqBody = {
            data: {
              bookingId: requestBody.bookingId,
              orderedItems: requestBody.orderedItems,
              totalAmount: requestBody.orderTotal,
              status: OrderStatus.PENDING,
              createdAt: requestBody.createdAt,
              createdByName: requestBody.createdByName,
              createdById: requestBody.createdById,
            },
          };
          // await ordersCreate(createOrderReqBody);
          // console.log('Order confirmed successfully:', responseData);
          setItemsToCheckout([]);
          setBookingInputValue('');
          setCurrentBooking(undefined);
          SearchForUserField?.current?.focus();
          showConfirm('Order confirmed successfully');
          setIsBuying(false);
        } else {
          const errorData = await response.json();
          console.error('Failed to confirm order:', errorData);
          setIsBuying(false);
          // console.log(errorData);
          showError(errorData.message);
        }
      }
    } catch (error: any) {
      console.error('Failed to confirm order:', error);
      setIsBuying(false);
      showError('An error occurred while confirming the order');
    }
  };

  const handleCancel = () => {
    setItemsToCheckout([]);
    setBookingInputValue('');
    setCurrentBooking(undefined);
    SearchForUserField?.current?.focus();
  };

  return (
    <Grid item xs={12} sm={5} md={4} lg={3}>
      <Box
        sx={{
          backgroundColor: 'secondary.light',
          minHeight: '100vh',
          p: 0.1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 3,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <Typography
            sx={{
              color: 'primary.main',
              fontWeight: 520,
              fontSize: '20px',
            }}
          >
            New Order
          </Typography>

          {bookingLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (
            <Avatar
              alt=""
              // src={`${cloudinaryURL}${currentBooking?.guest?.guest_avatar}`}
              src={
                currentBooking?.userImage?.includes('platform')
                  ? `${currentBooking?.userImage}`
                  : `${cloudinaryURL}${currentBooking?.userImage}`
              }
              sx={{ width: 120, height: 120 }}
            />
          )}

          {!bookingLoading && currentBooking && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <PriceChangeIcon sx={{ fontSize: '15px' }} />
              <Typography>Balance: {currentBooking?.balance} EGP</Typography>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <TextField
              name="booking"
              autoComplete="false"
              inputRef={SearchForUserField}
              focused={false}
              sx={{
                backgroundColor: 'secondary.main',
                'input::placeholder': {
                  color: 'white',
                },
                input: {
                  color: 'white',
                  height: '10px',
                  textAlign: 'center',
                },
                border: '1px solid',
                borderColor: 'secondary.main',
                // width: { xs: 'auto', sm: '15rem' },
                borderRadius: '10px',
                '.MuiInputBase-root': {
                  borderRadius: '10px',
                },
              }}
              placeholder="Booking"
              autoFocus
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              value={bookingInputValue}
            />

            <IconButton
              sx={{
                backgroundColor: '#6e3a37',
                p: 1,
                px: 1.25,
                borderRadius: '5px',
              }}
              onClick={handleGetBooking}
            >
              <InputIcon />
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Box>
            {itemsToCheckout.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: 1,
                  ml: 2,
                }}
              >
                <Typography>{item.name}</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <IconButton
                      onClick={() => handleItemNumberDecrement(item.id)}
                      disabled={bookingLoading || isBuying}
                    >
                      <RemoveCircleOutlineIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                    <Box
                      sx={{
                        backgroundColor: 'secondary.dark',
                        border: 'solid 1px white',
                        borderRadius: '5px',
                        px: 3,
                      }}
                    >
                      {item.quantity}
                    </Box>
                    <IconButton
                      onClick={() => handleItemNumberIncrement(item.id)}
                      disabled={bookingLoading || isBuying}
                    >
                      <AddCircleOutlineIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                  </Box>
                  <Typography sx={{ mr: 2 }}>
                    {item.price * item.quantity} EGP
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
            py: 3,
          }}
        >
          <Typography sx={{ color: '#959595' }}>Subtotal</Typography>
          <Typography sx={{ color: '#959595' }}>
            {getSubTotalPrice()} EGP
          </Typography>
        </Box> */}

        <Box
          sx={{
            backgroundColor: '#6b6b6b',
            display: 'flex',
            justifyContent: 'space-between',
            px: 2,
            width: '100%',
            p: 1,
            borderRadius: '5px',
          }}
        >
          <Typography sx={{ fontSize: '18px' }}>Total</Typography>
          <Typography sx={{ fontSize: '18px' }}>
            {Math.round(getTotalPrice())} EGP
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            my: 3,
          }}
        >
          <Button
            variant="contained"
            onClick={handleConfirm}
            sx={{
              backgroundColor: '#175f3d',
              textTransform: 'capitalize',
              borderRadius: '5px',
              color: 'primary.main',
            }}
            disabled={
              !currentBooking ||
              getTotalPrice() === 0 ||
              bookingLoading ||
              isBuying
            }
          >
            Confirm
          </Button>

          <Button
            variant="contained"
            onClick={handleCancel}
            sx={{
              backgroundColor: '#6e3a37',
              textTransform: 'capitalize',
              borderRadius: '5px',
              color: 'primary.main',
            }}
            disabled={isBuying}
          >
            Cancel
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            my: 4,
          }}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => dispatch(setModalOpen(true))}
            sx={{
              backgroundColor: 'warning.main',
              textTransform: 'capitalize',
              borderRadius: '5px',
            }}
          >
            Configure Server
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}
