import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DATE_PICKER_TYPES,
  LOCAL_STORAGE,
  // ReportType,
} from '../constants/enums';
// import {
//   syncBookingDate,
//   syncBookingDateConfig,
//   syncBookingDateListener,
// } from "../helpers/dbSync";
import {
  getBookingDateRange,
  setBookingDateRange,
} from '../helpers/localStorage';
import { formatDate, persistBookingDate } from '../helpers/utils';
import { UserConcepts } from '../models/GQL_API';
import {
  DatePickerParams,
  DateRangeProps,
  IBookingsSyncDuration,
} from '../models/app';
// import {
//   FilterReportByDateData,
//   FilterReportByDateKeys,
// } from "../components/Reports/FilterReportByDate";

interface IState {
  open: boolean;
  shouldSync: boolean;
  prevLoading: boolean;
  nextLoading: boolean;
  dateRange: DateRangeProps[];
  syncDateRange: DateRangeProps[];
}

// const range: DateRangeProps = {
//   startDate: persistBookingDate(new Date()),
//   endDate: persistBookingDate(new Date()),
//   key: 'selection',
// };

const range: DateRangeProps = {
  startDate: undefined,
  endDate: undefined,
  key: 'selection',
};

const INITIAL_STATE: IState = {
  open: false,
  shouldSync: false,
  prevLoading: false,
  nextLoading: false,
  dateRange: [range],
  syncDateRange: [range],
};

interface StateReports {
  open: boolean;
  prevLoading: boolean;
  nextLoading: boolean;
  dateRange: DateRangeProps[];
}

const INITIAL_STATE_Reports: StateReports = {
  open: false,
  prevLoading: false,
  nextLoading: false,
  dateRange: [range],
};

function useDateRangePicker({
  type,
  selection,
  initiallyUndefined,
}: DatePickerParams) {
  if (initiallyUndefined) {
    INITIAL_STATE.dateRange = [
      { startDate: undefined, endDate: undefined, key: 'selection' },
    ];
  }
  const [state, setState] = useState(INITIAL_STATE);
  const [stateReports, setStateReports] = useState(INITIAL_STATE_Reports);
  const userConcepts: UserConcepts = useSelector(
    (state: any) => state.app.concepts,
  );

  // useEffect(() => {
  //   const listener = syncBookingDateListener(syncBookingDateCb);

  //   return () => listener();

  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    setState({
      ...state,
      syncDateRange: state.dateRange,
    });
    localStorage.setItem(
      LOCAL_STORAGE.BOOKING_DATE,
      formatDate(state.dateRange[0].startDate),
    );
    // eslint-disable-next-line
  }, [state.dateRange[0].startDate]);

  // const syncBookingDateCb = () => {
  //   const bookingGuestSync: string | null = localStorage.getItem(
  //     LOCAL_STORAGE.BOOKING_GUEST
  //   );

  //   if (bookingGuestSync && bookingGuestSync === "false") {
  //     setState((prevState) => {
  //       if (selection === DATE_PICKER_TYPES.SINGLE) handleClose();

  //       return {
  //         ...prevState,
  //         open: false,
  //         shouldSync: false,
  //         prevLoading: false,
  //         nextLoading: false,
  //         dateRange: prevState.syncDateRange,
  //       };
  //     });
  //   }
  // };

  // const setSyncBookingDateConfig = (
  //   fromDate: Date,
  //   toDate: Date,
  //   userConcepts: UserConcepts
  // ) => {
  //   setBookingDateRange(fromDate, toDate);

  //   localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE_RANGE, "true");

  //   syncBookingDate();
  // };

  const handleChange = (item: any) => {
    if (selection === DATE_PICKER_TYPES.SINGLE) {
      const range: DateRangeProps = {
        key: 'selection',
        startDate: item.selection.startDate,
        endDate: item.selection.startDate,
      };
      setState((prevState) => ({
        ...prevState,
        shouldSync: true,
        syncDateRange: [range],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        shouldSync: true,
        syncDateRange: [item.selection],
      }));
    }

    // const bookingsSyncDuration: IBookingsSyncDuration =
    //   getBookingDateRange(userConcepts);
    // const { fromDate, toDate } = bookingsSyncDuration;

    // const startDate: Date = item.selection.startDate;
    // const endDate: Date = item.selection.endDate;

    // if (
    //   getDateFormatted(startDate) > getDateFormatted(fromDate) &&
    //   getDateFormatted(endDate) > getDateFormatted(toDate)
    // ) {
    //   setSyncBookingDateConfig(fromDate, endDate, userConcepts);
    // } else if (
    //   getDateFormatted(startDate) > getDateFormatted(fromDate) &&
    //   getDateFormatted(toDate) < getDateFormatted(endDate)
    // ) {
    //   setSyncBookingDateConfig(fromDate, endDate, userConcepts);
    // } else if (
    //   getDateFormatted(startDate) < getDateFormatted(fromDate) &&
    //   getDateFormatted(endDate) > getDateFormatted(toDate)
    // ) {
    //   setSyncBookingDateConfig(startDate, toDate, userConcepts);
    // } else if (
    //   getDateFormatted(startDate) < getDateFormatted(fromDate) &&
    //   getDateFormatted(endDate) < getDateFormatted(toDate)
    // ) {
    //   setSyncBookingDateConfig(startDate, toDate, userConcepts);
    // } else {
    //   setState((prevState) => ({
    //     ...prevState,
    //     shouldSync: false,
    //     dateRange: [item.selection],
    //   }));

    //   if (selection === DATE_PICKER_TYPES.SINGLE) handleClose();
    // }

    setState((prevState) => ({
      ...prevState,
      shouldSync: false,
      dateRange: [item.selection],
    }));

    if (selection === DATE_PICKER_TYPES.SINGLE) handleClose();
  };

  const handleSubtract = (event: React.MouseEvent<unknown>) => {
    const { dateRange } = state;
    setState((prevState) => ({ ...prevState, prevLoading: true }));

    let date = new Date(formatDate(dateRange[0].startDate));
    date.setDate(date.getDate() - 1);

    const bookingsSyncDuration: IBookingsSyncDuration =
      getBookingDateRange(userConcepts);
    const { fromDate, toDate } = bookingsSyncDuration;

    const range: DateRangeProps = {
      key: 'selection',
      startDate: date,
      endDate: date,
    };

    if (formatDate(date) < formatDate(fromDate)) {
      setState((prevState) => ({
        ...prevState,
        syncDateRange: [range],
      }));

      // setSyncBookingDateConfig(date, toDate, userConcepts);
    } else {
      setState((prevState) => ({
        ...prevState,
        prevLoading: false,
        dateRange: [range],
      }));

      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE, formatDate(date));
    }
  };

  const handleAdd = (event: React.MouseEvent<unknown>) => {
    const { dateRange } = state;
    setState((prevState) => ({ ...prevState, nextLoading: true }));

    let date = new Date(formatDate(dateRange[0].startDate));
    date.setDate(date.getDate() + 1);

    const bookingsSyncDuration: IBookingsSyncDuration =
      getBookingDateRange(userConcepts);
    const { fromDate, toDate } = bookingsSyncDuration;

    const range: DateRangeProps = {
      key: 'selection',
      startDate: date,
      endDate: date,
    };

    if (formatDate(date) > formatDate(toDate)) {
      setState((prevState) => ({
        ...prevState,
        syncDateRange: [range],
      }));

      // setSyncBookingDateConfig(fromDate, date, userConcepts);
    } else {
      setState((prevState) => ({
        ...prevState,
        nextLoading: false,
        dateRange: [range],
      }));

      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE, formatDate(date));
    }
  };

  const handleClose = (): void => {
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const handleCloseReportsOk = (): void => {
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const handleCloseReportsCancel = (): void => {
    setState(INITIAL_STATE);
  };

  const handleToday = () => {
    const range: DateRangeProps = {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    };

    setState((prevState) => ({ ...prevState, dateRange: [range] }));

    localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE, formatDate(new Date()));
  };

  const handleChangeReports = (item: any) => {
    if (selection === DATE_PICKER_TYPES.SINGLE) {
      const range: DateRangeProps = {
        key: 'selection',
        startDate: item.selection.startDate,
        endDate: item.selection.startDate,
      };
      setState((prevState) => ({
        ...prevState,
        shouldSync: false,
        dateRange: [item.selection],
        syncDateRange: [range],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        shouldSync: false,
        dateRange: [item.selection],
        syncDateRange: [item.selection],
      }));
    }
    if (selection === DATE_PICKER_TYPES.SINGLE) handleClose();
    getDateRange(item.selection.startDate, item.selection.endDate);
  };

  function getDateRange(startDate: Date, endDate: Date) {
    return { startDate: startDate, endDate: endDate };
  }

  // function getSum(
  //   array: FilterReportByDateData[],
  //   shiftsSelectedFilters: Shift[]
  // ): { sum: number; avgTime: number } {
  //   let sum = 0;
  //   let avgTime = 0;

  //   if (type === ReportType.MENUITEM) {
  //     for (let i = 0; i < array.length; i++) {
  //       // Get total consumption or get consumption per shift
  //       if (shiftsSelectedFilters.length > 0) {
  //         if (array[i].data.shifts !== undefined) {
  //           for (let j = 0; j < shiftsSelectedFilters.length; j++) {
  //             let shiftID = shiftsSelectedFilters[j].id;
  //             if (array[i].data.shifts[shiftID] !== undefined)
  //               sum += array[i].data.shifts[shiftID];
  //           }
  //         }
  //       } else {
  //         sum += array[i].data.consumption;
  //       }
  //     }
  //   } else if (type === ReportType.SERVING_AREA) {
  //     for (let i = 0; i < array.length; i++) {
  //       // Get consumption and preparation time
  //       sum += array[i].data.consumption;
  //       avgTime += array[i].data.avgPreparationTime
  //         ? array[i].data.avgPreparationTime
  //         : 0;
  //     }
  //   } else if (type === ReportType.WAITER) {
  //     for (let i = 0; i < array.length; i++) {
  //       sum += array[i].data.totalAverage;
  //     }
  //   }

  //   if (avgTime > 0) avgTime = avgTime / array.length;

  //   return { sum, avgTime };
  // }

  function getDatesBetween(startDate: Date, endDate: Date): Date[] {
    const dates: Date[] = [];
    let currentDate = new Date(startDate);

    // Function to get the number of days in a month
    function daysInMonth(date: Date): number {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }

    // Loop through dates until the currentDate is less than or equal to endDate
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate)); // Push a new copy of the date to the array
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day

      // Check if the month or year has changed
      if (currentDate > endDate) {
        break; // Break the loop if it exceeds the endDate
      }

      // Check if the current date exceeds the days in the current month
      if (currentDate.getDate() > daysInMonth(currentDate)) {
        currentDate.setDate(1); // Move to the next month
      }
    }

    return dates;
  }

  // function binarySearch(
  //   searchArray: string[],
  //   searchKeys: FilterReportByDateKeys[]
  // ): FilterReportByDateData[] {
  //   let results: FilterReportByDateData[] = [];

  //   if (searchKeys.length > 0 && searchArray.length > 0) {
  //     for (let key of searchKeys) {
  //       let left = 0;
  //       let right = searchArray.length - 1;

  //       while (left <= right) {
  //         const mid = Math.floor((left + right) / 2);
  //         const currentItem = JSON.parse(searchArray[mid]);

  //         if (currentItem.date === key.date) {
  //           results.push(currentItem);
  //           break;
  //         } else if (currentItem.date < key.date) {
  //           left = mid + 1;
  //         } else {
  //           right = mid - 1;
  //         }
  //       }
  //     }
  //   }
  //   return results;
  // }

  // function getFilterKeys(
  //   array: any[],
  //   startDate: Date,
  //   endDate: Date,
  //   shiftsSelectedFilters: []
  // ): { sum: number; avgTime: number } {
  //   let datesBetween: Date[] = getDatesBetween(startDate, endDate);
  //   let result: FilterReportByDateKeys[] = [];

  //   let date: FilterReportByDateKeys = {
  //     date: formatDate(startDate),
  //     year: startDate.getFullYear(),
  //     month: startDate.getMonth() + 1,
  //     day: startDate.getDate(),
  //   };

  //   for (let i = 0; i < datesBetween?.length; i++) {
  //     date = {
  //       date: formatDate(datesBetween[i]),
  //       year: datesBetween[i].getFullYear(),
  //       month: datesBetween[i].getMonth() + 1,
  //       day: datesBetween[i].getDate(),
  //     };

  //     let today = formatDate(new Date());
  //     if (today >= date.date) {
  //       result.push(date);
  //     } else {
  //       break;
  //     }
  //   }
  //   let x = binarySearch(array, result);
  //   const { sum, avgTime } = getSum(x, shiftsSelectedFilters);

  //   return { sum, avgTime };
  // }

  const resetRange = () => {
    range.startDate = undefined;
    range.endDate = undefined;
    setState((prevState) => ({
      ...prevState,
      dateRange: [range],
      syncDateRange: [range],
    }));
  };

  return {
    ...state,
    handleOpen: () => setState((prevState) => ({ ...prevState, open: true })),
    handleChange,
    handleChangeReports,
    handleClose,
    handleCloseReportsOk,
    handleCloseReportsCancel,
    // getFilterKeys,
    getDateRange,
    handleAdd,
    handleSubtract,
    handleToday,
    handleDateRange: (dateRange: DateRangeProps[]) => {
      setState((prevState) => ({
        ...prevState,
        dateRange,
      }));
    },
    resetRange,
  };
}

export default useDateRangePicker;
