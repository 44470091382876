import { listInvitations, getInvitation } from './../graphql/queries';
import { onCreateInvitation } from '../graphql/subscriptions';
import {
  updateInvitation,
  createInvitation,
  deleteInvitation,
} from '../graphql/mutations';
import { generateClient } from '@aws-amplify/api';
import {
  InvitationBulkTrashVariables,
  InvitationGetVariables,
  InvitationListingVariables,
  InvitationUpdateVariables,
  CreateVariables,
} from './../models/app';
import { useDispatch, useSelector } from 'react-redux';
import { setListing, setSelected } from '../store/ducks/invitation';
import { HeadCell } from '../models/dataTable';
import useApp from './useApp';
import {
  CreateInvitationInput,
  UpdateInvitationInput,
  Invitation,
  ModelInvitationFilterInput,
} from '../models/GQL_API';
import axios from 'axios';

const client = generateClient();

const useInvitation = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const serverAddress = useSelector((state: any) => state.server.serverAddress);

  async function fetch(params: InvitationListingVariables) {
    const { searchText, limit, phone_number, email } = params;
    try {
      const filter: ModelInvitationFilterInput = {
        deleted: { eq: '0' },
      };
      filter.or = [];
      filter.and = [];
      if (searchText.length > 0)
        filter.createdByName = { contains: searchText.toLowerCase() };
      if (phone_number) filter.phone_number = { eq: phone_number };
      if (email) filter.email = { eq: email };

      if (filter.and && filter.and.length === 0) delete filter.and;
      if (filter.or && filter.or.length === 0) delete filter.or;

      const invitationList: any = await client.graphql({
        query: listInvitations,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });
      const listing = invitationList.data.listInvitations.items;
      // dispatch(setListing(listing));
      return listing;
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function get(params: InvitationGetVariables) {
    const { id, listing } = params;
    try {
      let single: Invitation | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await client.graphql({
          query: getInvitation,
          variables: { id },
          authMode: 'userPool',
        });
        single = listing.data.getInvitation;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateInvitationInput = {
        invitationEventId: data.invitationEventId ? data.invitationEventId : '',
        invitationEventTicketId: data.invitationEventTicketId
          ? data.invitationEventTicketId
          : '',
        phone_number: data.phone_number ? data.phone_number : '',
        email: data.email ? data.email : '',
        name: data.name ? data.name.toLowerCase() : '',
        reason: data.reason ? data.reason : '',
        secret: data.secret ? data.secret : '',
        used: data.used ? data.used : false,
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
        cashlessCredit: 0,
        balance: 0,
      };
      const res = await client.graphql({
        query: createInvitation,
        variables: { input: createInput },
        authMode: 'userPool',
      });
      showConfirm(`New ${singleName} has been created successfully`);
      // console.log({ createdInvitation: res.data.createInvitation });
      return res.data.createInvitation;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: InvitationUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original: any = await get({ id, listing: [] });
      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }
      const updateInput: UpdateInvitationInput = {
        id: original.id,
        phone_number: data.phone_number
          ? data.phone_number
          : original!.phone_number,
        email: data.email ? data.email : original!.email,
        name: data.name ? data.name.toLowerCase() : original!.name,
        reason: data.reason ? data.reason : original!.reason,
        secret: data.secret ? data.secret : original!.secret,
        used: data.used ? data.used : original!.used,
        invitationEventId: data.invitationEventId
          ? data.invitationEventId
          : original!.invitationEventId,
        invitationEventTicketId: data.invitationEventTicketId
          ? data.invitationEventTicketId
          : original!.invitationEventTicketId,

        balance: data.balance ? data.balance : original!.balance ?? 0,
        _version: original._version,
      };
      await client.graphql({
        query: updateInvitation,
        variables: { input: updateInput },
        authMode: 'userPool',
      });
      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
      //   throw err;
    }
  }

  async function trash(params: InvitationGetVariables) {
    try {
      const { id, listing } = params;

      const original = await get(params);

      if (original) {
        const updateInput: UpdateInvitationInput = {
          id: original.id,
          deleted: '1',
          _version: original._version,
        };

        await client.graphql({
          query: updateInvitation,
          variables: { input: updateInput },
          authMode: 'userPool',
        });
      }
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: InvitationBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: InvitationGetVariables) {
    const { id, listing } = params;
    try {
      await client.graphql({
        query: deleteInvitation,
        variables: { input: { id } },
        authMode: 'userPool',
      });
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function fetchInvitationByIdOffline(id: string) {
    try {
      const res = await axios.get(serverAddress + '/invitation/' + id);
      const data = res.data.data.data;
      return data;
    } catch (err: any) {
      console.log({ err });
      if (err?.response?.status === 404) {
        throw new Error("This Guest Doesn't exist");
      } else {
        throw new Error(err?.response?.data?.message) ?? err;
      }
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'startDate',
      numeric: false,
      disablePadding: false,
      label: 'Start Date',
    },
    {
      id: 'endDate',
      numeric: false,
      disablePadding: false,
      label: 'End Date',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  const dataCells: readonly string[] = ['name', 'startDate', 'endDate'];

  const api: any = {};
  api[`${listingName}CreateSubscription`] = onCreateInvitation;
  api[`${listingName}Options`] = [];
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}FetchInvitationByIdOffline`] = fetchInvitationByIdOffline;
  api[`${listingName}ChangeListing`] = (listing: Invitation[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};
export default useInvitation;
