export const checkInternet = () => {
  try {
    const controller = new AbortController();
    const signal = controller.signal;
    const startTime = Date.now();

    // Set a timeout to abort the fetch request after 5000ms
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        controller.abort();
        reject(new Error('Request timed out'));
      }, 6000);
    });

    // Fetch request for checking internet connectivity
    const fetchPromise = fetch('https://www.google.com/', {
      mode: 'no-cors',
      signal: signal, // Pass the signal to the fetch request
    }).catch((error) => {
      // Handle fetch error here
      throw new Error('Failed to fetch');
    });

    // Use Promise.race to race between fetch and timeout promises
    return Promise.race([fetchPromise, timeoutPromise])
      .then((response: any) => {
        // Check if the request wasn't aborted
        if (!signal.aborted) {
          const endTime = Date.now();
          const pingTime = endTime - startTime;
          console.log('Ping time:', pingTime);
          // Resolve with true if response is OK and ping time is within 500ms
          return !response.ok;
        } else {
          console.log('Ping request aborted');
          // Resolve with false if the request was aborted
          return false;
        }
      })
      .catch((error) => {
        // Check if the error is due to aborting the fetch request or timeout
        if (
          error.name === 'AbortError' ||
          error.message === 'Request timed out'
        ) {
          console.log('Fetch request aborted or timed out');
          // Resolve with false if the request was aborted or timed out
          return false;
        } else {
          console.error('Error checking internet connection:', error);
          // Reject with error if any other error occurs
          throw error;
        }
      });
  } catch (error) {
    console.log(error);
    return false;
  }
};
