import { LambdaFunctions } from '../constants/enums';
import { invokeLambda } from '../helpers/utils';

async function sendEmail({
  customerEmail,
  adminName,
  eventName,
  link,
  textContent,
  templateName,
  guestName,
  customMessage,
  rejectionMessage,
}: any) {
  try {
    const operationId = 10;
    const emailAttributes = {
      customerEmail,
      guestName,
      adminName,
      eventName,
      link,
      textContent,
      customMessage,
      rejectionMessage,
      sourceMail: 'info@anyware.software',
      templateName,
    };
    const requestBody = {
      operationId,
      queryStringParameters: emailAttributes,
    };
    let response = await invokeLambda(
      LambdaFunctions.ticketingEventsOperations,
      requestBody,
    );
    return response;
  } catch (error) {
    console.error('Error sending email :', error);
    throw error;
  }
}

export default sendEmail;
