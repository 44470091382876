import { useSelector } from 'react-redux';
import { List, useMediaQuery } from '@mui/material';

import MenuLink from '../MenuLink';
import { getComparator, stableSort } from '../../helpers/utils';
import { FeaturesNames, Orders, Pages } from '../../constants/enums';
import { AdminPermissions } from '../../models/app';
import { useState } from 'react';

interface Props {
  open: boolean;
  closeDrawer: () => void;
}

interface StateProps {
  expanded: string;
  subMenuContent: any;
}

const initialState: StateProps = {
  expanded: '',
  subMenuContent: null,
};

const NavMenu: React.FC<Props> = ({ open, closeDrawer }) => {
  const accountsSelected = useSelector((state: any) => state.accounts.selected);

  const userPermissions: AdminPermissions = useSelector(
    (state: any) => state.app.userPermissions,
  );
  
  const featuresAllListing = useSelector(
    (state: any) => state.features.listingAll,
  );

  const [state, setState] = useState(initialState);
  const tablet = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const features: any[] = [];
  const featureItems: any[] = [];
  let filtered: any[] = [];

  const canViewBookings = (): boolean => {
    return (
      userPermissions.createReservation ||
      userPermissions.viewReservations ||
      userPermissions.cancelReservation
    );
  };

  const handleSetState = (value: any) => {
    setState({ ...state, subMenuContent: value });
  };
  const handleExpanded = (value: any) => {
    setState({ ...state, expanded: value });
  };

  const canViewSettings = (): boolean => {
    return userPermissions.settings;
  };

  // const canViewCashless = (): boolean => {
  //   return userPermissions.Cashless_Access;
  // };

  const canViewWalletManagement = (): boolean => {
    return userPermissions.Wallet_Management_Access;
  };

  const canViewPOS = (): boolean => {
    return userPermissions.POS_Access;
  };

  const canViewDispatcher = (): boolean => {
    return userPermissions.can_view_dispatcher;
  };

  const canViewAllTopUps = (): boolean => {
    return userPermissions.All_Top_Ups_Access;
  };

  const canViewAllTransactions = (): boolean => {
    return userPermissions.All_Transactions_Access;
  };

  const canViewItemConsumption = (): boolean => {
    return userPermissions.Item_Consumption_Access;
  };

  // Has  payment feature and group has access
  const canViewPayment = (): boolean => {
    return userPermissions.transactionReport;
  };
  const canViewInvitationsHistory = (): boolean => {
    return userPermissions.can_view_invitations_history;
  };
  const canViewHostScans = (): boolean => {
    return userPermissions.can_view_hostscans;
  };

  const canViewReportsChildren = (slug: string): boolean => {
    let hasAccess = false;
    switch (slug) {
      case Pages.TRANSACTIONS_REPORT:
        hasAccess = canViewPayment();
        break;
      case Pages.INVITATIONS_HISTORY:
        hasAccess = canViewInvitationsHistory();
        break;
      case Pages.hostScans:
        hasAccess = canViewHostScans();
        break;
      default:
        hasAccess = false;
        break;
    }

    return hasAccess;
  };

  const canViewCashlessChildren = (slug: string): boolean => {
    let hasAccess = false;
    switch (slug) {
      case Pages.POS:
        hasAccess = canViewPOS();
        break;
      case Pages.Wallet_Managements:
        hasAccess = canViewWalletManagement();
        break;
      case Pages.CASHLESS_ALL_TOP_UPS:
        hasAccess = canViewAllTopUps();
        break;
      case Pages.CASHLESS_ALL_Transactions:
        hasAccess = canViewAllTransactions();
        break;
      case Pages.ITEM_CONSUMPTION_REPORT:
        hasAccess = canViewItemConsumption();
        break;
      case Pages.DISPATCHER:
        hasAccess = canViewDispatcher();
        break;
      default:
        hasAccess = false;
        break;
    }
    return hasAccess;
  };

  if (accountsSelected && accountsSelected.features) {
    filtered = featuresAllListing.filter(
      (feature: any) => accountsSelected.features.indexOf(feature.id) !== -1,
    );
  }
  for (let feature of filtered) {
    if (feature.parent === '-1') {
      features.push(feature);
    }
  }

  for (let feature of features) {
    if (
      feature.name.toLowerCase() === FeaturesNames.Guests &&
      userPermissions.admins === true &&
      userPermissions.Guests_Access === true
    ) {
      featureItems.push({ ...feature, children: [] });
    } else if (
      feature.name.toLowerCase() === FeaturesNames.Events &&
      userPermissions.admins === true &&
      userPermissions.Events_Access === true
    ) {
      featureItems.push({ ...feature, children: [] });
    } else if (
      feature.name.toLowerCase() === FeaturesNames.EVENT_DASHBOARD &&
      userPermissions.admins === true &&
      userPermissions.can_view_event_dashboard === true
    ) {
      featureItems.push({ ...feature, children: [] });
    } else if (
      feature.name.toLowerCase() === FeaturesNames.FIELD_DASHBOARD &&
      userPermissions.admins === true &&
      userPermissions.can_view_field_dashboard === true
    ) {
      featureItems.push({ ...feature, children: [] });
    } else if (
      feature.name.toLowerCase() === FeaturesNames.Invitations &&
      userPermissions.can_view_invitations_history &&
      userPermissions.admins === true
    ) {
      featureItems.push({ ...feature, children: [] });
    } else if (
      feature.name.toLowerCase() === FeaturesNames.Booking &&
      userPermissions.admins === true &&
      userPermissions.Booking_Access === true
    ) {
      featureItems.push({ ...feature, children: [] });
    } else if (
      feature.name.toLowerCase() === FeaturesNames.Dashboard &&
      userPermissions.dashboard === true
    ) {
      featureItems.push({ ...feature, children: [] });
    } else if (
      feature.name.toLowerCase() === FeaturesNames.SystemUsers &&
      userPermissions.settings === true &&
      userPermissions.admins === true
    ) {
      featureItems.push({ ...feature, children: [] });
    } else {
      if (
        feature.slug === '#' &&
        feature.name.toLowerCase() === FeaturesNames.Settings &&
        canViewSettings()
      ) {
        const children: any[] = [];
        for (let i = 0; i < filtered.length; i++) {
          if (filtered[i].parent === feature.id) {
            if (filtered[i].name.toLowerCase() === FeaturesNames.AdminGroups) {
              if (userPermissions.admins === true) children.push(filtered[i]);
            } else {
              children.push(filtered[i]);
            }
          }
        }

        featureItems.push({ ...feature, children });
      }

      /* Reports Section */
      if (
        feature.slug === '#' &&
        feature.name.toLowerCase() === FeaturesNames.Reports
      ) {
        const children: any[] = [];

        for (let i = 0; i < filtered.length; i++) {
          if (filtered[i].parent === feature.id) {
            const hasAccessToChild = canViewReportsChildren(
              filtered[i]?.slug ?? '',
            );
            if (!hasAccessToChild) continue;
            children.push(filtered[i]);
          }
        }
        if (children.length > 0) {
          featureItems.push({ ...feature, children });
        }
      }

      if (
        feature.slug === '#' &&
        feature.name.toLowerCase() === FeaturesNames.Cashless
        // && canViewCashless()
      ) {
        const children: any[] = [];

        for (let i = 0; i < filtered.length; i++) {
          if (filtered[i].parent === feature.id) {
            const hasAccessToChild = canViewCashlessChildren(
              filtered[i]?.slug ?? '',
            );
            if (!hasAccessToChild) continue;
            children.push(filtered[i]);
          }
        }
        if (children.length > 0) {
          featureItems.push({ ...feature, children });
        }
      }
    }
  }

  return (
    <nav className="main-navigation" style={{ flexGrow: 1 }}>
      <List
        disablePadding
        sx={{
          pt: 2,
          pl: { xs: '12px', md: open && !tablet ? 2 : '6px' },
          pr: { xs: '12px', md: open && !tablet ? 2 : '6px' },
          '&:before': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 60,
            width: '106px',
            height: '1.5px',
            backgroundColor: 'background.default',
          },
          textTransform: 'capitalize',
        }}
      >
        {stableSort(featureItems, getComparator(Orders.ASC, 'precedence')).map(
          (feature: any) => (
            <MenuLink
              key={feature.id}
              feature={feature}
              open={open}
              closeDrawer={closeDrawer}
              handleSetState={handleSetState}
              subMenuContent={state.subMenuContent}
              handleExpanded={handleExpanded}
              expanded={state.expanded}
            />
          ),
        )}
      </List>
    </nav>
  );
};

export default NavMenu;
