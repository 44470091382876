import axios from 'axios';
import { smsEndpoint } from '../constants/enums';

const sendSms = async (phone: string, message: string) => {
  try {
    const requestBody = {
      phone: phone,
      message: message,
      conceptName: 'anyware',
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    };
    const response = await axios.post(smsEndpoint, requestBody, options);
    return response.data;
  } catch (error) {
    console.error('Error :', error);
    throw error;
  }
};

export default sendSms;
