import { useDispatch, useSelector } from 'react-redux';
import {
  setListing,
  setSelected,
  setNextToken,
} from '../store/ducks/invitationHistory';
import {
  HeadCell,
  ListingVariables,
  InvitationGetVariables,
  InvitationUpdateVariables,
} from '../models/app';
import useApp from './useApp';
import { getInvitation, listEvents, listInvitations } from '../graphql/queries';
import { generateClient } from '@aws-amplify/api';
import { Invitation } from '../models';
import {
  ModelEventFilterInput,
  UpdateInvitationInput,
} from '../models/GQL_API';
import { updateInvitation } from '../graphql/mutations';

const client = generateClient();
const useInvitationHistory = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  const nextToken = useSelector(
    (state: any) => state.invitationHistory['nextToken'],
  );
  const transactionsListing = useSelector(
    (state: any) => state.invitationHistory['listing'],
  );
  const generalFilters = useSelector((state: any) => state.generalFilters);

  async function fetch(params: ListingVariables) {
    try {
      const { searchText, limit, generalFilters } = params;
      const upcomingEvent = await fetchUpComingEvent();
      // console.log({ upcomingEvent: upcomingEvent });

      const filter: any = {};
      filter.or = [];
      filter.and = [];
      if (searchText.length > 0) {
        filter.or.push({ email: { contains: searchText.toLowerCase() } });
        filter.or.push({
          phone_number: { contains: searchText.toLowerCase() },
        });
        filter.or.push({
          createdByName: { contains: searchText.toLowerCase() },
        });
        filter.or.push({
          name: { contains: searchText.toLowerCase() },
        });
      }
      if (generalFilters?.fromDate && generalFilters.toDate) {
        const fromDate = new Date(generalFilters.fromDate);
        fromDate.setHours(0, 0, 0, 500);
        const toDate = new Date(generalFilters.toDate);
        toDate.setHours(23, 59, 59, 999);
        generalFilters.fromDate = fromDate.toISOString();
        generalFilters.toDate = toDate.toISOString();
        filter.and.push({
          createdAt: {
            ge: new Date(generalFilters?.fromDate).toISOString(),
          },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(generalFilters.toDate).toISOString(),
          },
        });
      }
      if (generalFilters?.event) {
        filter.invitationEventId = { eq: generalFilters?.event };
      }

      if((!generalFilters?.event || generalFilters?.event === '') && upcomingEvent?.id){
        filter.invitationEventId = { eq: upcomingEvent?.id };
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }
      filter.deleted = { eq: 0 };
      const groupsList: any = await client.graphql<Invitation>({
        query: listInvitations,
        variables: { filter, limit:1000, nextToken: nextToken },
        authMode: 'userPool',
      });
      const currentNextToken = groupsList.data.listInvitations.nextToken;
      const responseListing = groupsList.data.listInvitations.items;

      let listing = [...transactionsListing, ...responseListing];
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAll(params: ListingVariables) {
    try {
      const { searchText, generalFilters } = params;
      let allListing: any[] = [];
      const filter: any = {};
      filter.or = [];
      filter.and = [];
      if (searchText.length > 0) {
        filter.or.push({ name: { contains: searchText.toLowerCase() } });
        filter.or.push({
          phone_number: { contains: searchText.toLowerCase() },
        });
      }
      if (generalFilters?.fromDate && generalFilters.toDate) {
        filter.and.push({
          createdAt: {
            ge: new Date(generalFilters?.fromDate).toISOString(),
          },
        });
        filter.and.push({
          createdAt: {
            lt: new Date(generalFilters.toDate).toISOString(),
          },
        });
      }
      if (generalFilters?.event) {
        filter.invitationEventId = { eq: generalFilters?.event };
      }
      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }
      const upcomingEvent = await fetchUpComingEvent();

      if((!generalFilters?.event || generalFilters?.event === '') && upcomingEvent?.id){
        filter.invitationEventId = { eq: upcomingEvent?.id };
      }
      filter.deleted = { eq: 0 };

      const groupsList: any = await client.graphql<Invitation>({
        query: listInvitations,
        variables: { filter, limit: 1000 },
        authMode: 'userPool',
      });
      let currentNextToken = groupsList.data.listInvitations.nextToken;
      allListing = groupsList.data.listInvitations.items;
      while (currentNextToken) {
        const groupsListNew: any = await client.graphql<Invitation>({
          query: listInvitations,
          variables: { filter, limit: 1000, nextToken: currentNextToken },
          authMode: 'userPool',
        });
        allListing = [...allListing, ...groupsListNew.data.listInvitations.items];
        currentNextToken = groupsListNew.data.listInvitations.nextToken;
      }      
      return allListing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchUpComingEvent() {
    try {
      const limit = 10000;
      const filter: ModelEventFilterInput = {
        deleted: { eq: '0' },
        published: {
          eq: true,
        },
        endDate: {
          gt: new Date().toISOString(),
        },
      };

      const eventList = await client.graphql({
        query: listEvents,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });
      const listing = eventList.data.listEvents.items;
      return listing[0];
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function get(params: InvitationGetVariables) {
    try {
      // const { id, listing } = params;
      let single: Invitation | undefined;
      // if (listing?.length) {
      //   single = listing.find((resource: any) => resource.id === id);
      // }
      // if (single === undefined) {
      const listing: any = await client.graphql<Invitation>({
        query: getInvitation,
        variables: { id: params.id },
        authMode: 'userPool',
      });
      single = listing.data.getInvitation;
      // }
      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: InvitationUpdateVariables) {
    try {
      // const { data } = params;
      // const original: any = await get(params);
      // if (!original) {
      //   showError(`Invalid ${singleName} ID`);
      //   return;
      // }

      // const updateInput: UpdateInvitationInput = {
      //   id: original.id,
      //   refunded_amount_cents: data.refunded_amount_cents
      //     ? data.refunded_amount_cents
      //     : original!.refunded_amount_cents,
      //   _version: original._version,
      // };

      // let updatedInvitation = await client.graphql({
      //   query: updateInvitation,
      //   variables: { input: updateInput },
      //   authMode: 'userPool',
      // });

      showConfirm(`${singleName} has been updated successfully`);
      // return updatedInvitation.data.updateInvitation;
    } catch (err) {
      showError(err);
    }
  }
  async function trash(params: InvitationGetVariables) {
    try {
      const original: any = await get(params);
      const { id, listing } = params;

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateInvitationInput = {
        id: original.id,
        deleted: '1',
        _version: original._version,
      };

      await client.graphql<Invitation>({
        query: updateInvitation,
        variables: { input: updateInput },
        authMode: 'userPool',
      });
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }
  async function exportAll(params: any) {
    try {
      let exportedData: Invitation[] = [];
      const data: Invitation[] = await fetchAll({ ...params, generalFilters });
      for (let user of data!) {
        let row: Invitation = { ...user };
        exportedData.push(row);
      }
      return exportedData;
    } catch (err: any) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'phone_number',
      numeric: false,
      disablePadding: false,
      label: 'Phone',
    },
    {
      id: 'event',
      numeric: false,
      disablePadding: false,
      label: 'Event',
    },
    {
      id: 'used',
      numeric: false,
      disablePadding: false,
      label: 'Used',
    },
    {
      id: 'createdByName',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Created At',
    },

    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];
  const dataCells: readonly string[] = [
    'name',
    'email',
    'phone_number',
    'event',
    'used',
    'createdByName',
    'createdAt',
  ];

  const api: any = {};
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Update`] = update;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}Trash`] = trash;

  api[`${listingName}ChangeListing`] = (listing: Invitation[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = transactionsListing;
  return api;
};
export default useInvitationHistory;
