import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  capitalizeSentence,
  formatDateToAmPmFormat,
} from '../../../../helpers/utils';

interface Props {
  transaction: any;
  showPopUp: boolean;
  setShowPopUp: any;
}

const OrderDetails: React.FC<Props> = ({
  transaction,
  showPopUp,
  setShowPopUp,
}) => {
  const { createdAt, checkNumber, createdByName, orderTotal, orderedItems, id } =
    transaction;
  const accountsSelected = useSelector((state: any) => state.accounts.selected);

  return (
    <>
      <Dialog
        open={showPopUp}
        onClose={() => setShowPopUp(false)}
        sx={{ minWidth: '50%', borderRadius: 20 }}
        PaperProps={{
          sx: {
            borderRadius: '15px',
          },
        }}
      >
        <DialogTitle
          sx={{
            cursor: 'move',
            textAlign: 'center',
            fontWeight: 'bold',
            backgroundColor: 'background.default',
          }}
          id="draggable-dialog-title"
        >
          Order Details
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: 'background.default' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 3,
            }}
          >
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
              Check # {id.slice(0,8)}
            </Typography>

            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'flex-start'}
              sx={{ width: '100%' }}
            >
              <Typography sx={{ color: 'secondary.contrastText' }} variant="h6">
                Date: {formatDateToAmPmFormat(new Date(createdAt))}
              </Typography>
              <Typography sx={{ color: 'secondary.contrastText' }} variant="h6">
                Employee: {capitalizeSentence(createdByName)}
              </Typography>
            </Box>

            <TableContainer
              component={Paper}
              sx={{
                mb: '15px',
                mt: '15px',
                border: '1px solid #434343',
                backgroundColor: 'none',
                width: { sm: '20rem' },
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: 'info.500',
                        fontSize: 18,
                        borderRight: '1px solid #212121',
                        borderBottom: '1px solid #212121',
                      }}
                      align="center"
                    >
                      Item
                    </TableCell>
                    <TableCell
                      sx={{
                        color: 'info.500',
                        fontSize: 18,
                        borderRight: '1px solid #212121',
                        borderBottom: '1px solid #212121',
                      }}
                      align="center"
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      sx={{
                        color: 'info.500',
                        fontSize: 18,
                        borderBottom: '1px solid #212121',
                      }}
                      align="center"
                    >
                      Price
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderedItems &&
                    orderedItems.map((item: any, i: any) => (
                      <TableRow key={i}>
                        <TableCell
                          sx={{
                            fontSize: 15,
                            borderRight: '1px solid #212121',
                            borderBottom: '1px solid #212121',
                          }}
                          align="center"
                          component="th"
                          scope="row"
                        >
                          {capitalizeSentence(item.name)}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 15,
                            borderRight: '1px solid #212121',
                            borderBottom: '1px solid #212121',
                          }}
                          align="center"
                        >
                          {item.quantity}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 15,
                            borderBottom: '1px solid #212121',
                          }}
                          align="center"
                        >
                          {item.price} {accountsSelected.currency}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Typography variant="h6">Order Total: {orderTotal} {accountsSelected.currency}</Typography> */}
          </Box>
        </DialogContent>

        <DialogActions sx={{ backgroundColor: 'background.default' }}>
          <Box
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            width={'100%'}
            margin={2}
          >
            <Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor: 'info.400',
                color: 'white',
                width: '35%',
                textTransform: 'none',
              }}
              autoFocus
              onClick={() => setShowPopUp(false)}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderDetails;
