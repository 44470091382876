import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

interface Props {
  state: any;
  handleChange: any;
  months: number;
  setLimit: boolean;
  maxDate?: boolean;
}

const DateRange: React.FC<Props> = ({
  state,
  handleChange,
  months,
  setLimit,
  maxDate,
}) => {
  if (setLimit) {
    if (maxDate) {
      return (
        <DateRangePicker
          maxDate={new Date()}
          onChange={handleChange}
          moveRangeOnFirstSelection={false}
          months={months}
          ranges={state}
          direction="horizontal"
        />
      );
    } else {
      return (
        <DateRangePicker
          minDate={new Date()}
          staticRanges={[]}
          inputRanges={[]}
          onChange={handleChange}
          moveRangeOnFirstSelection={false}
          months={months}
          ranges={state}
          direction="horizontal"
        />
      );
    }
  } else {
    return (
      <DateRangePicker
        onChange={handleChange}
        moveRangeOnFirstSelection={false}
        months={months}
        ranges={state}
        direction="horizontal"
      />
    );
  }
};

export default DateRange;
