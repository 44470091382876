import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: '',
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: _.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public handleRefreshClick() {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });

    caches.keys().then((keyList) => {
      return Promise.all(
        keyList.map((key) => {
          return caches.delete(key);
        }),
      );
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  public render() {
    if (this.state.hasError) {
      // this.handleRefreshClick();

      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
          }}
        >
          <Box>
            <Typography variant="h4" paragraph align="center">
              Ooops!. Something Went Wrong!
            </Typography>
            <Typography variant="h5" paragraph align="center">
              Reload the Page, If the error persist
            </Typography>
            <Typography align="center">Contact Site Support</Typography>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
