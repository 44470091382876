import { createAdminRole, updateAdminRole } from './../graphql/mutations';
import { UpdateAdminRoleInput } from './../models/GQL_API';
import { useDispatch, useSelector } from 'react-redux';
import {
  setListing,
  setSelected,
  setNextToken,
} from '../store/ducks/adminRoles';
import { AdminRole } from '../models/GQL_API';
import { CreateAdminRoleInput } from '../models/GQL_API';
import {
  AdminRoleBulkTrashVariables,
  AdminRoleGetVariables,
  AdminRoleUpdateVariables,
  CreateVariables,
  ListingVariables,
  Option,
  HeadCell,
} from '../models/app';
import { deleteAdminRole } from '../graphql/mutations';
import useApp from './useApp';
import { getAdminRole, listAdminRoles } from '../graphql/queries';
import { onCreateAdminRole } from '../graphql/subscriptions';
import { generateClient } from '@aws-amplify/api';
import { clearLocalStorageData, sessionTimeout } from '../helpers/utils';
import { AppTypes, appType } from '../constants/enums';

const client = generateClient();

const useAdminRole = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]['nextToken'],
  );
  const rolesListing = useSelector(
    (state: any) => state[`${listingName}`]['listing'],
  );
  async function fetch(params: ListingVariables) {
    try {
      const offlineAdminRoles = localStorage.getItem('offlineAdminRoles');
      if (
        offlineAdminRoles &&
        !sessionTimeout() &&
        // @ts-ignore
        appType === AppTypes.CASHLESS
      ) {
        let adminRoles = JSON.parse(offlineAdminRoles);
        if (adminRoles) {
          return adminRoles;
        }
      } else {
        clearLocalStorageData();

        const { searchText, limit } = params;

        const filter: any = {
          deleted: { eq: '0' },
        };

        if (searchText.length > 0) {
          filter.name = { contains: searchText.toLowerCase() };
        }

        const groupsList: any = await client.graphql<AdminRole>({
          query: listAdminRoles,
          variables: { filter, nextToken: nextToken, limit: 100000 },
          authMode: 'userPool',
        });
        const currentNextToken = groupsList.data.listAdminRoles.nextToken;
        const responseListing = groupsList.data.listAdminRoles.items;

        let listing = [...rolesListing, ...responseListing];
        localStorage.setItem('offlineAdminRoles', JSON.stringify(listing));
        dispatch(setListing(listing));
        dispatch(setNextToken(currentNextToken));
        return listing;
      }
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAll(params: ListingVariables) {
    try {
      const offlineAdminRoles = localStorage.getItem('offlineAdminRoles');
      if (
        offlineAdminRoles &&
        !sessionTimeout() &&
        // @ts-ignore
        appType === AppTypes.CASHLESS
      ) {
        let adminRoles = JSON.parse(offlineAdminRoles);
        if (adminRoles) {
          return adminRoles;
        }
      } else {
        clearLocalStorageData();

        const { searchText, limit } = params;

        const filter: any = {
          deleted: { eq: '0' },
        };

        if (searchText.length > 0) {
          filter.name = { contains: searchText.toLowerCase() };
        }

        let listing: any[] = [];
        let currentNextToken = null;

        do {
          const groupsList: any = await client.graphql<AdminRole>({
            query: listAdminRoles,
            variables: { filter, nextToken: currentNextToken, limit: 100000 },
            authMode: 'userPool',
          });

          currentNextToken = groupsList.data.listAdminRoles.nextToken;
          const responseListing = groupsList.data.listAdminRoles.items;

          listing = [...listing, ...responseListing];
        } while (currentNextToken);

        localStorage.setItem('offlineAdminRoles', JSON.stringify(listing));
        dispatch(setListing(listing));
        dispatch(setNextToken(currentNextToken));
        return listing;
      }
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: AdminRoleGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: AdminRole) => model.id === id);

      return model ? model.name : '';
    }

    return '';
  };

  async function get(params: AdminRoleGetVariables) {
    try {
      const { id, listing } = params;

      let single: AdminRole | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await client.graphql<AdminRole>({
          query: getAdminRole,
          variables: { id },
          authMode: 'userPool',
        });
        single = listing.data.getAdminRole;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    try {
      const { userID, userName, data } = params;

      const createInput: CreateAdminRoleInput = {
        name: data.name.toLowerCase(),
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.description) createInput.description = data.description;

      await client.graphql<AdminRole>({
        query: createAdminRole,
        variables: { input: createInput },
        authMode: 'userPool',
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: AdminRoleUpdateVariables) {
    try {
      const { data } = params;

      const original: any = await get(params);

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateAdminRoleInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase() : original!.name,
        description: data.description
          ? data.description
          : original!.description,
        _version: original._version,
      };

      await client.graphql<AdminRole>({
        query: updateAdminRole,
        variables: { input: updateInput },
        authMode: 'userPool',
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: AdminRoleGetVariables) {
    try {
      const original: any = await get(params);
      const { id, listing } = params;

      if (original) {
        const updateInput: UpdateAdminRoleInput = {
          id: original.id,
          deleted: '1',
          _version: original._version,
        };

        await client.graphql<AdminRole>({
          query: updateAdminRole,
          variables: { input: updateInput },
          authMode: 'userPool',
        });
      }
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: AdminRoleBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    return `${ids.size} ${listingName} items has been moved to trash`;
  }

  async function remove(params: AdminRoleGetVariables) {
    try {
      const { id, listing } = params;

      await client.graphql<AdminRole>({
        query: deleteAdminRole,
        variables: { id: id },
        authMode: 'userPool',
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  function options(listing: AdminRole[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name!, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  const dataCells: readonly string[] = ['name', 'description'];

  const api: any = {};

  api[`${listingName}CreateSubscription`] = onCreateAdminRole;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: AdminRole[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = rolesListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useAdminRole;
