import { NavLink } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { primaryMain, greyVariations } from '../../constants/colors';

interface Props {
  title: string;
  slug: string;
  closeDrawer: () => void;
}

const MenuLink: React.FC<Props> = ({ title, slug, closeDrawer }) => {
  const tablet = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const handleClick = () => {
    if (tablet) closeDrawer();
  };

  return (
    <Box
      sx={{
        background: (theme) => greyVariations(theme, 100),
        height: 40,
        pl: '4px',
        display: 'flex',
        mb: 2,
        textDecoration: 'none',
        '&.active': {
          background: (theme) => primaryMain(theme),
        },
      }}
      {...({ component: NavLink, to: slug } as any)}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          background: (theme) => greyVariations(theme, 100),
          width: '100%',
          height: '100%',
          lineHeight: 0,
        }}
      >
        <ArrowForwardIosIcon
          sx={{
            transform: 'scale(.6)',
            color: (theme) => primaryMain(theme),
          }}
        />
        <Typography color="text.secondary">{title}</Typography>
      </Box>
    </Box>
  );
};

export default MenuLink;
