import { ThemeOptions } from '@mui/material/styles';

const darkThemeOptions: ThemeOptions = {
  direction: 'ltr',
  palette: {
    mode: 'dark',
    primary: {
      light: '#717171',
      main: '#FFF',
      dark: '#ABBBC2',
      // contrastText: '#F0635A',
      contrastText: 'red',
    },
    secondary: {
      light: '#2E2E2E',
      main: '#1E1E1E',
      dark: '#0A0A0A',
      contrastText: '#919191',
    },
    background: {
      paper: '#000',
      default: '#121212',
    },
    grey: {
      // 300: '#2E2E2E',
    },
    info: {
      400: '#5A7C95',
      500: '#F0635A',
      600: '#212121',
      700: '#434343',
      300: '#2E2E2E',
    },
  },
  shape: {
    borderRadius: 0,
  },
};

export default darkThemeOptions;
