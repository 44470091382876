import { generateClient } from '@aws-amplify/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  ItemConsumptionByEventId,
  cashlessTransactionByGuestID,
} from '../graphql/queries';
import { HeadCell, ListingVariables } from '../models/app';
import { setListing, setNextToken } from '../store/ducks/itemConsumption';
import { ItemConsumption } from './../models/GQL_API';
import useApp from './useApp';

const client = generateClient();
const useItemConsumption = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError } = useApp();
  const itemConsumptionListing = useSelector(
    (state: any) => state.itemConsumption.listing,
  );
  const newFirstFetch = useSelector((state: any) => state.app.firstFetch);

  const selectedAdmin = useSelector((state: any) => state.admins.selected);

  const nextToken = useSelector(
    (state: any) => state[`itemConsumption`]['nextToken'],
  );

  const reduxListing = useSelector(
    (state: any) => state[`itemConsumption`]['listing'],
  );
  const session = useSelector((state: any) => state.app.session);

  async function fetch(params: ListingVariables) {
    try {
      const { searchText, limit, generalFilters } = params;

      // console.log({ generalFilters, searchText, selectedAdmin });
      if (!generalFilters?.event || !selectedAdmin)
        return itemConsumptionListing;

      const filter: any = {
        deleted: { eq: '0' },
        createdByID: { eq: selectedAdmin.id },
      };
      const createdAtFilter: any = {};
      filter.or = [];
      filter.and = [];
      if (searchText.length > 0) {
        if (/[a-zA-Z]/.test(searchText)) {
          filter.or.push({
            itemName: { contains: searchText.toLocaleLowerCase() },
          });
        } else if (/[0-9]/.test(searchText)) {
          filter.or.push({ itemPrice: { contains: searchText } });
        }
      }

      if (generalFilters?.fromDate && generalFilters.toDate) {
        const toDatePlusOneDay = new Date(generalFilters.toDate);
        toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
        createdAtFilter.between = [
          new Date(generalFilters.fromDate).toISOString(),
          new Date(toDatePlusOneDay).toISOString(),
        ];
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const variables: any = {
        filter,
        limit: 100000,
        nextToken: nextToken,
        sortDirection: 'DESC',
        eventId: generalFilters?.event,
      };

      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }

      const groupsList: any = await client.graphql<ItemConsumption>({
        query: ItemConsumptionByEventId,
        variables: variables,
        authMode: 'userPool',
      });

      const currentNextToken =
        groupsList.data.ItemConsumptionByEventId.nextToken;
      const responseListing = groupsList.data.ItemConsumptionByEventId.items;

      let listing = [...reduxListing, ...responseListing];
      // if (responseListing?.length > 0) {
      dispatch(setListing(listing));
      // }
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'itemImage',
      numeric: false,
      disablePadding: false,
      label: 'Item Image',
    },
    {
      id: 'itemName',
      numeric: false,
      disablePadding: false,
      label: 'Item Name',
    },
    {
      id: 'itemQuantity',
      numeric: false,
      disablePadding: false,
      label: 'Sold Quantity',
    },
    {
      id: 'itemPrice',
      numeric: false,
      disablePadding: false,
      label: 'Item Price',
    },
    {
      id: 'totalPayment',
      numeric: false,
      disablePadding: false,
      label: 'Total Payment',
    },
    {
      id: 'event',
      numeric: false,
      disablePadding: false,
      label: 'Event',
    },
  ];
  const dataCells: readonly string[] = [
    'itemImage',
    'itemName',
    'itemQuantity',
    'itemPrice',
    'totalPayment',
    'event',
  ];
  const api: any = {};

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}ChangeListing`] = (listing: ItemConsumption[]) =>
    dispatch(setListing(listing));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = reduxListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};
export default useItemConsumption;
