import { ADMIN_ROLES, AppTypes, appType } from '../constants/enums';
import { clearLocalStorageData, sessionTimeout } from '../helpers/utils';
import { AdminPermissions, AdminRoleGetVariables } from '../models/app';
import useAdminRole from './useAdminRole';

const usePermissions = () => {
  const { adminRolesGet } = useAdminRole('adminRoles', 'adminRole');

  const adminPermissions: AdminPermissions = {
    admins: true,
    dashboard: false,
    settings: false,
    viewReservations: false,
    createReservation: false,
    cancelReservation: false,
    createGuest: false,
    editGuest: false,
    editVip: false,
    lockTable: false,
    editLayout: false,
    // Payment Access
    transactionReport: false,
    paymentDashboard: false,
    voidPaymentAccess: false,
    refundPaymentAccess: false,
    paymobSettings: false,

    callCenter: false,
    waiter: false,
    kitchen: false,
    view_payment_analysis_report: false,
    view_gender_analysis_report: false,
    view_tickets_statistics_report: false,
    view_ticket_types_overview_report: false,
    Booking_Access: false,
    Guests_Access: false,
    Events_Access: false,
    // Cashless_Access: false,
    POS_Access: false,
    Wallet_Management_Access: false,
    All_Top_Ups_Access: false,
    All_Transactions_Access: false,
    Item_Consumption_Access: false,
    can_view_dispatcher: false,
    can_view_complementary: false,
    can_view_invitations_history: false,
    can_view_hostscans: false,
    can_cancel_invitations: false,
    can_view_event_dashboard: false,
    can_view_field_dashboard: false,
  };

  const getAdminPermissions = async (props: {
    userGroup: any;
  }): Promise<AdminPermissions> => {
    const { userGroup } = props;
    if (userGroup && userGroup.length > 0) {
      let userRoles: any[] = [];
      for (let group of userGroup) {
        userRoles = userRoles.concat(group.roles);
      }

      // const userRoles = userGroup[0].roles;
      let roles: any;
      const offlineRoles = localStorage.getItem('roles');
      // @ts-ignore
      if (offlineRoles && !sessionTimeout() && appType === AppTypes.CASHLESS) {
        roles = JSON.parse(offlineRoles);
      } else {
        clearLocalStorageData();
        roles = await Promise.all(
          userRoles.map((role) => {
            const adminRolesParams: AdminRoleGetVariables = {
              id: role,
              listing: [],
            };
            return adminRolesGet(adminRolesParams);
          }),
        );
        localStorage.setItem('roles', JSON.stringify(roles));
      }
      // @ts-ignore
      if (offlineRoles && !sessionTimeout() && appType === AppTypes.CASHLESS) {
        roles.forEach((userRole) => {
          if (userRole === null) {
            return;
          }
          switch (userRole.name) {
            case ADMIN_ROLES.POS_Access:
              adminPermissions.POS_Access = true;
              break;
            case ADMIN_ROLES.Wallet_Management_Access:
              adminPermissions.Wallet_Management_Access = true;
              break;
            case ADMIN_ROLES.Can_View_Dispatcher:
              adminPermissions.can_view_dispatcher = true;
              break;
            case ADMIN_ROLES.Can_View_Complementary:
              adminPermissions.can_view_complementary = true;
              break;
            default:
              break;
          }
        });
      } else {
        roles.forEach((userRole) => {
          if (userRole === null) {
            return;
          }
          switch (userRole.name) {
            case ADMIN_ROLES.ADMINS:
              adminPermissions.admins = true;
              break;
            case ADMIN_ROLES.DASHBOARD:
              adminPermissions.dashboard = true;
              break;
            case ADMIN_ROLES.SETTINGS:
              adminPermissions.settings = true;
              break;
            case ADMIN_ROLES.VIEW_RESERVATIONS:
              adminPermissions.viewReservations = true;
              break;
            case ADMIN_ROLES.CREATE_RESERVATION:
              adminPermissions.createReservation = true;
              break;
            case ADMIN_ROLES.CANCEL_RESERVATION:
              adminPermissions.cancelReservation = true;
              break;
            case ADMIN_ROLES.CREATE_GUEST:
              adminPermissions.createGuest = true;
              break;
            case ADMIN_ROLES.EDIT_GUEST:
              adminPermissions.editGuest = true;
              break;
            case ADMIN_ROLES.EDIT_VIP:
              adminPermissions.editVip = true;
              break;
            case ADMIN_ROLES.EDIT_LAYOUT:
              adminPermissions.editLayout = true;
              break;
            case ADMIN_ROLES.LOCK_TABLE:
              adminPermissions.lockTable = true;
              break;
            case ADMIN_ROLES.TRANSACTION_REPORT:
              adminPermissions.transactionReport = true;
              break;
            case ADMIN_ROLES.PAYMENT_DASHBOARD:
              adminPermissions.paymentDashboard = true;
              break;
            case ADMIN_ROLES.PAYMOB_SETTINGS:
              adminPermissions.paymobSettings = true;
              break;
            case ADMIN_ROLES.CALL_CENTER:
              adminPermissions.callCenter = true;
              break;
            case ADMIN_ROLES.WAITER:
              adminPermissions.waiter = true;
              break;
            case ADMIN_ROLES.KITCHEN:
              adminPermissions.kitchen = true;
              break;
            case ADMIN_ROLES.view_payment_analysis_report:
              adminPermissions.view_payment_analysis_report = true;
              break;
            case ADMIN_ROLES.view_gender_analysis_report:
              adminPermissions.view_gender_analysis_report = true;
              break;
            case ADMIN_ROLES.view_tickets_statistics_report:
              adminPermissions.view_tickets_statistics_report = true;
              break;
            case ADMIN_ROLES.view_ticket_types_overview_report:
              adminPermissions.view_ticket_types_overview_report = true;
              break;
            case ADMIN_ROLES.Booking_Access:
              adminPermissions.Booking_Access = true;
              break;
            case ADMIN_ROLES.guests_Access:
              adminPermissions.Guests_Access = true;
              break;
            case ADMIN_ROLES.events_Access:
              adminPermissions.Events_Access = true;
              break;
            // case ADMIN_ROLES.cashless_Access:
            //   adminPermissions.Cashless_Access = true;
            //   break;
            case ADMIN_ROLES.POS_Access:
              adminPermissions.POS_Access = true;
              break;
            case ADMIN_ROLES.Wallet_Management_Access:
              adminPermissions.Wallet_Management_Access = true;
              break;
            case ADMIN_ROLES.All_Top_Ups_Access:
              adminPermissions.All_Top_Ups_Access = true;
              break;
            case ADMIN_ROLES.All_Transactions_Access:
              adminPermissions.All_Transactions_Access = true;
              break;
            case ADMIN_ROLES.Can_View_Dispatcher:
              adminPermissions.can_view_dispatcher = true;
              break;
            case ADMIN_ROLES.Item_Consumption_Access:
              adminPermissions.Item_Consumption_Access = true;
              break;
            case ADMIN_ROLES.Can_View_Complementary:
              adminPermissions.can_view_complementary = true;
              break;
            case ADMIN_ROLES.can_view_invitations_history:
              adminPermissions.can_view_invitations_history = true;
              break;
            case ADMIN_ROLES.can_view_hostscans:
              adminPermissions.can_view_hostscans = true;
              break;
            case ADMIN_ROLES.can_cancel_invitations:
              adminPermissions.can_cancel_invitations = true;
              break;
            case ADMIN_ROLES.can_view_event_dashboard:
              adminPermissions.can_view_event_dashboard = true;
              break;
            case ADMIN_ROLES.can_view_field_dashboard:
              adminPermissions.can_view_field_dashboard = true;
              break;
            default:
              break;
          }
        });
      }
    }
    return adminPermissions;
  };

  return { getAdminPermissions };
};

export default usePermissions;
