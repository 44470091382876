import { FC, Suspense, useState, memo, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CachedIcon from '@mui/icons-material/Cached';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import ContentLoader from '../../components/UI/ContentLoader/ContentLoader';
import { Pages, cloudinaryURL } from '../../constants/enums';
import ActionLoader from '../../components/UI/ActionLoader/ActionLoader';
import Logo from '../../components/Logo/Logo';
import useApp from '../../hooks/useApp';
import useAuth from '../../hooks/useAuth';
import useNetwork from '../../hooks/useNetwork';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Alert,
  AlertTitle,
  Avatar,
  Backdrop,
  Button,
  Collapse,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import NavMenu from '../../components/NavMenu/NavMenu';
import MultiConcepts from '../../components/MultiConcepts/MultiConcepts';
import { capitalizeSentence, clearBrowser } from '../../helpers/utils';
import { useSelector } from 'react-redux';
import useUpdating from '../../hooks/useUpdating';
// import usePreparationArea from "../../hooks/usePreparationArea";
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import Dashboard from '../../components/Dashboard/Dashboard';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: '100%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - 256px)`,
      marginLeft: 256,
    },
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  display: useMediaQuery(theme.breakpoints.up('md')) ? 'block' : 'none',
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 56,
    backgroundColor: theme.palette?.background.default,
    color: theme.palette?.primary.main,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
    }),
    [theme.breakpoints.up('md')]: {
      width: 256,
      ...(!open && {
        width: theme.spacing(0),
      }),
    },
  },
}));

type StateProps = {
  accountID: string | undefined;
  alertOpen: boolean;
  conceptsOpen: boolean;
  anchorEl: null | HTMLElement;
  mobileDrawerOpen: boolean;
  desktopDrawerOpen: boolean;
};

const initialState: StateProps = {
  accountID: undefined,
  alertOpen: false,
  conceptsOpen: false,
  mobileDrawerOpen: false,
  desktopDrawerOpen: true,
  anchorEl: null,
};

const DashboardContainer: FC = () => {
  const [state, setState] = useState(initialState);
  let session = useSelector((state: any) => state.app.session);
  const { navigateTo } = useApp();
  const { logout } = useAuth();
  const { updating, changeUpdating } = useUpdating();
  const { alertOpen, anchorEl, mobileDrawerOpen, desktopDrawerOpen } = state;
  const { since, online } = useNetwork();
  const menuId = 'primary-search-account-menu';
  const isMenuOpen = Boolean(anchorEl);
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const tablet = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const screen = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const userPermissions = useSelector(
    (state: any) => state.app.userPermissions,
  );
  const { setSession } = useApp();
  const selectedUser = useSelector((state: any) => state.app.selectedUser);
  const NewImg = selectedUser?.picture;

  // const { preparationAreasFetchByUser, preparationAreasChangeUserAreas } =
  //   usePreparationArea("preparationAreas", "preparationArea");

  useEffect(() => {
    if (!online || (online && since)) {
      setState({ ...state, alertOpen: true });
    }

    if (alertOpen) {
      setTimeout(() => {
        setState({ ...state, alertOpen: false });
      }, 2000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online]);

  const toggleDrawer = () => {
    screen
      ? setState({ ...state, mobileDrawerOpen: !state.mobileDrawerOpen })
      : setState({ ...state, desktopDrawerOpen: !state.desktopDrawerOpen });
  };

  const handleMenuClose = () => {
    setState({ ...state, anchorEl: null });
  };

  const handleConcepts = () => {
    setState({ ...state, conceptsOpen: !state.conceptsOpen, anchorEl: null });
  };

  const handleLogout = async () => {
    changeUpdating(true);
    setState({ ...state, anchorEl: null });

    await logout();
    changeUpdating(false);
  };

  const handleAdminProfile = async () => {
    let adminGroup = '';
    if (session['cognito:groups']) {
      adminGroup = session['cognito:groups'][0];
    }

    try {
      setState({ ...state, anchorEl: null });
      if (adminGroup === 'admin') {
        navigateTo(
          `/dashboard/${Pages.ADMINS + '/' + session['cognito:username']}`,
        );
      } else if (adminGroup === 'staff') {
        navigateTo(
          `/dashboard/${
            Pages.STAFF_MEMBERS + '/' + session['cognito:username']
          }`,
        );
      }
    } catch (error) {}
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setState({ ...state, anchorEl: event.currentTarget });
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!userPermissions.kitchen && !userPermissions.waiter && (
        <MenuItem onClick={handleAdminProfile}>
          <ListItemIcon>
            <ManageAccountsIcon
              fontSize="small"
              sx={{ color: 'primary.main' }}
            />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
      )}
      <MenuItem onClick={handleConcepts}>
        <ListItemIcon>
          <MyLocationIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Concepts</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" sx={{ color: 'primary.main' }} />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </Menu>
  );

  // for Etiahad
  const accountsSelected = useSelector((state: any) => state.accounts.selected);
  const featuresAllListing = useSelector(
    (state: any) => state.features.listingAll,
  );
  const [landscapeOrientation, setLandscapeOrientation] = useState<any>(true);
  const [hasWaiterOrder, setHasWaiterOrder] = useState<any>(true);
  const [flag, setFlag] = useState<any>(false);

  const [userPrepArea, setUserPrepArea] = useState<any>(null);

  function checkFeature(slug: any) {
    let feature: any[] = [];
    if (accountsSelected && accountsSelected.features) {
      feature = featuresAllListing.filter(function (feature: any) {
        return (
          slug.toLowerCase() === feature.slug.toLowerCase() &&
          accountsSelected.features.indexOf(feature.id) !== -1
        );
      });
    }
    if (feature.length > 0) {
      setHasWaiterOrder(true);
      if (window.matchMedia('(orientation: landscape)').matches) {
        // console.log("Device is in landscape orientation.");
        setLandscapeOrientation(true);
      } else if (window.matchMedia('(orientation: portrait)').matches) {
        // console.log("Device is in portrait orientation.");
        setLandscapeOrientation(false);
      }
      setFlag(true);
    } else {
      setHasWaiterOrder(false);
    }
  }

  function handleOrientationChange() {
    setTimeout(function () {
      if (window.matchMedia('(orientation: landscape)').matches) {
        console.log('Device is in landscape orientation.');
        setLandscapeOrientation(true);
      } else if (window.matchMedia('(orientation: portrait)').matches) {
        console.log('Device is in portrait orientation.');
        setLandscapeOrientation(false);
      }
    }, 50);
  }
  if (flag) {
    window.addEventListener('orientationchange', handleOrientationChange);
  }

  useEffect(() => {
    if (!session) {
      navigateTo(`/`);
    }
    checkFeature('waiterOrder');
  }, []);

  useEffect(() => {
    if (userPermissions.waiter === true) {
      const currentUrl = new URL(window.location.href);
      const params = new URLSearchParams(currentUrl.search);
      if (!params.has('userID')) {
        currentUrl.searchParams.append('userID', session.sub);
        window.history.replaceState({}, '', currentUrl);
        setTimeout(() => {
          currentUrl.searchParams.delete('userID');
          window.history.replaceState({}, '', currentUrl);
        }, 50);
      }
    }
  }, []);

  // const getPreparationArea = async () => {
  //   const params: any = {
  //     limit: 1000,
  //     userID: session.sub,
  //     accountID: accountsSelected.id,
  //   };
  //   const userPreparationArea = await preparationAreasFetchByUser(params);
  //   preparationAreasChangeUserAreas(userPreparationArea);
  //   setUserPrepArea(userPreparationArea);
  // };

  // useEffect(() => {
  //   if (userPermissions.kitchen === true) {
  //     getPreparationArea();
  //   }
  // }, []);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          // overflowY: 'scroll',
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100vh',
        }}
      >
        <AppBar
          // the above overflow is added for Etihad
          position="absolute"
          open={desktopDrawerOpen}
          elevation={0}
          sx={{
            backgroundColor: 'background.default',
            color: 'primary.main',
          }}
        >
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '18px',
                ...(desktopDrawerOpen && !screen && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{
                color: 'primary.dark',
                fontSize: {xs:'18px',sm:'24px'},
                fontWeight: 700,
                flexGrow: 1,
                textTransform: 'capitalize',
              }}
            >
              Hello {session?.name} !
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '7px',
              }}
            >
              {/* <Box
                sx={{
                  background: '#0A0A0A',
                  width: '48px',
                  height: '48px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <NotificationsNoneIcon />
              </Box> */}
              <Box sx={{}}>
                <Avatar
                  sx={{
                    width: '40px',
                    height: '40px',
                  }}
                  alt={session?.name.toUpperCase()}
                  src={`${cloudinaryURL}${session?.picture}`}
                />
              </Box>
            </Box>
            {/* <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "18px",
                  ...(desktopDrawerOpen && !tablet && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton> */}
            {/* <Box
                sx={{
                  display: desktopDrawerOpen && !tablet ? "none" : "block",
                  marginRight: 2,
                }}
              >
                <Logo />
              </Box> */}
            {/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {!tablet && userPermissions.kitchen === false && (
                  <MultiConcepts />
                )}
              </Box> */}
            {/* {userPermissions.kitchen &&
                userPrepArea &&
                userPrepArea.length > 0 && (
                  <Box
                    fontSize={"small"}
                    bgcolor={"black"}
                    padding={0.5}
                    minWidth={40}
                  >
                    <Typography
                      align={"center"}
                      fontFamily={"Urbanist"}
                      color="#FFFFFF"
                    >
                      {capitalizeSentence(userPrepArea[0].name)}
                    </Typography>
                  </Box>
                )} */}
            {/* <Box sx={{ flex: 1 }} /> */}

            {/* {!hasWaiterOrder && (
                <Button
                  onClick={async () => {
                    await logout();
                    await clearBrowser();
                  }}
                  variant="contained"
                  size="small"
                  sx={{
                    textTransform: "none",
                    display: { xs: "none", sm: "flex" },
                  }}
                  endIcon={<CachedIcon fontSize="small" />}
                >
                  Clear Browser
                </Button>
              )} */}

            {/* <Box sx={{ display: { xs: 'flex' } }}> */}
            {/* {!tablet&&<IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                edge="end"
                >
                 <AccountCircleIcon
                  fontSize="large"
                  sx={{ color: "primary.main" }}
                  />
              </IconButton>}
                {tablet && (
                  <MenuItem
                    onClick={handleConcepts}
                    style={{ marginRight: -15 }}
                  >
                    <ListItemIcon>
                      <MyLocationIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>Concepts</ListItemText>
                  </MenuItem>
                )}
              </Box> */}
          </Toolbar>
        </AppBar>
        {renderMenu}
        <Drawer
          sx={{ '.MuiPaper-root': { backgroundColor: 'secondary.dark' } }}
          variant="permanent"
          open={screen ? false : desktopDrawerOpen}
        >
          <Toolbar sx={{ mixBlendMode: 'lighten' }}>
            {/* <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "18px",
                  // ...(desktopDrawerOpen && !tablet && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton> */}
            {!tablet && <Logo />}{' '}
          </Toolbar>

          <NavMenu
            open={desktopDrawerOpen}
            closeDrawer={() => setState({ ...state, desktopDrawerOpen: true })}
          />
          {!tablet && (
            <>
              <div style={{ marginBottom: '2vh' }}>
                <Divider />
                {!userPermissions.kitchen && !userPermissions.waiter && (
                  <MenuItem onClick={handleAdminProfile}>
                    <ListItemIcon>
                      <ManageAccountsIcon
                        fontSize="small"
                        sx={{ color: 'primary.main' }}
                      />
                    </ListItemIcon>
                    {desktopDrawerOpen && <ListItemText>Profile</ListItemText>}
                  </MenuItem>
                )}
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon
                      fontSize="small"
                      sx={{ color: 'primary.main' }}
                    />
                  </ListItemIcon>
                  {desktopDrawerOpen && <ListItemText>Logout</ListItemText>}
                </MenuItem>

                {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: desktopDrawerOpen ? "flex-end" : "center",
                }}
              >
                <Toolbar
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon sx={{ color: "primary.main" }} />
                  </IconButton>
                </Toolbar>
              </Box> */}
              </div>
            </>
          )}
        </Drawer>

        {screen && (
          <>
            <Backdrop
              sx={{
                zIndex: (theme) => theme.zIndex.drawer - 1,
                alignItems: 'flex-start',
                backgroundColor: 'rgba(225,225,225,0.5)',
              }}
              open={mobileDrawerOpen}
              onClick={toggleDrawer}
            />
            <MuiDrawer
              variant="persistent"
              open={mobileDrawerOpen}
              sx={{ '.MuiDrawer-paper': { width: 240 } }}
            >
              <Toolbar />
              <NavMenu
                open={mobileDrawerOpen}
                closeDrawer={() =>
                  setState({ ...state, mobileDrawerOpen: false })
                }
              />
              <Divider />
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                >
                <Toolbar
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon sx={{ color: "primary.main" }} />
                  </IconButton>
                  </Toolbar>
              </Box> */}
              {!userPermissions.kitchen && !userPermissions.waiter && (
                <MenuItem onClick={handleAdminProfile}>
                  <ListItemIcon>
                    <ManageAccountsIcon
                      fontSize="small"
                      sx={{ color: 'primary.main' }}
                    />
                  </ListItemIcon>
                  <ListItemText>Profile</ListItemText>
                </MenuItem>
              )}

              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" sx={{ color: 'primary.main' }} />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </MuiDrawer>
          </>
        )}

        <Box
          sx={{
            // backgroundColor: (theme) =>
            //   theme.palette.mode === "light"
            //     ? theme.palette.grey[100]
            //     : theme.palette.grey[900],
            flexGrow: 1,
            // height: "100vh", // Etihad
            overflow: 'auto', // Etihad
          }}
        >
          <Toolbar />
          {/* <Box component="main" sx={{ p: { xs: '5px 16px', md: '24px' } }}> */}
          <Box component="main">
            {!online && state.alertOpen && (
              <Collapse in={state.alertOpen}>
                <Alert
                  sx={{ mb: 2, transition: 'all .6s ease-in-out' }}
                  severity="warning"
                  onClose={() => setState({ ...state, alertOpen: false })}
                >
                  <AlertTitle>Offline Mode</AlertTitle>
                  You're currently <strong> Offline! </strong> — Please, don't
                  create bookings until you're online.
                </Alert>
              </Collapse>
            )}
            {online && since && state.alertOpen && (
              <Collapse in={state.alertOpen}>
                <Alert
                  sx={{ mb: 2, transition: 'all .6s ease-in-out' }}
                  icon={<CheckCircleOutlineIcon fontSize="small" />}
                  severity="info"
                  onClose={() => setState({ ...state, alertOpen: false })}
                >
                  <AlertTitle>Back Online</AlertTitle>
                  You're currently <strong> Online! </strong> — Syncing
                  Started...
                </Alert>
              </Collapse>
            )}
            <Routes>
              <Route path="/" element={<Navigate to="overview" />} />
              <Route
                path=":slug/*"
                element={
                  <Suspense fallback={<ContentLoader />}>
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  </Suspense>
                }
              />
            </Routes>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: state.conceptsOpen ? 0 : -300,
          opacity: state.conceptsOpen ? 1 : -0,
          left: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: '100%',
          p: 2,
          backgroundColor: 'common.white',
          boxShadow: 'shadows.2',
          transition: 'all .2s ease-in-out',
        }}
      >
        <MultiConcepts handleClose={handleConcepts} />
      </Box>
      {updating && <ActionLoader position="fixed" />}
    </>
  );
};

export default memo(DashboardContainer);
