import { UserConcepts } from '../../models/GQL_API';

// actions
const SET = 'userConcepts/SET';
const SET_SELECTED = 'userConcepts/SET_SELECTED';
const SET_NEXT_TOKEN = 'userConcepts/SET_NEXT_TOKEN';

const DEFAULT_STATE = {
  listing: [],
  searchText: '',
  selected: null,

  nextToken: null,
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, {
        conceptsSelected: action.concept,
      });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (single: UserConcepts | null) => ({
  single,
  type: SET_SELECTED,
});
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
