import { getComment, listComments } from '../graphql/queries';
import { onCreateComment } from '../graphql/subscriptions';
import {
  updateComment,
  createComment,
  deleteComment,
} from '../graphql/mutations';
import { generateClient } from '@aws-amplify/api';
import {
  CommentBulkTrashVariables,
  CommentGetVariables,
  CommentListingVariables,
  CommentUpdateVariables,
  CreateVariables,
} from './../models/app';
import { useDispatch, useSelector } from 'react-redux';
import { setListing, setSelected } from '../store/ducks/comment';
import { HeadCell } from '../models/dataTable';
import useApp from './useApp';
import {
  CreateCommentInput,
  UpdateCommentInput,
  Comment,
} from '../models/GQL_API';

const client = generateClient();

const useComment = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetchAll(params: CommentListingVariables) {
    const { guestID, bookingID } = params;
    let filter: any = { deleted: { eq: '0' } };

    if (bookingID) {
      filter.bookingId = { eq: bookingID };
    }

    if (guestID) {
      filter.customerId = { eq: guestID };
    }

    const limit: any = 5000;
    let nextToken: any = null;
    const allComments: any = [];

    try {
      do {
        const listing: any = await client.graphql<Comment>({
          query: listComments,
          variables: { filter, nextToken, limit: 100000 },
          authMode: 'userPool',
        });
        allComments.push(...listing.data.listComments.items);
        nextToken = listing.data.listComments.nextToken;
      } while (nextToken);

      return allComments;
    } catch (err) {
      throw err;
    }
  }

  async function fetch(params: CommentListingVariables) {
    const { searchText, limit, guestID, bookingID } = params;
    let nextToken: any = null;
    try {
      const filter: any = {
        deleted: { eq: '0' },
      };
      if (searchText.length > 0) {
        filter.message = { contains: searchText.toLowerCase() };
      }
      if (bookingID) {
        filter.bookingId = { eq: bookingID };
      }

      if (guestID) {
        filter.customerId = { eq: guestID };
      }

      const commentList: any = await client.graphql<Comment>({
        query: listComments,
        variables: { filter, limit: 100000, nextToken },
        authMode: 'userPool',
      });
      const currentNextToken = commentList.data.listGuestGroups.nextToken;
      const listing = commentList.data.listGuestGroups.items;
      dispatch(setListing(listing));

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  async function get(params: CommentGetVariables) {
    const { id, listing } = params;
    try {
      let single: Comment | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await client.graphql<Comment>({
          query: getComment,
          variables: { id },
          authMode: 'userPool',
        });
        single = listing.data.getComment;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateCommentInput = {
        message: data.message,
        customerId: data.customerId ? data.customerId : '',
        bookingId: data.bookingId ? data.bookingId : '',
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByImg: data.createdByImg,
        createdByName: userName,
      };

      const res = await client.graphql({
        query: createComment,
        variables: { input: createInput },
        authMode: 'userPool',
      });
      showConfirm(`New ${singleName} has been created successfully`);
      return res.data.createComment;
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: CommentUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original: any = await get({ id, listing });
      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }
      const updateInput: UpdateCommentInput = {
        id: original.id,
        message: data.message ? data.message : original!.message,
        customerId: data.customerId ? data.customerId : original!.customerId,
        _version: original._version,
      };
      await client.graphql<Comment>({
        query: updateComment,
        variables: { input: updateInput },
        authMode: 'userPool',
      });
      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: CommentGetVariables) {
    try {
      const original = await get(params);

      if (original) {
        const updateInput: UpdateCommentInput = {
          id: original.id,
          deleted: '1',
          _version: original._version,
        };

        await client.graphql<Comment>({
          query: updateComment,
          variables: { input: updateInput },
          authMode: 'userPool',
        });
      }
      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: CommentBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: CommentGetVariables) {
    const { id, listing } = params;
    try {
      await client.graphql<Comment>({
        query: deleteComment,
        variables: { id: id },
        authMode: 'userPool',
      });
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'message',
      numeric: false,
      disablePadding: false,
      label: 'Message',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];
  const dataCells: readonly string[] = ['message'];

  const api: any = {};
  api[`${listingName}CreateSubscription`] = onCreateComment;
  api[`${listingName}Options`] = [];
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Comment[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));

  return api;
};

export default useComment;
