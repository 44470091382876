import React from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { FetchDashboardStatisticsResult, RecentGuest } from '../../models/app';
import { cloudinaryURL } from '../../constants/enums';

interface TodayStatisticsProps {
  dashboardStatistics: FetchDashboardStatisticsResult;
  loading: boolean;
}

export default function TodayStatistics({
  dashboardStatistics,
  loading,
}: TodayStatisticsProps) {
  const getPercentageStatus = (percentage: number) => {
    if (percentage > 0) {
      return 'increase';
    } else if (percentage < 0) {
      return 'decrease';
    } else {
      return 'no change';
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      lg={12}
      sx={{ my: { xs: 1, sm: 3 }, mx: { xs: 1, sm: 3 } }}
    >
      <Box
        sx={{
          backgroundColor: 'secondary.dark',
          border: '1px solid',
          borderColor: 'secondary.contrastText',
          borderRadius: '5px',
          p: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { xs: 'center', sm: 'start' },
          flexWrap: 'wrap',
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Recent Guests
          </Typography>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                flexWrap: 'wrap',
              }}
            >
              {dashboardStatistics.recentGuests.map(
                (recentGuest: RecentGuest) => {
                  return (
                    <Box
                      sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textTransform: 'capitalize',
                      }}
                    >
                      <Avatar
                        alt={recentGuest.name.toUpperCase()}
                        // src={
                        //   recentGuest.image
                        //     ? cloudinaryURL + recentGuest.image
                        //     : `/static/images/avatar/1.jpg`
                        // }
                        src={
                          recentGuest.image?.includes('platform')
                            ? `${recentGuest.image}`
                            : `${cloudinaryURL}${recentGuest.image}`
                        }
                      />
                      <Typography sx={{ fontSize: '14px' }}>
                        {recentGuest.name}
                      </Typography>
                      <Typography sx={{ fontSize: '14px' }}>
                        {recentGuest.totalChargedToday} EGP
                      </Typography>
                    </Box>
                  );
                },
              )}
              {!dashboardStatistics.recentGuests.length && (
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  width={'100%'}
                  height="100%"
                  mt={4}
                >
                  <Typography>No Data</Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: 'info.300' }}
        />

        <Box
          sx={{
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Today's Performance
          </Typography>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '25px',
                }}
              >
                {dashboardStatistics.todayPerformance} EGP
              </Typography>
              <Typography
                sx={{
                  color:
                    dashboardStatistics.todayPerformancePercentageWithYesterday >
                    0
                      ? 'green'
                      : 'red',
                  fontSize: '15px',
                }}
              >
                {dashboardStatistics.todayPerformancePercentageWithYesterday
                  ? `${dashboardStatistics.todayPerformancePercentageWithYesterday.toFixed(
                      2,
                    )}%`
                  : ''}{' '}
                {getPercentageStatus(
                  dashboardStatistics.todayPerformancePercentageWithYesterday,
                )}
              </Typography>
            </Box>
          )}
        </Box>

        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: 'info.300' }}
        />

        <Box
          sx={{
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            Account Summary
          </Typography>

          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                mt: 2,
                alignItems: 'center',
                gap: 2,
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#B2B2B2',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'column',
                  p: 1,
                  width: '8rem',
                  height: '5.5rem',
                }}
              >
                <Typography sx={{ color: 'secondary.main', fontWeight: 600 }}>
                  Transactions
                </Typography>
                <Typography sx={{ color: 'secondary.main', fontWeight: 600 }}>
                  {dashboardStatistics.totalTransactions}
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: '#1CD75C',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  flexDirection: 'column',
                  p: 1,
                  width: '8rem',
                  height: '5.5rem',
                  pl: '1.25rem',
                }}
              >
                <Typography sx={{ color: 'secondary.main', fontWeight: 600 }}>
                  Successful
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: 'secondary.main',
                      fontWeight: 600,
                      fontSize: 11,
                    }}
                  >
                    Cash:
                  </Typography>
                  <Typography
                    sx={{
                      color: 'secondary.main',
                      fontWeight: 600,
                      fontSize: 11,
                    }}
                  >
                    {dashboardStatistics.successfullCash}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: 'secondary.main',
                      fontWeight: 600,
                      fontSize: 11,
                    }}
                  >
                    Visa:
                  </Typography>
                  <Typography
                    sx={{
                      color: 'secondary.main',
                      fontWeight: 600,
                      fontSize: 11,
                    }}
                  >
                    {dashboardStatistics.successfullVisa}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: 'secondary.main',
                      fontWeight: 600,
                      fontSize: 11,
                    }}
                  >
                    Complementary:
                  </Typography>
                  <Typography
                    sx={{
                      color: 'secondary.main',
                      fontWeight: 600,
                      fontSize: 11,
                    }}
                  >
                    {dashboardStatistics.successfullComplementary}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: '#F11517',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'column',
                  p: 1,
                  width: '8rem',
                  height: '5.5rem',
                }}
              >
                <Typography sx={{ color: 'secondary.main', fontWeight: 600 }}>
                  Void
                </Typography>
                <Typography sx={{ color: 'secondary.main', fontWeight: 600 }}>
                  {dashboardStatistics.voidedTransactions}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
}
