export async function ping(ip: string, port: string) {
  const timeout = new Promise((_, reject) =>
    setTimeout(() => reject(new Error('Request timed out')), 2000),
  );

  const fetchRequest = fetch('http://' + ip + ':' + port + '/api/v1/test').then(
    (response) => response.json(),
  );

  try {
    const responseData = await Promise.race([fetchRequest, timeout]);
    return responseData;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
