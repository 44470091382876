import { useDispatch, useSelector } from 'react-redux';
import { generateClient } from '@aws-amplify/api';
import {
  setListing,
  setSelected,
  setNextToken,
} from '../store/ducks/adminGroups';
import { AdminGroup } from '../models/GQL_API';
import {
  CreateAdminGroupInput,
  UpdateAdminGroupInput,
} from '../models/GQL_API';
import {
  clearLocalStorageData,
  extractSelectedCheckboxes,
  sessionTimeout,
} from '../helpers/utils';
import {
  AdminGroupBulkTrashVariables,
  AdminGroupGetVariables,
  AdminGroupUpdateVariables,
  CreateVariables,
  Option,
  HeadCell,
  AdminGroupListingVariables,
} from '../models/app';
import useApp from './useApp';
import {
  createAdminGroup,
  deleteAdminGroup,
  updateAdminGroup,
} from '../graphql/mutations';
import {
  adminGroupsByDeleted,
  getAdminGroup,
  listAdminGroups,
} from '../graphql/queries';
import { onCreateAdminGroup } from '../graphql/subscriptions';
import { AppTypes, appType } from '../constants/enums';

const client = generateClient();

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();

  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]['nextToken'],
  );

  const adminGroupListing = useSelector(
    (state: any) => state[`${listingName}`]['listing'],
  );

  async function fetch(params: AdminGroupListingVariables) {
    const { searchText, limit, adminID } = params;

    try {
      const filter: any = {
        name: { contains: searchText.toLowerCase() },
      };

      if (adminID) {
        filter.users = { contains: adminID };
      }

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      const groupsList: any = await client.graphql<any>({
        query: adminGroupsByDeleted,
        // variables: { filter, limit: 2, nextToken: nextToken, deleted: '0' },
        variables: {
          filter,
          limit: 100000,
          nextToken: nextToken,
          deleted: '0',
        },
        authMode: 'userPool',
      });
      const currentNextToken = groupsList.data.adminGroupsByDeleted.nextToken;
      const responseListing = groupsList.data.adminGroupsByDeleted.items;

      let listing = [...adminGroupListing, ...responseListing];
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }
  async function fetchAll(params: AdminGroupListingVariables) {
    const { searchText, limit, adminID } = params;

    try {
      const filter: any = {
        name: { contains: searchText.toLowerCase() },
      };

      if (adminID) {
        filter.users = { contains: adminID };
      }

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      const groupsList: any = await client.graphql<any>({
        query: adminGroupsByDeleted,
        // variables: { filter, limit: 2, nextToken: nextToken, deleted: '0' },
        variables: {
          filter,
          limit: 100000,
          nextToken: nextToken,
          deleted: '0',
        },
        authMode: 'userPool',
      });
      const currentNextToken = groupsList.data.adminGroupsByDeleted.nextToken;
      const responseListing = groupsList.data.adminGroupsByDeleted.items;

      let listing = [...responseListing];
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchCurrentUser(session: string) {
    try {
      const offlineAdminGroups = localStorage.getItem('offlineAdminGroups');
      if (
        offlineAdminGroups &&
        !sessionTimeout() &&
        // @ts-ignore
        appType === AppTypes.CASHLESS
      ) {
        let adminGroups = JSON.parse(offlineAdminGroups);
        if (adminGroups) {
          return adminGroups;
        }
      } else {
        clearLocalStorageData();
        const filter: any = {
          deleted: { eq: '0' },
          users: { contains: session },
        };

        const groupsList: any = await client.graphql<AdminGroup>({
          query: listAdminGroups,
          variables: { filter, nextToken: nextToken },
          authMode: session ? 'userPool' : 'iam',
        });

        const currentNextToken = groupsList.data.listAdminGroups.nextToken;
        const listing = groupsList.data.listAdminGroups.items;
        localStorage.setItem(
          'offlineAdminGroups',
          JSON.stringify(groupsList.data.listAdminGroups.items),
        );

        dispatch(setNextToken(currentNextToken));
        return listing;
      }
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchUsersInGroup(params: any) {
    try {
      const { groupName } = params;

      const filter: any = {
        deleted: { eq: '0' },
      };

      if (groupName) {
        filter.name = { contains: groupName };
      }

      const groupsList: any = await client.graphql<AdminGroup>({
        query: listAdminGroups,
        variables: { filter, nextToken: nextToken },
        authMode: 'userPool',
      });

      const currentNextToken = groupsList.data.listAdminGroups.nextToken;
      const listing = groupsList.data.listAdminGroups.items;

      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: AdminGroupGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: AdminGroup) => model.id === id);

      return model ? model.name : '';
    }

    return '';
  };

  async function get(params: AdminGroupGetVariables) {
    try {
      const { id, listing } = params;

      let single: AdminGroup | undefined;
      if (listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await client.graphql<AdminGroup>({
          query: getAdminGroup,
          variables: { id },
          authMode: 'userPool',
        });
        single = listing.data.getAdminGroup;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    const roles: string[] = extractSelectedCheckboxes('roles_', data);
    const users: string[] = extractSelectedCheckboxes('users_', data);

    try {
      const createInput: CreateAdminGroupInput = {
        name: data.name.toLowerCase(),
        roles: roles,
        users: users,
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.description) createInput.description = data.description;

      await client.graphql<AdminGroup>({
        query: createAdminGroup,
        variables: { input: createInput },
        authMode: 'userPool',
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      throw err;
    }
  }

  async function update(params: AdminGroupUpdateVariables) {
    const { id, listing, data } = params;

    const roles: string[] = extractSelectedCheckboxes('roles_', data);
    const users: string[] = extractSelectedCheckboxes('users_', data);

    try {
      const original: any = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateAdminGroupInput = {
        id: original.id,
        name: data.name ? data.name.toLowerCase() : original!.name,
        description: data.description
          ? data.description
          : original!.description,
        roles: roles,
        users: users,
        _version: original._version,
      };

      await client.graphql<AdminGroup>({
        query: updateAdminGroup,
        variables: { input: updateInput },
        authMode: 'userPool',
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: AdminGroupGetVariables) {
    try {
      const original: any = await get(params);
      const { id, listing } = params;

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updateInput: UpdateAdminGroupInput = {
        id: original.id,
        deleted: '1',
        _version: original._version,
      };

      await client.graphql<AdminGroup>({
        query: updateAdminGroup,
        variables: { input: updateInput },
        authMode: 'userPool',
      });
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: AdminGroupBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: AdminGroupGetVariables) {
    try {
      const { id, listing } = params;

      await client.graphql<AdminGroup>({
        query: deleteAdminGroup,
        variables: { id: id },
        authMode: 'userPool',
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      console.log(err);
      showError(err);
    }
  }

  async function exportAll(params: AdminGroupListingVariables) {
    try {
      const data: any[] = await fetch(params);
      let exportedData: any[] = [];

      for (let group of data) {
        let row: any = { ...group };

        exportedData.push(row);
      }

      return exportedData;
    } catch (err) {
      showError(err);
    }
  }

  function options(listing: AdminGroup[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name!, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  const dataCells: readonly string[] = ['name', 'description'];

  const api: any = {};

  api[`${listingName}CreateSubscription`] = onCreateAdminGroup;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchCurrentUser`] = fetchCurrentUser;
  api[`${listingName}FetchUsersInGroup`] = fetchUsersInGroup;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;

  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: AdminGroup[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = adminGroupListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useResource;
