import { listConcepts } from './../graphql/queries';
import { useDispatch } from 'react-redux';
import {
  setBranches,
  setListing,
  setSelected,
  setFilters,
  setSelectedFilters,
  setNextToken,
} from '../store/ducks/concept';
import { Concept } from '../models/GQL_API';
import { HeadCell } from '../models/dataTable';
import useApp from './useApp';
import { CreateConceptInput, UpdateConceptInput } from '../models/GQL_API';
import {
  ConceptBulkTrashVariables,
  CreateVariables,
  ConceptGetVariables,
  ConceptListingVariables,
  Option,
} from '../models/app';
import { getConcept } from '../graphql/queries';
import { AppTypes, LOCAL_STORAGE, appType } from '../constants/enums';
import {
  createConcept,
  deleteConcept,
  updateConcept,
} from '../graphql/mutations';
import { onCreateConcept } from '../graphql/subscriptions';

import { generateClient } from '@aws-amplify/api';
import { clearLocalStorageData, sessionTimeout } from '../helpers/utils';

const client = generateClient();

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: ConceptListingVariables, isAuth = true) {
    const { accountID, limit } = params;

    try {
      const offlineUserConcept = localStorage.getItem('offlineUserConcept');

      if (
        offlineUserConcept &&
        !sessionTimeout() &&
        // @ts-ignore
        appType === AppTypes.CASHLESS
      ) {
        let userConcept = JSON.parse(offlineUserConcept);
        if (userConcept) {
          dispatch(setSelected(userConcept));
          return userConcept;
        }
      } else {
        clearLocalStorageData();
        const filter: any = {
          deleted: { eq: '0' },
          accountID: { eq: accountID },
        };

        const conceptListing: any = await client.graphql({
          query: listConcepts,
          variables: {
            filter,
            limit: 100000,
          },
          authMode: isAuth ? 'userPool' : 'iam',
        });

        const currentNextToken = conceptListing.data.listConcepts.nextToken;
        const listing: any = conceptListing.data.listConcepts.items;

        localStorage.setItem(
          'offlineUserConcept',
          JSON.stringify(conceptListing.data.listConcepts.items),
        );

        dispatch(setNextToken(currentNextToken));

        return listing;
      }
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAllOnline() {
    const filter: any = {
      deleted: { eq: '0' },
    };

    try {
      const listing: any = await client.graphql({
        query: listConcepts,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });

      return listing.data.listConcepts.items;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: ConceptGetVariables) {
    const { id, listing } = params;

    try {
      let single: Concept | undefined;
      if (listing.length > 0) {
        single = listing.find((resource: any) => resource.id === id);
      } else {
        const listing: any = await client.graphql<Concept>({
          query: getConcept,
          variables: { id },
          authMode: true ? 'userPool' : 'iam',
        });
        single = listing.data.getConcept;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function getOnline(id: string, isAuth: boolean) {
    try {
      const user: any = await client.graphql({
        query: getConcept,
        variables: { id: id },
        authMode: isAuth ? 'userPool' : 'iam',
      });

      return user.data.getConcept;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const createInput: CreateConceptInput = {
        accountID: data.accountID,
        name: data.name,
        description: data.description ? data.description : '',
        location: data.location ? data.location : '',
        type: data.type ? data.type : '',
        logo: data.logo ? data.logo : '',
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      await client.graphql<Concept>({
        query: createConcept,
        variables: { input: createInput },
        authMode: 'userPool',
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: any) {
    const { id, listing, data, newReceiveWaiter, delteWaiterID } = params;

    try {
      const original: any = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      if (!newReceiveWaiter && !delteWaiterID) {
        const updateInput: UpdateConceptInput = {
          id: original.id,
          name: data.name ? data.name : original!.name,
          precedence: parseInt(data.precedence),
          _version: original._version,
        };

        await client.graphql<Concept>({
          query: updateConcept,
          variables: { input: updateInput },
          authMode: 'userPool',
        });
      } else {
        let newReceiveWaiters: any = [];
        if (original!.receiveWaiters) {
          newReceiveWaiters = [...original!.receiveWaiters];
        }
        if (newReceiveWaiters && newReceiveWaiter) {
          newReceiveWaiters.push(newReceiveWaiter);
        }
        if (newReceiveWaiters && delteWaiterID) {
          newReceiveWaiters = newReceiveWaiters.filter((stringWaiter: any) => {
            let objectWaiter = JSON.parse(stringWaiter);
            return objectWaiter.id !== delteWaiterID;
          });
        }

        const updateInput: UpdateConceptInput = {
          id: original.id,
          name: original!.name,
          precedence: original!.precedence,
        };

        await client.graphql<Concept>({
          query: updateConcept,
          variables: { input: updateInput },
          authMode: 'userPool',
        });
      }

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: ConceptGetVariables) {
    try {
      const { id, listing } = params;

      const original: any = await get(params);

      if (original) {
        const updateInput: UpdateConceptInput = {
          id: original.id,
          deleted: '1',
          _version: original._version,
        };

        await client.graphql<Concept>({
          query: updateConcept,
          variables: { input: updateInput },
          authMode: 'userPool',
        });

        dispatch(
          setListing(listing.filter((resource: any) => resource.id !== id)),
        );
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ConceptBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: ConceptGetVariables) {
    const { id, listing } = params;
    try {
      await client.graphql<Concept>({
        query: deleteConcept,
        variables: { id: id },
        authMode: 'userPool',
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id)),
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function exportAll(params: ConceptListingVariables) {
    const data = await fetch(params);

    // console.log({ data });
    return data;
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: ConceptGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Concept) => model.id === id);

      return model ? model.name : '';
    }

    return '';
  };

  function options(listing: Concept[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'precedence',
      numeric: false,
      disablePadding: false,
      label: 'Precedence',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },

    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  const dataCells: readonly string[] = ['name', 'precedence'];

  const api: any = {};

  api[`${listingName}CreateSubscription`] = onCreateConcept;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchOnline`] = fetchAllOnline;
  api[`${listingName}Get`] = get;
  api[`${listingName}GetOnline`] = getOnline;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: Concept[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };

  api[`${listingName}ChangeSelected`] = (conceptID: string) => {
    dispatch(setSelected(conceptID));

    localStorage.setItem(LOCAL_STORAGE.SELECTED_CONCEPT, conceptID);
  };
  api[`${listingName}SetBranches`] = (branches: any) =>
    dispatch(setBranches(branches));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };

  return api;
};

export default useResource;
