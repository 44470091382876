import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { primaryMain } from '../../constants/colors';
import { Concept } from '../../models/GQL_API';

interface Props {
  concept: Concept;
  isSelected: boolean;
  onClick: any;
}

const Location: React.FC<Props> = ({ concept, isSelected, onClick }) => {
  return (
    <Box
      sx={{
        display: 'inline-block',
        border: '1px solid',
        borderColor: 'primary.main',
        borderLeft: { md: 'none' },
        backgroundColor: isSelected ? 'primary.main' : 'transparent',
        color: isSelected ? 'common.white' : 'text.primary',
        p: { xs: '4px 8px', md: '8px 16px' },
        cursor: 'pointer',
        width: 'fit-content',
        '&:first-of-type': {
          borderLeft: (theme) => `1px solid ${primaryMain(theme)}`,
        },
      }}
      onClick={(event: any) => onClick(event, concept.id)}
    >
      <Typography align="center">{concept.name}</Typography>
    </Box>
  );
};

export default Location;
