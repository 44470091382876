import { Suspense, useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MainModal from './components/UI/MainModal';
import MainSnackbar from './components/UI/MainSnackbar';
import useApp from './hooks/useApp';
import useLoading from './hooks/useLoading';
import useAccount from './hooks/useAccount';
import useFeature from './hooks/useFeature';
import useConcept from './hooks/useConcept';
import useAdminGroup from './hooks/useAdminGroup';
import {
  AdminPermissions,
  ConceptListingVariables,
  IBookingsSyncDuration,
  ListingVariables,
  UserConceptsListingVariables,
} from './models/app';
import { Account, Concept, Feature, UserConcepts } from './models/GQL_API';
// import { DataStore } from "aws-amplify";
import usePermissions from './hooks/usePermissions';
import useUserConcepts from './hooks/useUserConcepts';
import { AppTypes, LOCAL_STORAGE, appType } from './constants/enums';
import {
  getBookingDateRange,
  setBookingDateRange,
} from './helpers/localStorage';
import {
  clearLocalStorageData,
  formatDate,
  sessionTimeout,
} from './helpers/utils';
import MainLoader from './components/UI/MainLoader/MainLoader';
import ErrorBoundary from './components/UI/ErrorBoundary/ErrorBoundary';
import PublicRoute from './containers/PublicRoute/PublicRoute';
import AuthContainer from './containers/Auth/AuthContainer';
import DashboardContainer from './containers/Dashboard/DashboardContainer';
import AlertsWrapper from './components/UI/AlertsWrapper';
import { Box, Modal } from '@mui/material';
import OfflineIpModal from './components/CashlessPage/OfflineIpModal';
import { checkInternet } from './helpers/checkInternet';
import { useSelector } from 'react-redux';
import useAuth from './hooks/useAuth';

const App: React.FC = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { loading, changeLoading } = useLoading();
  const {
    fetchSession,
    setSession,
    clearSession,
    setPermissions,
    setConcepts,
  } = useApp();
  const { checkLastLoginTime, checkDeploymentVersion } = useAuth();
  const { getAdminPermissions } = usePermissions();
  const { userConceptsFetch } = useUserConcepts('userConcepts', 'userConcept');
  const { accountsFetchByDomainOnline } = useAccount('accounts', 'account');
  const { featuresFetchAll, featuresChangeListingAll } = useFeature(
    'features',
    'feature',
  );
  const { adminGroupsFetchCurrentUser } = useAdminGroup(
    'adminGroups',
    'adminGroup',
  );
  const { conceptsChangeSelected, conceptsChangeListing, conceptsFetch } =
    useConcept('concepts', 'concepts');
  const modalOpen = useSelector((state: any) => state.server.modalOpen);
  const [isClearCache, setIsClearCache] = useState(false);

  const initApp = useCallback(async (session: any) => {
    const filteredConcepts: Concept[] = [];

    const params: ListingVariables = {
      searchText: '',
      startIndex: 0,
      limit: 1000,
    };

    const userConceptParams: UserConceptsListingVariables = {
      ...params,
      userID: session?.sub,
    };

    try {
      const data = await Promise.all([
        accountsFetchByDomainOnline(true),
        featuresFetchAll(params),
        userConceptsFetch(userConceptParams, !!session),
      ]);

      const accountsSelected: Account = data[0];
      const featuresListingAll: Feature[] = data[1];
      const userConcepts: UserConcepts = data[2][0];

      if (featuresListingAll.length > 0)
        featuresChangeListingAll(featuresListingAll);

      const conceptsParams: ConceptListingVariables = {
        ...params,
        accountID: accountsSelected.id,
      };

      const resultData: any[] = await Promise.all([
        conceptsFetch(conceptsParams, !!session),
        adminGroupsFetchCurrentUser(session.sub),
      ]);

      const allConcepts: Concept[] = resultData[0];
      const userGroup = resultData[1];

      const permissions: AdminPermissions = await getAdminPermissions({
        userGroup,
      });

      setPermissions(permissions);

      if (userConcepts) {
        for (let concept of allConcepts) {
          if (userConcepts.concepts!.includes(concept.id)) {
            filteredConcepts.push(concept);
          }
        }

        setConcepts(userConcepts);
      }

      conceptsChangeListing(allConcepts);

      const storageConcept: string | null = localStorage.getItem(
        LOCAL_STORAGE.SELECTED_CONCEPT,
      );

      if (storageConcept) {
        conceptsChangeSelected(storageConcept);
      } else {
        if (filteredConcepts.length > 0) {
          for (var j = 0; j < filteredConcepts.length; j++) {
            if (
              userConcepts &&
              userConcepts.concepts &&
              userConcepts.concepts.includes(filteredConcepts[j].id)
            ) {
              conceptsChangeSelected(filteredConcepts[j].id);
              break;
            }
          }
        }
      }

      const bookingsSyncDuration: IBookingsSyncDuration =
        getBookingDateRange(userConcepts);
      const { fromDate, toDate } = bookingsSyncDuration;
      setBookingDateRange(fromDate, toDate);

      localStorage.setItem(LOCAL_STORAGE.BOOKING_REFRESH, 'false');
      localStorage.setItem(LOCAL_STORAGE.BOOKING_GUEST, 'false');
      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE_RANGE, 'false');
      localStorage.setItem(LOCAL_STORAGE.GUESTS_SEARCH, 'false');
      localStorage.setItem(LOCAL_STORAGE.SYNC_GUEST_STATS, 'false');
      localStorage.setItem(LOCAL_STORAGE.BOOKING_DATE, formatDate(new Date()));

      setSession(session);
      changeLoading(false);
    } catch (err: Error | any) {
      clearSession();
      changeLoading(false);
    }

    // eslint-disable-next-line
  }, []);

  const getSession = async () => {
    try {
      const storedSession = localStorage.getItem('sessionStored');
      // console.log({storedSession})
      // @ts-ignore
      if (storedSession && !sessionTimeout() && appType === AppTypes.CASHLESS) {
        initApp(JSON.parse(storedSession));
      } else {
        clearLocalStorageData();
        const session = await fetchSession();
        initApp(session);
      }
    } catch (err: Error | any) {
      // sync();
      console.log(err);
      initApp(null);
    }
  };

  // const sync = async () => {
  //   await DataStore.start();

  //   initApp(null);
  // };

  useEffect(() => {
    getSession();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const versionCheckingFlag: string | null = localStorage.getItem(
      LOCAL_STORAGE.VERSION_CHECKING,
    );

    if (versionCheckingFlag !== null) {
      handleClearCache();
    }

    function handleOnline() {
      setIsOnline(true);
    }

    function handleOffline() {
      setIsOnline(false);
    }

    async function handleClearCache() {
      localStorage.removeItem(LOCAL_STORAGE.VERSION_CHECKING);
      setIsClearCache(true);
      const clearCacheStatus = await checkDeploymentVersion();
      console.log({ clearCacheStatus });
      if (!clearCacheStatus) {
        console.log('Need to check las login time');
        await checkLastLoginTime();

        console.log('check last visit');
      }
      setIsClearCache(false);
    }
    function handleRemoveClearCache() {
      console.log('Remove version checking flag');
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    window.addEventListener(LOCAL_STORAGE.VERSION_CHECKING, handleClearCache);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener(
        LOCAL_STORAGE.VERSION_CHECKING,
        handleRemoveClearCache,
      );
    };
  }, []);

  useEffect(() => {
    if (!isOnline && (slug === 'walletManagements' || slug === 'pos')) {
      setIpModal(true);
    } else {
      setIpModal(false);
    }
  }, [isOnline]);
  const [ipModal, setIpModal] = useState(false);
  const [slug, setSlug] = useState('');
  if (loading) return <MainLoader />;
  if (!isOnline && !(slug === 'walletManagements' || slug === 'pos')) {
    return (
      <>
        <Snackbar
          open={!isOnline}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="error">You are currently offline!</Alert>
        </Snackbar>
        <MainLoader />;
      </>
    );
  }

  return (
    <ErrorBoundary>
      <DndProvider backend={HTML5Backend}>
        <Suspense fallback={<MainLoader />}>
          <Snackbar
            open={!isOnline && (slug === 'walletManagements' || slug === 'pos')}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert severity="error">You are currently offline!</Alert>
          </Snackbar>
          <OfflineIpModal
            open={modalOpen || ipModal}
            onClose={() => setIpModal(false)}
          />
          <Routes>
            <Route
              path="/*"
              element={
                <PublicRoute>
                  <AuthContainer />
                </PublicRoute>
              }
            />
            <Route path="dashboard/*" element={<DashboardContainer />} />
            {/* <Route path="templates/*" element={<EmailTemplates />} /> */}
          </Routes>
        </Suspense>
      </DndProvider>

      <AlertsWrapper />
    </ErrorBoundary>
  );
};

export default App;
