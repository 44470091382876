import { Box, TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CashlessType, PaymentStatus } from '../../../../constants/enums';
import { capitalizeSentence } from '../../../../helpers/utils';
import OrderDetails from './OrderDetails';

interface CashlessTransactionsRowsProps {
  row: any;
}

const CashlessTransactionsRows: React.FC<CashlessTransactionsRowsProps> = ({
  row,
}) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const accountsSelected = useSelector((state: any) => state.accounts.selected);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    return date.toLocaleString('en-US', options);
  };

  return (
    <>
      <TableRow
        onClick={() => setShowPopUp(true)}
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        style={{ cursor: 'pointer' }}
      >
        <TableCell sx={{ p: '8px 16px', minWidth: 80 }} align="left">
          {capitalizeSentence(row?.guest?.name ?? 'N/A')}
        </TableCell>
        {/* <TableCell sx={{ p: '8px 16px', minWidth: 80 }} align="left">
          {row?.guest?.phone_number ?? "N/A"}
        </TableCell> */}
        <TableCell
          sx={{ p: '8px 16px', minWidth: 80, textTransform: 'capitalize' }}
          align="left"
        >
          {row.guestPhoneNumber || (row.guest?.phone_number ?? 'N/A')}
        </TableCell>
        <TableCell
          sx={{ p: '8px 16px', minWidth: 80 }}
          align="left"
        >
          {row.guestEmail || (row.guest?.email ?? 'N/A')}
        </TableCell>
        {/* <TableCell sx={{ p: '8px 16px', minWidth: 80 }} align="left">
          {row.pos}
        </TableCell>
        <TableCell sx={{ p: '8px 16px', minWidth: 80 }} align="left">
          {row.checkNumber}
        </TableCell> */}
        <TableCell
          sx={{ p: '8px 16px', minWidth: 160, textTransform: 'capitalize' }}
          align="left"
        >
          {row.type ?? CashlessType.BOOKING}
        </TableCell>
        <TableCell
          sx={{ p: '8px 16px', minWidth: 160, textTransform: 'capitalize' }}
          align="left"
        >
          {row.event.name}
        </TableCell>
        <TableCell sx={{ p: '8px 16px', minWidth: 80 }} align="left">
          {row.orderTotal} {accountsSelected.currency}
        </TableCell>
        <TableCell sx={{ p: '8px 16px', minWidth: 80 }} align="left">
          {row.paidAmount.toFixed(2)} {accountsSelected.currency}
        </TableCell>
        {/* <TableCell sx={{ p: '8px 16px', minWidth: 160 }} align="left">
          <Box
            sx={{
              backgroundColor:
                row.paymentStatus === PaymentStatus.FULL ? '#1dd75b' : 'yellow',
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'black',
              textTransform: 'capitalize',
            }}
          >
            {row.paymentStatus}
          </Box>
        </TableCell> */}
        {/* <TableCell sx={{ p: '8px 16px', minWidth: 160 }} align="left">
        General Admin
      </TableCell> */}
        <TableCell sx={{ p: '8px 16px', minWidth: 160 }} align="left">
          {formatDate(row.createdAt)}
        </TableCell>
        <TableCell sx={{ p: '8px 16px', minWidth: 160 }} align="left">
          {capitalizeSentence(row.createdByName ?? '')}
        </TableCell>
      </TableRow>
      <OrderDetails
        transaction={row}
        showPopUp={showPopUp}
        setShowPopUp={setShowPopUp}
      />
    </>
  );
};

export default CashlessTransactionsRows;
