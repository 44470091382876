import { Box, Grid } from '@mui/material';
import React from 'react';
import Order from './Order';

interface Props {
  slug: string;
}
const Dispatcher: React.FC<Props> = ({ slug }) => {
  return (
    <Box>
      <Grid container display={'flex'} justifyContent={'center'}>
        <Order />
      </Grid>
    </Box>
  );
};
export default Dispatcher;
