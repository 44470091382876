import CloseIcon from '@mui/icons-material/Close';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Orders, Pages } from '../../constants/enums';
import { getComparator, stableSort } from '../../helpers/utils';
import useCashlessTopups from '../../hooks/useCashlessTopups';
import { Order } from '../../models/app';
import TopUpRows from './TopUpRows';

export default function TopUpsListing({
  onSearchChange,
  handleExport,
  isExporting,
  setUserBalance,
}: {
  onSearchChange: (value: string) => void;
  handleExport: () => void;
  isExporting: Boolean;
  setUserBalance: (newBalance: number) => void;
}) {
  type StateProps = {
    searchText: string;
    bulkActions: string;
    isDeleting: Boolean;
    order: Order;
    orderBy: keyof any;
    isCreating: Boolean;
    open: boolean;
  };

  const initialState: StateProps = {
    searchText: '',
    bulkActions: '-1',
    isDeleting: false,
    order: Orders.DSC,
    orderBy: 'createdAt',
    isCreating: false,
    open: false,
  };

  const cashlessTopupsListing = useSelector(
    (state: any) => state.cashlessTopups.listing,
  );

  const { cashlessTopupsHeadCells } = useCashlessTopups(
    'cashlessTopups',
    'cashlessTopup',
  );
  const extendedHeadCells = [
    ...cashlessTopupsHeadCells,
    {
      id: 'void',
      numeric: true,
      disablePadding: false,
      label: 'Actions',
    },
  ];
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  const [state, setState] = useState(initialState);
  const { order, orderBy } = state;

  const handleClearSearch = () => {
    setSearchValue('');
    onSearchChange('');
  };

  const handleSearchInputChange = (value: string) => {
    setSearchValue(value);
    onSearchChange(value);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cashlessTopupsListing.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const createSortHandler =
    (property: keyof any) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof any,
  ) => {
    const isAsc = orderBy === property && order === Orders.ASC;

    setState({
      ...state,
      order: isAsc ? Orders.DSC : Orders.ASC,
      orderBy: property,
    });
  };

  return (
    <>
      <Box
        display={'flex'}
        sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
        gap={2}
      >
        <Paper
          elevation={0}
          sx={{
            p: '3px 8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1,
            backgroundColor: 'secondary.main',
            borderRadius: '12px',
            // maxWidth: '50%',
            width: { xs: '15rem', sm: '25rem' },
            maxHeight: '8vh',
            ml: 2,
          }}
        >
          <SearchIcon
            fontSize="small"
            sx={{ color: 'info.600', fontSize: '25px' }}
          />
          <InputBase
            sx={{ ml: 1, flex: 1, py: 0.75 }}
            inputProps={{ 'aria-label': 'search resource' }}
            placeholder={'Search'}
            value={searchValue}
            onChange={(e) => handleSearchInputChange(e.target.value)}
          />
          <CloseIcon
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={handleClearSearch}
          />
        </Paper>

        <Box
          sx={{
            backgroundColor: 'secondary.dark',
            borderRadius: '30px',
            border: '1px solid secondary.light',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'primary.main',
            height: '2vh',
            p: 3,
            gap: 1,
            cursor: 'pointer',
            pointerEvents: isExporting ? 'none' : 'auto',
            opacity: isExporting ? '0.5' : '1',
          }}
          onClick={handleExport}
        >
          <ContentPasteGoIcon
            fontSize="small"
            sx={{
              marginRight: '5px',
              fontSize: '25px',
              textDecoration: 'none',
            }}
          ></ContentPasteGoIcon>
          Export
        </Box>
      </Box>

      <Box
        sx={{
          px: 2,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 3,
        }}
      >
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                backgroundColor: 'primary.light',
                borderRadius: '5px',
              }}
            >
              <TableRow>
                {extendedHeadCells.map((headCell: any, index: number) => {
                  return index < extendedHeadCells.length ? (
                    <TableCell
                      key={headCell.id}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ) : null;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {/* {currentItems.map((row: any) => (
                <TopUpRows key={row.id} row={row} />
              ))} */}

              {/* {stableSort(
                cashlessTopupsListing,
                getComparator(order, orderBy),
              ).map((row: any) => {
                return <TopUpRows row={row}></TopUpRows>;
              })} */}

              {stableSort(cashlessTopupsListing, getComparator(order, orderBy))
                .slice(indexOfFirstItem, indexOfLastItem)
                .map((row: any) => (
                  <TopUpRows
                    key={row.id}
                    row={row}
                    setUserBalance={setUserBalance}
                    slug={Pages.Wallet_Managements}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {cashlessTopupsListing.length > 0 && (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Typography component="span">
              Page {currentPage} of{' '}
              {Math.ceil(cashlessTopupsListing.length / itemsPerPage)}
            </Typography>

            <Box sx={{ mt: 1 }}>
              <IconButton
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                aria-label="previous-page"
                sx={{ fontSize: '15px' }}
              >
                Prev
              </IconButton>

              <IconButton
                onClick={handleNextPage}
                disabled={indexOfLastItem >= cashlessTopupsListing.length}
                aria-label="next-page"
                sx={{ fontSize: '15px' }}
              >
                Next
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
