import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import {
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material';
// import lightThemeOptions from "./theme/lightThemeOptions";
import darkThemeOptions from './theme/darkThemeOptions';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { clearBrowserDatabase } from './helpers/utils';
Amplify.configure(amplifyconfig);
/*
 * This part will run with every refresh
 * Check version, And empty cache in case there is new version deployed
 */
clearBrowserDatabase();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

// let lightTheme = createTheme(lightThemeOptions);
let darkTheme = createTheme(darkThemeOptions);

darkTheme = responsiveFontSizes(darkTheme);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            '.html-content': {
              paddingLeft: 8,
              paddingRight: 8,
            },
            '.html-content p': {
              backgroundColor: '#FFF9C4',
              padding: 8,
              margin: 0,
              marginBottom: 8,
            },
            '.reservation-comment p': {
              margin: 0,
            },
            '.MuiSnackbar-root': {
              right: '24px !important',
              left: '24px !important',
            },
            '.MuiButton-contained': {
              color: 'white',
            },
            '.MuiPaper-root-MuiSnackbarContent-root': {
              width: '100%',
            },
            '.MuiDrawer-paper': {
              borderRight: 'none !important',
            },
            '.MuiAccordionSummary-root .MuiAccordionSummary-content': {
              margin: '12px 8px !important',
            },
            '.MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded':
              {
                margin: '12px 8px !important',
              },
            '.MuiAccordionSummary-root.Mui-expanded': {
              minHeight: '0 !important',
            },
            '.MuiAccordionSummary-root.Mui-expanded .MuiTypography-root': {
              marginLeft: '0 !important',
            },
            '.tox-tinymce': {
              borderWidth: '1px !important',
              borderRadius: '0px !important',
            },
            '#editor textarea': {
              display: 'none',
            },
            '.sketch-picker ': {
              borderRadius: '0px !important',
              width: 'calc(100% - 20px) !important',
              boxShadow: 'none !important',
            },

            '::-webkit-scrollbar': {
              width: '10px',
            },
            '::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 5px grey',
              borderRadius: '10px',
            },
            '::-webkit-scrollbar-thumb': {
              background: darkTheme.palette.secondary.contrastText,
              borderRadius: '10px',
              transition: '0.2s',
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: darkTheme.palette.primary.contrastText,
            },

            '.recharts-pie-labels': {
              display: 'none',
            },
          }}
        />
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
