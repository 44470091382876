import { Invitation, ModelSortDirection } from './../models/GQL_API';
import {
  bookingByEventId,
  bookingByGuestId,
  bookingByMainBookingId,
  bookingByMainGuestId,
  getBooking,
  listBookings,
  invitationByEventId,
} from '../graphql/queries';
import { onCreateBooking } from '../graphql/subscriptions';
import {
  updateBooking,
  createBooking,
  deleteBooking,
  updateGuest,
} from '../graphql/mutations';
import { generateClient } from '@aws-amplify/api';
import {
  BookingBulkTrashVariables,
  BookingGetVariables,
  BookingListingVariables,
  BookingUpdateVariables,
  CreateVariables,
} from './../models/app';
import { useDispatch, useSelector } from 'react-redux';
import {
  setListing,
  setSelected,
  setNextToken,
  setRegisteredFilter,
} from '../store/ducks/booking';
import { HeadCell } from '../models/dataTable';
import useApp from './useApp';
import {
  CreateBookingInput,
  UpdateBookingInput,
  Booking,
  ModelBookingFilterInput,
  ListBookingsQueryVariables,
  UpdateGuestGroupInput,
  UpdateGuestInput,
  Guest,
} from '../models/GQL_API';
import {
  BookingOrigin,
  BookingPendingFilter,
  BookingsPaymentStatus,
  BookingSpecialNeedsFilter,
  BookingStatus,
  PaymentLinkOrigin,
} from '../constants/enums';
import sendEmail from '../Services/sendEmail';
import useGuest from './useGuest';
import sendSms from '../Services/sendSMS';
import axios from 'axios';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import QRCode from 'qrcode';
import { saveAs } from 'file-saver';

const client = generateClient();

const useBooking = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const bookingsListing = useSelector((state: any) => state.bookings.listing);
  const { guestsGet } = useGuest('guests', 'guest');
  const bookingRegisteredFilter = useSelector(
    (state: any) => state.bookings.registeredFilter,
  );
  const bookingStatusFilter = useSelector(
    (state: any) => state.bookings.statusFilter,
  );
  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]['nextToken'],
  );
  const session = useSelector((state: any) => state.app.session);
  const serverAddress = useSelector((state: any) => state.server.serverAddress);

  const sendSmsToUser = async (
    phone: string | null | undefined,
    message: string,
  ) => {
    if (!phone) return;
    try {
      await sendSms(phone, message);
    } catch (err) {
      console.log(err);
    }
  };

  async function fetch(params: BookingListingVariables) {
    const {
      searchText,
      limit,
      generalFilters,
      isMainGuest,
      status,
      overallStatus,
      mainGuestID,
      guestID,
      eventID,
    } = params;
    try {
      // console.log({ generalFilters });
      const filter: ModelBookingFilterInput = {
        deleted: { eq: '0' },
        overallStatus: { ne: BookingStatus.TEST },
      };
      if (generalFilters?.bookingsRegisteredFilter) {
        if (
          generalFilters?.bookingsRegisteredFilter ===
          BookingPendingFilter.REGISTERED
        ) {
          filter.bookingGuestId = { ne: '' };
          filter.hasNotRegisteredGuest = { eq: false };
        }
      }
      if (generalFilters?.bookingSpecialNeedsFilter) {
        if (
          generalFilters?.bookingSpecialNeedsFilter ===
          BookingSpecialNeedsFilter.Yes
        ) {
          filter.specialNeed = { eq: false };
        }
      }
      if (generalFilters?.bookingsPaymentStatus) {
        switch (generalFilters.bookingsPaymentStatus) {
          case BookingsPaymentStatus.PAID:
            filter.isPaid = { eq: true };
            break;
          case BookingsPaymentStatus.NOT_PAID:
            filter.isPaid = { eq: false };
            break;
          default:
            break;
        }
      }
      if (generalFilters?.bookingsOrigin === BookingOrigin.HOST_APP) {
        filter.origin = { eq: BookingOrigin.HOST_APP };
      } else if (generalFilters?.bookingsOrigin === BookingOrigin.WEBSITE) {
        filter.origin = { ne: BookingOrigin.HOST_APP };
      }
      filter.or = [];
      filter.and = [];
      if (searchText && searchText.length > 0) {
        if (/[a-zA-Z]/.test(searchText)) {
          filter.or.push({
            guestName: { contains: searchText.toLowerCase() },
          });
        } else if (/[0-9]/.test(searchText)) {
          filter.or.push({ phone_number: { contains: searchText } });
        }
      }

      if (
        isMainGuest !== undefined &&
        bookingStatusFilter !== BookingStatus.TRANSFERED
      ) {
        // console.log({ isMainGuest, status });
        filter.isMainGuest = { eq: isMainGuest };
      }
      if (mainGuestID) filter.bookingMainGuestId = { eq: mainGuestID };
      if (guestID) filter.bookingGuestId = { eq: guestID };
      if (status) filter.status = { eq: status };
      if (bookingStatusFilter === BookingStatus.TRANSFERED)
        filter.status = { eq: BookingStatus.TRANSFERED };
      if (bookingStatusFilter === BookingStatus.CANCELLED) {
        filter.status = { eq: BookingStatus.CANCELLED };
        filter.deleted = { eq: '1' };
      }
      if (bookingStatusFilter === BookingStatus.REJECTED) {
        filter.status = { eq: BookingStatus.REJECTED };
        filter.deleted = { eq: '0' };
      }
      if (eventID) filter.bookingEventId = { eq: eventID };
      if (
        overallStatus &&
        bookingStatusFilter !== BookingStatus.TRANSFERED &&
        bookingStatusFilter !== BookingStatus.CANCELLED &&
        bookingStatusFilter !== BookingStatus.REJECTED
      ) {
        filter.overallStatus = { eq: overallStatus };
        filter.isPendingTransfer = { ne: true };
      }
      if (!bookingStatusFilter || bookingStatusFilter === BookingStatus.ALL) {
        filter.isPendingTransfer = { ne: true };
      }

      if (generalFilters?.ticket)
        filter.bookingEventTicketId = { eq: generalFilters.ticket };
      if (generalFilters?.wave) filter.waveId = { eq: generalFilters.wave };
      let createdAtFilter: any = {};
      if (generalFilters?.fromDate && generalFilters.toDate) {
        const fromDate = new Date(generalFilters.fromDate);
        fromDate.setHours(0, 0, 0, 500);
        const toDate = new Date(generalFilters.toDate);
        toDate.setHours(23, 59, 59, 999);
        generalFilters.fromDate = fromDate.toISOString();
        generalFilters.toDate = toDate.toISOString();
        createdAtFilter = {
          between: [generalFilters.fromDate, generalFilters.toDate],
        };
      }
      if (filter.and && filter.and.length === 0) delete filter.and;
      if (filter.or && filter.or.length === 0) delete filter.or;
      if (!generalFilters?.event) return [];
      let variables: any = {
        filter,
        limit: 10000,
        nextToken: nextToken,
        bookingEventId: generalFilters?.event,
        sortDirection: ModelSortDirection.DESC,
      };

      if (createdAtFilter.between) {
        variables.createdAt = createdAtFilter;
      }
      const bookingList: any = await client.graphql({
        query: bookingByEventId,
        variables,
        authMode: 'userPool',
      });
      const currentNextToken = bookingList.data.bookingByEventId.nextToken;
      const responseListing = bookingList.data.bookingByEventId.items;

      let listing = [...bookingsListing, ...responseListing];
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err) {
      showError(err);
    }
  }
  // Function to remove cancelled bookings if there are non-cancelled bookings
  const removeCancelledIfNonCancelledExists = (bookings: Booking[]) => {
    const groupedBookings = bookings.reduce(
      (acc, booking) => {
        if (!acc[booking.bookingGuestId ?? '']) {
          acc[booking.bookingGuestId ?? ''] = [];
        }
        acc[booking.bookingGuestId ?? ''].push(booking);
        return acc;
      },
      {} as Record<string, Booking[]>,
    );

    return Object.values(groupedBookings).flatMap((guestBookings) => {
      const nonCancelledBookings = guestBookings.filter(
        (b) => b.deleted === '0',
      );
      if (nonCancelledBookings.length > 0) {
        // Remove all cancelled bookings if there are non-cancelled bookings
        return guestBookings.filter((b) => b.deleted === '0');
      } else {
        // Remove duplicate cancelled bookings, keep only one
        const cancelledBookings = guestBookings.filter(
          (b) => b.deleted === '1',
        );
        if (cancelledBookings.length > 1) {
          return [cancelledBookings[0]]; // Keep only one cancelled booking
        } else {
          return cancelledBookings;
        }
      }
    });
  };

  const fetchAllBookingsByMainGuestId = async (filter: any) => {
    try {
      const { eventID, mainGuestID, deleted } = filter;
      let queryFilter: ModelBookingFilterInput = {
        bookingEventId: { eq: eventID },
        deleted: { eq: deleted },
      };
      const bookingList: any = await fetchBookingsByMainGuestId(
        mainGuestID,
        queryFilter,
      );
      const accompaniedGuestsBooking = bookingList.filter(
        (booking: Booking) => !booking.isMainGuest,
      );
      const mainGuestBooking = bookingList.filter(
        (booking: Booking) => booking.isMainGuest,
      );

      const cleanedAccompaniedGuestsBooking =
        removeCancelledIfNonCancelledExists(accompaniedGuestsBooking);
      const cleanedMainGuestBooking =
        removeCancelledIfNonCancelledExists(mainGuestBooking);

      return {
        accomaniedGuests: cleanedAccompaniedGuestsBooking,
        mainGuestBooking: cleanedMainGuestBooking[0] || null,
      };
    } catch (error) {
      showError(error);
    }
  };
  const fetchAllBookingsByEventId = async (filter: any) => {
    try {
      const { eventID } = filter;
      const bookingList: any = await client.graphql({
        query: bookingByEventId,
        variables: { filter: {}, bookingEventId: eventID, limit: 100000 },
        authMode: 'userPool',
      });
      const responseListing = bookingList.data.bookingByEventId.items;
      return responseListing;
    } catch (error) {
      showError(error);
    }
  };
  const fetchBookingsList = async (
    filter: any,
    nextToken: string | undefined,
  ) => {
    try {
      const variables: any = { filter };
      if (nextToken) {
        variables.nextToken = nextToken;
      }
      variables.limit = 100000;
      const response: any = await client.graphql<Booking>({
        query: listBookings,
        variables: variables,
        authMode: 'userPool',
      });
      const bookings = response.data.listBookings;
      return bookings;
    } catch (err) {
      console.error('Error fetching bookings list:', err);
      throw err;
    }
  };

  const fetchBookings = async (filter: any) => {
    try {
      let userBookingsComplete: Booking[] = [];
      let nextToken: string | undefined = undefined;

      do {
        const userBookingsListNew = await fetchBookingsList(filter, nextToken);
        userBookingsComplete.push(...userBookingsListNew.items);
        nextToken = userBookingsListNew.nextToken;
      } while (nextToken);

      return userBookingsComplete;
    } catch (err) {
      console.error('Error fetching user bookings:', err);
      throw err;
    }
  };

  const fetchBookingsListByGuestId = async (
    filter: any,
    bookingGuestId: string,
    nextToken: string | undefined,
  ) => {
    try {
      const variables: any = { bookingGuestId, filter };
      if (filter) {
        variables.filter = filter;
      }
      if (nextToken) {
        variables.nextToken = nextToken;
      }
      variables.limit = 100000;
      const response: any = await client.graphql<Booking>({
        query: bookingByGuestId,
        variables: variables,
        authMode: 'userPool',
      });
      const bookings = response.data.bookingByGuestId;
      return bookings;
    } catch (err) {
      console.error('Error fetching bookings list:', err);
      throw err;
    }
  };

  const fetchBookingsByGuestId = async (
    bookingGuestId: string,
    filter: any,
  ) => {
    try {
      let userBookingsComplete: Booking[] = [];
      let nextToken: string | undefined = undefined;

      do {
        const userBookingsListNew = await fetchBookingsListByGuestId(
          filter,
          bookingGuestId,
          nextToken,
        );
        userBookingsComplete.push(...userBookingsListNew.items);
        nextToken = userBookingsListNew.nextToken;
      } while (nextToken);

      return userBookingsComplete;
    } catch (err) {
      console.error('Error fetching user bookings:', err);
      throw err;
    }
  };

  const fetchBookingsListByMainGuestId = async (
    filter: any,
    bookingMainGuestId: string,
    nextToken: string | undefined,
  ) => {
    try {
      const variables: any = { bookingMainGuestId };
      if (filter) {
        variables.filter = filter;
      }
      if (nextToken) {
        variables.nextToken = nextToken;
      }
      variables.limit = 100000;
      const response: any = await client.graphql<Booking>({
        query: bookingByMainGuestId,
        variables: variables,
        authMode: 'userPool',
      });
      const bookings = response.data.bookingByMainGuestId;
      return bookings;
    } catch (err) {
      console.error('Error fetching bookings list:', err);
      throw err;
    }
  };

  const fetchBookingsListByMainBookingId = async (
    filter: any,
    mainBookingId: string,
    nextToken: string | undefined,
  ) => {
    try {
      const variables: any = { mainBookingId };
      if (filter) {
        variables.filter = filter;
      }
      if (nextToken) {
        variables.nextToken = nextToken;
      }
      variables.limit = 100000;
      const response: any = await client.graphql<Booking>({
        query: bookingByMainBookingId,
        variables: variables,
        authMode: 'userPool',
      });
      const bookings = response.data.bookingByMainBookingId;
      return bookings;
    } catch (err) {
      console.error('Error fetching bookings list:', err);
      throw err;
    }
  };

  const fetchBookingsByMainGuestId = async (
    bookingMainGuestId: string,
    filter: any,
  ) => {
    try {
      let userBookingsComplete: Booking[] = [];
      let nextToken: string | undefined = undefined;

      do {
        const userBookingsListNew = await fetchBookingsListByMainGuestId(
          filter,
          bookingMainGuestId,
          nextToken,
        );
        userBookingsComplete.push(...userBookingsListNew.items);
        nextToken = userBookingsListNew.nextToken;
      } while (nextToken);

      return userBookingsComplete;
    } catch (err) {
      console.error('Error fetching user bookings:', err);
      throw err;
    }
  };

  const fetchBookingsByMainBookingId = async (
    mainBookingId: string,
    filter: any,
  ) => {
    try {
      let userBookingsComplete: Booking[] = [];
      let nextToken: string | undefined = undefined;

      do {
        const userBookingsListNew = await fetchBookingsListByMainBookingId(
          filter,
          mainBookingId,
          nextToken,
        );
        userBookingsComplete.push(...userBookingsListNew.items);
        nextToken = userBookingsListNew.nextToken;
      } while (nextToken);

      const accompaniedGuestsBooking = userBookingsComplete.filter(
        (booking: Booking) => !booking.isMainGuest,
      );
      return accompaniedGuestsBooking;
    } catch (err) {
      console.error('Error fetching user bookings:', err);
      throw err;
    }
  };

  const fetchBookingsListByEventId = async (
    filter: any,
    bookingEventId: string,
    nextToken: string | undefined,
  ) => {
    try {
      const variables: any = { bookingEventId };
      if (filter) {
        variables.filter = filter;
      }
      if (nextToken) {
        variables.nextToken = nextToken;
      }
      variables.limit = 100000;
      const response: any = await client.graphql<Booking>({
        query: bookingByEventId,
        variables: variables,
        authMode: 'userPool',
      });
      const bookings = response.data.bookingByEventId;
      return bookings;
    } catch (err) {
      console.error('Error fetching bookings list:', err);
      throw err;
    }
  };

  const fetchBookingsByEventId = async (
    bookingEventId: string,
    filter: any,
  ) => {
    try {
      let userBookingsComplete: Booking[] = [];
      let nextToken: string | undefined = undefined;

      do {
        const userBookingsListNew = await fetchBookingsListByEventId(
          filter,
          bookingEventId,
          nextToken,
        );
        userBookingsComplete.push(...userBookingsListNew.items);
        nextToken = userBookingsListNew.nextToken;
      } while (nextToken);

      return userBookingsComplete;
    } catch (err) {
      console.error('Error fetching user bookings:', err);
      throw err;
    }
  };

  const getAllBookingsByEventTicketId = async (eventTicketId: string) => {
    try {
      const filter = {
        bookingEventTicketId: { eq: eventTicketId },
        deleted: { eq: '0' },
      };
      return await fetchBookings(filter);
    } catch (err) {
      console.error('Error fetching Event ticket bookings:', err);
      throw err;
    }
  };

  const sortUserUpcomingEventsBookings = (
    userUpcomingEventsBookings: Booking[],
  ): Booking[] | [] => {
    if (userUpcomingEventsBookings.length === 0) {
      return []; // Return an empty array if there are no bookings
    }

    // Sort bookings by event start date
    const sortedBookings = userUpcomingEventsBookings.slice().sort((a, b) => {
      const startDateA = new Date(a.event.startDate!).getTime(); // Get time in milliseconds
      const startDateB = new Date(b.event.startDate!).getTime(); // Get time in milliseconds

      if (startDateA < startDateB) {
        return -1;
      }
      if (startDateA > startDateB) {
        return 1;
      }
      return 0;
    });

    return sortedBookings;
  };

  async function get(params: BookingGetVariables) {
    const { id, listing } = params;
    try {
      let single: Booking | undefined;
      if (listing && listing.length !== 0) {
        single = listing.find((resource: any) => resource.id === id);
      }

      if (single === undefined) {
        const listing: any = await client.graphql({
          query: getBooking,
          variables: { id },
          authMode: 'userPool',
        });
        single = listing.data.getBooking;
      }

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    try {
      const createInput: CreateBookingInput = {
        // Booking Status
        status: data.status ? data.status : '',
        overallStatus: data.overallStatus ? data.overallStatus : '',

        // Guest Information
        isMainGuest: data.isMainGuest ? data.isMainGuest : '',
        bookingMainGuestId: data.bookingMainGuestId,
        bookingGuestId: data.bookingGuestId,
        phone_number: data.phone_number ? data.phone_number : '',
        guestName: data.guestName ? data.guestName : '',

        // Booking Information
        specialNeed: data.specialNeed ? data.specialNeed : '',
        redeemed: data.redeemed,
        isPaid: data.isPaid,
        hasNotRegisteredGuest: data.hasNotRegisteredGuest,
        notRegisteredGuests: data.notRegisteredGuests
          ? data.notRegisteredGuests
          : [],

        bookingEventId: data.bookingEventId ? data.bookingEventId : '',
        bookingEventTicketId: data.bookingEventTicketId
          ? data.bookingEventTicketId
          : '',
        wave: data.wave ? data.wave : '',
        waveId: data.waveId ? data.waveId : '',

        // Ticket Details
        orderId: data.orderId ? data.orderId : '',
        cashlessCredit: data.cashlessCredit ? data.cashlessCredit : 0,
        guestTicket: data.guestTicket ? data.guestTicket : null,
        ticketNumber: data.ticketNumber ? data.ticketNumber : '',

        // Static Fields
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      const res = await client.graphql({
        query: createBooking,
        variables: { input: createInput },
        authMode: 'userPool',
      });
      // showConfirm(`New ${singleName} has been created successfully`);
      return res.data.createBooking;
    } catch (err) {
      // this is special case for bookings and booking tickets only
      // showError(err);
      throw err;
    }
  }

  async function update(params: BookingUpdateVariables) {
    const { id, listing, data, userID, userName, ticketChanged } = params;

    try {
      const original: any = await get({ id, listing: [] });
      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }
      const updateInput: UpdateBookingInput = {
        id: original.id,
        status: data.status ? data.status : original!.status,
        overallStatus: data.overallStatus
          ? data.overallStatus
          : original!.overallStatus,
        deleted: data.deleted ? data.deleted : original!.deleted,
        bookingEventId: data.bookingEventId
          ? data.bookingEventId
          : original!.bookingEventId,
        bookingGuestId: data.bookingGuestId
          ? data.bookingGuestId
          : original!.bookingGuestId,
        bookingMainGuestId: data.bookingMainGuestId
          ? data.bookingMainGuestId
          : original!.bookingMainGuestId,
        isMainGuest: data.isMainGuest
          ? data.isMainGuest
          : original!.isMainGuest,
        bookingEventTicketId: data.bookingEventTicketId
          ? data.bookingEventTicketId
          : original!.bookingEventTicketId,
        waveId: data.waveId ? data.waveId : original!.waveId,
        wave: data.waveName ? data.waveName : original!.wave,
        orderId: data.orderId ? data.orderId : original!.orderId,
        statusUpdatedByID: data.status ? userID : original.statusUpdatedByID,
        statusUpdatedByName: data.status
          ? userName
          : original.statusUpdatedByName,
        statusUpdatedAt: data.status
          ? new Date().toISOString()
          : original.statusUpdatedAt,
        bookingRejectionReasonId:
          data.rejectionReasonId || original.bookingRejectionReasonId,
        rejectionComment: data.rejectionComment || original.rejectionComment,
        balance: data.balance ? data.balance : original!.balance ?? 0,
        isPendingTransfer:
          data.isPendingTransfer !== null &&
          data.isPendingTransfer !== undefined
            ? data.isPendingTransfer
            : original.isPendingTransfer,
        lastGuestId:
          data.lastGuestId !== null && data.lastGuestId !== undefined
            ? data.lastGuestId
            : original.lastGuestId,
        lastMainGuestId:
          data.lastMainGuestId !== null && data.lastMainGuestId !== undefined
            ? data.lastMainGuestId
            : original.lastMainGuestId,
        isTransfered:
          data.isTransfered !== null && data.isTransfered !== undefined
            ? data.isTransfered
            : original.isTransfered,
        guestName:
          data.guestName !== null && data.guestName !== undefined
            ? data.guestName
            : original.guestName,
        ownerHistory:
          data.ownerHistory !== null && data.ownerHistory !== undefined
            ? data.ownerHistory
            : original.ownerHistory,
        _version: original._version,
      };

      // Update Query
      let updated = await client.graphql({
        query: updateBooking,
        variables: { input: updateInput },
        authMode: 'userPool',
      });
      let selectedBooking = updated.data.updateBooking;

      // Update Listing
      const updatedBookingListing = [...bookingsListing];
      const updatedBookingIdx = updatedBookingListing.findIndex(
        (booking) => booking.id === selectedBooking.id,
      );
      if (updatedBookingIdx >= 0) {
        updatedBookingListing[updatedBookingIdx] = selectedBooking;
        dispatch(setListing(updatedBookingListing));
      }
      if (
        data.status === BookingStatus.APPROVED &&
        original.guest?.email &&
        !data.isPendingTransfer
      ) {
        try {
          if (ticketChanged) {
            let textContent = `Hi ${original.guest.name}, ${original.eventTicket.type} tickets are not available, please proceed with your approved ${selectedBooking.eventTicket.type} tickets  https://www.ascai.tickets/home/payment/${original.id}?origin=${PaymentLinkOrigin.SMS}`;
            if (original.event.sendSmsToValidGuest) {
              await sendSmsToUser(original.guest.phone_number, textContent);
            }
          } else {
            let textContent = `Hi ${original.guest.name}, your booking for "${original.event.name}" is approved, please go to your ulter account and complete your payment to get your ${original.eventTicket.type} ticket https://www.ascai.tickets/home/payment/${original.id}?origin=${PaymentLinkOrigin.SMS}`;
            if (original.event.sendEmailToValidGuest) {
              await sendEmail({
                customerEmail: original.guest?.email,
                eventName: original.event?.name,
                guestName: original.guest?.name,
                link: `https://www.ascai.tickets/home/payment/${original.id}?origin=${PaymentLinkOrigin.EMAIL}`,
                templateName: 'UlterApprovedReserved',
              });
            }
            if (original.event.sendSmsToValidGuest) {
              await sendSmsToUser(original.guest.phone_number, textContent);
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
      if (data.status === BookingStatus.REJECTED && original.guest?.email) {
        try {
          // console.log(original.rejectionComment);
          let rejectionMessage =
            data.rejectionComment || original.rejectionComment;

          if (original.event.sendEmailToValidGuest) {
            await sendEmail({
              customerEmail: original.guest?.email,
              eventName: original.event?.name,
              guestName: original.guest?.name,
              rejectionMessage: rejectionMessage,
              templateName: 'UlterRejectedReserved',
            });
          }
          if (original.event.sendSmsToValidGuest) {
            let textContent = `Hi ${original.guest.name}, We regret to inform you that your booking request for the "${original.event.name}" event has been rejected.`;
            if (rejectionMessage) {
              textContent += ` Reason for Rejection: ${rejectionMessage}`;
            }
            await sendSmsToUser(original.guest.phone_number, textContent);
          }
        } catch (err) {
          console.log(err);
        }
      }
      // showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      // this is special case for bookings and booking tickets only
      // showError(err);
      console.log({ err });
      throw err;
    }
  }

  async function trash(params: BookingGetVariables) {
    try {
      const { id, listing } = params;

      const original = await get({ id: params.id, listing: [] });

      if (original) {
        const updateInput: UpdateBookingInput = {
          id: original.id,
          deleted: '1',
          _version: original._version,
        };

        await client.graphql({
          query: updateBooking,
          variables: { input: updateInput },
          authMode: 'userPool',
        });
      }
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: BookingBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: BookingGetVariables) {
    const { id, listing } = params;
    try {
      await client.graphql({
        query: deleteBooking,
        variables: { input: { id } },
        authMode: 'userPool',
      });
      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err) {
      showError(err);
    }
  }

  const fetchBookingsListByEventTicketId = async (
    eventTicketId: string,
    nextToken: string | undefined,
  ) => {
    try {
      const filter = {
        deleted: { eq: '0' },
        bookingEventTicketId: { eq: eventTicketId },
      };
      const variables: ListBookingsQueryVariables = { filter };
      if (nextToken) {
        variables.nextToken = nextToken;
      }
      variables.limit = 100000;
      const bookingList: any = await client.graphql({
        query: listBookings,
        variables,
        authMode: 'userPool',
      });
      // console.log({ bookingList });
      return bookingList.data.listBookings;
    } catch (err) {
      console.error('Error fetching bookings list:', err);
      throw err;
    }
  };

  const fetchBookingsByEventTicketId = async (eventTicketId: string) => {
    try {
      let userBookingsComplete: Booking[] = [];
      let nextToken: string | undefined = undefined;

      do {
        const userBookingsListNew = await fetchBookingsListByEventTicketId(
          eventTicketId,
          nextToken,
        );
        userBookingsComplete.push(...userBookingsListNew.items);
        nextToken = userBookingsListNew.nextToken;
      } while (nextToken);

      return userBookingsComplete;
    } catch (err) {
      console.error('Error fetching user bookings:', err);
      throw err;
    }
  };

  const updateBookingCashlessCredit = async (data: UpdateBookingInput) => {
    try {
      const original: any = await get({ id: data.id, listing: [] });
      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }
      const input: UpdateBookingInput = {
        id: data.id,
        cashlessCredit: data.cashlessCredit,
        balance: data.balance,
        _version: original._version,
      };
      let updated = await client.graphql({
        query: updateBooking,
        variables: { input },
        authMode: 'userPool',
      });
      return updated.data.updateBooking;
    } catch (err) {
      console.error('Error fetching bookings list:', err);
      throw err;
    }
  };

  const splitBookingsToPaidAndUnPaid = (bookings: Booking[]) => {
    const paidBookings: Booking[] = [];
    const unPaidBookings: Booking[] = [];
    for (const booking of bookings) {
      if (booking.isPaid) {
        paidBookings.push(booking);
      } else {
        unPaidBookings.push(booking);
      }
    }
    return { paidBookings, unPaidBookings };
  };

  const handleUnpaidBookings = async (
    unPaidBookings: Booking[],
    newCashlessCredit: number,
  ) => {
    try {
      const promisesArr: any[] = [];
      for (const unPaidBooking of unPaidBookings) {
        if (unPaidBooking.cashlessCredit === newCashlessCredit) continue;
        promisesArr.push(
          updateBookingCashlessCredit({
            id: unPaidBooking.id,
            cashlessCredit: newCashlessCredit,
          }),
        );
      }
      await Promise.all(promisesArr);
    } catch (err) {
      console.log('error updating booking: ', err);
    }
  };

  // const updateUserBalance = async (userId: string, creditToAdd: number) => {
  //   try {
  //     const original: Guest = await guestsGet({id: userId});
  //     const currentUserBalance = original.balance ?? 0;
  //     let newUserBalance = currentUserBalance + creditToAdd;
  //     const input: UpdateGuestInput = {
  //       id: userId,
  //       balance: newUserBalance
  //     };
  //     let updated = await client.graphql({
  //       query: updateGuest,
  //       variables: { input },
  //       authMode: 'userPool',
  //     });
  //     return updated.data.updateGuest;
  //   } catch (err) {
  //     console.error('Error updating user balance: ', err);
  //     throw err;
  //   };
  // }

  const handlePaidBookings = async (
    paidBookings: Booking[],
    newCashlessCredit: number,
  ) => {
    try {
      const promisesArr: any[] = [];
      for (const paidBooking of paidBookings) {
        const currentCashlessCredit = paidBooking.cashlessCredit ?? 0;
        const currentBalance = paidBooking.balance ?? 0;
        // check if the new cashless credit is less than or greater the last cashless credit
        // case new cashless credit is less than (decreased)
        if (currentCashlessCredit > newCashlessCredit) {
          // update the booking cashless with the new value and deduct from the balance the cashless credit difference
          const amountToBeDeducted = newCashlessCredit - currentCashlessCredit;
          promisesArr.push(
            updateBookingCashlessCredit({
              id: paidBooking.id,
              cashlessCredit: newCashlessCredit,
              balance: currentBalance + amountToBeDeducted,
            }),
          );
        } else if (currentCashlessCredit < newCashlessCredit) {
          // case new cashless credit is larger than (increased)
          // update the booking cashless with the new value and add to it the new cashless difference
          const amountToBeAdded = newCashlessCredit - currentCashlessCredit;
          promisesArr.push(
            updateBookingCashlessCredit({
              id: paidBooking.id,
              cashlessCredit: newCashlessCredit,
              balance: currentBalance + amountToBeAdded,
            }),
          );
        } else if (currentCashlessCredit === 0) {
          // just add the newCashless credit to the user and update booking
        } else {
          continue; // they are equal
        }
      }
      await Promise.all(promisesArr);
    } catch (err) {
      console.log({ err });
    }
  };

  async function updateBookingsCashlessCredit(
    eventTicketId: string,
    newCashlessCredit: number,
  ) {
    try {
      // fetch all bookings with this eventTicketId
      const bookings = await fetchBookingsByEventTicketId(eventTicketId);
      // split them to paid and unPaid
      const { paidBookings, unPaidBookings } =
        splitBookingsToPaidAndUnPaid(bookings);

      await Promise.all([
        handleUnpaidBookings(unPaidBookings, newCashlessCredit),
        handlePaidBookings(paidBookings, newCashlessCredit),
      ]);
    } catch (err) {
      console.log({ err });
    }
  }

  async function handleMainGuestAcceptTransfer(booking: Booking) {
    try {
      // 1. fetch all other guests bookings associated with this booking and change the mainGuest id of them
      const filter = {
        bookingEventId: { eq: booking.bookingEventId },
        isMainGuest: { eq: false },
        // deleted: { eq: '0' },
      };
      const allBookingsAssociatedWithMainGuest =
        await fetchBookingsByMainGuestId(booking.lastMainGuestId!, filter);

      // 2. change the status to approved and update the owner history and the isPendingTransfer and isTransfered
      const promisesArr: any = [];
      const updateMainGuestBookingData = {
        status: BookingStatus.APPROVED,
        isPendingTransfer: false,
        isTransfered: true,
        ownerHistory: [...(booking.ownerHistory ?? []), booking.lastGuestId],
      };
      promisesArr.push(
        update({
          id: booking.id,
          data: updateMainGuestBookingData,
          userID: session.sub,
          userName: session.name,
          listing: [],
        }),
      );

      for (const notMainGuestBooking of allBookingsAssociatedWithMainGuest) {
        const updateNotMainGuestBookingData = {
          bookingMainGuestId: booking.bookingMainGuestId,
        };
        promisesArr.push(
          update({
            id: notMainGuestBooking.id,
            data: updateNotMainGuestBookingData,
            userID: session.sub,
            userName: session.name,
            listing: [],
          }),
        );
      }

      await Promise.all(promisesArr);
    } catch (err: any) {
      console.log({ err });
      throw err;
    }
  }

  async function handleNotMainGuestAcceptTransfer(booking: Booking) {
    try {
      const filter = {
        isMainGuest: { eq: true },
        bookingEventId: { eq: booking.bookingEventId },
        deleted: { eq: '0' },
      };
      const mainGuestBookings = await fetchBookingsByMainGuestId(
        booking.bookingMainGuestId!,
        filter,
      );
      const mainGuestBooking = mainGuestBookings[0];
      const promisesArr: any = [];
      // 1. change the status to approved and update the owner history and the isPendingTransfer and isTransfered
      const updateNotMainGuestBookingData = {
        status: BookingStatus.APPROVED,
        isPendingTransfer: false,
        isTransfered: true,
        ownerHistory: [...(booking.ownerHistory ?? []), booking.lastGuestId],
      };
      promisesArr.push(
        update({
          id: booking.id,
          data: updateNotMainGuestBookingData,
          userID: session.sub,
          userName: session.name,
          listing: [],
        }),
      );
      // 2. update the mainGuest booking to has isPendingTransfer false
      const updateMainGuestBookingData = {
        isPendingTransfer: false,
      };
      promisesArr.push(
        update({
          id: mainGuestBooking.id,
          data: updateMainGuestBookingData,
          userID: session.sub,
          userName: session.name,
          listing: [],
        }),
      );

      await Promise.all(promisesArr);
    } catch (err) {
      console.log({ err });
      throw err;
    }
  }

  async function handleMainGuestRejectTransfer(booking: Booking) {
    try {
      const lastGuest = await guestsGet({
        id: booking.lastGuestId,
        listing: [],
      });
      // 1. change the status to approved and update the guest and mainGuest and the isPendingTransfer and guestName
      const updateMainGuestBookingData = {
        status: BookingStatus.APPROVED,
        isPendingTransfer: false,
        bookingGuestId: booking.lastGuestId,
        bookingMainGuestId: booking.lastMainGuestId,
        guestName: lastGuest.name,
      };
      await update({
        id: booking.id,
        data: updateMainGuestBookingData,
        userID: session.sub,
        userName: session.name,
        listing: [],
      });
    } catch (err) {
      console.log({ err });
      throw err;
    }
  }

  async function handleNotMainGuestRejectTransfer(booking: Booking) {
    try {
      const promisesArr: any = [];
      const fetchPromisesArr: any = [];
      // 1. change the status to approved and update the guest and the isPendingTransfer and guestName
      fetchPromisesArr.push(
        guestsGet({
          id: booking.lastGuestId,
          listing: [],
        }),
      );

      const filter = {
        isMainGuest: { eq: true },
        bookingEventId: { eq: booking.bookingEventId },
        deleted: { eq: '0' },
      };
      fetchPromisesArr.push(
        fetchBookingsByMainGuestId(booking.bookingMainGuestId!, filter),
      );

      const [lastGuest, mainGuestBookingList] =
        await Promise.all(fetchPromisesArr);
      const mainGuestBooking = mainGuestBookingList[0];

      const updateNotMainGuestBookingData = {
        status: BookingStatus.APPROVED,
        isPendingTransfer: false,
        bookingGuestId: booking.lastGuestId,
        guestName: lastGuest.name,
      };
      promisesArr.push(
        update({
          id: booking.id,
          data: updateNotMainGuestBookingData,
          userID: session.sub,
          userName: session.name,
          listing: [],
        }),
      );
      // 2. update the mainGuest booking to has isPendingTransfer false
      const updateMainGuestBookingData = {
        isPendingTransfer: false,
      };
      promisesArr.push(
        update({
          id: mainGuestBooking.id,
          data: updateMainGuestBookingData,
          userID: session.sub,
          userName: session.name,
          listing: [],
        }),
      );

      await Promise.all(promisesArr);
    } catch (err) {
      console.log({ err });
      throw err;
    }
  }

  async function handleAcceptTransferBooking(booking: Booking) {
    try {
      if (booking.isMainGuest) {
        // case 1 (this booking is the mainGuest booking)
        await handleMainGuestAcceptTransfer(booking);
      } else {
        // case 2 (not mainGuest booking)
        await handleNotMainGuestAcceptTransfer(booking);
      }
      showConfirm(`${singleName} has been transfered successfully`);
    } catch (err: any) {
      console.log({ err });
      showError(err?.meessage ?? 'Something went wrong');
    }
  }

  async function handleRejectTransferBooking(booking: Booking) {
    try {
      if (booking.isMainGuest) {
        // case 1 (this booking is the mainGuest booking)
        await handleMainGuestRejectTransfer(booking);
      } else {
        // case 2 (not mainGuest booking)
        await handleNotMainGuestRejectTransfer(booking);
      }
      showConfirm(`${singleName} transfer has been rejected successfully`);
    } catch (err: any) {
      console.log({ err });
      showError(err?.meessage ?? 'Something went wrong');
    }
  }

  async function fetchBookingByIdOffline(id: string) {
    try {
      const res = await axios.get(serverAddress + '/booking/' + id);
      const data = res.data.data.data;
      return data;
    } catch (err: any) {
      console.log({ err });
      if (err?.response?.status === 404) {
        throw new Error("This Guest Doesn't exist");
      } else {
        throw new Error(err?.response?.data?.message) ?? err;
      }
    }
  }

  const fetchAllBookingsForExport = async (
    bookingEventId,
    filter: any,
    nextToken: string | undefined,
  ) => {
    try {
      const variables: any = { bookingEventId };
      if (filter) {
        variables.filter = filter;
      }
      if (nextToken) {
        variables.nextToken = nextToken;
      }
      variables.limit = 100000;
      const response: any = await client.graphql<Booking>({
        query: bookingByEventId,
        variables: variables,
        authMode: 'userPool',
      });
      const bookings = response.data.bookingByEventId;
      return bookings;
    } catch (err) {
      console.error('Error fetching bookings list:', err);
      throw err;
    }
  };

  const fetchAllInvitaionsForExport = async (
    invitationEventId: string,
    filter: any,
    nextToken: string | undefined,
  ) => {
    try {
      const variables: any = { invitationEventId };
      if (filter) {
        variables.filter = filter;
      }
      if (nextToken) {
        variables.nextToken = nextToken;
      }
      variables.limit = 100000;
      const response: any = await client.graphql<Booking>({
        query: invitationByEventId,
        variables: variables,
        authMode: 'userPool',
      });
      const invitations = response.data.invitationByEventId;
      return invitations;
    } catch (err) {
      console.error('Error fetching bookings list:', err);
      throw err;
    }
  };

  const generateQRCode = async (data) => {
    try {
      const qrCodeDataURL = await QRCode.toDataURL(data);
      return qrCodeDataURL;
    } catch (err) {
      console.error('Error generating QR code:', err);
      throw err;
    }
  };

  const exportingBookingsAndInvitationsQRs = async (generalFilters) => {
    try {
      if (!generalFilters.event || generalFilters.event === '') {
        showError('Select Event First');
        return;
      }
      if (!generalFilters.ticket || generalFilters.ticket === '') {
        showError('Select Ticket Type First');
        return;
      }
      let AllBookings: Booking[] = [];
      let nextToken: string | undefined = undefined;
      let bookingEventId: string = generalFilters.event;
      const filter = {
        deleted: { eq: '0' },
        bookingEventTicketId: { eq: generalFilters.ticket },
        isPaid: { eq: true },
      };
      do {
        const userBookingsListNew = await fetchAllBookingsForExport(
          bookingEventId,
          filter,
          nextToken,
        );
        AllBookings.push(...userBookingsListNew.items);
        nextToken = userBookingsListNew.nextToken;
      } while (nextToken);

      let AllInvitaions: Invitation[] = [];
      let invNextToken: string | undefined = undefined;
      let invitationEventId: string = generalFilters.event;
      const invFilter = {
        deleted: { eq: '0' },
        invitationEventTicketId: { eq: generalFilters.ticket },
      };

      do {
        const userInvitaionsListNew = await fetchAllInvitaionsForExport(
          invitationEventId,
          invFilter,
          invNextToken,
        );
        AllInvitaions.push(...userInvitaionsListNew.items);
        invNextToken = userInvitaionsListNew.nextToken;
      } while (invNextToken);

      await generateExcelSheet(AllBookings, AllInvitaions);
      // return AllBookings;
      // return AllInvitaions;
    } catch (err) {
      console.error('Error fetching user bookings:', err);
      throw err;
    }
  };

  const generateExcelSheet = async (bookings, invitations) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      'Valid Paid Bookings and Invitations',
    );

    const headers = [
      'Booking Link',
      'QR',
      'Guest Name',
      'Guest Phone',
      'Ticket Type',
      'Ticket Source',
      'Redeem Status',
    ];

    worksheet.addRow(headers);

    const columnWidths = headers.map((header) => header.length);
    const imageWidth = 100;
    const imageHeight = 100;

    worksheet.getColumn(2).width = imageWidth / 7.5;

    for (const booking of bookings) {
      const bookingLink = `https://www.ascai.tickets/?id=${booking.id}`;
      const isRedeemed = booking.redeemed ? 'Redeemed' : 'Not redeemed';
      const qrCode = await generateQRCode(bookingLink);
      const row = [
        bookingLink,
        '',
        booking?.guest?.name,
        booking?.phone_number,
        booking?.eventTicket.type,
        booking.__typename,
        isRedeemed,
      ];
      const newRow = worksheet.addRow(row);

      worksheet.getRow(newRow.number).height = imageHeight * 0.75;

      const imageId = workbook.addImage({
        base64: qrCode,
        extension: 'png',
      });

      worksheet.addImage(imageId, {
        tl: { col: 1, row: newRow.number - 1 },
        ext: { width: imageWidth, height: imageHeight },
      });

      row.forEach((cell, index) => {
        const cellLength = cell?.toString().length;
        if (cellLength && cellLength > columnWidths[index]) {
          columnWidths[index] = cellLength;
        }
      });
    }

    for (const invitation of invitations) {
      const invitationLink = `https://www.ascai.tickets/?id=${invitation.id}`;
      const name =
        invitation.phone_number && invitation.phone_number !== ''
          ? invitation.phone_number
          : invitation.email;
      const isUsed = invitation.used ? 'Redeemed' : 'Not redeemed';
      const qrCode = await generateQRCode(invitationLink);
      const invRow = [
        invitationLink,
        '',
        name,
        invitation.phone_number,
        invitation?.eventTicket.type,
        invitation.__typename,
        isUsed,
      ];
      const newRow = worksheet.addRow(invRow);

      worksheet.getRow(newRow.number).height = imageHeight * 0.75;

      const imageId = workbook.addImage({
        base64: qrCode,
        extension: 'png',
      });

      worksheet.addImage(imageId, {
        tl: { col: 1, row: newRow.number - 1 },
        ext: { width: imageWidth, height: imageHeight },
      });

      invRow.forEach((cell, index) => {
        const cellLength = cell?.toString().length;
        if (cellLength && cellLength > columnWidths[index]) {
          columnWidths[index] = cellLength;
        }
      });
    }

    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width + 2;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'Tickets.xlsx');
  };

  const exportingBookingsApprovedAndNotpaid = async (
    generalFilters,
    status,
  ) => {
    console.log({ status });

    try {
      if (!generalFilters.event || generalFilters.event === '') {
        showError('Select Event First');
        return;
      }
      let AllBookings: Booking[] = [];
      let nextToken: string | undefined = undefined;
      let bookingEventId: string = generalFilters.event;
      const filter = {
        deleted: { eq: '0' },
        isPaid: { ne: true },
        // status: { eq: 'approved' },
        status:
          status === 'approved'
            ? { eq: 'approved' }
            : status === 'pending'
              ? { eq: 'pending' }
              : undefined,
      };
      do {
        const userBookingsListNew = await fetchAllBookingsForExport(
          bookingEventId,
          filter,
          nextToken,
        );
        AllBookings.push(...userBookingsListNew.items);
        nextToken = userBookingsListNew.nextToken;
      } while (nextToken);

      await generateExcelSheetApprovedAndNotpaid(AllBookings);
    } catch (err) {
      console.error('Error fetching bookings:', err);
      throw err;
    }
  };

  const generateExcelSheetApprovedAndNotpaid = async (bookings) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      'Valid Paid Bookings and Invitations',
    );

    const headers = ['Guest Name', 'Guest Email', 'Guest Phone'];

    worksheet.addRow(headers);

    const columnWidths = headers.map((header) => header.length);
    const imageWidth = 100;
    const imageHeight = 100;

    worksheet.getColumn(2).width = imageWidth / 7.5;

    for (const booking of bookings) {
      if (!booking?.guest?.email) continue;
      const bookingLink = `https://www.ascai.tickets/?id=${booking.id}`;
      const isRedeemed = booking.redeemed ? 'Redeemed' : 'Not redeemed';
      const qrCode = await generateQRCode(bookingLink);
      const row = [
        // bookingLink,
        // '',
        booking?.guest?.name,
        // booking?.phone_number,
        // booking?.eventTicket.type,
        // booking.__typename,
        // isRedeemed,
        booking?.guest?.email,
        booking?.phone_number,
      ];
      const newRow = worksheet.addRow(row);

      // worksheet.getRow(newRow.number).height = imageHeight * 0.75;

      // const imageId = workbook.addImage({
      //   base64: qrCode,
      //   extension: 'png',
      // });

      // worksheet.addImage(imageId, {
      //   tl: { col: 1, row: newRow.number - 1 },
      //   ext: { width: imageWidth, height: imageHeight },
      // });

      row.forEach((cell, index) => {
        const cellLength = cell?.toString().length;
        if (cellLength && cellLength > columnWidths[index]) {
          columnWidths[index] = cellLength;
        }
      });
    }

    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width + 2;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'Tickets.xlsx');
  };

  const exportingPaidBookingsAndInvitationsReport = async (generalFilters) => {
    try {
      if (!generalFilters.event || generalFilters.event === '') {
        showError('Select Event First');
        return;
      }
      let AllBookings: Booking[] = [];
      let nextToken: string | undefined = undefined;
      let bookingEventId: string = generalFilters.event;
      const filter = {
        deleted: { eq: '0' },
        isPaid: { eq: true },
      };
      do {
        const userBookingsListNew = await fetchAllBookingsForExport(
          bookingEventId,
          filter,
          nextToken,
        );
        AllBookings.push(...userBookingsListNew.items);
        nextToken = userBookingsListNew.nextToken;
      } while (nextToken);

      let AllInvitaions: Invitation[] = [];
      let invNextToken: string | undefined = undefined;
      let invitationEventId: string = generalFilters.event;
      const invFilter = {
        deleted: { eq: '0' },
        invitationEventTicketId: { eq: generalFilters.ticket },
      };

      do {
        const userInvitaionsListNew = await fetchAllInvitaionsForExport(
          invitationEventId,
          invFilter,
          invNextToken,
        );
        AllInvitaions.push(...userInvitaionsListNew.items);
        invNextToken = userInvitaionsListNew.nextToken;
      } while (invNextToken);

      await generatePaidExcelSheetReport(AllBookings, AllInvitaions);
      // return AllBookings;
      // return AllInvitaions;
    } catch (err) {
      console.error('Error fetching user bookings:', err);
      throw err;
    }
  };

  const generatePaidExcelSheetReport = async (bookings, invitations) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(
      'Valid Paid Bookings and Invitations',
    );

    const headers = [
      'Guest Name',
      'Guest Phone',
      'Guest Email',
      'Ticket Type',
      'Ticket Source',
      'Booking Source',
      'Payment Type',
    ];

    worksheet.addRow(headers);

    const columnWidths = headers.map((header) => header.length);
    const imageWidth = 100;
    const imageHeight = 100;

    worksheet.getColumn(2).width = imageWidth / 7.5;

    for (const booking of bookings) {
      // const isRedeemed = booking.redeemed ? 'Redeemed' : 'Not redeemed';
      const isBooking = booking.__typename === 'Booking' ? true : false;
      const row = [
        booking?.guest?.name,
        booking?.phone_number,
        booking?.guest?.email,
        booking?.eventTicket.type,
        booking.__typename,
        // isRedeemed,
        booking?.origin,
        isBooking && booking?.paymentMethod ? booking.paymentMethod : '-',
      ];

      const newRow = worksheet.addRow(row);

      // worksheet.getRow(newRow.number).height = imageHeight * 0.75;

      row.forEach((cell, index) => {
        const cellLength = cell?.toString().length;
        if (cellLength && cellLength > columnWidths[index]) {
          columnWidths[index] = cellLength;
        }
      });
    }

    for (const invitation of invitations) {
      const invitationLink = `https://www.ascai.tickets/?id=${invitation.id}`;
      const name =
        invitation.phone_number && invitation.phone_number !== ''
          ? invitation.phone_number
          : invitation.email;
      const isUsed = invitation.used ? 'Redeemed' : 'Not redeemed';
      const qrCode = await generateQRCode(invitationLink);
      const invRow = [
        invitationLink,
        '',
        name,
        invitation.phone_number,
        invitation?.eventTicket.type,
        invitation.__typename,
        isUsed,
      ];
      const newRow = worksheet.addRow(invRow);

      worksheet.getRow(newRow.number).height = imageHeight * 0.75;

      const imageId = workbook.addImage({
        base64: qrCode,
        extension: 'png',
      });

      worksheet.addImage(imageId, {
        tl: { col: 1, row: newRow.number - 1 },
        ext: { width: imageWidth, height: imageHeight },
      });

      invRow.forEach((cell, index) => {
        const cellLength = cell?.toString().length;
        if (cellLength && cellLength > columnWidths[index]) {
          columnWidths[index] = cellLength;
        }
      });
    }

    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width + 2;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'Tickets.xlsx');
  };

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    // {
    //   id: '# of tickets',
    //   numeric: false,
    //   disablePadding: false,
    //   label: '# of Tickets',
    // },
    {
      id: 'registration',
      numeric: false,
      disablePadding: false,
      label: 'Registration',
    },
    {
      id: 'group',
      numeric: false,
      disablePadding: false,
      label: 'Group',
    },
    {
      id: 'flag',
      numeric: false,
      disablePadding: false,
      label: 'Flag',
    },
    {
      id: 'average spending',
      numeric: false,
      disablePadding: false,
      label: 'Average Spending',
    },
    {
      id: '# of attended events',
      numeric: false,
      disablePadding: false,
      label: '# of Attended Events',
    },
    {
      id: 'last attended event',
      numeric: false,
      disablePadding: false,
      label: 'Last Attended Event',
    },
    {
      id: 'special needs',
      numeric: false,
      disablePadding: false,
      label: 'Special Needs',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
    },
    {
      id: 'latest action',
      numeric: false,
      disablePadding: false,
      label: 'Latest Action',
    },
  ];

  const TransferHeadCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'gender',
      numeric: false,
      disablePadding: false,
      label: 'Gender',
    },
    {
      id: 'transferTO',
      numeric: false,
      disablePadding: false,
      label: 'Transfer to',
    },
    {
      id: 'gender',
      numeric: false,
      disablePadding: false,
      label: 'Gender',
    },
    {
      id: 'requestDateTime',
      numeric: false,
      disablePadding: false,
      label: 'Request Date/Time',
    },
  ];

  const dataCells: readonly string[] = ['name', 'startDate', 'endDate'];

  const api: any = {};
  api[`${listingName}CreateSubscription`] = onCreateBooking;
  api[`${listingName}Options`] = [];
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}TransferHeadCells`] = TransferHeadCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAllBookingsByMainGuestId`] =
    fetchAllBookingsByMainGuestId;
  api[`${listingName}FetchAllBookingsByEventId`] = fetchAllBookingsByEventId;
  api[`${listingName}FetchBookingsByGuestId`] = fetchBookingsByGuestId;
  api[`${listingName}FetchBookingsByMainGuestId`] = fetchBookingsByMainGuestId;
  api[`${listingName}FetchBookingsByEventId`] = fetchBookingsByEventId;
  api[`${listingName}FetchBookingsByMainBookingId`] =
    fetchBookingsByMainBookingId;
  api[`${listingName}ExportingBookingsAndInvitationsQRs`] =
    exportingBookingsAndInvitationsQRs;
  api[`${listingName}ExportingBookingsApprovedAndNotpaid`] =
    exportingBookingsApprovedAndNotpaid;
  api[`${listingName}ExportingPaidBookingsAndInvitationsReport`] =
    exportingPaidBookingsAndInvitationsReport;
  api[`${listingName}FetchBookings`] = fetchBookings;
  api[`${listingName}FetchBookingByIdOffline`] = fetchBookingByIdOffline;
  api[`${listingName}FetchAllBookingsByEventTicketId`] =
    getAllBookingsByEventTicketId;
  api[`${listingName}SortUserUpcomingEventsBookings`] =
    sortUserUpcomingEventsBookings;
  api[`${listingName}UpdateBookingsCashlessCredit`] =
    updateBookingsCashlessCredit;
  api[`${listingName}AcceptTransfer`] = handleAcceptTransferBooking;
  api[`${listingName}RejectTransfer`] = handleRejectTransferBooking;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Booking[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ChangeRegisteredFilter`] = (filter: string) =>
    dispatch(setRegisteredFilter(filter));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = bookingsListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};
export default useBooking;
