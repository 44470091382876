// actions
const SET_SERVER_ADDRESS = 'server/SET_SERVER_ADDRESS';
const SET_SERVER_STATUS = 'server/SET_SERVER_STATUS';
const SET_MODAL_OPEN = 'server/SET_MODAL_OPEN';
interface ServerStatusState {
  serverAddress: string;
  serverStatus: boolean;
  modalOpen: boolean;
}

// interface SetServerAddressAction {
//   type: typeof SET_SERVER_ADDRESS;
//   address: string;
// }

// interface SetServerStatusAction {
//   type: typeof SET_SERVER_STATUS;
//   status: string | null;
// }

// type ServerStatusActionTypes = SetServerAddressAction | SetServerStatusAction;

const DEFAULT_STATE: ServerStatusState = {
  serverAddress: '',
  serverStatus: false,
  modalOpen: false,
};

// reducer
const reducer = (state = DEFAULT_STATE, action): ServerStatusState => {
  switch (action.type) {
    case SET_SERVER_ADDRESS:
      return { ...state, serverAddress: action.address };
    case SET_SERVER_STATUS:
      return { ...state, serverStatus: action.status };
    case SET_MODAL_OPEN:
      return { ...state, modalOpen: action.status };
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setServerAddress = (address: string): any => ({
  type: SET_SERVER_ADDRESS,
  address,
});

export const setServerStatus = (status: boolean): any => ({
  type: SET_SERVER_STATUS,
  status,
});
export const setModalOpen = (status: boolean): any => ({
  type: SET_MODAL_OPEN,
  status,
});
