import {
  CreateVariables,
  RejectReasonBulkTrashVariables,
  RejectReasonUpdateVariables,
  Option,
  RejectReasonGetVariables,
  RejectReasonListingVariables,
} from './../models/app';
import { generateClient } from 'aws-amplify/api';
import { useDispatch } from 'react-redux';
import { setListing, setNextToken, setSelected } from '../store/ducks/rejectionReason';
import { HeadCell } from '../models/dataTable';
import useApp from './useApp';
import {
  RejectReason,
  ModelRejectReasonFilterInput,
  UpdateRejectReasonInput,
} from '../models/GQL_API';
import { CreateRejectReasonInput } from '../models/GQL_API';
import { onCreateRejectReason } from '../graphql/subscriptions';
import { listRejectReasons, getRejectReason } from '../graphql/queries';
import {
  createRejectReason,
  deleteRejectReason,
  updateRejectReason,
} from '../graphql/mutations';

let client = generateClient();
const useRejectionReasons = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  async function fetch(params: RejectReasonListingVariables) {
    const { searchText, limit, rejectReasonsIds } = params;

    try {
      const filter: ModelRejectReasonFilterInput = {
        deleted: { eq: '0' },
      };
      filter.or = [];
      if (searchText) filter.content = { contains: searchText.toLowerCase() };
      if (rejectReasonsIds && rejectReasonsIds.length > 0) {
        for (const rejectReasonsId of rejectReasonsIds) {
          filter.or.push({ id: { eq: rejectReasonsId } });
        }
      }

      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      const listing = await client.graphql({
        query: listRejectReasons,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });

      // dispatch(setListing(listing));

      return listing.data.listRejectReasons.items;
    } catch (err: Error | any) {
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: RejectReasonGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: RejectReason) => model.id === id);

      return model ? model : '';
    }

    return '';
  };

  async function get(params: RejectReasonGetVariables) {
    const { id, listing } = params;

    // const single: Flag | undefined =
    //   listing.length === 0
    //     ? await DataStore.query(Flag as any, id)
    //     : listing.find((model: Flag) => model.id === id);

    let single: RejectReason | undefined;
    if (listing.length !== 0) {
      single = listing.find((resource: any) => resource.id === id);
    }

    if (single === undefined) {
      const listing = await client.graphql({
        query: getRejectReason,
        variables: { id },
        authMode: 'userPool',
      });
      single = listing.data.getRejectReason!;
    }
    return single;
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.accountID) {
      const error = new Error(`Cannot create ${singleName} without accountID`);
      return showError(error);
    }

    try {
      const createInput: CreateRejectReasonInput = {
        content: data.content.toLowerCase(),
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      // await DataStore.save(new Flag(createInput as any));

      await client.graphql({
        query: createRejectReason,
        variables: { input: createInput },
        authMode: 'userPool',
      });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: RejectReasonUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      // await DataStore.save(
      //   Flag.copyOf(original!, (updated) => {
      //     updated.name = data.name ? data.name.toLowerCase() : original!.name;
      //     updated.icon = data.icon ? data.icon : original!.icon;
      //     updated.color = data.color ? data.color : original!.color;
      //   }),
      // );

      let updateInput: UpdateRejectReasonInput = {
        id: original.id,
        updatedByID: data.accountID ? data.accountID : original.updatedByID,
        content: data.content ? data.content : original.content,
        _version: original._version,
      };

      await client.graphql({
        query: updateRejectReason,
        variables: { input: updateInput },
        authMode: 'userPool',
      });

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: RejectReasonGetVariables) {
    try {
      const original = await get(params);
      const { id, listing } = params;

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      // await DataStore.save(
      //   Flag.copyOf(original!, (updated) => {
      //     updated.deleted = '1';
      //   }),
      // );

      await client.graphql({
        query: updateRejectReason,
        variables: {
          input: { id: original.id, deleted: '1', _version: original._version },
        },
        authMode: 'userPool',
      });
      // console.log('listing', listing);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: RejectReasonBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: RejectReasonGetVariables) {
    const { id, listing } = params;

    try {
      // await DataStore.delete(id as any);

      await client.graphql({
        query: deleteRejectReason,
        variables: { input: { id } },
        authMode: 'userPool',
      });

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: RejectReason[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.content, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'content',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  const dataCells: readonly string[] = ['content'];

  const api: any = {};

  api[`${listingName}CreateSubscription`] = onCreateRejectReason;

  api[`${listingName}Options`] = options;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}ChangeListing`] = (listing: RejectReason[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));

  return api;
};

export default useRejectionReasons;
