/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/GQL_API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateAccount = /* GraphQL */ `subscription OnCreateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onCreateAccount(filter: $filter) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    currency
    merchantVersion
    merchantID
    merchantIframe
    merchantIntegrationNumber
    merchantAPIKey
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    accountLogoId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountSubscriptionVariables,
  APITypes.OnCreateAccountSubscription
>;
export const onUpdateAccount = /* GraphQL */ `subscription OnUpdateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onUpdateAccount(filter: $filter) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    currency
    merchantVersion
    merchantID
    merchantIframe
    merchantIntegrationNumber
    merchantAPIKey
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    accountLogoId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountSubscriptionVariables,
  APITypes.OnUpdateAccountSubscription
>;
export const onDeleteAccount = /* GraphQL */ `subscription OnDeleteAccount($filter: ModelSubscriptionAccountFilterInput) {
  onDeleteAccount(filter: $filter) {
    id
    logo {
      id
      mediaID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    currency
    merchantVersion
    merchantID
    merchantIframe
    merchantIntegrationNumber
    merchantAPIKey
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    accountLogoId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountSubscriptionVariables,
  APITypes.OnDeleteAccountSubscription
>;
export const onCreateConcept = /* GraphQL */ `subscription OnCreateConcept($filter: ModelSubscriptionConceptFilterInput) {
  onCreateConcept(filter: $filter) {
    id
    accountID
    name
    description
    logo
    type
    location
    precedence
    longitude
    latitude
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateConceptSubscriptionVariables,
  APITypes.OnCreateConceptSubscription
>;
export const onUpdateConcept = /* GraphQL */ `subscription OnUpdateConcept($filter: ModelSubscriptionConceptFilterInput) {
  onUpdateConcept(filter: $filter) {
    id
    accountID
    name
    description
    logo
    type
    location
    precedence
    longitude
    latitude
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateConceptSubscriptionVariables,
  APITypes.OnUpdateConceptSubscription
>;
export const onDeleteConcept = /* GraphQL */ `subscription OnDeleteConcept($filter: ModelSubscriptionConceptFilterInput) {
  onDeleteConcept(filter: $filter) {
    id
    accountID
    name
    description
    logo
    type
    location
    precedence
    longitude
    latitude
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteConceptSubscriptionVariables,
  APITypes.OnDeleteConceptSubscription
>;
export const onCreateLanguage = /* GraphQL */ `subscription OnCreateLanguage($filter: ModelSubscriptionLanguageFilterInput) {
  onCreateLanguage(filter: $filter) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateLanguageSubscriptionVariables,
  APITypes.OnCreateLanguageSubscription
>;
export const onUpdateLanguage = /* GraphQL */ `subscription OnUpdateLanguage($filter: ModelSubscriptionLanguageFilterInput) {
  onUpdateLanguage(filter: $filter) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLanguageSubscriptionVariables,
  APITypes.OnUpdateLanguageSubscription
>;
export const onDeleteLanguage = /* GraphQL */ `subscription OnDeleteLanguage($filter: ModelSubscriptionLanguageFilterInput) {
  onDeleteLanguage(filter: $filter) {
    id
    name
    code
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteLanguageSubscriptionVariables,
  APITypes.OnDeleteLanguageSubscription
>;
export const onCreateFeature = /* GraphQL */ `subscription OnCreateFeature($filter: ModelSubscriptionFeatureFilterInput) {
  onCreateFeature(filter: $filter) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFeatureSubscriptionVariables,
  APITypes.OnCreateFeatureSubscription
>;
export const onUpdateFeature = /* GraphQL */ `subscription OnUpdateFeature($filter: ModelSubscriptionFeatureFilterInput) {
  onUpdateFeature(filter: $filter) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFeatureSubscriptionVariables,
  APITypes.OnUpdateFeatureSubscription
>;
export const onDeleteFeature = /* GraphQL */ `subscription OnDeleteFeature($filter: ModelSubscriptionFeatureFilterInput) {
  onDeleteFeature(filter: $filter) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFeatureSubscriptionVariables,
  APITypes.OnDeleteFeatureSubscription
>;
export const onCreateAdminRole = /* GraphQL */ `subscription OnCreateAdminRole($filter: ModelSubscriptionAdminRoleFilterInput) {
  onCreateAdminRole(filter: $filter) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAdminRoleSubscriptionVariables,
  APITypes.OnCreateAdminRoleSubscription
>;
export const onUpdateAdminRole = /* GraphQL */ `subscription OnUpdateAdminRole($filter: ModelSubscriptionAdminRoleFilterInput) {
  onUpdateAdminRole(filter: $filter) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAdminRoleSubscriptionVariables,
  APITypes.OnUpdateAdminRoleSubscription
>;
export const onDeleteAdminRole = /* GraphQL */ `subscription OnDeleteAdminRole($filter: ModelSubscriptionAdminRoleFilterInput) {
  onDeleteAdminRole(filter: $filter) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAdminRoleSubscriptionVariables,
  APITypes.OnDeleteAdminRoleSubscription
>;
export const onCreateAdminGroup = /* GraphQL */ `subscription OnCreateAdminGroup(
  $filter: ModelSubscriptionAdminGroupFilterInput
) {
  onCreateAdminGroup(filter: $filter) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAdminGroupSubscriptionVariables,
  APITypes.OnCreateAdminGroupSubscription
>;
export const onUpdateAdminGroup = /* GraphQL */ `subscription OnUpdateAdminGroup(
  $filter: ModelSubscriptionAdminGroupFilterInput
) {
  onUpdateAdminGroup(filter: $filter) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAdminGroupSubscriptionVariables,
  APITypes.OnUpdateAdminGroupSubscription
>;
export const onDeleteAdminGroup = /* GraphQL */ `subscription OnDeleteAdminGroup(
  $filter: ModelSubscriptionAdminGroupFilterInput
) {
  onDeleteAdminGroup(filter: $filter) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAdminGroupSubscriptionVariables,
  APITypes.OnDeleteAdminGroupSubscription
>;
export const onCreateAttachment = /* GraphQL */ `subscription OnCreateAttachment(
  $filter: ModelSubscriptionAttachmentFilterInput
) {
  onCreateAttachment(filter: $filter) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAttachmentSubscriptionVariables,
  APITypes.OnCreateAttachmentSubscription
>;
export const onUpdateAttachment = /* GraphQL */ `subscription OnUpdateAttachment(
  $filter: ModelSubscriptionAttachmentFilterInput
) {
  onUpdateAttachment(filter: $filter) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAttachmentSubscriptionVariables,
  APITypes.OnUpdateAttachmentSubscription
>;
export const onDeleteAttachment = /* GraphQL */ `subscription OnDeleteAttachment(
  $filter: ModelSubscriptionAttachmentFilterInput
) {
  onDeleteAttachment(filter: $filter) {
    id
    mediaID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAttachmentSubscriptionVariables,
  APITypes.OnDeleteAttachmentSubscription
>;
export const onCreateUserConcepts = /* GraphQL */ `subscription OnCreateUserConcepts(
  $filter: ModelSubscriptionUserConceptsFilterInput
) {
  onCreateUserConcepts(filter: $filter) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserConceptsSubscriptionVariables,
  APITypes.OnCreateUserConceptsSubscription
>;
export const onUpdateUserConcepts = /* GraphQL */ `subscription OnUpdateUserConcepts(
  $filter: ModelSubscriptionUserConceptsFilterInput
) {
  onUpdateUserConcepts(filter: $filter) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserConceptsSubscriptionVariables,
  APITypes.OnUpdateUserConceptsSubscription
>;
export const onDeleteUserConcepts = /* GraphQL */ `subscription OnDeleteUserConcepts(
  $filter: ModelSubscriptionUserConceptsFilterInput
) {
  onDeleteUserConcepts(filter: $filter) {
    id
    defaultConcept
    concepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserConceptsSubscriptionVariables,
  APITypes.OnDeleteUserConceptsSubscription
>;
export const onCreateGuest = /* GraphQL */ `subscription OnCreateGuest($filter: ModelSubscriptionGuestFilterInput) {
  onCreateGuest(filter: $filter) {
    id
    name
    isNameEdited
    username
    email
    phone_number
    guestGroupID
    guestGroupName
    sub
    facebookSub
    guest_avatar
    avg_spend
    avg_ticket_type
    numberOfTickets
    connections
    last_attended_event
    last_attended_event_Name
    gender
    isGenderEdited
    group
    faceBookID
    appPassword
    birthdate
    isBirthdateEdited
    isVerified
    images
    address
    totalEvents
    flags
    frontPersonalID
    isFrontPersonalIDEdited
    passportImage
    isPassportImageEdited
    backPersonalID
    isBackPersonalIDEdited
    faceBookProfileLink
    instagramProfileLink
    nationality
    balance
    orderedItems {
      id
      name
      quantity
      price
      __typename
    }
    isBlocked
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGuestSubscriptionVariables,
  APITypes.OnCreateGuestSubscription
>;
export const onUpdateGuest = /* GraphQL */ `subscription OnUpdateGuest($filter: ModelSubscriptionGuestFilterInput) {
  onUpdateGuest(filter: $filter) {
    id
    name
    isNameEdited
    username
    email
    phone_number
    guestGroupID
    guestGroupName
    sub
    facebookSub
    guest_avatar
    avg_spend
    avg_ticket_type
    numberOfTickets
    connections
    last_attended_event
    last_attended_event_Name
    gender
    isGenderEdited
    group
    faceBookID
    appPassword
    birthdate
    isBirthdateEdited
    isVerified
    images
    address
    totalEvents
    flags
    frontPersonalID
    isFrontPersonalIDEdited
    passportImage
    isPassportImageEdited
    backPersonalID
    isBackPersonalIDEdited
    faceBookProfileLink
    instagramProfileLink
    nationality
    balance
    orderedItems {
      id
      name
      quantity
      price
      __typename
    }
    isBlocked
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGuestSubscriptionVariables,
  APITypes.OnUpdateGuestSubscription
>;
export const onDeleteGuest = /* GraphQL */ `subscription OnDeleteGuest($filter: ModelSubscriptionGuestFilterInput) {
  onDeleteGuest(filter: $filter) {
    id
    name
    isNameEdited
    username
    email
    phone_number
    guestGroupID
    guestGroupName
    sub
    facebookSub
    guest_avatar
    avg_spend
    avg_ticket_type
    numberOfTickets
    connections
    last_attended_event
    last_attended_event_Name
    gender
    isGenderEdited
    group
    faceBookID
    appPassword
    birthdate
    isBirthdateEdited
    isVerified
    images
    address
    totalEvents
    flags
    frontPersonalID
    isFrontPersonalIDEdited
    passportImage
    isPassportImageEdited
    backPersonalID
    isBackPersonalIDEdited
    faceBookProfileLink
    instagramProfileLink
    nationality
    balance
    orderedItems {
      id
      name
      quantity
      price
      __typename
    }
    isBlocked
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGuestSubscriptionVariables,
  APITypes.OnDeleteGuestSubscription
>;
export const onCreateGuestGroup = /* GraphQL */ `subscription OnCreateGuestGroup(
  $filter: ModelSubscriptionGuestGroupFilterInput
) {
  onCreateGuestGroup(filter: $filter) {
    id
    name
    description
    color
    guests
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGuestGroupSubscriptionVariables,
  APITypes.OnCreateGuestGroupSubscription
>;
export const onUpdateGuestGroup = /* GraphQL */ `subscription OnUpdateGuestGroup(
  $filter: ModelSubscriptionGuestGroupFilterInput
) {
  onUpdateGuestGroup(filter: $filter) {
    id
    name
    description
    color
    guests
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGuestGroupSubscriptionVariables,
  APITypes.OnUpdateGuestGroupSubscription
>;
export const onDeleteGuestGroup = /* GraphQL */ `subscription OnDeleteGuestGroup(
  $filter: ModelSubscriptionGuestGroupFilterInput
) {
  onDeleteGuestGroup(filter: $filter) {
    id
    name
    description
    color
    guests
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGuestGroupSubscriptionVariables,
  APITypes.OnDeleteGuestGroupSubscription
>;
export const onCreateTimeline = /* GraphQL */ `subscription OnCreateTimeline($filter: ModelSubscriptionTimelineFilterInput) {
  onCreateTimeline(filter: $filter) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    customerId
    isScanned
    type
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTimelineSubscriptionVariables,
  APITypes.OnCreateTimelineSubscription
>;
export const onUpdateTimeline = /* GraphQL */ `subscription OnUpdateTimeline($filter: ModelSubscriptionTimelineFilterInput) {
  onUpdateTimeline(filter: $filter) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    customerId
    isScanned
    type
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTimelineSubscriptionVariables,
  APITypes.OnUpdateTimelineSubscription
>;
export const onDeleteTimeline = /* GraphQL */ `subscription OnDeleteTimeline($filter: ModelSubscriptionTimelineFilterInput) {
  onDeleteTimeline(filter: $filter) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    customerId
    isScanned
    type
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTimelineSubscriptionVariables,
  APITypes.OnDeleteTimelineSubscription
>;
export const onCreateFlag = /* GraphQL */ `subscription OnCreateFlag($filter: ModelSubscriptionFlagFilterInput) {
  onCreateFlag(filter: $filter) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFlagSubscriptionVariables,
  APITypes.OnCreateFlagSubscription
>;
export const onUpdateFlag = /* GraphQL */ `subscription OnUpdateFlag($filter: ModelSubscriptionFlagFilterInput) {
  onUpdateFlag(filter: $filter) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlagSubscriptionVariables,
  APITypes.OnUpdateFlagSubscription
>;
export const onDeleteFlag = /* GraphQL */ `subscription OnDeleteFlag($filter: ModelSubscriptionFlagFilterInput) {
  onDeleteFlag(filter: $filter) {
    id
    accountID
    name
    icon
    color
    customers
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFlagSubscriptionVariables,
  APITypes.OnDeleteFlagSubscription
>;
export const onCreateComment = /* GraphQL */ `subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
  onCreateComment(filter: $filter) {
    id
    message
    customerId
    bookingId
    replyTo
    deleted
    createdAt
    createdByID
    createdByImg
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCommentSubscriptionVariables,
  APITypes.OnCreateCommentSubscription
>;
export const onUpdateComment = /* GraphQL */ `subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
  onUpdateComment(filter: $filter) {
    id
    message
    customerId
    bookingId
    replyTo
    deleted
    createdAt
    createdByID
    createdByImg
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCommentSubscriptionVariables,
  APITypes.OnUpdateCommentSubscription
>;
export const onDeleteComment = /* GraphQL */ `subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
  onDeleteComment(filter: $filter) {
    id
    message
    customerId
    bookingId
    replyTo
    deleted
    createdAt
    createdByID
    createdByImg
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCommentSubscriptionVariables,
  APITypes.OnDeleteCommentSubscription
>;
export const onCreateEvent = /* GraphQL */ `subscription OnCreateEvent($filter: ModelSubscriptionEventFilterInput) {
  onCreateEvent(filter: $filter) {
    id
    name
    description
    disclaimer
    startDate
    endDate
    location {
      address
      coordinates {
        lat
        lng
        __typename
      }
      __typename
    }
    todoList
    eventComments {
      image
      name
      message
      __typename
    }
    map
    image
    largeScreensImage
    mediumScreensImage
    smallScreensImage
    sendEmailToValidGuest
    sendSmsToValidGuest
    gallery
    visibleTo
    tickets {
      items {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    gates {
      items {
        id
        name
        eventId
        tickets
        admins
        checkpoints {
          id
          name
          precedence
          isSkippable
          createdAt
          createdByID
          createdByName
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invitationLimit {
      items {
        id
        adminID
        quota
        eventID
        totalQuota
        sentInvitations {
          invitationNumber
          reciver
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventInvitationLimitId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    published
    applyGenderRatio
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEventSubscriptionVariables,
  APITypes.OnCreateEventSubscription
>;
export const onUpdateEvent = /* GraphQL */ `subscription OnUpdateEvent($filter: ModelSubscriptionEventFilterInput) {
  onUpdateEvent(filter: $filter) {
    id
    name
    description
    disclaimer
    startDate
    endDate
    location {
      address
      coordinates {
        lat
        lng
        __typename
      }
      __typename
    }
    todoList
    eventComments {
      image
      name
      message
      __typename
    }
    map
    image
    largeScreensImage
    mediumScreensImage
    smallScreensImage
    sendEmailToValidGuest
    sendSmsToValidGuest
    gallery
    visibleTo
    tickets {
      items {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    gates {
      items {
        id
        name
        eventId
        tickets
        admins
        checkpoints {
          id
          name
          precedence
          isSkippable
          createdAt
          createdByID
          createdByName
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invitationLimit {
      items {
        id
        adminID
        quota
        eventID
        totalQuota
        sentInvitations {
          invitationNumber
          reciver
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventInvitationLimitId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    published
    applyGenderRatio
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEventSubscriptionVariables,
  APITypes.OnUpdateEventSubscription
>;
export const onDeleteEvent = /* GraphQL */ `subscription OnDeleteEvent($filter: ModelSubscriptionEventFilterInput) {
  onDeleteEvent(filter: $filter) {
    id
    name
    description
    disclaimer
    startDate
    endDate
    location {
      address
      coordinates {
        lat
        lng
        __typename
      }
      __typename
    }
    todoList
    eventComments {
      image
      name
      message
      __typename
    }
    map
    image
    largeScreensImage
    mediumScreensImage
    smallScreensImage
    sendEmailToValidGuest
    sendSmsToValidGuest
    gallery
    visibleTo
    tickets {
      items {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    gates {
      items {
        id
        name
        eventId
        tickets
        admins
        checkpoints {
          id
          name
          precedence
          isSkippable
          createdAt
          createdByID
          createdByName
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invitationLimit {
      items {
        id
        adminID
        quota
        eventID
        totalQuota
        sentInvitations {
          invitationNumber
          reciver
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventInvitationLimitId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    published
    applyGenderRatio
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEventSubscriptionVariables,
  APITypes.OnDeleteEventSubscription
>;
export const onCreateEventTicket = /* GraphQL */ `subscription OnCreateEventTicket(
  $filter: ModelSubscriptionEventTicketFilterInput
) {
  onCreateEventTicket(filter: $filter) {
    id
    type
    cashlessCredit
    description
    waves {
      id
      name
      price
      startDate
      endDate
      active
      quota
      AutomaticShift
      __typename
    }
    color
    paymentRules
    approvalRule
    showAll
    showOnHold
    onHoldDisplayText
    showSoldOut
    soldOutDisplayText
    setAvailable
    availableAtDate
    allowTransferred
    transferredAprroval
    transferredSameGender
    showUpcomingWave
    showUpcomingWaveDisplayText
    eventID
    gateID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateEventTicketSubscriptionVariables,
  APITypes.OnCreateEventTicketSubscription
>;
export const onUpdateEventTicket = /* GraphQL */ `subscription OnUpdateEventTicket(
  $filter: ModelSubscriptionEventTicketFilterInput
) {
  onUpdateEventTicket(filter: $filter) {
    id
    type
    cashlessCredit
    description
    waves {
      id
      name
      price
      startDate
      endDate
      active
      quota
      AutomaticShift
      __typename
    }
    color
    paymentRules
    approvalRule
    showAll
    showOnHold
    onHoldDisplayText
    showSoldOut
    soldOutDisplayText
    setAvailable
    availableAtDate
    allowTransferred
    transferredAprroval
    transferredSameGender
    showUpcomingWave
    showUpcomingWaveDisplayText
    eventID
    gateID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateEventTicketSubscriptionVariables,
  APITypes.OnUpdateEventTicketSubscription
>;
export const onDeleteEventTicket = /* GraphQL */ `subscription OnDeleteEventTicket(
  $filter: ModelSubscriptionEventTicketFilterInput
) {
  onDeleteEventTicket(filter: $filter) {
    id
    type
    cashlessCredit
    description
    waves {
      id
      name
      price
      startDate
      endDate
      active
      quota
      AutomaticShift
      __typename
    }
    color
    paymentRules
    approvalRule
    showAll
    showOnHold
    onHoldDisplayText
    showSoldOut
    soldOutDisplayText
    setAvailable
    availableAtDate
    allowTransferred
    transferredAprroval
    transferredSameGender
    showUpcomingWave
    showUpcomingWaveDisplayText
    eventID
    gateID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteEventTicketSubscriptionVariables,
  APITypes.OnDeleteEventTicketSubscription
>;
export const onCreateInvitationLimit = /* GraphQL */ `subscription OnCreateInvitationLimit(
  $filter: ModelSubscriptionInvitationLimitFilterInput
) {
  onCreateInvitationLimit(filter: $filter) {
    id
    adminID
    quota
    eventID
    totalQuota
    sentInvitations {
      invitationNumber
      reciver
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventInvitationLimitId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInvitationLimitSubscriptionVariables,
  APITypes.OnCreateInvitationLimitSubscription
>;
export const onUpdateInvitationLimit = /* GraphQL */ `subscription OnUpdateInvitationLimit(
  $filter: ModelSubscriptionInvitationLimitFilterInput
) {
  onUpdateInvitationLimit(filter: $filter) {
    id
    adminID
    quota
    eventID
    totalQuota
    sentInvitations {
      invitationNumber
      reciver
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventInvitationLimitId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInvitationLimitSubscriptionVariables,
  APITypes.OnUpdateInvitationLimitSubscription
>;
export const onDeleteInvitationLimit = /* GraphQL */ `subscription OnDeleteInvitationLimit(
  $filter: ModelSubscriptionInvitationLimitFilterInput
) {
  onDeleteInvitationLimit(filter: $filter) {
    id
    adminID
    quota
    eventID
    totalQuota
    sentInvitations {
      invitationNumber
      reciver
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    eventInvitationLimitId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInvitationLimitSubscriptionVariables,
  APITypes.OnDeleteInvitationLimitSubscription
>;
export const onCreateBooking = /* GraphQL */ `subscription OnCreateBooking($filter: ModelSubscriptionBookingFilterInput) {
  onCreateBooking(filter: $filter) {
    id
    status
    overallStatus
    bookingGuestId
    bookingMainGuestId
    mainBookingId
    bookingEventId
    bookingEventTicketId
    guest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mainGuest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventTicket {
      id
      type
      cashlessCredit
      description
      waves {
        id
        name
        price
        startDate
        endDate
        active
        quota
        AutomaticShift
        __typename
      }
      color
      paymentRules
      approvalRule
      showAll
      showOnHold
      onHoldDisplayText
      showSoldOut
      soldOutDisplayText
      setAvailable
      availableAtDate
      allowTransferred
      transferredAprroval
      transferredSameGender
      showUpcomingWave
      showUpcomingWaveDisplayText
      eventID
      gateID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cashlessCredit
    wave
    waveId
    isMainGuest
    isInvited
    orderId
    ticketNumber
    redeemed
    passedCheckpoints {
      id
      name
      precedence
      isSkippable
      createdAt
      createdByID
      createdByName
      __typename
    }
    statusUpdatedByID
    statusUpdatedByName
    statusUpdatedAt
    specialNeed
    phone_number
    guestTicket {
      number
      redeemed
      __typename
    }
    guestName
    notRegisteredGuests
    hasNotRegisteredGuest
    deleted
    createdAt
    createdByID
    createdByName
    rejectionReason {
      id
      content
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      updatedByID
      updatedByName
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    rejectionComment
    isPaid
    isTransfered
    isPendingTransfer
    lastGuestId
    lastGuest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    lastMainGuestId
    ownerHistory
    paidAmount
    balance
    scannedAt
    scannedByName
    refunded
    paymentTransactionId
    paymentParentTransactionId
    origin
    paymentMethod
    testField
    updatedAt
    _version
    _deleted
    _lastChangedAt
    bookingRejectionReasonId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBookingSubscriptionVariables,
  APITypes.OnCreateBookingSubscription
>;
export const onUpdateBooking = /* GraphQL */ `subscription OnUpdateBooking($filter: ModelSubscriptionBookingFilterInput) {
  onUpdateBooking(filter: $filter) {
    id
    status
    overallStatus
    bookingGuestId
    bookingMainGuestId
    mainBookingId
    bookingEventId
    bookingEventTicketId
    guest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mainGuest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventTicket {
      id
      type
      cashlessCredit
      description
      waves {
        id
        name
        price
        startDate
        endDate
        active
        quota
        AutomaticShift
        __typename
      }
      color
      paymentRules
      approvalRule
      showAll
      showOnHold
      onHoldDisplayText
      showSoldOut
      soldOutDisplayText
      setAvailable
      availableAtDate
      allowTransferred
      transferredAprroval
      transferredSameGender
      showUpcomingWave
      showUpcomingWaveDisplayText
      eventID
      gateID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cashlessCredit
    wave
    waveId
    isMainGuest
    isInvited
    orderId
    ticketNumber
    redeemed
    passedCheckpoints {
      id
      name
      precedence
      isSkippable
      createdAt
      createdByID
      createdByName
      __typename
    }
    statusUpdatedByID
    statusUpdatedByName
    statusUpdatedAt
    specialNeed
    phone_number
    guestTicket {
      number
      redeemed
      __typename
    }
    guestName
    notRegisteredGuests
    hasNotRegisteredGuest
    deleted
    createdAt
    createdByID
    createdByName
    rejectionReason {
      id
      content
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      updatedByID
      updatedByName
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    rejectionComment
    isPaid
    isTransfered
    isPendingTransfer
    lastGuestId
    lastGuest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    lastMainGuestId
    ownerHistory
    paidAmount
    balance
    scannedAt
    scannedByName
    refunded
    paymentTransactionId
    paymentParentTransactionId
    origin
    paymentMethod
    testField
    updatedAt
    _version
    _deleted
    _lastChangedAt
    bookingRejectionReasonId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBookingSubscriptionVariables,
  APITypes.OnUpdateBookingSubscription
>;
export const onDeleteBooking = /* GraphQL */ `subscription OnDeleteBooking($filter: ModelSubscriptionBookingFilterInput) {
  onDeleteBooking(filter: $filter) {
    id
    status
    overallStatus
    bookingGuestId
    bookingMainGuestId
    mainBookingId
    bookingEventId
    bookingEventTicketId
    guest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    mainGuest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventTicket {
      id
      type
      cashlessCredit
      description
      waves {
        id
        name
        price
        startDate
        endDate
        active
        quota
        AutomaticShift
        __typename
      }
      color
      paymentRules
      approvalRule
      showAll
      showOnHold
      onHoldDisplayText
      showSoldOut
      soldOutDisplayText
      setAvailable
      availableAtDate
      allowTransferred
      transferredAprroval
      transferredSameGender
      showUpcomingWave
      showUpcomingWaveDisplayText
      eventID
      gateID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cashlessCredit
    wave
    waveId
    isMainGuest
    isInvited
    orderId
    ticketNumber
    redeemed
    passedCheckpoints {
      id
      name
      precedence
      isSkippable
      createdAt
      createdByID
      createdByName
      __typename
    }
    statusUpdatedByID
    statusUpdatedByName
    statusUpdatedAt
    specialNeed
    phone_number
    guestTicket {
      number
      redeemed
      __typename
    }
    guestName
    notRegisteredGuests
    hasNotRegisteredGuest
    deleted
    createdAt
    createdByID
    createdByName
    rejectionReason {
      id
      content
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      updatedByID
      updatedByName
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    rejectionComment
    isPaid
    isTransfered
    isPendingTransfer
    lastGuestId
    lastGuest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    lastMainGuestId
    ownerHistory
    paidAmount
    balance
    scannedAt
    scannedByName
    refunded
    paymentTransactionId
    paymentParentTransactionId
    origin
    paymentMethod
    testField
    updatedAt
    _version
    _deleted
    _lastChangedAt
    bookingRejectionReasonId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBookingSubscriptionVariables,
  APITypes.OnDeleteBookingSubscription
>;
export const onCreateRejectReason = /* GraphQL */ `subscription OnCreateRejectReason(
  $filter: ModelSubscriptionRejectReasonFilterInput
) {
  onCreateRejectReason(filter: $filter) {
    id
    content
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    updatedByID
    updatedByName
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRejectReasonSubscriptionVariables,
  APITypes.OnCreateRejectReasonSubscription
>;
export const onUpdateRejectReason = /* GraphQL */ `subscription OnUpdateRejectReason(
  $filter: ModelSubscriptionRejectReasonFilterInput
) {
  onUpdateRejectReason(filter: $filter) {
    id
    content
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    updatedByID
    updatedByName
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRejectReasonSubscriptionVariables,
  APITypes.OnUpdateRejectReasonSubscription
>;
export const onDeleteRejectReason = /* GraphQL */ `subscription OnDeleteRejectReason(
  $filter: ModelSubscriptionRejectReasonFilterInput
) {
  onDeleteRejectReason(filter: $filter) {
    id
    content
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    updatedByID
    updatedByName
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRejectReasonSubscriptionVariables,
  APITypes.OnDeleteRejectReasonSubscription
>;
export const onCreateInvitation = /* GraphQL */ `subscription OnCreateInvitation(
  $filter: ModelSubscriptionInvitationFilterInput
) {
  onCreateInvitation(filter: $filter) {
    id
    invitationEventId
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventTicket {
      id
      type
      cashlessCredit
      description
      waves {
        id
        name
        price
        startDate
        endDate
        active
        quota
        AutomaticShift
        __typename
      }
      color
      paymentRules
      approvalRule
      showAll
      showOnHold
      onHoldDisplayText
      showSoldOut
      soldOutDisplayText
      setAvailable
      availableAtDate
      allowTransferred
      transferredAprroval
      transferredSameGender
      showUpcomingWave
      showUpcomingWaveDisplayText
      eventID
      gateID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cashlessCredit
    balance
    wave
    phone_number
    name
    email
    reason
    secret
    used
    scannedAt
    scannedByName
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    invitationEventTicketId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateInvitationSubscriptionVariables,
  APITypes.OnCreateInvitationSubscription
>;
export const onUpdateInvitation = /* GraphQL */ `subscription OnUpdateInvitation(
  $filter: ModelSubscriptionInvitationFilterInput
) {
  onUpdateInvitation(filter: $filter) {
    id
    invitationEventId
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventTicket {
      id
      type
      cashlessCredit
      description
      waves {
        id
        name
        price
        startDate
        endDate
        active
        quota
        AutomaticShift
        __typename
      }
      color
      paymentRules
      approvalRule
      showAll
      showOnHold
      onHoldDisplayText
      showSoldOut
      soldOutDisplayText
      setAvailable
      availableAtDate
      allowTransferred
      transferredAprroval
      transferredSameGender
      showUpcomingWave
      showUpcomingWaveDisplayText
      eventID
      gateID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cashlessCredit
    balance
    wave
    phone_number
    name
    email
    reason
    secret
    used
    scannedAt
    scannedByName
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    invitationEventTicketId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateInvitationSubscriptionVariables,
  APITypes.OnUpdateInvitationSubscription
>;
export const onDeleteInvitation = /* GraphQL */ `subscription OnDeleteInvitation(
  $filter: ModelSubscriptionInvitationFilterInput
) {
  onDeleteInvitation(filter: $filter) {
    id
    invitationEventId
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    eventTicket {
      id
      type
      cashlessCredit
      description
      waves {
        id
        name
        price
        startDate
        endDate
        active
        quota
        AutomaticShift
        __typename
      }
      color
      paymentRules
      approvalRule
      showAll
      showOnHold
      onHoldDisplayText
      showSoldOut
      soldOutDisplayText
      setAvailable
      availableAtDate
      allowTransferred
      transferredAprroval
      transferredSameGender
      showUpcomingWave
      showUpcomingWaveDisplayText
      eventID
      gateID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    cashlessCredit
    balance
    wave
    phone_number
    name
    email
    reason
    secret
    used
    scannedAt
    scannedByName
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    invitationEventTicketId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteInvitationSubscriptionVariables,
  APITypes.OnDeleteInvitationSubscription
>;
export const onCreateTransaction = /* GraphQL */ `subscription OnCreateTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
) {
  onCreateTransaction(filter: $filter) {
    id
    type
    guestId
    guestName
    guestsNames
    guestPhone_number
    eventId
    ticketId
    booking {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    success
    failureReason
    currency
    amount_cents
    refund
    refunded_amount_cents
    orderID
    transactionID
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    transactionBookingId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTransactionSubscriptionVariables,
  APITypes.OnCreateTransactionSubscription
>;
export const onUpdateTransaction = /* GraphQL */ `subscription OnUpdateTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
) {
  onUpdateTransaction(filter: $filter) {
    id
    type
    guestId
    guestName
    guestsNames
    guestPhone_number
    eventId
    ticketId
    booking {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    success
    failureReason
    currency
    amount_cents
    refund
    refunded_amount_cents
    orderID
    transactionID
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    transactionBookingId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTransactionSubscriptionVariables,
  APITypes.OnUpdateTransactionSubscription
>;
export const onDeleteTransaction = /* GraphQL */ `subscription OnDeleteTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
) {
  onDeleteTransaction(filter: $filter) {
    id
    type
    guestId
    guestName
    guestsNames
    guestPhone_number
    eventId
    ticketId
    booking {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    success
    failureReason
    currency
    amount_cents
    refund
    refunded_amount_cents
    orderID
    transactionID
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    transactionBookingId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTransactionSubscriptionVariables,
  APITypes.OnDeleteTransactionSubscription
>;
export const onCreateWavesConsumption = /* GraphQL */ `subscription OnCreateWavesConsumption(
  $filter: ModelSubscriptionWavesConsumptionFilterInput
) {
  onCreateWavesConsumption(filter: $filter) {
    waveId
    consumedTickets
    reservedTickets
    totalTickets
    consumed
    eventId
    eventTicketId
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateWavesConsumptionSubscriptionVariables,
  APITypes.OnCreateWavesConsumptionSubscription
>;
export const onUpdateWavesConsumption = /* GraphQL */ `subscription OnUpdateWavesConsumption(
  $filter: ModelSubscriptionWavesConsumptionFilterInput
) {
  onUpdateWavesConsumption(filter: $filter) {
    waveId
    consumedTickets
    reservedTickets
    totalTickets
    consumed
    eventId
    eventTicketId
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateWavesConsumptionSubscriptionVariables,
  APITypes.OnUpdateWavesConsumptionSubscription
>;
export const onDeleteWavesConsumption = /* GraphQL */ `subscription OnDeleteWavesConsumption(
  $filter: ModelSubscriptionWavesConsumptionFilterInput
) {
  onDeleteWavesConsumption(filter: $filter) {
    waveId
    consumedTickets
    reservedTickets
    totalTickets
    consumed
    eventId
    eventTicketId
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteWavesConsumptionSubscriptionVariables,
  APITypes.OnDeleteWavesConsumptionSubscription
>;
export const onCreateCashlessTransaction = /* GraphQL */ `subscription OnCreateCashlessTransaction(
  $filter: ModelSubscriptionCashlessTransactionFilterInput
) {
  onCreateCashlessTransaction(filter: $filter) {
    id
    pos
    checkNumber
    orderTotal
    paidAmount
    paymentStatus
    guestId
    guestPhoneNumber
    guestEmail
    guest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    guestName
    type
    bookingId
    invitationId
    eventId
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    orderedItems {
      id
      name
      quantity
      price
      __typename
    }
    syncOperationId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCashlessTransactionSubscriptionVariables,
  APITypes.OnCreateCashlessTransactionSubscription
>;
export const onUpdateCashlessTransaction = /* GraphQL */ `subscription OnUpdateCashlessTransaction(
  $filter: ModelSubscriptionCashlessTransactionFilterInput
) {
  onUpdateCashlessTransaction(filter: $filter) {
    id
    pos
    checkNumber
    orderTotal
    paidAmount
    paymentStatus
    guestId
    guestPhoneNumber
    guestEmail
    guest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    guestName
    type
    bookingId
    invitationId
    eventId
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    orderedItems {
      id
      name
      quantity
      price
      __typename
    }
    syncOperationId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCashlessTransactionSubscriptionVariables,
  APITypes.OnUpdateCashlessTransactionSubscription
>;
export const onDeleteCashlessTransaction = /* GraphQL */ `subscription OnDeleteCashlessTransaction(
  $filter: ModelSubscriptionCashlessTransactionFilterInput
) {
  onDeleteCashlessTransaction(filter: $filter) {
    id
    pos
    checkNumber
    orderTotal
    paidAmount
    paymentStatus
    guestId
    guestPhoneNumber
    guestEmail
    guest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    guestName
    type
    bookingId
    invitationId
    eventId
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    orderedItems {
      id
      name
      quantity
      price
      __typename
    }
    syncOperationId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCashlessTransactionSubscriptionVariables,
  APITypes.OnDeleteCashlessTransactionSubscription
>;
export const onCreateCashlessTopUp = /* GraphQL */ `subscription OnCreateCashlessTopUp(
  $filter: ModelSubscriptionCashlessTopUpFilterInput
) {
  onCreateCashlessTopUp(filter: $filter) {
    id
    operation
    amount
    availableBalance
    paymentChannel
    paymentMethod
    isVoided
    guestId
    guestPhoneNumber
    guestEmail
    guest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bookingId
    invitationId
    ticketType
    booking {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    invitation {
      id
      invitationEventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      balance
      wave
      phone_number
      name
      email
      reason
      secret
      used
      scannedAt
      scannedByName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invitationEventTicketId
      __typename
    }
    eventId
    eventName
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    type
    syncOperationId
    deleted
    guestName
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCashlessTopUpSubscriptionVariables,
  APITypes.OnCreateCashlessTopUpSubscription
>;
export const onUpdateCashlessTopUp = /* GraphQL */ `subscription OnUpdateCashlessTopUp(
  $filter: ModelSubscriptionCashlessTopUpFilterInput
) {
  onUpdateCashlessTopUp(filter: $filter) {
    id
    operation
    amount
    availableBalance
    paymentChannel
    paymentMethod
    isVoided
    guestId
    guestPhoneNumber
    guestEmail
    guest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bookingId
    invitationId
    ticketType
    booking {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    invitation {
      id
      invitationEventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      balance
      wave
      phone_number
      name
      email
      reason
      secret
      used
      scannedAt
      scannedByName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invitationEventTicketId
      __typename
    }
    eventId
    eventName
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    type
    syncOperationId
    deleted
    guestName
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCashlessTopUpSubscriptionVariables,
  APITypes.OnUpdateCashlessTopUpSubscription
>;
export const onDeleteCashlessTopUp = /* GraphQL */ `subscription OnDeleteCashlessTopUp(
  $filter: ModelSubscriptionCashlessTopUpFilterInput
) {
  onDeleteCashlessTopUp(filter: $filter) {
    id
    operation
    amount
    availableBalance
    paymentChannel
    paymentMethod
    isVoided
    guestId
    guestPhoneNumber
    guestEmail
    guest {
      id
      name
      isNameEdited
      username
      email
      phone_number
      guestGroupID
      guestGroupName
      sub
      facebookSub
      guest_avatar
      avg_spend
      avg_ticket_type
      numberOfTickets
      connections
      last_attended_event
      last_attended_event_Name
      gender
      isGenderEdited
      group
      faceBookID
      appPassword
      birthdate
      isBirthdateEdited
      isVerified
      images
      address
      totalEvents
      flags
      frontPersonalID
      isFrontPersonalIDEdited
      passportImage
      isPassportImageEdited
      backPersonalID
      isBackPersonalIDEdited
      faceBookProfileLink
      instagramProfileLink
      nationality
      balance
      orderedItems {
        id
        name
        quantity
        price
        __typename
      }
      isBlocked
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    bookingId
    invitationId
    ticketType
    booking {
      id
      status
      overallStatus
      bookingGuestId
      bookingMainGuestId
      mainBookingId
      bookingEventId
      bookingEventTicketId
      guest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      mainGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      wave
      waveId
      isMainGuest
      isInvited
      orderId
      ticketNumber
      redeemed
      passedCheckpoints {
        id
        name
        precedence
        isSkippable
        createdAt
        createdByID
        createdByName
        __typename
      }
      statusUpdatedByID
      statusUpdatedByName
      statusUpdatedAt
      specialNeed
      phone_number
      guestTicket {
        number
        redeemed
        __typename
      }
      guestName
      notRegisteredGuests
      hasNotRegisteredGuest
      deleted
      createdAt
      createdByID
      createdByName
      rejectionReason {
        id
        content
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        updatedByID
        updatedByName
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rejectionComment
      isPaid
      isTransfered
      isPendingTransfer
      lastGuestId
      lastGuest {
        id
        name
        isNameEdited
        username
        email
        phone_number
        guestGroupID
        guestGroupName
        sub
        facebookSub
        guest_avatar
        avg_spend
        avg_ticket_type
        numberOfTickets
        connections
        last_attended_event
        last_attended_event_Name
        gender
        isGenderEdited
        group
        faceBookID
        appPassword
        birthdate
        isBirthdateEdited
        isVerified
        images
        address
        totalEvents
        flags
        frontPersonalID
        isFrontPersonalIDEdited
        passportImage
        isPassportImageEdited
        backPersonalID
        isBackPersonalIDEdited
        faceBookProfileLink
        instagramProfileLink
        nationality
        balance
        orderedItems {
          id
          name
          quantity
          price
          __typename
        }
        isBlocked
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lastMainGuestId
      ownerHistory
      paidAmount
      balance
      scannedAt
      scannedByName
      refunded
      paymentTransactionId
      paymentParentTransactionId
      origin
      paymentMethod
      testField
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookingRejectionReasonId
      __typename
    }
    invitation {
      id
      invitationEventId
      event {
        id
        name
        description
        disclaimer
        startDate
        endDate
        location {
          address
          __typename
        }
        todoList
        eventComments {
          image
          name
          message
          __typename
        }
        map
        image
        largeScreensImage
        mediumScreensImage
        smallScreensImage
        sendEmailToValidGuest
        sendSmsToValidGuest
        gallery
        visibleTo
        tickets {
          nextToken
          startedAt
          __typename
        }
        gates {
          nextToken
          startedAt
          __typename
        }
        invitationLimit {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        published
        applyGenderRatio
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      eventTicket {
        id
        type
        cashlessCredit
        description
        waves {
          id
          name
          price
          startDate
          endDate
          active
          quota
          AutomaticShift
          __typename
        }
        color
        paymentRules
        approvalRule
        showAll
        showOnHold
        onHoldDisplayText
        showSoldOut
        soldOutDisplayText
        setAvailable
        availableAtDate
        allowTransferred
        transferredAprroval
        transferredSameGender
        showUpcomingWave
        showUpcomingWaveDisplayText
        eventID
        gateID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cashlessCredit
      balance
      wave
      phone_number
      name
      email
      reason
      secret
      used
      scannedAt
      scannedByName
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invitationEventTicketId
      __typename
    }
    eventId
    eventName
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    type
    syncOperationId
    deleted
    guestName
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCashlessTopUpSubscriptionVariables,
  APITypes.OnDeleteCashlessTopUpSubscription
>;
export const onCreateCategory = /* GraphQL */ `subscription OnCreateCategory($filter: ModelSubscriptionCategoryFilterInput) {
  onCreateCategory(filter: $filter) {
    id
    name
    precedence
    items {
      items {
        id
        name
        image
        price
        precedence
        categoryID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCategorySubscriptionVariables,
  APITypes.OnCreateCategorySubscription
>;
export const onUpdateCategory = /* GraphQL */ `subscription OnUpdateCategory($filter: ModelSubscriptionCategoryFilterInput) {
  onUpdateCategory(filter: $filter) {
    id
    name
    precedence
    items {
      items {
        id
        name
        image
        price
        precedence
        categoryID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCategorySubscriptionVariables,
  APITypes.OnUpdateCategorySubscription
>;
export const onDeleteCategory = /* GraphQL */ `subscription OnDeleteCategory($filter: ModelSubscriptionCategoryFilterInput) {
  onDeleteCategory(filter: $filter) {
    id
    name
    precedence
    items {
      items {
        id
        name
        image
        price
        precedence
        categoryID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCategorySubscriptionVariables,
  APITypes.OnDeleteCategorySubscription
>;
export const onCreateItem = /* GraphQL */ `subscription OnCreateItem($filter: ModelSubscriptionItemFilterInput) {
  onCreateItem(filter: $filter) {
    id
    name
    image
    price
    precedence
    categoryID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateItemSubscriptionVariables,
  APITypes.OnCreateItemSubscription
>;
export const onUpdateItem = /* GraphQL */ `subscription OnUpdateItem($filter: ModelSubscriptionItemFilterInput) {
  onUpdateItem(filter: $filter) {
    id
    name
    image
    price
    precedence
    categoryID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateItemSubscriptionVariables,
  APITypes.OnUpdateItemSubscription
>;
export const onDeleteItem = /* GraphQL */ `subscription OnDeleteItem($filter: ModelSubscriptionItemFilterInput) {
  onDeleteItem(filter: $filter) {
    id
    name
    image
    price
    precedence
    categoryID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteItemSubscriptionVariables,
  APITypes.OnDeleteItemSubscription
>;
export const onCreateItemConsumption = /* GraphQL */ `subscription OnCreateItemConsumption(
  $filter: ModelSubscriptionItemConsumptionFilterInput
) {
  onCreateItemConsumption(filter: $filter) {
    id
    eventId
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    itemId
    item {
      id
      name
      image
      price
      precedence
      categoryID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    itemName
    itemQuantity
    itemPrice
    syncOperationId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateItemConsumptionSubscriptionVariables,
  APITypes.OnCreateItemConsumptionSubscription
>;
export const onUpdateItemConsumption = /* GraphQL */ `subscription OnUpdateItemConsumption(
  $filter: ModelSubscriptionItemConsumptionFilterInput
) {
  onUpdateItemConsumption(filter: $filter) {
    id
    eventId
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    itemId
    item {
      id
      name
      image
      price
      precedence
      categoryID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    itemName
    itemQuantity
    itemPrice
    syncOperationId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateItemConsumptionSubscriptionVariables,
  APITypes.OnUpdateItemConsumptionSubscription
>;
export const onDeleteItemConsumption = /* GraphQL */ `subscription OnDeleteItemConsumption(
  $filter: ModelSubscriptionItemConsumptionFilterInput
) {
  onDeleteItemConsumption(filter: $filter) {
    id
    eventId
    event {
      id
      name
      description
      disclaimer
      startDate
      endDate
      location {
        address
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      todoList
      eventComments {
        image
        name
        message
        __typename
      }
      map
      image
      largeScreensImage
      mediumScreensImage
      smallScreensImage
      sendEmailToValidGuest
      sendSmsToValidGuest
      gallery
      visibleTo
      tickets {
        items {
          id
          type
          cashlessCredit
          description
          color
          paymentRules
          approvalRule
          showAll
          showOnHold
          onHoldDisplayText
          showSoldOut
          soldOutDisplayText
          setAvailable
          availableAtDate
          allowTransferred
          transferredAprroval
          transferredSameGender
          showUpcomingWave
          showUpcomingWaveDisplayText
          eventID
          gateID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      gates {
        items {
          id
          name
          eventId
          tickets
          admins
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitationLimit {
        items {
          id
          adminID
          quota
          eventID
          totalQuota
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          eventInvitationLimitId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      published
      applyGenderRatio
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    itemId
    item {
      id
      name
      image
      price
      precedence
      categoryID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    itemName
    itemQuantity
    itemPrice
    syncOperationId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteItemConsumptionSubscriptionVariables,
  APITypes.OnDeleteItemConsumptionSubscription
>;
export const onCreateTicketsScan = /* GraphQL */ `subscription OnCreateTicketsScan(
  $filter: ModelSubscriptionTicketsScanFilterInput
) {
  onCreateTicketsScan(filter: $filter) {
    id
    scannedById
    scannedAt
    status
    eventId
    bookingId
    invitationId
    guestName
    ticketType
    guestAvatar
    deleted
    gateName
    checkpointName
    scannedByName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTicketsScanSubscriptionVariables,
  APITypes.OnCreateTicketsScanSubscription
>;
export const onUpdateTicketsScan = /* GraphQL */ `subscription OnUpdateTicketsScan(
  $filter: ModelSubscriptionTicketsScanFilterInput
) {
  onUpdateTicketsScan(filter: $filter) {
    id
    scannedById
    scannedAt
    status
    eventId
    bookingId
    invitationId
    guestName
    ticketType
    guestAvatar
    deleted
    gateName
    checkpointName
    scannedByName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTicketsScanSubscriptionVariables,
  APITypes.OnUpdateTicketsScanSubscription
>;
export const onDeleteTicketsScan = /* GraphQL */ `subscription OnDeleteTicketsScan(
  $filter: ModelSubscriptionTicketsScanFilterInput
) {
  onDeleteTicketsScan(filter: $filter) {
    id
    scannedById
    scannedAt
    status
    eventId
    bookingId
    invitationId
    guestName
    ticketType
    guestAvatar
    deleted
    gateName
    checkpointName
    scannedByName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTicketsScanSubscriptionVariables,
  APITypes.OnDeleteTicketsScanSubscription
>;
export const onCreateGate = /* GraphQL */ `subscription OnCreateGate($filter: ModelSubscriptionGateFilterInput) {
  onCreateGate(filter: $filter) {
    id
    name
    eventId
    tickets
    admins
    checkpoints {
      id
      name
      precedence
      isSkippable
      createdAt
      createdByID
      createdByName
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGateSubscriptionVariables,
  APITypes.OnCreateGateSubscription
>;
export const onUpdateGate = /* GraphQL */ `subscription OnUpdateGate($filter: ModelSubscriptionGateFilterInput) {
  onUpdateGate(filter: $filter) {
    id
    name
    eventId
    tickets
    admins
    checkpoints {
      id
      name
      precedence
      isSkippable
      createdAt
      createdByID
      createdByName
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGateSubscriptionVariables,
  APITypes.OnUpdateGateSubscription
>;
export const onDeleteGate = /* GraphQL */ `subscription OnDeleteGate($filter: ModelSubscriptionGateFilterInput) {
  onDeleteGate(filter: $filter) {
    id
    name
    eventId
    tickets
    admins
    checkpoints {
      id
      name
      precedence
      isSkippable
      createdAt
      createdByID
      createdByName
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGateSubscriptionVariables,
  APITypes.OnDeleteGateSubscription
>;
export const onCreateOrder = /* GraphQL */ `subscription OnCreateOrder($filter: ModelSubscriptionOrderFilterInput) {
  onCreateOrder(filter: $filter) {
    id
    bookingId
    eventId
    userId
    orderedItems {
      id
      name
      quantity
      price
      __typename
    }
    status
    dispatcherId
    totalAmount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderSubscriptionVariables,
  APITypes.OnCreateOrderSubscription
>;
export const onUpdateOrder = /* GraphQL */ `subscription OnUpdateOrder($filter: ModelSubscriptionOrderFilterInput) {
  onUpdateOrder(filter: $filter) {
    id
    bookingId
    eventId
    userId
    orderedItems {
      id
      name
      quantity
      price
      __typename
    }
    status
    dispatcherId
    totalAmount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderSubscriptionVariables,
  APITypes.OnUpdateOrderSubscription
>;
export const onDeleteOrder = /* GraphQL */ `subscription OnDeleteOrder($filter: ModelSubscriptionOrderFilterInput) {
  onDeleteOrder(filter: $filter) {
    id
    bookingId
    eventId
    userId
    orderedItems {
      id
      name
      quantity
      price
      __typename
    }
    status
    dispatcherId
    totalAmount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderSubscriptionVariables,
  APITypes.OnDeleteOrderSubscription
>;
export const onCreateUserIP = /* GraphQL */ `subscription OnCreateUserIP($filter: ModelSubscriptionUserIPFilterInput) {
  onCreateUserIP(filter: $filter) {
    id
    ip
    date
    times
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserIPSubscriptionVariables,
  APITypes.OnCreateUserIPSubscription
>;
export const onUpdateUserIP = /* GraphQL */ `subscription OnUpdateUserIP($filter: ModelSubscriptionUserIPFilterInput) {
  onUpdateUserIP(filter: $filter) {
    id
    ip
    date
    times
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserIPSubscriptionVariables,
  APITypes.OnUpdateUserIPSubscription
>;
export const onDeleteUserIP = /* GraphQL */ `subscription OnDeleteUserIP($filter: ModelSubscriptionUserIPFilterInput) {
  onDeleteUserIP(filter: $filter) {
    id
    ip
    date
    times
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserIPSubscriptionVariables,
  APITypes.OnDeleteUserIPSubscription
>;
export const onCreateScan = /* GraphQL */ `subscription OnCreateScan($filter: ModelSubscriptionScanFilterInput) {
  onCreateScan(filter: $filter) {
    id
    link
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateScanSubscriptionVariables,
  APITypes.OnCreateScanSubscription
>;
export const onUpdateScan = /* GraphQL */ `subscription OnUpdateScan($filter: ModelSubscriptionScanFilterInput) {
  onUpdateScan(filter: $filter) {
    id
    link
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateScanSubscriptionVariables,
  APITypes.OnUpdateScanSubscription
>;
export const onDeleteScan = /* GraphQL */ `subscription OnDeleteScan($filter: ModelSubscriptionScanFilterInput) {
  onDeleteScan(filter: $filter) {
    id
    link
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteScanSubscriptionVariables,
  APITypes.OnDeleteScanSubscription
>;
