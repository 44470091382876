import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pages, paymentChannel } from '../../constants/enums';
import { exportXLSX } from '../../helpers/utils';
import useCashlessTopups from '../../hooks/useCashlessTopups';
import usePaginate from '../../hooks/usePaginate';
import { CashlessTopUp } from '../../models/GQL_API';
import {
  CashlessTopUpListingVariables,
  FetchDashboardStatisticsResult,
} from '../../models/app';
import ContentLoader from '../UI/ContentLoader/ContentLoader';
import TodayStatistics from './TodayStatistics';
import TopUpsListing from './TopUpsListing';
import UserCharge from './UserCharge';
import { setModalOpen } from '../../store/ducks/server';

interface Props {
  slug: string;
}

const CashlessPage: React.FC<Props> = ({ slug }) => {
  const [userBalance, setUserBalance] = useState(0);
  const [cashlessTopupsSearchValue, setCashlessTopupsSearchValue] =
    useState('');
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  const [dataLoading, setDataLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [initialLoadingDone, setInitialLoadingDone] = useState(false);
  const [dashboardStatistics, setDashboardStatistics] =
    useState<FetchDashboardStatisticsResult>({
      recentGuests: [],
      todayPerformance: 0,
      todayPerformancePercentageWithYesterday: 0,
      totalTransactions: 0,
      successfullTransactions: 0,
      successfullCash: 0,
      successfullVisa: 0,
      successfullComplementary: 0,
      voidedTransactions: 0,
    });
  const cashlessTopupsListing = useSelector(
    (state: any) => state.cashlessTopups.listing,
  );
  const serverStatus = useSelector((state: any) => state.server.serverStatus);
  const {
    cashlessTopupsFetchAllSortedByDate,
    cashlessTopupsChangeListing,
    cashlessTopupsFetchStatistics,
    cashlessTopupsHeadCells,
    cashlessTopupsDataCells,
  } = useCashlessTopups('cashlessTopups', 'cashlessTopup');
  const { startIndex, limit, next } = usePaginate();
  const dispatch = useDispatch();

  const handleCashlessTopupsSearchChange = (value: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const timeoutId = setTimeout(() => {
      setCashlessTopupsSearchValue(value);
    }, 500);
    setSearchTimeout(timeoutId);
  };

  const fetchCashlessTopups = async () => {
    const Params: CashlessTopUpListingVariables = {
      searchText: cashlessTopupsSearchValue,
      startIndex,
      limit,
      generalFilters: {
        fromDate: new Date().toISOString(),
        toDate: new Date().toISOString(),
        searchText: cashlessTopupsSearchValue,
        event: '',
      },
      guestId: '',
      eventFilter: '',
      operationTypeFilter: '',
      paymentChannelFilter: paymentChannel.ONSITE,
    };
    const listing: CashlessTopUp[] =
      await cashlessTopupsFetchAllSortedByDate(Params);
    if (!listing || !listing.length) {
      setDataLoading(false);
    }
    cashlessTopupsChangeListing(listing);
    setInitialLoadingDone(true);
  };

  const handleExport = async () => {
    if (!serverStatus) {
      dispatch(setModalOpen(true));
      return;
    }
    setIsExporting(true);
    let data = cashlessTopupsListing;
    exportXLSX({
      slug: Pages.CASHLESS_ALL_TOP_UPS,
      headCells: cashlessTopupsHeadCells,
      data,
      rowKeys: cashlessTopupsDataCells,
      selected: undefined,
    });
    setIsExporting(false);
  };
  const fetchCashlessTopupsDashboardStatistics = async () => {
    try {
      setDataLoading(true);
      // Get today's date
      const today = new Date();

      // Calculate yesterday's date
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      // Set fromDate to the start of yesterday (00:00:00 in local time zone)
      const fromDate = new Date(yesterday);
      fromDate.setHours(0, 0, 0, 0);

      // Set toDate to the end of yesterday (23:59:59 in local time zone)
      const toDate = new Date(yesterday);
      toDate.setDate(toDate.getDate() - 1);
      toDate.setHours(23, 59, 59, 999); // Set to end of the day

      const Params: CashlessTopUpListingVariables = {
        searchText: cashlessTopupsSearchValue,
        startIndex,
        limit,
        generalFilters: {
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
          searchText: '',
          event: '',
        },
        guestId: '',
        eventFilter: '',
        operationTypeFilter: '',
        paymentChannelFilter: paymentChannel.ONSITE,
      };
      const yesterdayListing: CashlessTopUp[] =
        await cashlessTopupsFetchAllSortedByDate(Params);
      const yesterdayPerformance = yesterdayListing.reduce(
        (totalAmount: number, item: CashlessTopUp) => {
          return totalAmount + item.amount;
        },
        0,
      );
      // console.log({ cashlessTopupsListing });
      const stats = await cashlessTopupsFetchStatistics(
        cashlessTopupsListing,
        yesterdayPerformance,
      );
      setDashboardStatistics(stats);
      setDataLoading(false);
    } catch (err) {
      console.log({ err });
      setDataLoading(false);
    }
  };
  useEffect(() => {
    if (!serverStatus) return;
    fetchCashlessTopups();
    // console.log({ cashlessTopupsSearchValue, serverStatus });
  }, [cashlessTopupsSearchValue, serverStatus, cashlessTopupsListing?.length]);

  useEffect(() => {
    // if (!cashlessTopupsListing?.length) return;
    if (!initialLoadingDone || !serverStatus) return;
    // if (dashboardStatistics.totalTransactions > 0) return;
    fetchCashlessTopupsDashboardStatistics();
    // console.log({ cashlessTopupsListing, initialLoadingDone });
  }, [cashlessTopupsListing, initialLoadingDone]);

  useEffect(() => {
    if (!serverStatus) {
      dispatch(setModalOpen(true));
    }
  }, [serverStatus]);

  return (
    <Box
      sx={{
        backgroundColor: 'info.300',
        minHeight: '100vh',
      }}
    >
      <Grid container spacing={1.5}>
        {/* Today Statistics */}
        <TodayStatistics
          dashboardStatistics={dashboardStatistics}
          loading={dataLoading}
        />
        {/* User Charge */}
        <UserCharge setUserBalance={setUserBalance} userBalance={userBalance} />
        {/* Top Ups Listing */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            mt: 3,
            ml: { xs: 0, sm: 0.75 },
            height: { xs: '100vh', sm: '110vh', md: '90vh', lg: '90vh' },
            // height: 'auto',
            width: '100%',
          }}
        >
          {!initialLoadingDone && serverStatus ? (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              <ContentLoader />
            </Box>
          ) : (
            <TopUpsListing
              onSearchChange={handleCashlessTopupsSearchChange}
              isExporting={isExporting}
              handleExport={handleExport}
              setUserBalance={setUserBalance}
            />
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default CashlessPage;
