import { lazy, FC, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import MainLoader from '../../components/UI/MainLoader/MainLoader';
import PublicRoute from '../PublicRoute/PublicRoute';
import Auth from '../../components/Auth/Auth';
// import EmailConfirmation from "../../components/EmailConfirmation";
// import Review from "../../components/Review";

// import PaymentStatus from "../../components/PaymentStatus/PaymentStatus";
// import WaitingList from "../../components/WaitingList/WaitingList";
// import CanceledView from "../../components/Canceled/CanceledView";
// import PoweredBy from "../../components/UI/PoweredBy";

// const PublicRoute = lazy(() => import("../PublicRoute"));
// const ReservationConfirmation = lazy(
//   () => import("../ReservationConfirmation")
// );
// const PaymentCard = lazy(() => import("../../components/PaymentCard"));

const AuthContainer: FC = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    // console.log("Containers Auth.tsx");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<MainLoader />}>
            <PublicRoute>
              <Auth key={pathname.replace('/', '')} />
            </PublicRoute>
          </Suspense>
        }
      />
      {/* <Route
        path="/reservations/:bookingHash"
        element={
          <Suspense fallback={<MainLoader />}>
            <ReservationConfirmation />
            <PoweredBy />
          </Suspense>
        }
      />
      <Route
        path="/waitinglist/:conceptID"
        element={
          <Suspense fallback={<MainLoader />}>
            <WaitingList />
            <PoweredBy />
          </Suspense>
        }
      />
      <Route
        path="/canceled/:bookingID/:conceptID"
        element={
          <Suspense fallback={<MainLoader />}>
            <CanceledView />
            <PoweredBy />
          </Suspense>
        }
      />
      <Route
        path="/payment-card/:token"
        element={
          <Suspense fallback={<MainLoader />}>
            <PaymentCard />
            <PoweredBy />
          </Suspense>
        }
      />
      <Route
        path="/payment-status"
        element={
          <Suspense fallback={<MainLoader />}>
            <PaymentStatus />
            <PoweredBy />
          </Suspense>
        }
      />
      <Route
        path="/email/:bookingID/:conceptID"
        element={
          <Suspense fallback={<MainLoader />}>
            <EmailConfirmation />
            <PoweredBy />
          </Suspense>
        }
      />
      <Route
        path="/review"
        element={
          <Suspense fallback={<MainLoader />}>
            <Review />
            <PoweredBy />
          </Suspense>
        }
      />
      <Route
        path="/reservations"
        element={
          <Suspense fallback={<MainLoader />}>
            <PublicRoute>
              <AuthForm key={pathname.replace("/", "")} />
            </PublicRoute>
          </Suspense>
        }
      /> */}
      <Route
        path=":slug"
        element={
          <Suspense fallback={<MainLoader />}>
            <Auth key={pathname.replace('/', '')} />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default AuthContainer;
