import {
  clearLocalStorageData,
  extractSelectedCheckboxes,
  sessionTimeout,
} from './../helpers/utils';
import { generateClient } from '@aws-amplify/api';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount, listAccounts } from '../graphql/queries';
import { setListing, setNextToken, setSelected } from '../store/ducks/account';
import { Account } from '../models/GQL_API';
import { CreateAccountInput, UpdateAccountInput } from '../models/GQL_API';
import {
  HeadCell,
  Option,
  ListingVariables,
  AccountGetVariables,
  AccountUpdateVariables,
  AccountBulkTrashVariables,
  CreateVariables,
} from '../models/app';
import {
  createAccount,
  deleteAccount,
  updateAccount,
} from '../graphql/mutations';
import useApp from './useApp';
import { onCreateAccount } from '../graphql/subscriptions';
import { AppTypes, appType } from '../constants/enums';

const client = generateClient();

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const url = window.location.hostname;
  const nextToken = useSelector(
    (state: any) => state[`${listingName}`]['nextToken'],
  );

  const accountsListing = useSelector(
    (state: any) => state[`${listingName}`]['listing'],
  );
  async function fetch(params: ListingVariables) {
    const { searchText, limit } = params;

    try {
      const filter: any = {
        deleted: { eq: '0' },
      };

      if (searchText)
        filter.or = {
          domain: { contains: searchText.toLowerCase() },
          siteTitle: { contains: searchText.toLowerCase() },
          siteAddress: { contains: searchText.toLowerCase() },
        };

      const accountList: any = await client.graphql<Account>({
        query: listAccounts,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });

      const currentNextToken = accountList.data.listAccounts.nextToken;
      const responseListing = accountList.data.listAccounts.items;

      let listing = [...accountsListing, ...responseListing];

      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function byDomainOnline(isAuth: boolean) {
    try {
      const offlineAccount = localStorage.getItem('offlineAccount');
      if (
        offlineAccount &&
        !sessionTimeout() &&
        // @ts-ignore
        appType === AppTypes.CASHLESS
      ) {
        let account = JSON.parse(offlineAccount);
        if (account) {
          dispatch(setSelected(account));
          return account;
        }
      } else {
        clearLocalStorageData();

        const listing: any = await client.graphql({
          query: listAccounts,
          variables: {
            limit: 100000,
            filter: {
              siteAddress: { contains: url },
            },
          },
        });
        localStorage.setItem(
          'offlineAccount',
          JSON.stringify(listing.data.listAccounts.items[0]),
        );
        const account = listing.data.listAccounts.items[0];

        if (account) {
          dispatch(setSelected(account));
          return account;
        }
      }
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function get(params: AccountGetVariables) {
    const { id, listing } = params;

    try {
      let single: Account | undefined;
      if (listing.length > 0) {
        single = listing.find((resource: any) => resource.id === id);
      } else {
        const listing: any = await client.graphql<Account>({
          query: getAccount,
          variables: { id },
          authMode: 'userPool',
        });
        single = listing.data.getAccount;
      }

      return single;
    } catch (err: Error | any) {
      showError(
        typeof err.message === 'string' ? err.message : 'Error occurred',
      );
      console.log(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;
    const languages: string[] = extractSelectedCheckboxes('languages_', data);
    const features: string[] = extractSelectedCheckboxes('features_', data);

    try {
      const createInput: CreateAccountInput = {
        domain: data.domain,
        siteTitle: data.siteTitle,
        tagline: data.tagline,
        description: data.description,
        siteAddress: data.siteAddress,
        defaultLanguage: data.defaultLanguage,
        status: 'active',
        languages: languages,
        features: features,
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      await client.graphql<Account>({
        query: createAccount,
        variables: { input: createInput },
        authMode: 'userPool',
      });

      return `New ${singleName} has been created successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function update(params: AccountUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original: any = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      let languages: string[] = [];
      let features: string[] = [];
      if (!data.dynamicStatus) {
        languages = extractSelectedCheckboxes('languages_', data);
        features = extractSelectedCheckboxes('features_', data);
      }

      const updateInput: UpdateAccountInput = {
        id: original.id,
        domain: data.domain ? data.domain : original!.domain,
        description: data.description
          ? data.description
          : original!.description,
        siteAddress: data.siteAddress
          ? data.siteAddress
          : original!.siteAddress,
        defaultLanguage: data.defaultLanguage
          ? data.defaultLanguage
          : original!.defaultLanguage,
        languages: data.dynamicStatus ? original!.languages : languages,
        features: data.dynamicStatus ? original!.features : features,
        _version: original._version,
      };

      await client.graphql<Account>({
        query: updateAccount,
        variables: { input: updateInput },
        authMode: 'userPool',
      });

      return `${singleName} has been updated successfully`;
    } catch (err: Error | any) {
      throw err;
    }
  }

  async function trash(params: AccountGetVariables) {
    try {
      const original = await get(params);

      if (original) {
        const updateInput: UpdateAccountInput = {
          id: original.id,
          deleted: '1',
          _version: original._version,
        };

        await client.graphql<Account>({
          query: updateAccount,
          variables: { input: updateInput },
          authMode: 'userPool',
        });
      }

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === 'string' ? err.message : 'Error occurred',
      );
      console.log(err);
    }
  }

  async function bulkTrash(params: AccountBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: string) => {
      try {
        await trash({ id, listing });
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    return `${ids.size} ${listingName} items has been moved to trash`;
  }

  async function remove(params: AccountGetVariables) {
    const { id, listing } = params;

    try {
      await client.graphql<Account>({
        query: deleteAccount,
        variables: { id: id },
        authMode: 'userPool',
      });

      dispatch(
        setListing(listing.filter((resource: any) => resource.id !== id)),
      );

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(
        typeof err.message === 'string' ? err.message : 'Error occurred',
      );
      console.log(err);
    }
  }

  function options(listing: Account[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.siteTitle, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'siteTitle',
      numeric: false,
      disablePadding: false,
      label: 'Site Title',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  const dataCells: readonly string[] = ['siteTitle'];

  const api: any = {};
  api[`${listingName}CreateSubscription`] = onCreateAccount;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchByDomainOnline`] = byDomainOnline;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ChangeListing`] = (listing: Account[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = accountsListing;
  return api;
};

export default useResource;
