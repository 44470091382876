import { FC } from 'react';
import Box from '@mui/material/Box';

import ContentLoader from '../ContentLoader/ContentLoader';

interface Props {
  position: string;
  minHeight?: string;
  background?: string;
}

const ActionLoader: FC<Props> = ({ position, minHeight, background }) => (
  <Box
    sx={{
      position: position,
      top: 0,
      left: 0,
      zIndex: (theme) => theme.zIndex.drawer + 1,
      background: background ? background : 'unset',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: minHeight ? minHeight : 'unset',
    }}
  >
    <ContentLoader />
  </Box>
);

export default ActionLoader;
