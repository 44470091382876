import {
  CreateVariables,
  InvitationLimitBulkTrashVariables,
  InvitationLimitUpdateVariables,
  Option,
  InvitationLimitGetVariables,
  InvitationLimitListingVariables,
} from './../models/app';
import { GraphQLQuery, generateClient } from 'aws-amplify/api';
import { useDispatch, useSelector } from 'react-redux';
import { HeadCell } from '../models/dataTable';
import useApp from './useApp';
import {
  InvitationLimit,
  ModelInvitationLimitFilterInput,
  UpdateInvitationLimitInput,
} from '../models/GQL_API';
import { CreateInvitationLimitInput } from '../models/GQL_API';
import { onCreateInvitationLimit } from '../graphql/subscriptions';
import { getInvitationLimit, listInvitationLimits } from '../graphql/queries';
import {
  createInvitationLimit,
  deleteInvitationLimit,
  updateInvitationLimit,
} from '../graphql/mutations';
import { setListing, setNextToken } from '../store/ducks/invitationLimits';

let client = generateClient();
const useInvitationLimit = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();

  const nextToken = useSelector(
    (state: any) => state[`invitationLimits`][`nextToken`],
  );

  const transactionsListing = useSelector(
    (state: any) => state[`invitationLimits`][`listing`],
  );

  async function fetch(params: InvitationLimitListingVariables) {
    const { generalFilters, limit } = params;

    try {
      const filter: ModelInvitationLimitFilterInput = {
        deleted: { eq: '0' },
      };
      if (generalFilters?.event) {
        filter.eventID = { eq: generalFilters.event };
      }

      const groupsList = await client.graphql({
        query: listInvitationLimits,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });
      const currentNextToken = groupsList.data.listInvitationLimits.nextToken;
      const responseListing = groupsList.data.listInvitationLimits.items;

      let listing = [...transactionsListing, ...responseListing];
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));

      return listing;
    } catch (err: Error | any) {
      showError(err);
    }
  }
  async function fetchAll(params: InvitationLimitListingVariables) {
    const { eventID } = params;

    try {
      const filter: ModelInvitationLimitFilterInput = {
        deleted: { eq: '0' },
      };
      if (eventID) {
        filter.eventID = { eq: eventID };
      }

      const groupsList = await client.graphql({
        query: listInvitationLimits,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });
      const responseListing = groupsList.data.listInvitationLimits.items;

      return responseListing;
    } catch (err: Error | any) {
      showError(err);
    }
  }

  async function fetchCurrentInvites(params: InvitationLimitListingVariables) {
    const { eventID, adminID } = params;

    try {
      const filter: ModelInvitationLimitFilterInput = {
        deleted: { eq: '0' },
        eventID: { eq: eventID },
        adminID: { eq: adminID },
      };

      const listing = await client.graphql({
        query: listInvitationLimits,
        variables: { filter, limit: 100000 },
        authMode: 'userPool',
      });
      // dispatch(setListing(listing));
      return listing.data.listInvitationLimits.items;
    } catch (err: Error | any) {
      showError(err);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */

  async function get(params: InvitationLimitGetVariables) {
    const { id, listing } = params;

    try {
      if (id !== undefined) {
        const single = await client.graphql({
          query: getInvitationLimit,
          variables: { id },
          authMode: 'userPool',
        });
        return single.data.getInvitationLimit;
      }
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    if (!data.eventID) {
      const error = new Error(`Cannot create ${singleName} without eventID`);
      return showError(error);
    }

    try {
      const createInput: CreateInvitationLimitInput = {
        adminID: data.adminID,
        quota: data.quota,
        eventID: data.eventID,
        totalQuota: data.totalQuota,
        deleted: '0',
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      await client.graphql({
        query: createInvitationLimit,
        variables: { input: createInput },
        authMode: 'userPool',
      });

      // showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      // this is special case for events and event tickets only
      // showError(err);
      throw err;
    }
  }

  // async function update(params: InvitationLimitUpdateVariables) {
  //   const { id, listing, data } = params;
  //   try {
  //     const original = await get({ id, listing });

  //     if (!original) {
  //       showError(`Invalid ${singleName} ID`);
  //       return;
  //     }

  //     // const updatedSentInvitations = (original.sentInvitations || []).map(invitation => {
  //     //   const { __typename, ...invitationWithoutTypename } = invitation as {
  //     //     __typename: any;
  //     //   };
  //     //   return invitationWithoutTypename;
  //     // });

  //     // console.log(data);

  //     const updatedSentInvitations = (original.sentInvitations || []).map(
  //       (invitation) => {
  //         if (invitation?.hasOwnProperty('__typename')) {
  //           const { __typename, ...invitationWithoutTypename } = invitation as {
  //             [key: string]: any;
  //           };
  //           return invitationWithoutTypename;
  //         }
  //         return invitation;
  //       },
  //     );

  //     const updatedSentInvitation = [
  //       ...updatedSentInvitations
  //     ];
  //     if (data.sentInvitations) {
  //       updatedSentInvitation.push(data.sentInvitations);
  //     }

  //     console.log(data.sentInvitations);

  //     let updateInput: UpdateInvitationLimitInput = {
  //       id: original.id,
  //       adminID: data.adminID ? data.adminID : original.adminID,
  //       quota: data.totalQuota ? data.totalQuota : data.currentRemainingQuota,
  //       eventID: data.eventID ? data.eventID : original.eventID,
  //       totalQuota: data.totalQuota ? data.totalQuota : original.totalQuota,
  //       sentInvitations: updatedSentInvitation
  //     };
  //     await client.graphql({
  //       query: updateInvitationLimit,
  //       variables: { input: updateInput },
  //       authMode: 'userPool',
  //     });
  //     showConfirm(`${singleName} has been updated successfully`);
  //   } catch (err) {
  //     // this is special case for events and event tickets only
  //     // showError(err);
  //     throw err;
  //   }
  // }

  async function update(params: InvitationLimitUpdateVariables) {
    const { id, listing, data } = params;

    try {
      const original = await get({ id, listing });

      if (!original) {
        showError(`Invalid ${singleName} ID`);
        return;
      }

      const updatedSentInvitations = (original.sentInvitations || []).map(
        (invitation) => {
          if (invitation?.hasOwnProperty('__typename')) {
            const { __typename, ...invitationWithoutTypename } = invitation as {
              [key: string]: any;
            };
            return invitationWithoutTypename;
          }
          return invitation;
        },
      );

      let updateInput: UpdateInvitationLimitInput = {
        id: original.id,
        adminID: data.adminID ? data.adminID : original.adminID,
        // quota: data.totalQuota ? data.totalQuota : data.currentRemainingQuota,
        quota: data.currentRemainingQuota
          ? data.currentRemainingQuota
          : original?.quota,
        eventID: data.eventID ? data.eventID : original.eventID,
        totalQuota: data.totalQuota ? data.totalQuota : original.totalQuota,
        _version: original._version,
      };

      if (
        data.sentInvitations !== null &&
        data?.sentInvitations?.toString() !==
          original?.sentInvitations?.toString()
      ) {
        // console.log(data.sentInvitations !== null);
        // console.log(
        //   data?.sentInvitations?.toString() !==
        //     original?.sentInvitations?.toString(),
        // );
        // console.log(data?.sentInvitations?.toString());
        // console.log(original?.sentInvitations?.toString());

        // Add sentInvitations property if data.sentInvitations is not null
        const updatedSentInvitation = [
          ...updatedSentInvitations,
          data.sentInvitations,
        ];
        updateInput.sentInvitations = updatedSentInvitation;
        // console.log(updatedSentInvitation);
      }

      await client.graphql({
        query: updateInvitationLimit,
        variables: { input: updateInput },
        authMode: 'userPool',
      });
      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      throw err;
    }
  }

  async function trash(params: InvitationLimitGetVariables) {
    try {
      const original = await get(params);
      const { id, listing } = params;

      if (!original) {
        // showError(`Invalid ${singleName} ID`);
        return;
      }

      await client.graphql({
        query: updateInvitationLimit,
        variables: {
          input: { id: original.id, deleted: '1', _version: original._version },
        },
        authMode: 'userPool',
      });

      // dispatch(setListing(listing.filter((model: any) => model.id !== id)));
      // showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
      console.log('Error while moving limit to trash');
    }
  }

  async function bulkTrash(params: InvitationLimitBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    //   dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: InvitationLimitGetVariables) {
    const { id, listing } = params;

    try {
      // await DataStore.delete(id as any);

      await client.graphql({
        query: deleteInvitationLimit,
        variables: { input: { id } },
        authMode: 'userPool',
      });

      // dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'adminID',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'quota',
      numeric: false,
      disablePadding: false,
      label: 'Remaining Quota',
    },
    {
      id: 'totalQuota',
      numeric: false,
      disablePadding: false,
      label: 'Total Quota',
    },
    {
      id: 'createdBy',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Created At',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: '',
    },
  ];

  const dataCells: readonly string[] = ['adminID', 'quota', 'totalQuota'];

  const api: any = {};

  api[`${listingName}CreateSubscription`] = onCreateInvitationLimit;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchCurrent`] = fetchCurrentInvites;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = transactionsListing;
  return api;
};

export default useInvitationLimit;
