import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import StopIcon from '@mui/icons-material/Stop';
import TuneIcon from '@mui/icons-material/Tune';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import {
  Backdrop,
  Box,
  Button,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CashlessTypeList,
  DATE_PICKER_TYPES,
  Orders,
  Pages,
} from '../../constants/enums';
import {
  capitalizeSentence,
  exportXLSX,
  formatDate,
  getComparator,
  renderPickedDates,
  stableSort,
} from '../../helpers/utils';
import useApp from '../../hooks/useApp';
import useCashlessTransaction from '../../hooks/useCashlessTransaction';
import useDateRangePicker from '../../hooks/useDateRangePicker';
import useEvent from '../../hooks/useEvent';
import { Event as EventInterface } from '../../models/GQL_API';
import {
  CashlessTransactionListingVariables,
  CashlessTransactionStatisticsByEventParams,
  CashlessTransactionStatisticsByEventResult,
  DatePickerParams,
  DateRangeProps,
  EventListingVariables,
  GeneralFilters,
  Order,
} from '../../models/app';
import CashlessTransactionsRows from '../Dashboard/Listing/GuestProfile/CashlessTransactionsRows';
import ContentLoader from '../UI/ContentLoader/ContentLoader';
import DateRangePicker from '../UI/DateRangePicker/DateRangePicker';
import LightBox from '../UI/LightBox';
import Section from '../UI/Section';
import useAdmin from '../../hooks/useAdmin';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

const params: DatePickerParams = {
  selection: DATE_PICKER_TYPES.RANGE,
};

export default function TransactionsAllListing() {
  type StateProps = {
    searchText: string;
    bulkActions: string;
    isDeleting: Boolean;
    order: Order;
    orderBy: keyof any;
    isCreating: Boolean;
    open: boolean;
  };

  const initialState: StateProps = {
    searchText: '',
    bulkActions: '-1',
    isDeleting: false,
    order: Orders.DSC,
    orderBy: 'createdAt',
    isCreating: false,
    open: false,
  };

  const cashlessTransactionsListing = useSelector(
    (state: any) => state.cashlessTransactions.listing,
  );

  const {
    cashlessTransactionsFetchSortedByDate,
    cashlessTransactionsFetchAllSortedByDate,
    cashlessTransactionsHeadCells,
    cashlessTransactionsDataCells,
    cashlessTransactionsChangeListing,
    cashlessTransactionsFetchStatisticsByEvent,
  } = useCashlessTransaction('cashlessTransactions', 'cashlessTransaction');
  const { eventsFetchAll } = useEvent('events', 'event');
  const { adminsFetchByGroup, adminsChangeSelected } = useAdmin(
    'admins',
    'admin',
  );
  const extendedHeadCells = [...cashlessTransactionsHeadCells];
  const [searchValue, setSearchValue] = useState('');
  const [searchValueNotFinished, setSearchValueNotFinished] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [nextToken, setNextToken] = useState('');
  const [prevNextToken, setPrevNextToken] = useState('');
  const itemsPerPage = 50;
  const [state, setState] = useState(initialState);
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [initialLoadingDone, setInitialLoadingDone] = useState(false);
  const [eventSelected, setEventSelected] = React.useState('');
  const [adminsSelected, setAdminsSelected] = React.useState<string>('');
  const [bookedTicketTypeSelected, setBookedTicketTypeSelected] =
    useState<string>('');
  const [statisticsResult, setStatisticsResult] =
    useState<CashlessTransactionStatisticsByEventResult>({
      totalRevenue: 0,
    });
  const [statisticsLoading, setStatisticsLoading] = useState(false);
  const [admins, setAdmins] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [shouldFetchNextPage, setShouldFetchNextPage] = useState(false);
  const [stopQuery, setStopQuery] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [eventSelectedForFilter, setEventSelectedForFilter] =
    React.useState('');
  const [paymentChannelSelected, setPaymentChannelSelected] =
    React.useState('');
  const [paymentChannelSelectedForFilter, setPaymentChannelSelectedForFilter] =
    React.useState('');
  const [adminSelectedFilter, setAdminSelectedFilter] = React.useState('');
  const [bookedTicketTypeSelectedFilter, setBookedTicketTypeSelectedFilter] =
    React.useState('');
  const { order, orderBy } = state;
  const { setGeneralFilters, clearGeneralFilters, changeDateRange } = useApp();
  const generalFilters: GeneralFilters = useSelector(
    (state: any) => state.generalFilters,
  );
  const session = useSelector((state: any) => state.app.session);

  const {
    open,
    dateRange,
    shouldSync,
    syncDateRange,
    handleChange,
    handleClose,
    handleOpen,
  } = useDateRangePicker(params);

  const handleSearchInputChange = (value: string) => {
    setSearchValueNotFinished(value);
  };

  const handleEventSelectChange = (event: SelectChangeEvent) => {
    setEventSelected(event.target.value as string);
  };

  const handleAdminsSelectChange = (event: SelectChangeEvent) => {
    const adminID = event.target.value as string;
    setAdminsSelected(adminID);
  };

  const handleBookedTicketTypeSelectChange = (event: SelectChangeEvent) => {
    setBookedTicketTypeSelected(event.target.value as string);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = cashlessTransactionsListing?.slice(
      indexOfFirstItem,
      indexOfLastItem,
    );
    if (!currentItems?.length) {
      setShouldFetchNextPage(true);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleOpenTransactionsModal = () => {
    setIsTransactionModalOpen(true);
  };

  const handleCloseTransactionsModal = () => {
    setIsTransactionModalOpen(false);
  };

  const handleExport = async () => {
    setIsExporting(true);
    let data = cashlessTransactionsListing;
    if (generalFilters?.fromDate && generalFilters.toDate) {
      setIsFetching(true);
      const Params: CashlessTransactionListingVariables = {
        searchText: searchValue,
        startIndex: 0,
        limit: 200,
        generalFilters,
        guestId: '',
        eventFilter: eventSelectedForFilter,
        adminIdFilter: adminSelectedFilter,
        bookedTicketTypeFilter: bookedTicketTypeSelectedFilter,
      };
      data = await cashlessTransactionsFetchAllSortedByDate(Params);
      setIsFetching(false);
    }
    exportXLSX({
      slug: Pages.CASHLESS_ALL_Transactions,
      headCells: cashlessTransactionsHeadCells,
      data,
      rowKeys: cashlessTransactionsDataCells,
      selected: undefined,
      fromDate: generalFilters.fromDate,
      toDate: generalFilters.toDate,
      statistics: statisticsResult,
    });
    setIsExporting(false);
  };

  const handleClearCashlessFilters = () => {
    clearGeneralFilters();
    setEventSelectedForFilter('');
    setAdminSelectedFilter('');
    setBookedTicketTypeSelectedFilter('');
    setPaymentChannelSelectedForFilter('');
    setEventSelected('');
    setPaymentChannelSelected('');
    const initialRange: DateRangeProps = {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    };
    changeDateRange(initialRange);
  };

  const handleStopQuery = () => {
    setNextToken('');
    setLoadingMore(false);
    setInitialLoadingDone(true);
    setIsFetching(false);
    setStopQuery(true);
  };

  const handleClearSearch = () => {
    setSearchValueNotFinished('');
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cashlessTransactionsListing?.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const [eventsListing, setEventsListing] = useState<EventInterface[]>([]);
  const createSortHandler =
    (property: keyof any) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof any,
  ) => {
    const isAsc = orderBy === property && order === Orders.ASC;

    setState({
      ...state,
      order: isAsc ? Orders.DSC : Orders.ASC,
      orderBy: property,
    });
  };

  const fetchCashlessTransactions = async (filtersChanged: boolean) => {
    const Params: CashlessTransactionListingVariables = {
      searchText: searchValue,
      startIndex: 0,
      limit: 200,
      generalFilters,
      guestId: '',
      eventFilter: eventSelectedForFilter,
      adminIdFilter: adminSelectedFilter,
      bookedTicketTypeFilter: bookedTicketTypeSelectedFilter,
    };
    if (!filtersChanged) {
      Params.nextToken = nextToken;
    }
    const response: any = await cashlessTransactionsFetchSortedByDate(Params);
    const currentNextToken = response.nextToken;
    const listing = response.listing;
    if (!isFetching && stopQuery) return;
    setPrevNextToken(nextToken);
    setNextToken(currentNextToken);
    if (!listing?.length && !currentNextToken) {
      setIsFetching(false);
      setLoadingMore(false);
    }
    if (!currentNextToken) {
      setIsFetching(false);
      setLoadingMore(false);
    }
    if (!initialLoadingDone || filtersChanged) {
      cashlessTransactionsChangeListing(listing);
    } else {
      cashlessTransactionsChangeListing([
        ...cashlessTransactionsListing,
        ...listing,
      ]);
    }
    setInitialLoadingDone(true);
  };

  useEffect(() => {
    if (
      !nextToken ||
      currentItems.length >= itemsPerPage ||
      nextToken === prevNextToken
    ) {
      setLoadingMore(false);
      setIsFetching(false);
      return;
    }
    if (!initialLoadingDone) {
      setLoadingMore(false);
      setIsFetching(false);
      return;
    }
    if (!nextToken?.length) {
      setLoadingMore(false);
      setIsFetching(false);
      return;
    }
    if (currentItems.length >= itemsPerPage) {
      setLoadingMore(false);
      setIsFetching(false);
      return;
    }
    if (stopQuery) {
      setLoadingMore(false);
      setIsFetching(false);
      return;
    }
    setLoadingMore(true);
    fetchCashlessTransactions(false);
  }, [nextToken]);

  useEffect(() => {
    if (!shouldFetchNextPage) return;
    setLoading(true);
    setIsFetching(true);
    fetchCashlessTransactions(false);
    setShouldFetchNextPage(false);
    setLoading(false);
  }, [shouldFetchNextPage]);

  const fetchEvents = async () => {
    const eventsParams: EventListingVariables = {
      searchText: '',
      startIndex: 0,
      limit: 10000,
    };
    let events = await eventsFetchAll(eventsParams);
    setEventsListing(events);
  };

  const fetchData = async (filtersChanged: boolean) => {
    setLoading(true);
    await Promise.all([
      fetchCashlessTransactions(filtersChanged),
      fetchEvents(),
    ]);
    setInitialLoadingDone(true);
    setShouldFetchData(false);
    setLoading(false);
  };

  useEffect(() => {
    setIsFetching(true);
    setStopQuery(false);
    setInitialLoadingDone(false);
    setShouldFetchData(true);
    setCurrentPage(1);
  }, [
    searchValue,
    eventSelectedForFilter,
    generalFilters,
    paymentChannelSelectedForFilter,
    adminSelectedFilter,
    bookedTicketTypeSelectedFilter,
  ]);

  useEffect(() => {
    if (!shouldFetchData) return;
    fetchData(true);
  }, [shouldFetchData]);

  useEffect(() => {
    let typingTimer: any;
    const handleTimeout = () => {
      setSearchValue(searchValueNotFinished);
    };
    clearTimeout(typingTimer);
    typingTimer = setTimeout(handleTimeout, 500);
    return () => {
      clearTimeout(typingTimer);
    };
  }, [searchValueNotFinished]);

  useEffect(() => {
    async function getAdmins() {
      try {
        const fetchedAdmins = await adminsFetchByGroup(
          '',
          10000,
          'admin',
          '',
          true,
        );
        const currentAdmin = fetchedAdmins.find(
          (admin) => admin.id === session.sub,
        );
        // console.log({ currentAdmin });
        if (currentAdmin) {
          adminsChangeSelected(currentAdmin);
        } else {
          adminsChangeSelected(fetchedAdmins[0]);
        }
        setAdmins(fetchedAdmins);
      } catch (error) {
        console.error('Error fetching admins:', error);
      }
    }
    getAdmins();
  }, []);

  const fetchStatisticsByEvent = async (eventId: string, adminId: string) => {
    try {
      setStatisticsLoading(true);
      const params: CashlessTransactionStatisticsByEventParams = {
        eventId,
        adminId,
      };
      const statisticsByEvent: CashlessTransactionStatisticsByEventResult =
        await cashlessTransactionsFetchStatisticsByEvent(params);
      setStatisticsResult(statisticsByEvent);
      setStatisticsLoading(false);
    } catch (err) {
      setStatisticsLoading(false);
      console.log({ err });
    }
  };

  useEffect(() => {
    fetchStatisticsByEvent(eventSelectedForFilter, adminSelectedFilter);
  }, [eventSelectedForFilter, adminSelectedFilter]);

  return (
    <>
      <Modal
        open={isTransactionModalOpen}
        onClose={handleCloseTransactionsModal}
        aria-labelledby="modal1-title"
        aria-describedby="modal1-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            height: '85vh',
            maxHeight: '100%',
            width: open ? '65rem' : 800,
            // maxWidth: 600,
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <Section
            label="Filter"
            startIcon={<TurnedInNotIcon />}
            endIcon={
              <>
                <CancelOutlinedIcon
                  onClick={handleCloseTransactionsModal}
                  sx={{ cursor: 'pointer' }}
                />
              </>
            }
            sx={{
              backgroundColor: 'secondary.light',
            }}
          >
            <LightBox maxHeight="550px" scroll>
              <Box
                sx={{
                  ml: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5,
                }}
              >
                <Typography>Period</Typography>
                <Button
                  variant="text"
                  fullWidth
                  startIcon={
                    <DateRangeIcon
                      fontSize="small"
                      sx={{ color: 'primary.main' }}
                    />
                  }
                  sx={{
                    display: 'flex',
                    padding: '0px 15px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                    height: '42px',
                    flexShrink: '0',
                    borderRadius: '4px',
                    background: '#1E1E1E',
                    maxWidth: '40%',
                  }}
                  onClick={handleOpen}
                >
                  {renderPickedDates(
                    dateRange[0]?.startDate!,
                    dateRange[0]?.endDate!,
                    true,
                  )}
                </Button>
                <Backdrop
                  sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    alignItems: 'flex-start',
                  }}
                  open={open}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      inset: 0,
                      zIndex: 0,
                      cursor: 'pointer',
                    }}
                    onClick={handleClose}
                  />
                  <DateRangePicker
                    key={`date-range-picker-${open}`}
                    title={'title'}
                    selection={DATE_PICKER_TYPES.RANGE}
                    shouldSync={shouldSync}
                    dateRange={syncDateRange}
                    handleClose={handleClose}
                    handleChange={handleChange}
                  />
                </Backdrop>
              </Box>

              <Box
                sx={{
                  ml: 3,
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5,
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: '15px', ml: 1 }}>
                    Event Name
                  </Typography>
                  <Box>
                    <FormControl
                      sx={{
                        mt: 2,
                        width: '16rem',
                        borderRadius: '10px',
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Event
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={eventSelected}
                        label="Event"
                        onChange={handleEventSelectChange}
                        sx={{
                          borderRadius: '10px',
                        }}
                      >
                        {eventsListing.map((event: EventInterface) => (
                          <MenuItem key={event.id} value={event.id}>
                            {capitalizeSentence(event.name ?? '')}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  ml: 3,
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5,
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: '15px', ml: 1 }}>
                    Admin
                  </Typography>
                  <Box>
                    <FormControl
                      sx={{
                        mt: 2,
                        width: '16rem',
                        borderRadius: '10px',
                      }}
                    >
                      <InputLabel id={`admins filter modal`}>Admin</InputLabel>
                      <Select
                        labelId={`admins filter modal`}
                        id={`admins filter modal`}
                        label="Admin"
                        onChange={handleAdminsSelectChange}
                        value={adminsSelected}
                      >
                        {admins?.map((admin) => (
                          <MenuItem key={admin.id} value={admin.id}>
                            {admin.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  ml: 3,
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5,
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: '15px', ml: 1 }}>Type</Typography>
                  <Box>
                    <FormControl
                      sx={{
                        mt: 2,
                        width: '16rem',
                        borderRadius: '10px',
                      }}
                    >
                      <InputLabel id={`booked ticket type`}>Type</InputLabel>
                      <Select
                        labelId={`booked ticket type`}
                        id={`booked ticket type`}
                        label="Admin"
                        onChange={handleBookedTicketTypeSelectChange}
                        value={bookedTicketTypeSelected}
                      >
                        {CashlessTypeList?.map((bookedTicketType) => (
                          <MenuItem
                            key={bookedTicketType}
                            value={bookedTicketType}
                          >
                            {bookedTicketType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Button
                  onClick={() => {
                    changeDateRange(dateRange);
                    setGeneralFilters(
                      'fromDate',
                      formatDate(dateRange[0].startDate),
                    );
                    setGeneralFilters(
                      'toDate',
                      formatDate(dateRange[0].endDate),
                    );
                    setPrevNextToken(nextToken);
                    handleCloseTransactionsModal();
                    setEventSelectedForFilter(eventSelected);
                    setAdminSelectedFilter(adminsSelected);
                    setBookedTicketTypeSelectedFilter(bookedTicketTypeSelected);
                    setPaymentChannelSelectedForFilter(paymentChannelSelected);
                  }}
                  startIcon={<FilterAltIcon />}
                  variant="contained"
                  color="error"
                  sx={{
                    py: 0,
                    borderRadius: '5px',
                  }}
                >
                  <div style={{ display: 'flex', gap: '3px' }}>
                    <p style={{ textTransform: 'capitalize' }}>Apply</p>
                    <p style={{ textTransform: 'capitalize' }}>Filters</p>
                  </div>
                </Button>
              </Box>
            </LightBox>
          </Section>
        </Box>
      </Modal>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            p: '3px 8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1,
            backgroundColor: 'secondary.main',
            borderRadius: '12px',
            maxWidth: '50%',
            ml: 2,
          }}
        >
          <SearchIcon
            fontSize="small"
            sx={{ color: 'info.600', fontSize: '25px' }}
          />
          <InputBase
            sx={{ ml: 1, flex: 1, py: 0.75 }}
            inputProps={{ 'aria-label': 'search resource' }}
            placeholder={'Search'}
            value={searchValueNotFinished}
            disabled={isFetching}
            onChange={(e) => handleSearchInputChange(e.target.value)}
          />
          {!isFetching && (
            <CloseIcon
              fontSize="small"
              sx={{ cursor: 'pointer' }}
              onClick={handleClearSearch}
            />
          )}
        </Paper>
        <Box
          sx={{
            backgroundColor: 'secondary.dark',
            borderRadius: '30px',
            border: '1px solid secondary.light',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'primary.main',
            height: '2vh',
            p: 3,
            gap: 1,
            cursor: 'pointer',
            pointerEvents: isFetching ? 'none' : 'auto',
            opacity: isFetching ? '0.5' : '1',
          }}
          onClick={handleOpenTransactionsModal}
        >
          <TuneIcon />
          <Typography>Filter</Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: 'secondary.dark',
            borderRadius: '30px',
            border: '1px solid secondary.light',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'primary.main',
            height: '2vh',
            p: 3,
            gap: 1,
            cursor: 'pointer',
            pointerEvents: isFetching ? 'none' : 'auto',
            opacity: isFetching ? '0.5' : '1',
          }}
          onClick={handleClearCashlessFilters}
        >
          <TuneIcon />
          <Typography>Clear Filter</Typography>
        </Box>
        {isFetching && !isExporting && (
          <Box
            sx={{
              backgroundColor: 'transparent',
              borderRadius: '30px',
              border: '1px solid secondary.light',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'primary.main',
              height: '2vh',
              p: 3,
              gap: 1,
              cursor: 'pointer',
            }}
            onClick={handleStopQuery}
          >
            <StopIcon sx={{ color: 'red' }} />
            <Typography>Stop</Typography>
          </Box>
        )}
        <Box
          sx={{
            backgroundColor: 'secondary.dark',
            borderRadius: '30px',
            border: '1px solid secondary.light',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'primary.main',
            height: '2vh',
            p: 3,
            gap: 1,
            cursor: 'pointer',
            pointerEvents: isFetching || isExporting ? 'none' : 'auto',
            opacity: isFetching || isExporting ? '0.5' : '1',
          }}
          onClick={handleExport}
        >
          <ContentPasteGoIcon
            fontSize="small"
            sx={{
              marginRight: '5px',
              fontSize: '25px',
              textDecoration: 'none',
            }}
          ></ContentPasteGoIcon>
          Export
        </Box>
      </Box>

      {statisticsLoading && (
        <Box
          sx={{
            width: '100%',
            height: '5vh',
            mt: 3,
            mx: 3,
            mb: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          <ContentLoader />
        </Box>
      )}

      {eventSelectedForFilter && !statisticsLoading && (
        <Box
          sx={{
            width: '100%',
            height: '5vh',
            mt: 3,
            mx: 3,
            mb: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              minWidth: { xs: '80%', sm: '23%' },
              backgroundColor: 'secondary.main',
              border: '1px solid',
              borderColor: 'secondary.light',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
              flexWrap: 'wrap',
            }}
          >
            <Box>
              <Typography
                sx={{ color: 'grey', fontWeight: 'bold', fontSize: '15px' }}
              >
                Total Revenue
              </Typography>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  display: 'flex',
                  alignItems: 'end',
                  gap: 0.5,
                }}
              >
                {statisticsResult.totalRevenue}
                <span
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '15px',
                  }}
                >
                  EGP
                </span>
              </Typography>
            </Box>
            <PaidOutlinedIcon
              sx={{
                backgroundColor: '#252839',
                borderRadius: '5px',
                color: '#8988B8',
              }}
            />
          </Box>
        </Box>
      )}

      {loading ? (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'100vh'}
        >
          <ContentLoader />
        </Box>
      ) : (
        <Box
          sx={{
            px: 2,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 3,
          }}
        >
          {!cashlessTransactionsListing?.length ? (
            <Typography variant="h6" color="textSecondary">
              No Data Found
            </Typography>
          ) : (
            <>
              <TableContainer sx={{ maxHeight: '550px' }}>
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: 'primary.light',
                      borderRadius: '5px',
                    }}
                  >
                    <TableRow>
                      {extendedHeadCells.map((headCell: any, index: number) => {
                        return index < extendedHeadCells.length ? (
                          <TableCell
                            key={headCell.id}
                            padding={
                              headCell.disablePadding ? 'none' : 'normal'
                            }
                          >
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={
                                orderBy === headCell.id ? order : 'asc'
                              }
                              onClick={createSortHandler(headCell.id)}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          </TableCell>
                        ) : null;
                      })}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {stableSort(
                      cashlessTransactionsListing,
                      getComparator(order, orderBy),
                    )
                      .slice(indexOfFirstItem, indexOfLastItem)
                      .map((row: any) => (
                        <CashlessTransactionsRows key={row.id} row={row} />
                      ))}
                  </TableBody>
                </Table>
                {loadingMore && (
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    m={2}
                  >
                    <ContentLoader />
                  </Box>
                )}
              </TableContainer>

              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography component="span">Page {currentPage}</Typography>

                <Box sx={{ mt: 1 }}>
                  <IconButton
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    aria-label="previous-page"
                    sx={{ fontSize: '15px' }}
                  >
                    Prev
                  </IconButton>

                  <IconButton
                    onClick={handleNextPage}
                    disabled={
                      (indexOfLastItem >= cashlessTransactionsListing.length &&
                        !nextToken) ||
                      loading ||
                      isFetching
                    }
                    aria-label="next-page"
                    sx={{ fontSize: '15px' }}
                  >
                    Next
                  </IconButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
}
