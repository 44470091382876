import * as Yup from 'yup';
import { Pages } from '../constants/enums';

const useFormValidations = (slug: string, action: string) => {
  const phoneRegExp =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
  const loginValidation = () =>
    Yup.object().shape({
      email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(40, 'Password must not exceed 40 characters'),
    });

  const bookingConfirmationValidation = () =>
    Yup.object().shape({
      email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
    });

  const nameValidation = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
    });

  const typeValidation = () =>
    Yup.object().shape({
      type: Yup.string().required('Type is required'),
    });

  const accountValidation = () =>
    Yup.object().shape({
      domain: Yup.string().required('Domain is required'),
      siteTitle: Yup.string().required('Site Title is required'),
      tagline: Yup.string().required('Tagline is required'),
      description: Yup.string().required('Description is required'),
      siteAddress: Yup.string().required('Site Address is required'),
    });

  const languageValidation = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      code: Yup.string().required('Code is required'),
    });

  const featureValidation = () =>
    Yup.object().shape({
      precedence: Yup.string().required('Precedence is required'),
      name: Yup.string().required('Name is required'),
      slug: Yup.string().required('URL is required'),
    });

  const socialLinkValidation = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      icon: Yup.string().required('Icon is required'),
      slug: Yup.string().required('URL is required'),
    });

  const statusValidation = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      category: Yup.string().required('Category is required'),
    });

  const groupSettingValidation = () =>
    Yup.object().shape({
      groupID: Yup.string().required('Group is required'),
      statusID: Yup.string().required('Status is required'),
    });

  const usersValidation = () =>
    Yup.object().shape({
      phone_number: Yup.string()
        .matches(/^(010|011|012|015)\d{8}$/, 'Phone number is not valid')
        .required('Phone number is required'),
      name: Yup.string().required('name is required'),
      email: Yup.string()
        .email('Email is invalid')
        .required('Email is required'),
      gender: Yup.string().required('gender is required'),
      birthdate: Yup.date()
        .max(new Date(), "birth date field can't be in the future")
        .typeError('birth date is required'),
    });

  const tableValidation = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      label: Yup.string().required('Label is required'),
      width: Yup.string().required('Width is required'),
      height: Yup.string().required('Height is required'),
    });

  const adminRolesValidation = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
    });
  const adminGroupsValidation = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
    });

  const planItemValidation = () =>
    Yup.object().shape({
      areaID: Yup.string().required('Area is required'),
      xPosition: Yup.string().required('X Coordinates is required'),
      yPosition: Yup.string().required('Y Coordinates is required'),
      name: Yup.string().required('Name is required'),
    });

  const paymentStatValidation = () =>
    Yup.object().shape({
      accountID: Yup.string().required('Account ID is required'),
      year: Yup.string().required('Year is required'),
      month: Yup.string().required('Month is required'),
      earning: Yup.string().required('Earning value is required'),
      refund: Yup.string().required('Refund value is required'),
      earningTrxCount: Yup.string().required('Earning Trx Count is required'),
      refundTrxCount: Yup.string().required('Refund Trx Count is required'),
    });

  const bookingGuestValidation = () =>
    Yup.object().shape({
      bookingID: Yup.string().required('Booking ID is required'),
      transactionID: Yup.string().required('Transaction ID is required'),
      guestName: Yup.string().required('Guest Name is required'),
    });

  const categoryValidation = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
    });

  const getValidationSchema = () => {
    switch (slug) {
      case Pages.LOGIN:
        return loginValidation();
      case Pages.ACCOUNTS:
        return accountValidation();
      // case Pages.AREAS:
      //   return nameValidation();
      // case Pages.CONCEPTS:
      //   return nameValidation();
      // case Pages.Parent_Concepts:
      //   return typeValidation();
      case Pages.FEATURES:
        return featureValidation();
      case Pages.LANGUAGES:
        return languageValidation();
      // case Pages.SOCIAL_LINKS:
      //   return socialLinkValidation();
      // case Pages.GROUPS:
      //   return nameValidation();
      // case Pages.RESERVATION_STATUS:
      //   return statusValidation();
      case Pages.FLAGS:
        return nameValidation();
      // case Pages.INTERESTS:
      //   return nameValidation();
      // case Pages.GROUP_SETTINGS:
      //   return groupSettingValidation();
      case Pages.GUESTS:
        return usersValidation();
      // case Pages.TABLES:
      //   return tableValidation();
      // case Pages.PLAN_ITEMS:
      //   return planItemValidation();
      // case Pages.TIME_SLOTS:
      //   return nameValidation();
      // case Pages.BOOKING_CONFIRMATION:
      //   return bookingConfirmationValidation();
      // case Pages.ADMIN_ROLES:
      //   return adminRolesValidation();
      // case Pages.ADMIN_GROUPS:
      //   return adminGroupsValidation();
      // case Pages.PAYMENT_STATS:
      //   return paymentStatValidation();
      // case Pages.BOOKING_GUEST:
      //   return bookingGuestValidation();
      // case Pages.CATEGORIES:
      //   return categoryValidation();
      default:
        return Yup.object();
    }
  };

  return {
    getValidationSchema,
  };
};

export default useFormValidations;
