// actions
const SET = 'languages/SET';
const SET_SELECTED = 'languages/SET_SELECTED';

const DEFAULT_STATE = {
  listing: [],
  selected: '',
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, {
        conceptsSelected: action.concept,
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (single: any) => ({ single, type: SET_SELECTED });
