import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import ForestIcon from '@mui/icons-material/Forest';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DeckIcon from '@mui/icons-material/Deck';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import GroupsIcon from '@mui/icons-material/Groups';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import Man4Icon from '@mui/icons-material/Man4';
import PaymentIcon from '@mui/icons-material/Payment';
import useAdmin from '../../hooks/useAdmin';
import { NavLink } from 'react-router-dom';
import ExpansionPanel from '../UI/ExpansionPanel';
import { SyntheticEvent, useEffect } from 'react';
import SubMenuLink from '../SubMenuLink';
import { useSelector } from 'react-redux';
import { getComparator, stableSort } from '../../helpers/utils';
import { FeaturesNames, Orders } from '../../constants/enums';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import HomeIcon from '@mui/icons-material/Home';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import FestivalIcon from '@mui/icons-material/Festival';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import useNetwork from '../../hooks/useNetwork';

interface Props {
  feature: any;
  open: boolean;
  closeDrawer: () => void;
  handleSetState: (value: any) => void;
  handleExpanded: (value: any) => void;
  subMenuContent: any[];
  expanded: string;
}

const MenuLink: React.FC<Props> = ({
  feature,
  open,
  closeDrawer,
  handleSetState,
  handleExpanded,
  subMenuContent,
  expanded,
}) => {
  const session = useSelector((state: any) => state.app.session);
  const { isAdmin } = useAdmin('admins', 'admin');
  const tablet = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const { online } = useNetwork();

  useEffect(() => {
    if (open && !tablet) handleClose();
    if (!open && tablet) {
      handleHideSideBarLists(true);
    } else {
      handleHideSideBarLists(false);
    }

    // eslint-disable-next-line
  }, [tablet, open]);

  const handleHideSideBarLists = (hide: boolean) => {
    if (hide) {
      document
        .querySelectorAll(`.SideBarList`)
        .forEach((a) => a.setAttribute('style', 'display:none'));
    } else {
      document
        .querySelectorAll(`.SideBarList`)
        .forEach((a) =>
          a.className.includes(feature.name)
            ? a.setAttribute('style', 'display:block')
            : a.setAttribute('style', 'display:none'),
        );
    }
  };
  const renderIcon = (icon: string) => {
    switch (icon) {
      case 'WarehouseIcon':
        return (
          <WarehouseIcon fontSize="small" sx={{ color: 'primary.main' }} />
        );
      case 'InsertDriveFileIcon':
        return (
          <InsertDriveFileIcon
            fontSize="small"
            sx={{ color: 'primary.main' }}
          />
        );
      case 'RestaurantIcon':
        return (
          <RestaurantIcon fontSize="small" sx={{ color: 'primary.main' }} />
        );
      case 'Man4Icon':
        return <Man4Icon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 'DeliveryDiningIcon':
        return (
          <DeliveryDiningIcon fontSize="small" sx={{ color: 'primary.main' }} />
        );
      case 'DeckIcon':
        return <DeckIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 'PersonIcon':
        return <PersonIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 'PaymentIcon':
        return <PaymentIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 'StickyNote2Icon':
        return (
          <StickyNote2Icon fontSize="small" sx={{ color: 'primary.main' }} />
        );
      case 'AdminPanelSettingsIcon':
        return (
          <AdminPanelSettingsIcon
            fontSize="small"
            sx={{ color: 'primary.main' }}
          />
        );
      case 'GroupIcon':
        return <GroupIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 'SettingsIcon':
        return <SettingsIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 'ForestIcon':
        return <ForestIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 'QrCodeScannerIcon':
        return (
          <QrCodeScannerIcon fontSize="small" sx={{ color: 'primary.main' }} />
        );
      case 'CurrencyExchangeIcon':
        return (
          <CurrencyExchangeIcon
            fontSize="small"
            sx={{ color: 'primary.main' }}
          />
        );
      case 'AccountBalanceIcon':
        return (
          <AccountBalanceIcon fontSize="small" sx={{ color: 'primary.main' }} />
        );
      case 'HeadphonesIcon':
        return (
          <HeadphonesIcon fontSize="small" sx={{ color: 'primary.main' }} />
        );
      case 'Diversity3Icon':
        return (
          <Diversity3Icon fontSize="small" sx={{ color: 'primary.main' }} />
        );
      case 'InterpreterModeIcon':
        return (
          <InterpreterModeIcon
            fontSize="small"
            sx={{ color: 'primary.main' }}
          />
        );
      case 'GroupsIcon':
        return <GroupsIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 'notification-clear-all':
        return (
          <NotificationsIcon fontSize="small" sx={{ color: 'primary.main' }} />
        );
      case 'ListAltIcon':
        return <ListAltIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 'HomeIcon':
        return <HomeIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 'FestivalIcon':
        return <FestivalIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 'InvitationIcon':
        return (
          <LocalActivityIcon fontSize="small" sx={{ color: 'primary.main' }} />
        );
      default:
        return <SpeedIcon fontSize="small" sx={{ color: 'primary.main' }} />;
    }
  };

  if (!isAdmin(session) && feature.name === 'Admins') {
    return null;
  }

  const handleClick = (event: any, feature: any) => {
    if (feature.children.length > 0) {
      event.preventDefault();
      handleHideSideBarLists(false);

      if (JSON.stringify(feature.children) === JSON.stringify(subMenuContent))
        handleClose();
      else handleSetState(feature.children);
    } else {
      handleClose();
    }
  };

  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      handleExpanded(newExpanded ? panel : '');
    };

  const handleClose = () => {
    handleSetState(null);
    if (tablet) closeDrawer();
  };

  if (feature.children.length > 0 && open)
    return (
      <ExpansionPanel
        expanded={expanded}
        panelNumber={`panel${feature.id}`}
        handleChange={handleChange}
        title={feature.name}
        icon={renderIcon(feature.icon)}
      >
        {stableSort(
          feature.children,
          getComparator(Orders.ASC, 'precedence'),
        ).map((child: any) => (
          <ListItemButton
            disabled={
              !online &&
              !(child.slug === 'pos' || child.slug === 'walletManagements')
            }
            key={child.id}
            {...({
              component: NavLink,
              to: `/dashboard/${child.slug}`,
              className: ({ isActive }) => (isActive ? 'active' : ''),
            } as any)}
            sx={{
              height: 40,
              background: 'transparent',
              transition: 'background .3s ease-in',
              position: 'relative',
              backgroundColor: 'secondary.main',
              mb: 2,
              '&.active:before': {
                content: "''",
                position: 'absolute',
                top: 0,
                left: 0,
                width: '4px',
                height: '100%',
                backgroundColor: 'primary.contrastText',
              },
              '&:last-of-type': {
                mb: 0,
              },
            }}
            onClick={tablet && handleClose}
          >
            <ListItemIcon sx={{ minWidth: 24, ml: '-8px' }}>
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{ color: 'primary.main', transform: 'scale(.7)' }}
              />
            </ListItemIcon>
            <ListItemText primary={child.name} />
          </ListItemButton>
        ))}
      </ExpansionPanel>
    );
  return (
    <>
      <ListItemButton
        {...({
          component: NavLink,
          to: `/dashboard/${feature.slug}`,
          className: ({ isActive }) => (isActive ? 'active' : ''),
        } as any)}
        onClick={(event: any) => handleClick(event, feature)}
        disabled={!online}
        sx={{
          background: 'transparent',
          transition: 'background .3s ease-in',
          position: 'relative',
          backgroundColor: 'secondary.dark',
          mb: 2,
          '&.active:before': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            width: '5px',
            height: '100%',
            borderRadius: '5px',
            backgroundColor: 'primary.contrastText',
          },
          '&.active:after': {
            content: "''",
            position: 'absolute',
            top: 20,
            left: 200,
            width: '10px',
            height: '20%',
            borderRadius: '50%',
            backgroundColor: 'primary.contrastText',
          },
          zIndex: (theme) => theme.zIndex.appBar + 1,
        }}
      >
        <ListItemIcon sx={{ minWidth: 40, ml: open ? 0 : '-3px' }}>
          {renderIcon(feature.icon!)}
        </ListItemIcon>
        <ListItemText
          primary={
            feature.name === FeaturesNames.FIELD_DASHBOARD
              ? 'Dashboard'
              : feature.name
          }
        />
      </ListItemButton>
      <List
        className={`SideBarList ${feature.name}`}
        disablePadding
        sx={{
          p: '16px 6px',
          backgroundColor: 'primary.main',
          width: 200,
          height: 'calc(100vh)',
          position: 'fixed',
          transition: 'all .2s ease-in',
          top: 0,
          left: 0,
          transform: subMenuContent ? 'translateX(57px)' : 'translateX(-200px)',
          WebkitTransform: subMenuContent
            ? 'translateX(57px)'
            : 'translateX(-200px)',
          overflowY: 'scroll',
          zIndex: (theme) => theme.zIndex.appBar - 1,
        }}
        onClick={() => handleSetState(null)}
      >
        <Toolbar />
        {subMenuContent &&
          subMenuContent.map((feature: any) => (
            <SubMenuLink
              key={feature.id}
              title={feature.name}
              slug={feature.slug}
              closeDrawer={handleClose}
            />
          ))}
      </List>
    </>
  );
};

export default MenuLink;
